import React from 'react';
import styled from 'styled-components/macro';
import Select from 'react-select';
import { connect } from 'react-redux';

import one from './resources/1.png';
import two from './resources/2.png';
import three from './resources/3.png';
import four from './resources/4.png';
import five from './resources/5.png';
import six from './resources/6.png';
import seven from './resources/7.png';
import './index.css';
import { Input, Scale, Rating, RatingValue } from './Styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSadTear,
  faSadCry,
  faFrown,
  faMeh,
  faSmile,
  faLaugh,
  faGrinHearts,
} from '@fortawesome/free-solid-svg-icons';

const scaleEmojis = {
  3: [one, four, seven],
  5: [one, two, four, six, seven],
  7: [one, two, three, four, five, six, seven],
};

const emojis = {
  3: [faSadCry, faMeh, faGrinHearts],
  5: [faSadCry, faFrown, faMeh, faSmile, faGrinHearts],
  7: [faSadCry, faSadTear, faFrown, faMeh, faSmile, faLaugh, faGrinHearts],
};

const options = [
  {
    label: 'Three',
    value: 3,
  },
  {
    label: 'Five',
    value: 5,
  },
  {
    label: 'Seven',
    value: 7,
  },
];

const LikertScale = ({ item, editField, hasError }) => {
  //
  return (
    <>
      <Select
        className='react-select-container'
        classNamePrefix={'react-select'}
        theme={{
          borderRadius: 0,
          minHeight: 0,
          spacing: {
            baseUnit: 0,
            controlHeight: 10,
            menuGutter: 1,
          },
        }}
        styles={{
          container: (style) => {
            return {
              ...style,
              minHeight: '1px !important',
              height: '25px',
              width: '25%',
              marginTop: '20px',
              marginBottom: '10px',
            };
          },
          control: (base) => {
            return {
              ...base,
              boxShadow: '1px 1px 5px 1px #D9D9D9',
              padding: '1px 2px',
              background: 'white',
              ':hover': {
                ...base[':hover'],
                boxShadow: '1px 1px 10px 2px #D9D9D9',
                borderColor: '#1B998B !important',
              },
              ':active': {
                ...base[':active'],
                borderColor: '#1B998B !important',
              },
            };
          },
          singleValue: (style) => {
            return {
              ...style,
              padding: '0 5px',
            };
          },
        }}
        isSearchable={false}
        options={options}
        value={options.find((entry) => entry.value === item.scale)}
        onChange={(selected) => {
          editField(item.id, 'scale', selected.value);
        }}
      />
      <Scale>
        {emojis[item.scale].map(
          (
            emoji,
            index //for generating the images, scaleEmoji is the array that contains all the iamges, item.scale is the selected number 3,5 or 7
          ) => (
            <Rating key={index}>
              <FontAwesomeIcon
                icon={emoji}
                size='3x'
                style={{ color: '#757575', marginBottom: '5px' }}
              />
              <RatingValue
                css={`
                  border: ${item.ratings[index] || !hasError
                    ? ''
                    : '1px solid red'};
                `}
                value={item.ratings[index]}
                scaleValue={item.scale}
                onChange={(e) => {
                  editField(item.id, 'ratings', {
                    index,
                    value: e.target.value,
                  });
                }}
              />
            </Rating>
          )
        )}
      </Scale>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    hasError: state.error.ratings,
  };
};

export default connect(mapStateToProps, null)(LikertScale);
