import React from 'react'
import { pexUrl } from '../../../../../url'

const OpenLink = props => {
    return (
        <div>
            <h4>OpenLink</h4>
            <label className="dislabel" style={{ margin: '10px' }}>
                Open Link
            <input
                    name="active"
                    type="checkbox"
                    checked
                    disabled
                />
            </label>
            <a href={`${pexUrl}/openlink/${props.openlink.hashId}`} style={{ fontSize: '.7em', wordBreak: 'break-all', color: 'rgba(0,0,0,.8)', fontWeight: '500', margin: '10px' }} target="_blank">
                {`${pexUrl}/openlink/${props.openlink.hashId}`}
            </a>
        </div>
    )
}

export default OpenLink