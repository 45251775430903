import styled from 'styled-components'

export const Options = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  padding: 4px 0;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color:#EFF3F4;
`

export const OptionItem = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  padding: 5px 5px 5px 0;
`

export const SelectOptions = styled(OptionItem)`
  flex-grow: initial;
  flex-basis: 50%;
  justify-content: flex-start;
  flex-direction: column;
`