import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle } from '@fortawesome/free-solid-svg-icons'

 const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width: 30vw;
    height:20vh;
    background-color: white;
`
const Icon = styled.div`
    display: flex;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    width:100%;
    height:50%;
    color: #310E31;
    font-size: 500%;
`

const Text = styled.div`
    display: flex;
    box-sizing: border-box;
    //justify-content: center;
    flex-direction: column;
    align-items: center;
    width:100%;
    height:50%;
    color: #310E31;
    font-size: 100%;
`
const CloseButton = styled.div`
    display: flex;
    box-sizing: border-box;  
    color: white;
    font-size: 100%;
    align-items: center;
    justify-content: center;
    background-color: #310E31;
    width: 50px;
    height: 20px;
    margin-top:5%;
    box-shadow: 1px 1px 5px -1px #586469;
    :hover{
    cursor: pointer;
    box-shadow: 1px 1px 5px 2px #586469;
    }
`

const ShowAlert = (props)=>{
    return(
        <React.Fragment>
            <Card>
                <Icon>
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                    />
                </Icon>
                <Text>
                    {props.alert ? 'Created Successfully!' : 
                     props.email ? 'Messsage Queued, will be sent accordingly' : 'Added Successfully'}
                    <CloseButton onClick={() => {
                    if (props.alert){
                        props.alert(false)  //to close the alert view
                        props.close(false)  //to close the whole modal
                    }
                    else
                        props.close(false)
                    }}>
                Ok
                </CloseButton>
                </Text>
            </Card>
        </React.Fragment>
    )
}


export default ShowAlert