import React from 'react'
import Select from 'react-select'
import { connect } from 'react-redux'

import './index.css'

import { selectProjectAndFetch } from '../../../redux/actions'

import {
  // projectPropertySelector, 
  projectListFormatSelector,
  projectObjectSelector,
  projectIsLoadingSelector
} from '../../../redux/selectors'

const SelectProjects = ({ selected, selectProject, projectList, loading }) => {

  // const disabled = !projectProperty
  console.log(projectList)
  return (
    <Select
      className='react-select-container'
      classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base => {
          return {
            ...base,
            margin: "5px 0",
            minHeight: "1px !important",
            height: "25px",
            width: "100%",
          }
        },
        control: (base) => {
          return {
            ...base,
            boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover': {
              ...base[':hover'],
              boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active': {
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style => {
          return {
            ...style,
            padding: "0 5px"
          }
        }
      }}
      id="project"
      isLoading={loading}
      // isClearable={true}
      // isDisabled={disabled}
      placeholder="Please Select..."
      isSearchable={false}
      options={projectList}
      value={selected}
      onChange={selected => {
        selectProject(selected.value)
      }}
    />
  )
}

const mapStateToProps = state => {
  return {
    projectList: projectListFormatSelector(state),
    // projectProperty: projectPropertySelector(state),
    selected: projectObjectSelector(state),
    loading: projectIsLoadingSelector(state)
  }
}

const mapDispatchToProps = {
  selectProject: channel => selectProjectAndFetch(channel)
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectProjects)