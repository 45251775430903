import React from 'react' 
import Scrollspy from 'react-scrollspy'

function TermsNavbar(props) { 
  const items = [
    'terms-of-services-introduction', 'terms-of-services-our-service', 'terms-of-services-your-content', 'terms-of-services-our-content', 
    'terms-of-services-use-of-the-services', 'terms-of-services-fees-and-payments', 'terms-of-services-suspension-and-termination', 
    'terms-of-services-changes-and-updates', 'terms-of-services-confidential-information', 'terms-of-services-indemnification', 
    'terms-of-services-electronic-communication-and-notices', 'terms-of-services-miscellaneous', 

    'privacy-statement-prolificxm-privacy-statement', 'privacy-statement-data-collection-and-services', 'privacy-statement-information-we-collect', 
    'privacy-statement-purpose-of-information-collection', 'privacy-statement-retension-of-information', 
    'privacy-statement-optout-from-sharing-information', 'privacy-statement-your-rights-to-your-data', 'privacy-statement-links-to-other-sites', 
    'privacy-statement-tracking-technologies', 'privacy-statement-information-we-share', 'privacy-statement-security', 
    'privacy-statement-changes-in-privacy-statement', 'privacy-statement-contact-us', 

    'security-statement-prolificxm-security-and-compilance', 'security-statement-user-security', 'security-statement-data-security', 
    'security-statement-system-reliability', 'security-statement-vulnerability-and-penetration-tests', 'security-statement-access-control', 
    'security-statement-network-security', 'security-statement-security-beach', 'security-statement-customer-responsibility', 
    'security-statement-compilation-and-certification', 'security-statement-anti-spam-protection', 'security-statement-conclusion', 

    'anti-spam-policy-prolificxm-anti-spam-policy', 'anti-spam-policy-how-to-avoid-sending-spam', 'anti-spam-policy-our-stand-against-spam', 
    'anti-spam-policy-reporting-spam', 

    'fees-and-payments-subscription-fees', 'fees-and-payments-taxes', 'fees-and-payments-price-changes', 'fees-and-payments-overage-fees', 

    'suspension-and-termination-by-you', 'suspension-and-termination-by-us', 

    'changes-and-updates-to-terms', 'changes-and-updates-to-services', 'changes-and-updates-downgrades', 

    'information-we-collect-customer-information', 'information-we-collect-participant-information', 'information-we-collect-automated-data-collection', 

    'compilation-and-certification-gdpr-compilance' 
  ] 

  return ( 
    <div style={{ padding: '50px 20px 50px 40px', marginBottom: '30px' }}> 
      <Scrollspy items={ items } currentClassName="is-current"> 
        <ul style={{listStyle: 'none'}}> 
          <li style={{cursor: 'pointer', color: 'rgb(52, 58, 64)', fontSize: '14px'}} onClick={() => props.selectPolicy(1)} className="mb-2"> 
            Terms of Services 
            <ul className="mt-1 ml-4" type="disc"> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-introduction">Introduction               </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-our-service">Our Services                </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-your-content">Your Content               </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-our-content">Our Content                 </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-use-of-the-services">Use of the services </a></li>
              <li>
                <a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-fees-and-payments">Fees and Payments </a> 
                <ul className="mt-1 ml-3" type="disc">
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#fees-and-payments-subscription-fees"> Subscription Fees </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#fees-and-payments-taxes">             Taxes             </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#fees-and-payments-price-changes">   Price Changes     </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#fees-and-payments-overage-fees">      Overage Fees      </a></li>
                </ul> 
              </li> 
              <li> 
                <a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-suspension-and-termination">Suspension and Termination              </a> 
                <ul className="mt-1 ml-3" type="disc"> 
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#suspension-and-termination-by-you"> Termination by You               </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#suspension-and-termination-by-us">  Suspension and Termination by Us </a></li>
                </ul> 
              </li> 
              <li> 
                <a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-changes-and-updates">Changes and Updates</a> 
                <ul className="mt-1 ml-3" type="disc"> 
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#changes-and-updates-to-terms"> Changes to Terms    </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#changes-and-updates-to-services"> Changes to Services </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#changes-and-updates-downgrades"> Downgrades            </a></li>
                </ul> 
              </li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-confidential-information">Confidential Information                </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-indemnification">Indemnification                          </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-electronic-communication-and-notices">Electronic Communications and Notices </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#terms-of-services-miscellaneous">Miscellaneous                            </a></li>
            </ul> 
          </li> 
          <li style={{cursor: 'pointer', color: 'rgb(52, 58, 64)', fontSize: '14px'}} onClick={() => props.selectPolicy(2)} className="mb-2"> 
            Privacy Statement
            <ul className="mt-1 ml-4" type="disc">
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-prolificxm-privacy-statement">ProlificXM Privacy Statement</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-data-collection-and-services">Data Collection and Services</a></li>
              <li>
                <a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-information-we-collect">Information We Collect</a> 
                <ul className="mt-1 ml-3" type="disc">
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#information-we-collect-customer-information">  Customer Information     </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#information-we-collect-participant-information">  Participant Information </a></li>
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#information-we-collect-automated-data-collection"> Automated Data Collection </a></li>
                </ul> 
              </li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-purpose-of-information-collection">Purpose of Information Collection</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-retension-of-information">Retention of Information</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-optout-from-sharing-information">Opt-out from Sharing/Receiving Information</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-your-rights-to-your-data">Your Rights to Your Data</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-links-to-other-sites">Links to Other Sites</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-tracking-technologies">Tracking Technologies</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-information-we-share">Information we share</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-security">Security</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-changes-in-privacy-statement">Changes in Privacy Statementy</a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#privacy-statement-contact-us">Contact Us</a></li>
            </ul> 
          </li> 
          <li style={{cursor: 'pointer', color: 'rgb(52, 58, 64)', fontSize: '14px'}} onClick={() => props.selectPolicy(3)} className="mb-2"> 
            Security Statement 
            <ul className="mt-1 ml-4" type="disc"> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-prolificxm-security-and-compilance"> ProlificXM Security and Compliances            </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-user-security">   User Security                                  </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-data-security">   Data Security                                  </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-system-reliability">   System Reliability                             </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-vulnerability-and-penetration-tests">Vulnerability Management and Penetration Tests </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-access-control">   Access Control                                 </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-network-security">   Network Security                               </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-security-beach">   Security Breach                                </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-customer-responsibility">   Customer Responsibilities                      </a></li>  
              {/* <li>
                <a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-compilation-and-certification">  Compliance and Certifications </a>
                <ul style={{listStyle: 'none'}} className="ml-3 mt-1">
                  <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#compilation-and-certification-gdpr-compilance"> GDPR Compliance </a></li>
                </ul> 
              </li>  */} 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-anti-spam-protection"> Anti-Spam Protection </a></li> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#security-statement-conclusion">   Conclusion           </a></li> 
            </ul>  
          </li>  
          <li style={{cursor: 'pointer', color: 'rgb(52, 58, 64)', fontSize: '14px'}} onClick={() => props.selectPolicy(4)}> 
            Anti-spam Policy 
            <ul className="mt-1 ml-4" type="disc"> 
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#anti-spam-policy-prolificxm-anti-spam-policy">  ProlificXM Anti-Spam Policy </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#anti-spam-policy-how-to-avoid-sending-spam"> How to Avoid Sending Spam   </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#anti-spam-policy-our-stand-against-spam">  Our Stand against Spam      </a></li>
              <li><a style={{fontSize: '13px', color: 'rgb(52, 58, 64)'}} href="#anti-spam-policy-reporting-spam">    Reporting Spam              </a></li>
            </ul> 
          </li> 
        </ul> 
      </Scrollspy> 
    </div> 
  ) 
} 

export default TermsNavbar; 
