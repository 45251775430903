import React from 'react'; 
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'; 
import BrandCategory from './BrandCategory'; 
import Details from './components/details/details'; 
import { Provider } from 'react-redux'; 
import store from './store/index'; 
import createBrandCategory from './components/create_brand/createBrandCategory'

const Routes = props => { 
  let { match } = props; 

  return ( 
    <BrowserRouter> 
      <Switch> 
        <Route exact path={`${match.path}`} component={BrandCategory} /> 
        <Route path={`${match.path}/create`} component={createBrandCategory} /> 
        <Route path={`${match.path}/:id`} component={Details} /> 
      </Switch> 
    </BrowserRouter> 
  ) 
} 

const ReduxConnectedRoutes = withRouter(Routes) 



function Index() { 
    return ( 
      <Provider store={store}> 
        <ReduxConnectedRoutes /> 
      </Provider> 
    ) 
} 

export default Index













// ------------------------------------------------------------
// ------------------------------------------------------------
// ------------------------------------------------------------
// ------------------------------------------------------------









// import React, { useState, useEffect, memo } from 'react';
// import { Link, Switch, Route, Redirect } from 'react-router-dom'
// import styled from 'styled-components/macro'
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faSave } from '@fortawesome/free-solid-svg-icons'
// import * as Yup from 'yup'
// import { withFormik } from 'formik'
// import { AddNewBrandCategory, FetchAllBrandCategory } from '../../../url/prmUrl';
// import Alert from '../ShowAlert'

// import { Button, Input, Label, Body, Error, Aside, TextArea, Modal } from '../StyledComponents'

// const Form = props => {

//   const {
//     values,
//     touched,
//     errors,
//     handleChange,
//     handleBlur,
//     handleSubmit,
//   } = props;

//   return (
//     <form onSubmit={handleSubmit} css={`width: 100%`}>
//           <Aside>
//             <section>
//             {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Category Name:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <Input
//                     name="name"
//                     type="text"
//                     placeholder="Brand Category name"
//                     value={values.name}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>

//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Active:
//                 </div>
//                 <div css={'width:15px'}>
//                   <Input
//                     name="status"
//                     type="checkbox"
//                     css={`width:18px;height:18px`}
//                     checked={values.status}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>

//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                    Description:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <TextArea
//                     name="description"
//                     placeholder="Type here..."
//                     value={values.description}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>

//             </section>
//         </Aside>
//         <Button type="submit">
//           <FontAwesomeIcon
//             icon={faSave}
//             css={`margin-right:10px`}
//           />
//           Create
//         </Button>
//     </form>
//   )

// }








// const BrandCategory = props => {
//   const [view, setView] = useState(false)
//   const [editable, setEditable] = useState({
//     loaded: '',
//     fetched: false,
//     redirect: false
//   })

//   useEffect(() => {

//     const fetchData = async () => {
//       const result = await axios(FetchAllBrandCategory);
//       try {
//         setEditable({
//         ...editable,
//         loaded: result.data,
//       })
//     }
//       catch (err) {
//           console.log("err")
//       }
//     };
//     if(view===false){
//       fetchData(); 
//     }
//   }, [view]);

//    const brandCategory = editable.loaded? editable.loaded.map(item=>item.name) : []

//   const schema = Yup.object().shape({
//     name: Yup.string().required("Must Define a Name")
//                              .min(3, "Minimum 3 characters")
//                              .notOneOf(brandCategory, "Already exists")

//   })

//   const CreateBrandCategory = withFormik({
//     validationSchema: schema,
//     mapPropsToValues: () => 
//     ({ 
//       name:"" , 
//       description: '',
//       status: true
//     }),

//     handleSubmit: (values, { setSubmitting }) => {
//       axios.post(AddNewBrandCategory, values)
//       .then((response) => {
//         if(!response.data.Error){
//           setView(true)
//         }
//         else{
//           window.alert("Uexpected Error! Duplicate Name found!")
//         }
//       })
//       .catch((err) => {
//         // console.log("Error")
//       })

//     }
//   })(Form)






// const BrandCategory = props => {
//   return (
//     <React.Fragment> 
//       Hello World
//       <Body>
//         <Modal
//           view={view}
//           onClick={event => {
//             event.preventDefault()
//             if (event.target === event.currentTarget)
//             {
//               setView(false)
//           }
//           }}
//         >
//           <Alert close={setView}/>
//         </Modal>
//         <CreateBrandCategory/>
//       </Body> */}
//       {editable.redirect === false ? <AddNewUser /> :
//         <Redirect
//           to={{
//             pathname: "/app/users"
//           }}
//         />
//       } 
//      </React.Fragment>
//   )
// }

// export default memo(BrandCategory); 