import React, { useState, useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import axios from 'axios'
import baseUrl from '../../../../url'
import { useSelector, useDispatch } from 'react-redux'
import collect from 'collect.js'
import { addQuestionToSurvey } from '../../redux/actions/modifyQuestion'
import './style.css'
import { toast, Slide } from 'react-toastify';
const AddFromQuestionBank = ({ selectedFeatureID }) => {

    const [bankLists, setBankLists] = useState([])
    // const [addtoLists, setAddtoLists] = useState([])
    const [questionLists, setQuestionLists] = useState([])
    const [status, setStatus] = useState(true)
    const question = useSelector(state => state.question)
    const featureId = useSelector(state => state.featureId.id)
    let dispatch = useDispatch()
    // console.log(featureId)
    useEffect(() => {

        if (selectedFeatureID) {
            axios.get(`${baseUrl}/api/questionBank/list/${selectedFeatureID}`).then(
                response => {
                    if (response && Object.keys(response.data).length > 0) {
                        const resData = response.data.bankList.map(banklist => {
                            const lists = banklist.question.map(quest => {
                                return { ...quest, status: false }
                            })
                            return { ...banklist, question: lists }
                        })
                        setBankLists(resData)
                        let questions = resData.map(banklist => banklist.question.map(question => question))
                        questions = collect(questions).collapse().items
                        setQuestionLists(questions)
                    } else {
                        setBankLists([]);
                    }
                }
            )
        }


    }, [selectedFeatureID])

    const addQuestionToSurveys = (event) => {

        const bankListsData = bankLists.map(banklist => {
            const lists = banklist.question.map(quest => {
                if (quest.id === event.target.value) {
                    return { ...quest, status: event.target.checked }
                } else {
                    return quest
                }
            })
            return { ...banklist, question: lists }
        })
        setBankLists(bankListsData)
        // console.log(questionLists)
        const lists = questionLists.map(item => {
            if (item.id === event.target.value) {
                return { ...item, status: event.target.checked }
            } else {
                return item
            }
        })
        setQuestionLists(lists)
        // console.log(lists)
        const btnStatus = collect(lists).where('status', true)
        // console.log(btnStatus)
        if (btnStatus.items.length > 0) {
            setStatus(false)
        } else {
            setStatus(true)
        }
        // const curAddtoLists = addtoLists
        // curAddtoLists.push(event.target.value)
        // setAddtoLists(curAddtoLists)
        // console.log(addtoLists)

    }

    const addQuestion = () => {

        let questionIds = []
        const addData = bankLists.map(banklist => {
            const lists = banklist.question.map(quest => {
                if (quest.status === true) {
                    questionIds.push(quest.id)
                }
                return quest
            })
            return banklist
        })

        let addtoListSurveys = collect(questionLists).whereIn('id', questionIds)
        const stateQuestionIds = question.map(item => item.id)
        addtoListSurveys = addtoListSurveys.whereNotIn('id', stateQuestionIds)

        // console.log(addtoListSurveys)
        if (addtoListSurveys.items.length > 0) {

            dispatch(addQuestionToSurvey(addtoListSurveys.items))

            const bankListsData = bankLists.map(banklist => {
                const lists = banklist.question.map(quest => {
                    return { ...quest, status: false }
                })
                return { ...banklist, question: lists }
            })

            setBankLists(bankListsData)
            setStatus(true)
            toast("Questions Added Successfully.", {
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                transition: Slide,
                className: 'text-center'
            })

        } else {

            const bankListsData = bankLists.map(banklist => {
                const lists = banklist.question.map(quest => {
                    return { ...quest, status: false }
                })
                return { ...banklist, question: lists }
            })

            setBankLists(bankListsData)
            setStatus(true)

        }


    }
    // console.log(bankLists)
    if (bankLists.length <= 0) {
        return "No questions added to question bank." // "No question bank added."
    }

    return (
        <div>
            <button
                className="btn btn-success btn-sm btn-block"
                disabled={status}
                onClick={() => addQuestion()}
                style={{ backgroundColor: '#1B998B', marginBottom: '10px' }}>
                Add Question to survey
            </button>
            <Accordion allowZeroExpanded={true}>
                {
                    bankLists.map((bankList, index) => <AccordionItem key={index}>
                        <AccordionItemHeading>
                            <AccordionItemButton>
                                {bankList.name}
                            </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                            {bankList.question.map(question => {
                                return <label style={{ display: 'block' }} key={question.id}>
                                    <input
                                        name="addtoSurvey"
                                        type="checkbox"
                                        checked={question.status}
                                        value={question.id}
                                        onChange={addQuestionToSurveys}
                                        style={{ marginRight: '5px', position: 'relative', top: '2px' }} />
                                    {question.title}
                                </label>
                                // <li onClick={() => addQuestionToSurveys(question.id)}>{question.title}</li>
                            })}
                        </AccordionItemPanel>
                    </AccordionItem>
                    )
                }

            </Accordion>
            <button
                className="btn btn-success btn-sm btn-block"
                disabled={status}
                onClick={() => addQuestion()}
                style={{ backgroundColor: '#1B998B', marginTop: '10px' }}>
                Add Question to survey
            </button>
        </div>
    )
}

export default AddFromQuestionBank