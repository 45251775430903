import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 20px;
  background-color: #white;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 0 5px 4px #E6E6E6;
  justify-content: center;
  align-items: center;
  
  > span {color: #585151}
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
  }

`

export const Aside = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    margin-bottom:10px;
    box-shadow: 0 0 5px 4px #E6E6E6;
    color: #310D31 !important;
    >section {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding-left:15px;  
    }
    >table{
      border: 1px solid #ddd;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
    }
    >table >tr >th{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td >input{
      width:25%;
    }
  `

export const UploadContainer = styled.div`
  overflow: hidden;
  position: absolute;
  width: 120px;
  height: 25px;
  right: 20px;
  top: 20px;
  :hover{
    box-shadow: 2px 2px 5px #BFBFBF;
  }
  
`

export const Upload = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
`

export const UploadButton = styled.div`
  background-color: #310D31;
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${Upload}:hover & {
    background-color: #C52590;
  }
`


export const Input = styled.input.attrs(props => ({
  ...props
}))`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 30px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 3px 1px rgb(52, 58, 64);
  
  :placeholder {
    color: rgb(52, 58, 64);
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: left;
  flex-direction: row;
  text-align: center;
  padding:10px 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: rgb(52, 58, 64);
  font-weight:normal;
  font-size: 15px;
  > h2 {
    font-size: 30px;
    @media only screen and (max-width: 768px){
      font-size: 18px;
      margin-top: 30px;
    }
    @media only screen and (max-width: 425px){
      font-size: 16px;
      margin-top: 45px;
    }
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`

export const Button = styled.button`
  /* display: flex; */
  box-sizing: border-box;
  /* justify-content: center;
  align-items: center; */
  margin: 20px auto;
  color: white;
  border: none;
  background-color: #1B998B;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 1;
  :hover{
    background-color: #0B6157;
    color: #fff;
    border: 1px solid #0B6157;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
    width: 90px;
    height: 25px;
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
    width: 80px;
    height: 20px;
  }
`