import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faPowerOff,
  faUserCircle,
  faUserTie,
  faColumns,
  faLandmark,
  faPencilRuler,
  faProjectDiagram,
  faPoll,
  faChartLine,
  faTicketAlt,
  faMailBulk,
  faUsers,
  faBullseye,
  faBinoculars,
  faCaretDown,
  faArrowAltCircleRight,
  faHaykal,
  faDotCircle,
  faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import baseUrl from '../../url';

import Profile from '../Profile';
import Feature from '../Feature';
// import FeatureTwo from '../FeatureTwo'
import FeatureDetailed from '../Feature/components/Table/FeatureDetail';
// import DetailedTwo from '../FeatureTwo/components/Table/Detail'
import BroadObjectives from '../BroadObjectives';
import Objectives from '../Objectives';
import MicroObjectives from '../MicroObjectives';
import Projects from '../Projects';
import QuestionBank from '../QuestionBank';
import Distribution from '../Distribution';
// import List from '../Distribution/pages/List'
// import EditSortAndPreview from '../Distribution/pages/EditSortAndPreview'
import Email from '../Email';
import Dashboard from '../Dashboard';
import Avatar from '../../resources/download.png';
import UserProfile from '../UserProfile';
import Preview from '../Dashboard'; //loaded from main page
import Form from '../Form';
import DataAnalysis from '../DataAnalysis';
import DataAnalysisTwo from '../DataAnalysisTwo';
import Users from '../Users';
import CampaignCategory from '../CustomerRelationship/CampaignCategory';
import Contact from '../CustomerRelationship/Contact';
import Employee from '../CustomerRelationship/Employee';
import Designation from '../CustomerRelationship/Designation';
import Lead from '../CustomerRelationship/Lead';
import Opportunity from '../CustomerRelationship/Opportunity';
import Company from '../CustomerRelationship/Company';
import Industry from '../CustomerRelationship/Industry/index';
import Branch from '../CustomerRelationship/Branch';
import Department from '../CustomerRelationship/Department';
import Blog from '../Blog/index';
import BrandCategory from '../CustomerRelationship/BrandCategory';
import ProductCategory from '../CustomerRelationship/ProductCategory';
import TargetAudience from '../CustomerRelationship/TargetAudience';
import Campaign from '../CustomerRelationship/Campaign';
import Brand from '../CustomerRelationship/Brand';
import Product from '../CustomerRelationship/Product';
import Channels from '../Channels';
import Survey from '../Survey';
import setAuthToken from '../../util/setAuthToken';
import Can from '../../components/Can';
import ChangePassword from '../Users/ChangePassword';
// import ChannelsTwo from '../ChannelsTwo/index'
import './style.css';

import {
  ModalContainer,
  ModalArrow,
  Modal,
  ModalDiv_Name,
  ModalDiv_ViewProfile,
  ModalDiv_Logout,
  Line,
  Sidebar,
  TopBar,
  Apps,
  StyledList,
  StyledNavLink,
  Expandable,
  User,
  StyledFontAwesomeIcon,
  Body,
  DeviceBody,
  Span,
  CrmIcon,
  MenuListIconSpan,
  Anchor,
} from './StyledComponents';
import Logo from '../../resources/ProlificXM.png';
import { Expand } from 'grommet-icons';
import { error } from '../Question/redux/actions/modifyQuestion';

import { Provider } from 'react-redux';
import store from './store/index';
import updateSidebarData from './store/actionCreator';
import { css } from 'glamor';
import BulkEmail from '../BulkEmailList';
import MailConfig from '../MailConfig';
import Ticket from '../Ticket';

const MainPage = (props) => {
  const { history } = props;

  const dispatch = useDispatch();

  const [viewModal, setviewModal] = useState(false);
  const [view, toggle] = useState(false);
  const [crExpand, setCrExpand] = useState(false);
  const [disId, setDisId] = useState('');
  const [createdByCompanyID, setCreatedByCompanyID] = useState('');
  const [loadingDevice, setLoadingDevice] = useState(true);
  const [user, setUser] = useState({});

  // Responsive sidebars
  const [fullsidebar, setFullsidebar] = useState('true');
  const [barClick, setBarclick] = useState(false);
  const [crmClick, setCrmClick] = useState(false);
  // End of responsive sidebar

  let rout = props.location.pathname.split('/');
  let currentRoute = '';
  // if(rout.length === 3 && rout[2] === 'users') { currentRoute = '' }
  if (rout.length === 2) {
    currentRoute = rout[1];
  } else if (rout.length >= 3) {
    currentRoute = rout[2];
  }

  useEffect(() => {
    axios
      .get(`${baseUrl}/api/login/${localStorage.getItem('jwtID')}`)
      .then((response) => {
        setUser(response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    dispatch(updateSidebarData(fullsidebar));
  }, [fullsidebar]);

  const logOut = () => {
    localStorage.clear();
    // Remove auth header
    setAuthToken(false);
    props.history.push('/');
  };

  const sidebarCssClasses = [
    'sidebar',
    fullsidebar ? 'sidebarShow' : 'sidebarHide',
  ];

  // Text / Icon
  const selectedSidebarColor = '#fff';
  const nonSelectedSidebarColor = 'rgb(52, 58, 64)';
  const iconColor = '#1B998B';

  // Background
  const sidebarItemBackground = '#1B998B';
  const sidebarTextBackground = '#1B998B';

  const crmSettingCSS = css({
    color: '#1B998B',
    '@media(max-width: 1920px)': {
      margin: fullsidebar == 'true' ? '2px 10px 2px 41px' : '2px 10px',
    },
    '@media(max-width: 1600px)': {
      margin: fullsidebar == 'true' ? '2px 10px 2px 33px' : '2px 10px',
    },
    '@media(max-width: 1440px)': {
      margin: fullsidebar == 'true' ? '2px 10px 2px 29px' : '2px 10px',
    },
    '@media(max-width: 1366px)': {
      margin: fullsidebar == 'true' ? '2px 10px 2px 27px' : '2px 10px',
    },
    '@media(max-width: 1024px)': {
      margin: fullsidebar == 'true' ? '2px 10px 2px 23px' : '2px 10px',
    },
    '@media(max-width: 768px)': {
      margin: fullsidebar == 'true' ? '3px 10px' : '3px 10px 3px 21px',
    },
    '@media(max-width: 750px)': { margin: '3px 10px 3px 20px' },
    '@media(max-width: 425px)': { margin: '3.5px 10px 3.5px 20px' },
  });

  const Content =
    localStorage.getItem('jwtType') !== 'Device'
      ? () => (
          <div style={{ marginBottom: '0px' }}>
            {/* Topbar */}

            <TopBar>
              <FontAwesomeIcon
                style={{ display: 'none' }}
                icon={faBars}
                onClick={() => {
                  toggle(!view);
                }}
                css={`
                  border-sizing: border-box;
                  float: left;
                  font-size: 15px;
                  margin: 10px 0px 10px 10px;
                  display: none;
                  background-color: rgba(0, 0, 0, 0.3);
                  @media only screen and (max-width: 600px) {
                    display: block;
                  }
                `}
              />
              <Link to={`${props.match.url}`}>
                <img
                  alt='Pex Logo'
                  src={Logo}
                  css={`
                    box-sizing: border-box;
                    float: left;
                    padding: 10px;
                    margin-left: 10px;
                    height: 90%;
                  `}
                />
              </Link>

              <Span
                className='ml-auto mt-2'
                id='barBtn'
                onClick={() => {
                  if (fullsidebar == 'true') {
                    setFullsidebar('false');
                  } else {
                    setFullsidebar('true');
                  }
                  setBarclick(true);
                }}
              >
                <i style={{ color: '#1B998B' }} className='fa fa-bars mt-3'></i>
              </Span>

              <User
                onClick={() => {
                  setviewModal(!viewModal);
                }}
              >
                {/* viewModal ? setviewModal(false) : setviewModal(true)  */}
                {/* <img alt="avatar" src={Avatar} /> */}
                <div className='userIcon'>
                  <i style={{ color: '#1B988B' }} className='far fa-user'></i>
                </div>
                {/* <div css={`padding-left: 5px`}>{'lastName' in user && user.lastName}</div> */}
              </User>
            </TopBar>

            {/* Sidebar */}
            <Sidebar fullSidebar={fullsidebar} barClick={barClick} view={view}>
              <StyledList>
                <>
                  <Can
                    feature='dashboard'
                    perform='view'
                    yes={() => (
                      <li
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'app'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'app'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'app'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faColumns}
                          />
                          <StyledNavLink
                            style={{
                              fontWeight:
                                currentRoute === 'app' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'app'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            to={`${props.match.url}`}
                            fullSidebar={fullsidebar}
                          >
                            Dashboard
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <Can
                    feature='survey'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'survey'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'survey'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/survey`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'survey'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faPencilRuler}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/survey`}
                            style={{
                              fontWeight:
                                currentRoute === 'survey' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'survey'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Survey
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <Can
                    feature='channel'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'channels'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          fullsidebar={fullsidebar}
                          color={
                            currentRoute === 'channels'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/channels`}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'channels'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faDotCircle}
                          />
                          <StyledNavLink
                            style={{
                              // color: currentRoute === 'channels' ? selectedSidebarColor : nonSelectedSidebarColor,
                              fontWeight:
                                currentRoute === 'channels' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'channels'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            to={`${props.match.url}/channels`}
                            fullSidebar={fullsidebar}
                          >
                            Channels
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <Can
                    feature='feature'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'feature'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'feature'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/feature`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'feature'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faHaykal}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/feature`}
                            style={{
                              // color: currentRoute === 'feature' ? selectedSidebarColor : nonSelectedSidebarColor,
                              fontWeight:
                                currentRoute === 'feature' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'feature'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Feature
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  {/* <Can
										feature='highLevelObjective'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'broadObjectives'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'broadObjectives'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/broadObjectives`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'broadObjectives'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faDotCircle}
													/>
													<StyledNavLink
														to={`${props.match.url}/broadObjectives`}
														style={{
															// color: currentRoute === 'broadObjectives' ? selectedSidebarColor : nonSelectedSidebarColor,
															fontWeight:
																currentRoute === 'broadObjectives'
																	? 'bold'
																	: 'normal',
															background:
																currentRoute === 'broadObjectives'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														High-Level Objectives
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/>

									<Can
										feature='lowLevelObjective'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'objectives'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'objectives'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/objectives`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'objectives'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faBullseye}
													/>
													<StyledNavLink
														to={`${props.match.url}/objectives`}
														style={{
															// color: currentRoute === 'objectives' ? selectedSidebarColor : nonSelectedSidebarColor,
															fontWeight:
																currentRoute === 'objectives'
																	? 'bold'
																	: 'normal',
															background:
																currentRoute === 'objectives'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														Low-Level Objectives
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/>

									<Can
										feature='microLevelObjective'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'microObjectives'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'microObjectives'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/microObjectives`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'microObjectives'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faBinoculars}
													/>
													<StyledNavLink
														to={`${props.match.url}/microObjectives`}
														style={{
															fontWeight:
																currentRoute === 'microObjectives'
																	? 'bold'
																	: 'normal',
															background:
																currentRoute === 'microObjectives'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														Micro-Level Objectives
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/>

									<Can
										feature='project'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'projects'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'projects'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/projects`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'projects'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faPencilRuler}
													/>
													<StyledNavLink
														to={`${props.match.url}/projects`}
														style={{
															// color: currentRoute === 'projects' ? selectedSidebarColor : nonSelectedSidebarColor,
															fontWeight:
																currentRoute === 'projects' ? 'bold' : 'normal',
															background:
																currentRoute === 'projects'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														Projects
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/>

									<Can
										feature='distribution'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'distribution'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'distribution'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/distribution`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'distribution'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faProjectDiagram}
													/>
													<StyledNavLink
														to={`${props.match.url}/distribution`}
														style={{
															// color: currentRoute === 'distribution' ? selectedSidebarColor : nonSelectedSidebarColor,
															fontWeight:
																currentRoute === 'distribution'
																	? 'bold'
																	: 'normal',
															background:
																currentRoute === 'distribution'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														Distribution
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/> */}

                  <Can
                    feature='questionBank'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'questionbank'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'questionbank'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/questionbank`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'questionbank'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faPencilRuler}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/questionbank`}
                            style={{
                              fontWeight:
                                currentRoute === 'questionbank'
                                  ? 'bold'
                                  : 'normal',
                              background:
                                currentRoute === 'questionbank'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Question Bank
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <>
                    {/* <Can
                feature="email"
                perform="view"
                yes={() => (
                  <li className="d-flex"
                    onClick={() => { 
                      if(window.innerWidth <= 768) { 
                        if(fullsidebar == 'false') { 
                          setFullsidebar('true'); 
                          setBarclick(true); 
                        } 
                      } 
                    }} 
                    style={{ background: currentRoute === 'email' ? sidebarItemBackground : 'transparent', cursor: 'pointer' }}>
                    <Anchor
                      className="d-flex"
                      color={currentRoute === 'email' ? selectedSidebarColor : nonSelectedSidebarColor}
                      to={`${props.match.url}/email`} fullsidebar={fullsidebar} 
                      style={{textDecoration: 'none'}}> 
                    <StyledFontAwesomeIcon 
                      color={currentRoute === 'email' ? selectedSidebarColor : iconColor} 
                      fullsidebar={fullsidebar} 
                      icon={faEnvelope} /> 
                    <StyledNavLink to={`${props.match.url}/email`} 
                      style={{
                        // color: currentRoute === 'email' ? selectedSidebarColor : nonSelectedSidebarColor, 
                        fontWeight: currentRoute === 'email' ? 'bold' : 'normal',
                        background: currentRoute === 'email' ? sidebarTextBackground : 'transparent', 
                        textDecoration: 'none'
                      }} fullSidebar={fullsidebar} > 
                      Email
                    </StyledNavLink> 
                    </Anchor> 
                  </li> 
                )} 
              />  */}
                  </>

                  <Can
                    feature='blog'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'blog'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'blog'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/blog`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'blog'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faChartLine}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/blog`}
                            style={{
                              fontWeight:
                                currentRoute === 'blog' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'blog'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Blog
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <Can
                    feature='survey'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar === 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'bulkEmail'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'bulkEmail'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/bulkEmail`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'bulkEmail'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faMailBulk}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/bulkEmail`}
                            style={{
                              fontWeight:
                                currentRoute === 'bulkEmail'
                                  ? 'bold'
                                  : 'normal',
                              background:
                                currentRoute === 'bulkEmail'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Bulk Email
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  <Can
                    feature='dataAnalysis_1'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'dataanalysis'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'dataanalysis'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/dataanalysis`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'dataanalysis'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faChartLine}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/dataanalysis`}
                            style={{
                              // color: currentRoute === 'dataanalysis' ? selectedSidebarColor : nonSelectedSidebarColor,
                              fontWeight:
                                currentRoute === 'dataanalysis'
                                  ? 'bold'
                                  : 'normal',
                              background:
                                currentRoute === 'dataanalysis'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Core Analysis
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />
                  <Can
                    feature='dataAnalysis_1'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'ticket'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'ticket'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/ticket`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'ticket'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faTicketAlt}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/ticket`}
                            style={{
                              // color: currentRoute === 'dataanalysis' ? selectedSidebarColor : nonSelectedSidebarColor,
                              fontWeight:
                                currentRoute === 'ticket' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'ticket'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Ticket
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  {/* <Can
										feature='dataAnalysis_2'
										perform='view'
										yes={() => (
											<li
												className='d-flex'
												onClick={() => {
													if (window.innerWidth <= 768) {
														if (fullsidebar == 'false') {
															setFullsidebar('true');
															setBarclick(true);
														}
													}
												}}
												style={{
													background:
														currentRoute === 'dataanalysisadvance'
															? sidebarItemBackground
															: 'transparent',
													cursor: 'pointer',
												}}
											>
												<Anchor
													className='d-flex'
													color={
														currentRoute === 'dataanalysisadvance'
															? selectedSidebarColor
															: nonSelectedSidebarColor
													}
													to={`${props.match.url}/dataanalysisadvance`}
													fullsidebar={fullsidebar}
													style={{ textDecoration: 'none' }}
												>
													<StyledFontAwesomeIcon
														color={
															currentRoute === 'dataanalysisadvance'
																? selectedSidebarColor
																: iconColor
														}
														fullsidebar={fullsidebar}
														icon={faChartLine}
													/>
													<StyledNavLink
														to={`${props.match.url}/dataanalysisadvance`}
														style={{
															// color: currentRoute === 'dataanalysisadvance' ? selectedSidebarColor : nonSelectedSidebarColor,
															fontWeight:
																currentRoute === 'dataanalysisadvance'
																	? 'bold'
																	: 'normal',
															background:
																currentRoute === 'dataanalysisadvance'
																	? sidebarTextBackground
																	: 'transparent',
															textDecoration: 'none',
														}}
														fullSidebar={fullsidebar}
													>
														Advance Analysis
													</StyledNavLink>
												</Anchor>
											</li>
										)}
									/> */}

                  <Can
                    feature='user'
                    perform='view'
                    yes={() => (
                      <li
                        className='d-flex'
                        onClick={() => {
                          if (window.innerWidth <= 768) {
                            if (fullsidebar == 'false') {
                              setFullsidebar('true');
                              setBarclick(true);
                            }
                          }
                        }}
                        style={{
                          background:
                            currentRoute === 'users'
                              ? sidebarItemBackground
                              : 'transparent',
                          cursor: 'pointer',
                        }}
                      >
                        <Anchor
                          className='d-flex'
                          color={
                            currentRoute === 'users'
                              ? selectedSidebarColor
                              : nonSelectedSidebarColor
                          }
                          to={`${props.match.url}/users`}
                          fullsidebar={fullsidebar}
                          style={{ textDecoration: 'none' }}
                        >
                          <StyledFontAwesomeIcon
                            color={
                              currentRoute === 'users'
                                ? selectedSidebarColor
                                : iconColor
                            }
                            fullsidebar={fullsidebar}
                            icon={faUsers}
                          />
                          <StyledNavLink
                            to={`${props.match.url}/users`}
                            style={{
                              // color: currentRoute === 'users' ? selectedSidebarColor : nonSelectedSidebarColor,
                              fontWeight:
                                currentRoute === 'users' ? 'bold' : 'normal',
                              background:
                                currentRoute === 'users'
                                  ? sidebarTextBackground
                                  : 'transparent',
                              textDecoration: 'none',
                            }}
                            fullSidebar={fullsidebar}
                          >
                            Users
                          </StyledNavLink>
                        </Anchor>
                      </li>
                    )}
                  />

                  {/* <>
										<Can
											feature='prm'
											perform='view'
											yes={() => (
												<Expandable
													css={`
														display: flex;
													`}
													onClick={() => {
														setCrExpand(!crExpand);
														setCrmClick(!crmClick);
													}}
												>
													<Anchor
														className='d-flex'
														to={
															currentRoute === 'app'
																? `${props.match.url}`
																: `${props.match.url}/${currentRoute}`
														}
														fullsidebar={fullsidebar}
														style={{
															textDecoration: 'none',
															color: nonSelectedSidebarColor,
															background: 'transparent',
														}}
													>
														<FontAwesomeIcon
															style={{ margin: '0px 10px', color: '#1B998B' }}
															icon={faUserTie}
														/>
														<StyledNavLink
															css={`
																background: transparent;
																z-index: -1;
																margin-right: 10px;
																width: 90%;
															`}
															to={`${props.match.url}/users`}
															fullSidebar={fullsidebar}
														>
															<strong style={{ color: 'rgb(52, 58, 64)' }}>
																Customer Relationship
															</strong>
														</StyledNavLink>
														<span>
															{crmClick ? (
																<CrmIcon
																	fullsidebar={fullsidebar}
																	style={{ color: '#1B998B' }}
																	crmclick={crmClick}
																	className='fas fa-chevron-down'
																>
																	{' '}
																</CrmIcon>
															) : (
																<CrmIcon
																	fullsidebar={fullsidebar}
																	style={{ color: '#1B998B' }}
																	crmclick={crmClick}
																	className='fas fa-chevron-right'
																>
																	{' '}
																</CrmIcon>
															)}
														</span>
													</Anchor>
												</Expandable>
											)}
										/>
									</> */}
                </>
                <Can
                  feature='setting'
                  perform='view'
                  yes={() => (
                    <Expandable
                      css={`
                        display: flex;
                      `}
                      onClick={() => {
                        setCrExpand(!crExpand);
                        setCrmClick(!crmClick);
                      }}
                    >
                      <Anchor
                        className='d-flex'
                        to={
                          currentRoute === 'app'
                            ? `${props.match.url}`
                            : `${props.match.url}/${currentRoute}`
                        }
                        fullsidebar={fullsidebar}
                        style={{
                          textDecoration: 'none',
                          color: nonSelectedSidebarColor,
                          background: 'transparent',
                        }}
                      >
                        <FontAwesomeIcon
                          {...crmSettingCSS}
                          fullsidebar={fullsidebar}
                          icon={faUserTie}
                        />
                        <StyledNavLink
                          css={`
                            background: transparent;
                            z-index: -1;
                            margin-right: 10px;
                            width: 90%;
                            margin-top: 3px;
                          `}
                          to={`${props.match.url}/users`}
                          fullSidebar={fullsidebar}
                        >
                          <strong style={{ color: 'rgb(52, 58, 64)' }}>
                            Setting
                          </strong>
                        </StyledNavLink>
                        <span>
                          {crmClick ? (
                            <CrmIcon
                              fullsidebar={fullsidebar}
                              style={{ color: '#1B998B' }}
                              crmclick={crmClick}
                              className='fas fa-chevron-down'
                            >
                              {' '}
                            </CrmIcon>
                          ) : (
                            <CrmIcon
                              fullsidebar={fullsidebar}
                              style={{ color: '#1B998B' }}
                              crmclick={crmClick}
                              className='fas fa-chevron-right'
                            >
                              {' '}
                            </CrmIcon>
                          )}
                        </span>
                      </Anchor>
                    </Expandable>
                  )}
                />

                {crExpand && (
                  <>
                    <Can
                      feature='companyProfile'
                      perform='view'
                      yes={() => (
                        <li
                          className='d-flex'
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              if (fullsidebar == 'false') {
                                setFullsidebar('true');
                                setBarclick(true);
                              }
                            }
                          }}
                          style={{
                            background:
                              currentRoute === 'profile'
                                ? sidebarItemBackground
                                : 'transparent',
                            cursor: 'pointer',
                          }}
                        >
                          <Anchor
                            className='d-flex'
                            color={
                              currentRoute === 'profile'
                                ? selectedSidebarColor
                                : nonSelectedSidebarColor
                            }
                            to={`${props.match.url}/profile`}
                            fullsidebar={fullsidebar}
                            style={{ textDecoration: 'none' }}
                          >
                            <StyledFontAwesomeIcon
                              color={
                                currentRoute === 'profile'
                                  ? selectedSidebarColor
                                  : iconColor
                              }
                              fullsidebar={fullsidebar}
                              icon={faLandmark}
                            />
                            <StyledNavLink
                              to={`${props.match.url}/profile`}
                              fullSidebar={fullsidebar}
                              style={{
                                // color: currentRoute === 'profile' ? selectedSidebarColor : nonSelectedSidebarColor,
                                fontWeight:
                                  currentRoute === 'profile'
                                    ? 'bold'
                                    : 'normal',
                                background:
                                  currentRoute === 'profile'
                                    ? sidebarTextBackground
                                    : 'transparent',
                                textDecoration: 'none',
                              }}
                            >
                              Profile
                            </StyledNavLink>
                          </Anchor>
                        </li>
                      )}
                    />

                    <Can
                      feature='companyProfile'
                      perform='view'
                      yes={() => (
                        <li
                          className='d-flex'
                          onClick={() => {
                            if (window.innerWidth <= 768) {
                              if (fullsidebar === 'false') {
                                setFullsidebar('true');
                                setBarclick(true);
                              }
                            }
                          }}
                          style={{
                            background:
                              currentRoute === 'mail'
                                ? sidebarItemBackground
                                : 'transparent',
                            cursor: 'pointer',
                          }}
                        >
                          <Anchor
                            className='d-flex'
                            color={
                              currentRoute === 'mail'
                                ? selectedSidebarColor
                                : nonSelectedSidebarColor
                            }
                            to={`${props.match.url}/mail`}
                            fullsidebar={fullsidebar}
                            style={{ textDecoration: 'none' }}
                          >
                            <StyledFontAwesomeIcon
                              color={
                                currentRoute === 'mail'
                                  ? selectedSidebarColor
                                  : iconColor
                              }
                              fullsidebar={fullsidebar}
                              icon={faLandmark}
                            />
                            <StyledNavLink
                              to={`${props.match.url}/mail`}
                              fullSidebar={fullsidebar}
                              style={{
                                // color: currentRoute === 'profile' ? selectedSidebarColor : nonSelectedSidebarColor,
                                fontWeight:
                                  currentRoute === 'mail' ? 'bold' : 'normal',
                                background:
                                  currentRoute === 'mail'
                                    ? sidebarTextBackground
                                    : 'transparent',
                                textDecoration: 'none',
                              }}
                            >
                              Mail Config
                            </StyledNavLink>
                          </Anchor>
                        </li>
                      )}
                    />

                    {/* <Can
											feature='contact'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'contact'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'contact'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/contact`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'contact'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/contact`}
															style={{
																// color: currentRoute === 'contact' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'contact'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'contact'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Contact
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='employee'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'employee'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'employee'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/employee`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'employee'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/employee`}
															style={{
																// color: currentRoute === 'employee' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'employee'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'employee'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Employee
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='designation'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'designation'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'designation'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/designation`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'designation'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/designation`}
															style={{
																// color: currentRoute === 'designation' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'designation'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'designation'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Designation
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/> */}

                    {/* <li
											className='d-flex'
											style={{
												background:
													currentRoute === 'lead'
														? sidebarItemBackground
														: 'transparent',
												cursor: 'pointer',
											}}
										>
											<Anchor
												className='d-flex'
												color={
													currentRoute === 'lead'
														? selectedSidebarColor
														: nonSelectedSidebarColor
												}
												to={`${props.match.url}/lead`}
												fullsidebar={fullsidebar}
												style={{ textDecoration: 'none' }}
											>
												<StyledFontAwesomeIcon
													color={
														currentRoute === 'lead'
															? selectedSidebarColor
															: iconColor
													}
													icon={faArrowAltCircleRight}
												/>
												<StyledNavLink
													to={`${props.match.url}/lead`}
													style={{
														// color: currentRoute === 'lead' ? selectedSidebarColor : nonSelectedSidebarColor,
														fontWeight:
															currentRoute === 'lead' ? 'bold' : 'normal',
														background:
															currentRoute === 'lead'
																? sidebarTextBackground
																: 'transparent',
														textDecoration: 'none',
													}}
													fullSidebar={fullsidebar}
												>
													Lead
												</StyledNavLink>
											</Anchor>
										</li>
										<li
											className='d-flex'
											style={{
												background:
													currentRoute === 'opportunity'
														? sidebarItemBackground
														: 'transparent',
												cursor: 'pointer',
											}}
										>
											<Anchor
												className='d-flex'
												color={
													currentRoute === 'opportunity'
														? selectedSidebarColor
														: nonSelectedSidebarColor
												}
												to={`${props.match.url}/opportunity`}
												fullsidebar={fullsidebar}
												style={{ textDecoration: 'none' }}
											>
												<StyledFontAwesomeIcon
													color={
														currentRoute === 'opportunity'
															? selectedSidebarColor
															: iconColor
													}
													icon={faArrowAltCircleRight}
												/>
												<StyledNavLink
													to={`${props.match.url}/opportunity`}
													style={{
														// color: currentRoute === 'opportunity' ? selectedSidebarColor : nonSelectedSidebarColor,
														fontWeight:
															currentRoute === 'opportunity'
																? 'bold'
																: 'normal',
														background:
															currentRoute === 'opportunity'
																? sidebarTextBackground
																: 'transparent',
														textDecoration: 'none',
													}}
													fullSidebar={fullsidebar}
												>
													Opportunity
												</StyledNavLink>
											</Anchor>
										</li> */}

                    {/* <Can
											feature='company'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'company'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'company'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/company`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'company'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/company`}
															style={{
																// color: currentRoute === 'company' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'company'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'company'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Company
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='industry'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'industry'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'industry'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/industry`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'industry'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/industry`}
															style={{
																// color: currentRoute === 'industry' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'industry'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'industry'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Industry
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='branch'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'branch'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'branch'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/branch`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'branch'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/branch`}
															style={{
																// color: currentRoute === 'branch' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'branch' ? 'bold' : 'normal',
																background:
																	currentRoute === 'branch'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Branch
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='department'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'department'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'department'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/department`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'department'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/department`}
															style={{
																// color: currentRoute === 'department' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'department'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'department'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Department
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='brandCategory'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'brandCategory'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'brandCategory'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/brandCategory`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'brandCategory'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/brandCategory`}
															style={{
																// color: currentRoute === 'brandCategory' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'brandCategory'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'brandCategory'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Brand Category
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='campaignCategory'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'campaignCategory'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'campaignCategory'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/campaignCategory`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'campaignCategory'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/campaignCategory`}
															style={{
																// color: currentRoute === 'campaignCategory' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'campaignCategory'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'campaignCategory'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Campaign Category
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='productCategory'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'productCategory'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'productCategory'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/productCategory`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'productCategory'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/productCategory`}
															style={{
																// color: currentRoute === 'productCategory' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'productCategory'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'productCategory'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Product Category
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='targetAudience'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'targetAudience'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'targetAudience'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/targetAudience`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'targetAudience'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/targetAudience`}
															style={{
																// color: currentRoute === 'targetAudience' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'targetAudience'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'targetAudience'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Target Audience
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='campaign'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'campaign'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'campaign'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/campaign`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'campaign'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/campaign`}
															style={{
																// color: currentRoute === 'campaign' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'campaign'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'campaign'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Campaign
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='brand'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'brand'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'brand'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/brand`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'brand'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/brand`}
															style={{
																// color: currentRoute === 'brand' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'brand' ? 'bold' : 'normal',
																background:
																	currentRoute === 'brand'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Brand
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/>

										<Can
											feature='product'
											perform='view'
											yes={() => (
												<li
													className='d-flex'
													onClick={() => {
														if (window.innerWidth <= 768) {
															if (fullsidebar == 'false') {
																setFullsidebar('true');
																setBarclick(true);
															}
														}
													}}
													style={{
														background:
															currentRoute === 'product'
																? sidebarItemBackground
																: 'transparent',
														cursor: 'pointer',
													}}
												>
													<Anchor
														className='d-flex'
														color={
															currentRoute === 'product'
																? selectedSidebarColor
																: nonSelectedSidebarColor
														}
														to={`${props.match.url}/product`}
														fullsidebar={fullsidebar}
														style={{ textDecoration: 'none' }}
													>
														<StyledFontAwesomeIcon
															color={
																currentRoute === 'product'
																	? selectedSidebarColor
																	: iconColor
															}
															fullsidebar={fullsidebar}
															icon={faArrowAltCircleRight}
														/>
														<StyledNavLink
															to={`${props.match.url}/product`}
															style={{
																// color: currentRoute === 'product' ? selectedSidebarColor : nonSelectedSidebarColor,
																fontWeight:
																	currentRoute === 'product'
																		? 'bold'
																		: 'normal',
																background:
																	currentRoute === 'product'
																		? sidebarTextBackground
																		: 'transparent',
																textDecoration: 'none',
															}}
															fullSidebar={fullsidebar}
														>
															Product
														</StyledNavLink>
													</Anchor>
												</li>
											)}
										/> */}
                  </>
                )}
              </StyledList>
            </Sidebar>

            {/* Body */}
            <Apps
              fullSidebar={fullsidebar}
              onClick={() => {
                setviewModal(false);
              }}
            >
              <ModalContainer view={viewModal}>
                <ModalArrow />
                <Modal>
                  <ModalDiv_Name>
                    {/* <img alt="avatar" src={Avatar} /> */}
                    <div className='ml-2 mt-1' style={{ width: '40px' }}>
                      <i
                        style={{ color: '#1B988B' }}
                        className='fas fa-user fa-3x'
                      ></i>
                    </div>
                    <div className='mt-1'>
                      <span>
                        {'firstName' in user && user.firstName}{' '}
                        {'lastName' in user && user.lastName}
                      </span>
                      <span>{'email' in user && user.email}</span>
                    </div>
                  </ModalDiv_Name>

                  {/* <ModalDiv_ViewProfile to={`${props.match.url}/userProfile`}> */}
                  <ModalDiv_ViewProfile
                    to={`${props.match.url}/changePassword`}
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      css={`
                        font-size: 15px;
                        margin-right: 7px;
                        color: #1b998b;
                        ${ModalDiv_ViewProfile}:hover & {
                          color: white;
                        }
                        @media only screen and (max-width: 425px) {
                          font-size: 9px;
                        }
                      `}
                    />
                    Change Password
                  </ModalDiv_ViewProfile>
                  <ModalDiv_Logout
                    onClick={() => {
                      logOut();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPowerOff}
                      css={`
                        font-size: 12px;
                        margin-right: 7px;
                        color: #1b998b;
                        ${ModalDiv_Logout}:hover & {
                          color: white;
                        }
                        @media only screen and (max-width: 425px) {
                          font-size: 9px;
                        }
                      `}
                    />
                    Logout
                  </ModalDiv_Logout>
                </Modal>
              </ModalContainer>
              <Switch>
                {/* <Can
              feature="dashboard"
              perform="view"
              yes={() => (
                <Route path={`${props.match.path}`} exact component={Dashboard} />
              )}
              no={() => <Redirect to="/" />}
            /> */}
                <Route
                  path={`${props.match.path}`}
                  exact
                  component={Dashboard}
                />
                <Route
                  path={`${props.match.path}/profile`}
                  component={Profile}
                />
                <Route
                  path={`${props.match.path}/projects`}
                  component={Projects}
                />
                <Route
                  path={`${props.match.path}/questionbank`}
                  component={QuestionBank}
                />
                <Route
                  path={`${props.match.path}/distribution`}
                  render={() => <Distribution sidebarState={fullsidebar} />}
                />
                {/* <Route path={`${props.match.path}/distribution/list`} exact component={List} />
            <Route path={`${props.match.path}/distribution/editsortandpreview`} exact component={EditSortAndPreview} /> */}
                <Route path={`${props.match.path}/email`} component={Email} />
                <Route path={`${props.match.path}/survey`} component={Survey} />
                <Route
                  path={`${props.match.path}/userProfile`}
                  component={UserProfile}
                />
                <Route
                  path={`${props.match.path}/changePassword`}
                  component={ChangePassword}
                />
                <Route
                  path={`${props.match.path}/dataanalysis`}
                  component={DataAnalysis}
                />
                <Route
                  path={`${props.match.path}/dataanalysisadvance`}
                  component={DataAnalysisTwo}
                />
                <Route
                  path={`${props.match.path}/feature`}
                  component={Feature}
                />
                <Route
                  path={`${props.match.path}/broadObjectives`}
                  component={BroadObjectives}
                />
                <Route
                  path={`${props.match.path}/microObjectives`}
                  component={MicroObjectives}
                />
                <Route
                  path={`${props.match.path}/objectives`}
                  component={Objectives}
                />
                <Route path={`${props.match.path}/users`} component={Users} />
                <Route
                  path={`${props.match.path}/contact`}
                  component={Contact}
                />
                <Route
                  path={`${props.match.path}/employee`}
                  component={Employee}
                />
                <Route
                  path={`${props.match.path}/designation`}
                  component={Designation}
                />
                <Route path={`${props.match.path}/lead`} component={Lead} />
                <Route
                  path={`${props.match.path}/opportunity`}
                  component={Opportunity}
                />
                <Route
                  path={`${props.match.path}/company`}
                  component={Company}
                />
                <Route
                  path={`${props.match.path}/campaignCategory`}
                  component={CampaignCategory}
                />
                <Route
                  path={`${props.match.path}/industry`}
                  component={Industry}
                />
                <Route path={`${props.match.path}/branch`} component={Branch} />
                <Route
                  path={`${props.match.path}/department`}
                  component={Department}
                />
                <Route path={`${props.match.path}/blog`} component={Blog} />
                <Route
                  path={`${props.match.path}/bulkEmail`}
                  component={BulkEmail}
                />
                <Route path={`${props.match.path}/ticket`} component={Ticket} />
                <Route
                  path={`${props.match.path}/mail`}
                  component={MailConfig}
                />
                <Route
                  path={`${props.match.path}/brandCategory`}
                  component={BrandCategory}
                />
                <Route
                  path={`${props.match.path}/productCategory`}
                  component={ProductCategory}
                />
                <Route
                  path={`${props.match.path}/targetAudience`}
                  component={TargetAudience}
                />
                <Route
                  path={`${props.match.path}/campaign`}
                  component={Campaign}
                />
                <Route path={`${props.match.path}/brand`} component={Brand} />
                <Route
                  path={`${props.match.path}/product`}
                  component={Product}
                />
                <Route
                  path={`${props.match.path}/channels`}
                  component={Channels}
                />
                {/* <Route path={`${props.match.path}/channelstwo`} component={ChannelsTwo} />  */}
                <Route component={() => <div>Error 404</div>} />
              </Switch>
            </Apps>
          </div>
        )
      : localStorage.getItem('jwtType') === 'Device'
      ? () => {
          const channelType = 'device';
          const subChannelId = localStorage.getItem('jwtID');

          axios
            .get(`${baseUrl}/api/channels/device/individual/${subChannelId}`)
            .then(
              (res) => {
                setDisId(res.data[0].distributionId);
                setCreatedByCompanyID(res.data[0].createdByCompanyID);
                setLoadingDevice(false);
              },
              (err) => {
                setLoadingDevice(false);
              }
            );

          if (loadingDevice) {
            return 'loading';
          }
          return (
            <div>
              <TopBar>
                <FontAwesomeIcon
                  icon={faBars}
                  css={`
                    border-sizing: border-box;
                    float: left;
                    font-size: 15px;
                    margin: 10px 0px 10px 10px;
                    display: none;
                    @media only screen and (max-width: 425px) {
                      display: block;
                    }
                  `}
                  onClick={() => {
                    toggle(!view);
                  }}
                />
                <img
                  alt='Pex Logo'
                  src={Logo}
                  css={`
                    box-sizing: border-box;
                    float: left;
                    padding: 10px;
                    margin-left: 10px;
                    height: 100%;
                  `}
                />
                <User
                  onClick={() => {
                    logOut();
                  }}
                >
                  UserLogout
                  <FontAwesomeIcon
                    icon={faPowerOff}
                    css={`
                      font-size: 12px;
                      margin-left: 5px;
                      color: #243350;
                      ${User}:hover & {
                        color: red;
                      }
                      @media only screen and (max-width: 425px) {
                        padding-top: 3px;
                        font-size: 8px;
                      }
                    `}
                  />
                </User>
              </TopBar>

              <DeviceBody>
                <Route
                  path={`${props.match.url}/preview`}
                  render={() => (
                    <Form
                      userType='device'
                      disId={disId}
                      channelType={channelType}
                      subChannelId={subChannelId}
                      createdByCompanyID={createdByCompanyID}
                    />
                  )}
                />
              </DeviceBody>
            </div>
          );
        }
      : localStorage.getItem('jwtType') === 'Admin'
      ? () => {
          console.log('Hello !!!!');
          return (
            <div
              css={`
                padding-top: 5px;
                position: relative;
                width: 100%;
                top: 60px;
                @media only screen and (max-width: 768px) {
                  top: 35px;
                }
              `}
            >
              <Route exact path={`${props.match.url}`} component={Preview} />
            </div>
          );
        }
      : () => <div>Nothing</div>;
  return (
    <Body style={{ marginBottom: '0px' }}>
      <Content />
    </Body>
  );
};

// export default React.memo(withRouter(MainPage))
const Page = React.memo(withRouter(MainPage));

function Index(props) {
  return (
    <Provider store={store}>
      <Page />
    </Provider>
  );
}

export default Index;
