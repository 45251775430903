import React from 'react';
import styled from 'styled-components'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'

const SingleLine = styled.input`
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius:3px;
  background-color:#CFD8DC;
  resize: none;
  width: 50%;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  outline: none;
  padding: 10px;
  margin-top: 15px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

const DateComponent = () => {
    return (
        <>    
            <SingleLine disabled placeholder="Datepicker" type="text" />
        </>
    );
}

export default DateComponent;