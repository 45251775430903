import React from 'react'
import TableView from './components/table_view/index'
import CreateButton from './components/create_designation/createButton'

const Designation = () => { 
    return ( 
        <div className="container"> 
            <CreateButton /> 
            <TableView /> 
        </div> 
    ) 
} 

export default Designation; 
