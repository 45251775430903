import React, { useState } from 'react'
import QRCode from 'qrcode.react';
import { pexUrl } from '../../../../url/index'

const QrCode = props => {
    const [active, setActive] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false)

    const downloadQR = value => {
        const imageFile = `${value}.png`
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = imageFile;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    // const updateQrCode = ({ distributionId, hashId }) => {

    //     const canvas = document.getElementById("qrcode");
    //     const pngUrl = canvas
    //         .toDataURL("image/png")
    //         .replace("image/png", "image/octet-stream");
    //     props.genQrCode(distributionId, { 'image': pngUrl, 'link': `${pexUrl}/qrcode/${hashId}` });
    // }

    return (
        <div>
            <h4>QR Code</h4>
            <label className="dislabel" style={{ margin: '10px' }}>
                Active QR Code
            <input
                    name="active"
                    type="checkbox"
                    checked={active}
                    onChange={event => {
                        const target = event.target;
                        const value = target.type === 'checkbox' ? target.checked : target.value;
                        setActive(value)
                        props.setQrCode(value)
                    }
                    }
                />
            </label>
            {
                props.qRCode.hasOwnProperty('hashId') &&
                <button className="generate" onClick={() => { setShowQrCode(true); props.genQrCode(props.disId); }}>Generate QR Code</button>
            }

            {showQrCode &&
                <div>
                    <QRCode
                        id="qrcode"
                        value={`${pexUrl}/qrcode/${props.qRCode.hashId}`}
                        size={250}
                        level={"H"}
                        includeMargin={true}
                    />
                    <button className="generate" onClick={() => downloadQR(props.disId)}> Download </button >
                    {/* <a style={{ color: 'rgba(0,0,0,.8)', margin: '10px' }} onClick={() => updateQrCode(props.qRCode)}> Update </a> */}
                </div>
            }

        </div>

    )
}

export default QrCode