import React from 'react'
import image from './assets/brand.png'
import brandTracking from './assets/brand_tracking.png'
import brandPromotion from './assets/Brand_Promotional.png'
import banner from './assets/brand_experience.png'
import {css} from 'glamor'

function Brand() { 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 

  const imageHideOnMobile = css({'@media(max-width: 767px)': { display: 'none !important' }})

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Brand experience banner" {...bannerWidth} /> 
      </div> 

      <p {...text}> 
        Let’s think whenever the word tooth-paste is heard, which brand comes to mind? Or thinking about cold drinks which brand’s one provokes our thirst? By relying more on some specific brands aren’t we biased decision-makers? Brand experience is what pulls out customer’s thoughts about the brands. A brand can be connected with its customers emotionally, financially or conveniently. Everyone’s perspectives might be different for a brand but a brand should have a common strategic plan to cover the attention of the target consumers as much as possible.  
      </p>

      <p {...text}>
        It is not easy to make or change brand image overnight. Understanding the market, customer demands, customer’s priorities, niche group, market segmentation, etc. every entity is equally important to evaluate for decorating brand experience. A brand which is not known to anyone, which doesn’t have any promotional activities, which is behind the market position cannot take control over its customer. Any business is not successful at all without having a strong customer base. Brand experience helps to reveal the strengths and weaknesses it has, the threats it can face and the opportunities it can spin round to success. 
      </p> 
      {/* Image */} 
      {/* <div className="text-center"> 
        <img src={image} alt="brand experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br />  */}

      <p {...text}> 
        ProlificXM is the ultimate tool that can bring out the most accurate brand experience through real-time data analysis. Brand Experience can map the journey of a customer from its awareness about the brand to loyalty towards it. ProlificXM can track a brand’s overall health condition through brand promotional. Though the analysis of how each and every attribute regarding brand is responding can be determined. Market research is very much important to know about the brand’s position in the competitive marketplace. ProlificXM pulls out the insights of category awareness, price premium, competitor research, market segmentation etc. to determine the market strength of the brand. Net Value Score (NVS) is there to indicate the perceived value of the brand which can help to make better decisions for the brand in order to serve the best brand experience. 
      </p> 


      <h1 {...header}>Brand Tracking</h1> 
      <div className="text-center"> 
        <img src={brandTracking} alt="brand experience" 
          className="img-fluid d-sm-inline d-md-none d-lg-none" style={{maxHeight: '300px'}} /> 
      </div> 
      <div className="row"> 
        <div className="col-sm-12 col-md-6 col-lg-6"> 
          <p {...text}> 
            A brand cannot be made overnight. It takes years for a company to make any signature products or services a renowned brand. So many efforts, ideas, resources, struggles need to be put to gather to make a brand a successful one. Think once, there are so many soft drinks in the market but which one pops up in your mind in terms of taste, quality, and popularity? One of the fastest names will be “Cocacola”. Have you ever think why people choose it without any doubt and delay? People are so much aware of it and it is so widely consumed that no one puts a single objection on it. Cocacola has earned this reputation being consistent with their served quality. Coca-Cola has managed to create a large customer base who is not only loyal to them but advocates them to others which shapes up the image of Cocacola so strong.
          </p> 
        </div> 
        <div className="text-center col-md-6 col-lg-6" {...imageHideOnMobile}> 
          <img src={brandTracking} alt="brand experience" 
            className="img-fluid" style={{maxHeight: '300px'}} /> 
        </div> 
      </div> 





      
      {/* Promotion */}
      <> 
        <h1 {...header}>Brand Promotional</h1> 
        <div className="text-center"> 
          <img src={brandPromotion} alt="brand experience" 
            className="img-fluid d-sm-inline d-md-none d-lg-none" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row"> 
          <div className="text-center col-md-6 col-lg-6" {...imageHideOnMobile}> 
            <img src={brandPromotion} alt="brand experience" 
              className="img-fluid" style={{maxHeight: '300px'}} /> 
          </div> 
          <div className="col-sm-12 col-md-6 col-lg-6"> 
            <p {...text}> 
              “A brand for a company is like a reputation for a person. You earn a reputation by trying to do hard things well” – Jeff Bezos. Brand just doesn’t build up with a satisfying and renowned product or service. Its overall contents should look good. A company’s name, logo, tag line, package offerings, advertisements, promotional messages all contribute to promoting a brand’s standard. To spread wider in the market and grab the attention of maximum customers, brands must go through proper promotional processes. For promoting a brand, business strategy must be drawn with proper innovation and creation so that no one can come up to compete. Social media, online promotions, quiz-contests, promotional offers/gifts, etc. there are so many ways to go to your targeted audience to let them know about your business and build the brand.
            </p> 
          </div> 
        </div> 
      </> 


      <h1 {...header}>Market Research</h1>
      <p {...text}>
        You have some great ideas in your mind about developing some new products or services. You really want to put all of your resources together to make it launched in the market. But have you thought the product or service you are assuming great, it might not have any demand or need for the customers in the market. Understanding the market is very much important to place your business in such a position from where it can functionally run. Market is a very complicated place to determine. Customer needs, market demands are not static at all. The targeted group which is interested in grabbing dietary food today may change their interest in junk food tomorrow. So keeping that change in mind market forecast should be made. Market research is more about market prediction. The business seed you are planting today will start giving fruits after a long period. So if you cannot predict that future time, your brand cannot bloom in the market at all. 
      </p> 
    </div> 
  ) 
} 

export default Brand; 
