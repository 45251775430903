//A function used to identify what type of question to add to array of questions in
//store. Returns the question structure based on a string input of question type
import {
  mcq, dropdown,
  dichotomy, thumbsUpDown,
  checkbox, likertScale,
  ratingScale, starRating,
  thumbsUpRating, loveRating,
  nps, date, daterange,
  textBox, slider, numeric, demography
} from '../../questionStructure'


const questionSelect = type => {
  switch (type) {
    case "multipleChoice":
      return {
        ...mcq
      }
    case "dropDown":
      return {
        ...dropdown
      }
    case "dichotomy":
      return {
        ...dichotomy
      }
    case "thumbsUpDown":
      return {
        ...thumbsUpDown
      }
    case "checkbox":
      return {
        ...checkbox
      }
    case "likertScale":
      return {
        ...likertScale
      }
    case "ratingScale":
      return {
        ...ratingScale
      }
    case "starRating":
      return {
        ...starRating
      }
    case "thumbsUpRating":
      return {
        ...thumbsUpRating
      }
    case "loveRating":
      return {
        ...loveRating
      }
    case "nps":
      return {
        ...nps
      }
    case "textBox":
      return {
        ...textBox
      }
    case "slider":
      return {
        ...slider
      }
    case "numeric":
      return {
        ...numeric
      }
    case "date":
      return {
        ...date
      }
    case "daterange":
      return {
        ...daterange
      }
    case "demography":
      return {
        ...demography
      }
    default:
      return {
        ...mcq
      }
  }
}

export default questionSelect