import React from 'react'
import Information from './components/Information'
import ChannelsConfig from './components/ChannelsConfig'

const Profile = () => {
  return (
    <React.Fragment>
      <Information />
      <ChannelsConfig />
    </React.Fragment>
  )
}

export default Profile