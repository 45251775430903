import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 5px 10px;
  margin: 20px 0 0 0;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-shadow: 2px 2px 5px 1px grey;
  `
  
export const Ques = styled.div`
  position: relative;
  font-size: 25px;
  margin-left: 5px;   
  box-sizing: border-box;
`

export const Checkbox = styled.input`
  margin: 5px;
`