import {
  // SELECT_CHANNEL, 
  TOGGLE_VIEW,
  FETCH,
  // SUB_CHANNEL_LIST_RECEIVE,
  // SELECT_SUB_CHANNEL,
  PROJECT_RECEIVE,
  QUESTION_RECIEVE,
  SELECT_PROJECT,
  RESET,
  EDIT_DISTRIBUTION_CONFIG
} from '../actions/const'

const configReducer = (state = { // placing the initial values
  // channel: "",
  // subChannel: "",
  project: "",
  // subChannelList: [],
  projectList: [],
  questionList: [],
  isLoading: {
    // subChannel: false,
    project: false,
    question: false
  },
  view: true,
}, action) => {
  switch (action.type) {

    // case SELECT_CHANNEL:
    //   // console.log(action.channel);
    //   return {
    //     ...state,
    //     channel: action.channel,
    //     subChannel: "",
    //     project: "",
    //     subChannelList: [],
    //     questionList: [] 
    //   }

    case TOGGLE_VIEW: {

      const { view } = state

      return {
        ...state,
        view: !view
      }
    }

    case FETCH: {
      const { what } = action
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          [what]: true
        }
      }
    }

    // case SUB_CHANNEL_LIST_RECEIVE:{
    //   return{
    //     ...state,
    //     isLoading: {
    //       ...state.isLoading,
    //       subChannel: false
    //     },
    //     subChannelList: action.subChannelList
    //   }
    // }

    // case SELECT_SUB_CHANNEL:{
    //   return{
    //     ...state,
    //     subChannel: action.subChannel,
    //     project: "",
    //     questionList: [],
    //   }
    // }

    case PROJECT_RECEIVE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          project: false
        },
        projectList: action.projectList
      }
    }

    case SELECT_PROJECT: {
      return {
        ...state,
        project: action.project
      }
    }

    case QUESTION_RECIEVE: {
      return {
        ...state,
        isLoading: {
          ...state.isLoading,
          question: false
        },
        questionList: action.questionList
      }
    }

    case RESET: {
      return {
        ...state,
        // channel: "",
        // subChannel: "",
        project: "",
        // subChannelList: [],
        questionList: []
      }
    }

    case EDIT_DISTRIBUTION_CONFIG:
      return {
        ...state,
        project: action.payload.question.questionBank.projectOrder[0]
      }

    default:
      return state
  }
}

export default configReducer