import React from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
// import {composeWithDevTools} from 'remote-redux-devtools';
import { Provider, } from 'react-redux'

import reducer from './redux/reducers'
import FormBody from './container/FormBody'

function App(props) {

  const { isPreview, channelType, subChannelId, disId, userType, header, createdByCompanyID } = props

  // console.log(channelType, subChannelId)

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, composeEnhancers(
    applyMiddleware(thunk)
  ))

  return (
    <Provider store={store}>
      <FormBody
        isPreview={isPreview ? isPreview : false}
        channelType={channelType}
        subChannelId={subChannelId}
        disId={disId}
        userType={userType}
        header={header}
        createdByCompanyID={createdByCompanyID}
      />
    </Provider>

  );
}

export default App;
