import React from 'react';
import './InfoBar.css';
import ProgressBar from '../progressbar/ProgressBar';

const InfoBar = (props) => {
    
    const {value, title, color} = props;
    const progressBarHeight = props.progressBarHeight ? `${props.progressBarHeight}px` : '30px';
    const valueFontSize = props.valueFontSize ? `${props.valueFontSize}px` : '18px';
    const textFontSize = props.textFontSize ? `${props.textFontSize}px` : '12px';

    const style = {
        value:{color: color, fontSize: valueFontSize},
        text: { fontSize: textFontSize}
    }

    return (
        <div className="info-bar">
            <div className="row">
                <div className="col-4 align-self-center pr-0">
                    <div className="text-left info">
                        <small className="my-0 info-value" style={style.value}>{`${value}%`}</small>
                        <small className="my-0 info-text" style={style.text}>{title}</small>
                    </div>
                </div>
                <div className="col-8 align-self-center">
                    <ProgressBar value={value} bg={color} height={progressBarHeight} />
                </div>
            </div>
        </div>
    );
}
export default InfoBar;