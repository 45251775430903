import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Card, CardBody, Col, Button, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label
} from 'reactstrap'; 

import { 
    getBrandCategoryDetails, 
    updateBrandCategoryById, 
    backButtonFunction, 
    deleteBrandCategoryById, 
    getParentsOfBrandCategoriesTrueOnly, 
    getALLBrandCategories 
} from '../../store/actions/actionCreator' 

import { deleteBrandCetagory } from '../../../../../url/prmUrl'; 
import Axios from 'axios'
import showToast from '../../../../../components/Toast/toast'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb';
import '../buttonCss.css'


function Details(props) { 
    let dispatch = useDispatch() 
    let { dataDetails: state, allData, parentsTrueOnly: parents } = useSelector(state => state.brandCategory) 
    
    try { 
        if(!Object.keys(allData).length) { allData.data = [] } 
        if(!Object.keys(parents).length) { parents.data = [] } 
        if(Object.keys(parents).length) { 
            // Sorting Alphabetically 
            let compare = (a, b) => { 
                const nameA = a.name.toUpperCase(); 
                const nameB = b.name.toUpperCase(); 

                let comparison = 0; 
                if (nameA > nameB) { comparison = 1; } 
                else if (nameA < nameB) { comparison = -1; } 
                return comparison; 
            } 
            parents.data.sort(compare); 
        } 
    } catch (error) { } 

    let parentName = ''
    if(state.parentId) {
        for(let i = 0; i < parents.data.length; i++) {
            if(state.parentId === parents.data[i]._id) {
                parentName = parents.data[i].name
            }
        }
    }

    let idOfParent = state.parentId || ''; 
    
    
    const { className } = props;
    let [isEditable, setIsEditable] = useState(false)
    let [showModal, setShowModal] = useState(false) 
    let [canNotDeleteMsg, setCanNotDeleteMsg] = useState(false) 

    const backToFeaturePage = () => { 
        props.history.push('/app/brandCategory'); 
    } 

    const DeleteToast = () => showToast('Deleted Successfully') 
    const UpdateToast = () => showToast('Updated Successfully') 
    const ErrorToast = () => showToast('Server Error') 
    const DependencyToast = () => showToast('Cannot Delete. There are dependency') 


    let { id } = props.match.params; 

    useEffect(() => { 
        dispatch(getBrandCategoryDetails(id, ErrorToast)) 
        dispatch(getParentsOfBrandCategoriesTrueOnly()); 
        dispatch(getALLBrandCategories()); 
    }, [])


    const NowDelete = async () => { 
        try { 
            Axios.delete(deleteBrandCetagory(id)) 
                .then(res => { 
                    if(res.data.isDeletable) { 
                        DeleteToast() 
                        dispatch(deleteBrandCategoryById(state)); 
                        props.history.push('/app/brandCategory'); 
                    } 
                    else { 
                        DependencyToast() 
                        setCanNotDeleteMsg(true) 
                        props.history.push('/app/brandCategory');
                    } 
                }) 
                .catch(err => { 
                    ErrorToast(); 
                }) 
        } 
        catch (error) {} 
    } 


    let emptyName = ''
    return (
        <> 
            {
                showModal &&
                <Modal isOpen={showModal} className={className}>
                    <ModalHeader>Delete Brand Category</ModalHeader>
                    <ModalBody>
                        { 
                            !canNotDeleteMsg && <> Are you sure, you want to delete ? </>
                        } 
                        {
                            canNotDeleteMsg &&
                            <p style={{ color: 'red', fontSize: '18px', textAlign: 'left' }}>
                                Cannot delete. <br /> 
                                This brand category is currently in use! 
                            </p> 
                        } 
                    </ModalBody> 
                    <ModalFooter> 
                        {
                            !canNotDeleteMsg &&
                            <>
                                <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                                    className="btnHover" onClick={NowDelete}> 
                                    Delete
                            </Button>
                            </>
                        }

                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                            className="btnHover" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button> 
                    </ModalFooter> 
                </Modal> 
            } 

            <div className="container"> 
                <Breadcrumb path="Brand Category" slug={state.name} link="/app/brandCategory" backButtonFunction={backButtonFunction}/> 
                {/* Button --> Back, Edit/save, Delete */} 
                <Button
                    size="sm"
                    className="btnHover"
                    style={{ margin: '10px 0px 10px 0px', background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    onClick={() => {
                        backToFeaturePage()
                        dispatch(backButtonFunction())
                    }}>
                    Back
                </Button> 
                {
                    !isEditable &&
                    <Button
                        size="sm" className="ml-2 btnHover"
                        style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                        onClick={() => setIsEditable(true)}>
                        Edit
                    </Button>
                }
                <Button
                    size="sm" className="ml-2 btnHover"
                    style={{ 
                        background: '#1B998B',
                        borderColor: '#1B998B',
                        color: '#fff' 
                    }} 
                    onClick={ () => setShowModal(true) }>
                    Delete 
                </Button> 


                {/* Data on card view */}
                <Card>
                    <CardBody> 
                    { 
                        state.name && 
                        <Formik 
                            initialValues={{ name: state.name, status: state.active, description: state.description, parentId: idOfParent}}
                            validationSchema={ 
                                Yup.object().shape({ 
                                    name: Yup 
                                        .string().required("Required").trim().lowercase()
                                        .notOneOf(Object.keys(allData).length ? allData.data.map(item => {
                                            if(item.name.toLowerCase() !== state.name.toLowerCase()) {
                                                return item.name.toLowerCase() 
                                            } 
                                        }) : [], "Already exists"), 
                                }) 
                            } 
                            onSubmit={(values) => { 
                                let edittedData; 
                                if(values.parentId) { 
                                    edittedData = { 
                                        name: values.name.trim(),
                                        description: values.description,
                                        active: values.status,
                                        parentId: values.parentId 
                                    } 
                                } 
                                else { 
                                    edittedData = { 
                                        name: values.name.trim(), 
                                        description: values.description, 
                                        active: values.status, 
                                    }  
                                } 
                                
                                dispatch(updateBrandCategoryById(id, edittedData, UpdateToast, ErrorToast));
                                setIsEditable(false);
                            }}>
                            {
                                props => (
                                    <Form onSubmit={props.handleSubmit}>
                                        <FormGroup row>
                                            <Label htmlFor="name" sm={2} style={{ marginTop: '-7px' }}>
                                                Name: <span style={{color: 'red'}}>{isEditable && ` *`}</span>
                                            </Label>
                                            <Col sm={4}> 
                                                { 
                                                    isEditable 
                                                    ? <> 
                                                        <Input 
                                                            type="text" name="name" id="name" 
                                                            style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                                            value={props.values.name} 
                                                            onChange={(e) => { 
                                                                if(e.target.value === '') { emptyName = 'empty' } 
                                                                if(e.target.value) { emptyName = '' } 
                                                                props.handleChange(e) 
                                                            }} 
                                                            onBlur={props.handleBlur} 
                                                        /> 
                                                        <span style={{display: 'none'}}> 
                                                            {emptyName === 'empty' ? props.errors.name = 'Required' : null}
                                                        </span> 
                                                        
                                                        <p style={{ 
                                                            color: 'red', fontSize: '12px', marginLeft: '5px', 
                                                            textAlign: 'left', fontWeight: 'bold' 
                                                        }}> {props.errors.name} </p> 
                                                    </> 
                                                    : <span> {state.name} </span> 
                                                } 
                                            </Col> 
                                            
                                            { 
                                                isEditable ?
                                                <Label htmlFor="status"> Status </Label> 
                                                :
                                                <Label> Status </Label> 
                                            } 
                                            <Col sm={4}> 
                                            { 
                                                isEditable ?
                                                <Input
                                                    type="checkbox" name="status" id="status"
                                                    className="form-check-input"
                                                    checked={props.values.status} 
                                                    onChange={props.handleChange} 
                                                    onBlur={props.handleBlur} 
                                                /> 
                                                : 
                                                <Input 
                                                    type="checkbox" name="status" id="status"
                                                    className="form-check-input"
                                                    readOnly={true}
                                                    checked={state.active} 
                                                /> 
                                            }
                                            </Col> 
                                        </FormGroup> 

                                        <FormGroup row> 
                                            <Label htmlFor="parentId" sm={2} style={{ marginTop: '-7px' }}>
                                                Parent Category 
                                            </Label> 
                                            {
                                                isEditable ? 
                                                <Col sm={4}> 
                                                    <Input 
                                                        type="select" name="parentId" id="parentId" 
                                                        value={props.values.parentId} 
                                                        onChange={props.handleChange} 
                                                        onBlur={props.handleBlur} > 
                                                        <option value="" disabled defaultValue hidden>Select...</option> 
                                                        { 
                                                            parents.data.map((obj) => { 
                                                                return ( <option key={obj._id} value={obj._id}> {obj.name} </option> ) 
                                                            }) 
                                                        } 
                                                    </Input> 
                                                </Col> 
                                                : 
                                                <Col sm={4}> 
                                                    { parentName ? parentName : null } 
                                                </Col> 
                                            } 
                                        </FormGroup>

                                        <FormGroup row>
                                            <Label htmlFor="description" sm={2} style={{ marginTop: '-7px' }}>
                                                Description
                                            </Label>
                                            <Col sm={10}>
                                                {
                                                    isEditable ?
                                                        <Input
                                                            rows="3" type="textarea"
                                                            name="description" id="description"
                                                            value={props.values.description}
                                                            onChange={props.handleChange}
                                                            onBlur={props.handleBlur}
                                                        />
                                                        :
                                                        <span>
                                                            {state.description}
                                                        </span>
                                                }
                                            </Col>
                                        </FormGroup>

                                        { 
                                            isEditable && 
                                            <Button
                                                type="submit" size="sm" className="btnHover"
                                                style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}>
                                                Save 
                                            </Button> 
                                        } 
                                    </Form> 
                                ) 
                            } 
                        </Formik> 
                    } 
                    </CardBody> 
                </Card> 
            </div> 
        </> 
    ) 
} 

export default withRouter(Details)




