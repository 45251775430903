import React from 'react'
import { connect } from "react-redux"
import { Question, SingleLine, Input} from './Styles'

const SliderQuestion = ({ item, editField, hasError, isReadOnly }) => {
    return (
        <>
            <div className="row w-100 ml-0 mt-4 mb-3">
                <div className="col pl-0">
                    <h6 style={{ fontSize: "12px", fontWeight: "700", color: "#424242" }}>Min value</h6>
                    <Input 
                        value={item.min == null ? "" : item.min} 
                        type="number" 
                        name="minLabel" 
                        placeholder="min value"
                        onChange={event => { editField(item.id, 'min', event.target.value) }} 
                        disabled={isReadOnly}
                        hasError={hasError && item.min === null}
                    />
                </div>
                <div className="col pr-0">
                    <h6 style={{ fontSize: "12px", fontWeight: "700", color: "#424242" }}>Max value</h6>
                    <Input 
                        value={item.max == null ? "" : item.max} 
                        type="number" 
                        name="maxLabel" 
                        placeholder="max value"
                        onChange={event => { editField(item.id, 'max', event.target.value) }} 
                        disabled={isReadOnly}
                        hasError={hasError && item.max === null}
                    />
                </div>
            </div>
            <SingleLine disabled placeholder="(non-editable demo) Example: Numeric Value" type="Number" Step="any" />
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        hasError: state.error.range,
        disStatus: state.disStatus,
        isReadOnly: state.isReadOnly
    }
}

export default connect(mapStateToProps, null)(SliderQuestion);