import React, { useState, useEffect } from 'react';
import { MainBody, MainBodyDiv } from './styles';
// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
// import { setSurveyConfigStep } from '../../redux/actions/index'
import {
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  fetchChannels,
  surveyQuestionSaveMessageTrue,
} from '../../redux/actions';
import QuestionSurvey from '../../../QuestionSurvey';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Step from '../../components/Step/index';
import baseUrl from '../../../../url';
import axios from 'axios';
// import { Link } from 'react-router-dom'

const SelectPage = (props) => {
  const [surveyUpdateMessage, setSurveyUpdateMessage] = useState(false);
  let projectId = useSelector((state) => state.survey.projectId);
  let disStatus = useSelector((state) => state.question.status);
  let isReadOnly = useSelector((state) => state.question.isReadOnly);
  let disID = useSelector((state) => state.question.distributionId);
  let dispatch = useDispatch();

  useEffect(() => {
    const fetchDistDataOnReload = async (surveyid) => {
      const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`);
      dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data });
      dispatch(setSurveyProjectStep());

      const disid = disId.data.distributionId;
      if (disid) {
        axios
          .get(`${baseUrl}/api/distribution/details/${disid}`)
          .then((res) => {
            dispatch(editDistributionQuestion(res.data, disid));
            dispatch(editDistributionConfig(res.data));
            dispatch(
              selectProjectAndFetch(
                res.data.question.questionBank.projectOrder[0]
              )
            );
            dispatch(projectFetch(localStorage.getItem('jwtProfileId')));

            if ('activeChannels' in res.data) {
              dispatch(setActiveChannel(res.data.activeChannels));
            }

            if (res.data.status === 'DRAFT') {
              dispatch(setSurveyConfigStep());
              dispatch(setSurveyPreviewStep());
              // props.history.push(`/app/survey/edit/sortandpreview/${disid}`)
            }
            if (res.data.status === 'SUBMITTED') {
              dispatch(setSurveyConfigStep());
              dispatch(setSurveyPreviewStep());
              dispatch(setSurveyChannelStep());
              dispatch(fetchChannels(localStorage.getItem('jwtProfileId')));
              // props.history.push(`/app/survey/edit/sortandpreview/${disid}`)
            }
            if (res.data.status === 'DISTRIBUTED') {
              dispatch(setSurveyConfigStep());
              dispatch(setSurveyPreviewStep());
              dispatch(setSurveyChannelStep());
              dispatch(fetchChannels(localStorage.getItem('jwtProfileId')));
              // props.history.push(`/app/survey/edit/channel/${disid}`)
            }
          });
      }
    };
    // console.log(props.location.state)
    // if (props.location.state === undefined) {
    if (disID === '') {
      fetchDistDataOnReload(props.match.params.surveyid);
    }

    // if (props.location.state !== undefined && status !== 'DRAFT' && status !== 'SUBMITTED' && status !== 'DISTRIBUTED') {
    //     fetchDistData(props.match.params.surveyid)
    // }
  }, []);

  const message = (value) => {
    // console.log('value 1')
    setSurveyUpdateMessage(value);
    if (value === true) {
      dispatch(setSurveyConfigStep());
    }
  };

  const surveyQuestionSave = (value) => {
    // if (status === 'SUBMITTED' || status === 'DISTRIBUTED') {
    //     dispatch(changeConfigurationStatus(distributionId))
    // }
    // console.log('value', value)
    if (value === true) dispatch(surveyQuestionSaveMessageTrue());
  };

  return (
    <MainBody>
      <MainBodyDiv>
        {/* <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link> */}
        <div>
          <Step />
        </div>
      </MainBodyDiv>

      {projectId && (
        <QuestionSurvey
          message={message}
          projectId={projectId}
          mode='create'
          disStatus={disStatus}
          surveyQuestionSave={surveyQuestionSave}
          isReadOnly={isReadOnly}
        />
      )}
    </MainBody>
  );
};

export default withRouter(SelectPage);
