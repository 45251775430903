import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import axios from 'axios';

import { ChannelCard, ChannelItem, Banner, DeleteText, EditText } from '../../../StyledComponents'
// import { Modal } from '../../../../StyledComponents'
import QrCodeCreate from './QrCodeCreate'
import QrCodeDetails from './QrCodeDetails'
import { DotLoader } from '../../../../Loader'
import { getAllProfileWiseQRcode, deleteQRcode } from '../../../../../url/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQrcode } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import showToast from '../../../../../components/Toast/toast'


const QrCode = props => {
  /*States-----------------------------------------------------*/
  const [view, setView] = useState([false, false])
  const [qrCode, setQrCode] = useState([])
  const [select, setSelect] = useState('')
  const [loading, setLoading] = useState(false)
  let [deleteModal, setDeleteModal] = useState(false)
  let [editModal, setEditModal] = useState(false)
  let [deletableQRcodeId, setDeletableQRcodeId] = useState(false)
  let [editableQRcodeId, setEditableQRcodeId] = useState('')

  const toggle = () => setView([false, false]);
  /*-----------------------------------------------------------*/

  // Toast 
  const DeleteToast = () => showToast('Deleted Successfully')
  const DependencyToast = () => showToast('Cannot delete, there are dependency')
  const ErrorToast = () => showToast('Server Error')

  useEffect(() => { setLoading(false) }, []) 

  useEffect(() => { 
    const fetchData = async () => { 
      let result, qrCodeData; 
      try { 
        result = await axios(getAllProfileWiseQRcode(localStorage.getItem('jwtProfileId'))); 
        qrCodeData = result.data.map((d) => {  
        const filterData = { 
          id: d._id, 
          name: d.name, 
          active: d.active, 
          createdByCompanyID: d.createdByCompanyID, 
          branchId: d.branchId ? d.branchId : '' 
        } 
        return filterData; 
      }) 
      } 
      catch (error) { qrCodeData = [] } 
      finally { setQrCode([ ...qrCodeData ]); setLoading(false) } 
    }; 

    fetchData();
  }, []);

  const deleteQrCode = () => { 
    axios.delete(deleteQRcode(deletableQRcodeId)) 
    .then(res => { 
      if(res.data.isDeletable) { 
        const newQRcode = qrCode.filter(item => (item.id !== deletableQRcodeId)) 
        setQrCode(newQRcode) 
        DeleteToast() 
      } 
      else if(res.data.isDeletable === false) { DependencyToast(); } 
      setDeleteModal(false); 
    }) 
    .catch(err => ErrorToast()) 
    setView([false, false]) 
    setSelect('') 
  } 

  const qrCodeList = qrCode.map((item, id) => (
    <ChannelCard key={id}>
      <ChannelItem>
        <DeleteText item={ChannelCard} onClick={() => { setDeleteModal(true); setDeletableQRcodeId(item.id); }}> Delete </DeleteText>
        <EditText item={ChannelCard} onClick={() => { setEditModal(true); setEditableQRcodeId(item.id) }}> Edit </EditText>

        <div css={`height: 70%; font-size: 50px; color: #1B998B;`}>
          <FontAwesomeIcon icon={faQrcode} css={`padding: .1px; @media only screen and (max-width: 425px){}`} />
        </div>
        <div css={`height: 30%; color:white; background-color: rgb(52, 58, 64); word-break: break-all; padding: 0 5px;`}> {item.name} </div>
      </ChannelItem>
    </ChannelCard>
  ))

  return (
    <>
      {
        deleteModal &&
        <Modal isOpen={deleteModal} className={props.className}>
          <ModalHeader> Delete QR Code </ModalHeader>
          <ModalBody> Are you sure, you want to delete? </ModalBody>
          <ModalFooter>
            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={deleteQrCode}>
              Delete
            </Button>

            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={() => setDeleteModal(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal> 
      } 


      { 
        loading ? <DotLoader /> 
        : 
          <div css={` display: flex; position: relative; justify-content: flex-start; align-items: flex-start; align-content: flex-start; flex-wrap: wrap;`}>
            <Banner>QrCode Channel List</Banner> 

            {/* Create QR Code Modal */} 
            <Modal isOpen={view[0]} toggle={toggle} className={props.className}>
              <ModalHeader toggle={toggle}>
                <span style={{ margin: '0 0 0 25px', color: 'rgb(52, 58, 64)', fontSize: '18px' }}> Create QR Code </span>
              </ModalHeader>
              <QrCodeCreate />
            </Modal>

            {/* Edit Device Modal */} 
            <Modal isOpen={editModal} toggle={() => setEditModal(false)} className={props.className}>
              <ModalHeader toggle={() => setEditModal(false)}>
                <span style={{ margin: '0 0 0 25px', color: 'rgb(52, 58, 64)', fontSize: '18px' }}> Edit QR Code </span>
              </ModalHeader>
              <QrCodeDetails data={qrCode.find((item) => item.id === editableQRcodeId)} />
            </Modal>

            {qrCodeList} 

            { 
              props.activestatus && 
              <ChannelCard> 
                <ChannelItem onClick={() => { setView([true, false]) }}> 
                  <div css={`height: 55%; font-size: 30px; color: #1B998B;`}> + </div> 
                  <div css={`height: 45%; color:white; text-align: center; background-color: rgb(52, 58, 64);`}>Add Predefined QR Code</div> 
                </ChannelItem> 
              </ChannelCard> 
            } 
          </div> 
      } 
    </> 
  ) 
} 

export default QrCode; 