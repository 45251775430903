import React from 'react'
import './DashCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const DashCard = (props) => {
    return (
        <div className="DashCard">
            <div className="card-body DashCardContent">
                <div className="flex-item-left">
                    <h6 className="DashCard-title">
                        <FontAwesomeIcon icon={props.icon} style={{ marginRight: "6px" }} />
                        {props.title}
                    </h6>
                    <p className="DashCard-description">{props.description}</p>
                </div>
                <div className="flex-item-right">{props.value}</div>
            </div>
        </div>
    )
}

export default DashCard;