import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import DatePicker from "react-datepicker";
import StarRatingComponent from 'react-star-rating-component';
import { FetchAllContactByIndividualCompany, GetIndividualContact, GetAllCompanyProfile,
  FetchAllEmployee,FetchAllIndustry, AddNewLead, GetAllLeadByProfile} from '../../../url/prmUrl';
import { Button, Input, Label, Body, Error, Aside, Modal, TextArea } from '../StyledComponents'
import Alert from '../ShowAlert'

let leadStatusOptions = [
    {label: 'Attempted to Contact', value: 'Attempted to Contact'},{label: 'cold', value: 'cold'},
    {label: 'Contact in Future', value: 'Contact in Future'},{label: 'Contacted', value: 'Contacted'},
    {label: 'Hot', value: 'Hot'},{label: 'Junk Lead', value: 'Junk Lead'},
    {label: 'Lost Lead', value: 'Lost Lead'},{label: 'Not Contacted', value: 'Conference'},
    {label: 'Pre Qualified', value: 'Trade Show'},{label: 'Qualified', value: 'Qualified'},
    {label: 'Warm', value: 'Warm'},
]

let leadSourceOptions = [
  {label: 'Cold Call', value: 'Cold Call'},{label: 'Existing Customer', value: 'Existing Customer'},
  {label: 'Self Generated', value: 'Self Generated'},{label: 'Employee', value: 'Employee'},
  {label: 'Partner', value: 'Partner'},{label: 'Public Relations', value: 'Public Relations'},
  {label: 'Direct Mail', value: 'Direct Mail'},{label: 'Conference', value: 'Conference'},
  {label: 'Trade Show', value: 'Trade Show'},{label: 'Website', value: 'Website'},
  {label: 'Social Media', value: 'Social Media'},{label: 'Word of Mouth', value: 'Word of Mouth'},
  {label: 'Other', value: 'Other'},
]

let contactOptions = []
let profileId = []
let assignToIdOptions = []
let industryIdOptions = []
let leadownerIdOptions = []

const Form = props => {


  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;


  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Contact:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="contactId"
                    value={values.contactId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.contactId}
                  /> 
                </div>
              </Label>
            
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Name:   <span>*</span>{errors.name && touched.name ? <Error>{errors.name}</Error> : null}
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Mobile No:   {errors.mobileNumber && touched.mobileNumber ? <Error>{errors.mobileNumber}</Error> : null}
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="mobileNumber"
                    type="text"
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Email:   {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              
              {/* <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Company Profile:   <span>*</span>{errors.profileId && touched.profileId ? <Error>{errors.profileId}</Error> : null}
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="profileId"
                    value={values.profileId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.profileId}
                  /> 
                </div>
              </Label> */}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Lead Status:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="leadStatus"
                    value={values.leadStatus}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.leadStatus}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Lead Source:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="leadSource"
                    value={values.leadSource}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.leadSource}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Rating:
                </div>
                <div css={`width: 60%`}>
                  <MyRating
                    name="rating"
                    value={values.rating}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.rating}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Assign To:
                </div>
                <div css={`width: 60%`}>
                  <MySelect       
                    name="assignToId"
                    value={values.assignToId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.assignToId}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Industry:
                </div>
                <div css={`width: 60%`}>
                  <MySelect       
                    name="industryId"
                    value={values.industryId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.industryId}
                  /> 
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Next Contact Date:
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="nextContactDate"
                  value={values.nextContactDate}
                  onChange={setFieldValue}   
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Lead Owner:
                </div>
                <div css={`width: 60%`}>
                  <MySelect       
                    name="leadownerId"
                    value={values.leadownerId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.leadownerId}
                  /> 
                </div>
              </Label>
              {errors.amount && touched.amount ? <Error>{errors.amount}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Amount:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="amount"
                    type="number"
                    min="0"
                    placeholder="Enter Number"
                    value={values.amount }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Created Date:   <span>*</span>{errors.createdDate && touched.createdDate ? <Error>{errors.createdDate}</Error> : null}
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="createdDate"
                  value={values.createdDate}
                  onChange={setFieldValue}   
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Remarks:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="remarks"
                    placeholder="Type here..."
                    value={values.remarks}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Next Step:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="nextStep"
                    placeholder="Type here..."
                    value={values.nextStep}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>


            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}

const DateSelect = ({ name, value, onChange }) => {
    return (
      <DatePicker
        selected={(value && new Date(value)) || null}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={val => {
          onChange(name, val);
        }}
      />
    );
  };

  class MyRating extends React.Component {

    onStarClick(nextValue, prevValue, name) {
      this.props.onChange("rating", nextValue); 
    }

    render() {
      const rating = this.props.value;  
      return (                
        <div css={`display: flex; align-items: center;`}>
          Star Rating: {rating} 
          <StarRatingComponent 
            css={`font-size:2.5vw; margin-left:10px;`}
            name="rating" 
            starCount={10}
            value={rating}
            onStarClick={this.onStarClick.bind(this)}   
          />
        </div>
      );
    }
  }

class MySelect extends React.Component {
  handleChange = value => {

    const props = this.props // need to define props like this to work auto fill from contact. don't know why

    this.props.name==="contactId" ? this.props.onChange("contactId", value) :
   // this.props.name==="profileId" ? this.props.onChange("profileId", value) :
    this.props.name==="leadStatus" ? this.props.onChange("leadStatus", value) :
    this.props.name==="leadSource" ? this.props.onChange("leadSource", value) :
    this.props.name==="assignToId" ? this.props.onChange("assignToId", value) :
    this.props.name==="industryId" ? this.props.onChange("industryId", value) :
    this.props.name==="leadownerId" ? this.props.onChange ("leadownerId", value) :
    this.props.onChange("null", value)

    if(this.props.name==="contactId"){
        axios(GetIndividualContact(value.value))
        .then(function (response) {
          props.onChange("name", response.data[0].firstName) //need to define props at the begininnig of handle change, otherwise 
                                                            // referense to this part wont work and props undefined error will show
          props.onChange("mobileNumber", response.data[0].primaryPhone) 
          props.onChange("email", response.data[0].primaryEmail)

        })
        .catch(function(error){
          // console.log("error")
        })
    }
  };

  handleBlur = () => {
    this.props.name==="contactId" ? this.props. onBlur("contactId", true) :
    this.props.name==="leadStatus" ? this.props.onBlur("leadStatus", true) :  
    this.props.name==="leadSource" ? this.props.onBlur("leadSource", true) :  
   // this.props.name==="profileId" ? this.props.onBlur("profileId", true) :
    this.props.name==="assignToId" ? this.props.onBlur("assignToId", true) :
    this.props.name==="industryId" ? this.props.onBlur("industryId", true) :
    this.props.name==="leadownerId" ? this.props.onBlur("leadownerId", true) :
    this.props.onBlur("null", true)
  };
 
  render() {
    return (
        <Select
          id="color"
          placeholder="Select one..."
          options={
            this.props.name==="contactId" ? contactOptions :
           // this.props.name==="profileId" ? profileId :
            this.props.name==="leadStatus" ? leadStatusOptions :
            this.props.name==="leadSource" ? leadSourceOptions :
            this.props.name==="assignToId" ? assignToIdOptions :
            this.props.name==="industryId" ? industryIdOptions :
            this.props.name==="leadownerId" ? leadownerIdOptions :
            null
            }
          //isMulti
          noOptionsMessage={() => 'Loading...'}
          onChange={this.handleChange}  
          onBlur={this.handleBlur}     
          value={this.props.value}      
        />

    );
  }
}

const Lead = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    name:'',
    mobileNumber:'',
    email:'',
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
      const fetchContact = await axios(FetchAllContactByIndividualCompany(localStorage.getItem("jwtID")));
      contactOptions = fetchContact.data.Error ? [] : fetchContact.data.map(element=>({label: element.firstName+" "+element.lastName, value: element._id}))

      //  const allCompanyProfile = await axios(GetAllCompanyProfile);
      //  profileId = allCompanyProfile.data.map(element=>({label: element.user_Name, value: element.user_Id}))

       const fetchAllEmployee = await axios(FetchAllEmployee);
       assignToIdOptions = fetchAllEmployee.data.map(element=>({label: element.name, value: element._id}))

       const fetchAllIndustry = await axios(FetchAllIndustry);
       industryIdOptions = fetchAllIndustry.data.map(element=>({label: element.name, value: element._id}))
       
       leadownerIdOptions = fetchAllEmployee.data.map(element=>({label: element.name, value: element._id}))

       const result = await axios(GetAllLeadByProfile(localStorage.getItem("jwtID")))
       if(result.data.Error){
         throw new Error()
       }
          setEditable(prev=>{
          return {
            ...prev,
            loaded: result.data
          }
        })
     }
     catch (err) {
      // console.log('err')
     }
    };

    if(view===false){
      fetchData(); 
    }
  }, [view]);

  const duplicacy = editable.loaded ? editable.loaded.map(element=>element.name) : []
                  
  const schema = Yup.object().shape({
    name: Yup.string().required("Must Define a Name")
                      .min(3, "Minimum 3 characters")
                      .notOneOf(duplicacy, "Already Exists"),
   // profileId: Yup.string().required("Please select one..."),
    createdDate: Yup.date().required("Created Date is required"),
    mobileNumber: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    email: Yup.string().email("Invalid Email"),
    amount : Yup.number().min(0, "Can't be Negative")
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      contactId: '', 
      name: '',
      mobileNumber: '',
      email: '',
      profileId: localStorage.getItem("jwtID"),
      leadStatus:'',
      leadSource:'',
      rating: 5,
      assignToId:'',
      industryId:'',
      nextContactDate:'',
      leadownerId:'',
      amount: '',
      createdDate: new Date(Date.now()),  
      remarks: '',
      nextStep: ''
    }),

    handleSubmit: (values, { setSubmitting }) => {
      const data = {
        ...values,
        contactId : values.contactId.value ?   values.contactId.value : '',
      //  profileId : values.profileId.value ? values.profileId.value : '',
        leadStatus : values.leadStatus ? values.leadStatus.value : '',
        leadSource : values.leadSource ? values.leadSource.value : '',
        assignToId : values.assignToId ? values.assignToId.value : '',
        leadownerId : values.leadownerId ? values.leadownerId.value : '',
      }

      axios.post(AddNewLead, data)
      .then((response) => {
        if(!response.data.Error){
          setView(true)
        }
        else {
          window.alert("Uexpected Error! Duplicate Name found!")
        }
      })
      .catch((err) => {
        // console.log("err")
      })

    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Lead)