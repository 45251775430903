import React, { useState, useEffect } from 'react'
import { MainBody } from './styles'

// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
// import { setSurveyConfigStep } from '../../redux/actions/index'
import QuestionSurvey from '../../../QuestionSurvey'
// import { Redirect } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom'
import {
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
    projectFetch,
    setSurveyProjectStep,
    setSurveyChannelStep,
    setSurveyPreviewStep,
    setSurveyConfigStep,
    fetchChannels,
    // changeConfigurationStatus,
    surveyQuestionSaveMessageTrue
} from '../../redux/actions'
import EditStep from '../../components/EditStep'
import baseUrl from '../../../../url'
import axios from 'axios'
// import { Link } from 'react-router-dom'

const SelectPage = props => {

    let dispatch = useDispatch()
    let status = useSelector(state => state.question.status)
    let isReadOnly = useSelector(state => state.question.isReadOnly)
    // let projectList = useSelector(state => state.config.projectList)
    let distributionId = useSelector(state => state.question.distributionId)

    useEffect(
        () => {

            const fetchDistData = async (surveyid) => {
                const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`)
                dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data })
                dispatch(setSurveyProjectStep())

                const disid = disId.data.distributionId
                if (disid) {
                    axios.get(`${baseUrl}/api/distribution/details/${disid}`).then(res => {

                        dispatch(editDistributionQuestion(res.data, disid))
                        dispatch(editDistributionConfig(res.data))
                        dispatch(selectProjectAndFetch(res.data.question.questionBank.projectOrder[0]))
                        dispatch(projectFetch(localStorage.getItem('jwtProfileId')))

                        if ('activeChannels' in res.data) { dispatch(setActiveChannel(res.data.activeChannels)) }
                        if (res.data.status === "DRAFT") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            props.history.push(`/app/survey/edit/sortandpreview/${surveyid}`)
                        }
                        if (res.data.status === "SUBMITTED") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            // dispatch(setSurveyChannelStep())
                            dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
                            props.history.push(`/app/survey/edit/sortandpreview/${surveyid}`)
                        }
                        if (res.data.status === "DISTRIBUTED") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            dispatch(setSurveyChannelStep())
                            dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
                            props.history.push(`/app/survey/edit/channel/${surveyid}`)
                        }
                    })
                }
            }

            const fetchDistDataOnReload = async (surveyid) => {
                const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`)
                dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data })
                dispatch(setSurveyProjectStep())

                const disid = disId.data.distributionId
                if (disid) {
                    axios.get(`${baseUrl}/api/distribution/details/${disid}`).then(res => {

                        dispatch(editDistributionQuestion(res.data, disid))
                        dispatch(editDistributionConfig(res.data))
                        dispatch(selectProjectAndFetch(res.data.question.questionBank.projectOrder[0]))
                        dispatch(projectFetch(localStorage.getItem('jwtProfileId')))

                        if ('activeChannels' in res.data) { dispatch(setActiveChannel(res.data.activeChannels)) }

                        if (res.data.status === "DRAFT") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            // props.history.push(`/app/survey/edit/sortandpreview/${disid}`)
                        }
                        if (res.data.status === "SUBMITTED") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            // dispatch(setSurveyChannelStep())
                            dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
                            // props.history.push(`/app/survey/edit/sortandpreview/${disid}`)
                        }
                        if (res.data.status === "DISTRIBUTED") {
                            dispatch(setSurveyConfigStep())
                            dispatch(setSurveyPreviewStep())
                            dispatch(setSurveyChannelStep())
                            dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
                            // props.history.push(`/app/survey/edit/channel/${disid}`)
                        }
                    })
                }
            }

            // if (distributionId === "" && status !== 'DRAFT' && status !== 'SUBMITTED' && status !== 'DISTRIBUTED')
            //     fetchDistData(props.match.params.surveyid)
            // console.log(props.location)

            if (props.location.state === undefined) {
                // console.log('reload')
                fetchDistDataOnReload(props.match.params.surveyid)
            }

            if (props.location.state !== undefined && status !== 'DRAFT' && status !== 'SUBMITTED' && status !== 'DISTRIBUTED') {
                // console.log('not reload')
                fetchDistData(props.match.params.surveyid)
            }


        }
        , [])

    const message = (value) => {
        if (value === true) {
            // dispatch(setSurveyConfigStep())
        }
    }

    const surveyQuestionSave = value => {
        // if (status === 'SUBMITTED' || status === 'DISTRIBUTED') {
        //     dispatch(changeConfigurationStatus(distributionId))
        // }
        // console.log('value', value)
        if (value === true)
            dispatch(surveyQuestionSaveMessageTrue())

    }

    return (
        <MainBody>
            {/* <div className="container">
                <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link>
            </div> */}
            <div>
                <EditStep />
            </div>
            {props.match.params.surveyid &&
                <QuestionSurvey
                    message={message}
                    projectId={props.match.params.surveyid}
                    mode="edit"
                    disStatus={status}
                    surveyQuestionSave={surveyQuestionSave}
                    isReadOnly={isReadOnly}
                />}

        </MainBody>
    )
}

export default withRouter(SelectPage)