import React, { useEffect, useReducer } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBullseye } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'

import {
  Project as Channels,
  ProjectItem as ChannelItem
} from '../Projects/StyledComponents'
import { Body, Edit, AddNewItem, Delete } from './Styles'
import Loader from '../Loader'
import Form from './components/Form'
import Modal from '../../components/Modal'
import Confirmation from '../../components/Confirmation'
import Alert from '../../components/Alert'

import { startChannel, getChannelList, deleteChannel, updateChannel, getProfileByChannelId } from '../../url';
import Can from '../../components/Can'
import { Button } from 'reactstrap'; 





const channelTypeOptions = [
  { label: 'Device', value: 'device' },
  { label: 'OpenLink', value: 'openLink' },
  { label: 'Email', value: 'email' },
  { label: 'Software', value: 'software' },
  { label: 'Website', value: 'website' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Twitter', value: 'twitter' },
  { label: 'LinkedIn', value: 'linkedin' },
  { label: 'Instagram', value: 'instagram' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'SMS', value: 'sms' },
  { label: 'Service Center', value: 'serviceCenter' },
  { label: 'CC Camera', value: 'ccCamera' },
  { label: 'Call Center', value: 'callCenter' },
  { label: 'Manual', value: 'manual' },
  { label: 'QR Code', value: 'qrCode' },
  { label: 'Offline', value: 'offline' },
  { label: 'In App', value: 'inApp' },
]

const Feature = props => {

  /*Action types for useReducer*/
  const SET_LOADING = "SET_LOADING"
  const SET_VIEW = "SET_VIEW"
  const SET_EDIT = "SET_EDIT"
  const SET_ALERT = "SET_ALERT"
  const SET_CHANNELS = "SET_CHANNELS"
  const SET_CONFIRMATION = "SET_CONFIRMATION"
  const SET_DELETE_ID = "SET_DELETE_ID"

  //Inital State for useReducer
  const initialState = {
    alert: {
      status: false,
      isSuccess: "failed",
      message: ""
    },
    viewForm: {
      create: false,
      edit: false,
    },
    edit: {
      id: "",
      values: {
        channelType: null,
        channelMode: null,
        isActive: null
      }
    },
    loading: false,
    confirmation: {
      delete: false
    },
    deleteId: "",
    channelList: []
  }

  //Reducer function for useReducer
  const reducer = (state, action) => {
    const { value } = action
    switch (action.type) {
      case SET_LOADING: {
        return {
          ...state,
          loading: value
        }
      }
      case SET_ALERT: {
        const newState = { ...state }
        if (!value) {
          newState.alert = {
            status: false,
            isSuccess: "failed",
            message: ""
          }
        } else {
          newState.alert = {
            ...value
          }
        }
        return newState
      }
      case SET_EDIT: {
        const newState = { ...state }
        if (!value) {
          newState.edit = {
            id: "",
            values: {
              channelType: null,
              channelMode: null,
              isActive: null
            }
          }
        } else {
          newState.edit = {
            id: value.id,
            values: {
              ...value.values
            }
          }
        }
        return newState
      }
      case SET_CHANNELS: {
        const newState = { ...state }
        newState.channelList = value
        return newState
      }
      case SET_VIEW: {
        const newState = { ...state }
        if (!value) {
          newState.viewForm = {
            create: false,
            edit: false
          }
        } else {
          newState.viewForm = {
            ...state.viewForm,
            ...value
          }
        }
        return newState
      }
      case SET_CONFIRMATION: {
        const newState = { ...state }
        if (!value) {
          newState.confirmation = {
            delete: false
          }
          return newState
        }
        newState.confirmation = {
          ...state.confirmation,
          ...value
        }
        return newState
      }
      case SET_DELETE_ID: {
        const newState = { ...state }
        if (!value)
          newState.deleteId = ""
        else
          newState.deleteId = value.id
        return newState
      }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  //Used to fetch the list of Channels when the page loads
  useEffect(() => {
    const fetch = async () => { 
      try { 
        dispatch({ type: SET_LOADING, value: true }) 
        const { data } = await axios.get(getChannelList) 
        console.log('data');
        console.log(data);
        
        const formattedData = data.map(item => ({ 
          ...item, 
          label: channelTypeOptions.find(element => element.value.toLowerCase() === item.channelType.toLowerCase()).label
        })) 
        dispatch({ type: SET_CHANNELS, value: formattedData }) 
      } 
      catch (err) { 
      } 
      finally { 
        dispatch({ type: SET_LOADING, value: false }) 
      } 
    } 
    fetch() 
  }, []) 

  //Used to add a new feature using the form generated in the modal after pressing the create button
  const addChannel = async (obj) => {
    dispatch({ type: SET_LOADING, value: true })

    const wrapData = {
      createdBy: localStorage.getItem("jwtID"),
      channelType: obj.channelType,
      status: obj.isActive,
      distributionType: obj.channelMode
    }
    try {
      const { data } = await axios.post(startChannel, wrapData)
      const formattedData = {
        ...data,
        label: channelTypeOptions.find(element => element.value.toLowerCase() === data.channelType.toLowerCase()).label
      }
      dispatch({ type: SET_CHANNELS, value: [...state.channelList, formattedData] })
      dispatch({ type: SET_ALERT, value: { status: true, message: `${formattedData.label} channel Added`, isSuccess: "success" } })
    }
    catch (err) {
      dispatch({ type: SET_ALERT, value: { status: true, message: "Oops! Something went Wrong! Please Try Again!", isSuccess: "failed" } })
    }
    finally { 
      dispatch({ type: SET_LOADING, value: false }) 
    } 
  } 

  //Used to close the modal
  const closeModal = () => {
    dispatch({ type: SET_EDIT, value: false })
    dispatch({ type: SET_VIEW, value: false })
    dispatch({ type: SET_ALERT, value: false })
    dispatch({ type: SET_CONFIRMATION, value: false })
  }

  //Generates functions for the edit button inside the feature Bodies based on individual feature Ids and content
  const createEditChannel = (id, values) => {
    return () => {
      dispatch({ type: SET_EDIT, value: { id, values } })
      dispatch({ type: SET_VIEW, value: { edit: true } })
    }
  }

  // Generate a function for delete of a feature
  const createDeleteChannel = channelId => { 
    return async () => { 
      dispatch({ type: SET_LOADING, value: true }) 
      try { 
        const { data } = await axios.get(getProfileByChannelId(channelId)) 
        const isDeletable = data.length === 0 
        if (!isDeletable) { 
          dispatch({ type: SET_ALERT, value: { status: true, message: "The Channel is Currently in Use!", isSuccess: "warn" } })
        } else { 
          dispatch({ type: SET_CONFIRMATION, value: { delete: true } }) 
          dispatch({ type: SET_DELETE_ID, value: { id: channelId } }) 
        }  
      } catch (err) {  
        dispatch({ type: SET_ALERT, value: { status: true, message: "Oops! Something Went Wrong!", isSuccess: "failed" } })
      } finally {
        dispatch({ type: SET_LOADING, value: false })
      }
    }
  } 

  // Deletes a feature based on the deleteId stored in the state
  const deleteFn = async () => {
    dispatch({ type: SET_LOADING, value: true })
    try {
      await axios.delete(deleteChannel(state.deleteId))
      dispatch({ type: SET_CONFIRMATION, value: false })
      dispatch({ type: SET_DELETE_ID })
      dispatch({ type: SET_ALERT, value: { status: true, message: "Successfully Deleted", isSuccess: "success" } })
      const { data } = await axios.get(getChannelList)
      const formattedData = data.map(item => ({
        ...item,
        label: channelTypeOptions.find(element => element.value.toLowerCase() === item.channelType.toLowerCase()).label
      }))
      dispatch({ type: SET_CHANNELS, value: formattedData })
    } catch (err) {
      // console.log(err)
      dispatch({ type: SET_ALERT, value: { status: true, message: "Something Went Wrong. Try Again!", isSuccess: "failed" } })
    } finally {
      dispatch({ type: SET_LOADING, value: false })
    }
  } 

  //Edits the content of a specific feature based on the data provided by the form of the edit modal
  const editChannel = (values) => {
    // console.log(channelId, values);
    const channelId = state.edit.id 
    const data = {
      status: values.isActive,
      distributionType: values.channelMode
    }
    const update = async () => {
      dispatch({ type: SET_LOADING, value: true })
      try {
        await axios.put(updateChannel(channelId), data)
        const updatedList = await axios.get(getChannelList)
        const formattedData = updatedList.data.map(item => ({
          ...item,
          label: channelTypeOptions.find(element => element.value.toLowerCase() === item.channelType.toLowerCase()).label
        }))
        dispatch({ type: SET_CHANNELS, value: formattedData })
        dispatch({ type: SET_ALERT, value: { status: true, message: "Successfully Updated", isSuccess: "success" } })
      } catch (err) {
        // console.log(err)
        dispatch({ type: SET_ALERT, value: { status: true, message: "Oops! Something went wrong! Please Try Again!", isSuccess: "failed" } })
      } finally {
        dispatch({ type: SET_LOADING, value: false })
      }

    }
    update()
  } 

  const createChannel = () => {
    dispatch({ type: SET_VIEW, value: { create: true } })
  } 
  // console.log(feature)

  //Generating all the feature items
  const items = state.channelList.map((item, index) => (
    <ChannelItem key={index}>
      <Channels>
        <div
          css={` text-align: center; padding-top:10px; `} >
          <FontAwesomeIcon 
            icon={faBullseye}
            css={`
              font-size: 50px; color: #1B998B; padding-top:10px; :hover { color: #1B998B; }
              @media only screen and (max-width: 768px) { }
              @media only screen and (max-width: 425px) { padding-top:15px; }
            `} 
          /> 
        </div>
        <div css={` display: flex; position:relative; background-color: rgb(52, 58, 64); align-items:center; justify-content:center; `} >
          {item.label} 
        </div> 
        <Can
          feature="channel"
          perform="edit"
          yes={() => (
            <Edit
              item={ChannelItem} 
              onClick={createEditChannel(
                item._id,
                { channelType: item.channelType, channelMode: item.distributionType, isActive: item.status }
              )}> 
              Edit
            </Edit> 
          )} 
        // no={() => <h2>User can't do it</h2>}
        />
        <Can feature="channel" perform="delete"
          yes={() => (
            <Delete item={ChannelItem} onClick={createDeleteChannel(item._id)}>Delete</Delete>
          )}
        // no={() => <h2>User can't do it</h2>}
        /> 
      </Channels>
    </ChannelItem>
  )) 

  const deleteDecline = () => {
    dispatch({ type: SET_DELETE_ID })
    closeModal()
  }

  const viewModal = state.viewForm.create || state.viewForm.edit || state.alert.status || state.confirmation.delete

  return (
    <>
      {state.loading && <Loader />}
      {!state.loading &&
        <>
          <Can
            feature="channel"
            perform="create"
            yes={() => (
            //   <AddNewItem onClick={createChannel}>
            //     Create
            // </AddNewItem>
              <Button 
                className="btnHover" size="sm" 
                style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px', width: '100px',
                padding:'4px 15px', fontSize:'13px', fontWeight:'500' }} 
                onClick={() => { createChannel(); }}> 
                Create New 
              </Button> 
            )} 
          // no={() => <h2>User can't do it</h2>}
          />

          <Body>
            {viewModal &&
              <Modal close={closeModal}>
                {
                  state.viewForm.edit &&
                  <Form
                    initialValues={state.edit.values} header="Edit Feature" submitFn={editChannel} 
                    close={closeModal}
                    allChannels={state.channelList.filter(item => item._id !== state.edit.id).map(elem => elem.channelType)}
                  />
                }
                {
                  state.viewForm.create &&
                  <Form
                    header="Add Channel" submitFn={addChannel} close={closeModal}
                    allChannels={state.channelList.map(elem => elem.channelType)}
                  />
                }
                {
                  state.alert.status &&
                  <Alert close={closeModal} icon={state.alert.isSuccess}>
                    {state.alert.message}
                  </Alert>
                }
                {
                  state.confirmation.delete &&
                  <Confirmation
                    warning="Are you sure you want to delete?"
                    accept={{ value: "Delete", fn: deleteFn }}
                    decline={{ value: "Cancel", fn: deleteDecline }}
                  />
                }
              </Modal>
            }
            {items}
          </Body>
        </>
      }
    </>
  )
}

export default Feature; 