import React from 'react';
import './ResponseCategory.css';
import collect from 'collect.js';

const ResponseCategory = (props) => {
    const response = props.responses;
    let device =  response.get('device');
    let qrCode = response.get('qr');
    let openlink = response.get('openLink');
    let total = response.sum();
    
    return (
        <div className="ResponeCategory text-lg-left text-center">
            <ul className="list-group list-group-flush">
                <li className="list-group-item">Responses</li>
                <li className="list-group-item"><span>Total</span> { total }</li>
                <li className="list-group-item"><span>Device</span> { device }</li>
                <li className="list-group-item"><span>Openlink</span> { openlink }</li>
                <li className="list-group-item"><span>QrCode</span> { qrCode }</li>
            </ul>
        </div>
    );
}

export default ResponseCategory;    