import React from 'react'
import Projects from './Projects';

const DataAnalysis = ()=>{
  return(
    <React.Fragment>
      <Projects />
    </React.Fragment>
  )
}

export default DataAnalysis