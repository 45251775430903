import React from 'react'
import {connect} from 'react-redux'
import randomColor from 'randomcolor'
import {
  BarChart,
  Bar,
  Brush, 
  ReferenceLine, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer,
  LabelList} from 'recharts'

import {makeAnswerTallySelector} from '../../redux/selectors'

const Answers = props=>{

  const {data} = props

  if(data.length===0){
    return <></>
  }

  const barNames = Object.keys(data[0])
  const color = randomColor({count: barNames.length})
  const bar = barNames.map((element,index)=>(
    <Bar 
      key={index} 
      dataKey={element} 
      fill={color[index]}
      legendType="square"
    >
      <LabelList dataKey={element} position="top"/>
    </Bar>
    )
  )

  return(
    <>
      <hr></hr>
      <h3>Response Distribution:</h3>
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={data} barGap={20} margin={{ top: 15, right: 5, bottom: 10, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3"/>
          {/* <XAxis /> */}
          <YAxis allowDecimals={false}/>
          {/* <Tooltip /> */}
          <Legend verticalAlign="bottom" wrapperStyle={{lineHeight: '35px'}}/>
          <ReferenceLine y={0} stroke="#555"/>
          {bar}
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

const makeMapStateToProps = ()=>{
  const answerTallySelector = makeAnswerTallySelector()
  const mapStateToProps = (state,ownProps)=>({
    // title: ownProps.title,
    // type: ownProps.type,
    data: answerTallySelector(state, ownProps)
  })
  return mapStateToProps
}

export default connect(makeMapStateToProps)(Answers)