import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseUrl, { pexUrl } from '../../../../url/index'
import { Link } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import collect from 'collect.js';

import { Container, PreviewBody, SaveButton, SubmitButton, DistributeButton, PreviewButton } from './styles'
import './style.css'
import BackButton from '../../components/BackButton'
import ProjectGroup from '../../container/ProjectGroup'
import Header from '../../components/Header'
import AdditionalContentBar from '../../components/AdditionalContentBar'
import DeviceWrapper from '../../components/Distribution/DeviceWrapper'
import Device from '../../components/Distribution/Device'
import OpenLink from '../../components/Distribution/OpenLink'
import QrCode from '../../components/Distribution/QrCode'

import { sortQuestion, sortProjects, addCard, pushConfiguration, updateConfiguration, setProfileIdInState, fetchChannels, fetchDeviceLists, setDeviceActive, setDistDevice, distributeConfiguration, initOpenLink, setOpenLink, updateOpenLink, setOpenLinkInDistribution, initQrCode, setQRCode, setGenQrCode, setQrCodeInDistribution, setProgressbar } from '../../redux/actions'
import { apiDataGeneratorSelector, activeDeviceSelector, saveButtonSelector } from '../../redux/selectors'

const SortAndPreview = props => {

  const { sortQuestion, sortProjects, addCard, dataToPush, pushConfiguration, updateConfiguration, distributeConfiguration, enableSave, setProfileIdInState, disId, fetchChannels, statusDevice, fetchDeviceLists, activeDevice, deviceLists, setDeviceActive, setDistDevice, status, statusOpenLink, initOpenLink, setOpenLink, updateOpenLink, setOpenLinkInDistribution, openlinks, initQrCode, setQRCode, statusQrCode, qRCode, setGenQrCode, setQrCodeInDistribution, order, progressbar } = props
  const enablePreview = !enableSave

  const [profileId, setProfileId] = useState(localStorage.getItem('jwtProfileId'));
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [linkStatus, setLinkStatus] = useState(false);
  const [qrCodeStatus, setQrCodeStatus] = useState(false)
  // console.log("activeDevice", activeDevice)
  const openlink = {
    "name": "Open link",
    "createdByCompanyID": profileId,
    "status": linkStatus,
    "distributionId": disId
  }

  const qrCode = {
    "name": "QR Code",
    "createdByCompanyID": profileId,
    "status": qrCodeStatus,
    "distributionId": disId,
  }

  useEffect(() => {
    // const jwtProfileId = localStorage.getItem('jwtProfileId')
    // setProfileId(jwtProfileId)
    setProfileIdInState(profileId);
  },
    []
  )

  const deviceStatusOnClick = () => {
    let deviceStatus = collect(deviceLists).where('status', '=', 'Active').isNotEmpty();
    setDeviceStatus(deviceStatus)
  }

  const setOpenLinkStatus = value => {
    setLinkStatus(value)
  }

  const setQrCode = value => {
    setQrCodeStatus(value)
  }
  // console.log('dataToPush: ', dataToPush)
  const handleSaveClick = () => {
    const { data } = dataToPush
    pushConfiguration(data, profileId)
  }

  const handleSubmitClick = () => {
    const { data } = dataToPush
    updateConfiguration(data, profileId, disId)
    fetchChannels(profileId)
    initOpenLink(openlink)
    initQrCode(qrCode)
  }

  const handleDistributeClick = () => {
    const { data } = dataToPush
    if (deviceStatus) { setDistDevice(deviceLists, disId) }
    if (linkStatus) { setOpenLink(openlink) }
    if (qrCodeStatus) { setQRCode(qrCode) }
    distributeConfiguration(data, activeDevice, profileId, disId)
  }

  const updateLink = (id, obj) => {
    // console.log('openlink id', id, 'obj', obj)
    updateOpenLink(id, obj)
    setOpenLinkInDistribution(id, { id: openlinks._id, hashId: openlinks.hashID })
  }

  const genQrCode = (id) => {
    // console.log('qrcode id', id, 'obj', obj)
    // setGenQrCode(id, obj)
    setQrCodeInDistribution(id, { id: qRCode._id, hashId: qRCode.hashId })
  }

  const onDragEnd = ({ destination, source, draggableId, type, }) => {
    if (destination) {
      if (type === "projects") {
        if (source.droppableId === "additionalCardsList") {

          addCard(destination, draggableId)
          return
        }
        sortProjects(destination, source, draggableId)
        return
      }
      sortQuestion(destination, source, draggableId)
    }
  }

  return (
    <Container>

      <BackButton disid={props.match.params.disid} />

      {status === '' && <SaveButton type='button' onClick={handleSaveClick}>Save</SaveButton>}

      {status === 'DRAFT' && <SubmitButton type='button' onClick={handleSubmitClick}>Submit</SubmitButton>}

      {status === 'SUBMITTED' && <DistributeButton type='button' onClick={handleDistributeClick} disabled={!deviceStatus && !linkStatus && !qrCodeStatus}>Distribute</DistributeButton>}

      {status !== '' && !props.match.params.disid && <PreviewButton as={Link} to="/app/distribution/create/preview">Preview</PreviewButton>}
      {status !== '' && props.match.params.disid && <PreviewButton as={Link} to={`/app/distribution/edit/preview/${props.match.params.disid}`}>Preview</PreviewButton>}

      <PreviewBody>
        <Header />
        <DragDropContext onDragEnd={onDragEnd}>
          <AdditionalContentBar />
          <ProjectGroup />
        </DragDropContext>

        <div className="channel-wrapper">
          <div>
            <label className="dislabel" style={{ margin: '10px' }}>
              Show question count? (Not applicable for conditional question)
              <input
                name="progressbar"
                type="checkbox"
                checked={progressbar === true ? true : false}
                onChange={event => {
                  const target = event.target;
                  const value = target.type === 'checkbox' ? target.checked : target.value;
                  props.setProgressbar(value);
                }
                }
              />
            </label>
          </div>
          {statusDevice !== undefined &&
            <DeviceWrapper profileId={profileId} channel={statusDevice} fetchDeviceLists={fetchDeviceLists}>
              {deviceLists.length > 0 && deviceLists.map((item, index) => <Device deviceStatusOnClick={deviceStatusOnClick} index={index} item={item} key={item._id} setDeviceActive={setDeviceActive} />)}
            </DeviceWrapper>
          }
          {
            statusOpenLink !== undefined &&
            <OpenLink setStatus={setOpenLinkStatus} disId={disId} updateLink={updateLink} openlink={openlinks} />
          }
          {
            statusQrCode !== undefined &&
            <QrCode setQrCode={setQrCode} disId={disId} qRCode={qRCode} genQrCode={genQrCode}></QrCode>
          }
        </div>
      </PreviewBody>

    </Container>
  )
}

const mapStateToProps = state => ({
  dataToPush: apiDataGeneratorSelector(state),
  enableSave: saveButtonSelector(state),
  activeDevice: activeDeviceSelector(state),
  status: state.question.status,
  progressbar: state.question.progressbar,
  // profileId: state.question.profileId,
  disId: state.question.distributionId,
  order: state.question.order,
  statusDevice: state.question.pushChannelList.find(channel => channel.channelType === 'device'),
  statusOpenLink: state.question.pushChannelList.find(channel => channel.channelType === 'openLink'),
  statusQrCode: state.question.pushChannelList.find(channel => channel.channelType === 'qrCode'),
  deviceLists: state.question.deviceList,
  openlinks: state.question.openLink,
  qRCode: state.question.qRCode,
})

const mapDispatchToProps = {
  sortQuestion,
  sortProjects,
  addCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  initQrCode,
  updateOpenLink,
  setOpenLinkInDistribution,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar
}

export default connect(mapStateToProps, mapDispatchToProps)(SortAndPreview)