import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; //need to import other styling or it wont' effect
import { FetchAllCompany, FetchAllBranch, FetchAllDepartment, 
  FetchAllDesignation, FetchAllContactByIndividualCompany, AddNewContact, GetAllCompanyProfile } from '../../../url/prmUrl';
import { Button, Input, Label, Body, Error, Aside, TextArea, Modal } from '../StyledComponents'
import Alert from '../ShowAlert'
import { get } from 'https';


const typeOptions = [
  {label: "Individual", value: "individual"},
  {label: "Company", value: "company"}
]
const bloodGroupOptions = [
    {label: "A+", value: "A+"},{label: "A-", value: "A-"},
    {label: "B+", value: "B+"},{label: "B-", value: "B-"},
    {label: "O+", value: "O+"},{label: "O-", value: "O-"},
    {label: "AB+", value: "AB+"},{label: "AB-", value: "AB-"}
  ]
const religionOptions = [
    {label: "Islam", value: "Islam"},{label: "Hinduism", value: "Hinduism"},
    {label: "Christianity", value: "Christianity"},{label: "Buddhism", value: "Buddhism"},
    {label: "Judaism", value: "Judaism"},{label: "Atheism", value: "Atheism"},
    {label: "Other", value: "Other"}
  ]
let companyOptions = []
let branchOptions = []
let departmentOptions = []
let designationOptions = []
let reportsToOptions = []
let assistantOptions = []
let referenceContactOptions = []
let leadIdOptions = []  //will be workable after lead form is created 
let ownedbyOptions = []

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.firstName && touched.firstName ? <Error>{errors.firstName}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  First Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Last Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.type && touched.type ? <Error>{errors.type}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Type:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="type"
                    value={values.type}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.type}
                  /> 
                </div>
              </Label>
              <Label css={`display: ${values.type.value==='company' ? 'flexbox' : 'none'}`}>              
                  <div css={`width: 30%;text-align:left;`}>
                    Organization:
                </div>
                <div css={`width: 60%`}>
                  <MySelect      
                    name="companyId"
                    value={values.companyId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.companyId}
                  />
                </div>
              </Label>
              <Label css={`display: ${values.type.value==='company' ? 'flexbox' : 'none'}`}>              
                  <div css={`width: 30%;text-align:left;`}>
                    Branch:
                </div>
                <div css={`width: 60%`}>
                  <MySelect      
                    name="branchId"
                    value={values.branchId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.branchId}
                  />
                </div>
              </Label>
              <Label css={`display: ${values.type.value==='company' ? 'flexbox' : 'none'}`}>              
                  <div css={`width: 30%;text-align:left;`}>
                  Department:
                </div>
                <div css={`width: 60%`}>
                  <MySelect      
                    name="departmentId"
                    value={values.departmentId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.departmentId}
                  />
                </div>
              </Label>
              <Label css={`display: ${values.type.value==='company' ? 'flexbox' : 'none'}`}>              
                  <div css={`width: 30%;text-align:left;`}>
                    Designation:
                </div>
                <div css={`width: 60%`}>
                  <MySelect      
                    name="designationId"
                    value={values.designationId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.designationId}
                  />
                </div>
              </Label>
              {errors.officePhone && touched.officePhone ? <Error>{errors.officePhone}</Error> : null}
              <Label css={`display: ${values.type.value==='company' ? 'flexbox' : 'none'}`}>
                <div css={`width: 30%;text-align:left;`}>
                  Office Phone:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="officePhone"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.officePhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.primaryPhone && touched.primaryPhone ? <Error>{errors.primaryPhone}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Primary Phone:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="primaryPhone"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.primaryPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.homePhone && touched.homePhone ? <Error>{errors.homePhone}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Home Phone:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="homePhone"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.homePhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.secondaryPhone && touched.secondaryPhone ? <Error>{errors.secondaryPhone}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Secondary Phone:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="secondaryPhone"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.secondaryPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Fax:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="fax"
                    type="text"
                    placeholder="Fax"
                    value={values.fax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.dateOfBirth && touched.dateOfBirth ? <Error>{errors.dateOfBirth}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Date of Birth:
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="dateOfBirth"
                  value={values.dateOfBirth}
                  onChange={setFieldValue}
                  
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Blood Group:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="bloodGroup"
                    value={values.bloodGroup}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.bloodGroup}
                  /> 
                </div>
              </Label>
              {errors.religion && touched.religion ? <Error>{errors.religion}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Religion:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="religion"
                    value={values.religion}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.religion}
                  /> 
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Hobby:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="hobby"
                    placeholder="Type here..."
                    value={values.hobby}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Reports To:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="reportsToId"
                    value={values.reportsToId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.reportsToId}
                  /> 
                </div>
              </Label>
              {errors.primaryEmail && touched.primaryEmail ? <Error>{errors.primaryEmail}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                    Primary Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="primaryEmail"
                    type="text"
                    placeholder="Enter Primary Email"
                    value={values.primaryEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.secondaryEmail && touched.secondaryEmail ? <Error>{errors.secondaryEmail}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                    Secondary Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="secondaryEmail"
                    type="text"
                    placeholder="Enter Secondary Email"
                    value={values.secondaryEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Assistant:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="assistantId"
                    value={values.assistantId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.assistantId}
                  /> 
                </div>
              </Label>
              {errors.assistantPhone && touched.assistantPhone ? <Error>{errors.assistantPhone}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Assistant Phone:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="assistantPhone"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.assistantPhone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.assistantMobile && touched.assistantMobile ? <Error>{errors.assistantMobile}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Assistant Mobile:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="assistantMobile"
                    type="text"
                    placeholder="Enter Mobile number"
                    value={values.assistantMobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.assistantEmail && touched.assistantEmail ? <Error>{errors.assistantEmail}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                    Secondary Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="assistantEmail"
                    type="text"
                    placeholder="Enter Email"
                    value={values.assistantEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Reference Contact:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="referenceContactId"
                    value={values.referenceContactId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.referenceContactId}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Lead:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="leadId"
                    value={values.leadId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.leadId}
                  /> 
                </div>
              </Label>
              {errors.address && touched.address ? <Error>{errors.address}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Address:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="address"
                    placeholder="Type here..."
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Contact Instruction:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="contactInstruction"
                    placeholder="Type here..."
                    value={values.contactInstruction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Special Instruction:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="specialInstruction"
                    placeholder="Type here..."
                    value={values.specialInstruction}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Description:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="description"
                    placeholder="Type here..."
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.ownedby && touched.ownedby ? <Error>{errors.ownedby}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Owned by:
                </div>
                <div css={`width: 60%`}>
                  <MySelect   
                    name="ownedby"
                    value={values.ownedby}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.ownedby}
                  /> 
                </div>
              </Label>


            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class MySelect extends React.Component {
  handleChange = value => {
    this.props.name==='type' ? this.props.onChange("type", value) :
    this.props.name==='companyId' ? this.props.onChange("companyId", value) :              
    this.props.name==='branchId' ? this.props.onChange("branchId", value) :  
    this.props.name==='departmentId' ? this.props.onChange("departmentId", value) : 
    this.props.name==='designationId' ? this.props.onChange("designationId", value) :
    this.props.name==='bloodGroup' ? this.props.onChange("bloodGroup", value) : 
    this.props.name==='religion' ? this.props.onChange("religion", value) : 
    this.props.name==='reportsToId' ? this.props.onChange("reportsToId", value) : 
    this.props.name==='assistantId' ? this.props.onChange("assistantId", value) : 
    this.props.name==='referenceContactId' ? this.props.onChange("referenceContactId", value) : 
    this.props.name==='leadId' ? this.props.onChange("leadId", value) :
    this.props.onChange("ownedby", value)
     
  };

  handleBlur = () => {
    this.props.name==='type' ? this.props.onBlur("type", true) :
    this.props.name==='companyId' ? this.props.onBlur("companyId", true) :              
    this.props.name==='branchId' ? this.props.onBlur("branchId", true) :  
    this.props.name==='departmentId' ? this.props.onBlur("departmentId", true) : 
    this.props.name==='designationId' ? this.props.onBlur("designationId", true) :
    this.props.name==='bloodGroup' ? this.props.onBlur("bloodGroup", true) : 
    this.props.name==='religion' ? this.props.onBlur("religion", true) : 
    this.props.name==='reportsToId' ? this.props.onBlur("reportsToId", true) : 
    this.props.name==='assistantId' ? this.props.onBlur("assistantId", true) : 
    this.props.name==='referenceContactId' ? this.props.onBlur("referenceContactId", true) : 
    this.props.name==='leadId' ? this.props.onBlur("leadId", true) :
    this.props.onBlur("ownedby", true)
    
  };

  render() {
    return (
        <Select
          id="color"
          placeholder={this.props.name==='type' ? "Select type" :
                        this.props.name==='companyId' ? "Select Organization" :              
                        this.props.name==='branchId' ? "Select Branch" :  
                        this.props.name==='departmentId' ? "Select Department" : 
                        this.props.name==='designationId' ? "Select Designation" :
                        this.props.name==='bloodGroup' ? "Select bloodGroup" :
                        this.props.name==='religion' ? "Select religion" : 
                        this.props.name==='reportsToId' ? "Select One" : 
                        this.props.name==='assistantId' ? "Select One" : 
                        this.props.name==='referenceContactId' ? "Select One" : 
                        this.props.name==='leadId' ? "Select Lead" :
                        "Select Profile"
                    }
          options={this.props.name==='type' ? typeOptions :
                    this.props.name==='companyId' ? companyOptions :              
                    this.props.name==='branchId' ? branchOptions :  
                    this.props.name==='departmentId' ? departmentOptions : 
                    this.props.name==='designationId' ? designationOptions :
                    this.props.name==='bloodGroup' ? bloodGroupOptions :
                    this.props.name==='religion' ? religionOptions : 
                    this.props.name==='reportsToId' ? reportsToOptions : 
                    this.props.name==='assistantId' ? assistantOptions : 
                    this.props.name==='referenceContactId' ? referenceContactOptions : 
                    this.props.name==='leadId' ? leadIdOptions :
                    ownedbyOptions
      }
          //isMulti
          onChange={this.handleChange}  
          onBlur={this.handleBlur}      
          value={this.props.value}      
        />

    );
  }
}

const DateSelect = ({ name, value, onChange }) => {
    return (
      <DatePicker
        selected={(value && new Date(value)) || null}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={val => {
          onChange(name, val);
        }}
      />
    );
  };


const Contact = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
        const fetchCompany = await axios(FetchAllCompany(localStorage.getItem("jwtID")));
        companyOptions = fetchCompany.data.Error ? [] : fetchCompany.data.map(element=>({label: element.companyName, value: element._id}))

        const fetchBranch = await axios(FetchAllBranch)
        branchOptions = fetchBranch.data.map(element=>({label: element.name, value: element._id}))

        const fetchDepartment = await axios(FetchAllDepartment)
        departmentOptions = fetchDepartment.data.map(element=>({label: element.name, value: element._id}))

        const fetchDesignation = await axios(FetchAllDesignation)
        designationOptions = fetchDesignation.data.map(element=>({label: element.name, value: element._id}))

        const result = await axios(FetchAllContactByIndividualCompany(localStorage.getItem('jwtID')));
        reportsToOptions = result.data.Error ? [] : result.data.map(element=>({label: element.firstName+" "+element.lastName, value: element._id}))
        assistantOptions = reportsToOptions 
        referenceContactOptions = reportsToOptions
        
        const getAllCompanyProfile = await axios(GetAllCompanyProfile)
        ownedbyOptions = getAllCompanyProfile.data.map(element=>({label: element.user_Name, value: element.user_Id}))

        setEditable(prev=>{  //gets all previous editable value
          return {
            ...prev,
            loaded: result.data
          }
        })
     }
     catch (err) {
      // console.log("err")
     }    

    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  const primaryPhoneDuplicacyCheck = editable.loaded ?
                  editable.loaded.Error? [] :
                  editable.loaded.map(item=>item.primaryPhone) : []

  const primaryEmailDuplicacyCheck = editable.loaded ?
                  editable.loaded.Error? [] :
                  editable.loaded.map(item=>item.primaryEmail) : []

  const schema = Yup.object().shape({
    firstName: Yup.string().required("Must Define a Name").min(3, "Minimum 3 characters"),
    type: Yup.string().required("Please select a Type"),
    officePhone: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    primaryPhone: Yup.string().required("Required primary phone")
                     .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid')
                     .notOneOf(primaryPhoneDuplicacyCheck, "Contact with this Phone Already exists"),
    homePhone: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    secondaryPhone: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    dateOfBirth: Yup.date().required("Date of Birth required"),
    religion: Yup.string().required("Please select one..."),
    primaryEmail : Yup.string().required("Required Primary Email")
                               .email("Invalid Email")
                               .notOneOf(primaryEmailDuplicacyCheck, "Contact with this Email Already exists"),
    secondaryEmail : Yup.string().email("Invalid mail"),
    assistantPhone: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    assistantMobile: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Mobile number is not valid'),
    assistantEmail : Yup.string().email("Invalid Email"),
    address : Yup.string().required("Required Address"),
    ownedby: Yup.string().required("Please select one..."),
  })
  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      userId: localStorage.getItem("jwtID"),
      firstName:"" , 
      lastName:'',
      type: '',
      companyId:'',
      branchId:'',
      departmentId:'',
      designationId:'',
      officePhone:'',
      primaryPhone:'',
      homePhone:'',
      secondaryPhone:'',
      fax:'',
      dateOfBirth:'',
      bloodGroup:'',
      religion:'',
      hobby:'',
      reportsToId:'',
      primaryEmail:'',
      secondaryEmail:'',
      assistantId:'',
      assistantPhone:'',
      assistantMobile:'',
      assistantEmail:'',
      referenceContactId:'',
      leadId:'',
      address:'',
      contactInstruction:'',
      specialInstruction:'',
      description:'',
      ownedby: '',
    }),

    handleSubmit: (values, { setSubmitting }) => { 
        const data= {
            ...values,
            type: values.type.value,
            companyId: values.companyId ? values.companyId.value : '',
            branchId: values.branchId ? values.branchId.value : '',
            departmentId: values.departmentId ? values.departmentId.value : '',
            designationId: values.designationId ? values.designationId.value : '',
            bloodGroup: values.bloodGroup ? values.bloodGroup.value : '',
            religion: values.religion ? values.religion.value : '',
            reportsToId: values.reportsToId ? values.reportsToId.value : '',
            assistantId: values.assistantId ? values.assistantId.value : '',
            referenceContactId: values.referenceContactId ? values.referenceContactId.value : '',
            ownedby: values.ownedby ? values.ownedby.value : '',
        }

        axios.post(AddNewContact, data)
        .then((response) => {
         // console.log(response)
          if(!response.data.Error){
            setView(true)
          }
          else {
            window.alert("Uexpected Error! Duplicate Primary Emial or Phone found!")
          }
        })
        .catch((err) => {
          // console.log("Error")
        })
    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
          </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Contact)