import React, { useState, useEffect } from 'react'
import { Formik } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import {css} from 'glamor'
// import countryList from 'react-select-country-list'
import { Col, Button, Input, Form, FormGroup, Label, Modal, ModalHeader, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap' 
import baseUrl from '../../url'
import showToast from '../../components/Toast/toast'
import crossEye from './asset/eye-cross.png'
import Eye from './asset/eye.png'
import { parsePhoneNumber } from 'react-phone-number-input' 
import { getName } from 'country-list'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './style.css' 

function SignUp(props) { 
  const {closeModal} = props 
  
  const [allIndustries, setAllIndustries]             = useState([]) 
  const [accountTypeId, setAccountTypeId]             = useState('') 
  const [showStar, setShowStar]                       = useState(false) 
  const [makeNameRequired, setMakeNameRequired]       = useState(false) 
  const [makeEmailRequired, setMakeEmailRequired]     = useState(false) 
  const [makePhoneRequired, setMakePhoneRequired]     = useState(false) 
  const [makeCountryRequired, setMakeCountryRequired] = useState(false) 
  const [invalidEmailMsg, setInvalidEmailMsg]         = useState(false) 
  const [showMsg, setShowMsg]                         = useState(false) 
  const [submitMsg, setSubmitMsg]                     = useState('') 
  const [displayPassword, setDisplayPassword]         = useState(false)
  const [displayPassword2, setDisplayPassword2]       = useState(false)

  // const allCountryList = countryList().getData(); 

  const styles = { 
    error: { fontSize: '11.5px', color: '#F27D7D', margin: '-10px 0 2px 15px', fontWeight: '500' }, 
    button: {background: '#1B998B', borderColor: '#1B998B', fontSize: '12px !important'} 
  } 

  // Industry
  useEffect(() => { 
    axios.get(`${baseUrl}/api/prm/industry`) 
      .then(res => setAllIndustries(res.data.data)) 
      .catch(err => console.log('Server Error')) 
  }, []) 

  // Account Type 
  useEffect(() => {
    axios.get(`${baseUrl}/api/accountType/byName/Free`) 
      .then(res => setAccountTypeId(res.data._id)) 
      .catch(err => console.log('Server Error')) 
  }, []) 

  const ErrorToast = () => showToast('Server Error') 

  const inputCss = css({ height: 'calc((1.5em + 0.3rem) + 2px) !important' }) 

  return ( 
    <div> 
      <Formik 
        initialValues={{
          industryId: '', signUpType: '', companyName: '', companyEmail: '', companyContactNo: '', companyWebsite: '', companyAddress: '', 
          userFirstName: '', userLastName: '', userEmail: '', userContactNo: '', userPassword: '', confirmPassword: '' 
        }} 
        validationSchema={ 
          Yup.object().shape({ 
            industryId:        Yup.string().required("Required").trim(), 
            signUpType:        Yup.string().required("Required").trim(), 
            companyName:       Yup.string().trim(), 
            companyEmail:      Yup.string().email().trim(), 
            companyContactNo:  Yup.string().trim(), 
            userFirstName:     Yup.string().required("Required").trim(), 
            userEmail:         Yup.string().email().required("Required").trim(), 
            // userContactNo:     Yup.string().required("Required").min(10, 'Invalid Number').trim(),  
            userContactNo:     Yup.string().min(10, 'Invalid Number').trim(),  
            userPassword:      Yup.string().required("Required").min(6).trim(), 
            confirmPassword:   Yup.string().required("Required").trim().oneOf([Yup.ref('userPassword'), null],'Passwords must match')  
          }) 
        } 
        onSubmit={(values, { resetForm }) => { 
          let data = { 
            accountTypeId:        accountTypeId, 
            industryId:           values.industryId, 
            signUpType:           values.signUpType, 
            companyName:          values.companyName.trim(), 
            companyWebsite:       values.companyWebsite.trim(), 
            companyAddress:       values.companyAddress.trim(), 
            userFirstName:        values.userFirstName.trim(), 
            userLastName:         values.userLastName.trim(), 
            country:              values.country, 
            country:              values.userContactNo.trim() ? getName(parsePhoneNumber(values.userContactNo.trim()).country) : '', 
            userEmail:            values.userEmail.trim(), 
            userContactNo:        values.userContactNo.trim(), 
            userPassword:         values.userPassword.trim(), 
            confirmPassword:      values.confirmPassword.trim() 
          } 

          axios.post(`${baseUrl}/api/registration/`, data) 
            .then(res => { 
              if(res.data.isValid) { setShowMsg(true); resetForm(); closeModal('Thanks for joining us. Please check your email to verify.') } 
              else { setInvalidEmailMsg(true); setSubmitMsg(res.data.message.error);} 
            }) 
            .catch(err => ErrorToast()) 
        }}> 
        { 
          props => ( 
            <Form onSubmit={(e) => { 
              e.preventDefault() 
              if(!makeNameRequired) { props.handleSubmit() } 
            }}> 
              {/* Industry ID */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="industryId" id="industryId" 
                  value={props.values.industryId} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.industryId && props.touched.industryId ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden>Select an industry...</option> 
                  { !allIndustries.length && <option value="" disabled defaultValue>No data</option> } 
                  { 
                    allIndustries.length && 
                    allIndustries.map(obj => <option value={obj._id} key={obj._id}> {obj.name} </option> ) 
                  } 
                </Input> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.industryId && props.touched.industryId && 
                <p style={styles.error}> {props.errors.industryId} </p> 
              } 

              {/* signup type */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="signUpType" id="signUpType" 
                  value={props.values.signUpType} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ border: props.errors.signUpType && props.touched.signUpType ? '1px solid #F27D7D' : null }} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden>Select a signup type...</option> 
                  <option value="Individual"> Individual </option> 
                  <option value="Company"> Organization / Company </option> 
                </Input> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
                { props.values.signUpType === 'Company' ? setShowStar(true)         : setShowStar(false) } 
                { props.values.signUpType === 'Company' ? setMakeNameRequired(true) : setMakeNameRequired(false) } 
              </FormGroup> 
              { 
                props.errors.signUpType && props.touched.signUpType && 
                <p style={styles.error}> {props.errors.signUpType} </p> 
              } 

              {/* Country */} 
              <>
                {/* <FormGroup row style={{marginBottom: '0.5em'}}> 
                  <Label htmlFor="country" sm={4} style={{fontSize: '14px'}}> 
                    Country: <span style={{color: 'red'}}>{` *`}</span> 
                  </Label> 
                  <Col sm={8}> 
                    <Input type="select" name="country" id="country" 
                      value={props.values.country} 
                      onChange={props.handleChange} 
                      style={{
                        height: 'calc(1.5em + 0.5rem + 2px)', 
                        border: props.errors.country && props.touched.country ? '1px solid red' : null
                      }} 
                      onBlur={props.handleBlur}> 
                      <option value="" disabled defaultValue hidden
                        style={{fontSize: '15px', color: '#768192', fontWeight: '400'}}>
                        Select...
                      </option> 
                      { !allCountryList.length && <option value="" disabled defaultValue>No data</option> } 
                      { 
                        allCountryList.length && 
                        allCountryList.map(obj => <option value={obj.label} key={obj.label}> {obj.label} </option> ) 
                      } 
                    </Input> 
                    { 
                      props.errors.country && props.touched.country && 
                      <p style={styles.error}> {props.errors.country} </p> 
                    } 
                  </Col> 
                </FormGroup>  */} 
              </> 
              {
                showStar && 
                <>
                  {/* Company Name */}
                  <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                    <Input type="text" name="companyName" id="companyName"
                      value={props.values.companyName}
                      onChange={props.handleChange} 
                      className="inputField ml-3" {...inputCss} 
                      style={{ 
                        border: makeNameRequired || (props.errors.companyName && props.touched.companyName) ? '1px solid #F27D7D' : null 
                      }} 
                      onBlur={props.handleBlur} placeholder="Organization Name"
                      autoComplete="off"
                    /> 
                    <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
                    { 
                      props.values.companyName 
                      ? setMakeNameRequired(false) 
                      : props.values.accountType === 'Company' && setMakeNameRequired(true) 
                    } 
                  </FormGroup> 
                  { makeNameRequired && <p style={styles.error}> Required </p> } 
                
                  {/* Website */}
                  <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                    <Input type="text" name="companyWebsite" id="companyWebsite"
                      value={props.values.companyWebsite} 
                      onChange={props.handleChange} 
                      className="inputField ml-3" {...inputCss} 
                      onBlur={props.handleBlur} placeholder="Organization Website" 
                      autoComplete="off"
                    /> 
                  </FormGroup> 
                
                  {/* Address */}
                  <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                    <Input type="text" name="companyAddress" id="companyAddress"
                      value={props.values.companyAddress} 
                      onChange={props.handleChange} 
                      className="inputField ml-3" {...inputCss} 
                      onBlur={props.handleBlur} placeholder="Organization Address" 
                      autoComplete="off"
                    /> 
                  </FormGroup> 
                </> 
              } 


              {/* First Name */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="userFirstName" id="userFirstName"
                  value={props.values.userFirstName} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.userFirstName && props.touched.userFirstName ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur} placeholder="First Name" 
                  autoComplete="off"
                /> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.userFirstName && props.touched.userFirstName && 
                <p style={styles.error}> {props.errors.userFirstName} </p> 
              } 
            
              {/* Last Name */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="userLastName" id="userLastName"
                  value={props.values.userLastName} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  onBlur={props.handleBlur} placeholder="Last Name" 
                  autoComplete="off"
                /> 
              </FormGroup> 
              
              {/* Email */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="userEmail" id="userEmail"
                  value={props.values.userEmail} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.userEmail && props.touched.userEmail ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur} placeholder="Email" 
                  autoComplete="off"
                /> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.userEmail && props.touched.userEmail && 
                <p style={styles.error}> {props.errors.userEmail} </p> 
              } 
            
            
              {/* Phone Number */}
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <PhoneInput 
                  placeholder="Phone Number" name="userContactNo"
                  countryCodeEditable={false} 
                  value={props.values.userContactNo} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.userContactNo && props.touched.userContactNo ? '1px solid #F27D7D' : null, 
                    width: '277px', borderRadius: '20px'
                  }} 
                  onBlur={() => props.setFieldTouched('userContactNo', true)} 
                  onChange={(data) => props.setFieldValue('userContactNo', data, true)} 
                /> 
                {/* <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span>  */}
              </FormGroup> 
              { 
                props.errors.userContactNo && props.touched.userContactNo && 
                <p style={styles.error}> {props.errors.userContactNo} </p> 
              } 


              {/* User Password */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <InputGroup> 
                  <Input type={displayPassword ? 'text' : 'password'} name="userPassword" id="userPassword" className="signupPassword" 
                    value={props.values.userPassword} onChange={props.handleChange} 
                    className="passwordCSS inputField ml-3" {...inputCss} 
                    style={{ 
                      border: props.errors.userPassword && props.touched.userPassword ? '1px solid #F27D7D' : null 
                    }} 
                    onBlur={props.handleBlur} placeholder="Password" 
                    autoComplete="off" 
                  /> 

                  <InputGroupAddon addonType="append"> 
                    <InputGroupText style={{borderRadius: '0 20px 20px 0'}}> 
                      { displayPassword && <img width="18px" onClick={() => { setDisplayPassword(false) }} src={Eye} /> } 
                      { !displayPassword && <img width="18px" onClick={() => { setDisplayPassword(true) }} src={crossEye} /> } 
                    </InputGroupText> 
                  </InputGroupAddon> 
                </InputGroup> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.userPassword && props.touched.userPassword && 
                <p style={styles.error}> {props.errors.userPassword} </p> 
              } 
            
              {/* Confirm Password */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <InputGroup> 
                  <Input type={displayPassword2 ? 'text' : 'password'} name="confirmPassword" id="confirmPassword"
                    value={props.values.confirmPassword} onChange={props.handleChange} 
                    className="passwordCSS inputField ml-3" {...inputCss} 
                    style={{ 
                      border: props.errors.confirmPassword && props.touched.confirmPassword ? '1px solid #F27D7D' : null 
                    }} 
                    onBlur={props.handleBlur} placeholder="Confirm Password" 
                    autoComplete="off"
                  /> 

                  <InputGroupAddon addonType="append"> 
                    <InputGroupText style={{borderRadius: '0 20px 20px 0'}}> 
                      { displayPassword2 && <img width="18px" onClick={() => { setDisplayPassword2(false) }} src={Eye} /> } 
                      { !displayPassword2 && <img width="18px" onClick={() => { setDisplayPassword2(true) }} src={crossEye} /> } 
                    </InputGroupText> 
                  </InputGroupAddon> 
                </InputGroup> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.confirmPassword && props.touched.confirmPassword && 
                <p style={styles.error}> {props.errors.confirmPassword} </p> 
              } 
            
              <Button type="submit" className="btnHover mr-2" size="sm" style={{...styles.button, fontSize: '13px'}}> 
                Sign Up 
              </Button> 
              
              <Button className="btnHover" size="sm" onClick={() => closeModal()} style={{...styles.button, fontSize: '13px'}}> 
                Cancel 
              </Button> 

              <p className="mt-3" style={{fontSize: '10px', color: 'rgb(52, 58, 64)', textAlign: 'justify'}}>
                By clicking ‘Create account’ or signing up, you agree to the {` `}
                <a href="/terms" target="_blank">Terms of service</a> {` `}
                and {` `}
                <a href="/terms#PS-pps" target="_blank">Privacy Policy</a>. {` `}
                You also agree to receive information and offers relevant to our services via email. You can unsubscribe the emails anytime.
              </p> 
            </Form> 
          ) 
        } 
      </Formik> 

      <> 
        {/* Invalid email */}
        <Modal isOpen={invalidEmailMsg} className={props.className} style={{top: '150px'}}> 
          <ModalHeader style={{color: 'rgb(244, 60, 60)', padding: '0.5rem 1rem'}}> 
            Error 
          </ModalHeader> 
          <ModalBody> 
            { submitMsg ? submitMsg : 'Error Occured' } 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setInvalidEmailMsg(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 
    </div> 
  ) 
} 

export default SignUp; 
