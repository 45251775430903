import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'reactstrap'
import Table from './Table'
import { getAll_ } from '../../store/actions/actionCreator'
import showToast from '../../../../../components/Toast/toast'


function Index() { 
    const dispatch = useDispatch()
    let { allData } = useSelector(state => state.industry) 

    try { 
        if(!Object.keys(allData).length) { allData.data = []; } 
    } 
    catch (error) { } 

    const columns = React.useMemo(() => 
        [ 
            { Header: 'Name', accessor: 'name' }, 
            { Header: 'Active', accessor: 'active' }, 
            { Header: 'Created Date', accessor: 'createdAt' }, 
        ], [] 
    ) 

    const ErrorToast = () => showToast('Server Error') 
    useEffect(() => { dispatch(getAll_(ErrorToast)) }, []) 

    return ( 
        <div className="animated fadeIn"> 
            <Card style={{ border: '0px', background: 'transparent' }}> 
                { <Table columns={columns} data={allData.data ? allData.data.reverse() : []} /> } 
            </Card> 
        </div> 
    ) 
} 

export default Index
