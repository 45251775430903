import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import { Provider, connect } from 'react-redux'
import { BrowserRouter, Route, Switch, withRouter, Link, Redirect } from 'react-router-dom'
import SelectPage from './pages/SelectPage'
import EditSelectPage from './pages/EditSelectPage'
// import EditSelectPageWithoutDisid from './pages/EditSelectWithoutDisid'
import SortAndPreview from './pages/SortAndPreview'
import SortAndPreviewWithoutdisId from './pages/SortAndPreviewWithoutdisId'
import EditQuestion from './pages/EditQuestion'
import EditSortAndPreview from './pages/EditSortAndPreview'
import EditSortandPreviewWithoutDisid from './pages/EditSortAndPreviewWithoutDisid'
import EditChannel from './pages/EditChannel'
import EditPreview from './pages/EditPreview'
import PrivateRoute from './pages/PrivateRoute'
import Preview from './pages/Preview'
import SelectChannel from './pages/SelectChannel'
import List from './pages/List'
import reducer from './redux/reducers'
import sidebarState from './redux/actions/sidebarState'
import SelectQuestion from './pages/SelectQuestion'

import { nextButtonSelector, projectPropertySelector } from './redux/selectors'

import './style.css'

const Routes = props => {
  const { match, questionsSelected, redirectPreview } = props

  const redirect = !questionsSelected;

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(sidebarState(props.sidebarState))
  })

  return (
    <BrowserRouter>

      <Switch>

        <Route exact path={`${match.path}`} component={List} />

        <Route exact path={`${match.path}/question/:surveyid`} component={SelectQuestion} />

        {/* <Route exact path={`${match.path}/create`} component={SelectPage} /> */}

        <Route
          // redirect={redirect}
          exact
          component={SortAndPreview}
          path={`${match.path}/create/sortandpreview/:surveyid`}
        />
        <Route
          // redirect={redirect}
          exact
          component={SortAndPreviewWithoutdisId}
          path={`${match.path}/create/sortandpreviewwoid/:surveyid`}
        />
        <Route
          // redirect={redirect}
          component={Preview}
          path={`${match.path}/create/preview/:surveyid`}
        />
        <Route
          // redirect={redirect}
          component={SelectChannel}
          path={`${match.path}/create/channel/:surveyid`}
        />

        {/* edit section */}

        <Route exact path={`${match.path}/edit/question/:surveyid`} component={EditQuestion} />

        {/* <Route
          // redirect={redirect}
          exact
          path={`${match.path}/edit`}
          component={EditSelectPageWithoutDisid}
        /> */}

        <Route
          // redirect={redirect}
          exact
          path={`${match.path}/edit/sortandpreviewwoid/:surveyid`}
          component={EditSortandPreviewWithoutDisid}
        />

        <Route
          // redirect={redirect}
          exact
          path={`${match.path}/edit/:disid`}
          component={EditSelectPage}
        />

        <Route
          // redirect={redirect}
          exact
          path={`${match.path}/edit/sortandpreview/:surveyid`}
          component={EditSortAndPreview}
        />

        <Route
          // redirect={redirect}
          component={EditPreview}
          path={`${match.path}/edit/preview/:surveyid`}
        />

        <Route
          // redirect={redirect}
          component={EditChannel}
          path={`${match.path}/edit/channel/:surveyid`}
        />

        <Route render={() => <div>Error 404! Page Not Found</div>} />

      </Switch>

    </BrowserRouter>
  )
}

const mapStateToProps = state => ({
  questionsSelected: nextButtonSelector(state),
  redirectPreview: !(projectPropertySelector(state) && !nextButtonSelector(state))
})

const ReduxConnectedRoutes = withRouter(connect(mapStateToProps)(Routes))



const App = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
  ))



  return (
    <Provider store={store}>
      <ReduxConnectedRoutes sidebarState={props.sidebarState} />
    </Provider>
  );
}

export default App;