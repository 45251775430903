import React, { useState, useEffect } from 'react'
import { Card } from 'reactstrap'
import Table from './Table'
import axios from 'axios'
import baseUrl from '../../../../url/index'

function Index() {

    const userId = localStorage.getItem('jwtProfileId')
    const [projects, setProjects] = useState([])

    useEffect(
        () => {
            axios.get(`${baseUrl}/api/project/survey/profile/${userId}/?page=1&limit=500`).then(res => {
                // console.log(res.data.projectList)
                if (res && Object.keys(res.data).length > 1) {
                    setProjects(res.data.projectList)
                }
            })
        },
        []
    )


    const columns = React.useMemo(() => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Feature', accessor: 'featureinfo.name' },
        { Header: 'No of questions', accessor: 'questionCount' },
        { Header: 'Status', accessor: 'status' },
        { Header: 'Active', accessor: 'active' },
        // { Header: 'Status', accessor: 'status' },
        { Header: 'Created By', accessor: 'createdBy.name' },
        { Header: 'Created Date', accessor: 'createdAt' },

    ], []
    )

    return <div className="animated fadeIn survey-table">
        {
            projects.length === 0 ?
                <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                    <Table columns={columns} data={[]} />
                </Card>
                :
                <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                    <Table columns={columns} data={projects.reverse()} />
                </Card>
        }
    </div>
}

export default Index