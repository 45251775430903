import React, {useState} from 'react'
import ScreenModal from '../modal/modal'
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap'
import ReqDemoModal from '../../../LandingPage/components/ReqDemo/ReqDemoModal'
import {css} from 'glamor'
import './style.css'

function Compare(props) { 
  const [showFreeModal, setShowFreeModal] = useState(false) 
  const [successMsgModal, setSuccessMsgModal] = useState(false) 
  const [reqDemo, setReqDemo] = useState(false); 

  const style = {
    btnStyle: {backgroundColor: '#1B998B', color: '#fff', borderRadius: '0%'}, 
    pStyle: {color: 'black', fontSize: '15px', fontWeight: '500', lineHeight: '1.2'}
  } 
  const cancelModal = () => setReqDemo(false); 
  const closeModal = (msg = null) => {  
    if(msg) { 
      setSuccessMsgModal(true) 
    } 
    setShowFreeModal(false) 
  } 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }
  } 

  const textColor = css({ color: 'rgb(52, 58, 64) !important', fontWeight: '400' }) 
  const headerColor = css({ color: 'rgb(52, 58, 64)', fontWeight: '500' }) 
  const iconColor = css({ color: 'rgb(52, 58, 64) !important' })

  return ( 
    <div className="container"> 

      <> 
        {/* Signup Modals for free, basic, standard, pro, advanced */} 
        <> 
          { showFreeModal && <ScreenModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} /> } 
        </> 

        {/* Signup success message */}
        <>
          <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
            {/* { closeModal() }  */}
            <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
              You are Done!!!
            </ModalHeader> 
            <ModalBody> 
              Thank you for joining us. Please check your email to verify. 
              <br /> 
              <Button className="btnHover mt-3" size="sm" style={styles.button}
                onClick={() => setSuccessMsgModal(false)}> 
                Close 
              </Button> 
            </ModalBody> 
          </Modal> 
        </> 

        {/* Request Demo */} 
        { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
      </> 


      <div className="row">
        <div className="col-md-12 mt-2 mx-auto">
          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsve">
                <table className="table table-striped table-hover">
                  <thead className="thead-inverse">
                    <tr>
                      <th className="w-25 text-center" 
                        style={{verticalAlign: 'middle', fontWeight: '400', color: 'rgb(52, 58, 64)', 
                          fontSize: '18px'}}>
                        Choose your suitable plan
                      </th>
                      
                      <th className="text-center" {...headerColor}>
                        Free 
                        <h6>$0/month</h6> 
                        <h6 className="text-center" style={{fontSize: '11px', visibility: 'hidden'}}>
                          $10/month <br/> billed annually
                        </h6> 
                        <button className="btn btn-sm btn-block btnHover" 
                          style={style.btnStyle} onClick={() => setShowFreeModal(true)}>
                          Join Free  
                        </button> 
                      </th> 

                      <th className="text-center" {...headerColor}>
                        Basic 
                        <h6 className="pro" {...headerColor}>$15/month</h6> 
                        <h6 className="text-center" style={{fontSize: '11px'}}>
                          $10/month <br/> billed annually
                        </h6> 
                        <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
                          Request Demo 
                        </button> 
                      </th>
                      
                      <th className="text-center" {...headerColor}>
                        Standard
                        <h6 {...headerColor}>$39/month</h6> 
                        <h6 className="text-center" style={{fontSize: '11px'}}>
                          $35/month <br/> billed annually
                        </h6> 
                        <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
                          Request Demo 
                        </button> 
                      </th> 
                      
                      <th className="text-center" {...headerColor}>
                        Pro 
                        <h6 {...headerColor}>$69/month</h6> 
                        <h6 className="text-center" style={{fontSize: '11px'}}>
                          $65/month <br/> billed annually
                        </h6> 
                        <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
                          Request Demo 
                        </button> 
                      </th>
                      
                      <th className="text-center" {...headerColor}> 
                        Advanced 
                        <h5 className="text-center mt-2" style={{marginBottom: '18px', fontSize: '14px'}}>
                          Let's talk 
                        </h5> 
                        <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
                          Request Demo 
                        </button> 
                      </th> 
                    </tr> 
                  </thead> 
                  <tbody className="priceCompareRowHover"> 
                    {/* row 1 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Response</td>
                      <td className="text-center">100/month</td>
                      <td className="text-center">1000/month</td>
                      <td className="text-center">5000/month</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                    </tr> 

                    {/* row 2 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">User</td>
                      <td className="text-center">1</td>
                      <td className="text-center">1</td>
                      <td className="text-center">5</td>
                      <td className="text-center">10</td>
                      <td className="text-center">As per <br/> reqirement</td>
                    </tr> 

                    {/* row 3 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Question Type</td>
                      <td className="text-center">Basic</td>
                      <td className="text-center">10</td>
                      <td className="text-center">10</td>
                      <td className="text-center">All</td>
                      <td className="text-center">All</td>
                    </tr> 

                    {/* row 4 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Logic</td>
                      <td className="text-center">Mimimum</td>
                      <td className="text-center">All</td>
                      <td className="text-center">All</td>
                      <td className="text-center">All</td>
                      <td className="text-center">All</td>
                    </tr> 

                    {/* row 5 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Number of question in a project</td>
                      <td className="text-center">10</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                    </tr> 

                    {/* row 6 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Number of project</td>
                      <td className="text-center">3/m</td>
                      <td className="text-center">10/m</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                      <td className="text-center">Unlimited</td>
                    </tr> 

                    {/* row 7 */} 
                    <tr {...textColor}> 
                      <td className="w-25 option">Advance Distribution</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 8 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Ads & Watermark</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                    </tr> 

                    {/* row 9 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Core Analysis</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 10 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Advance Analysis</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 11 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Trend Analysis</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 12 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Comparative Analysis</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 13 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Feature</td> 
                      <td className="text-center">Random <br/> Survey, Form</td> 
                      <td className="text-center">Random <br/> Survey, Form</td> 
                      <td className="text-center">Random <br/> Survey, Form</td> 
                      <td className="text-center">Standard features 
                        <br /> + <br/> Customer Experience / <br /> Employee Experience <br /> 
                        <span style={{fontSize: '12px'}}>(anyone)</span>
                      </td> 
                      <td className="text-center">Unlimited</td> 
                    </tr> 

                    {/* row 14 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Objective</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 15 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Channel</td> 
                      <td className="text-center">Openlink, <br/> QR code, <br/> Social media</td> 
                      <td className="text-center">Openlink, <br/> QR code, <br/> Social media, <br/> Email, <br/> Embaded <br /> Code</td> 
                      <td className="text-center">Openlink, <br/> QR code, <br/> Social media, <br/> Email, 
                        <br/> Embaded <br /> Code, <br/> Offline <br /> Mobile App</td> 
                      <td className="text-center">All</td> 
                      <td className="text-center">All</td> 
                    </tr> 

                    {/* row 16 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">API</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 17 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Multiple Branch</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center">Up to 10</td> 
                      <td className="text-center">As per <br/> requirement</td> 
                    </tr> 

                    {/* row 18 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Qr code predetermined</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 19 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Open link predetermined</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 20 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Own Branding</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 21 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Design</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 22 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Export Data</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 23 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Export Report</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 24 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Customized Analysis</td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-times"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                      <td className="text-center"><i {...iconColor} className="fa fa-check"></i></td> 
                    </tr> 

                    {/* row 25 */} 
                    <tr {...textColor}>
                      <td className="w-25 option">Support</td> 
                      <td className="text-center">Minimum</td> 
                      <td className="text-center">Moderate</td> 
                      <td className="text-center">Moderate</td> 
                      <td className="text-center">24x7</td> 
                      <td className="text-center">24x7</td> 
                    </tr> 
                  </tbody> 
                </table> 
              </div> 
            </div> 
          </div> 
        </div> 
      </div> 
    </div> 
  ) 
} 

export default Compare; 
