import React, { useState } from 'react'
import image from './Assets/NPS_2.png'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import './style.css'

function NPSsection() {
  const [reqDemo, setReqDemo] = useState(false);
  const cancelModal = () => setReqDemo(false);

  let style = {
    ourBtn: {
      backgroundColor: '#1B998B',
      borderColor: '#1B998B',
      color: 'white',
      borderRadius: '0%'
    }
  }

  return (
    <div className="container boxStyle">
      <div className="row align-items-center">
        <div className="col-sm-12 col-md-6 col-lg-6 orderText">
          <div className="d-block d-sm-none orderImg">
            <div className="p-md-2 p-lg-5" style={{ width: '100%' }}>
              <img className="img-fluid" src={image} alt="Net Promoter Score" />
            </div>
          </div>
          <div className="p-md-2 p-lg-5" style={{ width: '100%' }}>
            <h6 className="display-4 heading mb-3">
              Measure your net <br />
              promoter score (NPS)
            </h6>
            <p className="text">
              Net Promoter Score (NPS) is a customer loyalty score. It gauges customer satisfaction and loyalty. It measures the willingness of your customers to recommend your company and predicts business growth. Nowadays it is used as a core measurement for customer experience management(CEM) programs.
            </p>
            <Button className="btn btn-sm btnHover"
              onClick={() => setReqDemo(true)}>
              Request Demo
            </Button>
          </div>
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 d-none d-sm-block orderImg">
          <div className="p-md-2 p-lg-5" style={{ width: '100%' }}>
            <img className="img-fluid" src={image} alt="Net Promoter Score" />
          </div>
        </div>
      </div>

      {/* Request Demo */}
      {reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} />}
    </div>
  )
}

export default NPSsection; 
