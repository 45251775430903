const likertScale = [
    {
        label: "Agree - Disagree",
        value: [
            "Strongly disagree",
            "Disagree",
            "Somewhat disagree",
            "Neither disagree nor agree",
            "Somewhat agree",
            "Agree",
            "Strongly agree"
        ]
    },
    {
        label: "Satisfied - Dissatisfied",
        value: [
            "Extremely dissatisfied",
            "Dissatisfied",
            "Slightly dissatisfied",
            "Neither dissatisfied nor satisfied",
            "Slightly satisfied",
            "Satisfied",
            "Extremely satisfied"
        ]
    },
    {
        label: "Appropriate - Inappropriate",
        value: [
            "Extremely inappropriate",
            "Inappropriate",
            "Slightly inappropriate",
            "Neutral",
            "Slightly appropriate",
            "Appropriate",
            "Extremely appropriate"
        ]
    },
    {
        label: "True - False",
        value: [
            "Definitely false",
            "False",
            "Probably false",
            "Neither true nor false",
            "Probably true",
            "True",
            "Definitely true"
        ]
    },
    {
        label: "Yes - No",
        value: [
            "Definitely no",
            "No",
            "Probably no",
            "Might or might not",
            "Probably yes",
            "Yes",
            "Definitely yes"
        ]
    },
    {
        label: "Above Average - Below Average",
        value: [
            "Far below average",
            "Moderately below average",
            "Slightly below average",
            "Average",
            "Slightly above average",
            "Moderately above average",
            "Far above average"
        ]
    }, {
        label: "Better - Worse",
        value: [
            "Much worse",
            "Moderately worse",
            "Slightly worse",
            "About the same",
            "Slightly better",
            "Moderately better",
            "Much better"
        ]
    },
    {
        label: "Difficult - Easy",
        value: [
            "Extremely difficult",
            "Difficult",
            "Slightly difficult",
            "Neither difficult nor easy",
            "Slightly easy",
            "Easy",
            "Extremely easy"
        ]
    },
    {
        label: "Effective - Ineffective",
        value: [
            "Extremely ineffective",
            "Ineffective",
            "Slightly ineffective",
            "Neither ineffective nor effective",
            "Slightly effective",
            "Effective",
            "Extremely effective"
        ]
    },
    {
        label: "Good - Bad",
        value: [
            "Very bad",
            "Bad",
            "Slightly bad",
            "Neither good nor bad",
            "Slightly good",
            "Good",
            "Very good"
        ]
    },
    {
        label: "Delightful - Regretful",
        value: [
            "Extremely regretful", 
            "Regretful", 
            "Slightly regretful", 
            "Neither regretful nor delightful", 
            "Slightly delightful", 
            "Delightful", 
            "Extremely delightful"
        ]
    },
    {
        label: "Believable - Unbelievable",
        value: [
            "Extremely Unbelievable",
            "Unbelievable",
            "Slightly unbelievable",
            "Neither believable nor unbelievable",
            "Slightly believable",
            "Believable",
            "Extremely believable"
        ]
    },
    {
        label: "Fast - Slow",
        value: [
            "Very slow",
            "Slow",
            "Slightly slow",
            "Neither slow nor fast",
            "Slightly fast",
            "Fast",
            "Very fast"
        ]
    },
    {
        label: "Likely - Unlikely",
        value: [
            "Highly unlikely", 
            "Unlikely", 
            "Slightly unlikely", 
            "Neither unlikely nor likely", 
            "Slightly likely", 
            "Likely", 
            "Highly likely" 
        ]
    },
    {
        label: "Happy - Unhappy",
        value: [
            "Very unhappy", 
            "Unhappy", 
            "Slightly unhappy", 
            "Neither happy nor unhappy", 
            "Slightly happy", 
            "Happy", 
            "Very happy" 
        ]
    },
    {
        label: "Useful - Useless",
        value: [
            "Extremely useless", 
            "Useless", 
            "Slightly useless", 
            "Neither useless nor useful", 
            "Slightly useful", 
            "Useful", 
            "Extremely useful"
        ]
    },
    {
        label: "Pleased - Displeased",
        value: [
            "Extremely unpleased", 
            "Unpleased", 
            "Slightly unpleased", 
            "Neither unpleased nor pleased", 
            "Slightly pleased", 
            "Pleased", 
            "Extremely pleased"
        ]
    },
    {
        label: "Like - Dislike",
        value: [
            "Totally disliked", 
            "Disliked", 
            "Slightly disliked", 
            "Neither disliked nor liked", 
            "Slightly liked", "Liked", 
            "Extremely liked"
        ]
    },
    {
        label: "Quantity",
        value: [
            "Very less", 
            "Less", 
            "Slightly less", 
            "Neither less nor high", 
            "Slightly high", 
            "High", 
            "Very high"
        ]
    },

]


export default likertScale