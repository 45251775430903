import React from 'react';
import { dataFormater } from '../../Helper.js';
import { LayoutSwitcher } from '../../layoutSwitcher/LayoutSwitcher.js';


const Analytic = props => {

    const tag = props.tag;
    let element = '';
    let lowLevelTitle = "";
    const formatedData = dataFormater(props.data);
    // console.log({formatedData});
    if (Object.keys(props.data).length !== 0 && props.data.constructor === Object) {
        lowLevelTitle = props.data['objectiveDetails']['name'];
    }
    element = LayoutSwitcher(tag, formatedData, props.lowLevelId, lowLevelTitle);
    return element;
}

export default Analytic;