import styled from 'styled-components/macro'
import React, { useState, useEffect } from 'react'
import { Formik, } from 'formik'
import * as Yup from 'yup'
// import { useState, useEffect } from React
import axios from 'axios'
import baseUrl from '../../url'

import {
  Button,
  CardSignUp,
  Error,
  InputSignUp,
  LabelFormikSignUp,
  StyledLink
} from '../StyledComponents'
import Logo from '../../resources/PEX_Logo.png'


const schema = Yup.object().shape({
  name: Yup.string()
    .required('Name is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Invalid Email'),
  company: Yup.string()
    .required('Company name is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Minimum 6 characters'),
  password2: Yup.string()
    .required('Password match is required')
    .min(6, 'Minimum 6 characters')
    .oneOf([Yup.ref('password'), null], "Passwords must match"),
  industryId: Yup.string()
    .required('Industry name is required')
})


const SignUp = () => {

  const [industryList, setIndustryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successMessageText, setSuccessMessageText] = useState('Your acount has been successfully registered! Please check your mail to confirm.');
  const [successMessage, setSuccessMessage] = useState(false)

  useEffect(() => {

    const fetchData = async () => {
      const result = await axios(`${baseUrl}/api/prm/industry`);
      setIndustryList(result.data.data)
      setLoading(false)
    };
    fetchData();

  }, []);


  if (loading) {
    return 'Loading...'
  }

  return <div style={{ display: 'flex', width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', background: '#310D31' }}>
    <CardSignUp style={{ display: 'block', position: 'relative', background: '#fff', padding: '15px', borderRadius: '4px' }}>
      <img alt="PEX Logo" src={Logo} css={`width:150px; margin-bottom:5px; display: block; margin: 10px auto`} />
      {/* <div css={`
        font-family: 'Roboto', sans-serif;
        text-align: center;
        font-size: 20px;
        font-weight: 700;
        color: #574b7a;
      `}>
      Sign Up
      </div> */}
      {successMessage && <p style={{ color: '#333', margin: '0 20px', fontSize: '16px' }}>{successMessageText}</p>}

      <Formik
        initialValues={{
          name: "",
          company: "",
          email: "",
          password: "",
          password2: "",
          industryId: "",
          withpex: true,
          withcrm: false,
          address: "",
          website: "",
          mobile: ""
        }}
        validationSchema={schema}
        onSubmit={(values) => {
          axios.post(`${baseUrl}/api/registration/register`, { ...values, "type": "SuperUser" })
            .then(res => { setSuccessMessage(true); })
            .catch(err => console.log(err));
        }
        }
        render={({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
          <form onSubmit={handleSubmit}>

            <LabelFormikSignUp>
              Name {errors.name && touched.name ? <Error>*{errors.name}</Error> : null}
              <InputSignUp
                name="name"
                // placeholder="Full Name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Company Name {errors.company && touched.company ? <Error>*{errors.company}</Error> : null}
              <InputSignUp
                name="company"
                // placeholder="Company Name"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.company}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Email {errors.email && touched.email ? <Error>*{errors.email}</Error> : null}
              <InputSignUp
                name="email"
                // placeholder="Email"
                type="email"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Password {errors.password && touched.password ? <Error>*{errors.password}</Error> : null}
              <InputSignUp
                name="password"
                // placeholder="Password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Confirm Password {errors.password2 && touched.password2 ? <Error>*{errors.password2}</Error> : null}
              <InputSignUp
                name="password2"
                // placeholder="Confirm Password"
                type="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password2}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Address
              <InputSignUp
                name="address"
                // placeholder="Address"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.address}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Website
              <InputSignUp
                name="website"
                // placeholder="website"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.website}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              Mobile
              <InputSignUp
                name="mobile"
                // placeholder="mobile"
                type="text"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.mobile}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp> Industry {errors.industryId && touched.industryId ? <Error>*{errors.industryId}</Error> : null}
              <select
                name="industryId"
                value={values.industryId}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ display: 'block' }}
              >
                <option value="" label="Select option here" />
                {industryList.map(industry => <option value={industry._id} label={industry.name} key={industry.name} />)}

              </select>
            </LabelFormikSignUp>

            <LabelFormikSignUp>
              With PEX
              <input
                name="withpex"
                // placeholder="With PEX"
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.withpex}
                value={values.withpex}
              />
            </LabelFormikSignUp>

            <LabelFormikSignUp style={{ marginTop: '0px' }}>
              With CRM
              <input
                name="withcrm"
                // placeholder="With CRM"
                type="checkbox"
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.withcrm}
                value={values.withcrm}
              />
            </LabelFormikSignUp>

            <Button type="submit" style={{ marginTop: '30px' }}>Sign Up</Button>
            {/* <StyledLink to="/">Sign In</StyledLink> */}
          </form>
        )}
      />

    </CardSignUp >

  </div>

}
export default SignUp
