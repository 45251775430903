import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { Card } from 'reactstrap';
import showToast from '../../components/Toast/toast';
import baseUrl from '../../url';
import Table from './components/Table';
import { MainBody, Body } from './StyledComponents';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb';
// import Table from './Table';

const Project = (props) => {
  const projectId = props.match.params.projectId;
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchBulkEmails = async () => {
      try {
        const res = await axios.get(`${baseUrl}/api/ticket/${projectId}`);

        if (res.data.length > 0) {
          setData(res.data);
        } else {
          setData([]);
        }
      } catch (error) {
        showToast('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchBulkEmails();
  }, [projectId]);

  const columns = React.useMemo(() => {
    return [
      { Header: 'Ticket ID', accessor: 'uid' },
      { Header: 'Contact', accessor: '' },
      { Header: 'Priority', accessor: 'priority' },
      { Header: 'Status', accessor: 'status' },
      { Header: 'Assignee', accessor: '' },
    ];
  }, []);

  return (
    <MainBody>
      <div className='mx-3 mt-2'>
        <Breadcrumb path='Ticket / Recommendation' fontSize='16px' />
      </div>

      <div className='animated fadeIn' style={{ padding: '20px' }}>
        <Card style={{ border: '0px', background: 'transparent' }}>
          <Table columns={columns} data={data} />
        </Card>
      </div>
    </MainBody>
  );
};

export default withRouter(Project);
