import React, { useState, useEffect } from 'react';
import {useDispatch} from 'react-redux'
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import { Provider, connect } from 'react-redux'
import { BrowserRouter, Route, Switch, withRouter, Link, Redirect } from 'react-router-dom'
import SelectPage from './pages/SelectPage'
import EditSelectPage from './pages/EditSelectPage'
import SortAndPreview from './pages/SortAndPreview'
import EditSortAndPreview from './pages/EditSortAndPreview'
import PrivateRoute from './pages/PrivateRoute'
import Preview from './pages/Preview'
import List from './pages/List'
import reducer from './redux/reducers'
import sidebarState from './redux/actions/sidebarState'

import { nextButtonSelector, projectPropertySelector } from './redux/selectors'
// import axios from 'axios';
// import baseUrl from '../../url/index'
import './style.css'
// import { async } from 'q';

const Routes = props => {
  const { match, questionsSelected, redirectPreview } = props
  
  const redirect = !questionsSelected; 

  const dispatch = useDispatch()
  useEffect(() => { 
    dispatch(sidebarState(props.sidebarState)) 
  }) 

  return (
    <BrowserRouter> 
      <Switch> 
        <Route exact path={`${match.path}`} component={List} />

        <Route exact path={`${match.path}/create`} component={SelectPage} />

        <PrivateRoute
          redirect={redirect} 
          // render={() => <SortAndPreview sidebarState={sidebarState} />} 
          component={SortAndPreview} 
          path={`${match.path}/create/sortandpreview`} 
        /> 

        <PrivateRoute redirect={redirect} component={Preview}
          path={`${match.path}/create/preview`}
        />

        <PrivateRoute redirect={redirectPreview} component={Preview}
          path={`${match.path}/oldpreview`}
        />

        <Route
          // redirect={redirect}
          exact
          path={`${match.path}/edit/:disid`}
          component={EditSelectPage}
        />

        <PrivateRoute
          redirect={redirect} exact
          path={`${match.path}/edit/sortandpreview/:disid`}
          component={EditSortAndPreview}
        />

        <PrivateRoute
          redirect={redirect} component={Preview}
          path={`${match.path}/edit/preview/:disid`}
        />

        <Route render={() => <div>Error 404! Page Not Found</div>} /> 
      </Switch> 
    </BrowserRouter> 
  ) 
} 

const mapStateToProps = state => ({ 
  questionsSelected: nextButtonSelector(state),
  redirectPreview: !(projectPropertySelector(state) && !nextButtonSelector(state))
})

const ReduxConnectedRoutes = withRouter(connect(mapStateToProps)(Routes))




const App = (props) => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(reducer, /* preloadedState, */ composeEnhancers(
    applyMiddleware(thunk)
  )) 

  

  return (
    <Provider store={store}>
      <ReduxConnectedRoutes sidebarState={props.sidebarState} />
    </Provider>
  );
} 

export default App;