import React from 'react'
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import MicroObjective from './components/MicroObjective'
import Create from './components/Create'
import Detail from './components/Details'
import { Provider } from 'react-redux';
import store from './store/index';

const Routes = props => {
    let { match } = props

    return (
        <BrowserRouter>
            <Switch>
                <Route exact path={`${match.path}`} component={MicroObjective} />
                <Route path={`${match.path}/create`} component={Create} />
                <Route path={`${match.path}/:id`} component={Detail} />
            </Switch>
        </BrowserRouter>
    )
}

const ReduxConnectedRoutes = withRouter(Routes)

function MicroObjectives() {
    return <Provider store={store}>
        <ReduxConnectedRoutes />
    </Provider>
}

export default MicroObjectives