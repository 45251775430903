import React, { useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import moment from 'moment'
import baseUrl from '../../../../url/index'
import Can from '../../../../components/Can'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import CreateBtn from './createBtn'
import { Button, Card } from 'reactstrap'
import Table from './Table'
import './index.css'

const List = props => {
  const userId = localStorage.getItem('jwtProfileId')
  const [distributions, setDistributions] = useState([])

  useEffect(
    () => {
      axios.get(`${baseUrl}/api/distribution?profileId=${userId}`).then(res => {
        if (res.data) {
          const data = res.data.reverse().map(d => {
            return {
              _id: d._id, name: d.name, active: d.active, status: d.status,
              createdDate: d.createdDate.slice(0, 10)
            }
          })
          setDistributions(data)
        }
      })
    }, []
  )

  const columns = React.useMemo(() => [
    { Header: 'Name', accessor: 'name' },
    { Header: 'Active', accessor: 'active' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Created At', accessor: 'createdDate' }
  ], [])

  return (
    <div className="container">
      <CreateBtn />

      <div className="animated fadeIn">
        {
          distributions.length === 0 ?
            <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
              <Table columns={columns} data={[]} />
            </Card>
            :
            <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
              <Table columns={columns} data={distributions} />
            </Card>
        }
      </div>
    </div>
  )
}

export default withRouter(List); 
