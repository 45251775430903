import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Form from '../../../Form'
// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import axios from 'axios'
import baseUrl from '../../../../url/index'
import {
  fetchChannels,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  distributeConfiguration,
} from '../../redux/actions'
import { channelTypeIdSelector, activeDeviceSelector, apiDataGeneratorSelector } from '../../redux/selectors'
import { CloseButton, Content, DistributeButton } from './style'
import EditStep from '../../components/EditStep'
import { useDispatch } from 'react-redux'
const Preview = props => {

  const {
    channelType,
    subChannelId,
    disId,
    header,
    fetchChannels,
    createProjectForSurveyOnReload,
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
    projectFetch,
    setSurveyProjectStep,
    setSurveyChannelStep,
    setSurveyPreviewStep,
    setSurveyConfigStep,
    distributeConfiguration,
    // disId,
    status,
    dataToPush,
    activeDevice,
    history } = props
  // let dispatch = useDispatch()

  const [profileId, setProfileId] = useState(localStorage.getItem('jwtProfileId'));

  useEffect(() => {

    const fetchDistDataOnReload = async (surveyid) => {
      const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`)
      // dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data }) //
      createProjectForSurveyOnReload(disId.data)
      setSurveyProjectStep()

      const disid = disId.data.distributionId
      if (disid) {
        axios.get(`${baseUrl}/api/distribution/details/${disid}`).then(res => {

          editDistributionQuestion(res.data, disid)
          editDistributionConfig(res.data)
          selectProjectAndFetch(res.data.question.questionBank.projectOrder[0])
          projectFetch(localStorage.getItem('jwtProfileId'))

          if ('activeChannels' in res.data) { setActiveChannel(res.data.activeChannels) }

          if (res.data.status === "DRAFT") {
            setSurveyConfigStep()
            setSurveyPreviewStep()
          }
          if (res.data.status === "SUBMITTED") {
            setSurveyConfigStep()
            setSurveyPreviewStep()
            setSurveyChannelStep()
            fetchChannels(localStorage.getItem("jwtProfileId"))
          }
          if (res.data.status === "DISTRIBUTED") {
            setSurveyConfigStep()
            setSurveyPreviewStep()
            setSurveyChannelStep()
            fetchChannels(localStorage.getItem("jwtProfileId"))
          }
        })
      }
    }

    if (disId === "") {
      fetchDistDataOnReload(props.match.params.surveyid)
    }
  }, [disId])

  const handleDistributeClick = () => {
    const { data } = dataToPush
    distributeConfiguration(data, profileId, disId, history, 'edit')
  }

  return (
    <div className="container">
      <Content>
        <EditStep />
        {status === 'SUBMITTED' && <DistributeButton type='button' onClick={handleDistributeClick}>Distribute</DistributeButton>}
        <Form isPreview={true} userType='admin' disId={disId} header={header} channelType={channelType} subChannelId={subChannelId} />
      </Content>
    </div>
  )
}

const mapStateToProps = state => {
  const { channelType, subChannelId } = channelTypeIdSelector(state)
  return {
    channelType,
    subChannelId,
    disId: state.question.distributionId,
    header: state.question.header.text,
    status: state.question.status,
    dataToPush: apiDataGeneratorSelector(state),
    activeDevice: activeDeviceSelector(state),
  }
}

const mapDispatchToProps = {
  fetchChannels,
  distributeConfiguration,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep
}
export default connect(mapStateToProps, mapDispatchToProps)(Preview)