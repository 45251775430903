import React, { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, nextConditionalQuestion, startDate } from '../../redux/actions'
import './style.css'
import { QuestionCard, Fields, QuestionTitle } from '../styles'
import { Rating, Label, Scale } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSadTear, faSadCry, faFrown, faMeh, faSmile, faLaugh, faGrinHearts } from '@fortawesome/free-solid-svg-icons'
const icons = [faSadTear, faSadCry, faFrown, faMeh, faSmile, faLaugh, faGrinHearts];


const LikertScale = props => {
  const { data, updateData, answer, nextConditionalQuestion, startDate } = props
  const { isConditional, jump, title } = data
  const [likertSelected, setLikertSelected] = useState(null)

  useEffect(() => {
    setLikertSelected(null)
  }, [data.id])

  // console.log(data)
  // console.log(jump)
  useEffect(() => {
    if (answer.index !== null)
      setLikertSelected(answer.index)
  }, [answer])

  useEffect(() => {
    // console.log(answer)
    if (answer.index !== null) {
      if (isConditional) {
        const nextQuestion = jump.find(item => item.jumpOptionIndex === answer.index)
        if (!nextQuestion) {
          nextConditionalQuestion("NEXT")
        } else {
          nextConditionalQuestion(nextQuestion.jumpTo)
        }
      }
    }
  }, [answer])

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  const handleChange = (event) => {
    // console.log(event)
    const { id, value } = event.target
    const index = parseInt(id, 10)
    updateData({
      index,
      value
    })
    setLikertSelected(index)
    if (isConditional) {
      const nextQuestion = jump.find(item => item.jumpOptionIndex === index)
      if (!nextQuestion) {
        nextConditionalQuestion("NEXT")
      } else {
        nextConditionalQuestion(nextQuestion.jumpTo)
      }
    }
  }

  const selectCheck = value => {
    return value === answer.index
  }

  const ratings = data.ratings.map((rating, index) =>
    <Rating key={`rating-${index}`}>
      <input
        id={index}
        name={data.id}
        type="radio"
        value={rating}
        onChange={handleChange}
        checked={selectCheck(index)}
        className="likertscaleres"
      />
      <Label htmlFor={index}>
        <div>
          <FontAwesomeIcon icon={icons[index]} size="3x" style={{ color: index === likertSelected ? "rgb(27, 153, 139)" : "#757575", marginBottom: "5px" }} />
        </div>
        <div>
          {rating}
        </div>
      </Label>
    </Rating>
  )

  // const ratings = data.ratings.map((rating, index) =>
  //   <div key={`rating-${index}`} style={{ maxWidth: '60px', margin: '0 5px' }}>
  //     <input
  //       id={index}
  //       name={data.id}
  //       type="radio"
  //       value={rating}
  //       onChange={handleChange}
  //       checked={selectCheck(index)}
  //       className="likertscaleres"
  //     />
  //     {/* <Gif src={emojis[index]} /> */}
  //     <label htmlFor={index} style={{ textAlign: 'center', cursor: 'pointer', fontSize:'11px' }}>
  //       <FontAwesomeIcon icon={icons[index]} size="2x" style={{ color: index === likertSelected ? "rgb(27, 153, 139)" : "#757575", marginBottom: "5px" }} /><br />
  //       {rating}
  //     </label>
  //   </div>
  // )


  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        <Scale>
          {ratings}
        </Scale>
      </Fields>
    </QuestionCard>)
}

LikertScale.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state)
  }
}

const mapDispatchToProps = {
  updateData,
  nextConditionalQuestion,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(LikertScale))