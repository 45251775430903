import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 10px 10px;
  background-color: white;
  border-radius:4px;
  border:1px solid #EEE;
`;

export const QuestionHeader = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  font-weight:700;
  color:#424242;
  border-bottom: 1px solid #BDBDBD;
`

export const Question = styled.div`
  position: relative;
  line-height: 16px;
  flex-grow: 1;
  padding-right: 10px;
  font-size: 14px;
  text-align: justify;
`

export const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  padding-top: 0px;
`

export const Delete = styled.input`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0;
  font-size: 10px;
  color: #FFF;
  font-weight: 700;
  padding: 2px 10px;
  border: none;
  outline: none;
  background: #F27D7D;
  align-self: center;
`