import React, { useState, useEffect } from 'react'
import { MainBody } from './styles'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList/editIndex'
import EditNextButton from '../../components/EditNextButton'
import { useDispatch, useSelector } from 'react-redux'
import { setSurveyConfigStep, setSurveyPreviewStep } from '../../redux/actions'

import { connect } from 'react-redux'
import {
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
} from '../../redux/actions'

import EditStep from '../../components/EditStep'

const SelectPage = props => {

    let projectId = useSelector(state => state.survey.projectId)
    let dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSurveyConfigStep())
        dispatch(setSurveyPreviewStep())
    }, [])

    useEffect(() => {
        if (projectId) {
            dispatch(selectProjectAndFetch(projectId))
        }
    }, [projectId])


    return (
        <MainBody>
            <div className="container">
                <EditStep />
                <EditNextButton disid={props.disId} />
                <Configuration />
                <QuestionList />
            </div>
        </MainBody>
    )
}

export default connect(null, { editDistributionQuestion, editDistributionConfig, selectProjectAndFetch, setActiveChannel })(SelectPage)