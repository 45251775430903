import React from 'react' 
import { Button } from 'reactstrap' 
import { Link, withRouter } from 'react-router-dom'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb'
import '../buttonCss.css' 

function CreateButton() { 
    return ( 
        <div> 
            <Breadcrumb path="Designation /" /> 
            <Link to={{ pathname: `/app/designation/create` }}> 
                <Button 
                    className="btnHover" 
                    style={{ 
                        background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 0px', 
                        padding:'4px 15px', fontSize:'13px', fontWeight:'500' 
                    }} 
                    size="sm"> 
                    Create New 
                </Button> 
            </Link> 
        </div> 
    ) 
} 

export default withRouter(CreateButton); 
