import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RadioButton } from 'primereact/radiobutton';
import { disabled } from 'glamor';

const Input = styled.textarea`
  box-sizing: border-box;
  border: 1px solid grey;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px 10px 5px 10px;
  overflow: hidden;
  @media screen and (max-width: 425px) {
    font-size: 10px;
    padding: 12.5px;
  }
`;

const Dichotomy = ({ item, hasError, isReadOnly }) => {
  // console.log(item)
  // console.log(editField)

  //Generate the choices
  const options = item.options.map((option, index) => {
    return (
      <div className='p-col-3' key={index}>
        <RadioButton
          inputId={`${item.id}`}
          name={`${item.id}`}
          value={option.value ? option.value : option}
          onChange={(e) => false}
          disabled={isReadOnly}
        />
        <label htmlFor={`${item.id}`} className='p-radiobutton-label'>
          {option.value ? option.value : option}
        </label>
      </div>
    );
  });

  //Return the Dichotomy Component
  return (
    <React.Fragment>
      <h6
        style={{
          marginTop: '20px',
          marginBottom: '0px',
          fontSize: '14px',
          fontWeight: '700',
        }}
      >
        Options
      </h6>
      <div className='p-grid' style={{ width: '100%', marginTop: '10px' }}>
        {options}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    hasError: state.error.options,
    // disStatus: state.disStatus,
    isReadOnly: state.isReadOnly,
  };
};

export default connect(mapStateToProps, null)(Dichotomy);
