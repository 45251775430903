import React from 'react'

import {Option} from './styles'

const Checkbox = props=>{

  const {id, options} = props
  const optionList = options.map((item,index)=>(
    <Option key={`${id}_${index}`}>
      <input type='checkbox' disabled readOnly/>
      <label>{item}</label>
    </Option>
  ))
  return(
    <>{optionList}</>
  )
}

export default Checkbox