import axios from 'axios';
// import { history } from 'react-router-dom'
import * as Actions from './actionTypes';
import baseUrl, {
  fetchQuestionUrl,
  fetchObjective,
  saveQuestionUrl,
} from '../../../../url';
import { stat } from 'fs';
import { toast, Slide } from 'react-toastify';

export const changeName = (value) => {
  return {
    type: Actions.CHANGE_NAME,
    payload: value,
  };
};

export const changeFeature = (value) => {
  return {
    type: Actions.CHANGE_FEATURE,
    payload: value,
  };
};

export const changeActive = (value) => {
  return {
    type: Actions.CHANGE_ACTIVE,
    payload: value,
  };
};

export const addQuestion = (selectType = 'multipleChoice') => {
  return {
    type: Actions.ADD_QUESTION,
    questionType: selectType,
  };
};

export const addQuestionAnyPosition = (
  selectType = 'multipleChoice',
  index
) => {
  return {
    type: Actions.ADD_QUESTION_ANY_POSITION,
    questionType: selectType,
    index: index,
  };
};

export const deleteQuestion = (id) => {
  return {
    type: Actions.DELETE_QUESTION,
    id,
  };
};

export const getQuestionId = (id) => {
  return {
    type: Actions.GET_QUESTION_ID,
    id,
  };
};

export const addField = (id) => {
  return {
    type: Actions.ADD_FIELD,
    id,
  };
};

//qId -> Question ID
//fId -> Field ID
export const deleteField = (qId, fId) => {
  return {
    type: Actions.DELETE_FIELD,
    qId,
    fId,
  };
};

export const editField = (qId, fId, value) => {
  //fId is the index of the choices or options
  return {
    type: Actions.EDIT_FIELD,
    qId,
    fId,
    value,
  };
};

export const editCheckbox = (qId, value) => {
  //fId is the index of the choices or options
  return {
    type: Actions.EDIT_CHECKBOX,
    qId,
    value,
  };
};

export const editTitle = (id, value) => {
  return {
    type: Actions.EDIT_TITLE,
    id,
    value,
  };
};

export const changeType = (id, value) => {
  return {
    type: Actions.CHANGE_TYPE,
    id,
    value,
  };
};

export const saveQuestion = (data) => {
  // console.log(data)
  let err = {
    choices: [],
    options: [],
    ratings: [],
    noData: [],
  };
  data.map((ele) => {
    ele.title = ele.title.trim();
    if (ele.choices) {
      //checks if its a multiple type ques or not
      ele.choices.length < 2
        ? (err.noData = [...err.noData, ele.id])
        : ele.choices.some((choice) => choice === '')
        ? (err.choices = [...err.choices, ele.id])
        : (ele.choices = ele.choices.map((choice) => choice.trim()));
    }
    if (ele.options) {
      ele.options.length < 2
        ? (err.noData = [...err.noData, ele.id])
        : ele.options.some((option) => option === '')
        ? (err.options = [...err.options, ele.id])
        : (ele.options = ele.options.map((option) => option.trim()));
    }
    if (ele.ratings) {
      ele.ratings.some((rating) => rating === '')
        ? (err.ratings = [...err.ratings, ele.id])
        : (ele.ratings = ele.ratings.map((rating) => rating.trim()));
    }
  });

  // const dampdata = {
  //   "user_id": localStorage.getItem('jwtProfileId'),
  //   "project_id": window.location.href.split("/")[5],
  //   "project_name": "abcde",
  //   "question": [...data]
  // }
  return (dispatch, getState) => {
    const dampdata = {
      name: getState().projectName,
      active: getState().active,
      profileId: localStorage.getItem('jwtProfileId'),
      featureinfo: getState().featureId,
      question: [...data],
      createdAt: Date.now(),
      createdBy: {
        id: localStorage.getItem('jwtID'),
        name: localStorage.getItem('userName'),
      },
    };
    // console.log(dampdata)
    if (data.some((element) => element.title === '')) {
      dispatch(showToast('Title Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('title', true));
    } else if (dampdata.name === '') {
      dispatch(showToast('Name Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      // dispatch(error("name", true))
    } else if (!dampdata.featureinfo.id) {
      dispatch(showToast('Feature Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      // dispatch(error("name", true))
    } else if (err.noData.length > 0) {
      dispatch(
        showToast('Add at least Two options for Multiple choise or Checkbox')
      );
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('noData', err.noData));
    } else if (
      err.choices.length > 0 ||
      err.options.length > 0 ||
      err.ratings.length > 0
    ) {
      dispatch(showToast('Input all Required field first'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      err.choices.length > 0
        ? dispatch(error('choices', true))
        : dispatch(error('choices', false));
      err.options.length > 0
        ? dispatch(error('options', true))
        : dispatch(error('options', false));
      err.ratings.length > 0
        ? dispatch(error('ratings', true))
        : dispatch(error('ratings', false));
    } else {
      return axios
        .post(`${baseUrl}/api/project/post`, dampdata, { timeout: 2000 })
        .then((response) => {
          dispatch(saveQuestionSuccess(response.data));
          dispatch(showToast('Save Succesful'));
          setTimeout(() => {
            dispatch(hideToast());
          }, 2000);
        })
        .catch((error) => {
          dispatch(showToast('Oops Something Went Wrong'));
          setTimeout(() => {
            dispatch(hideToast());
          }, 2000);
          throw error;
        });
    }
  };
};

export const updateQuestion = (data, projectId, history, mode) => {
  console.log('update', data);
  let err = {
    choices: [],
    options: [],
    ratings: [],
    noData: [],
  };
  data.map((ele) => {
    ele.title = ele.title.trim();

    if (ele.options && ele.options.length > 1) {
      ele.options = ele.options.map((option) => option.value || option);
    }

    if (ele.choices && ele.choices.length > 1) {
      ele.choices = ele.choices.map((choice) => choice.value || choice);
    }

    if (ele.choices) {
      //checks if its a multiple type ques or not
      ele.choices.length < 2
        ? (err.noData = [...err.noData, ele.id])
        : ele.choices.some((choice) => choice === '')
        ? (err.choices = [...err.choices, ele.id])
        : (ele.choices = ele.choices.map((choice) => choice.trim()));
    }
    if (ele.options) {
      ele.options.length < 2
        ? (err.noData = [...err.noData, ele.id])
        : ele.options.some((option) => option === '')
        ? (err.options = [...err.options, ele.id])
        : (ele.options = ele.options.map((option) => option.trim()));
    }
    if (ele.ratings) {
      ele.ratings.some((rating) => rating === '')
        ? (err.ratings = [...err.ratings, ele.id])
        : (ele.ratings = ele.ratings.map((rating) => rating.trim()));
    }
  });

  // const dampdata = {
  //   "user_id": localStorage.getItem('jwtProfileId'),
  //   "project_id": window.location.href.split("/")[5],
  //   "project_name": "abcde",
  //   "question": [...data]
  // }
  return (dispatch, getState) => {
    const dampdata = {
      name: getState().projectName,
      active: getState().active,
      profileId: localStorage.getItem('jwtProfileId'),
      featureinfo: getState().featureId,
      question: [...data],
      createdAt: Date.now(),
      createdBy: {
        id: localStorage.getItem('jwtID'),
        name: localStorage.getItem('userName'),
      },
      modifiedAt: Date.now(),
      modifiedBy: {
        id: localStorage.getItem('jwtID'),
        name: localStorage.getItem('userName'),
      },
    };
    // console.log(dampdata)
    // if (data.some(element => element.title === "")) {
    //   dispatch(showToast("Title Field is mendatory"))
    //   setTimeout(() => { dispatch(hideToast()) }, 2000)
    //   dispatch(error("title", true))
    // }
    if (dampdata.question.length < 1) {
      dispatch(showToast('Select at least one question.'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      // dispatch(error("label", true))
    } else if (data.some((element) => element.startLabel === '')) {
      dispatch(showToast('Start Label Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('label', true));
    } else if (data.some((element) => element.endLabel === '')) {
      dispatch(showToast('End Label Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('label', true));
    } else if (data.some((element) => element.min === null)) {
      dispatch(showToast('Min Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('range', true));
    } else if (data.some((element) => element.max === null)) {
      dispatch(showToast('Max Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('range', true));
    } else if (dampdata.name === '') {
      dispatch(showToast('Name Field is mendatory'));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      // dispatch(error("name", true))
    }
    // else if (!dampdata.featureinfo.id) {
    //   dispatch(showToast("Feature Field is mendatory"))
    //   setTimeout(() => { dispatch(hideToast()) }, 2000)
    //   // dispatch(error("name", true))
    // }
    else if (err.noData.length > 0) {
      dispatch(
        showToast('Add at least Two options for Multiple choise or Checkbox')
      );
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
      dispatch(error('noData', err.noData));
    }
    // else if (err.choices.length > 0 || err.options.length > 0 || err.ratings.length > 0) {
    //   dispatch(showToast("Input all Required field first"))
    //   setTimeout(() => { dispatch(hideToast()) }, 2000)
    //   err.choices.length > 0 ? dispatch(error("choices", true)) : dispatch(error("choices", false))
    //   err.options.length > 0 ? dispatch(error("options", true)) : dispatch(error("options", false))
    //   err.ratings.length > 0 ? dispatch(error("ratings", true)) : dispatch(error("ratings", false))
    // }
    else {
      return axios
        .patch(`${baseUrl}/api/project/${projectId}`, dampdata, {
          timeout: 2000,
        })
        .then((response) => {
          // console.log(response)
          if (
            response.data.hasOwnProperty('isValid') &&
            response.data.isValid === false
          ) {
            dispatch(showToast(response.data.message));
            setTimeout(() => {
              dispatch(hideToast());
            }, 2000);
          } else {
            dispatch(saveQuestionSuccess(response.data));
            dispatch(showToast('Updated Succesfully'));
            setTimeout(() => {
              dispatch(hideToast());
            }, 2000);
            dispatch({ type: Actions.UPDATE_MESSAGE, payload: true });
            // dispatch(push("/app/survey/create"));
            // handleRedirect()
            // console.log(mode)
            toast('Survey saved successfully', {
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: false,
              transition: Slide,
              className: 'text-center',
            });
            if (mode === 'create') {
              history.push({
                pathname: `/app/survey/create/sortandpreviewwoid/${projectId}`,
                state: { surveyQuestionUpdated: true },
              });
              dispatch(surveyQuestionSaveMessageTrue());
            }
            if (mode === 'edit') {
              history.push({
                pathname: `/app/survey/edit/sortandpreviewwoid/${projectId}`,
                state: { surveyQuestionUpdated: true },
              });
              dispatch(surveyQuestionSaveMessageTrue());
            }
          }
        })
        .catch((error) => {
          dispatch(showToast('Oops Something Went Wrong'));
          dispatch({ type: Actions.UPDATE_MESSAGE, payload: false });
          setTimeout(() => {
            dispatch(hideToast());
          }, 2000);
          throw error;
        });
    }
  };
};

export const surveyQuestionSaveMessageTrue = () => {
  return { type: Actions.SURVEY_QUESTION_SAVE_MESSAGE_TRUE, payload: true };
};

export const surveyQuestionSaveMessageFalse = () => {
  return { type: Actions.SURVEY_QUESTION_SAVE_MESSAGE_FALSE, payload: false };
};

export const deleteQuestionBank = (projectId) => {
  return (dispatch) => {
    axios.delete(`${baseUrl}/api/project/${projectId}`).then((response) => {
      // console.log(response)
      // dispatch({ type: Actions.DELETE_FEATURE })
    });
  };
};

const saveQuestionSuccess = (data) => {
  // console.log(data)
  return {
    type: Actions.SAVE_QUESTION,
    payload: data,
  };
};

export const addQuestionToSurvey = (data) => {
  return (dispatch) => {
    // dispatch(showToast("Questions Added Succesfully"))
    // setTimeout(() => { dispatch(hideToast()) }, 2000)
    dispatch({ type: Actions.ADD_QUESTION_TO_SURVEY, payload: data });
  };
  // return {
  //   type: Actions.ADD_QUESTION_TO_SURVEY,
  //   payload: data
  // }
};

const fetchData = async (projectId) => {
  let questions = [],
    objectives,
    name,
    feature,
    active;
  try {
    const questionsRes = await axios.get(fetchQuestionUrl(projectId));
    questions[0] = questionsRes.data; // questions = questionsRes.data;

    const objectivesRes = await axios.get(
      `${baseUrl}/api/project/objectives/${localStorage.getItem(
        'jwtProfileId'
      )}`
    );
    name = questionsRes.data.name;
    feature = questionsRes.data.featureinfo;
    active = questionsRes.data.active;
    objectives = objectivesRes.data;
  } catch (err) {
    // console.log(err)
    questions = [];
    objectives = [];
  } finally {
    return Promise.all([questions, objectives, name, feature, active]);
  }
};

export const fetchQuestion = (projectId) => {
  return (dispatch) => {
    dispatch(loading(true));
    fetchData(projectId).then(
      ([questions, objectives, name, feature, active]) => {
        dispatch(fetchQuestionSuccess(questions));
        dispatch(fetchObjectiveSuccess(objectives));
        dispatch({ type: Actions.CHANGE_NAME, payload: name });
        dispatch({ type: Actions.CHANGE_FEATURE, payload: feature });
        dispatch({ type: Actions.CHANGE_ACTIVE, payload: active });
        dispatch(loading(false));
      },
      (err) => {
        // console.log(err)
      }
    );
  };
};

export const fetchObjectives = () => {
  return async (dispatch) => {
    const objectives = await axios.get(
      `${baseUrl}/api/project/objectives/${localStorage.getItem(
        'jwtProfileId'
      )}`
    );
    dispatch(fetchObjectiveSuccess(objectives.data));
  };
};

export const fetchObjectiveSuccess = (data) => {
  return {
    type: Actions.FETCH_OBJECTIVE,
    objective: data,
  };
};

// export const initialize = data=>{
//   return{
//     type: Actions.INITIALIZE,
//     payload: data
//   }
// }

export const addObjective = (qId, obj) => {
  return {
    type: Actions.ADD_OBJECTIVE,
    payload: {
      qId,
      obj,
    },
  };
};

const fetchQuestionSuccess = (data) => {
  return {
    type: Actions.FETCH_QUESTION,
    payload: data,
  };
};

export const showToast = (message) => {
  return {
    type: Actions.SHOW_TOAST,
    payload: {
      message,
    },
  };
};

export const hideToast = () => {
  return {
    type: Actions.HIDE_TOAST,
  };
};

export const loading = (status) => {
  return {
    type: Actions.LOADING,
    payload: {
      status,
    },
  };
};
export const error = (name, value) => {
  return {
    type: Actions.ERROR,
    payload: {
      name,
      value,
    },
  };
};

export const setRequired = (id, status) => {
  return {
    type: Actions.SET_REQUIRED,
    payload: {
      id,
      status,
    },
  };
};

export const libraryUpdate = (id_, library) => {
  return {
    type: Actions.LIBRARY_UPDATE,
    payload: {
      id_,
      library,
    },
  };
};

export const backButtonFunction = () => (dispatch) => {
  dispatch({ type: Actions.BACK_BUTTON_IN_DETAIL_PAGE });
};

export const toggleDemographyField = (id_, field_name) => {
  return {
    type: Actions.TOGGLE_DEMOGRAPHY_FIELD,
    payload: {
      id_,
      field_name,
    },
  };
};

export const setDisStatus = (status) => {
  return { type: Actions.DIS_STATUS, status };
};

export const setIsReadOnly = (isReadOnly) => {
  return { type: Actions.SET_IS_READ_ONLY, isReadOnly };
};
