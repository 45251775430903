import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import RequestDemo from './requestDemo'
import { css } from 'glamor'

function ReqDemoModal(props) { 
  const closeCss = css({ 
    cursor: 'pointer', position: 'absolute', color: '#1B998B', fontSize: '20px', fontWeight: '400', 
    lineHeight: '18px', textAlign: 'center', top: '-10px', right: '-10px', width: '20px', height: '20px', 
    backgroundColor: 'white', borderRadius: '50%', boxShadow: '1px 1px 5px -1px #586469', 
    ':hover': { boxShadow: '1px 1px 5px 2px #586469' } 
  }) 

  const modalBodyCss = css({
    top: '60px !important', 
    '@media(max-width: 600px)': { left: '15px !important' }, 
    '@media(max-width: 400px)': { left: '0px !important' } 
  }) 

  return ( 
    <div> 
      <Modal isOpen={props.reqDemo} className={props.className} {...modalBodyCss} id="demo"> 
        <ModalHeader style={{position: 'relative', color: '#1B998B'}}> 
          Request Demo 
          <span {...closeCss} onClick={() => props.cancelModal()}> x </span> 
        </ModalHeader> 
        <ModalBody> 
          <RequestDemo cancelModal={props.cancelModal} /> 
        </ModalBody> 
      </Modal> 
    </div> 
  ) 
} 

export default ReqDemoModal; 
