import styled from 'styled-components'

export const Card = styled.div`
  z-index: ${props => props.selected
    ? '1'
    : '0'};
  width: 50%;
  position: relative;
  margin: 15px auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.1s cubic-bezier(.25,.8,.25, 1);
  border-left: ${props => props.selected
    ? '4px solid #1B998B;'
    : 'none'};
  @media (max-width: 1024px) {
    width: 95%;
  }
  
`

export const QuestionEdit = styled.div`
  height: 30px;
  position: relative;
  width: 90%;
  margin: 25px auto 5px auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
`

export const Config = styled.div`
  position: absolute;
  display: ${props => props.view ? 'flex' : 'none'};
  flex-direction: column;
  width: 40%;
  height: 90%;
  top: 0;
  border-radius:4px;
  left: calc(100% + 5px);
  padding: 10px 5px;
  background-color:#EFF3F4;
`

export const ObjectiveSelect = styled(QuestionEdit)`
  margin: 10px auto;
  height: auto;
`