import React, { useState, useEffect, memo } from 'react';
//import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Moment from 'moment'

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import { FetchAllCampaign } from '../../url/prmUrl';
import { emailScheduler } from '../../url';
import { Button, Input, Label, Body, Error, Aside, Modal } from '../CustomerRelationship/StyledComponents'
import Alert from '../CustomerRelationship/ShowAlert'


let campaignOptions = []

let timeOptions = [
  { label: 'Send Now', value: 'Send Now' },
  { label: 'Send in 1 hour', value: 'Send in 1 hour' },
  { label: 'Send in 6 hours', value: 'Send in 6 hours' },
  { label: 'Send in 1 day', value: 'Send in 1 day' },
  { label: 'Send in 7 days', value: 'Send in 7 days' },
]

let fromOptions = [
  { label: 'Default', value: 'Default' },
  { label: 'Custom', value: 'Custom' },
]

let emailServerOptions = [
  { label: 'SMTP', value: 'SMTP' },
]

const editorConfiguration = {
  plugins: [Essentials, Bold, Italic, Paragraph, Underline, Alignment, Font],
  toolbar: ['bold', 'italic', 'underline', 'alignment:justify', 'alignment:center', 'alignment:left', 'alignment:right', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'undo', 'redo',],
};

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
      <Aside>
        <section>
          <Label>
            <div css={`width: 30%;text-align:left;`}>
              From:     {errors.from && touched.from ? <Error>{errors.from}</Error> : null}
            </div>
            <div css={`width: 60%`}>
              <MySelect
                name="from"
                value={values.from}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.from}
              />
            </div>
          </Label>
        </section>
      </Aside>
      <Aside css={`display: ${values.from ? 'flex' : 'none'}`}>
        <section css={`display: ${values.from ? 'flex' : 'none'}`}>
          <Label css={`display: ${values.from && values.from.value === 'Default' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Name:
                </div>
            <div css={`width: 60%`}>
              <Input
                name="default"
                type="text"
                value={'PEX Deafult System'}
                disabled
              />
            </div>
          </Label>
        </section>

        <section css={`display: ${values.from ? 'flex' : 'none'}`}>
          <Label css={`display: ${values.from && values.from.value === 'Default' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Email:
                </div>
            <div css={`width: 60%`}>
              <Input
                name="default"
                type="text"
                value={'pex.generated@gmail.com'}
                disabled
              // onChange={handleChange}
              // onBlur={handleBlur}
              />
            </div>
          </Label>
        </section>
      </Aside>

      <Aside css={`display: ${values.from ? 'flex' : 'none'}`}>
        <section>
          <Label css={`display: ${values.from && values.from.value === 'Custom' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Server:
                </div>
            <div css={`width: 60%`}>
              <MySelect
                name="fromCustomServer"
                value={values.fromCustomServer}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.fromCustomServer}
              />
            </div>
          </Label>
          <Label css={`display: ${values.from && values.from.value === 'Custom' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Port:     {errors.fromCustomPort && touched.fromCustomPort ? <Error>{errors.fromCustomPort}</Error> : null}
            </div>
            <div css={`width: 60%`}>
              <Input
                name="fromCustomPort"
                type="number"
                min="0"
                max="9999"
                value={values.fromCustomPort}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Label>
        </section>

        <section >
          <Label css={`display: ${values.from && values.from.value === 'Custom' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Name:
                </div>
            <div css={`width: 60%`}>
              <Input
                name="fromName"
                type="text"
                value={values.fromName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Label>
          <Label css={`display: ${values.from && values.from.value === 'Custom' ? 'flex' : 'none'}`}>
            <div css={`width: 30%;text-align:left;`}>
              Email:
                </div>
            <div css={`width: 60%`}>
              <Input
                name="fromCustomEmail"
                type="text"
                value={values.fromCustomEmail}
              // onChange={handleChange}
              // onBlur={handleBlur}
              />
            </div>
          </Label>

        </section>
      </Aside>

      <Aside >
        <section>

          <Label>
            <div css={`width: 30%;text-align:left;`}>
              To:     {errors.toEmailAddress && touched.toEmailAddress ? <Error>{errors.toEmailAddress}</Error> : null}
            </div>
            <div css={`width: 60%`}>
              <Input
                name="toEmailAddress"
                type="text"
                placeholder="Type Emails Separated by Comma"
                value={values.toEmailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Label>

          <Label>
            <div css={`width: 30%;text-align:left;`}>
              Subject:     {errors.subject && touched.subject ? <Error>{errors.subject}</Error> : null}
            </div>
            <div css={`width: 60%`}>
              <Input
                name="subject"
                type="text"
                placeholder="Email Subject"
                value={values.subject}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          </Label>


          <Label>
            <div css={`width: 30%;text-align:left;`}>
              When To Send:     {errors.when && touched.when ? <Error>{errors.when}</Error> : null}
            </div>
            <div css={`width: 60%; z-index:10;`}>
              <MySelect
                name="when"
                value={values.when}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.when}
              />
            </div>
          </Label>

          <Label>
            <div css={`width: 30%;text-align:left;`}>
              Campaign:
                </div>
            <div css={`width: 60%;z-index:5;`}>
              <MySelect
                name="campaignId"
                value={values.campaignId}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.campaignId}
              />
            </div>
          </Label>


          <Label>
            <div css={`width: 30%;text-align:left;`}>
              Body:     {errors.message && touched.message ? <Error>{errors.message}</Error> : null}
            </div>
            <div css={`width: 60%;font-size:10px`}>
              <Editor
                name="message"
                value={values.message}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
                touched={touched.message}

              />
            </div>
          </Label>

        </section>
      </Aside>
      <Button type="submit">
        <FontAwesomeIcon icon={faPaperPlane} css={`margin-right:10px`} />
        Send
      </Button> 
    </form>
  ) 
}


class Editor extends React.Component {
  handleChange = value => {
    this.props.onChange("message", value)
  };

  handleBlur = () => {
    this.props.onBlur("message", true)
  };

  render() {
    return (
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          this.handleChange(data)
        }}
        onBlur={this.handleBlur}
      />

    );
  }
}

class MySelect extends React.Component {
  handleChange = value => {
    this.props.name === 'fromCustomServer' ? this.props.onChange("fromCustomServer", value) :
      this.props.name === 'from' ? this.props.onChange("from", value) :
        this.props.name === 'when' ? this.props.onChange("when", value) :
          this.props.onChange("campaignId", value)
  };

  handleBlur = () => {
    this.props.name === 'fromCustomServer' ? this.props.onBlur("fromCustomServer", true) :
      this.props.name === 'from' ? this.props.onBlur("from", true) :
        this.props.name === 'when' ? this.props.onBlur("when", true) :
          this.props.onBlur("campaignId", true)
  };

  render() {
    return (
      <Select
        id="color"
        placeholder={this.props.name === 'from' ? "Select One..." :
          this.props.name === 'when' ? "Select Time" :
            this.props.name === 'fromCustomServer' ? "Select Server" :
              "Select Campaign"}
        options={this.props.name === 'from' ? fromOptions :
          this.props.name === 'when' ? timeOptions :
            this.props.name === 'fromCustomServer' ? emailServerOptions :
              this.props.name === 'campaignId' ? campaignOptions : ""}
        //isMulti
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.props.value}
      />

    );
  }
}

const Branch = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {
    const fetchData = async () => {

      try {
        const fetchCampaign = await axios(FetchAllCampaign(localStorage.getItem('jwtProfileId')));
        campaignOptions = fetchCampaign.data.map(element => ({ label: element.campaignName, value: element._id }))
      }
      catch (err) {
        console.log("err")
      }

    };

    if (view === false) {
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);


  const schema = Yup.object().shape({
    from: Yup.string().required("*Select One"),
    toEmailAddress: Yup.string().strict(true)
      .matches(/^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/, "Email not valid")
      .lowercase('Email must be lowercase')
      .required('Email is required!'),
    subject: Yup.string().required("*Required"),
    when: Yup.string().required("*Please select..."),
    message: Yup.string().required("*Required"),
    fromCustomPort: Yup.string().matches(/[0-9]|\./, "Not valid")
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () =>
      ({
        from: "",
        fromName: "",
        toEmailAddress: "",
        subject: '',
        when: '',
        campaignId: '',
        message: '',
        profileId: localStorage.getItem("jwtProfileId"),
        sendDateTime: '', //Moment().add(2, 'hours')
        fromCustomPort: ''
      }),

    handleSubmit: (values, { setSubmitting }) => {
      let data = {
        ...values,
        fromName: values.from.value === 'Custom' ? values.fromName : "PEX Deafult System",
        toEmailAddress: values.toEmailAddress.split(','),
        campaignId: values.campaignId.value,
        sendDateTime: values.when.value === "Send Now" ? new Date() :
          values.when.value === "Send in 1 hour" ? Moment().add(1, 'hours') :
            values.when.value === "Send in 6 hour" ? Moment().add(6, 'hours') :
              values.when.value === "Send in 1 day" ? Moment().add(1, 'days') :
                values.when.value === "Send in 7 days" ? Moment().add(7, 'days') : ""
      }
      delete data.from
      delete data.when
      delete data.fromCustomPort
      console.log(data)
      // axios.post(emailScheduler, data)
      // .then((response) => {
      //   if(!response.data.Error){
      //     setView(true)
      //   }
      //   else {
      //     window.alert("Uexpected Error!")
      //   }
      // })
      // .catch((err) => console.log("Error"))
      // setSubmitting(false)

    }
  })(Form)


  return (
    <React.Fragment>
      <Body>
        <Modal
          view={view}
          onClick={event => {
            event.preventDefault()
            if (event.target === event.currentTarget) {
              setView(false)
            }
          }}
        >
          <Alert email={"Email"} close={setView} />
        </Modal>
        <FormikForm />
      </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Branch)