//Dependencies
import React from 'react';
import styled from 'styled-components/macro';
import styledComponents from 'styled-components';
import DeleteButton from '../DeleteButton';
import QuestionCardContent from '../QuestionCardContent';
import QuestionCardObjectives from '../QuestionCardObjectives';
import SelectType from '../SelectType';
import QuestionConfig from '../QuestionConfig';
import { useSelector } from 'react-redux';
import {
  Card,
  Config,
  Container,
  QuestionEdit,
  ObjectiveSelect,
} from './styles';
import AddButtonAnyPosition from '../AddButton/anyPosition';

const types = [
  {
    type: 'Multiple Choice',
    value: 'multipleChoice',
  },
  {
    type: 'Drop Down',
    value: 'dropDown',
  },
  {
    type: 'Dichotomy',
    value: 'dichotomy',
  },
  {
    type: 'ThumbsUp/ThumbsDown',
    value: 'thumbsUpDown',
  },
  {
    type: 'Checkbox',
    value: 'checkbox',
  },
  {
    type: 'Likert Scale',
    value: 'likertScale',
  },
  {
    type: 'rating Scale',
    value: 'ratingScale',
  },
  {
    type: 'Star Rating',
    value: 'starRating',
  },
  {
    type: 'ThumbsUp Rating',
    value: 'thumbsUpRating',
  },
  {
    type: 'Love Rating',
    value: 'loveRating',
  },
  {
    type: 'NPS',
    value: 'nps',
  },
  {
    type: 'Date',
    value: 'date',
  },
  {
    type: 'Date Range',
    value: 'daterange',
  },
  {
    type: 'Text Box',
    value: 'textBox',
  },
  // {
  // 	type: 'Numeric Box',
  // 	value: 'numeric',
  // },
  {
    type: 'Slider',
    value: 'slider',
  },
  {
    type: 'Date',
    value: 'date',
  },
  {
    type: 'Date Range',
    value: 'daterange',
  },
  // {
  //   type: "Demography",
  //   value: "demography"
  // }
];

const AddButtonContainer = styledComponents.div`
  width: 100%;
  padding-bottom: 10px;
`;

//styling components(found in styles.js):
//Card, Config, QuestionEdit

//Functional Components:

const QuestionCard = ({
  id,
  item,
  editTitle,
  deleteQuestion,
  editField,
  editCheckbox,
  changeType,
  getQuestionId,
  selected,
  err,
  index,
}) => {
  //
  //
  // console.log("\n")
  // console.log(err)
  // console.log('item:', item)
  // let disStatus = useSelector(state => state.disStatus)
  let isReadOnly = useSelector((state) => state.isReadOnly);
  return (
    <>
      {!isReadOnly && (
        <AddButtonContainer>
          <AddButtonAnyPosition index={index} />
        </AddButtonContainer>
      )}
      <Card
        selected={item.id === selected} //indicates which card id selected on click;
        name={id}
        className='animated fadeIn delay-0.1s'
        onClick={() => {
          getQuestionId(item.id);
        }} //call back fn dispatching in QuestionCardList
        css={`
          border: ${err.some((check) => check === item.id)
            ? `1px solid red`
            : ''};
          background-color: #eff3f4;
        `}
      >
        {/* {item.id === selected && item.type === "likertScale" ? 
        <Config view={item.id === selected}>               
          <QuestionConfig id_={item.id} type={item.type} />
        </Config>
        :
        null
      } */}
        <QuestionEdit>
          <SelectType
            options={types}
            changeType={changeType}
            selectValue={item.type}
            selectId={item.id}
            isReadOnly={isReadOnly}
          />
          {/* <DeleteButton click={() => deleteQuestion(id)}>Delete</DeleteButton>
        passed click as call-back fn props to DeleteButton and called there */}

          {!isReadOnly && (
            <DeleteButton click={() => deleteQuestion(id)}>Delete</DeleteButton>
          )}
        </QuestionEdit>
        <QuestionCardContent
          item={item}
          editField={editField}
          editCheckbox={editCheckbox}
          editTitle={editTitle}
        />
        {/* <ObjectiveSelect>
      </ObjectiveSelect> */}
        {/* <QuestionCardObjectives id={id} selected={item.objectives} isReadOnly={isReadOnly} /> */}
      </Card>
    </>
  );
};

export default QuestionCard;
