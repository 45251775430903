import styled from 'styled-components'

export const Body = styled.div`
  box-sizing: border-box;
  display: flex;
  align-self: center;
  position:relative;
  margin: 0;
  width: 90%;
  flex-wrap: wrap;
  padding: 0 0 20px 0;
`

export const Label = styled.label`
  box-sizing: border-box;
  padding: 2px 10px;
  position:relative;
  flex-basis: 100%;
  justify-content: flex-start;
  align-items: center;
 ;
`