import React, { Component } from 'react';
import {Provider, connect} from 'react-redux'
import {Route,withRouter} from 'react-router-dom';

import {initialize,fetchQuestion} from './redux/actions/modifyQuestion'
import FormApp from './components/FormApp';
import generateLink from './components/GenerateLink';
import store from './redux/store'
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

class App extends Component {

  render() {
    const {objective, projectId} = this.props
    return(
      <Provider store={store}>
        <FormApp projectId={this.props.projectId}/>
        {/* <Route exact path="/" component={FormApp} />
        <Route exact path='/distribution' component={generateLink} />
        <Route exact path='/analysis' component={Preview} /> */}    
      </Provider>
    )
  }
}

export default App;
