import React from 'react';
import baseUrl from '../../url';
import axios from 'axios';
import showToast from '../../components/Toast/toast';
import { toast } from 'react-toastify';
import Loader from '../Loader';

import mailSuccess from './asset/mail.png';
import mailFail from './asset/mail-fail.png';

import './style.css';

const EmailVerification = () => {
	const verificationToken = window.location.search;
	const urlParams = new URLSearchParams(verificationToken);
	const id = urlParams.get('id');
	const token = urlParams.get('token');

	const [counter, setCounter] = React.useState(3);
	const [success, setSuccess] = React.useState(false);
	const [loading, setLoading] = React.useState(true);

	React.useEffect(() => {
		const verifyEmail = async () => {
			try {
				const res = await axios.get(
					`${baseUrl}/verification${verificationToken}`
				);
				const data = res.data;

				if (res.status === 200) {
					setSuccess(true);
					toast.success(data.Status);
				} else {
					setSuccess(false);
					toast.error('Email verification failed');
				}
				setTimeout(() => {
					window.location.href = '/signin';
				}, 5000);
			} catch (error) {
				toast.error('Email verification failed');
				setSuccess(false);
			} finally {
				setLoading(false);
			}
		};

		if (verificationToken && id && token) {
			verifyEmail();
		}
	}, [verificationToken, id, token]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setCounter((prev) => (prev === 0 ? 0 : prev - 1));
		}, 1000);

		return () => clearInterval(interval);
	}, []);

	return (
		<div className='wrapper'>
			{loading ? (
				<Loader />
			) : (
				<div>
					{success ? (
						<div className='success'>
							<img
								src={mailSuccess}
								alt='mail-success'
								className='mail-success'
							/>

							<h3>Email Verified Successfully</h3>
							<p>
								Redirecting to login page in {counter} seconds...If not
								redirected click <a href='/signin'>here</a>
							</p>
						</div>
					) : (
						<div className='fail'>
							<img src={mailFail} alt='mail-fail' className='mail-fail' />

							<h3>Email Verification Failed</h3>
							<p>
								Redirecting to login page in {counter} seconds...If not
								redirected click <a href='/signin'>here</a>
							</p>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default EmailVerification;
