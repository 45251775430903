import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative; 
  align-items: center;
  justify-content: center;
  height:50px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 1 3px grey;
  flex-direction: row; 
  @media (max-width: 767.92px) {
    width: 100%;
    margin: 0 auto!important;
  }
`

