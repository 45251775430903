import styled from 'styled-components'

export const Header = styled.div`
display: flex;
max-width:800px;
// box-sizing: border-box;
// position: relative;
// border-radius:5px
margin: 0 auto;
// @media (max-width: 767.92px) { 
//   width: 100%;
// }  
`

export const ImageBody = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  height:auto;
  align-items: start;
  justify-content: start;
  padding: 0 10px 0 0
`

export const LogoImage = styled.img`
  width: 120px; 
  height: auto; 
`;

export const HeadlineBody = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  height:100%;
  align-items: start;
  justify-content: start;
  width: 80%;
  padding:0 4px; 
  word-break:break-word;
`