import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top:15px;
  border-radius:4px!important;
  transition: background-color 0.5s ease;
  background-color: ${props => props.isDraggingOver ? 'lightblue' : '#EFF3F4'};
`

export const ProjectContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
  background-color:#EFF3F4;
  // box-shadow: 1px 1px 5px 1px grey;
`

export const ProjectTitle = styled.h6`
  box-sizing: border-box;
  position: relative;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  font-weight: 700;
  background-color: transparent;
  color: #424242;
  margin: 0;
`

export const QuestionList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  transition: background-color 0.5s ease;
  background-color: ${props => props.isDraggingOver ? 'lightblue' : 'transparent'};
`