import React, {useState} from 'react' 
import { withRouter } from 'react-router-dom'
import image from './assets/xm.png'
import {css} from 'glamor'
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap'
import NavBar from '../LandingPage/components/nav/nav'
import Footer from '../LandingPage/components/footer/Footer'
import ReqDemoModal from '../LandingPage/components/ReqDemo/ReqDemoModal'

function XM(props) { 
  const contentTitle = css({textAlign: 'center', color: '#1B998B', fontSize: '22px', marginBottom: '20px'})
  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 

  const [successMsgModal, setSuccessMsgModal] = useState(false) 
  const [reqDemo, setReqDemo] = useState(false); 

  const cancelModal = () => setReqDemo(false); 

  return ( 
    <div style={{overflowY: 'scroll'}}> 
      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 


      <NavBar /> 
      <div className="container mt-4"> 
        {/* <h1 {...contentTitle}>Brand Experience (BX)</h1> */}
        <h1 {...header}>What is XM?</h1> 
        <p {...text}> 
        Experience management is the process of monitoring every interaction people experience with a company in order to discover opportunities for improvement. Experience management systems are based on customer’s such type of feedbacks whatever they are bad or good. Experience management gathers customer insights so that they can be used for business purposes. It relates customers impressions with business metrics to analyze the implicit meanings of the business attributes. The analysis driven by the customer insights leads to beneficial business strategic implementation. 
        </p> 

        {/* Image */} 
        <div className="text-center"> 
          <img src={image} alt="Experience Management" height="300px" /> 
        </div> 
        <br /> 

        <center> 
          <Button 
            style={{ background: '#1B998B', borderColor: '#1B998B', margin: 'auto', padding:'4px 15px', fontSize:'13px', fontWeight:'500' }}
            className="btnHover" size="sm" onClick={() => setReqDemo(true)}> 
            Request Demo
          </Button> 
        </center> 

        <> 
          <h1 {...header}>WHY it is important?</h1> 
          <p {...text}> 
            Experience management (XM) system can measure the customer satisfaction which allows a company to know where it is lacking off to satisfy its customers. By knowing the fact, companies can take steps to redecorate their customer service for attaining more customer satisfaction which will eventually lead to business success. Experience management is all about collection, relation and analysis of data which is used to draw progressive business results.  
          </p> 


          <h1 {...header}>How it works?</h1> 
          <p {...text}> 
            Here are the steps to successful experience management: 
          </p> 
          <ul style={{marginLeft: '40px'}} {...text}>
            <li>Create and design our desired survey</li>
            <li>Distribute is through Omni Channels</li>
            <li>Get the right response to the right place at the right time – every time.</li>
            <li>Finally get real time analysis and take perfect decision.</li>
          </ul> 


          <h1 {...header}>ALL in a single platform</h1>
          <p {...text}>
          With ProlificXM you can create, monitor and manage every experience on a single platform. Our advanced analytical tools help you to uncover deep insights and makes connections between your customer, employee, product and brand experiences to help close experience gaps.
        </p> 
        </>
      </div> 
      <Footer /> 
    </div>
  ) 
} 

export default withRouter(XM); 
