import React from 'react';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import showToast from '../../../../../components/Toast/toast';
import { read as xlsxRead, utils as xlsxUtils } from 'xlsx';
import { Button, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import baseUrl from '../../../../../url';

const EmailModal = ({ modal, setModal, list, setList }) => {
  const [file, setFile] = React.useState(null); // file[0]
  const [entryType, setEntryType] = React.useState('manual'); // manual or file or list
  const [searchQuery, setSearchQuery] = React.useState(''); // searchQuery for bulk email list
  const [emailList, setEmailList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchBulkEmails = async () => {
      setLoading(true);
      const profileId = localStorage.getItem('jwtProfileId');

      try {
        const res = await axios.get(
          `${baseUrl}/api/bulk-email/list/${profileId}?fields=name,email&search=${searchQuery}`
        );
        const { data } = res;

        if (data && data.status === 'success') {
          setEmailList(data.data);
        } else {
          setEmailList([]);
        }
      } catch (error) {
        showToast('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchBulkEmails();
  }, [searchQuery, entryType]);

  const toggleModal = () => setModal(!modal);

  const handleFileChange = (e) => {
    // only one file can be uploaded & accepted file types are .csv, .xlsx, .xls
    const files = e.target.files;
    const file = files ? files[0] : null;

    if (file) {
      if (
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      ) {
        setFile(file);
      } else {
        alert('Invalid file type. Please upload .csv, .xlsx, or .xls file.');
      }
    } else {
      setFile(null);
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    // upload file
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = xlsxRead(bstr, { type: rABS ? 'binary' : 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsxUtils.sheet_to_json(ws, { header: 1 }).slice(1);

      const transformedData = [];
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (!emailRegex.test(element[1])) {
          continue;
        }

        transformedData.push({
          name: element[0],
          email: element[1],
        });
      }

      handleSubmit(transformedData);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async (values = null) => {
    let bodyData = null;

    if (entryType === 'manual') {
      bodyData = {
        createdByProfileId: localStorage.getItem('jwtProfileId'),
        email_list: [
          {
            name: values.name,
            email: values.email,
          },
        ],
      };
    } else {
      bodyData = {
        createdByProfileId: localStorage.getItem('jwtProfileId'),
        email_list: [...values],
      };
    }

    try {
      const res = await axios.post(`${baseUrl}/api/bulk-email`, bodyData);

      const { data } = res;

      if (data && data.success) {
        showToast('Bulk Emails Added Successfully');
        setList(
          [...list, ...bodyData.email_list].filter(
            (v, i, a) => a.findIndex((t) => t.email === v.email) === i
          )
        );
        toggleModal();
      } else {
        showToast('Failed to add email');
      }
    } catch (error) {
      showToast('Something went wrong');
    }
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>Email Selection</ModalHeader>

      <ModalBody
        style={{
          padding: 0,
        }}
      >
        <Formik
          enableReinitialize
          initialValues={{
            name: '',
            email: '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Name is required'),
            email: Yup.string().required('Email is required'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            // ;

            handleSubmit(values);
          }}
        >
          {(props) => (
            <>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr 1fr',
                  textAlign: 'center',
                  borderBottom: '1px solid #E0E0E0',
                  cursor: 'pointer',
                }}
              >
                <div
                  style={{
                    padding: '1rem',
                    fontSize: '16px',
                    fontWeight: '500',
                    borderBottom:
                      entryType === 'manual' ? '4px solid #1B998B' : null,
                    background: entryType === 'manual' ? '#E0E0E0' : null,
                  }}
                  onClick={() => setEntryType('manual')}
                >
                  Manual
                </div>

                <div
                  style={{
                    padding: '1rem',
                    fontSize: '16px',
                    fontWeight: '500',
                    borderBottom:
                      entryType === 'file' ? '4px solid #1B998B' : null,
                    background: entryType === 'file' ? '#E0E0E0' : null,
                  }}
                  onClick={() => setEntryType('file')}
                >
                  Upload
                </div>

                <div
                  style={{
                    padding: '1rem',
                    fontSize: '16px',
                    fontWeight: '500',
                    borderBottom:
                      entryType === 'list' ? '4px solid #1B998B' : null,
                    background: entryType === 'list' ? '#E0E0E0' : null,
                  }}
                  onClick={() => setEntryType('list')}
                >
                  List
                </div>
              </div>

              <form
                onSubmit={
                  entryType === 'manual'
                    ? props.handleSubmit
                    : entryType === 'file'
                    ? handleFileUpload
                    : (e) => {
                        e.preventDefault();
                        toggleModal();
                      }
                }
                style={{
                  padding: '1rem',
                }}
              >
                {entryType === 'manual' ? (
                  <>
                    <div className='form-group'>
                      <label>
                        Name<span style={{ color: 'red' }}>{` *`}</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='name'
                        value={props.values.name}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        style={{
                          border:
                            props.errors.name && props.touched.name
                              ? '1px solid red'
                              : null,
                        }}
                      />
                      {props.errors.name && props.touched.name && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            margin: '0px',
                            textAlign: 'left',
                          }}
                        >
                          {' '}
                          {props.errors.name}{' '}
                        </p>
                      )}
                    </div>

                    <div className='form-group'>
                      <label>
                        Email<span style={{ color: 'red' }}>{` *`}</span>
                      </label>
                      <input
                        type='text'
                        className='form-control'
                        id='email'
                        value={props.values.email}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        style={{
                          border:
                            props.errors.email && props.touched.email
                              ? '1px solid red'
                              : null,
                        }}
                      />
                      {props.errors.email && props.touched.email && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            margin: '0px',
                            textAlign: 'left',
                          }}
                        >
                          {' '}
                          {props.errors.email}{' '}
                        </p>
                      )}
                    </div>
                  </>
                ) : entryType === 'file' ? (
                  <div className='form-group'>
                    <label style={{ marginBottom: '20px' }}>
                      Upload File
                      <span style={{ color: 'red' }}>{` *`}</span> (.csv, .xlsx,
                      .xls)
                    </label>
                    <br />
                    <Input
                      type='file'
                      name='file'
                      id='file'
                      multiple={false}
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className='form-group'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search by Name or Email'
                      style={{ marginBottom: '20px' }}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    {list.length > 0 && (
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        Selected {list.length} emails
                      </p>
                    )}

                    {loading && (
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        Loading...
                      </p>
                    )}

                    {!loading && emailList.length === 0 && (
                      <p
                        style={{
                          textAlign: 'center',
                        }}
                      >
                        No data found
                      </p>
                    )}

                    {!loading && emailList.length > 0 && (
                      <>
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                            padding: '10px',
                            borderBottom: '1px solid #E0E0E0',
                          }}
                        >
                          <input
                            type='checkbox'
                            name='email'
                            value='all'
                            style={{
                              marginRight: '10px',
                              flexShrink: 0,
                              width: '16px',
                              height: '16px',
                            }}
                            onChange={(e) => {
                              const checked = e.target.checked;

                              if (checked) {
                                setList([...emailList]);
                              } else {
                                setList([]);
                              }
                            }}
                            checked={list.length === emailList.length}
                          />

                          <span style={{ fontSize: '14px' }}>
                            Select All {emailList.length}
                          </span>
                        </label>

                        <div
                          style={{
                            maxHeight: '400px',
                            overflowY: 'auto',
                          }}
                        >
                          {emailList.map((item) => (
                            <label
                              key={item._id}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '10px',
                                padding: '10px',
                                borderBottom: '1px solid #E0E0E0',
                                cursor: 'pointer',
                              }}
                            >
                              <input
                                type='checkbox'
                                name='email'
                                value={item.email}
                                style={{
                                  marginRight: '10px',
                                  flexShrink: 0,
                                  width: '16px',
                                  height: '16px',
                                }}
                                onChange={(e) => {
                                  const checked = e.target.checked;

                                  if (checked) {
                                    setList([
                                      ...list,
                                      {
                                        name: item.name,
                                        email: item.email,
                                      },
                                    ]);
                                  } else {
                                    setList(
                                      list.filter((x) => x.email !== item.email)
                                    );
                                  }
                                }}
                                checked={list.find(
                                  (x) => x.email === item.email
                                )}
                              />

                              <div style={{ flexGrow: 1, fontSize: '14px' }}>
                                <p style={{ margin: 0, marginBottom: '5px' }}>
                                  Name: {item.name}
                                </p>
                                <p style={{ margin: 0 }}>Email: {item.email}</p>
                              </div>
                            </label>
                          ))}
                        </div>
                      </>
                    )}
                  </div>
                )}

                <Button
                  className='btnHover mr-2'
                  style={{ background: '#1B998B', borderColor: '#1B998B' }}
                  type='submit'
                >
                  Add
                </Button>

                <Button color='secondary' onClick={toggleModal}>
                  Cancel
                </Button>
              </form>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EmailModal;
