import React, { useState, useEffect } from 'react'
import { Card } from 'reactstrap'
import Table from './Table'
// import showToast from '../../../../components/Toast/toast'

import { useSelector, useDispatch } from 'react-redux'
// import { getAllObjectives } from '../../store/actions/actionCreator'
import axios from 'axios'
import baseUrl from '../../../url'
import { fetchProject } from '../../../url'
function Index() {

    // let dispatch = useDispatch()
    const [project, setProject] = useState([]);
    // let { allData } = useSelector(state => state.objective)
    // const ErrorToast = () => showToast('Server Error')

    // useEffect(() => {
    //     dispatch(getAllObjectives(ErrorToast))
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            // console.log(fetchProject(localStorage.getItem('jwtID')));
            // console.log(fetchProject(localStorage.getItem('jwtID')))
            try {
                // console.log(fetchProject(localStorage.getItem('jwtID')))
                let res = await axios.get(`${baseUrl}/api/project/profile/${localStorage.getItem('jwtProfileId')}`)
                // console.log(res)
                let data = res.data.projectList.map((project) => {
                    const filteredData = {
                        id: project._id,
                        projectName: project.name,
                        projectType: project.featureinfo ? project.featureinfo.name : null,
                        active: project.active,
                        createdBy: project.createdBy.name,
                        createdAt: project.createdAt.slice(0, 10),
                        modifiedBy: project.modifiedBy ? project.modifiedBy.name : null,
                        modifiedAt: project.modifiedAt ? project.modifiedAt.slice(0, 10) : null
                    }
                    return filteredData;
                })

                setProject([
                    ...project,
                    ...data
                ])
            }
            catch (err) {
                // console.log(err)
            }
            finally {
                // setLoaded(true)
            }
        };

        fetchData();
    }, []);

    const columns = React.useMemo(() => [
        { Header: 'Name', accessor: 'projectName' },
        { Header: 'Feature', accessor: 'projectType' },
        { Header: 'Active', accessor: 'active' },
        { Header: 'Created By', accessor: 'createdBy' },
        { Header: 'Created At', accessor: 'createdAt' },
        { Header: 'Modified By', accessor: 'modifiedBy' },
        { Header: 'Modified At', accessor: 'modifiedAt' },
    ], [] 
    ) 

    return <div className="animated fadeIn" style={{ width: '100%' }}>
    {
        project.length === 0 ?
            <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                <Table columns={columns} data={[]} />
            </Card>
            :
            <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                <Table columns={columns} data={project.reverse()} />
            </Card>
    } 
    </div> 
} 

export default Index