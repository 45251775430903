import React from 'react'; 
import { withRouter, Link } from 'react-router-dom'
import { css } from 'glamor'
import './style.css' 

function Blogs({posts, history}) { 
  let readMoreCss = css({ 
    color: '#1B998B', fontWeight: '500', fontSize: '10px', 
    marginLeft: '10px', cursor: 'pointer', ':hover': { color: '#0B6157' } 
  }); 

  let postHoverCss = css({':hover': { boxShadow: '0px 0px 3px 2px rgba(173, 173, 173,0.4)' }}) 

  return ( 
    <div className="container"> 
    { 
      posts.length 
      ? 
        posts.map((obj, i) => { 
          if(i % 2 === 0) { 
            return ( 
              <div key={`id: ${i}`} {...postHoverCss} style={{background: '#eeeeee', cursor: 'pointer', borderRadius: '3px'}}> 
                <Link to={{
                  pathname:`/blog/${obj.title.replace(/\s+/g, '-').toLowerCase()}`, 
                  state: { detail: obj } 
                }} className="row align-items-center mt-3 p-3"> 
                  <div className={`${obj.featureImageUrl ? 'col-sm-12 col-md-6 col-lg-8' : 'col-sm-12'} orderText d-flex`}> 
                    <div style={{width: '100%'}}> 
                      <h5 style={{color: '#1B998B', fontSize: '16px'}}> { obj.title } </h5> 
                      <p className="text-justify" style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}> 
                        { obj.excerpt } 
                        <span {...readMoreCss}>Read more...</span> 
                      </p> 
                    </div> 
                  </div> 
                  { 
                    obj.featureImageUrl && 
                    <div className="col-sm-12 col-md-6 col-lg-4 blogImg"> 
                      <div className="" style={{width: '100%'}}> 
                        <img className="img-fluid text-center" src={obj.featureImageUrl} alt={obj.title} /> 
                      </div> 
                    </div> 
                  } 
                </Link> 
              </div> 
            ) 
          } 
          else { 
            return ( 
              <div key={`id: ${i}`} {...postHoverCss} style={{background: '#eeeeee', cursor: 'pointer', borderRadius: '3px'}}> 
                <Link to={{
                  pathname:`/blog/${obj.title.replace(/\s+/g, '-').toLowerCase()}`, 
                  state: { detail: obj } 
                }} className="row align-items-center mt-3 p-3"> 
                  { 
                    obj.featureImageUrl &&
                    <div className="col-sm-12 col-md-6 col-lg-4 blogImg"> 
                      <div className="" style={{width: '100%'}}> 
                        <img className="img-fluid text-center" height="120px" src={obj.featureImageUrl} alt={obj.title} /> 
                      </div> 
                    </div> 
                  } 
                  <div className={`${obj.featureImageUrl ? 'col-sm-12 col-md-6 col-lg-8' : 'col-sm-12'} orderText d-flex`}> 
                    <div style={{width: '100%'}}> 
                      <h5 style={{color: '#1B998B', fontSize: '16px'}}> { obj.title } </h5> 
                      <p className="text-justify" style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}> 
                        { obj.excerpt } 
                        <span {...readMoreCss}>Read more...</span> 
                      </p> 
                    </div> 
                  </div> 
                </Link> 
              </div> 
            ) 
          }
        }) 
      : 
      <h4 className="mt-5">No post available</h4> 
    } 
    </div> 
  ) 
} 

export default withRouter(Blogs); 
