import styled from 'styled-components'

export const Options = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px dotted grey;
`

export const OptionItem = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  padding: 5px 5px 5px 0;
`

export const SelectOptions = styled(OptionItem)`
  flex-grow: initial;
  flex-basis: 50%;
  justify-content: flex-start;
`