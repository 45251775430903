import React, { Component } from 'react';
import { Provider, connect } from 'react-redux'
import FormApp from './components/FormApp';
import store from './redux/store'
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css"

class App extends Component {

  render() {
    const { objective, projectId } = this.props
    // console.log('Questions project id:', projectId);
    return (
      <Provider store={store}>
        <FormApp projectId={this.props.projectId} />
      </Provider>
    )
  }
}

export default App;
