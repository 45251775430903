import React from 'react';
import './HeaderInfoCard.css';

const HeaderInfoCard = (props) => {

    const { respondants, answered, skipped, averageResponseTime } = props.summary;
    
    return (
        <div className="HeaderInfoCard d-flex flex-row flex-wrap mt-1">
            <div className="header-info mr-2">Respondants : <span>{respondants}</span></div>
            <div className="header-info mr-2">Answered : <span>{answered}</span></div>
            <div className="header-info mr-2">Skipped : <span>{skipped}</span></div>
            <div className="header-info mr-2">Average response time : <span>{averageResponseTime}s</span></div>
        </div>
    );
}

export default HeaderInfoCard;