import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'reactstrap'
import Table from './Table'
import { getALLBrandsProfileWise, categoryFieldData, companyFieldData } from '../../store/actions/actionCreator'


function Index() {
    const companyProfileId = localStorage.getItem("jwtProfileId")

    let dispatch = useDispatch()
    let { allData, category, companys } = useSelector(state => state.brand)
    // let [haveCategoryLabel, setHaveCategoryLabel] = useState(false)
    // let [haveCompanyName, setHaveCompanyName] = useState(false)


    try {
        if (!Object.keys(allData).length) { allData.data = [] }
        if (Object.keys(allData).length) {
            allData.data.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });
        }
        if (!Object.keys(category).length) { category.data = [] }
        if (!Object.keys(companys).length) { companys = [] }
    } catch (error) { }



    let i, j, haveCategoryLabel = false, haveCompanyName = false;
    for (i = 0; i < allData.data.length; i++) {
        allData.data[i].companyName = companys.name || companys.user_Name
        if (i === allData.data.length - 1) { haveCompanyName = true; }
    }

    for (i = 0; i < allData.data.length; i++) {
        for (j = 0; j < category.data.length; j++) {
            if (allData.data[i].categoryId === category.data[j]._id) {
                allData.data[i].category = category.data[j].name
                if (i === allData.data.length - 1) { haveCategoryLabel = true; }
            }
        }
    }



    useEffect(() => {
        dispatch(getALLBrandsProfileWise(companyProfileId))
        dispatch(categoryFieldData())
        dispatch(companyFieldData(companyProfileId))
    }, [])

    const columns = React.useMemo(() => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Active', accessor: 'active' },
        { Header: 'Category', accessor: 'category' },
        { Header: 'Company', accessor: 'companyName' }
    ], []
    )

    return (
        <div className="animated fadeIn">
            {
                allData.data.length === 0 ?
                    <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                        <Table columns={columns} data={[]} />
                    </Card>
                    :
                    haveCategoryLabel && haveCompanyName === true &&
                    <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                        <Table columns={columns} data={allData.data[0].companyName ? allData.data : []} />
                    </Card>
            }
        </div>
    )
}

export default Index
