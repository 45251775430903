export const deleteField = (qId, fId, questionList) => {
  return (questionList.map(item => {
    if (qId !== item.id) {
      return { ...item }
    } else {
      switch (item.type) {
        case "multipleChoice":
          return deleteMultipleChoice(fId, item)
        case "dropDown":
          return deleteCheckboxOrDropDown(fId, item)
        case "checkbox":
          return deleteCheckboxOrDropDown(fId, item)
        default:
          return { ...item }
      }
    }
  }))
}

const deleteMultipleChoice = (fId, item) => {
  let newData = [...item.choices]
  newData.splice(fId, 1)
  return {
    ...item,
    choices: [
      ...newData
    ]
  }
}

const deleteCheckboxOrDropDown = (fId, item) => {
  let newData = [...item.options]
  newData.splice(fId, 1)
  return {
    ...item,
    options: [
      ...newData
    ]
  }
}