import React, { useState, useEffect } from 'react'; 
import { Link, Switch, Route } from 'react-router-dom'; 
import { Body } from './StyledComponents'; 
import IndividualUser from './IndividualUser'; 
import AddNewUser from './AddNewUser'; 
import Index from './Table/index'; 

const Users = props => { 
  const styles = { 
    button: { 
      color: '#ffffff',
      background: 'rgb(27, 153, 139)',
      borderColor: 'rgb(27, 153, 139)',
      // margin: '20px 0px',
      position: 'relative', 
      top: '-6px',
      padding: '.5rem .55rem',
      // fontSize: '.875rem',
      // lineHeight: '1.5',
      // borderRadius: '.2rem',
      // textDecoration: 'none'
    } 
  } 

  
  //initial view of the Users tab showing all the created Users
  const UserView = props => 
    ( 
      <div className="container">
        <div className="row">
          <Body> 
            <h6 style={{color: '#1B998B'}}> Users / </h6> 
            <br /> 
            {/* <Link className="btnHover" to={`${props.match.path}addUser`} style={styles.button}>
              Create 
            </Link>  */}
            <Link 
              to={`${props.match.path}addUser`} 
              style={{ 
                lineHeight: '1.5', borderRadius: '.2rem', textDecoration: 'none',
                background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 0px', 
                padding:'7px 16px', fontSize:'13px', fontWeight:'500', color: '#ffffff', 
                position: 'relative', top: '-8px' 
              }} 
              className="btnHover"> 
              Create New 
            </Link> 
            <Index /> 
          </Body> 
        </div>
      </div>
    ) 



  //The routes are for main user tab and individual Users
  return (
    <>
      <Switch>
        <Route path={`${props.match.path}/`} exact component={UserView} />
        <Route path={`${props.match.path}/addUser`} render={() => (<AddNewUser item={`${props.match.path}`} />)} />
        <Route path={`${props.match.path}/:userId`} exact component={IndividualUser} /> 
        {/* <Route path={`${props.match.path}/:userId`} exact user={user} component={IndividualUser} />  */}
      </Switch> 
    </> 
  ) 
} 

export default Users; 













// const items = user.map((item, index) => ( 
  //   <div className="animated fadeIn w-100"> 
  //     { 
  //       // <Card style={{border: '0px', marginTop: '18px'}}> 
  //         // <Table columns={columns} data={user} /> 
  //       // </Card> 
  //     } 
  //   </div> 
  // ))





{/* <UserItem> 
  <Link css={`
      box-sizing: border-box; margin: 0; padding: 0; display: block; position: absolute;
      top: 0; left: 0; padding: 10px; width: 100%; height: 100%; z-index: 5; 
    `} to={`${props.match.url}/addUser`}> </Link> 
  <User> 
    <div css={` text-align: center; padding-top:10px; height: 60%; `}> 
      <FontAwesomeIcon icon={faUserPlus} 
      css={` 
        font-size: 50px; color: #1B998B; padding-top:10px; :hover { color: #1B998B; }
        @media only screen and (max-width: 425px) { padding-top:15px; } 
      `}/> 
    </div> 

    <div css={`
        display: flex; position:relative; background-color: #1B998B;
        align-items:center; justify-content:center; height: 40%; 
      `}> 
      Add User 
    </div> 
  </User> 
</UserItem>  */} 








{/* // <UserItem key={index}> 
//   <Table /> 
//   {<Link css={`
//       box-sizing: border-box; margin: 0; padding: 0;
//       display: block; position: absolute; top: 0; left: 0;
//       padding: 10px; width: 100%; height: 100%; z-index: 5;
//     `}  */}
    {/* to={`${props.match.url}/${item._id}`}>
  </Link> 
  <User> 
    <div css={` text-align: center; padding-top:10px; height: 60%; `} >
      <FontAwesomeIcon
        icon={faUserCircle}
        css={`
          font-size: 50px; color: #1B998B; padding-top:10px; :hover { color: #1B998B; }
          @media only screen and (max-width: 425px) { padding-top:15px; }
        `}/> 
    </div>

    <div 
      css={` 
        display: flex; position:relative; background-color:#1B998B; 
        align-items:center; justify-content:center; height: 40%; 
      `}> 
      <span>{item.firstName} {item.lastName}</span> 
      <span>{item.roleInfo.roleName}</span> 
    </div>  
  </User> 
</UserItem>  */}







