import React from 'react';
import './Nps.css';

const Nps = (props) => {
    const nps = props.nps;

    return (
        <div className="NPS">
            <div className="progress mt-1 mb-1" style={{ height: '36px' }}>
                <div className="progress-bar bgDanger" role="progressbar"
                    style={{ width: `${nps.detractor}%`, fontSize: "12px", color: "#011627", fontWeight: "700" }} aria-valuenow={`${nps.detractor}`}
                    aria-valuemin="0" aria-valuemax="100">
                    {props.label ? `${nps.detractor}%` : ""}
                </div>

                <div className="progress-bar bgWarning" role="progressbar"
                    style={{ width: `${nps.passive}%`, fontSize: "12px", color: "#011627", fontWeight: "700" }} aria-valuenow={`${nps.passive}`}
                    aria-valuemin="0" aria-valuemax="100">
                    {props.label ? `${nps.passive}%` : ""}
                </div>

                <div className="progress-bar bgSuccess" role="progressbar"
                    style={{ width: `${nps.promoter}%`, fontSize: "12px", color: "#011627", fontWeight: "700" }} aria-valuenow={`${nps.promoter}`}
                    aria-valuemin="0" aria-valuemax="100">
                    {props.label ? `${nps.promoter}%` : ""}
                </div>
            </div>
            {/* <div>
                {nps.detractor}, {nps.passive}, {nps.promoter}    
            </div> */}
            {
                nps.value && (
                    <div className="nps-value text-left">{nps.value}
                        <span className="nps-text">NPS</span>
                    </div>
                )
            }

            {/* <img className="rounded-circle border" src="http://via.placeholder.com/80" width="24" height="24" alt="nps" /> */}
        </div>
    );
}

export default Nps;
