import React from 'react';
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom';
import MailConfig from './mailConfig';

const Routes = (props) => {
  let { match } = props;
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={`${match.path}`} component={MailConfig} />
      </Switch>
    </BrowserRouter>
  );
};

const ReduxConnectedRoutes = withRouter(Routes);

function Index() {
  return <ReduxConnectedRoutes />;
}

export default Index;
