import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { Button, Grommet } from 'grommet'
import { Add } from 'grommet-icons'
import { connect } from 'react-redux'

import { addField } from '../../redux/actions/modifyQuestion'

const AddButton = styled.button`
  box-sizing: border-box;
  position: relative;
  background-color: #1B998B;
  border: none;
  border-radius:3px;
  color: white;
  font-size: 14px;
  font-weight:500;
  line-height: 15px;
  padding: 5px 10px;
  margin: 10px 0 10px 0;
  align-self: flex-start;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  :hover{
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
  @media screen and (max-width:425px){
    font-size: 10px;
    padding: 2px 8px;
  }
`


const AddFieldButton = ({ addField, id, }) => {

  {/* <Button label="Add" icon={<Add color="white" size="15px"/>} onClick={() => addField(id)}/> */ }
  return (
    // <Grommet theme={{
    //   button:{
    //     extend:`
    //       height: 35px;
    //       background-color: #2993a5;
    //       border-color: #2993a5;
    //       border-radius: 5px;
    //       color: white;
    //       font-size: 15px;
    //       line-height: 15px;
    //       padding-left: 15px;
    //       padding-right: 15px; 
    //       margin: 0 5px 5px 0;
    //       &:hover{
    //         background-color: #267EBD;
    //         border-color: #267EBD;
    //         box-shadow: 2px 3px 2px 2px #D9D9D9;
    //       }
    //     `
    //   }
    // }}>
    <AddButton
      onClick={() => addField(id)}
    >
      <FontAwesomeIcon icon={faPlus} style={{ color: "white", marginRight: "5px" }} />
      Add
    </AddButton>
    // </Grommet>
  )
}



//You can add dispatch like this too: 
//https://dev.to/mcrowder65/you-ve-been-doing-mapdispatchtoprops-wrong-this-entire-time-582m
const mapDispatchToProps = {
  addField: addField
}

export default connect(null, mapDispatchToProps)(AddFieldButton)