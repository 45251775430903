import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import axios from 'axios';

import { ChannelCard, ChannelItem, Banner, DeleteText, EditText } from '../../../StyledComponents'
// import { Modal } from '../../../../StyledComponents'
import DeviceCreate from './DeviceCreate'
import DeviceDetails from './DeviceDetails'
import { DotLoader } from '../../../../Loader'
import { getAllDevice, fetchDevice, postDevice, deleteDeviceURL, updateDeviceURL, deleteDeviceById } from '../../../../../url';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTabletAlt } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import showToast from '../../../../../components/Toast/toast'


const Device = props => { 
  /*States-----------------------------------------------------*/
  const [view, setView] = useState([false, false]) 
  const [device, setDevice] = useState([]) 
  let [deleteModal, setDeleteModal] = useState(false) 
  let [editModal, setEditModal] = useState(false) 
  let [deletableDeviceId, setDeletableDeviceId] = useState(false) 
  let [editableDeviceId, setEditableDeviceId] = useState(false) 
  let [loading, setLoading] = useState(false) 

  const toggle = () => setView([false, false]); 
  /*-----------------------------------------------------------*/

  // Toast 
  const DeleteToast = () => showToast('Deleted Successfully') 
  const DependencyToast = () => showToast('Cannot delete, there are dependency') 
  const ErrorToast = () => showToast('Server Error') 


  useEffect(() => { setLoading(true) }, []) 


  useEffect(() => {
    const fetchData = async () => { 
      let result, deviceData; 
      try { 
        result = await axios(getAllDevice(localStorage.getItem('jwtProfileId'))); 
        deviceData = result.data.map((d) => { 
          const filterData = { 
            id: d._id, 
            name: d.name, 
            password: d.password, 
            email: d.email, 
            active: d.active, 
            createdByCompanyID: d.createdByCompanyID, 
            macAddress: d.macAddress, 
            branchId: d.branchId ? d.branchId : '' 
          } 
          return filterData; 
        }) 
      } 
      catch (err) { deviceData = [] } 
      finally { setDevice([ ...deviceData ]); setLoading(false) } 
    }; 
    fetchData();
  }, []);


  const deleteDevice = () => { 
    axios.delete(deleteDeviceById(deletableDeviceId)) 
      .then(res => { 
        if(res.data.isDeletable) { 
          const newDevice = device.filter(item => (item.id !== deletableDeviceId)) 
          setDevice(newDevice) 
          DeleteToast() 
        } 
        else { DependencyToast() } 
        setDeleteModal(false); 
      }) 
      .catch(err => ErrorToast()) 
  } 

  const deviceList = device.map((item, id) => ( 
    <ChannelCard key={id}> 
      <ChannelItem> 
        <DeleteText item={ChannelCard} onClick={() => { setDeleteModal(true); setDeletableDeviceId(item.id);}}> 
          Delete 
        </DeleteText> 
        <EditText item={ChannelCard} onClick={() => {setEditModal(true); setEditableDeviceId(item.id)}}> Edit </EditText> 

        <div css={`height: 70%; font-size: 50px; color: #1B998B;`}> 
          <FontAwesomeIcon 
            icon={faTabletAlt} 
            css={`padding: .1px; @media only screen and (max-width: 425px){}`}/> 
        </div> 
        <div css={` height: 30%; color:white; background-color: rgb(52, 58, 64); word-break: break-all; padding: 0 5px; `} >
          {item.name} 
        </div>
      </ChannelItem> 
    </ChannelCard> 
  )) 

  
  return ( 
    <> 
      {/* Delete Modal */} 
      { 
        deleteModal && 
        <Modal isOpen={deleteModal} className={props.className}> 
          <ModalHeader> Delete Device </ModalHeader> 
          <ModalBody> Are you sure, you want to delete? </ModalBody> 
          <ModalFooter> 
            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={deleteDevice}> 
              Delete 
            </Button> 
            
            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={() => setDeleteModal(false)}> 
              Cancel
            </Button> 
          </ModalFooter> 
        </Modal> 
      } 


      { 
        loading ? <DotLoader /> 
        : 
        <div 
          css={` display: flex; position: relative; justify-content: flex-start; align-items: flex-start; align-content: flex-start; flex-wrap: wrap; `} >
          <Banner>Device List</Banner> 
          
          {/* Create Device Modal */} 
          <Modal isOpen={view[0]} toggle={toggle} className={props.className}> 
            <ModalHeader toggle={toggle}> 
              <span style={{margin:'0 0 0 25px',color:'rgb(52, 58, 64)',fontSize:'18px'}}>Create Device</span> 
            </ModalHeader> 
            <DeviceCreate /> 
          </Modal> 

          {/* Edit Device Modal */} 
          <Modal isOpen={editModal} toggle={() => setEditModal(false)} className={props.className} style={{width: '100%'}}> 
            <ModalHeader toggle={() => setEditModal(false)}> 
              <span style={{margin: '0 0 0 25px',color:'rgb(52, 58, 64)',fontSize:'18px'}}>Edit Device</span> 
            </ModalHeader> 
            <DeviceDetails data={device.find((item) => item.id === editableDeviceId)} /> 
          </Modal> 

          {deviceList}  {/* List View */} 

          { 
            props.activestatus && 
            <ChannelCard> 
              <ChannelItem onClick={() => { setView([true, false]) }}> 
                <div css={`height: 70%; font-size: 30px; color: #1B998B;`}> + </div> 
                <div css={`height: 30%; color:white; background-color: rgb(52, 58, 64);`}>Add Device</div> 
              </ChannelItem> 
            </ChannelCard> 
          } 
        </div>
      } 
    </> 
  ) 
} 

export default Device; 