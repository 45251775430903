import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'reactstrap'
import Table from './Table'
import { getAll_ } from '../../store/actions/actionCreator'
import showToast from '../../../../../components/Toast/toast'


function Index() { 
    const dispatch = useDispatch()
    let { allData } = useSelector(state => state.designation) 

    try { 
        if(!Object.keys(allData).length) { allData.designation = [] } 
        if(Object.keys(allData).length) { 
            allData.designation.sort(function(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            }); 
        } 
    } 
    catch (error) { } 

    const columns = React.useMemo(() => 
        [ 
            { Header: 'Name', accessor: 'name' }, 
            { Header: 'Active', accessor: 'active' } 
        ], [] 
    ) 

    const ErrorToast = () => showToast('Server Error') 
    useEffect(() => { dispatch(getAll_(ErrorToast)) }, []) 

    return ( 
        <div className="animated fadeIn"> 
            <Card style={{ border: '0px', background: allData.designation.length ? 'transparent' : null }}> 
                <Table columns={columns} data={allData.designation} /> 
            </Card> 
        </div> 
    ) 
} 

export default Index
