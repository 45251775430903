import {
  TOGGLE_QUESTION,
  SORT_QUESTION,
  SORT_PROJECTS,
  REMOVE_QUESTION,
  ADD_CARD,
  REMOVE_CARD,
  ADD_IMAGE,
  EDIT_HEADER,
  CHANGE_CONTACT_CARD_OPTIONS,
  TOGGLE_CONDITIONAL,
  TOGGLE_REQUIRED,
  SET_QUESTION_ID_FOR_CONDITION,
  SAVE_CONFIGURATION,
  CONFIGURATION_SAVED,
  RESET,
  TOGGLE_CONDITIONAL_PREVIEW,
  SET_QUESTION_FOR_CONDITIONAL_PREVIEW,
  SET_OPTION_FOR_CONDITIONAL_PREVIEW,
  SET_NAME,
  SET_ACTIVE,
  SET_STATUS,
  PROFILE_ID,
  PUSH_CHANNEL_LIST,
  DEVICE_LIST,
  SET_DEVICE_STATUS,
  SET_DIST_DEVICE,
  INIT_OPEN_LINK,
  SET_OPEN_LINK,
  UPDATE_OPEN_LINK,
  SET_OPEN_LINK_IN_DIST,
  INIT_QR_CODE,
  SET_QR_CODE,
  SET_GEN_QR_CODE,
  SET_QR_CODE_IN_DIST,
  SET_ACTIVE_CHANNEL,
  EDIT_DISTRIBUTION_QUESTION,
  SET_PROGRESSBAR
} from '../actions/const'

import * as cardConfig from '../../config'

const questionReducer = (
  state = {
    name: "",
    active: true,
    status: "",
    progressbar: true,
    profileId: '',
    distributionId: '',
    selectedQuestion: {},
    pushChannelList: [],
    deviceList: [],
    openLink: {},
    qRCode: {},
    activeChannels: {},
    order: {
      list: [],
      cardsList: cardConfig.cardsList,
      projects: {},
      cards: {}
    },
    header: {
      text: '',
      image: ''
    },
    isSaving: false,
    isSaved: true,
    message: ""
  },
  action
) => {

  switch (action.type) {

    case SET_NAME:
      return {
        ...state,
        name: action.name
      }

    case SET_ACTIVE:
      return {
        ...state,
        active: action.active
      }

    case SET_STATUS:
      return {
        ...state,
        status: action.status
      }

    case PROFILE_ID:
      return {
        ...state,
        profileId: action.profileId
      }

    case PUSH_CHANNEL_LIST:
      return {
        ...state,
        pushChannelList: [...state.pushChannelList, action.channel]
      }

    case DEVICE_LIST:
      return {
        ...state,
        deviceList: action.device
      }

    case SET_DEVICE_STATUS:
      return {
        ...state,
        // deviceList: [...state.deviceList, {deviceList[action.payload.index].status : action.payload.status}]
        deviceList: state.deviceList.map((device, index) => {
          if (index === action.payload.index) {
            device.status = action.payload.status
          }
          return device
        })
      }

    case SET_DIST_DEVICE:
      return {
        ...state
      }

    case INIT_OPEN_LINK:
      return {
        ...state,
        openLink: action.payload
      }

    case SET_OPEN_LINK:
      return {
        ...state,
        openLink: action.payload
      }

    case INIT_QR_CODE: {
      return {
        ...state,
        qRCode: action.payload
      }
    }

    case SET_QR_CODE:
      return {
        ...state,
        qRCode: action.payload
      }

    case UPDATE_OPEN_LINK:
      return {
        ...state,
        openLink: action.payload
      }
    case SET_OPEN_LINK_IN_DIST:
      return {
        ...state
      }

    case SET_GEN_QR_CODE:
      return {
        ...state,
        qRCode: action.payload
      }

    case SET_QR_CODE_IN_DIST:
      return {
        ...state
      }

    case SET_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannels: action.payload
      }

    case SET_PROGRESSBAR:
      return {
        ...state,
        progressbar: action.payload
      }

    case TOGGLE_QUESTION: {
      const { question, projectId } = action
      const { selectedQuestion, order } = state
      const newQuestion = {
        ...question,
        isRequired: false,
        isConditional: false,
        jump: [],
        previewCondition: {
          status: false,
          questionId: '',
          optionIndex: null,
          optionsList: []
        },
        dependant: []
      }

      if (!selectedQuestion.hasOwnProperty(projectId)) {

        selectedQuestion[projectId] = []
        selectedQuestion[projectId].push(newQuestion)

        order.list.push({ id: projectId, type: "project" })
        order.projects[projectId] = { projectId, questionList: [] }
        order.projects[projectId].questionList.push(question.id)

      } else {

        if (selectedQuestion[projectId].some(element => element.id === question.id)) {

          const newList = selectedQuestion[projectId].filter(element => element.id !== question.id)
          selectedQuestion[projectId] = newList

          const newQuestionList = order.projects[projectId].questionList.filter(element => element !== question.id)
          order.projects[projectId].questionList = newQuestionList

        } else {

          selectedQuestion[projectId].push(newQuestion)

          order.projects[projectId].questionList.push(question.id)

        }
      }

      const keys = Object.keys(selectedQuestion)
      keys.forEach(key => {
        if (selectedQuestion[key].length === 0) {
          delete selectedQuestion[key]
          order.list = order.list.filter(element => element.id !== key)
          delete order.projects[key]
        }
      })

      return {
        ...state,
        selectedQuestion: { ...selectedQuestion },
        order: { ...order },
        isSaved: false
      }
    }

    case TOGGLE_REQUIRED: {
      const { projectId, questionId } = action
      const questionList = state.selectedQuestion[projectId]
      const newQuestionList = questionList.map(item => {
        if (item.id !== questionId) {
          return item
        } else {
          const { isRequired } = item
          const newItem = {
            ...item,
            isRequired: !isRequired
          }
          return newItem
        }
      })

      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: newQuestionList
        },
        isSaved: false
      }
      return newState
    }

    case TOGGLE_CONDITIONAL: {
      const { projectId, questionId } = action
      const questionList = state.selectedQuestion[projectId]
      const newQuestionList = questionList.map(item => {
        if (item.id !== questionId) {
          return item
        } else {
          const { isConditional } = item
          const newItem = {
            ...item,
            isConditional: !isConditional,
            jump: []
          }
          return newItem
        }
      })

      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: newQuestionList
        },
        isSaved: false
      }
      return newState
    }

    case TOGGLE_CONDITIONAL_PREVIEW: {
      const { projectId, questionId } = action
      const questionList = state.selectedQuestion[projectId]
      let conditionalId = ''
      const newQuestionList = questionList.map(item => {
        const { previewCondition } = item
        if (item.id !== questionId) {
          return item
        } else {
          conditionalId = previewCondition.questionId
          const newItem = {
            ...item,
            previewCondition: {
              status: !previewCondition.status,
              questionId: '',
              optionIndex: null,
              optionsList: []
            }
          }
          return newItem
        }
      })
      newQuestionList.forEach(item => {
        if (item.id === conditionalId) {
          item.dependant = item.dependant.filter(id => id !== questionId)
        }
      })
      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: newQuestionList
        },
        isSaved: false
      }
      return newState
    }

    case SET_QUESTION_FOR_CONDITIONAL_PREVIEW: {
      const { projectId, questionId, conditionalId, optionsList } = action
      const questionList = state.selectedQuestion[projectId]
      const newQuestionList = questionList.map(item => {
        if (item.id !== questionId) {
          if (item.id === conditionalId) {
            return {
              ...item,
              isRequired: true,
              dependant: [...item.dependant, questionId]
            }
          }
          if (!conditionalId) {
            return {
              ...item,
              isRequired: true,
              dependant: item.dependant.filter(id => id !== questionId)
            }
          }
          return item
        }
        else {
          const { previewCondition } = item
          const newItem = {
            ...item,
            previewCondition: {
              ...previewCondition,
              questionId: conditionalId,
              optionIndex: conditionalId ? 0 : null,
              optionsList: [...optionsList]
            }
          }
          return newItem
        }
      })

      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: newQuestionList
        },
        isSaved: false
      }
      return newState
    }

    case SET_OPTION_FOR_CONDITIONAL_PREVIEW: {
      const { projectId, questionId, conditionalId } = action
      const questionList = state.selectedQuestion[projectId]
      const newQuestionList = questionList.map(item => {
        if (item.id !== questionId) {
          return item
        }
        else {
          const { previewCondition } = item
          const newItem = {
            ...item,
            previewCondition: {
              ...previewCondition,
              optionIndex: conditionalId
            }
          }
          return newItem
        }
      })

      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: newQuestionList
        },
        isSaved: false
      }
      return newState
    }

    case SET_QUESTION_ID_FOR_CONDITION: {
      const { projectId, questionId, optionIndex, destinationQuestionId } = action
      const { selectedQuestion } = state
      let questionList = selectedQuestion[projectId]
      const updatedQuestion = questionList.find(item => item.id === questionId)

      if (!destinationQuestionId) {

        updatedQuestion.jump = updatedQuestion.jump.filter(item => item.jumpOptionIndex !== optionIndex)

      } else {

        if (updatedQuestion.jump.some(item => item.jumpOptionIndex === optionIndex)) {
          updatedQuestion.jump = updatedQuestion.jump.map(item => {
            if (item.jumpOptionIndex !== optionIndex) {
              return item
            } else {
              return {
                ...item,
                jumpTo: destinationQuestionId
              }
            }
          })
        } else {

          updatedQuestion.jump.push({ jumpOptionIndex: optionIndex, jumpTo: destinationQuestionId })

        }

      }

      const updatedQuestionList = questionList.map(item => {
        if (item.id !== updatedQuestion.id) {
          return item
        } else {
          return updatedQuestion
        }
      })

      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [projectId]: [...updatedQuestionList]
        },
        isSaved: false
      }

      return newState
    }

    case SORT_QUESTION: {
      const { destination, source, questionId } = action
      const { projects } = state.order
      projects[source.droppableId].questionList.splice(source.index, 1)
      projects[destination.droppableId].questionList.splice(destination.index, 0, questionId)
      const questionList = state.selectedQuestion[source.droppableId]
      const updatedQuestionList = questionList.map(item => ({ ...item, jump: [], isConditional: false }))
      const newState = {
        ...state,
        selectedQuestion: {
          ...state.selectedQuestion,
          [source.droppableId]: updatedQuestionList
        },
        order: {
          ...state.order,
          projects: { ...projects }
        },
        isSaved: false
      }
      return newState
    }

    case SORT_PROJECTS: {
      const { destination, source, projectId } = action
      const { list } = state.order
      const data = list.find(item => item.id === projectId)
      list.splice(source.index, 1)
      list.splice(destination.index, 0, data)
      const newState = {
        ...state,
        order: {
          ...state.order,
          list: [...list]
        },
        isSaved: false
      }
      return newState
    }

    case REMOVE_QUESTION: {
      const { projectId, questionId } = action
      const { selectedQuestion, order } = state
      selectedQuestion[projectId] = selectedQuestion[projectId].filter(element => element.id !== questionId)
      console.log('selected question', selectedQuestion[projectId])
      selectedQuestion[projectId].forEach(ques => {
        ques.dependant = ques.dependant.filter(id => id !== questionId)
      })
      order.projects[projectId].questionList = order.projects[projectId].questionList
        .filter(element => element !== questionId)
      if (order.projects[projectId].questionList.length === 0) {
        delete selectedQuestion[projectId]
        delete order.projects[projectId]
        order.list = order.list.filter(element => element.id !== projectId)
      } else {
        selectedQuestion[projectId] = selectedQuestion[projectId]
          .map(item => ({
            ...item,
            isConditional: false,
            jump: []
          }))
      }
      const newState = {
        ...state,
        selectedQuestion: { ...selectedQuestion },
        order: { ...order },
        isSaved: false
      }
      return newState
    }

    case ADD_CARD: {
      const { destination, draggableId } = action
      const { cardsList, list } = state.order
      const card = cardsList.find(item => item.type === draggableId)
      let data
      if (!card.multiple) {
        data = { id: card.id, type: card.type }
        list.splice(destination.index, 0, data)
      }
      const newCards = { ...state.order.cards }
      switch (card.type) {
        case 'contactCard': {
          newCards[card.id] = { type: data.type, fields: { ...cardConfig.contactCard.fields } }
          break
        }
        default:
          return state
      }
      const newState = {
        ...state,
        order: {
          ...state.order,
          list: [...list],
          cards: { ...newCards }
        },
        isSaved: false
      }

      return newState
    }

    case REMOVE_CARD: {
      const { id } = action
      const { list, cards } = state.order
      const newList = list.filter(item => item.id !== id)
      delete cards[id]
      const newState = {
        ...state,
        order: {
          ...state.order,
          list: [...newList],
          cards: { ...cards }
        },
        isSaved: false
      }
      return newState
    }

    case EDIT_HEADER: {
      const { data } = action
      const newState = {
        ...state,
        header: {
          ...state.header,
          text: data
        },
        isSaved: false
      }
      return newState
    }

    case ADD_IMAGE: {
      const { data } = action
      const newState = {
        ...state,
        header: {
          ...state.header,
          image: data
        },
        isSaved: false
      }
      return newState
    }

    case CHANGE_CONTACT_CARD_OPTIONS: {
      const { options } = action
      const { contactCard } = state.order.cards
      Object.keys(contactCard.fields).forEach(field => {
        if (options.some(option => option.value === field)) {
          contactCard.fields[field] = true
        } else {
          contactCard.fields[field] = false
        }
      })
      const newState = {
        ...state,
        order: {
          ...state.order,
          cards: {
            ...state.order.cards,
            contactCard: { ...contactCard }
          }
        },
        isSaved: false
      }

      return newState
    }

    case SAVE_CONFIGURATION: {
      return {
        ...state,
        isSaving: true,
      }
    }

    case CONFIGURATION_SAVED: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        distributionId: action.resData._id,
        status: action.resData.status
      }
    }

    case RESET: {
      return {
        selectedQuestion: {},
        order: {
          list: [],
          cardsList: cardConfig.cardsList,
          projects: {},
          cards: {}
        },
        header: {
          text: '',
          image: ''
        },
        isSaving: false,
        isSaved: true,
        message: ""
      }
    }

    case EDIT_DISTRIBUTION_QUESTION:
      // console.log('edit dist reducer', action.payload)
      return {
        ...state,
        name: action.payload.name,
        active: action.payload.active,
        status: action.payload.status,
        profileId: action.payload.profileId,
        distributionId: action.payload.disid,
        selectedQuestion: action.payload.question.questionBank.questionOrder,
        header: { text: action.payload.header, image: action.payload.logo },
        order: { ...state.order, ...action.payload.question.questionBank.order }
      }


    default:
      return { ...state }
  }
}

export default questionReducer