import React from 'react'

import { Container, Image, TextBox, Title } from './styles'

import ImageUpload from './ImageUpload'
import TextEditor from './TextEditor'

const Header = props => {
  return (
    <>
      <Title>Header</Title>
      <Container>
        <Image>
          <ImageUpload />
        </Image>
        <TextBox>
          <TextEditor style={{ textAlign: 'initial' }} />
        </TextBox>
      </Container>
    </>
  )
}

export default Header