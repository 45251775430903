import styled from 'styled-components'

export const Choice = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  >input{
    margin: 5px;
    padding: 0;
  }
  >label{
    display: block;
    padding: 5px;
    flex-grow: 1;
    font-size:.8em
  }
`