
let init = { sidebarState: true } 


const sidebarStateReducer = (state = init, action) => { 
    switch (action.type) { 
        case 'UPDATE_STATE': { 
            return { 
                sidebarState: action.payload 
            } 
        } 
        default: return state 
    } 
} 

export default sidebarStateReducer;
