import React from 'react';
import { Body, Banner } from '../../UserProfile/StyledComponents';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import baseUrl from '../../../url';
import './style.css';
import { Col } from 'reactstrap';

class ChangePassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = { success: '', error: '' };
	}
	render() {
		return (
			<Body>
				{/* <Banner>Change Password</Banner> */}
				<h4
					style={{
						marginTop: '30px',
						borderBottom: '1px solid #D0D0D0',
						textAlign: 'center',
						width: '100%',
						paddingBottom: '10px',
						color: 'rgb(52, 58, 64)',
					}}
				>
					Change Password
				</h4>
				<div className='change-password'>
					<Formik
						initialValues={{
							oldPassword: '',
							newPassword: '',
							confirmNewPassword: '',
						}}
						onSubmit={(values, actions) => {
							const obj = {
								currentPassword: values.oldPassword,
								newPassword: values.newPassword,
								confirmNewPassword: values.confirmNewPassword,
							};
							axios
								.put(
									`${baseUrl}/api/login/changePassword/${localStorage.getItem(
										'jwtID'
									)}`,
									obj
								)
								.then((response) => {
									// console.log(response);
									actions.resetForm();
									actions.setSubmitting(false);
									this.setState({
										success: 'Your password has been reset successfully!',
									});
									setTimeout(() => this.setState({ success: '' }), 3000);
								})
								.catch((error) => {
									// console.log(error);
									actions.setSubmitting(false);
									this.setState({
										error: 'Something went wrong! Please try again.',
									});
									setTimeout(() => this.setState({ error: '' }), 3000);
								});
						}}
						validationSchema={Yup.object().shape({
							oldPassword: Yup.string().required(
								'Current password is required.'
							),
							newPassword: Yup.string().required('New password is required'),
							confirmNewPassword: Yup.string()
								.required('Confirm new password is required.')
								.test(
									'confirm-new-password',
									'Passwords must be matched',
									function (value) {
										return this.parent.newPassword === value;
									}
								),
						})}
					>
						{(props) => {
							const {
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
							} = props;
							return (
								<form onSubmit={handleSubmit}>
									<div className='row' style={{ marginBottom: '0' }}>
										<label
											htmlFor='oldPassword'
											style={{
												display: 'block',
												padding: '10px 0 0 0',
												marginBottom: '0',
												color: 'rgb(52, 58, 64)',
											}}
											className='col-md-5 col-lg-4'
										>
											Current Password
										</label>
										<div
											className='col-sm-12 col-md-7 col-lg-8'
											style={{ padding: '0' }}
										>
											<input
												type='password'
												id='oldPassword'
												// placeholder="Enter your password"
												value={values.oldPassword}
												onChange={handleChange}
												onBlur={handleBlur}
												style={{
													borderColor:
														errors.oldPassword && touched.oldPassword
															? 'red'
															: 'none',
													padding: '8px',
												}}
											/>
											{errors.oldPassword && touched.oldPassword && (
												<div
													className='input-feedback'
													style={{ fontSize: '12px' }}
												>
													{errors.oldPassword}
												</div>
											)}
										</div>
									</div>

									<div className='row' style={{ marginBottom: '0' }}>
										<label
											htmlFor='newPassword'
											style={{
												display: 'block',
												padding: '10px 0',
												marginBottom: '0',
												color: 'rgb(52, 58, 64)',
											}}
											className='col-md-5 col-lg-4'
										>
											New Password
										</label>
										<div
											className='col-sm-12 col-md-7 col-lg-8'
											style={{ padding: '0' }}
										>
											<input
												id='newPassword'
												// placeholder="Enter your newPassword"
												type='password'
												value={values.newPassword}
												onChange={handleChange}
												onBlur={handleBlur}
												style={{
													borderColor:
														errors.newPassword && touched.newPassword
															? 'red'
															: 'none',
													padding: '8px',
												}}
											/>
											{errors.newPassword && touched.newPassword && (
												<div
													className='input-feedback'
													style={{ fontSize: '12px' }}
												>
													{errors.newPassword}
												</div>
											)}
										</div>
									</div>

									<div className='row' style={{ marginBottom: '0' }}>
										<label
											htmlFor='confirmNewPassword'
											style={{
												display: 'block',
												padding: '10px 0',
												marginBottom: '0',
												color: 'rgb(52, 58, 64)',
											}}
											className='col-md-5 col-lg-4'
										>
											Confirm New Password
										</label>
										<div
											className='col-sm-12 col-md-7 col-lg-8'
											style={{ padding: '0' }}
										>
											<input
												id='confirmNewPassword'
												// placeholder="Enter your confirmNewPassword"
												type='password'
												value={values.confirmNewPassword}
												onChange={handleChange}
												onBlur={handleBlur}
												style={{
													borderColor:
														errors.confirmNewPassword &&
														touched.confirmNewPassword
															? 'red'
															: 'none',
													padding: '8px',
												}}
											/>
											{errors.confirmNewPassword &&
												touched.confirmNewPassword && (
													<div
														className='input-feedback'
														style={{ fontSize: '12px' }}
													>
														{errors.confirmNewPassword}
													</div>
												)}
										</div>
									</div>

									<button
										type='submit'
										disabled={isSubmitting}
										className='btnHover'
										style={{
											float: 'left',
											marginLeft: '-15px',
											background: '#1B998B',
											color: '#fff',
										}}
									>
										Change password
									</button>

									{this.success !== '' && (
										<p className='success'>{this.state.success}</p>
									)}
									{this.error !== '' && (
										<p className='error'>{this.state.error}</p>
									)}
								</form>
							);
						}}
					</Formik>
				</div>
			</Body>
		);
	}
}

export default ChangePassword;
