import React, { useEffect, memo } from 'react';
import { connect } from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Body, Container } from './styles';
import Header from '../../components/Header';
// import Footer from '../../components/Footer'
import Questions from '../Questions';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import Spnnier from '../../components/Spnnier';
import {
  init,
  submitData,
  resetForm,
  resetFormOnClick,
  setChannelID,
  setChannelType,
} from '../../redux/actions';
import {
  dataLoadedSelector,
  submitFunctionSelector,
  dataAvailabilitySelector,
  postDataSelector,
  redirectUrlSelector,
} from '../../redux/selectors';

import PoweredByPEX from '../../components/PoweredByPEX';

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: 'green',
  diffNameColor: 'aqua',
});

const TextCenter = styled.p`
  text-align: center;
`;

const FormSubmission = (props) => {
  const urlParamsObj = window.location.search;
  const urlParams = new URLSearchParams(urlParamsObj);
  const isEmailChannel = urlParams.get('channel') === 'email';
  const isFollowUp = urlParams.get('followUp') === 'true';
  const isTicketId = urlParams.get('ticketId');
  const {
    // channelInfoFetch,
    dataLoaded,
    submit,
    dataAvailable,
    channelType,
    setChannelID,
    setChannelType,
    postData,
    submitData,
    init,
    isPreview,
    subChannelId,
    disId,
    resetForm,
    resetFormOnClick,
    header,
    userType,
    redirectUrl,
    createdByCompanyID,
  } = props;

  useEffect(() => {
    setChannelType(channelType);
    setChannelID(subChannelId);
    init(disId, userType);
  }, [init, disId, userType]);

  const startSubmit = () => {
    // ;
    if (submit && !isPreview) {
      submitData(
        {
          ...postData,
          surveyLink: window.location.href,
          channelType: isEmailChannel ? 'email' : channelType,
          ...(isFollowUp && { followUp: true, ticketId: isTicketId }),
        },
        createdByCompanyID
      );
      if (channelType === 'device') {
        resetForm();
      }
    }
  };

  useEffect(() => {
    startSubmit();
  }, [submit]);

  const render = dataLoaded ? (
    dataAvailable ? (
      <Container>
        {submit ? (
          isPreview ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '35px 0',
              }}
            >
              <TextCenter>End of Preview Page</TextCenter>
              <button
                className='btn btn-success btn-sm'
                style={{ backgroundColor: '#1b998a', borderColor: '#1b998a' }}
                onClick={() => resetFormOnClick()}
              >
                Preview again
              </button>
            </div>
          ) : (
            <Spnnier channelType={channelType} redirectUrl={redirectUrl} />
          )
        ) : (
          <>
            <Header header={header} />
            <Questions />
          </>
        )}
        <PoweredByPEX />
      </Container>
    ) : (
      <TextCenter style={{ marginTop: '15px' }}>
        Distribution is inactive.
      </TextCenter>
    )
  ) : (
    'Loading'
  );

  return render;
};

FormSubmission.whyDidYouRender = true;

const mapStateToProps = (state, ownProps) => {
  const { isPreview, channelType, subChannelId } = ownProps;

  return {
    dataLoaded: dataLoadedSelector(state),
    submit: submitFunctionSelector(state),
    dataAvailable: dataAvailabilitySelector(state),
    postData: postDataSelector(state),
    redirectUrl: redirectUrlSelector(state),
    // disId: state.question.distributionId,
    isPreview,
    channelType,
    subChannelId,
  };
};

const mapDispatchToProps = {
  // channelInfoFetch,
  setChannelID,
  setChannelType,
  submitData,
  init,
  resetForm,
  resetFormOnClick,
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(FormSubmission)
);
