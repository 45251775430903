import React, { useState, useEffect, memo } from 'react';
import styled from 'styled-components/macro';
import axios from 'axios';
import Avatar from '../../../../resources/Banolota-Cover-pic.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEdit,
	faPlusCircle,
	faSave,
	faCamera,
} from '@fortawesome/free-solid-svg-icons';
import {
	Button,
	Input,
	Label,
	Body,
	UploadContainer,
	UploadButton,
	Upload,
} from './StyledComponents';
import {
	fetchProfile,
	putProfile,
	postImageURL,
	postCoverImageURL,
} from '../../../../url';
import { Formik } from 'formik';
import Loader from '../../../Loader';

const Information = (props) => {
	const [editable, setEditable] = useState({
		data: false,
		picture: false,
		loaded: '',
		fetched: false,
	});

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(
				fetchProfile(localStorage.getItem('jwtProfileId'))
			);

			setEditable({
				...editable,
				loaded: result.data,
				fetched: !editable.fetched,
			});
		};

		fetchData();
	}, []);

	const editHandler = () => {
		setEditable({
			...editable,
			data: !editable.data,
		});
	};

	const postFormHandler = async (values) => {
		const data = {
			// "user_Id": localStorage.getItem('jwtID'), contact
			name: values.name,
			// "designation": values.designation,
			organization: values.organization,
			website: values.website,
			contact: values.contact,
			email: values.email,
			address: values.address,
			picture: editable.loaded.picture,
		};

		axios
			.put(putProfile(localStorage.getItem('jwtProfileId')), data)
			.then((result) => {
				setEditable({
					...editable,
					data: !editable.data,
					loaded: result.data,
				});
			});
		// .catch(err => console.log(err))

		editHandler();
	};

	const changePictureHandler = () => {
		setEditable({
			...editable,
			picture: !editable.picture,
		});
	};

	const handleFileUplaod = (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append('file', event.target.files[0]);

		axios
			.post(postImageURL(localStorage.getItem('jwtProfileId')), formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				console.log(response.data);
				setEditable({
					...editable,
					loaded: response.data,
				});
			})
			.catch((error) => {
				// console.log(error);
			});
	};

	const handleCoverUpload = (event) => {
		event.preventDefault();
		const formData = new FormData();
		formData.append('file', event.target.files[0]);

		axios
			.post(postCoverImageURL(localStorage.getItem('jwtProfileId')), formData, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then((response) => {
				setEditable({
					...editable,
					loaded: response.data,
				});
			})
			.catch((error) => {
				// console.log(error);
			});
	};
	const ViewDetails = () => (
		<Body>
			{/*cover pic */}
			<div
				css={`
					display: flex;
					border: 3px solid white;
					vertical-align: middle;
					width: 100%;
					height: 180px;
					margin-bottom: 10px;
					box-shadow: 0 0 3px grey;
					background-color: #1b998b;
					align-items: center;
					justify-content: center;
					color: white;
				`}
			>
				{editable.loaded.coverPicture ? (
					<img
						alt='cover'
						src={editable.loaded.coverPicture}
						css={`
							width: 100%;
							height: 100%;
						`}
					/>
				) : (
					<h1>Add Cover Photo</h1>
				)}
			</div>
			{/*cover pic */}

			<div
				css={`
					display: flex;
					border: 3px solid white;
					position: absolute;
					vertical-align: middle;
					width: 120px;
					height: 120px;
					align-items: center;
					justify-content: center;
					margin-bottom: 10px;
					box-shadow: 0 0 3px grey;
					border-radius: 50%;
					right: 60px;
					top: 140px;
					color: white;
					background-color: #1b998b;
					@media only screen and (max-width: 425px) {
						width: 100px;
						height: 100px;
						right: 140px;
					}
					@media only screen and (max-width: 375px) {
						width: 100px;
						height: 100px;
						right: 115px;
					}
					@media only screen and (max-width: 320px) {
						width: 100px;
						height: 100px;
						right: 85px;
					}
				`}
			>
				{editable.loaded.picture ? (
					<img
						alt='company logo'
						src={editable.loaded.picture}
						css={`
							width: 100%;
							height: 100%;
							border-radius: 50%;
						`}
					/>
				) : (
					<span>
						Your
						<br />
						Logo
					</span>
				)}
			</div>

			<Label>
				{' '}
				<h1>{editable.loaded.name} </h1>
			</Label>
			<aside>
				<section>
					<Label>Organization Type: </Label>
					<span>{editable.loaded.organization} </span>
					<Label>Contact : </Label>
					<span>{editable.loaded.contact} </span>
					<Label>Email : </Label>
					<span>{editable.loaded.email} </span>
				</section>
				<section>
					<Label>Website : </Label>
					<span>{editable.loaded.website} </span>
					<Label>Address : </Label>
					<span>{editable.loaded.address}</span>
				</section>
			</aside>
			<Button
				css={`
					margin: 5px 0;
					background: '';
				`}
				onClick={editHandler}
			>
				<FontAwesomeIcon
					icon={faEdit}
					css={`
						margin: 0 5px 0 0;
					`}
				/>
				Edit
			</Button>
		</Body>
	);

	const ViewEdit = () => (
		<Body>
			{/*cover pic */}
			<div
				css={`
					display: flex;
					border: 3px solid white;
					vertical-align: middle;
					width: 100%;
					height: 180px;
					margin-bottom: 10px;
					box-shadow: 0 0 3px grey;
					align-items: center;
					justify-content: center;
					color: #310d31;
				`}
			>
				<img
					alt='cover'
					src={editable.loaded.coverPicture}
					css={`
						width: 100%;
						height: 100%;
						opacity: 0.7;
					`}
				/>
				<span
					css={`
						position: absolute;
					`}
				>
					Minimum Width and Height 768*168
				</span>
			</div>
			{/*cover pic */}

			{editable.loaded.picture ? (
				<img
					alt='avatar'
					src={editable.loaded.picture}
					css={`
						background-color: white;
						border: 3px solid white;
						position: absolute;
						vertical-align: middle;
						width: 120px;
						height: 120px;
						margin-bottom: 10px;
						box-shadow: 0 0 3px grey;
						border-radius: 50%;
						right: 60px;
						top: 140px;
						@media only screen and (max-width: 425px) {
							width: 100px;
							height: 100px;
							right: 140px;
						}
						@media only screen and (max-width: 375px) {
							width: 100px;
							height: 100px;
							right: 115px;
						}
						@media only screen and (max-width: 320px) {
							width: 100px;
							height: 100px;
							right: 85px;
						}
					`}
				/>
			) : (
				<span
					css={`
						background-color: white;
						border: 3px solid white;
						position: absolute;
						vertical-align: middle;
						width: 120px;
						display: flex;
						align-items: center;
						justify-content: center;
						height: 120px;
						margin-bottom: 10px;
						box-shadow: 0 0 3px grey;
						border-radius: 50%;
						right: 60px;
						top: 140px;
						@media only screen and (max-width: 425px) {
							width: 100px;
							height: 100px;
							right: 140px;
						}
						@media only screen and (max-width: 375px) {
							width: 100px;
							height: 100px;
							right: 115px;
						}
						@media only screen and (max-width: 320px) {
							width: 100px;
							height: 100px;
							right: 85px;
						}
					`}
				>
					Avatar
				</span>
			)}

			{/* //upload button for cover pic */}
			<UploadContainer
				css={`
					@media only screen and (max-width: 768px) {
						width: 100px;
						height: 20px;
					}
					@media only screen and (max-width: 425px) {
						width: 85px;
						height: 20px;
					}
				`}
			>
				<Upload type='file' name='company_cover' onChange={handleCoverUpload} />
				<UploadButton>
					<FontAwesomeIcon
						icon={faCamera}
						css={`
							margin: 0 5px 0 0;
							color: white;
						`}
					/>
					Upload Photo
				</UploadButton>
			</UploadContainer>
			{/* //upload button for cover pic */}

			{/* //upload button for company profile pic */}
			<UploadContainer
				css={`
            width:20px;
            height:20px;
            top: 230px;
            right: 70px;
            border: 2px solid white;
            border-radius: 50% 
            background-color:white;
            @media only screen and (max-width: 425px){
            top: 215px;
            right: 145px;  
            }
            @media only screen and (max-width: 375px){
            top: 215px;
            right: 120px;  
            }
            @media only screen and (max-width: 320px){
            top: 215px;
            right: 90px;  
            }           
            `}
			>
				<Upload type='file' name='company_logo' onChange={handleFileUplaod} />
				<UploadButton
					css={`
						background-color: rgba(0, 0, 0, 0);
					`}
				>
					<FontAwesomeIcon
						icon={faPlusCircle}
						css={`
							font-size: 20px;
							color: #310d31;
						`}
					/>
				</UploadButton>
			</UploadContainer>
			{/* //upload button for company profile pic */}

			<br />

			<Formik
				initialValues={{
					name: editable.loaded.name,
					// designation: editable.loaded.designation,
					organization: editable.loaded.organization,
					website: editable.loaded.website,
					contact: editable.loaded.contact,
					email: editable.loaded.email,
					address: editable.loaded.address,
				}}
				onSubmit={(values) => postFormHandler(values)}
				render={({
					handleSubmit,
					handleChange,
					handleBlur,
					values,
					errors,
					touched,
				}) => (
					<form
						onSubmit={handleSubmit}
						css={`
							width: 100%;
							margin-top: 55px;
						`}
					>
						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Name:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='name'
									type='text'
									value={values.name}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Organization Type:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='organization'
									type='text'
									value={values.organization}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Contact:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='contact'
									type='text'
									value={values.contact}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Email:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='email'
									type='text'
									value={values.email}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Website:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='website'
									type='text'
									value={values.website}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Label>
							<div
								css={`
									width: 30%;
									text-align: left;
								`}
							>
								Address:
							</div>
							<div
								css={`
									width: 60%;
								`}
							>
								<Input
									name='address'
									type='text'
									value={values.address}
									onChange={handleChange}
								/>
							</div>
						</Label>

						<Button type='submit'>
							<FontAwesomeIcon
								icon={faSave}
								css={`
									margin: 0 5px 0 0;
								`}
							/>
							Save Info
						</Button>
					</form>
				)}
			/>
		</Body>
	);

	return (
		<React.Fragment>
			{editable.fetched === false ? (
				<Loader />
			) : editable.data === false ? (
				<ViewDetails />
			) : (
				<ViewEdit />
			)}
		</React.Fragment>
	);
};

export default memo(Information);
