import * as Actions from '../actions/actionTypes';
import questionSelect from './functions/questionSelect';
import { addField } from './functions/addField';
import { deleteField } from './functions/deleteField';
import { editField } from './functions/editField';
import { editCheckbox } from './functions/editCheckbox';
import { editTitle } from './functions/editTitle';
import { changeType } from './functions/changeType';
import { libraryUpdate } from './functions/libraryUpdate';
import uniqueID from '../../../../util/uniqueId';
import { stat } from 'fs';

const init = {
  userId: '',
  projectId: '',
  projectName: '',
  featureId: {},
  question: [],
  selected: '',
  active: true,
  objectives: [],
  toast: {
    status: false,
    message: '',
  },
  loading: false,
  error: {
    title: false, //[{'mul':false},{'check':false},{'likert':false},{'text':false}],
    choices: false,
    options: false,
    ratings: false,
    dublicateField: false,
    noData: [],
  },
  updateMessage: false,
  surveyQuestionSaveMessage: false,
  disStatus: '',
  isReadOnly: false,
};

const modify = (state = init, action) => {
  switch (action.type) {
    case Actions.CHANGE_NAME:
      return {
        ...state,
        projectName: action.payload,
      };

    // case Actions.CHANGE_FEATURE:
    //   return {
    //     ...state,
    //     featureId: { id: action.payload._id || action.payload.id, name: action.payload.name }
    //   }

    case Actions.CHANGE_ACTIVE:
      return {
        ...state,
        active: action.payload,
      };

    case Actions.FETCH_QUESTION:
      if (action.payload.length > 0) {
        return {
          ...state,
          userId: action.payload[0].user_id,
          projectId: action.payload[0].project_id,
          projectName: action.payload[0].project_name,
          selected: '',
          question: action.payload[0].question.map((item) => {
            const { options, choices, ...rest } = item;

            if (options) {
              const modifiedOptions = options.map((option) => {
                return {
                  id: crypto.randomUUID(),
                  value: option,
                };
              });

              return {
                ...rest,
                options: modifiedOptions,
              };
            }

            if (choices) {
              const modifiedChoices = choices.map((choice) => {
                return {
                  id: crypto.randomUUID(),
                  value: choice,
                };
              });

              return {
                ...rest,
                choices: modifiedChoices,
              };
            }

            return item;
          }),
        };
      } else {
        return {
          ...state,
        };
      }

    case Actions.UPDATE_MESSAGE:
      return {
        ...state,
        updateMessage: action.payload,
      };

    case Actions.FETCH_OBJECTIVE:
      // const objectives = action.objective.map(obj => {
      //   return {
      //     id: obj.id,
      //     objective: obj.name
      //   }
      // })

      return {
        ...state,
        objectives: action.objective,
      };

    case Actions.ADD_QUESTION:
      const uniqueId = uniqueID();

      return {
        ...state,
        question: [
          ...state.question,
          { ...questionSelect(action.questionType), id: uniqueId },
        ],
        error: {
          ...state.error,
          dublicateField: false,
        },
      };

    case Actions.ADD_QUESTION_ANY_POSITION: {
      let questionList = [...state.question];
      if (action.index !== null) {
        questionList.splice(action.index, 0, {
          ...questionSelect(action.questionType),
          id: uniqueID(),
        });
        return {
          ...state,
          question: questionList,
          error: {
            ...state.error,
            dublicateField: false,
          },
        };
      }
    }

    // case Actions.ADD_QUESTION:
    //   return {
    //     ...state,
    //     question: [
    //       ...state.question,
    //       { ...questionSelect(action.questionType), id: uniqueID() }
    //     ],

    //       error: {
    //         ...state.error,
    //         dublicateField: false

    //       }
    //     }

    case Actions.DELETE_QUESTION:
      return {
        ...state,
        question: state.question.filter((item) => item.id !== action.id),
      };

    case Actions.CHANGE_TYPE:
      return {
        ...state,
        question: changeType(action.id, action.value, state.question),
        error: {
          ...state.error,
          dublicateField: false,
        },
      };

    case Actions.DIS_STATUS:
      return {
        ...state,
        disStatus: action.status,
      };

    case Actions.GET_QUESTION_ID:
      return {
        ...state,
        selected: action.id,
      };

    case Actions.EDIT_FIELD:
      return {
        ...state,
        question: editField(
          action.qId,
          action.fId,
          action.value,
          state.question,
          state.error
        ),
      };

    case Actions.EDIT_CHECKBOX:
      return {
        ...state,
        question: editCheckbox(
          action.qId,
          action.value,
          state.question,
          state.error
        ),
      };

    case Actions.EDIT_TITLE:
      action.value !== ''
        ? (state.error.title = false)
        : (state.error.title = true);
      return {
        ...state,
        question: editTitle(action.id, action.value, state.question),
      };

    case Actions.ADD_FIELD:
      state.error.noData = state.error.noData.filter((id) => id !== action.id); //revome current item with no data(noData->means no option or choices added)
      state.question.map((item) => {
        if (item.id === action.id) {
          if (item.choices) {
            if (item.choices.some((ele) => ele === '')) {
              state.error.choices = true;
              state.error.dublicateField = false;
            } else if (new Set(item.choices).size !== item.choices.length) {
              state.error.dublicateField = true;
              state.error.choices = true;
              item.choices.pop();
            } else {
              state.error.choices = false;
              state.error.dublicateField = false;
            }
            // item.choices.some(ele => ele === "") ? state.error.choices = true : state.error.choices = false
          }
          if (item.options) {
            if (item.options.some((ele) => ele === '')) {
              state.error.options = true;
              state.error.dublicateField = false;
            } else if (new Set(item.options).size !== item.options.length) {
              state.error.dublicateField = true;
              state.error.options = true;
              item.options.pop();
            } else {
              state.error.options = false;
              state.error.dublicateField = false;
            }
          }
        }
      });
      let item = addField(action.id, state.question, state.error);
      return {
        ...state,
        question: item.qitem,
      };

    case Actions.DELETE_FIELD:
      return {
        ...state,
        question: deleteField(action.qId, action.fId, state.question),
      };

    case Actions.SAVE_QUESTION:
      const { user_id, profileId, name } = action.payload;
      // console.log(action.payload)
      return {
        ...state,
        userId: user_id,
        projectId: profileId,
        projectName: name,
        selected: '',
        question: action.payload.question,
        saveDisabled: true,
      };

    case Actions.ADD_QUESTION_TO_SURVEY:
      const questions = action.payload.map((item) => {
        const { options, choices, ...rest } = item;

        if (options) {
          const modifiedOptions = options.map((option) => {
            return {
              id: crypto.randomUUID(),
              value: option,
            };
          });

          return {
            ...rest,
            options: modifiedOptions,
          };
        }

        if (choices) {
          const modifiedChoices = choices.map((choice) => {
            return {
              id: crypto.randomUUID(),
              value: choice,
            };
          });

          return {
            ...rest,
            choices: modifiedChoices,
          };
        }

        return item;
      });

      return {
        ...state,
        // question: state.question.concat(action.payload),
        question: state.question.concat(questions),
      };

    // case Actions.INITIALIZE:
    //   const {id, objectives} = action.payload
    //   return {
    //     ...init,
    //     projectId: id,
    //     objectives: [...objectives]
    //   }

    case Actions.ADD_OBJECTIVE:
      const { qId, obj } = action.payload;
      // console.log(obj)
      return {
        ...state,
        question: state.question.map((ques) => {
          if (ques.id !== qId) {
            return ques;
          } else {
            return {
              ...ques,
              objectives: obj,
            };
          }
        }),
      };

    case Actions.SHOW_TOAST:
      const { message } = action.payload;
      return {
        ...state,
        toast: {
          status: true,
          message: `${message}`,
        },
      };

    case Actions.HIDE_TOAST:
      return {
        ...state,
        toast: {
          status: false,
          message: '',
        },
        saveDisabled: false,
      };

    case Actions.LOADING:
      return {
        ...state,
        loading: action.payload.status,
      };
    case Actions.ERROR:
      if (action.payload.name === 'title')
        state.error.title = action.payload.value;
      else if (action.payload.name === 'choices')
        state.error.choices = action.payload.value;
      else if (action.payload.name === 'options')
        state.error.options = action.payload.value;
      else if (action.payload.name === 'ratings')
        state.error.ratings = action.payload.value;
      else if (action.payload.name === 'label')
        state.error.label = action.payload.value;
      else if (action.payload.name === 'noData')
        state.error.noData = [...action.payload.value];
      return {
        ...state,
      };
    case Actions.LIBRARY_UPDATE: {
      let { id_, library } = action.payload;

      return {
        ...state,
        question: state.question.map((ques) => {
          if (id_ !== ques.id) {
            return {
              ...ques,
            };
          } else {
            return libraryUpdate(ques, library);
          }
        }),
      };
    }

    case Actions.BACK_BUTTON_IN_DETAIL_PAGE: {
      return {
        ...state,
      };
    }

    case Actions.TOGGLE_DEMOGRAPHY_FIELD: {
      const { id_, field_name } = action.payload;

      return {
        ...state,
        question: state.question.map((ques) => {
          if (id_ !== ques.id) {
            return {
              ...ques,
            };
          } else {
            // return libraryUpdate(ques, library)
            const { fields } = ques;
            fields[field_name] = !fields[field_name];
            return {
              ...ques,
              fields,
            };
          }
        }),
      };
    }

    case Actions.SURVEY_QUESTION_SAVE_MESSAGE_TRUE: {
      return {
        ...state,
        surveyQuestionSaveMessage: action.payload,
      };
    }

    case Actions.SURVEY_QUESTION_SAVE_MESSAGE_FALSE: {
      return {
        ...state,
        surveyQuestionSaveMessage: action.payload,
      };
    }

    case Actions.SET_IS_READ_ONLY:
      return {
        ...state,
        isReadOnly: action.isReadOnly,
      };

    default:
      return state;
  }
};

export default modify;
