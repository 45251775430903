import React from 'react'
import Select from 'react-select'
import {connect} from 'react-redux'

import './index.css'
import likertScale from '../../../questionLibraries/likertScale'
import {libraryUpdate} from '../../../redux/actions/modifyQuestion'

const LikertScale = ({id_, libraryUpdate})=>{
  return(
    <Select
      className='react-select-container'
      classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: style=>{
          return{
            ...style,
            minHeight: "1px !important",
            height: "25px",
            width: "100%",
          }
        },
        control: (base) => {
          return {
            ...base,
            boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover':{
              ...base[':hover'],
              boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#1B998B !important"
            },
            ':active':{
              ...base[':active'],
              borderColor: "#1B998B !important"
            }
          };
        },
        singleValue: style=>{
          return{
            ...style,
            padding: "0 5px"
          }
        }
      }}
      isSearchable={false}
      options={likertScale}
      onChange={selected=>{
        libraryUpdate(id_, selected.value)
      }} 
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const {id_} = ownProps
  return {
    id_,
  }
}

const mapDispatchToProps = {
  libraryUpdate: (id_, library)=>libraryUpdate(id_, library)
}

export default connect(mapStateToProps, mapDispatchToProps)(LikertScale)