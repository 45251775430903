import styled from 'styled-components'

export const Body = styled.aside`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 500;
  background-color: rgba(0,0,0,0.5);
`

export const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  height: ${props=>props.height? props.height : '90%'};
  width: ${props=>props.width? props.width : '90%'};
  background: white;
  z-index: 501;
`