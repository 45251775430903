import React from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import { Header, ImageBody, HeadlineBody, LogoImage } from './styles'

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const HeaderComponent = props => {

  if (props.text === "" && props.image === "") {
    return null
  }

  return (
    <Header>
      {
        props.image !== "" && <ImageBody>
          <LogoImage src={props.image} />
        </ImageBody>
      }
      <HeadlineBody>
        {/* BASECAMP ADVENTURE WEEK */}
        {/* { props.header } */}
        <div dangerouslySetInnerHTML={{ __html: props.text }} />
      </HeadlineBody>
    </Header>
  )
}

HeaderComponent.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    // header: state.form.data.name
    text: state.form.data.header,
    image: state.form.data.logo,
  }
}

export default connect(mapStateToProps)(HeaderComponent)