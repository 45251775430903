import React, {useState, useEffect} from 'react'
import Nav from '../LandingPage/components/nav/nav'
import Footer from '../LandingPage/components/footer/Footer'
import Blogs from './components/blogs'
import axios from 'axios'
import baseUrl from '../../url/index' 
import showToast from '../../components/Toast/toast'

function LandingBlog() { 
  const [allPost, setAllPost] = useState([]) 

  const ErrorToast = () => showToast('Server Error') 
  useEffect(() => { 
    axios.get(`${baseUrl}/api/blog/posts/?active=true`) 
      .then(res => setAllPost(res.data.postList.reverse()) ) 
      .catch(err => ErrorToast()) 
  }, []) 
  return ( 
    <div style={{overflowY: 'auto'}}> 
      <Nav /> 
      <Blogs posts={allPost} /> 
      <Footer /> 
    </div> 
  ) 
} 

export default LandingBlog; 
