import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap' 
import SignUp from '../../../SignUp/signup' 
import { css } from 'glamor'

function SignupModal(props) { 
  let { header, closeModal, showFreeModal } = props; 

  const headerCss = css({position: 'relative', color: '#1B998B', paddingBottom: '0.5em !important', paddingTop: '0.5em !important'}) 

  const closeCss = css({ 
    cursor: 'pointer', position: 'absolute', color: '#1B998B', fontSize: '20px', fontWeight: '400', 
    lineHeight: '18px', textAlign: 'center', top: '-10px', right: '-10px', width: '20px', height: '20px', 
    backgroundColor: 'white', borderRadius: '50%', boxShadow: '1px 1px 5px -1px #586469', 
    ':hover': { boxShadow: '1px 1px 5px 2px #586469' } 
  }) 

  const modalBodyCss = css({
    top: '60px !important', 
    '@media(max-width: 600px)': { left: '15px !important' }, 
    '@media(max-width: 400px)': { left: '0px !important' } 
  }) 


  return ( 
    <Modal isOpen={showFreeModal} className={props.className} {...modalBodyCss} id="demo"> 
      <ModalHeader {...headerCss}> 
        { header } 
        <span {...closeCss} onClick={() => closeModal()}> x </span> 
      </ModalHeader> 
      
      <ModalBody> 
        <SignUp closeModal={closeModal} /> 
      </ModalBody> 
    </Modal> 
  ) 
} 

export default SignupModal; 
