import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactDatePicker.css";

const ReactDatePicker = (props) => {
    const [startDate, setStartDate] = useState(new Date());

    const handleChange = (date) => {
        setStartDate(date);
    }

    props.getFilterParam({
        startDate: startDate,
        endDate: null,
        type: props.type
    });

    return (
        <div className="mx-2 ReactDatePicker">
            {/* <h6 className="text-left" style={{ fontSize: "12px", fontWeight: "700", color: "#424242" }}>Date</h6> */}
            <DatePicker
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                onChange={handleChange}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                withPortal
            />
        </div>
    );
}

export default ReactDatePicker;