//Dependencies
import React from 'react'
import {connect} from 'react-redux'
import {withRouter} from 'react-router-dom';
import axios from 'axios';

//Custom Components and resources


class GenerateLink extends React.Component {

    state={

        to: "",
        subject: "",
        text: "",
        link : "",
        sent : ""
    }

    onChangeHandler = (event) =>{
        event.preventDefault();

        this.setState({
            [ event.target.name ]:event.target.value
        })

    }

    generateLinkHandler = () =>{

        this.setState({
            link : `http://localhost:3001/api/surveys/user/${this.props.questionList.userId}`
        })
    }

    sendMail = () => {

        const {to,subject,text} = this.state ;

        var data = {
            to,
            subject,
            text
        }

        

        axios.post('http://localhost:3002/mailer',data)
            .then((result) => {

                this.setState({
                    to:'',
                    subject:'',
                    text:'',
                    sent:'Message Sents'
                })
                
            }).catch((err) => {
                // console.log(err);
            });
    }


  render(){
    return(
        <div>
            <button onClick={this.generateLinkHandler}> generate link </button>
            <p>{this.state.link}</p>

            To:
            <input type="text" value={this.state.to} name="to" onChange={this.onChangeHandler} /> <br/>
            Subject:
            <input type="text" value={this.state.subject} name="subject" onChange={this.onChangeHandler}/> <br/>
            Text:
            <textarea value={this.state.text}  name="text" onChange={this.onChangeHandler} /> <br/>
            <button onClick={this.sendMail}>Send</button>
            <p>{this.state.sent}</p>

        </div>
        )
  }
}

const mapStatetoProps = state => ({
    questionList: state
  })


export default withRouter( connect(mapStatetoProps)(GenerateLink))