import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faCheckCircle, faTimesCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

import {Card, CloseButton, Icon, Text} from './styles'

const Alert = (props)=>{
  const {children, close, icon} = props   //children takes all the inner html value.
  let iconComponent
  switch(icon){
    case "success":
      iconComponent = faCheckCircle
      break
    case "failed":
      iconComponent = faTimesCircle
      break
    case "warn":
      iconComponent = faExclamationCircle
      break
    default :
      iconComponent = faCheckCircle
  }
  return(
    <React.Fragment>
      <Card>
        <Icon>
          <FontAwesomeIcon icon={iconComponent}/>
        </Icon>
        <Text>
          {children}
        </Text>
        <CloseButton onClick={close}>
          Ok
        </CloseButton>
      </Card>
    </React.Fragment>
  )
}


export default Alert