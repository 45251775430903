import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Card } from 'reactstrap'
import Table from './Table'
import { getALLBrandCategories, getParentsOfBrandCategories } from '../../store/actions/actionCreator'


function Index() {
    let dispatch = useDispatch()
    let { allData, parents } = useSelector(state => state.brandCategory) 
    try { 
        if(!Object.keys(allData).length) { allData.data = [] } 
        if(Object.keys(allData).length) { 
            allData.data.sort(function(a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            }); 
        } 
        if(!Object.keys(parents).length) { 
            parents.data = [] 
        } 
    } catch (error) { } 

    for(let i = 0; i < allData.data.length; i++) { 
        for(let j = 0; j < parents.data.length; j++) {
            if(allData.data[i].parentId === parents.data[j]._id) {
                allData.data[i].parentCategory = parents.data[j].name
            } 
        } 
    } 

    useEffect(() => { 
        const abortController = new AbortController(); 
        dispatch(getALLBrandCategories()); 
        dispatch(getParentsOfBrandCategories()); 

        return () => { abortController.abort() }; 
    }, []) 


    const columns = React.useMemo(() => 
        [ 
            { Header: 'Name', accessor: 'name' }, 
            { Header: 'Active', accessor: 'active' }, 
            { Header: 'Parent Category', accessor: 'parentCategory' } 
        ], [] 
    ) 


    return ( 
        <div className="animated fadeIn"> 
            <Card style={{ border: '0px', background: allData.data.length ? 'transparent' : null }}> 
                <Table columns={columns} data={allData.data} /> 
            </Card> 
        </div> 
    ) 
} 

export default Index; 
