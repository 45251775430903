import React from 'react';
import { connect } from 'react-redux';
import { SplitButton } from 'primereact/splitbutton';

import { addQuestionAnyPosition } from '../../redux/actions/modifyQuestion';
import './index.css';

const AddButton = ({ addQuestion, index }) => {
  const model = [
    {
      label: 'Multiple Choice',
      command: () => {
        addQuestion('multipleChoice', index);
      },
    },
    {
      label: 'Drop Down',
      command: () => {
        addQuestion('dropDown', index);
      },
    },
    {
      label: 'Dichotomy',
      command: () => {
        addQuestion('dichotomy', index);
      },
    },
    {
      label: 'ThumbsUp/ThumbsDown',
      command: () => {
        addQuestion('thumbsUpDown', index);
      },
    },
    {
      label: 'Checkbox',
      command: () => {
        addQuestion('checkbox', index);
      },
    },
    {
      label: 'Likert Scale',
      command: () => {
        addQuestion('likertScale', index);
      },
    },
    {
      label: 'Rating Scale',
      command: () => {
        addQuestion('ratingScale', index);
      },
    },
    {
      label: 'Star Rating',
      command: () => {
        addQuestion('starRating', index);
      },
    },
    {
      label: 'Thumbs Rating',
      command: () => {
        addQuestion('thumbsUpRating', index);
      },
    },
    {
      label: 'Love Rating',
      command: () => {
        addQuestion('loveRating', index);
      },
    },
    {
      label: 'NPS',
      command: () => {
        addQuestion('nps', index);
      },
    },
    {
      label: 'NPS +',
      command: () => {
        addQuestion('nps', index);
        addQuestion('textBox', index + 1);
      },
    },
    {
      label: 'Text Box',
      command: () => {
        addQuestion('textBox', index);
      },
    },
    // {
    //   label: 'Numeric Box',
    //   command: () => {
    //     addQuestion('numeric');
    //   },
    // },
    {
      label: 'Slider',
      command: () => {
        addQuestion('slider');
      },
    },
    {
      label: 'Date',
      command: () => {
        addQuestion('date', index);
      },
    },
    {
      label: 'Date Range',
      command: () => {
        addQuestion('daterange', index);
      },
    },
    // ,
    // {
    //   label: "Demography",
    //   command: () => { addQuestion("demography") }
    // }
  ];

  return (
    <SplitButton
      id='add'
      label='Add Question'
      onClick={() => {
        addQuestion('multipleChoice', index);
      }}
      model={model}
      style={{ margin: '0px auto 0px auto' }}
    />
  );
};

const mapDispatchToProps = {
  addQuestion: (questionType, index) =>
    addQuestionAnyPosition(questionType, index),
};

export default connect(null, mapDispatchToProps)(AddButton);
