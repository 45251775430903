import React, {useEffect, Fragment} from 'react' 
import { withRouter } from 'react-router-dom' 
import { useSelector, useDispatch } from 'react-redux'; 
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Input } from 'reactstrap'; 
import { Formik } from 'formik' 
import * as Yup from 'yup' 
import showToast from '../../../../../components/Toast/toast' 
import BreadCrumb from '../../../../../components/Breadcrumb/breadcrumb' 
import { create_, getAll_, backButtonFunction } from '../../store/actions/actionCreator' 
import '../buttonCss.css' 

const CreateDesignation = (props) => { 
    const { history } = props; 
    const dispatch = useDispatch(); 
    let { allData } = useSelector(state => state.designation) 

    try { if(!Object.keys(allData).length) { allData.designation = [] } } 
    catch (error) { } 
    

    const CreateToast = () => showToast('Created Successfully') 
    const ErrorToast = () => showToast('Server Error') 

    useEffect(() => { dispatch(getAll_(ErrorToast)) }, []) 


    return ( 
        <Fragment> 
            <div className="container"> 
            <BreadCrumb path="Designation" slug="Create" link="/app/designation/" backButtonFunction={backButtonFunction} /> 

            <Card className="my-3 "> 
                <CardBody> 
                <Formik 
                    initialValues={{ name:'', status: true, description: '' }} 
                    validationSchema={ 
                        Yup.object().shape({ 
                            name: Yup 
                                .string().required("Required").trim().lowercase() 
                                .notOneOf(allData ? allData.designation.map(obj => obj.name.toLowerCase()) : [], "Already Exist"), 
                        }) 
                    } 
                    onSubmit={(values, { resetForm }) => { 
                        let createdData = { 
                            name: values.name, 
                            active: values.status, 
                            description: values.description, 
                            createdAt: Date.now() 
                        } 
                        
                        dispatch(create_(createdData, CreateToast, ErrorToast)); 
                        resetForm(); 
                        history.push('/app/designation/'); 
                    }}> 
                    { 
                    props => ( 
                        <Form onSubmit={props.handleSubmit}> 
                            <FormGroup row> 
                                <Label htmlFor="name" sm={2}> 
                                    Name: 
                                    <span style={{color: 'red'}}>{` *`}</span> 
                                </Label> 
                                <Col sm={4}> 
                                    <Input type="text" name="name" id="name" 
                                        style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}} 
                                        value={props.values.name} onChange={props.handleChange} 
                                        onBlur={props.handleBlur} placeholder="Enter designation"
                                    /> 
                                    { 
                                        props.errors.name && props.touched.name && 
                                        <p style={{ color: 'red', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}> 
                                            {props.errors.name} 
                                        </p> 
                                    } 
                                </Col> 

                                <Label htmlFor="status" className="mt-1">Active:</Label> 
                                <Col > 
                                    <Input 
                                        type="checkbox" id="status" name="status" 
                                        className="form-check-input mt-2" checked={props.values.status} 
                                        onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            <FormGroup row> 
                                <Label htmlFor="description" sm={2}> Description: </Label> 
                                <Col> 
                                    <Input type="textarea" name="description" id="description" 
                                        value={props.values.description} onChange={props.handleChange} onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            
                            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B' }} size="sm" type="submit" > 
                                Save 
                            </Button> 
                            
                            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px' }} size="sm" 
                                onClick={() => { history.push('/app/designation') }}> 
                                Cancel 
                            </Button> 
                        </Form> 
                    ) 
                    } 
                </Formik> 
            </CardBody> 
            </Card> 
            </div> 
        </Fragment> 
    ) 
} 

export default withRouter(CreateDesignation); 
