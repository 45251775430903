import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { AddNewTargetAudience,FetchAllTargetAudience } from '../../../url/prmUrl';
import Alert from '../ShowAlert'

import { Button, Input, Label, Body, Error, Aside, TextArea, Modal } from '../StyledComponents'

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name for Targeted Audience"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Active:
                </div>
                <div css={'width:15px'}>
                  <Input
                    name="status"
                    type="checkbox"
                    css={`width:18px;height:18px`}
                    checked={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Description:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="description"
                    placeholder="Type here..."
                    value={values.description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


const TargetAudience = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(FetchAllTargetAudience);
      try {
        setEditable({
        ...editable,
        loaded: result.data,
      })
    }
      catch (err) {
        // console.log("err")
      }
    };
    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  const targetAudience = editable.loaded ?
                    editable.loaded.Error ? [] :
                    editable.loaded.map(item=>item.name) : [] 

  const schema = Yup.object().shape({
    name: Yup.string().required("Must Define a Name")
                             .min(3, "Minimum 3 characters")
                             .notOneOf(targetAudience, "Already exists")

  })

  const CreateTargetAudience= withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      name:"" , 
      description: '',
      status: true
    }),

    handleSubmit: (values, { setSubmitting }) => {
      axios.post(AddNewTargetAudience, values)
        .then((response) => {
          if(!response.data.Error){
            setView(true)
          }
          else{
            window.alert("Uexpected Error! Duplicate Name found!")
          }
        })
        .catch((err) => {
          // console.log("Error")
        })
    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <CreateTargetAudience/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(TargetAudience)