import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Card, CardBody, CardHeader, Col, Button, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label 
} from 'reactstrap';

import { 
    getBrandDetail, companyFieldData, categoryFieldDataTrueOnly, 
    backButtonFunction, updateBrandById, deleteBrandById
} from '../../store/actions/actionCreator' 

import { deleteBrand } from '../../../../../url/prmUrl'; 
import Axios from 'axios'
import showToast from '../../../../../components/Toast/toast'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb';
import '../buttonCss.css'


function Details(props) { 
    let { id } = props.match.params; 
    let {history} = props; 
    
    let dispatch = useDispatch() 
    const companyProfileId = localStorage.getItem("jwtProfileId") 
    let {allData, dataDetails: state, companys, categoryTrueOnly: category} = useSelector(state => state.brand) 
    
    let categoryName;
    try { 
        if(!Object.keys(allData).length) { allData.data = [] } 
        if(!Object.keys(companys).length) { companys = [] } 
        if(!Object.keys(category).length) { category.data = [] } 
        if(Object.keys(category).length) { 
            for(let i = 0; i < category.data.length; i++) { 
                if(state.categoryId === category.data[i]._id) { 
                    categoryName = category.data[i].name; 
                } 
            } 

            // Sorting Alphabetically 
            let compare = (a, b) => { 
                const nameA = a.name.toUpperCase(); 
                const nameB = b.name.toUpperCase(); 

                let comparison = 0; 
                if (nameA > nameB) { comparison = 1; } 
                else if (nameA < nameB) { comparison = -1; } 
                return comparison; 
            } 
            category.data.sort(compare); 
        } 
    } catch (error) { } 
    

    const { className } = props;
    let [isEditable, setIsEditable] = useState(false)
    let [showModal, setShowModal] = useState(false) 
    let [canNotDeleteMsg, setCanNotDeleteMsg] = useState(false) 



    const DeleteToast = () => showToast('Deleted Successfully') 
    const UpdateToast = () => showToast('Updated Successfully') 
    const ErrorToast = () => showToast('Server Error') 
    const DependencyToast = () => showToast('Cannot Delete. There are dependency') 


    useEffect(() => { 
        dispatch(getBrandDetail(id, ErrorToast)) 
        dispatch(companyFieldData(companyProfileId)) 
        dispatch(categoryFieldDataTrueOnly()) 
    }, []) 


    const NowDelete = async () => { 
        try { 
            Axios.delete(deleteBrand(id)) 
                .then(res => { 
                    if(res.data.isDeletable) { 
                        DeleteToast(); 
                        dispatch(deleteBrandById(state)); 
                        props.history.push('/app/brand/'); 
                    } 
                    else { 
                        DependencyToast(); 
                        setCanNotDeleteMsg(true); 
                        props.history.push('/app/brand/'); 
                    } 
                }) 
                .catch(err => { ErrorToast(); }) 
        } 
        catch (error) {} 
    } 

    

    let emptyName = ''
    return ( 
        <> 
            {/* Delete Modals */} 
            { 
                showModal && 
                <Modal isOpen={showModal} className={className}> 
                    <ModalHeader>Delete Brand</ModalHeader> 
                    <ModalBody> Are you sure, you want to delete? </ModalBody> 
                    <ModalFooter> 
                        <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                            onClick={NowDelete}> 
                            Delete 
                        </Button> 
                        
                        <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                            onClick={() => setShowModal(false)}> 
                            Cancel 
                        </Button> 
                    </ModalFooter> 
                </Modal> 
            } 




            {/* Main Body */}
            <div className="container"> 
                <Breadcrumb path="Brand" slug={state.name} link="/app/brand" backButtonFunction={backButtonFunction}/> 
                {/* Button --> Back, Edit/save, Delete */} 
                <Button
                    size="sm" 
                    className="btnHover"
                    style={{ margin: '10px 0px 10px 0px', background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    onClick={() => {
                        dispatch(backButtonFunction()); 
                        props.history.push('/app/brand/'); 
                    }}>
                    Back
                </Button>
                {
                    !isEditable &&
                    <Button
                        size="sm" className="ml-2 btnHover"
                        style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                        onClick={() => setIsEditable(true)}>
                        Edit
                    </Button>
                }
                <Button
                    size="sm" className="ml-2 btnHover"
                    style={{
                        background: '#1B998B',
                        borderColor: '#1B998B',
                        color: '#fff'
                    }}
                    onClick={ () => setShowModal(true) }>
                    Delete
                </Button> 
            
            
            
                {/* Data on card view */}
                <Card>
                    <CardBody>
                        { 
                        state._id && 
                            <Formik
                                initialValues={{ name: state.name, status: state.active, company: companys.name, category: state.categoryId }} 
                                validationSchema={ 
                                    Yup.object().shape({ 
                                        name: Yup 
                                            .string().required("Required").trim().lowercase()
                                            .notOneOf(Object.keys(allData).length ? allData.data.map(item => {
                                                if(item.name.toLowerCase() !== state.name.toLowerCase()) {
                                                    return item.name.toLowerCase() 
                                                } 
                                            }) : [], "Already exists"), 
                                    }) 
                                } 
                                onSubmit={(values, { resetForm }) => { 
                                    const createdData = { 
                                        companyProfileId: companyProfileId, 
                                        name: values.name.trim(), 
                                        active: values.status, 
                                        categoryId: values.category 
                                    } 
                                    dispatch(updateBrandById(id, createdData, UpdateToast, ErrorToast)); 
                                    resetForm(); 
                                    history.push('/app/brand/'); 
                                }}> 
                                {
                                    props => (
                                        <Form onSubmit={props.handleSubmit}>
                                        <FormGroup row> 
                                            {/* Brand Name */} 
                                            <Label htmlFor="brand" sm={2}> 
                                                Name: <span style={{color: 'red'}}>{isEditable && ` *`}</span> 
                                            </Label> 
                                            <Col sm={4}> 
                                            { 
                                                isEditable ?
                                                <>
                                                <Input type="text" name="name" id="brand" 
                                                    value={props.values.name} 
                                                    style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                                    onChange={(e) => { 
                                                        if(e.target.value === '') { emptyName = 'empty' } 
                                                        if(e.target.value) { emptyName = '' } 
                                                        props.handleChange(e) 
                                                    }} 
                                                    onBlur={props.handleBlur} 
                                                    placeholder="Enter brand name"
                                                /> 
                                                <span style={{display: 'none'}}> 
                                                    {emptyName === 'empty' ? props.errors.name = 'Required' : null}
                                                </span> 
                                                { 
                                                    props.errors.name && props.touched.name && 
                                                    <p style={{ 
                                                        color: 'red', fontSize: '12px', 
                                                        marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                                    }}> 
                                                        {props.errors.name} 
                                                    </p> 
                                                } 
                                                </> 
                                                : 
                                                <span>{state.name}</span>
                                            }
                                            </Col> 



                                            {/* Company */} 
                                            <Label htmlFor="company" sm={2} style={{paddingLeft: '0px'}}> Company: </Label> 
                                            <Col sm={4}> 
                                            { 
                                                isEditable ?
                                                <>
                                                <Input 
                                                    type="select" name="company" id="company" 
                                                    value={props.values.company} 
                                                    onChange={props.handleChange} 
                                                    onBlur={props.handleBlur} > 
                                                    {/* <option value="" disabled defaultValue hidden>Select...</option>  */} 
                                                    <option value={companys._id}> 
                                                        {companys.name ? companys.name : companys.user_Name} 
                                                    </option> 
                                                </Input> 
                                                { 
                                                    props.errors.company && props.touched.company && 
                                                    <p style={{ 
                                                        color: 'red', fontSize: '12px', 
                                                        marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                                    }}> 
                                                        {props.errors.company} 
                                                    </p> 
                                                } 
                                                </> 
                                                : 
                                                <span>{companys.name}</span> 
                                            }
                                            </Col> 
                                        </FormGroup> 

                                        {/* 2nd line */}
                                        <FormGroup row> 
                                            <Label htmlFor="category" sm={2}> Category: </Label> 
                                            <Col sm={4}> 
                                            { 
                                                isEditable ?
                                                <> 
                                                <Input 
                                                    type="select" name="category" id="category" 
                                                    value={props.values.category} 
                                                    onChange={props.handleChange} 
                                                    onBlur={props.handleBlur} > 
                                                    { 
                                                        category.data.map((obj) => { 
                                                            return ( <option value={obj._id} key={obj._id}> {obj.name} </option> ) 
                                                        }) 
                                                    } 
                                                </Input> 
                                                { 
                                                    props.errors.category && props.touched.category && 
                                                    <p style={{ 
                                                        color: 'red', fontSize: '12px', 
                                                        marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                                    }}> 
                                                        {props.errors.category} 
                                                    </p> 
                                                } 
                                                </> 
                                                : 
                                                <span>{categoryName}</span> 
                                            } 
                                            </Col> 
                                            
                                            { 
                                                isEditable ? 
                                                <Label htmlFor="status"> Status </Label> 
                                                : 
                                                <Label> Status </Label> 
                                            } 
                                            <Col sm={4}> 
                                            { 
                                                isEditable ? 
                                                <Input 
                                                    type="checkbox" name="status" id="status" 
                                                    className="form-check-input" 
                                                    checked={props.values.status} 
                                                    onChange={props.handleChange} 
                                                    onBlur={props.handleBlur} 
                                                /> 
                                                : 
                                                <Input 
                                                    type="checkbox" name="status" id="status" 
                                                    className="form-check-input" 
                                                    readOnly={true} 
                                                    checked={state.active} 
                                                /> 
                                            } 
                                            </Col> 
                                        </FormGroup> 

                                        { 
                                            isEditable && 
                                            <Button className="btnHover" type="submit" size="sm" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}>
                                                Save 
                                            </Button> 
                                        } 
                                        </Form> 
                                    ) 
                                } 
                            </Formik> 
                        }
                    </CardBody> 
                </Card> 
            </div> 
        </> 
    ) 
} 

export default withRouter(Details) 




