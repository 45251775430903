//Dependencies
import React from 'react'
import styled from 'styled-components'

//Custom Components and resources
import logo from '../../resources/PEX_Logo.png'

const Header = styled.header`
  width: 100%;
  height: 40px;
  background-color: #c1dee7;
  margin-bottom: 20px;
`

const Logo = styled.img.attrs(()=> ({
  src: logo
}))`
  height: 30px;
  margin: 5px 80px;
  padding: 0;
`

const MainNav = () => {
  return(
    <Header>
      <Logo></Logo>   
    </Header>
  )
}

export default MainNav