import React, { Component } from 'react';

class TabBar extends Component {

    classes = "bd-highlight";
    tagName = 'feature';

    render() {
        return (
            <div className="d-flex flex-row flex-wrap bd-highlight" data-section-name={this.props.sectionName} id={this.props.sectionName}>
                {this.props.links.map(link => {

                    let active = '';

                    if (link.active) {
                        active = ' active'
                    }

                    if (this.props.sectionName != 'feature') {
                        this.tagName = link.tag;
                    }

                    return (
                        <div className={`${this.classes} ${active}`} data-id={link.id} data-tag={this.tagName} key={link.id} onClick={this.props.handleClick} >
                            {link.name}
                        </div>
                    );

                })}
            </div>
        );
    }
}

export default TabBar;