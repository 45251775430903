import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  box-shadow: 1px 1px 5px 1px grey;
`

export const Title = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  color: white;
`

export const Header = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0;
  background-color: #1B998B;
`

export const RemoveButton = styled.input`
  display: block;
  position: absolute;
  padding: 5px 10px;
  margin: 0 10px;
  top: 50%;
  transform: translate(0, -50%);
  right: 0;
  outline: none;
  border: none;
  cursor: pointer;
  background: rgb(52, 58, 64);
  color: #fff;
  &:hover { background: #ff1744 }
`