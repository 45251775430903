import React from 'react';
import { ProgressContainer } from './style'

class ProgressBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            score: 0
        };
    }

    render() {
        const style = {
            width: this.props.current / this.props.total * 100 + '%'
        };

        return (
            <ProgressContainer>
                {this.props.current + ' out of ' + this.props.total}
                <div style={{ width: '100%', height: '5px', backgroundColor: '#ddd', borderRadius: '2px', position: 'relative' }}>
                    <div style={{ width: style.width, height: '5px', transition: 'width 0.3s', backgroundColor: 'rgb(27, 153, 139)', borderRadius: '2px', position: 'absolute', top: '0', left: '0' }} />
                </div>
            </ProgressContainer>
        );
    }


}

export default ProgressBar
