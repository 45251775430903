import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DateRange.css";

const DateRange = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(new Date());

    const handleStartDate = date => {
        setStartDate(date);
        if (!date) {
            setEndDate(new Date());
        }
    }

    const handleEndDate = date => {
        setEndDate(date);
    }

    const handleKeyDown = (e) => {
        e.target.value = "--";
    }

    props.getFilterParam({
        startDate: startDate,
        endDate: endDate,
        type: props.type
    });

    return (
        <div className="DateRange d-flex justify-content-end flex-row">
            <div className="flex-fill mx-2 start-date">
                {/* <h6 className="text-left">From</h6> */}
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={startDate}
                    isClearable
                    onChange={handleStartDate}
                    onKeyDown={handleKeyDown}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    placeholderText="--"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    withPortal
                />
            </div>

            <div className="flex-fill mr-2 end-date">
                {/* <h6 className="text-left">To</h6> */}
                <DatePicker
                    dateFormat="yyyy-MM-dd"
                    selected={endDate}
                    onChange={handleEndDate}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    withPortal
                />
            </div>
        </div>
    );
}

export default DateRange;