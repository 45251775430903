import React from 'react'
import NavBar from '../LandingPage/components/nav/nav'
import MainContent from './mainContent' 
import Clients from '../LandingPage/components/clients/clients'
// import Footer from './footer' 
import Footer from '../LandingPage/components/footer/Footer'

function AboutUs() { 
  const styles = {
    aboutHeader: { 
      color: 'rgb(52, 58, 64)', fontSize: '22px', fontFamily: 'Roboto', 
      textAlign: 'center', marginTop: '30px'
    }, 
    aboutText: {
      color: 'rgb(52, 58, 64)', fontSize: '15px', fontFamily: 'Roboto', textAlign: 'justify' 
    }, 
    titleStyle: { 
      color: 'black', fontWeight: '500', textAlign: 'center', fontSize: '22px', 
      marginTop: '25px', marginBottom: '15px', fontFamily: 'Roboto', color: 'rgb(52, 58, 64)'
   } 
  } 

  
  return ( 
    <div style={{overflowY: 'scroll'}}> 
      <NavBar /> 
      <div className="container"> 
        <h2 style={styles.aboutHeader}> 
          About Us 
        </h2> 
        <p style={styles.aboutText}> 
          ProlificXM has very specific analytics solutions for modern Business.We have a youth and very dynamic team who are always involve to develop the interaction between modern Business and Technology. It helps you to get better dicissions. 
        </p> 

        <div style={{width: '100%'}}>
          <center> 
            <div style={{height: '2px', width: '40%', background: 'rgba(52, 58, 64, 0.2)'}}></div> 
          </center> 
        </div> 
         
        <MainContent /> 

        <p style={styles.titleStyle}> Some Happy Clients </p>
        <Clients /> 
      </div> 

      <Footer /> 
    </div> 
  ) 
} 

export default AboutUs; 
