import React from 'react'
import QRCode from 'qrcode.react'
import { pexUrl } from '../../../../../url'

const QrCode = props => {

    const downloadQR = value => {
        const imageFile = `${value}.png`
        const canvas = document.getElementById("qrcode");
        const pngUrl = canvas
            .toDataURL("image/png")
            .replace("image/png", "image/octet-stream");
        let downloadLink = document.createElement("a");
        downloadLink.href = pngUrl;
        downloadLink.download = imageFile;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <div>
            <h4>QR Code</h4>
            <label className="dislabel" style={{ margin: '10px' }}>
                Qr Code
            <input
                    name="active"
                    type="checkbox"
                    checked
                    disabled
                />
            </label>
            <div>
                <QRCode
                    id="qrcode"
                    value={`${pexUrl}/qrcode/${props.qrcode.hashId}`}
                    size={250}
                    level={"H"}
                    includeMargin={true}
                />

            </div>
            <a style={{ color: 'rgba(0,0,0,.8)', margin: '10px' }} onClick={() => downloadQR(props.disId)}> Download QR </a>
        </div>

    )
}

export default QrCode