import React, { useEffect } from 'react'

const DeviceWrapper = props => {
    useEffect(() => {
        props.fetchDeviceLists(localStorage.getItem('jwtProfileId'))
        // console.log('props.profileId', props.profileId)
    }, [])
    return (
        <div>
            <h4>{props.channel.channelType}</h4>
            {props.children}
        </div>
    )
}

export default DeviceWrapper