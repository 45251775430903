import React, { Component } from 'react'
import Nav from './components/nav/nav'
import Slider from './components/slider/slider'
import CJM from './components/CJM/CJM'
import NPSsection from './components/NPSsection/NPSsection'
import CSAT from './components/CSAT/CSAT'
import Effort from './components/effort/effort'
import Employee from './components/employee/Employee'
import Footer from './components/footer/Footer'
import ReactCarousel from './components/multi-carousel/ReactCarousel'
import Features from './components/Features/features'
import Clients from './components/clients/clients'

import {css} from 'glamor'
import './style.css'

export default class LandingPage extends Component { 
   state = { 
      navbarBGcolor: '#1B998B', 
      titleStyle: { 
         fontWeight: '500', textAlign: 'center', fontSize: '30px', 
         marginTop: '25px', marginBottom: '15px', fontFamily: 'Roboto', color: 'rgb(52, 58, 64)'
      } 
   } 

   titleStyle = css({ 
      color: 'rgb(52, 58, 64)', fontWeight: '400', textAlign: 'center', fontSize: '30px', 
      marginTop: '25px', marginBottom: '15px', fontFamily: 'Roboto', 
      '@media(max-width: 600px)': { fontSize: '25px !important' }, 
      '@media(max-width: 400px)': { fontSize: '22px !important' }, 
   }) 
   
   render() { 
      return ( 
         <div style={{overflowY: 'scroll'}}> 
            <Nav bgColor={this.state.navbarBGcolor} /> 
            <Slider /> 
            <Features /> 

            <h4 {...this.titleStyle}> Some Happy Clients </h4> 
            <Clients /> 

            <div style={{marginTop: '70px'}}>
               <h4 {...this.titleStyle}> Advanced analysis based on indications </h4> 
            </div> 
            <CJM /> 
            <CSAT /> 
            <Effort /> 
            <NPSsection /> 
            <Employee /> 

            <h4 {...this.titleStyle}> Omni Channels </h4> 
            <ReactCarousel /> 

            <Footer /> 
         </div> 
      ) 
   } 
} 
