import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';

import {
  questionDataSelector,
  answerFieldValueSelector,
} from '../../redux/selectors';
import {
  updateData,
  nextConditionalQuestion,
  startDate,
} from '../../redux/actions';

import { QuestionCard, Fields, FieldItem, QuestionTitle } from '../styles';

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: 'green',
  diffNameColor: 'aqua',
});

const MultipleChoice = (props) => {
  const { data, updateData, answer, nextConditionalQuestion, startDate } =
    props;
  const isConditional = data.isConditional;
  const jump = data.jump;

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date());
    }
  }, [data]);

  useEffect(() => {
    // console.log(answer)
    if (answer.index !== null) {
      if (isConditional) {
        const nextQuestion = jump.find(
          (item) => item.jumpOptionIndex === answer.index
        );
        if (!nextQuestion) {
          nextConditionalQuestion('NEXT');
        } else {
          nextConditionalQuestion(nextQuestion.jumpTo);
        }
      }
    }
  }, [answer]);

  const handleChange = (event) => {
    // const { id, value } = event.target
    // console.log(event.target.selectedIndex)
    const selectedId = event.target.selectedIndex;
    const optionElement = event.target.childNodes[selectedId];
    const id = optionElement.getAttribute('id');
    const value = optionElement.getAttribute('value');

    const index = parseInt(id, 10);
    updateData({
      index,
      value,
    });

    if (isConditional) {
      const nextQuestion = jump.find((item) => item.jumpOptionIndex === index);
      if (!nextQuestion) {
        nextConditionalQuestion('NEXT');
      } else {
        nextConditionalQuestion(nextQuestion.jumpTo);
      }
    }
  };

  const selectCheck = (value) => {
    return value === answer.index;
  };

  const choiceFields = data.options.map((choice, index) => {
    return (
      // <FieldItem key={`choice-${index}`}>
      //     <input
      //     type='radio'
      //     name={data.id}
      //     id={index}
      //     value={choice}
      //     onChange={handleChange}
      //     checked={selectCheck(index)}
      //     />
      //     <label htmlFor={index}>
      //     {choice}
      //     </label>

      // </FieldItem>
      <option
        //   type='radio'
        key={`${data.id}_${index}`}
        name={index}
        id={index}
        value={choice}
        checked={selectCheck(index)}
      >
        {choice}
      </option>
    );
  });

  return (
    <>
      {data.id && (
        <QuestionCard>
          <QuestionTitle>{data.title}</QuestionTitle>
          <Fields>
            <select
              value={typeof answer === 'object' ? answer.value : 'unanswered'}
              style={{ maxWidth: '220px' }}
              className='form-control form-control-sm'
              onChange={handleChange}
            >
              <option value=''>Please select</option>
              {choiceFields}
            </select>
          </Fields>
        </QuestionCard>
      )}
    </>
  );
};

MultipleChoice.whyDidYouRender = true;

const mapStateToProps = (state) => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state),
  };
};

const mapDispatchToProps = {
  updateData,
  nextConditionalQuestion,
  startDate,
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(MultipleChoice)
);
