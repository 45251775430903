import {createSelector} from 'reselect'

const questionListSelector = state=>state.question
const selectedQuestionSelector = state=>state.selected
export const saveButtonSelector = createSelector(
  state=>state.saveDisabled,
  data=>data
)

export const questionSelector = createSelector(
  questionListSelector,
  selectedQuestionSelector,
  (questionList, selected)=>{
    return questionList.find(question=>question.id===selected)
  }
)
