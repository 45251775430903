export const SET_NAME = "SET_NAME"
export const SET_ACTIVE = "SET_ACTIVE"
export const SET_STATUS = "SET_STATUS"
export const PROFILE_ID = "PROFILE_ID"

export const SELECT_CHANNEL = "SELECT_CHANNEL"
export const TOGGLE_VIEW = "TOGGLE_VIEW"
export const FETCH = "FETCH"
// export const SUB_CHANNEL_LIST_RECEIVE = "SUB_CHANNEL_LIST_RECEIVE"
// export const SELECT_SUB_CHANNEL = "SELECT_SUB_CHANNEL"

export const PROJECT_RECEIVE = "PROJECT_RECEIVE"
export const SELECT_PROJECT = "SELECT_PROJECT"

export const QUESTION_RECIEVE = "QUESTION_RECIEVE"

export const TOGGLE_QUESTION = "TOGGLE_QUESTION"

export const SORT_QUESTION = "SORT_QUESTION"

export const SORT_PROJECTS = "SORT_PROJECTS"

export const REMOVE_QUESTION = "REMOVE_QUESTION"
export const TOGGLE_REQUIRED = "TOGGLE_REQUIRED"
export const TOGGLE_CONDITIONAL = "TOGGLE_CONDITIONAL"
export const TOGGLE_CONDITIONAL_PREVIEW = "TOGGLE_CONDITIONAL_PREVIEW"
export const SET_QUESTION_ID_FOR_CONDITION = "SET_QUESTION_ID_FOR_CONDITION"
export const SET_QUESTION_FOR_CONDITIONAL_PREVIEW = "SET_QUESTION_FOR_CONDITIONAL_PREVIEW"
export const SET_OPTION_FOR_CONDITIONAL_PREVIEW = "SET_OPTION_FOR_CONDITIONAL_PREVIEW"

export const ADD_CARD = "ADD_CARD"
export const REMOVE_CARD = "REMOVE_CARD"

export const CHANGE_CONTACT_CARD_OPTIONS = "CHANGE_CONTACT_CARD_OPTIONS"

export const EDIT_HEADER = "EDIT_HEADER"
export const ADD_IMAGE = "ADD_IMAGE"

export const SAVE_CONFIGURATION = "SAVE_CONFIGURATION"
export const CONFIGURATION_SAVED = "CONFIGURATION_SAVED"
export const RESET = "RESET"
export const PUSH_CHANNEL_LIST = "PUSH_CHANNEL_LIST"
export const DEVICE_LIST = "DEVICE_LIST"
export const SET_DEVICE_STATUS = "SET_DEVICE_STATUS"
export const SET_DIST_DEVICE = "SET_DIST_DEVICE"
export const INIT_OPEN_LINK = "INIT_OPEN_LINK"
export const SET_OPEN_LINK = "SET_OPEN_LINK"
export const UPDATE_OPEN_LINK = "UPDATE_OPEN_LINK"
export const SET_QR_CODE = "SET_QR_CODE"
export const INIT_QR_CODE = "INIT_QR_CODE"
export const SET_GEN_QR_CODE = "SET_GEN_QR_CODE"
export const EDIT_DISTRIBUTION_CONFIG = "EDIT_DISTRIBUTION_CONFIG"
export const EDIT_DISTRIBUTION_QUESTION = "EDIT_DISTRIBUTION_QUESTION"
export const SET_OPEN_LINK_IN_DIST = "SET_OPEN_LINK_IN_DIST"
export const SET_QR_CODE_IN_DIST = "SET_QR_CODE_IN_DIST"
export const SET_ACTIVE_CHANNEL = "SET_ACTIVE_CHANNEL"
export const SET_PROGRESSBAR = "SET_PROGRESSBAR" 