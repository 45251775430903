import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Card, CardBody, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import axios from 'axios'
import collect from 'collect.js';
import Select from 'react-select';

import { backButtonFunction, getBroadObjectiveDetail, deleteBroadObjectiveById, updateBroadObjectiveById } from '../../store/actions/actionCreator'
import baseUrl from '../../../../url';
import showToast from '../../../../components/Toast/toast'
import Breadcrumb from '../../../../components/Breadcrumb/breadcrumb';

const styles = {
    error: {
        color: 'red',
        fontSize: '12px',
        marginLeft: '5px',
        textAlign: 'left',
        fontWeight: 'bold'
    }
}

class MySelect extends React.Component {

    state = { options: [] }

    componentDidMount() {

        axios.get(`${baseUrl}/api/prm/industry`).then(
            response => {
                const option = response.data.data.map(obj => {
                    return {
                        value: obj._id,
                        label: obj.user_Name || obj.name
                    }
                })
                this.setState({ options: option })
            }
        ).catch(
            error => console.log(error)
        )
    }

    handleChange = value => {
        this.props.onChange('industryIds', value);
    };

    handleBlur = () => {
        this.props.onBlur('industryIds', true);
    };

    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                {/* <label htmlFor="color">Topics (select at least 3) </label> */}
                <Select
                    id="color"
                    options={this.state.options}
                    isMulti
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                />
                {!!this.props.error &&
                    this.props.touched && (
                        <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                    )}
            </div>
        );
    }
}

class MySelectFeatureId extends React.Component {

    state = { options: [] }

    componentDidMount() {
        axios.get(`${baseUrl}/api/features`).then(
            response => {
                // console.log(response)
                const option = response.data.features.map(obj => {
                    return {
                        value: obj._id,
                        label: obj.user_Name || obj.name
                    }
                })
                this.setState({ options: option })
            }
        ).catch(
            error => console.log(error)
        )
    }

    handleChange = value => {
        this.props.onChange('featureIds', value);
    };

    handleBlur = () => {
        this.props.onBlur('featureIds', true);
    };

    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                {/* <label htmlFor="color">Topics (select at least 3) </label> */}
                <Select
                    id="color"
                    options={this.state.options}
                    isMulti
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                />
                {!!this.props.error &&
                    this.props.touched && (
                        <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                    )}
            </div>
        );
    }
}

function Details(props) {
    let { id } = props.match.params;
    let { history } = props;

    const { className } = props;
    let [isEditable, setIsEditable] = useState(false)
    let [showModal, setShowModal] = useState(false)
    let [canNotDeleteMsg, setCanNotDeleteMsg] = useState(false)

    const [industry, setIndustry] = useState([])
    const [profile, setProfile] = useState([])

    const [singleProfile, setSingleProfile] = useState(null)
    const [ind, setInd] = useState([])
    const [indValue, setIndValue] = useState([])

    const [features, setFeatures] = useState([])
    const [featuresCollect, setFeaturesCollect] = useState([])
    const [featuresCollectValue, setFeaturesCollectValue] = useState([])

    const DeleteToast = () => showToast('Deleted Successfully')
    const UpdateToast = () => showToast('Updated Successfully')
    const ErrorToast = () => showToast('Server Error')
    const DependencyToast = () => showToast('Cannot Delete. There are dependency')

    let dispatch = useDispatch()
    let { allData, dataDetails: state, } = useSelector(state => state.broadObjective)

    useEffect(
        () => {

            axios.get(`${baseUrl}/api/prm/industry`).then(
                response => {
                    setIndustry(response.data.data)
                }
            ).catch(
                error => console.log(error)
            )

            axios.get(`${baseUrl}/api/profile/all`).then(
                response => {
                    setProfile(response.data.data)
                }
            ).catch(
                error => console.log(error)
            )

            axios.get(`${baseUrl}/api/features`).then(
                response => {
                    // console.log(response)
                    setFeatures(response.data.features)
                }
            ).catch(
                error => console.log(error)
            )

        }
        , [])

    useEffect(
        () => {
            if (state.profileId) {
                axios.get(`${baseUrl}/api/profile/${state.profileId}`).then(
                    response => setSingleProfile(response.data)
                )
            }
        }
        , [state.profileId])

    useEffect(
        () => {
            if (industry.length && state.industryIds) {
                const ind = collect(industry).whereIn('_id', state.industryIds)
                setInd(ind.items)
                const indVal = ind.items.map(i => { return { value: i._id, label: i.name } })
                setIndValue(indVal)
            }

        }
        , [industry, state])

    useEffect(
        () => {
            if (features.length && state.featureIds) {
                const f = collect(features).whereIn('_id', state.featureIds)
                setFeaturesCollect(f.items)
                const fe = f.items.map(ff => { return { value: ff._id, label: ff.name } })
                // console.log(fe)
                setFeaturesCollectValue(fe)
            }
        }
        , [features, state])







    useEffect(() => {
        dispatch(getBroadObjectiveDetail(id, ErrorToast))
    }, [])


    const NowDelete = async () => {
        try {
            axios.delete(`${baseUrl}/api/broadObjectives/${id}`)
                .then(res => {
                    if (res.data.isDeletable) {
                        DeleteToast();
                        dispatch(deleteBroadObjectiveById(state));
                        props.history.push('/app/broadObjectives/');
                    }
                    else {
                        DependencyToast();
                        setCanNotDeleteMsg(true);
                        props.history.push('/app/broadObjectives/');
                    }
                })
                .catch(err => { ErrorToast(); })
        }
        catch (error) { }
    }



    let emptyName = ''
    return (
        <>
            {/* Delete Modals */}
            {
                showModal &&
                <Modal isOpen={showModal} className={className}>
                    <ModalHeader>Delete Brand</ModalHeader>
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody>
                    <ModalFooter>
                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" className="btnHover" 
                            onClick={NowDelete}>
                            Delete
                        </Button>

                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" className="btnHover" 
                            onClick={() => setShowModal(false)}>
                            Cancel
                        </Button> 
                    </ModalFooter> 
                </Modal> 
            } 




            {/* Main Body */}
            <div className="container">
                <Breadcrumb path="BroadObjective" slug={state.name} link="/app/broadObjectives" backButtonFunction={backButtonFunction} />
                {/* Button --> Back, Edit/save, Delete */}
                <Button
                    size="sm" 
                    className="btnHover" 
                    style={{ margin: '10px 0px 10px 0px', background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    onClick={() => {
                        dispatch(backButtonFunction());
                        props.history.push('/app/broadObjectives/');
                    }}>
                    Back
                </Button>
                {
                    !isEditable &&
                    <Button
                        size="sm" className="ml-2 btnHover"
                        style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                        onClick={() => setIsEditable(true)}>
                        Edit
                    </Button>
                }
                <Button
                    size="sm" className="ml-2 btnHover"
                    style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    onClick={() => setShowModal(true)}>
                    Delete 
                </Button> 



                {/* Data on card view */}
                <Card>
                    <CardBody>
                        {
                            state.name &&
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    profileId: state.profileId,
                                    name: state.name,
                                    active: state.active,
                                    isDefault: state.isDefault,
                                    industryIds: indValue,
                                    featureIds: featuresCollectValue,
                                    description: state.description,
                                    tag: state.tag
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        name: Yup
                                            .string().required("Required").trim().lowercase()
                                            .notOneOf(Object.keys(allData).length ? allData.map(item => {
                                                if (item.name.toLowerCase() !== state.name.toLowerCase()) {
                                                    return item.name.toLowerCase()
                                                }
                                            }) : [], "Already exists"),
                                        profileId: Yup
                                            .string().when("isDefault", {
                                                is: false,
                                                then: Yup.string().required("Required")
                                            })
                                    })
                                }
                                onSubmit={(values, { resetForm }) => {
                                    const indId = values.industryIds.map(obj => obj.value)
                                    const feaId = values.featureIds.map(obj => obj.value)
                                    const createdData = {
                                        "profileId": values.profileId,
                                        "name": values.name,
                                        "active": values.active,
                                        "isDefault": values.isDefault,
                                        "industryIds": indId,
                                        "featureIds": feaId,
                                        "description": values.description,
                                        "tag": values.tag,
                                    }
                                    dispatch(updateBroadObjectiveById(id, createdData, UpdateToast, ErrorToast));
                                    resetForm();
                                    history.push('/app/broadObjectives/');
                                }}>
                                {
                                    props => (
                                        <Form onSubmit={props.handleSubmit}>
                                            <FormGroup row>

                                                <Label htmlFor="name" sm={2}>
                                                    Name: <span style={{ color: 'red' }}>{isEditable && ` *`}</span>
                                                </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <>
                                                                <Input type="text" name="name" id="name"
                                                                    value={props.values.name}
                                                                    style={{ border: props.errors.name && props.touched.name ? '1px solid red' : null }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '') { emptyName = 'empty' }
                                                                        if (e.target.value) { emptyName = '' }
                                                                        props.handleChange(e)
                                                                    }}
                                                                    onBlur={props.handleBlur}
                                                                    placeholder="Enter brand name"
                                                                />
                                                                <span style={{ display: 'none' }}>
                                                                    {emptyName === 'empty' ? props.errors.name = 'Required' : null}
                                                                </span>
                                                                {
                                                                    props.errors.name && props.touched.name &&
                                                                    <p style={styles.error}>{props.errors.name}</p>
                                                                }
                                                            </>
                                                            :
                                                            <span>{state.name}</span>
                                                    }
                                                </Col>

                                                <Label htmlFor="isDefault" sm={2}> Default: </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <>
                                                                <Input
                                                                    type="checkbox" id="isDefault" name="isDefault"
                                                                    className="form-check-input mt-2"
                                                                    checked={props.values.isDefault}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                                {
                                                                    props.errors.isDefault && props.touched.isDefault &&
                                                                    <p style={styles.error}> {props.errors.isDefault} </p>
                                                                }
                                                            </>
                                                            :
                                                            <Input
                                                                type="checkbox" name="isDefault" id="isDefault"
                                                                className="form-check-input"
                                                                readOnly={true}
                                                                checked={state.isDefault}
                                                            />
                                                    }
                                                </Col>

                                            </FormGroup>

                                            <FormGroup row>

                                                <Label htmlFor="industryIds" sm={2} > Industry: </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <MySelect
                                                                value={props.values.industryIds}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                error={props.errors.industryIds}
                                                                touched={props.touched.industryIds}
                                                            />
                                                            :
                                                            <span>{ind.map(i => <span style={{ marginRight: '5px', backgroundColor: 'hsl(0,0%,90%)', padding: '3px' }}>{i.name}</span>)}</span>
                                                    }
                                                </Col>

                                                <Label htmlFor="active" sm={2}>
                                                    Active:
                                                </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <>
                                                                <Input
                                                                    type="checkbox" id="active" name="active"
                                                                    className="form-check-input mt-2"
                                                                    checked={props.values.active}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} />
                                                                {
                                                                    props.errors.active && props.touched.active &&
                                                                    <p style={styles.error}> {props.errors.active} </p>
                                                                }
                                                            </>
                                                            :
                                                            <Input
                                                                type="checkbox" name="active" id="active"
                                                                className="form-check-input"
                                                                readOnly={true}
                                                                checked={state.active}
                                                            />
                                                    }
                                                </Col>



                                            </FormGroup>

                                            <FormGroup row>

                                                <Label htmlFor="featureIds" sm={2} > Feature: </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <MySelectFeatureId
                                                                value={props.values.featureIds}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                error={props.errors.featureIds}
                                                                touched={props.touched.featureIds}
                                                            />
                                                            :
                                                            <span>{featuresCollect.map(i => <span style={{ marginRight: '5px', backgroundColor: 'hsl(0,0%,90%)', padding: '3px' }}>{i.name}</span>)}</span>
                                                    }
                                                </Col>

                                                <Label htmlFor="brand" sm={2}>
                                                    Tag:
                                                </Label>
                                                <Col sm={4}>
                                                    {
                                                        isEditable ?
                                                            <>
                                                                <Input type="text" name="tag" id="brand"
                                                                    value={props.values.tag}
                                                                    style={{ border: props.errors.tag && props.touched.tag ? '1px solid red' : null }}
                                                                    onChange={(e) => {
                                                                        if (e.target.value === '') { emptyName = 'empty' }
                                                                        if (e.target.value) { emptyName = '' }
                                                                        props.handleChange(e)
                                                                    }}
                                                                    onBlur={props.handleBlur}
                                                                    placeholder="Enter brand tag"
                                                                />
                                                                <span style={{ display: 'none' }}>
                                                                    {emptyName === 'empty' ? props.errors.tag = 'Required' : null}
                                                                </span>
                                                                {
                                                                    props.errors.tag && props.touched.tag &&
                                                                    <p style={styles.error}>{props.errors.tag}</p>
                                                                }
                                                            </>
                                                            :
                                                            <span>{state.tag}</span>
                                                    }
                                                </Col>

                                            </FormGroup>

                                            <FormGroup row>

                                                {!props.values.isDefault ? <>
                                                    <Label htmlFor="profileId" sm={2}>
                                                        Profile: <span style={{ color: 'red' }}>{isEditable && ` *`}</span>
                                                    </Label>
                                                    <Col sm={4}>
                                                        {
                                                            isEditable ?
                                                                <>
                                                                    <Input type="select" name="profileId" id="profileId"
                                                                        value={props.values.profileId}
                                                                        style={{ border: props.errors.profileId && props.touched.profileId ? '1px solid red' : null }}
                                                                        onChange={(e) => {
                                                                            if (e.target.value === '') { emptyName = 'empty' }
                                                                            if (e.target.value) { emptyName = '' }
                                                                            props.handleChange(e)
                                                                        }}
                                                                        onBlur={props.handleBlur}
                                                                        placeholder="Enter brand profileId"
                                                                    >
                                                                        <option value="" disabled defaultValue={props.values.profileId} hidden>Select...</option>
                                                                        {profile.map((obj) => <option value={obj._id} key={obj._id}> {obj.user_Name || obj.name} </option>)}
                                                                    </Input>
                                                                    <span style={{ display: 'none' }}>
                                                                        {emptyName === 'empty' ? props.errors.profileId = 'Required' : null}
                                                                    </span>
                                                                    {
                                                                        props.errors.profileId && props.touched.profileId &&
                                                                        <p style={styles.error}>{props.errors.profileId}</p>
                                                                    }
                                                                </>
                                                                :
                                                                <span>{singleProfile && (singleProfile.user_Name || singleProfile.name)}</span>
                                                        }
                                                    </Col> </> : null}



                                            </FormGroup>

                                            <FormGroup row>
                                                <Label htmlFor="description" sm={2} > Description: </Label>
                                                <Col sm={10}>
                                                    {
                                                        isEditable ?
                                                            <>
                                                                <Input
                                                                    type="textarea" name="description" id="description"
                                                                    value={props.values.description}
                                                                    onChange={props.handleChange}
                                                                    onBlur={props.handleBlur} >
                                                                </Input>
                                                                {
                                                                    props.errors.description && props.touched.description &&
                                                                    <p style={styles.error}>{props.errors.description}</p>
                                                                }
                                                            </>
                                                            :
                                                            <span>{state.description}</span>
                                                    }
                                                </Col>
                                            </FormGroup>

                                            {
                                                isEditable &&
                                                <Button type="submit" className="btnHover" size="sm" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}>
                                                    Save
                                                </Button>
                                            }
                                        </Form>
                                    )
                                }
                            </Formik>
                        }
                    </CardBody>
                </Card>
            </div>
        </>
    )
}

export default withRouter(Details)