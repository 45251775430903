import React from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';

function Breadcrumb(props) {
	let dispatch = useDispatch();
	const {
		path = null,
		slug = null,
		link = null,
		backButtonFunction = null,
		fontSize = '14px',
	} = props;
	return (
		<>
			{slug ? (
				<div className='d-flex mt-2'>
					<h6>
						<span
							style={{
								color: '#1B998B',
								cursor: 'pointer',
								fontSize,
							}}
							onClick={() => {
								if (backButtonFunction) {
									dispatch(backButtonFunction());
								}
								props.history.push(link);
							}}
						>
							{path}
						</span>
						{` / ${slug}`}
					</h6>
				</div>
			) : (
				<div className='d-flex mt-2 mb-1'>
					<h6 style={{ color: '#1B998B', fontSize }}> {path} </h6>
				</div>
			)}
		</>
	);
}

export default withRouter(Breadcrumb);
