import React from 'react';
import axios from 'axios';
import { useTable, usePagination } from 'react-table';
import { Link, withRouter } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import {
  Table as TableComponent,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import './index.css';
import showToast from '../../../../components/Toast/toast';
import baseUrl from '../../../../url';

const Table = ({
  columns,
  data,
  setData,
  pageNo,
  setPageNo,
  pageLimit,
  setPageLimit,
  totalPages,
  setTotalPages,
  ...props
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    usePagination
  );

  const [modal, setModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState('');

  React.useEffect(() => {
    setPageSize(15);
  }, [setPageSize]);

  const handleDelete = async (id) => {
    try {
      const res = await axios.delete(`${baseUrl}/api/bulk-email/${id}`);
      const { data: deletedData } = res;

      if (deletedData && deletedData.message === 'Successfully Deleted') {
        const newData = data.filter((item) => item._id !== id);
        setData((prev) => newData);
        setTotalPages(Math.ceil(newData.length / pageSize));
      } else {
        showToast('Unable to delete email');
      }
    } catch (error) {
      showToast('Something went wrong');
    }
  };

  const toggleModal = () => setModal(!modal);

  return (
    <div>
      {/* Table */}
      <Scrollbars
        autoHide
        style={{
          height: data.length === 0 ? '300px' : '65vh',
          borderBottom: '1px solid #dee2e6',
          borderTop: '1px solid #dee2e6',
        }}
      >
        <TableComponent
          hover
          {...getTableProps()}
          style={{ marginBottom: '0px', lineHeight: '100%' }}
        >
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr
                id='header'
                {...headerGroup.getHeaderGroupProps()}
                key={`id: ${i}`}
              >
                {headerGroup.headers.map((column, i) => (
                  <th
                    {...column.getHeaderProps()}
                    style={{ position: 'sticky', top: '-1px' }}
                    key={`id: ${i}`}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className='rowHover' {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={`id: ${i}`}>
                  {row.cells.map((cell, j) => {
                    return (
                      <React.Fragment key={`id: ${j}`}>
                        {j === 0 ? (
                          <td
                            style={{ width: '40%' }}
                            className='text-justify'
                            {...cell.getCellProps()}
                            key={`id: ${j}`}
                          >
                            {cell.value}
                          </td>
                        ) : j === 1 ? (
                          <td
                            {...cell.getCellProps()}
                            style={{ width: '40%' }}
                            key={`id: ${i}`}
                          >
                            {cell.value}
                          </td>
                        ) : j === 2 ? (
                          <td
                            {...cell.getCellProps()}
                            key={`id: ${j}`}
                            className='text-justify'
                          >
                            {new Date(cell.value).toLocaleString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}
                          </td>
                        ) : j === 3 ? (
                          <td
                            key={`id: ${j}`}
                            className='text-justify'
                            style={{
                              width: '60px',
                            }}
                          >
                            <Button
                              color='danger'
                              size='sm'
                              onClick={() => {
                                toggleModal();
                                setSelectedId(cell.row.original._id);
                              }}
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </Button>
                          </td>
                        ) : null}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </TableComponent>
      </Scrollbars>

      {/* Pagination */}
      <div style={{ position: 'sticky', bottom: '15px' }}>
        <div className='pagination mt-3'>
          <>
            <button
              style={{
                height: '28px',
                background: '#EEEEEE',
                padding: '0px 10px',
                borderRadius: '3px',
                border: 'none',
                marginRight: '10px',
              }}
              onClick={() => {
                gotoPage(0);
                setPageNo(1);
              }}
              disabled={pageNo === 1}
            >
              <i className='fas fa-angle-double-left'></i>
            </button>
            <button
              style={{
                height: '28px',
                background: '#EEEEEE',
                padding: '0px 10px',
                borderRadius: '3px',
                border: 'none',
                marginRight: '10px',
              }}
              onClick={() => {
                previousPage();
                setPageNo(pageIndex + 1);
              }}
              disabled={pageNo === 1}
            >
              <i className='fas fa-angle-left'></i>
            </button>
            <button
              style={{
                height: '28px',
                background: '#EEEEEE',
                padding: '0px 10px',
                borderRadius: '3px',
                border: 'none',
                marginRight: '10px',
              }}
              onClick={() => {
                nextPage();
                setPageNo(pageNo + 1);
              }}
              disabled={pageNo === totalPages}
            >
              <i className='fas fa-angle-right'></i>
            </button>
            <button
              style={{
                height: '28px',
                background: '#EEEEEE',
                padding: '0px 10px',
                borderRadius: '3px',
                border: 'none',
                marginRight: '10px',
              }}
              onClick={() => {
                gotoPage(totalPages);
                setPageNo(totalPages);
              }}
              disabled={pageNo === totalPages}
            >
              <i className='fas fa-angle-double-right'></i>
            </button>
          </>
          <span
            style={{
              color: '#424242',
              marginRight: '10px',
              marginTop: '2px',
            }}
          >
            page{' '}
            <span style={{ color: '#212121', fontWeight: '500' }}>
              {pageNo} of {totalPages}
            </span>{' '}
          </span>
          <span
            style={{
              color: '#424242',
              marginRight: '10px',
              lineHeight: '23px',
            }}
          >
            | go to page:{' '}
            <input
              type='number'
              defaultValue={pageNo}
              onChange={(e) => {
                if (e.target.value && e.target.value < 1) {
                  e.target.value = 1;
                }

                if (e.target.value && e.target.value > totalPages) {
                  e.target.value = Number(totalPages);
                }

                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
                setPageNo(page + 1);
              }}
              style={{
                color: '#212121',
                fontWeight: '700',
                width: '80px',
                height: '25px',
                padding: '0px 8px',
                border: '1px solid #BDBDBD',
                borderRadius: '3px',
              }}
            />
          </span>{' '}
          <select
            value={pageSize}
            style={{
              height: '25px',
              background: '#FFF',
              border: '1px solid #BDBDBD',
              borderRadius: '3px',
            }}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              setPageLimit(Number(e.target.value));
              setTotalPages(Math.ceil(data.length / Number(e.target.value)));
            }}
          >
            {[5, 10, 15, 20, 30, 40, 50, 75, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Modal */}
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalBody>Are you sure you want to delete this email?</ModalBody>
        <ModalFooter>
          <Button
            color='danger'
            onClick={() => {
              handleDelete(selectedId);
              toggleModal();
            }}
          >
            Delete
          </Button>
          <Button color='secondary' onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default withRouter(Table);
