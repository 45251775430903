import React from 'react'

function ToastComponent(props) {
  return (
    <div> 
    { 
      props.msg === 'Server Error' 
        ? <><i style={{color: '#1B998B'}} className="fa fa-times"></i>{` ${props.msg}`}</> 
        : props.msg === 'Cannot delete, there are dependency' 
          ? <><i style={{color: '#1B998B'}} className="fas fa-exclamation-triangles"></i>{` ${props.msg}`}</> 
          : <><i style={{color: '#1B998B'}} className="fa fa-thumbs-up"></i>{` ${props.msg}`}</>
    } 
    </div> 
  ) 
} 

export default ToastComponent
