import React from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { HIDE_TOAST } from '../../redux/actions/actionTypes';
import { hideToast } from '../../redux/actions/modifyQuestion'
const Modal = styled.div`  
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: black;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0,0,0,0.35);
  left: 0;
  top: 0;
`

const Card = styled.div`
position: absolute;
display: ${props => props.view ? "flex" : "none"};
flex-direction: column;
box-sizing: border-box;
justify-content: center;
align-items: center;
width: 30vw;
height:20vh;
background-color: white;
border-radius: 3px;
z-index:5;
`

const Content = styled.div`
display: flex;
box-sizing: border-box;
justify-content: center;
padding:30px;
width:100%;
color: #212121;
font-size: 120%;
`
export const CloseButton = styled.div`
color: #C52590;
font-size: 20px;
line-height:18px;
position: absolute;
text-align: center;
top: -10px;
right: -10px;
background-color: white;
border-radius: 50%;
width: 20px;
height: 20px;
z-index: 1;
box-shadow: 1px 1px 5px -1px #586469;
:hover{
  cursor: pointer;
  box-shadow: 1px 1px 5px 2px #586469;
}
`

const Toast = ({ active, hideToast }) => {
  return (
    <Modal css={`display:${active.status ? 'flex' : 'none'}`}>
      <Card view={active.status}>
        {/* <CloseButton onClick={() => { hideToast() }}>x</CloseButton> */}
        <Content css={`align-items: bottom;height:40%;`}>
          <FontAwesomeIcon
            icon={active.message.split(" ")[0] === 'Save' ? faCheckCircle : faExclamationTriangle}
            style={{ fontSize: "45px", marginRight: "10px", color:"#1B998B" }}
          />
        </Content>
        <Content css={`height:60%;`}>
          {active.message}
        </Content>
      </Card>
    </Modal>
  )
}

const mapStateToProps = state => ({
  active: state.toast
})

const mapDispatchToProps = {
  hideToast: hideToast

}

export default connect(mapStateToProps, mapDispatchToProps)(Toast)