import React, { Component } from 'react';
import CardSummary from './cardSummary/CardSummary';
import PieChart from './charts/piechart/PieChart';
import CardView from './cardView/CardView';
import Line from '../../DataAnalysisTwo/components/charts/nivo/Line';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import HeaderInfoCard from './headerInfoCard/HeaderInfoCard';
import { Scrollbars } from 'react-custom-scrollbars';
import Respondant from './respondant/Respondant';
import QuestionAnalytics from './questions/QuestionsAnalytics';

import { CSVLink, CSVDownload } from 'react-csv';

class CoreAccordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionIndex: [
        {
          index: 0,
          collapse: true,
        },
        {
          index: 1,
          collapse: false,
        },
      ],
    };
    this.index_0 = React.createRef();
    this.index_1 = React.createRef();
    this.toggleSection = this.toggleSection.bind(this);
  }

  toggleSection(index, e) {
    e.preventDefault();

    if (index === 0) {
      const sectionIndex = [...this.state.sectionIndex];

      if (this.state.sectionIndex[index].collapse) {
        this.index_0.current.className = 'collapse show';
        sectionIndex[index].collapse = !sectionIndex[index].collapse;
        this.setState({
          sectionIndex: sectionIndex,
        });
      } else {
        this.index_0.current.className = 'collapse';
        sectionIndex[index].collapse = !sectionIndex[index].collapse;
        this.setState({
          sectionIndex: sectionIndex,
        });
      }
    } else if (index === 1) {
      const sectionIndex = [...this.state.sectionIndex];

      if (this.state.sectionIndex[index].collapse) {
        this.index_1.current.className = 'collapse show';
        sectionIndex[index].collapse = !sectionIndex[index].collapse;
        this.setState({
          sectionIndex: sectionIndex,
        });
      } else {
        this.index_1.current.className = 'collapse';
        sectionIndex[index].collapse = !sectionIndex[index].collapse;
        this.setState({
          sectionIndex: sectionIndex,
        });
      }
    }
  }

  render() {
    // const header = [
    //     {label: 'Question', key: 'title'},
    //     {label: 'Options', key: 'options'},
    //     {label: 'Answered', key: 'answered'},
    //     {label: 'Answer', key: 'answer'}
    // ];

    let data = this.props.coreData.questions;

    data = data.map((row) => ({
      Question: row.title,
      Options: row.options,
      Answered: row.answered,
      Answer:
        row.type === 'textBox' ? row.answer.map((x) => x.text) : row.answer,
    }));

    return (
      <div className='accordion' id='accordionExample'>
        <div className='mb-0'>
          <div
            className='card-header'
            id='headingOne'
            onClick={this.toggleSection.bind(this, 0)}
            style={{ backgroundColor: '#FFF', cursor: 'pointer' }}
          >
            <div className='row'>
              <div className='col-10'>
                {this.state.sectionIndex[0].collapse ? (
                  <HeaderInfoCard summary={this.props.coreData.summary} />
                ) : (
                  <h6
                    className='mt-2'
                    style={{
                      color: '#1B998B',
                      fontSize: '14px',
                      fontWeight: '700',
                    }}
                  >
                    Respondants Analytics
                  </h6>
                )}
              </div>
              <div className='col-2 text-right'>
                <button className='btn btn-link' type='button'>
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    transform={{
                      rotate: this.state.sectionIndex[0].collapse ? -180 : 0,
                    }}
                    size='lg'
                    style={{ color: '#1B998B' }}
                  />
                </button>
              </div>
            </div>
          </div>

          <div id='collapseOne' className='collapse fade' ref={this.index_0}>
            <div className='card-body'>
              <div className='row'>
                <div className='col-lg-4 col-md-12 mb-4'>
                  <CardSummary summary={this.props.coreData.summary} />
                </div>
                <div className='col-lg-8 col-md-12 mb-4'>
                  <CardView
                    title='Date wise Response'
                    padding='0'
                    hasSparkLine={false}
                    sparklineTitle='Last 15 days of response'
                  >
                    {this.props.coreData.dateWiseResponse.trend.length ? (
                      <Line
                        title=' '
                        line={this.props.coreData.dateWiseResponse.trend}
                        area={true}
                        isParcent={false}
                        marginBottom={null}
                      />
                    ) : (
                      <div
                        className='alert text-danger text-center'
                        role='alert'
                      >
                        <strong>No data found!</strong>
                      </div>
                    )}
                  </CardView>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-4 col-md-12 mb-4'>
                  <CardView title='Respondants Information'>
                    <Scrollbars
                      style={{ height: 250 }}
                      autoHide
                      autoHideTimeout={1000}
                      autoHideDuration={200}
                    >
                      <Respondant
                        respondants={
                          this.props.coreData.respondantInformation.list
                        }
                      />
                    </Scrollbars>
                  </CardView>
                </div>
                <div className='col-lg-4 col-md-12 mb-4'>
                  <CardView
                    title='Response By Channel'
                    hasSparkLine={true}
                    // sparklineTitle='Last 15 days of response by channel'
                    sparklineTitle={`${this.props.coreData.durationInDays} response by channel`}
                    sparkLineData={
                      this.props.coreData.channelWiseResponse.trend
                    }
                  >
                    {this.props.coreData.channelWiseResponse.chartData
                      .length ? (
                      <PieChart
                        id='channel'
                        chartData={
                          this.props.coreData.channelWiseResponse.chartData
                        }
                      />
                    ) : (
                      <div
                        className='alert text-danger text-center'
                        role='alert'
                      >
                        <strong>No data found!</strong>
                      </div>
                    )}
                  </CardView>
                </div>
                <div className='col-lg-4 col-md-12 mb-4'>
                  <CardView
                    title='Gender'
                    hasSparkLine={true}
                    // sparklineTitle='Last 15 days of response by gender'
                    sparklineTitle={`${this.props.coreData.durationInDays} response by gender`}
                    sparkLineData={this.props.coreData.genderWiseResponse.trend}
                  >
                    {this.props.coreData.genderWiseResponse.chartData.filter(
                      (x) => x[1] !== 0
                    ).length > 0 ? (
                      <PieChart
                        chartData={
                          this.props.coreData.genderWiseResponse.chartData
                        }
                        id='gender'
                      />
                    ) : (
                      <div
                        className='alert text-danger text-center'
                        role='alert'
                      >
                        <strong>No data found!</strong>
                      </div>
                    )}
                  </CardView>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='mb-0'>
          <div
            className='card-header'
            id='headingTwo'
            onClick={this.toggleSection.bind(this, 1)}
            style={{ backgroundColor: '#FFF', cursor: 'pointer' }}
          >
            <div className='row'>
              <div className='col-10'>
                <h6
                  className='mt-2'
                  style={{
                    color: '#1B998B',
                    fontSize: '14px',
                    fontWeight: '700',
                  }}
                >
                  Response Analytics
                </h6>
              </div>
              <div className='col-2 text-right'>
                <button className='btn btn-link' type='button'>
                  <FontAwesomeIcon
                    icon={faAngleUp}
                    transform={{
                      rotate: this.state.sectionIndex[1].collapse ? -180 : 0,
                    }}
                    size='lg'
                    style={{ color: '#1B998B' }}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            id='collapseTwo'
            className='collapse fade show'
            ref={this.index_1}
          >
            <div className='text-right'>
              {this.props.coreData.questions.length ? (
                <CSVLink
                  data={data}
                  filename={'my-file.csv'}
                  className='btn btn-primary btn-sm'
                  style={{
                    marginTop: '15px',
                    marginRight: '20px',
                    border: 'none',
                    backgroundColor: 'rgb(27, 153, 139)',
                  }}
                >
                  Export CSV
                </CSVLink>
              ) : null}
            </div>
            <div className='card-body'>
              <QuestionAnalytics
                questions={this.props.coreData.questions}
                totalResponse={this.props.coreData.summary.answered}
                apiBody={this.props.apiBody}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CoreAccordion;
