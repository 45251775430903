//Dependencies
import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

//Custom Components and resources
import MainNav from '../MainNav';
import QuestionCardList from '../../containers/QuestionCardList';
import CreateProject from '../CreateProject';
import SaveButton from '../SaveButton';
import PreviewButton from '../PreviewButton';
import Analysis from '../Visualization';
import LinkButton from '../LinkButton';
import { Button, Input } from 'reactstrap';
import AddButton from '../AddButton';
import { connect } from 'react-redux';
import {
  fetchQuestion,
  fetchObjectives,
  updateQuestion,
  backButtonFunction,
  deleteQuestionBank,
  setDisStatus,
  setIsReadOnly,
  surveyQuestionSaveMessageFalse,
} from '../../redux/actions/modifyQuestion';
import Toast from '../Toast';
import Loader from '../../../Loader';
import AddFromQuestionBank from '../AddFromQuestionBank';
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb';
import { TextAlignCenter } from 'grommet-icons';
import './style.css';
import axios from 'axios';
import baseUrl from '../../../../url';

const MainBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const AddButtonContainer = styled.div`
  width: 100%;
  padding-top: 10px;
`;

const Body = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  position: relative;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: calc(100vh - 135px);
  padding: 10px 10px 0px 10px;

  @media (max-width: 991.92px) {
    grid-template-columns: 1fr;
    min-height: fit-content;
  }
`;

const GridItem1 = styled.div`
  order: -1;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  padding-bottom: 20px;

  @media (max-width: 991.92px) {
    order: 1;
    padding-left: 0;
  }
`;

const FormButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #fff;
  height: 55px;
  width: 56px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  @media (max-width: 767.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
  @media (max-width: 991.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
`;

const SurveySaveButton = styled(FormButton)`
  background: rgb(27, 153, 139);
  position: fixed;
  top: 85px;
  right: 50px;
  z-index: 2;
  height: auto;
  width: auto;
  padding: 5px 20px;
  border-radius: 5px;
  @media (max-width: 767.92px) {
    top: 80px;
    right: 45px;
  }
  @media (max-width: 991.92px) {
    top: 55px;
    right: 90px;
    width: auto;
    height: auto;
  }
`;

class FormApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      features: [],
      selectedFeatureId: '',
    };
  }

  // componentDidMount(){
  //   this.props.fetchQuestion();
  // }

  /* componentDidMount() {
    // this.props.initialize(this.props.objective) 
    this.props.fetchObjectives()
    if (this.props.projectId !== 'create')
      this.props.fetchQuestion(this.props.projectId)
    console.log(this.props.projectId)
  } */

  getFeaturesData() {
    axios
      .get(`${baseUrl}/api/features/list/all`)
      .then((res) => {
        console.log('feature res - ', res);
        if (res.data.features) {
          const getFeatures = res.data.features.map((item) => ({
            _id: item._id,
            name: item.name,
          }));

          this.setState({
            features: getFeatures,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    // this.props.initialize(this.props.objective)
    this.props.fetchObjectives();
    if (this.props.projectId !== 'create')
      this.props.fetchQuestion(this.props.projectId);
    // console.log(this.props.projectId)
    this.props.setDisStatus(this.props.disStatus);
    this.props.setIsReadOnly(this.props.isReadOnlyProps);

    this.getFeaturesData();
  }

  componentDidUpdate() {
    if (this.props.surveyQuestionSaveMessage === true) {
      this.props.surveyQuestionSave(this.props.surveyQuestionSaveMessage);
    }
    this.props.message(this.props.updateMessage);
    this.props.setDisStatus(this.props.disStatus);
    this.props.setIsReadOnly(this.props.isReadOnlyProps);
    // console.log(this.props.isReadOnly)
  }

  componentWillUnmount() {
    this.props.surveyQuestionSaveMessageFalse();
  }

  handleSelectChange = (event) => {
    this.setState({ selectedFeatureId: event.target.value });
  };

  render() {
    return (
      <MainBody id='testId'>
        {/* <MainNav /> */}
        {/* <PreviewButton>Preview</PreviewButton>
        <Analysis>Analysis</Analysis>
        <LinkButton> Generate Link </LinkButton> */}
        {/* <BreadCrumb path="Projects" slug="Create" link="/app/projects/" backButtonFunction={backButtonFunction} /> */}
        {this.props.loading ? (
          <Loader />
        ) : (
          <>
            <Toast />
            {this.props.projectId !== 'create' && (
              <SurveySaveButton
                className='btnHover'
                onClick={() => {
                  this.props.updateQuestion(
                    this.props.question,
                    this.props.projectId,
                    this.props.history,
                    this.props.mode
                  );
                }}
              >
                Next
              </SurveySaveButton>
            )}
            {/* {this.props.projectId === 'create' && <SaveButton className="btnHover" css={`background: '#1B998B`}>Save</SaveButton>}
            {this.props.projectId !== 'create' && <Button className="btnHover text-center" style={{ ...style.button, ...style.update }} size="sm" onClick={() => this.props.updateQuestion(this.props.question, this.props.projectId)}>Update</Button>}
            {this.props.projectId !== 'create' && <Button className="btnDeleteHover text-center" style={{ ...style.button, ...style.delete }} size="sm" onClick={() => this.props.deleteQuestion(this.props.projectId)}>Delete</Button>} */}
            {/* 10px 25px 5px 25px */}
            <Body>
              <GridItem1>
                <div className='survey-info-container'>
                  <CreateProject />
                </div>
                {/* {!this.props.isReadOnly && */}
                {/* } */}
                {this.props.question.length ? (
                  <h6 className='survey-question-text text-center mb-2 mt-2'>
                    Survey Questions
                  </h6>
                ) : null}
                <QuestionCardList />
                {!this.props.isReadOnly && (
                  <AddButtonContainer>
                    <AddButton />
                  </AddButtonContainer>
                )}
              </GridItem1>
              {/* <AddButton /> */}

              <div className='question-bank-container'>
                <div className='survey-questionbank-container'>
                  <Accordion allowZeroExpanded={true}>
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton
                          className='survey-question-bank'
                          style={{
                            cursor: this.props.isReadOnly
                              ? 'not-allowed'
                              : 'pointer',
                            pointerEvents: this.props.isReadOnly
                              ? 'none'
                              : 'auto',
                            opacity: this.props.isReadOnly ? '0.5' : '1',
                          }}
                        >
                          Use question bank template
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Input
                          type='select'
                          name='featureId'
                          id='featureId'
                          onChange={this.handleSelectChange}
                          value={this.state.selectedFeatureId}
                          style={{
                            fontSize: '14px',
                            marginBottom: '10px',
                          }}
                        >
                          <option value='' defaultValue hidden>
                            Select a Feature
                          </option>
                          {this.state.features.map((obj) => (
                            <option value={obj._id} key={obj._id}>
                              {' '}
                              {obj.name}{' '}
                            </option>
                          ))}
                        </Input>
                        <AddFromQuestionBank
                          selectedFeatureID={this.state.selectedFeatureId}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  </Accordion>
                </div>
              </div>
            </Body>
          </>
        )}
      </MainBody>
    );
  }
}

const mapDispatchToProps = {
  fetchQuestion: fetchQuestion,
  fetchObjectives: fetchObjectives,
  updateQuestion: updateQuestion,
  deleteQuestion: deleteQuestionBank,
  setDisStatus: setDisStatus,
  setIsReadOnly: setIsReadOnly,
  surveyQuestionSaveMessageFalse: surveyQuestionSaveMessageFalse,
  // initialize: initialize,
};

const mapStateToProps = (state, ownProps) => {
  // state -> retrieve value from store, ownprops-> values form this component
  const { objective, projectId } = ownProps;
  const { loading, question } = state;
  const updateMessage = state.updateMessage;
  const surveyQuestionSaveMessage = state.surveyQuestionSaveMessage;
  const isReadOnly = state.isReadOnly;
  return {
    objective,
    projectId,
    loading,
    question,
    updateMessage,
    surveyQuestionSaveMessage,
    isReadOnly,
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FormApp)
);
