import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { MainBody, Back } from './Styles'
// import ProjectObjectives from '../ProjectObjectives'
import QuestionBankQuestions from '../QuestionBankQuestions'
import baseUrl from '../../url'
import axios from 'axios'


const IndividualProject = props => {
    let [name, setName] = useState('')
    const { id } = props.match.params

    useEffect(() => {
        axios.get(`${baseUrl}/api/questionbank/${id}`)
            .then(res => { setName(res.data.name) })
            .catch(err => console.log('server error on project-individual index page api call'))
    }, [])


    return (
        <MainBody>
            {/* Breadcrumb */}
            {
                (name || id === 'create') && <>
                    <div className="d-flex mt-3">
                        <h6>
                            <span style={{ color: '#1B998B', cursor: 'pointer', marginLeft: '20px' }}
                                onClick={() => { props.history.push('/app/questionbank') }}>
                                Question Bank
                    </span>
                            {` / ${id === 'create' ? 'Create' : name}`}
                        </h6>
                    </div>
                    <hr className="mt-0" />
                </>
            }
            {/* <Back to={`/app/questionbank`}>Back</Back> */}

            <QuestionBankQuestions projectId={id} />

        </MainBody>
    )
}

export default withRouter(IndividualProject)