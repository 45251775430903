// const baseUrl = 'http://ec2-18-139-130-52.ap-southeast-1.compute.amazonaws.com';
// const baseUrl = 'http://ec2-52-221-183-227.ap-southeast-1.compute.amazonaws.com';
// const baseUrl = 'http://192.168.43.233:3530';
// const baseUrl = 'http://ec2-52-221-181-43.ap-southeast-1.compute.amazonaws.com:3530'
const baseUrl = process.env.REACT_APP_BASE_URL;


// export const pexUrl = '192.168.43.233:3000'
export const pexUrl = process.env.REACT_APP_PEX_URL;


export default baseUrl

/*------------------------Login----------------------------------------*/
export const loginURL = `${baseUrl}/api/login/check`;




/*------------------------Profile----------------------------------------*/

export const fetchProfile = (userId) => {
  return `${baseUrl}/api/profile/${userId}`;
}

export const putProfile = (userId) => {
  return `${baseUrl}/api/profile/update/${userId}`
}

export const profileDetailsById = (profileId) => {
  return `${baseUrl}/api/profile/${profileId}`
}

export const postImageURL = (userId) => {
  return `${baseUrl}/upload/image-upload/${userId}`
}

export const postCoverImageURL = (userId) => {
  return `${baseUrl}/upload/cover-upload/${userId}`
}

export const getProfileIdWiseChannels = profileId => `${baseUrl}/api/profile/channels/${profileId}`





/*------------------------Device----------------------------------------*/

export const createDevice = `${baseUrl}/api/channels/device`
export const getAllBranch = (profileId) => `${baseUrl}/api/prm/branch/profile/${profileId}/?page=1&limit=500&active=true`
export const getAllDevice = (profileId) => `${baseUrl}/api/channels/device/list/${profileId}`
export const singleDeviceById = (id) => `${baseUrl}/api/channels/device/individual/${id}`
export const deleteDeviceById = (id) => `${baseUrl}/api/channels/device/${id}`
export const updateDeviceById = (id) => `${baseUrl}/api/channels/device/update/${id}`






export const postDevice = `${baseUrl}/api/channels/device/add`;

export const fetchDevice = (userId) => {
  return `${baseUrl}/api/channels/device/list/${userId}`
}

export const deleteDeviceURL = (deviceID) => {
  return `${baseUrl}/api/channels/device/delete/${deviceID}`;
}

export const updateDeviceURL = (deviceID) => {
  return `${baseUrl}/api/channels/device/update/${deviceID}`;
}

export const fetchDeviceConfig = deviceId => `${baseUrl}/api/channels/device/individual/${deviceId}`

export const updateDeviceDistribution = deviceId => (
  `${baseUrl}/api/channels/device/update/question/${deviceId}`
)





// ----------------------------------- qrCode -----------------------------
export const qrCodeLink = userId => `${baseUrl}/api/channels/qrcode/users/${userId}`

export const createQRcode = `${baseUrl}/api/channels/qrcode`
export const getAllProfileWiseQRcode = id => `${baseUrl}/api/channels/qrcode/profile/${id}/?limit=500&page=1`
export const singleQRcode = id => `${baseUrl}/api/channels/qrcode/${id}`
export const updateQRcode = id => `${baseUrl}/api/channels/qrcode/individual/${id}`
export const deleteQRcode = id => `${baseUrl}/api/channels/qrcode/${id}`









// ----------------------------------- Blog -----------------------------
export const createBlogPost = `${baseUrl}/api/blog/posts`
export const getAllBlogPost = `${baseUrl}/api/blog/posts/`
export const singleBlogPost = id => `${baseUrl}/api/blog/posts/${id}`
export const updateBlogPost = id => `${baseUrl}/api/blog/posts/${id}`
export const deleteBlogPost = id => `${baseUrl}/api/blog/posts/${id}`
export const uploadBlogImage = id => `${baseUrl}/api/blog/posts/${id}`











/*-------------------------Open Link----------------------------------------*/

export const createOL = `${baseUrl}/api/channels/openlink/`
export const getAllProfileWiseOL = id => `${baseUrl}/api/channels/openlink/profile/${id}/?limit=500&page=1`
export const singleOL = id => `${baseUrl}/api/channels/openlink/${id}`
export const updateOL = id => `${baseUrl}/api/channels/openlink/individual/${id}`
export const deleteOL = id => `${baseUrl}/api/channels/openlink/${id}`




export const fetchLink = companyId => `${baseUrl}/api/channels/openlink/list/${companyId}`

export const fetchLinkQuestions = linkId => `${baseUrl}/api/channels/openlink/question/${linkId}`

export const addQuestionLink = linkId => `${baseUrl}/api/channels/openlink/update/question/${linkId}`

export const fetchOpenLink = hashLink => `${baseUrl}/api/channels/openlink/hash/${hashLink}`

export const fetchQrCodeLink = hashLink => `${baseUrl}/api/channels/qrcode/hash/${hashLink}`

export const updateOpenLinkDistribution = channelId => (
  `${baseUrl}/api/channels/openlink/update/question/${channelId}`
)

export const fetchOpenLinkConfig = channelId => (
  `${baseUrl}/api/channels/openlink/individual/${channelId}`
)









/*------------------------Project----------------------------------------*/


export const fetchProject = (userId) => {
  return `${baseUrl}/api/project/profile/${userId}?active=true`
}

export const postProject = `${baseUrl}/api/project/post`;




//----------------------Feature-----------------------------
export const postFeature = `${baseUrl}/api/feature/addnew`

export const fetchAllFeatureByProfile = (companyId) => `${baseUrl}/api/feature/get/${companyId}`

export const fetchFeature = `${baseUrl}/api/feature/all`

export const updateFeature = featureId => `${baseUrl}/api/feature/update/${featureId}`

export const getSingleFeature = featureId => `${baseUrl}/api/feature/details/${featureId}`

export const checkFeatureDeleteStatus = featureId => `${baseUrl}/api/broadObjective/checkfeature/${featureId}`

export const deleteFeature = featureId => `${baseUrl}/api/feature/delete/${featureId}`




/*------------------------Broad Objective----------------------------------------*/

export const fetchBroadObjective = `${baseUrl}/api/broadObjective/all`

export const fetchBroadObjectiveByProfile = (companyId) => `${baseUrl}/api/broadObjective/get/${companyId}`

export const postBroadObjective = `${baseUrl}/api/broadObjective/addnew`

export const checkBroadObjectiveDeleteStatus = broadObjeciveId => `${baseUrl}/api/objective/checkbroadobjective/${broadObjeciveId}`

export const deleteBroadObjective = broadObjeciveId => `${baseUrl}/api/broadObjective/delete/${broadObjeciveId}`

export const updateBroadObjective = broadObjectiveId => `${baseUrl}/api/broadObjective/update/${broadObjectiveId}`




/*------------------------Objective----------------------------------------*/

export const fetchObjective = (companyId) => {

  return `${baseUrl}/api/objective/get/${companyId}`;
}

export const fetchAllObjective = `${baseUrl}/api/objective/all`;

export const postObjective = `${baseUrl}/api/objective/addnew`;

export const updateObjective = (objectiveId) => `${baseUrl}/api/objective/update/${objectiveId}`

export const deleteObjective = (objectiveId) => `${baseUrl}/api/objective/delete/${objectiveId}`

export const checkObjectiveDeleteStatus = objectiveId => `${baseUrl}/api/survey/checkobjective/${objectiveId}`





/*------------------------End User Preview ----------------------------------------*/

export const getDeviceQuestions = (deviceID) => {

  return `${baseUrl}/api/device/question/${deviceID}`;
}

export const postDeviceResponse = `${baseUrl}/api/response/post`;
// export const postProject = 'http://ec2-54-255-238-205.ap-southeast-1.compute.amazonaws.com/api/project/post';


export const addQuestionDevice = (deviceId) => {

  return `${baseUrl}/api/device/update/question/${deviceId}`;

}




/*------------------------Email Scheduler-----------------------------------------*/
export const emailScheduler = `${baseUrl}/api/service/emailscheduler/addnew`





/*------------------------Fetch Question----------------------------------------*/

export const saveQuestionUrl = `${baseUrl}/api/survey/post`;

export const fetchQuestionUrl = (projectId) => {

  return `${baseUrl}/api/project/${projectId}`
  // return `${baseUrl}/api/survey/project/${projectId}`
}




/*----------------------------Fetch Response------------------------------------*/

export const fetchResponse = projectId => `${baseUrl}/api/response/project/${projectId}`






/*------------------------Aggregate for DashBoard----------------------------------------*/

export const primaryDashboardData = () => {
  return `${baseUrl}/api/visualization/dashboard`;
  // return `${baseUrl}/dashboard.json`;
}

// export const countProject = (userId) => {
//   return `${baseUrl}/api/project/count/${userId}`;
// }

// export const countResponse = (userId) => {
//   return `${baseUrl}/api/response/count/${userId}`;
// }

// export const countDevice = (userId) => {
//   return `${baseUrl}/api/channels/device/count/${userId}`;
// }





/*----------------------------------Channels Configuration-----------------------------------------------------*/

export const startChannel = `${baseUrl}/api/channel/addnew`

export const getChannelList = `${baseUrl}/api/channel/list/all`

export const getActiveChannelList = `${baseUrl}/api/channel/list/active`

export const singleChannel = channelId => `${baseUrl}/api/channel/details/${channelId}`

export const updateChannel = channelId => `${baseUrl}/api/channel/update/${channelId}`

export const deleteChannel = channelId => `${baseUrl}/api/channel/delete/${channelId}`

export const getProfileByChannelId = channelId => `${baseUrl}/api/profile/list/${channelId}`