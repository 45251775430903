import styled from 'styled-components'

export const Scale = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
`

export const Rating = styled.div`
  margin: 0 5px;
`;

export const Label = styled.label`
text-align: center; 
cursor: pointer; 
font-size: 12px; 
maxWidth:120px; 
height:60px; 
color:#424242;
word-break: break-word;
@media screen and (max-width: 767.92px){
  max-width:80px;
  font-size:10px;
  font-weight:500;
}
`
