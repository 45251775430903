import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { FetchAllCompany,FetchAllIndustry, AddNewCompany } from '../../../url/prmUrl';
import { Button, Input, Label, Body, Error, Aside, Modal } from '../StyledComponents'
import Alert from '../ShowAlert'

const Form = props => {

  const {
    options,
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;


  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.companyName && touched.companyName ? <Error>{errors.companyName}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Company Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="companyName"
                    type="text"
                    placeholder="Company name"
                    value={values.companyName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.industryId && touched.industryId ? <Error>{errors.industryId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Industry:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="industryId"
                    value={values.industryId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.industryId}
                    options={options}
                  /> 
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Active:
                </div>
                <div css={'width:15px'}>
                  <Input
                    name="status"
                    type="checkbox"
                    css={`width:18px;height:18px`}
                    checked={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class MySelect extends React.Component {
  handleChange = value => {
    this.props.onChange("industryId", value); //initially gets the null value and updates when called from onchange below
  };

  handleBlur = () => {
    this.props.onBlur("industryId", true); //sets the onblur value to true when called from onBlur below
  };
 
  render() {
    return (
        <Select
          id="color"
          placeholder="Select Industry"
          options={this.props.options}
          //isMulti
          onChange={this.handleChange}  //calls the handleChange in MySelect class
          onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
          value={this.props.value}      // gets the selected value, which is eventually options.value
        />

    );
  }
}

const Company = props => {
  const [view, setView] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [editable, setEditable] = useState({
    loaded: '',
    redirect: false
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios(FetchAllIndustry);
        setIndustries((prev) => data.data.map((element) => ({label: element.name, value: element._id})))

        const result = await axios(FetchAllCompany(localStorage.getItem('jwtID')));
        setEditable((prev) => ({
          ...prev,
          loaded: result.data
        }))
      } catch (error) {
        console.log('fetch error - ', error)
      }
    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);


  const company = editable.loaded ?
                  editable.loaded.Error? [] :
                  editable.loaded.map(item=>item.companyName) : [] //only getting the "name value" in an array
                  
  const schema = Yup.object().shape({
    companyName: Yup.string().required("Must Define a Name")
                      .min(3, "Minimum 3 characters")
                      .notOneOf(company, "Already exists"),
    industryId: Yup.string().required("Please select one...")
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      createdByCompanyID: localStorage.getItem("jwtID"),
      companyName:"" , 
      industryId: '',
      status: true
    }),

    handleSubmit: (values, { setSubmitting }) => {
      values.industryId=values.industryId.value
      axios.post(AddNewCompany, values)
      .then((response) => {
        if(!response.data.Error){
          setView(true)
        }
        else {
          window.alert("Uexpected Error! Duplicate Name found!")
        }
      })
      .catch((err) => {
        // console.log("Error")
      })

    }
  })(Form)


  return (
    <>
      <Body>
        <Modal
          view={view}
          onClick={event => {
            event.preventDefault()
            if (event.target === event.currentTarget)
            {
              setView(false)
          }
          }}
        >
          <Alert close={setView}/>
        </Modal>
        <FormikForm options={industries} />
      </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </>
  )
}

export default memo(Company)