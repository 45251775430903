import Axios from 'axios';

import {
  FETCH_RESPONSE,
  RECIEVE_RESPONSE,
  SET_IDENTIFICATION,
  RESET,
  FORMAT_BY_QUESTION,
} from '../constants/actions.js'
import { fetchDevice, fetchResponse, fetchLink, qrCodeLink } from '../../../../../url'


export const initialize = (projectId, id) => {
  return {
    type: SET_IDENTIFICATION,
    id,
    projectId
  }
}

export const reset = () => {
  return {
    type: RESET
  }
}

export const requestResponse = () => {
  return {
    type: FETCH_RESPONSE
  }
}

export const recieveResponse = (response, device, openLink, qrCode) => {
  return {
    type: RECIEVE_RESPONSE,
    response,
    device,
    openLink,
    qrCode
  }
}

// const getResponse = ()=>Axios.get("http://localhost:3530/api/response/project/5d0f0935d3e63c3698f195d4")

const getResponse = (projectId) => {
  // console.log(fetchResponse(projectId))
  return Axios.get(fetchResponse(projectId))
}
// const getQuestion =  ()=>Axios.get("http://localhost:3530/api/survey/project/5d0f0935d3e63c3698f195d4")

// const getQuestion =  (projectId)=>{
//   console.log(fetchQuestionUrl(projectId))
//   return Axios.get(fetchQuestionUrl(projectId))}
// const getDevice =  ()=>Axios.get("http://localhost:3530/api/device/list/5cd7a8b8fdf62829d3caab9d")

const getDevice = (id) => {
  // console.log(fetchDevice(id))
  return Axios.get(fetchDevice(id))
}

const getOpenLink = (companyId) => {
  return Axios.get(fetchLink(companyId))
}

const getQrCodeLink = (userId) => {
  // console.log('user', userId);
  return Axios.get(qrCodeLink(userId))
}

export const fetchData = (projectId, id) => {
  return dispatch => {

    dispatch(requestResponse())

    Axios.all([getResponse(projectId), getDevice(id), getOpenLink(id), getQrCodeLink(id)])
      .then(Axios.spread((response, device, openLink, qrCode) => {
        // console.log('device', device); console.log('qrcode', qrCode);
        if (response.data.length === 0) {
          dispatch(recieveResponse(
            [], [], [], []
          ))
        } else {
          const newResponse = response.data.filter(item => {
            const type = Array.isArray(item.answers) ? "array" : "object" //take only data in the structure ofthe new schema for consistency
            // console.log(typeof item.responseDate)
            return type === "object"
          }).map(item => {
            const {
              _id,
              channelType,
              responseDate,
              channelID,
              answers
            } = item
            const answersKeys = Object.keys(answers)
            if (answersKeys.length !== 1) {
              return item
            } else {
              const newAnswers = answers[answersKeys[0]]
              return {
                _id,
                channelID,
                channelType,
                responseDate,
                answers: [...newAnswers]
              }
            }
          })
          dispatch(recieveResponse(newResponse, device.data, openLink.data, qrCode.data.datas))
          dispatch(formatByQuestion())
        }
      }),
        err => {
          console.log(err)
          dispatch(recieveResponse([], [], [], []))
        }
      )
  }
}

const formatByQuestion = () => {
  return {
    type: FORMAT_BY_QUESTION
  }
}