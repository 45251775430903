import React from 'react';

const Respondant = (props) => {
  const { respondants } = props;

  if (respondants.length < 1) {
    return (
      <p className='text-center text-danger'>
        <strong>No data found!</strong>
      </p>
    );
  }

  return (
    <table className='table table-sm'>
      <thead>
        <tr>
          <th className='text-center' scope='col'>
            #
          </th>
          <th className='text-center' scope='col'>
            Name
          </th>
          <th className='text-center' scope='col'>
            Email
          </th>
        </tr>
      </thead>
      <tbody>
        {respondants.map((respondant, index) => {
          return (
            <tr key={index}>
              <th className='text-center' scope='row'>
                {index + 1}
              </th>
              <td className='text-center'>{respondant.name}</td>
              <td className='text-center'>{respondant.email}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Respondant;
