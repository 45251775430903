import styled from 'styled-components'


// export const Arrow = styled.p` 
//   font-size: 15px; 
//   color: black; 
//   padding: 3px; 
  
//   position: absolute; 
//   right: 8px; 
//   display: inline; 
// `


export const Container = styled.div`
  // position: fixed;
  box-sizing: border-box;
  top: calc(60px + 10%);
  left: ${({ sidebarState }) => sidebarState ? '100px' : '175px'};
  /* left: 90px; */
  width: 100%;
  // height: 70vh;
  // z-index: 3;
  padding: 8px 8px;
  border-radius: 4px;
  margin-top: 15px;
  background: #EFF3F4;
  // box-shadow: 1px 1px 10px 2px grey;
  transition: left 0.2s ease;
  // &:hover{
  //   /* left: 150px; */
  //   left: ${({ sidebarState }) => sidebarState ? '218px' : '300px'};
  } 

  // @media only screen and (max-width: 1300px) { 
  //   left: ${({ sidebarState }) => sidebarState ? '80px' : '-40px'};
  //   &:hover{ left: ${({ sidebarState }) => sidebarState ? '200px' : '70px'} };
  // } 

  // @media only screen and (max-width: 1200px) { 
  //   /* left: 65px; 
  //   &:hover{ left: 130px; }  */

  //   left: ${({ sidebarState }) => sidebarState ? '65px' : '-50px'};
  //   &:hover{ left: ${({ sidebarState }) => sidebarState ? '192px' : '80px'} };
  // } 

  // @media only screen and (max-width: 1050px) { 
  //   left: ${({ sidebarState }) => sidebarState ? '30px' : '-70px'};
  //   &:hover{ left: ${({ sidebarState }) => sidebarState ? '165px' : '60px'} };
  // } 

  // @media only screen and (max-width: 768px){ 
  //   top: calc(60px + 10%); 
  //   left: ${({ sidebarState }) => sidebarState ? '-75px' : '60px'}; 
  //   &:hover{ left: ${({ sidebarState }) => sidebarState ? '55px' : '190px'} }; 
  // } 

  // @media only screen and (max-width: 600px){ 
  //   top: calc(60px + 10%); 
  //   width: 120px; 
  //   left: -100px; 
  //   &:hover{ left: 0px; } 
  // } 
`









