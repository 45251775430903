import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import './style.css'
import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, startDate } from '../../redux/actions'

import { QuestionCard, Fields, FieldItem, QuestionTitle } from '../styles'

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const Checkbox = props => {
  const { data, updateData, answer, startDate } = props

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  const handleChange = event => {
    const { value } = event.target
    let newAnswer
    if (answer.some(element => element === value)) {
      newAnswer = answer.filter(element => element !== value)
    } else {
      newAnswer = [...answer]
      newAnswer.push(value)
    }
    updateData(newAnswer)
  }

  const checkFields = data.options.map((option, index) => {
    return (
      <div key={`option-${data.id}-${index}`}>
        <input
          type='checkbox'
          name={data.id}
          id={index}
          value={option}
          onChange={handleChange}
          className="checkboxres"
          defaultChecked={answer.includes(option)}
        />
        {/* {console.log('option', option, 'answer', answer)} */}
        <label htmlFor={index}>
          {option}
        </label>
      </div>
    )
  })

  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        {checkFields}
      </Fields>
    </QuestionCard>)
}

Checkbox.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state)
  }
}

const mapDispatchToProps = {
  updateData,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(Checkbox))