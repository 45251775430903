
import {
    CREATE_BRAND, GET_ALL_BRAND, 
    GET_SINGLE_BRAND, UPDATE_BRAND, 
    DELETE_BRAND, ERROR_IN_BRAND, 
    BACK_BUTTON_IN_DETAIL_PAGE, 
    COMPANY_DATA, CATEGORY_DATA, 
    CATEGORY_DATA_TRUE_ONLY
} from '../actions/actionTypes'

const init = { 
    allData: [], 
    dataDetails: {}, 
    errorText: '', 
    companys: [], 
    category: [], 
    categoryTrueOnly: [] 
} 


const brandCategoryReducer = (state = init, action) => { 
    switch (action.type) { 
        case ERROR_IN_BRAND: { 
            return { 
                ...state, 
                errorText: 'Server Error' 
            } 
        } 
        case GET_ALL_BRAND: { 
            return {
                ...state, 
                allData: action.payload
            }
        } 
        case COMPANY_DATA: { 
            return {
                ...state, 
                companys: action.payload
            }
        } 
        case CATEGORY_DATA: { 
            return {
                ...state, 
                category: action.payload
            }
        } 
        case CATEGORY_DATA_TRUE_ONLY: { 
            return {
                ...state, 
                categoryTrueOnly: action.payload, 
            }
        } 
        case GET_SINGLE_BRAND: { 
            return { 
                ...state, 
                dataDetails: action.payload 
            } 
        } 
        case BACK_BUTTON_IN_DETAIL_PAGE: { 
            return { 
                ...state, 
                dataDetails: {}, 
                categoryTrueOnly: []
            } 
        } 
        case UPDATE_BRAND: { 
            let data = state.allData.data.map(obj => { 
                if(obj._id === action.payload._id) { 
                    return action.payload 
                } 
                return obj 
            }) 
            let datas = { count: data.length, data } 
            return { 
                ...state, 
                allData: datas, 
                dataDetails: action.payload, 
                dataDetails: {}, 
                categoryTrueOnly: []
            } 
        } 
        case DELETE_BRAND: { 
            let data = state.allData.data.filter(obj => { 
                if(obj._id !== action.payload.state._id) { return obj } 
            }) 
            let datas = { count: data.length, data } 

            return { 
                ...state, 
                allData: datas, 
                dataDetails: {}, 
                categoryTrueOnly: []
            } 
        } 
        case CREATE_BRAND: { 
            const allDatas = state.allData.data.concat(action.payload) 
            return { 
                ...state, 
                allData: {
                    count: allDatas.length, 
                    data: allDatas 
                }
            } 
        } 
        default: return state 
    } 
} 

export default brandCategoryReducer; 
