import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTable, usePagination } from 'react-table'
import { Link, withRouter } from 'react-router-dom'
import { Scrollbars } from 'react-custom-scrollbars';
import { Table, Input } from 'reactstrap'
import './index.css'


function TableCompo(props) {
  let { columns, data } = props;

  const {
    getTableProps, getTableBodyProps, headerGroups, prepareRow, page, canPreviousPage, canNextPage,
    pageOptions, pageCount, gotoPage, nextPage, previousPage, setPageSize, state: { pageIndex, pageSize },
  } = useTable({ columns, data, initialState: { pageIndex: 0 } }, usePagination)

  useEffect(() => { setPageSize(15) }, []) 

  return ( 
    <div> 
      {/* Table */} 
      <Scrollbars autoHide style={{ height: data.length === 0 ? '300px' : '65vh' }}> 
        <Table hover {...getTableProps()} style={{ marginBottom: '0px', lineHeight: '100%' }}>
          <thead>
          {
            headerGroups.map((headerGroup, i) => (
              <tr className="headerOfDistribution"
                {...headerGroup.getHeaderGroupProps()} key={`id: ${i}`}>
                { 
                  headerGroup.headers.map((column, i) => ( 
                    <th 
                      {...column.getHeaderProps()} 
                      key={`id: ${i}`}> 
                      {column.render('Header')} 
                    </th> 
                  )) 
                } 
              </tr> 
            )) 
          } 
          </thead>
          <tbody className="rowHover" {...getTableBodyProps()} >
            {
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} key={`id: ${i}`}>
                    {
                      row.cells.map((cell, j) => {
                        return (
                          <React.Fragment key={`id: ${j}`}>
                            {
                              j === 0
                                ? 
                                <td id="clickable" 
                                  onClick={() => props.history.push(`/app/distribution/edit/${row.original._id}`)}
                                  {...cell.getCellProps()} key={`id: ${j}`}> 
                                  {cell.render('Cell')} 
                                </td> 
                                :
                                typeof (cell.value) === 'boolean'
                                  ?
                                  <td {...cell.getCellProps()} key={`id: ${i}`}> 
                                    <input type="checkbox" readOnly={true} checked={cell.value} />
                                  </td>
                                  :
                                  <td {...cell.getCellProps()} key={`id: ${j}`}>
                                    {cell.render('Cell')} 
                                  </td> 
                            }
                          </React.Fragment>
                        )
                      })
                    }
                  </tr>
                )
              })
            }
          </tbody>
        </Table>
      </Scrollbars> 

      {/* Pagination */}
      <div style={{ position: 'sticky', bottom: '15px' }}>
      { 
        data.length > 5 ?
          <div className="pagination mt-3">
            <>
              <button 
              style={{ height: '28px', background: '#EEEEEE', padding:'0px 10px', borderRadius:'3px', border: 'none', marginRight: '10px' }} 
              onClick={() => gotoPage(0)} disabled={!canPreviousPage}> 
              <i className="fas fa-angle-double-left"></i> 
              </button> 
              <button 
              style={{ height: '28px', background: '#EEEEEE', padding:'0px 10px', borderRadius:'3px', border: 'none', marginRight: '10px' }} 
              onClick={() => previousPage()} disabled={!canPreviousPage}>
              <i className="fas fa-angle-left"></i>
              </button> 
              <button 
              style={{ height: '28px', background: '#EEEEEE', padding:'0px 10px', borderRadius:'3px', border: 'none', marginRight: '10px' }} 
              onClick={() => nextPage()} disabled={!canNextPage}>
              <i className="fas fa-angle-right"></i>
              </button> 
              <button 
              style={{ height: '28px', background: '#EEEEEE', padding:'0px 10px', borderRadius:'3px', border: 'none', marginRight: '10px' }} 
              onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              <i className="fas fa-angle-double-right"></i>
              </button> 
            </> 
                    
            <span style={{color: '#424242', marginRight: '10px', marginTop: '2px'}}>
                page{' '} 
                <span style={{color: '#212121', fontWeight:'500'}}> 
                {pageIndex + 1} of {pageOptions.length} 
                </span>{' '}
            </span> 

            <span style={{color: '#424242', marginRight: '10px', lineHeight: '23px'}}> 
                | go to page:{' '} 
                <input
                type="number" defaultValue={pageIndex + 1}
                onChange={e => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0
                    gotoPage(page)
                }}
                style={{ color:'#212121', fontWeight:'700', width: '80px', height:'25px', padding:'0px 8px', border:'1px solid #BDBDBD', borderRadius:'3px' }}
                />
            </span>{' '} 

            <select
              value={pageSize}
              style={{ height: '25px', background: '#FFF', border: '1px solid #BDBDBD', borderRadius: '3px' }}
              onChange={e => { setPageSize(Number(e.target.value)) }}>
              {
              [5, 10, 15, 20, 30, 40, 50, 75, 100].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                  </option>
              ))
              }
          </select>
          </div>
        : null 
      } 
      </div> 
    </div> 
  ) 
} 

export default withRouter(TableCompo); 
