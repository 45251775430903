import React from 'react'
import Select from 'react-select'
import {connect} from 'react-redux'

import {contactCard as config} from '../../../../config'
import {changeCardOptions} from '../../../../redux/actions'
import {activeContactFieldsSelector} from '../../../../redux/selectors'

const Options = props=>{
  const {changeCardOptions, activeFields} = props
  return(
    <Select
      // className='react-select-container'
      // classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base=>{
          return{
            ...base,
            margin: "10px",
            // minHeight: "1px !important",
            // height: "25px",
            width: "100%",
          }
        },
        control: (base) => {
          return {
            ...base,
            // boxShadow: "1px 1px 5px 1px #D9D9D9",
            // padding: "1px 2px",
            background: "white",
            ':hover':{
              ...base[':hover'],
              // boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active':{
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style=>{
          return{
            ...style,
            padding: "5px"
          }
        }
      }}
      isMulti
      id="contactCard"
      // isClearable={true}
      placeholder="Select an option"
      isSearchable={false}
      options={config.fieldList}
      value={activeFields}
      onChange={selected=>{
        if(selected){
          changeCardOptions(selected)
        }else{
          changeCardOptions([])
        }
      }} 
    />
  )
}

const mapStateToProps = state=>({
  activeFields: activeContactFieldsSelector(state)
})

const mapDispatchToProps = {
  changeCardOptions: options=>changeCardOptions(options)
}

export default connect(mapStateToProps, mapDispatchToProps)(Options)