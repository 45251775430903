export const libraryUpdate = (ques, library)=>{
  switch(ques.type){
    case "multipleChoice":
      return {
        ...ques,
        choices: [...library]
      }
    case "checkbox":
      return {
        ...ques,
        options: [...library]
      }
    case "likertScale":
      var ratings
      console.log("library:", library);
      switch(ques.scale){
        case 7:
          ratings = [...library]
          return{
            ...ques,
            ratings: [...ratings]
          }
        case 5:
          ratings = [library[0],library[1],library[3],library[5],library[6]]
          return{
            ...ques,
            ratings: [...ratings]
          }
        case 3:
          ratings = [library[1],library[3],library[5]]
          return{
            ...ques,
            ratings: [...ratings]
          }
      }  
    default:
      return {
        ...ques
      }
  }
}