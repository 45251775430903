import React, { useEffect } from 'react'
import Modal from './Modal'
// import Can from '../../../../components/Can'
import TableView from '../../components/TableView/index'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { useDispatch } from 'react-redux'
// import Toast from '../../components/Toast'
import { resetStore } from '../../redux/actions'
import { Link } from 'react-router-dom'
const List = props => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(resetStore())
    }, [])


    //         {/* <Can
    //             feature="project"
    //             perform="create"
    //             yes={() => (
    //                 <Link style={{ background: 'rgb(52, 58, 64' }} to="/app/project/create">
    //                     Create Project
    //                 </Link>
    //             )}
    //         /> */}

    return <div className="container">
        <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link>
        <Modal buttonLabel="Create New" />
        <TableView />
    </div>
}

export default List;