import styled from 'styled-components'

export const Body = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 100%;
  top: 0;
  left: 0;
`