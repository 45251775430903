import React from 'react'
import { connect } from 'react-redux'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { projectOrderSelector } from '../../redux/selectors'
import { Container, ProjectContainer, ProjectTitle, QuestionList } from './styles'
import ProjectQuestions from '../../components/ProjectQuestions'
import ContactCard from '../../components/AdditionalCards/ContactCard'

const Questions = ({ questionList, projectId }) => {
  return questionList.map((question, index) => {
    return (
      <ProjectQuestions
        key={`question_${question.id}`}
        question={question}
        index={index}
        projectId={projectId}
      />
    )
  })
}

const ProjectGroup = ({ projectList }) => {

  const projects = projectList.map((item, index) => (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided, snapshot) => {
        switch (item.type) {
          case 'project': {
            return (
              <ProjectContainer
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <ProjectTitle
                  {...provided.dragHandleProps}
                >
                  Project Name: {item.projectName}
                </ProjectTitle>
                <Droppable droppableId={item.id} type={`Project_${item.id}`}>
                  {(provided, snapshot) => (
                    <QuestionList
                      isDraggingOver={snapshot.isDraggingOver}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Questions questionList={item.questionList} projectId={item.id} />
                      {provided.placeholder}
                    </QuestionList>
                  )}
                </Droppable>
              </ProjectContainer>
            )
          }

          case 'contactCard': {
            return (
              <ContactCard
                provided={provided}
                snapshot={snapshot}
                fields={item.fields}
                id={item.id}
              />)
          }

          default: return null
        }
      }}
    </Draggable>
  ))

  return (
    <Droppable droppableId="projectBody" type="projects">
      {(provided, snapshot) => (
        <Container
          isDraggingOver={snapshot.isDraggingOver}
          ref={provided.innerRef}
          {...provided.droppableProps}
        >
          {projects}
          {provided.placeholder}
        </Container>
      )}
    </Droppable>
  )
}

const mapStateToProps = state => ({
  projectList: projectOrderSelector(state)
})

export default connect(mapStateToProps)(ProjectGroup)