import styled from 'styled-components'

export const QuestionCard = styled.div`
  max-width: 800px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 15px;
  margin: 0 auto;
  width: 100%;
  height: auto;
  background: white;
  border-radius: 4px;
  @media (max-width: 767px) {
    width: 100%;
    padding: 25px 0px;
  }
`

export const Fields = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  align-items: flex-start;
  width: 100%;
`

export const FieldItem = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 2px;
  >input{
    margin: 5px;
  }
`

export const QuestionTitle = styled.h2`
  width: 100%;  
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
  word-break:break-word;
`