import React, { useState } from 'react';
import { withRouter, NavLink, Link } from 'react-router-dom'
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, 
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarText,
  Card, CardBody, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label } from 'reactstrap';
import Logo from './asset/ProlificXM.png' 
import { css } from 'glamor' 
import ReqDemoModal from '../ReqDemo/ReqDemoModal' 
import SignupModal from '../../../Pricing/components/modal/modal' 
import './nav.css' 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

function NavigationBar(props) { 
  let title = props.match.params.title; 
  
  const [isOpen, setIsOpen] = useState(false); 
  const [reqDemo, setReqDemo] = useState(false); 
  const toggle = () => setIsOpen(!isOpen); 
  const cancelModal = () => setReqDemo(false); 
  const [showFreeModal, setShowFreeModal] = useState(false) 
  const [successMsgModal, setSuccessMsgModal] = useState(false) 

  const menuHover = css({ ':hover': {textDecoration: 'none', color: '#1B998B !important'} }) 
  const menuTopLinks = css({color: 'rgb(52, 58, 64)', fontSize: '13px', color: '#1B998B', marginRight: '15px'}) 
  const dropdownListHover = css({ ':hover': { background: '#1B998B !important', color: '#fff !important' } }) 
  const dropdownListCss = css({ paddingTop: '3px !important', paddingBottom: '3px !important', ':hover': {color: 'white'} }) 
  const navbarCSS = css({
    position: 'sticky', top: '0', zIndex: '9999', 
    borderBottom: '1px solid rgba(200, 200, 200, 0.25) !important', 
  }) 
  const navTopListCss = css({ '@media(max-width: 765px)': { marginTop: '10px !important' } }) 
  const navTopListFirstChildCss = css({ '@media(max-width: 765px)': { marginTop: '50px !important' } }) 

  const closeModal = (msg = null) => { 
    if(msg) {
      setSuccessMsgModal(true)
    } 
    setShowFreeModal(false)
  } 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' } 
  } 

  let rout = props.location.pathname.split('/') 
  
  let currentRoute = ''
  if (rout.length === 2) { currentRoute = rout[1] }
  else if (rout.length >= 3) { currentRoute = rout[1] }

  
  
  return ( 
    <div {...navbarCSS}> 
      <Navbar color="light" light expand="md"> 
        <div className="container"> 
          <Link to="/" style={{ position: 'relative', top: '4px' }}> 
            <img height="40px" src={Logo}/> 
          </Link> 

          <NavbarToggler onClick={toggle} /> 

          <Collapse className="mobileCollapse" isOpen={isOpen} navbar 
            style={{ paddingBottom: isOpen ? '1rem' : '0rem' }}> 
            <Nav navbar style={{ position: 'relative' }}> 
              <UncontrolledDropdown nav inNavbar style={{marginTop: '-8px', marginRight: '12px'}}> 
                <DropdownToggle nav caret style={{color: '#000'}}> 
                  <span {...menuHover} onClick={() => props.history.push("/prolificxm-features")}
                    style={{
                      color: currentRoute === 'prolificxm-features' ? '#1B998B' : 'rgb(52, 58, 64)', 
                      borderBottom: currentRoute === 'prolificxm-features' ? '2px solid #1B998B' : 'none', 
                      fontSize: '14px'
                    }}> 
                    Our Solutions 
                  </span> 
                </DropdownToggle> 
                <DropdownMenu right> 
                  <NavLink to="/prolificxm-features/Customer-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Customer-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Customer-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}> 
                      Customer Experience 
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Brand-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}> 
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Brand-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Brand-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Brand Experience 
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Product-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss} 
                      style={{
                        backgroundColor: title === 'Product-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Product-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Product Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Employee-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Employee-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Employee-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Employee Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Professional-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Professional-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Professional-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Professional Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Education-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Education-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Education-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Education Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Community-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Community-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Community-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}> 
                      Community Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} />
                  <NavLink to="/prolificxm-features/Event-Experience"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}>
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Event-Experience' ? '#1B998B' : 'transparent',
                        color: title === 'Event-Experience' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Event Experience
                    </DropdownItem> 
                  </NavLink> 

                  <DropdownItem divider style={{marginBottom: '0', marginTop: '0'}} /> 
                  <NavLink to="/prolificxm-features/Random-Survey"
                    style={{color: 'rgb(52, 58, 64)', fontSize: '14px'}}> 
                    <DropdownItem {...dropdownListHover} {...dropdownListCss}
                      style={{
                        backgroundColor: title === 'Random-Survey' ? '#1B998B' : 'transparent',
                        color: title === 'Random-Survey' ? '#fff' : 'rgb(52, 58, 64)'
                      }}>  
                      Random Survey 
                    </DropdownItem> 
                  </NavLink> 
                  
                </DropdownMenu> 
              </UncontrolledDropdown> 

              <NavItem> 
                <NavLink to="/pricing" {...menuHover} 
                  style={{color: props.match.url === '/pricing' ? '#1B998B' : 'rgb(52, 58, 64)', marginRight: '15px', 
                  borderBottom: props.match.url === '/pricing' ? '2px solid #1B998B' : 'none', 
                  fontSize: '14px', position: 'relative', top: '3px'
                }}> 
                  Pricing 
                </NavLink> 
              </NavItem> 
              <NavItem> 
                <NavLink to="/blog" {...menuHover} 
                  style={{color: currentRoute === 'blog' ? '#1B998B' : 'rgb(52, 58, 64)', 
                  borderBottom: currentRoute === 'blog' ? '2px solid #1B998B' : 'none', fontSize: '14px', 
                  position: 'relative', top: '3px', zIndex: '999'
                }}> 
                  Blog 
                </NavLink> 
              </NavItem> 
            </Nav> 



            <Nav className="ml-auto" navbar style={{ position: 'relative', top: '-8px' }}>
              {localStorage.jwtToken ? (
                <NavItem>
                  <div className="userIcon" style={{ width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={() => { props.history.push('/app') }}>
                    <i style={{ color: '#1B988B' }} className="far fa-user"></i>
                  </div>
                </NavItem>
              ) : (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <NavItem {...navTopListFirstChildCss} className="secondary-menu-link"> 
                    <span {...menuTopLinks} 
                      style={{background: '#1B998B', cursor: 'pointer', padding: '4px 8px', color: '#fff', borderRadius: '5px'}} 
                      className="btnHover" onClick={() => setShowFreeModal(true)}> 
                      Free Account 
                    </span> 
                  </NavItem> 
                  <NavItem {...menuHover} {...menuTopLinks} {...navTopListCss}
                    style={{marginTop: '2px', cursor: 'pointer'}}
                    className="secondary-menu-link"
                    onClick={() => setReqDemo(!reqDemo)}> 
                    Request Demo 
                  </NavItem> 
                  <NavItem {...navTopListCss} className="secondary-menu-link"> 
                    <NavLink {...menuHover} {...menuTopLinks} to="/signin/">
                      Log In
                    </NavLink> 
                  </NavItem>
                </div>
              )}
            </Nav> 
          </Collapse> 
        </div> 
      </Navbar> 
      
      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 

      {/* Signup Modals for free, basic, standard, pro, advanced */} 
      <> 
        { showFreeModal && <SignupModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} /> } 
      </> 

      {/* Signup success message */} 
      <> 
        <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          {/* { closeModal() }  */}
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!!
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 
    </div> 
  ) 
} 

export default withRouter(NavigationBar); 
