import styled from 'styled-components'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  width: 300px;
  margin: 0;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #310E31;
`

export const Message = styled.div`
  box-sizing: border-box;
  position: relative;
  text-align: center;
  word-wrap: break-word;
  font-size: 1.5em;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
`
export const ButtonBody = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
  width: 100%;
`

export const Button = styled.button`
  position: relative;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  color: #310E31;
  border: none;
  padding: 5px 15px;
  margin: 0;
  text-align: center;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s;
  :hover{
    background-color: grey;
  }
`