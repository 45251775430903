import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'

import Mcq from '../Mcq'
import Checkbox from '../Checkbox'
import LikertScale from '../LikertScale'
import TextBox from '../TextBox'
import Dropdown from '../Dropdown'
import Dicotomy from '../Dicotomy'
import Thumbsup from '../Thumbsup'
import Ratingscale from '../Ratingscale'
import Starrating from '../Starrating'
import Loverating from '../Loverating'
import Nps from '../Nps'
import Thumbsuprating from '../Thumbsuprating'

import { Options, OptionItem, SelectOptions } from './styles'

import {
  toggleConditional,
  toggleRequired,
  toggleConditionalPreview,
  setQuestionForConditionalPreview,
  setOptionForConditionalPreview,
  conditionalPreview
} from '../../../redux/actions'

const Selector = props => {

  return (
    <Select
      {...props}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base => {
          return {
            ...base,
            margin: "5px 0",
            // minHeight: "1px !important",
            height: "25px",
            width: "100%",
            flexShrink: 0
          }
        },
        control: (base) => {
          return {
            ...base,
            // boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover': {
              ...base[':hover'],
              // boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active': {
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style => {
          return {
            ...style,
            padding: "0 5px"
          }
        }
      }}
      // id="channel"
      // isClearable={true}
      // placeholder="Question"
      isSearchable={false}
    // options={options}
    // value={value}
    // onChange={onChange} 
    />
  )
}

const QuestionBody = props => {

  // const [optionsList, setOptionsList] = useState([])

  const {
    question,
    projectId,
    questionsAfter,
    checkboxQuestionsBefore,
    jump,
    toggleConditional,
    toggleRequired,
    toggleConditionalPreview,
    setQuestionForConditionalPreview,
    setOptionForConditionalPreview,
    validateConditionalPreview,
    selectedQuestions,
    conditionalPreview } = props
  // console.log(question)
  // console.log(validateConditionalPreview)
  const handleRequiredClick = () => {
    toggleRequired(projectId, question.id)
  }

  const handleConditionalClick = () => {
    toggleConditional(projectId, question.id)
  }

  const handleConditionalPreviewClick = () => {

    // console.log('true .')
    toggleConditionalPreview(projectId, question.id)
    // console.log(selectedQuestions)
    let checkConditionalPreview = selectedQuestions;
    let cP = false
    for (let key in checkConditionalPreview) {

      let lists = checkConditionalPreview[key].map(item => {

        if (item.previewCondition.status === true) {
          if (item.previewCondition.optionIndex === null || item.previewCondition.questionId === "") {
            // console.log('true', item.previewCondition.questionId)
            cP = true;
          }
        }

        return item

      })

    }
    conditionalPreview(cP)

  }

  const handleConditionalQuestionSelect = selected => {
    const { value, data } = selected
    const formattedOptionsList = data ? data.map((item, index) => ({
      label: item,
      value: index
    })) : []
    // setOptionsList(formattedOptionsList)
    setQuestionForConditionalPreview(projectId, question.id, value, formattedOptionsList)
  }

  const handleConditionalOptionsSelect = selected => {
    setOptionForConditionalPreview(projectId, question.id, selected.value)
  }

  let conditionalQuestion, content //In the future change to functionality based on database
  // console.log(question)
  switch (question.type) {
    case "multipleChoice":
      content =
        <Mcq
          isConditional={question.isConditional}
          id={question.id}
          projectId={projectId}
          choices={question.choices}
          questionsAfter={questionsAfter}
          jump={jump}
        />
      conditionalQuestion = true
      break
    case "likertScale":
      content =
        <LikertScale
          isConditional={question.isConditional}
          id={question.id}
          projectId={projectId}
          scale={question.scale}
          ratings={question.ratings}
          questionsAfter={questionsAfter}
          jump={jump}
        />
      conditionalQuestion = true
      break
    case "checkbox":
      content = <Checkbox id={question.id} options={question.options} />
      conditionalQuestion = false
      break
    case "textBox":
      content = <TextBox />
      conditionalQuestion = false
      break
    case "dropDown":
      content = <Dropdown
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.options}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "dichotomy":
      content = <Dicotomy
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.options}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "thumbsUpDown":
      content = <Thumbsup
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.options}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "ratingScale":
      content = <Ratingscale
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.ratings}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "starRating":
      content = <Starrating
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.ratings}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "loveRating":
      content = <Loverating
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.ratings}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "nps":
      content = <Nps
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.ratings}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    case "thumbsUpRating":
      content = <Thumbsuprating
        isConditional={question.isConditional}
        id={question.id}
        projectId={projectId}
        choices={question.ratings}
        questionsAfter={questionsAfter}
        jump={jump}
      />
      conditionalQuestion = true
      break
    default:
      content = <></>
      conditionalQuestion = false
  }
  // console.log(conditionalQuestion)
  return (
    <>
      <Options>
        <OptionItem>
          <input
            id={`isRequired${question.id}`}
            name={`isRequired${question.id}`}
            type='checkbox'
            // disabled={question.dependant.length !== 0}
            checked={question.isRequired}
            onChange={handleRequiredClick}
            disabled={props.isReadOnly}
          />
          <label htmlFor={`isRequired${question.id}`} style={{ margin: "0 0 0 4px", fontSize: "12px", fontWeight: "500", color: "#424242", lineHeight: "14px" }} >
            Required
          </label>
        </OptionItem>
        {conditionalQuestion && questionsAfter.length !== 2 &&
          <OptionItem>
            <input
              id={`isConditional${question.id}`}
              name={`isConditional${question.id}`}
              type='checkbox'
              // disabled={question.optionsDisabled.conditional}
              checked={question.isConditional}
              onChange={handleConditionalClick}
              disabled={props.isReadOnly}
            />
            <label htmlFor={`isConditional${question.id}`} style={{ margin: "0 0 0 4px", fontSize: "12px", fontWeight: "500", color: "#424242", lineHeight: "14px" }} >
              Conditional
            </label>
          </OptionItem>
        }
        {checkboxQuestionsBefore.length > 1 &&
          <OptionItem>

            <input
              id={`conditionalPreview${question.id}`}
              name={`conditionalPreview${question.id}`}
              type='checkbox'
              checked={question.previewCondition.status}
              onChange={handleConditionalPreviewClick}
              disabled={props.isReadOnly}
            />
            <label htmlFor={`conditionalPreview${question.id}`} style={{ margin: "0 0 0 4px", fontSize: "12px", fontWeight: "500", color: "#424242", lineHeight: "14px" }} >
              Conditional Preview
            </label>

          </OptionItem>
        }
      </Options>
      {question.previewCondition.status && <>
        <Options>
          <SelectOptions>
            <Selector
              options={checkboxQuestionsBefore}
              onChange={handleConditionalQuestionSelect}
              value={checkboxQuestionsBefore.find(
                item => item.value === question.previewCondition.questionId) ||
                { label: 'None', value: '' }
              }
            />
            {validateConditionalPreview && question.previewCondition.questionId === "" &&
              <small style={{ color: 'red' }}>Required</small>}
          </SelectOptions>
          <SelectOptions>
            <Selector
              options={question.previewCondition.optionsList}
              onChange={handleConditionalOptionsSelect}
              value={question.previewCondition.optionsList.find(item => item.value === question.previewCondition.optionIndex)}
            />
            {validateConditionalPreview && question.previewCondition.optionIndex === null &&
              <small style={{ color: 'red' }}>Required</small>}
          </SelectOptions>
        </Options>
        <hr />
      </>}
      {content}
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { question, projectId, questionsAfter, jump, checkboxQuestionsBefore } = ownProps

  return {
    question,
    projectId,
    questionsAfter,
    checkboxQuestionsBefore,
    jump,
    // status: state.question.status,
    isReadOnly: state.question.isReadOnly,
    validateConditionalPreview: state.question.conditionalPreview,
    selectedQuestions: state.question.selectedQuestion
  }
}

// : (projectId, questionId)=>toggleRequired(projectId, questionId)
// : (projectId, questionId)=>toggleConditional(projectId,questionId)
const mapDispatchToProps = {
  toggleRequired,
  toggleConditional,
  toggleConditionalPreview,
  setQuestionForConditionalPreview,
  setOptionForConditionalPreview,
  conditionalPreview
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBody)