import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: background-color 0.5s ease;
  background-color: ${props=>props.isDraggingOver ? 'lightblue' : 'white'};
`

export const ProjectContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
  box-shadow: 1px 1px 5px 1px grey;
`

export const ProjectTitle = styled.h3`
  box-sizing: border-box;
  position: relative;
  text-align: center;
  padding: 10px;
  background-color: #310D31;
  color: white;
  margin: 0;
`

export const QuestionList = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 10px 0;
  transition: background-color 0.5s ease;
  background-color: ${props=>props.isDraggingOver ? 'lightgreen' : 'white'};
`