import React, { useState, useEffect } from 'react'
import { Card } from 'reactstrap'
import Table from './Table'
import showToast from '../../../../components/Toast/toast'

import { useSelector, useDispatch } from 'react-redux'
import { getAllMicroObjectives } from '../../store/actions/actionCreator'

function Index() {

    let dispatch = useDispatch()
    let { allData } = useSelector(state => state.microObjective)
    const ErrorToast = () => showToast('Server Error')

    useEffect(() => {
        dispatch(getAllMicroObjectives(ErrorToast))
    }, [])

    const columns = React.useMemo(() => [
        { Header: 'Name', accessor: 'name' },
        { Header: 'Default', accessor: 'isDefault' },
        { Header: 'Active', accessor: 'active' },
        { Header: 'Created Date', accessor: 'createdAT' },
        // { Header: 'Sequence', accessor: 'sequence' }
    ], []
    )

    return <div className="animated fadeIn">
        {
            allData.length === 0 ?
                <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                    <Table columns={columns} data={[]} />
                </Card>
                :
                <Card style={{ border: '0px', background: true ? 'transparent' : null }}>
                    <Table columns={columns} data={allData.reverse()} />
                </Card>
        }
    </div>
}

export default Index