import React, {useState} from 'react' 
import { Button } from 'reactstrap' 
import { Link, withRouter } from 'react-router-dom'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb'
import '../buttonCss.css'

function CreateButton() { 
    let [create, setCreate] = useState(); 
    return ( 
        <div> 
            <Breadcrumb path="Brand Category /" /> 
            <Link to={{ pathname: `/app/brandCategory/create` }}> 
                <Button style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 0px', padding:'4px 15px', fontSize:'13px', fontWeight:'500' }}
                    className="btnHover" size="sm" onClick={() => setCreate(!create)}> 
                    Create New
                </Button>
            </Link> 
        </div> 
    ) 
} 

export default withRouter(CreateButton); 
