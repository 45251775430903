import React from 'react'
import { connect } from 'react-redux'
import { ThemeProvider, css } from 'styled-components'
import { FormClose, base } from 'grommet-icons'
import { deepMerge } from 'grommet-icons/utils'

import { deleteField } from '../../redux/actions/modifyQuestion'

const customTheme = deepMerge(base, {
  icon: {
    extend: css`
      ${() => `
        stroke: #FFF;
        background:#F27D7D;
        border-radius:3px;
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
        transition: all 0.3s cubic-bezier(.25,.8,.25,1);
        &:hover{
          background:#EF233C;
          cursor: pointer;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
      `}
    `
  }
})


const DeleteFieldButton = ({ deleteField, qId, fId }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <FormClose size="20px" onClick={() => deleteField(qId, fId)} />
    </ThemeProvider>
  )
}

//You can add dispatch like this too: 
//https://dev.to/mcrowder65/you-ve-been-doing-mapdispatchtoprops-wrong-this-entire-time-582m
const mapDispatchToProps = {
  deleteField: deleteField
}

export default connect(null, mapDispatchToProps)(DeleteFieldButton)