import React, { memo } from 'react';
import styled from 'styled-components/macro'
import whyDidYouRender from '@welldone-software/why-did-you-render'


//Nothing
// import Logo from '../../resources/PEX New.png'
import Logo from '../../../../resources/ProlificXM.png'
import { Body } from './styles'

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const PoweredByPEX = () => {

  return (
    <Body>
      {/* <a href="http://www.prolificanalyticsinc.com/pex" target="_blank" rel='noopener noreferrer' css={`color: rgb(27, 153, 139);`}>
        Powered by
      </a> */}
      <span css={`color: #b9b9b9;`}>Powered by</span>
      <a href="http://www.prolificxm.com/" target="_blank" rel='noopener noreferrer'>
        <img
          draggable="false"
          alt="PEX Logo"
          src={Logo}
          css={`
              width:30x;
              height:25px; 
              margin-left:10px;
              `}
        />
      </a>
    </Body>
  )
}

PoweredByPEX.whyDidYouRender = true

export default memo(PoweredByPEX);






