import React, { useEffect } from 'react'
import { MainBody } from './styles'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList/createIndex'
import NextButton from '../../components/NextButton'
// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { useSelector, useDispatch } from 'react-redux';
import { selectProjectAndFetch } from '../../redux/actions/index'
import Step from '../../components/Step/index'
// import { Link } from 'react-router-dom'

const SelectPage = props => {
    let projectId = useSelector(state => state.survey.projectId)
    let dispatch = useDispatch()

    useEffect(() => {
        if (projectId) {
            dispatch(selectProjectAndFetch(projectId))
        }
    }, [projectId])

    return (
        <MainBody>

            <div className="container">
                {/* <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link> */}
                <Step />
                <Configuration />
                <QuestionList />
            </div>
            <NextButton disid={null} />

        </MainBody>
    )
}

export default SelectPage