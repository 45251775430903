import React from 'react'; 
import CreateButton from './Create/CreateButton'; 
import TableView from './TableView'; 
import SmallFooter from '../../smallFooter/Footer'; 

const Feature = () => { 
  return ( 
    <div className="container"> 
      <CreateButton /> 
      <TableView /> 
      {/* <SmallFooter />  */}
    </div> 
  ) 
} 

export default Feature
