export const mcq = {
  id: "",
  type: "multipleChoice",
  title: "",
  choices: [],
  objectives: []
}

export const dropdown = {
  id: "",
  type: "dropDown",
  title: "",
  options: [],
  objectives: []
}

export const dichotomy = {
  id: "",
  type: "dichotomy",
  title: "",
  options: ["Yes", "No"],
  objectives: []
}

export const thumbsUpDown = {
  id: "",
  type: "thumbsUpDown",
  title: "",
  options: ["ThumbsUp", "ThumbsDown"],
  objectives: []
}


export const checkbox = {
  id: "",
  type: "checkbox",
  title: "",
  options: [],
  objectives: []
}

export const likertScale = {
  id: "",
  type: "likertScale",
  title: "",
  scale: 5,
  ratings: ["", "", "", "", ""],
  objectives: []
}

export const ratingScale = {
  id: "",
  type: "ratingScale",
  title: "",
  scale: 5,
  ratings: ["1", "2", "3", "4", "5"],
  objectives: []
}

export const starRating = {
  id: "",
  type: "starRating",
  title: "",
  scale: 5,
  ratings: ["1", "2", "3", "4", "5"],
  objectives: []
}

export const thumbsUpRating = {
  id: "",
  type: "thumbsUpRating",
  title: "",
  scale: 5,
  ratings: ["1", "2", "3", "4", "5"],
  objectives: []
}

export const loveRating = {
  id: "",
  type: "loveRating",
  title: "",
  scale: 5,
  ratings: ["1", "2", "3", "4", "5"],
  objectives: []
}

export const nps = {
  id: "",
  type: "nps",
  startLabel:"Not at all likely",
  endLabel:"Extremely likely",
  title: "",
  ratings: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  objectives: []
}

export const textBox = {
  id: "",
  type: "textBox",
  title: "",
  text: "singleLine",
  objectives: []
}

export const demography = {
  id: "",
  type: "demography",
  title: "",
  objectives: [],
  fields: {
    name: true,
    gender: true,
    'contact number': false,
    email: false,
    age: false,
    'birth date': false,
    district: "",
    "postal code": "",
    profession: "",
    "organization/institution": ""
  }
}