import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleDown} from '@fortawesome/free-solid-svg-icons'

import {Body} from './styles'

const ToggleMinimize = props=>{

  return(
    <Body>
      <FontAwesomeIcon icon={faChevronCircleDown}/>
    </Body>
  )
}

export default ToggleMinimize