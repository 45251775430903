import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Table from './Table'
import { Card } from 'reactstrap'; 
import baseUrl from '../../../url';

function Index() { 
  const [user, setUser] = useState([]); 

  useEffect(() => { 
    axios.get(`${baseUrl}/api/login/all/${localStorage.getItem('jwtProfileId')}`) 
      .then(response => { 
        const data = response.data.map(d => { 
          return { firstName: d.firstName, email: d.email, _id: d._id, roleName: d.roleInfo.roleName, active: d.active } 
        }) 
        setUser(data); 
      }) 
      .catch(error => console.log(error)) 
  }, []) 

  const columns = React.useMemo(() => [  
    { Header: 'Name',   accessor: 'firstName' }, 
    { Header: 'Email',  accessor: 'email' }, 
    { Header: 'Role',   accessor: 'roleName' }, 
    { Header: 'Active', accessor: 'active' } 
  ], []) 

  return ( 
    <div className="animated fadeIn w-100"> 
    { 
      user.length === 0 ? 
        <Card style={{ border: '0px', margin: '18px 0'}}> 
          <Table columns={columns} data={[]} /> 
        </Card> 
        : 
        <Card style={{ border: '0px', margin: '9px 0'}}> 
          <Table columns={columns} data={user} /> 
        </Card> 
    } 
    </div> 
  ) 
} 

export default Index; 



