// To display the objectives and tag different questions
import React, { useState } from 'react'
import { connect } from 'react-redux'

import { Body, Label, Heading } from './styles'
import { addObjective } from '../../redux/actions/modifyQuestion'

import Select from 'react-select'
import collect from 'collect.js'
import './style.css'

const QuestionCardObjectives = props => {

  const { id, selected, objectives, add, isReadOnly } = props

  // console.log(objectives)

  const objectiveOptions = objectives.map(element => ({ label: element.name, value: element.id }))
  const selectedObj = objectiveOptions.filter(element =>   //returns as objectiveOptions format... label, value .. if true
  {
    return selected.some(sel => sel.id === element.value)
  })


  const handleOnChange = (value) => {

    let newObjectives = objectiveOptions.filter(element => {
      return value ? value.some(sel => sel.value === element.value) : null   //throws error if value is null
    })
    // console.log(newObjectives)
    // console.log(id)
    // const newOption = collect(objectiveOptions).where(objectiveOptions.id, newObjectives.value)

    // console.log(newOption)
    // console.log(objectiveOptions)
    // console.log(newObjectives)

    newObjectives = newObjectives.map(ele => ele.value)
    const newOption = collect(objectives).whereIn('id', newObjectives)
    // console.log(newOption.items)
    // console.log(newObjectives)
    add(id, newOption.items)

  }
  return (
    <>
      <h6 style={{ width: "90%", margin: "20px auto 4px auto", fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question's Objectives</h6>
      <Select
        id="color"
        backgroundColor="red"
        placeholder={"Tag Objectives"}
        options={objectiveOptions}

        isMulti
        onChange={handleOnChange}
        value={selectedObj}
        className='react-select-container'
        classNamePrefix={'react-select'}
        isDisabled={isReadOnly}
        theme={{
          borderRadius: 0,
          // minHeight: 0,
          spacing: {
            baseUnit: 3,
            controlHeight: 10,
            menuGutter: 4
          }
        }}
        styles={{
          container: style => {
            return {
              ...style,
              margin: '2px auto 20px auto',
              width: "90%",
            }
          },
          control: (base) => {
            return {
              ...base,
              padding: "1px 2px",
              background: "white",
              ':hover': {
                ...base[':hover'],
                boxShadow: "1px 1px 10px 2px #D9D9D9",
                borderColor: "#1B998B !important"
              },
              ':active': {
                ...base[':active'],
                borderColor: "#1B998B !important"
              },
            };
          },
          // option: (styles, { data, isDisabled, isFocused, isSelected }) => {
          //   // const color = chroma(data.color);
          //   return {
          //     ...styles,
          //     backgroundColor: 'red',
          //     zIndex: '999'
          //   }
          // },
          singleValue: style => {
            return {
              ...style,
              padding: "0 5px",
              backgroundColor: "red"
            }
          },
        }}
      />

    </>
  )
}

const mapStateToProps = state => {
  return {
    objectives: state.objectives
  }
}

const mapDispatchToProps = {
  add: (qId, obj) => addObjective(qId, obj)
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionCardObjectives)