import React, {useState, useEffect, Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input } from 'reactstrap'; 
import { getParentsOfBrandCategoriesTrueOnly, createNewBrandCategory, getALLBrandCategories, backButtonFunction } from '../../store/actions/actionCreator'
import { Formik } from 'formik' 
import * as Yup from 'yup' 
import showToast from '../../../../../components/Toast/toast' 
import { withRouter } from 'react-router-dom' 
import BreadCrumb from '../../../../../components/Breadcrumb/breadcrumb'
import '../buttonCss.css'

function CreateBrandCategory(props) { 
    const { history } = props; 
    
    let dispatch = useDispatch() 
    let [create, setCreate] = useState(false) 
    let {allData, parentsTrueOnly: parents} = useSelector(state => state.brandCategory) 
    
    try { 
        if(!Object.keys(allData).length) { allData.data = [] } 
        if(!Object.keys(parents).length) { parents.data = [] } 
        if(Object.keys(parents).length) { 
            // Sorting Alphabetically 
            let compare = (a, b) => { 
                const nameA = a.name.toUpperCase(); 
                const nameB = b.name.toUpperCase(); 

                let comparison = 0; 
                if (nameA > nameB) { comparison = 1; } 
                else if (nameA < nameB) { comparison = -1; } 
                return comparison; 
            } 
            parents.data.sort(compare); 
        } 
    } catch (error) { } 
    
    
    useEffect(() => { 
        const abortController = new AbortController(); 

        dispatch(getParentsOfBrandCategoriesTrueOnly()) 
        dispatch(getALLBrandCategories()) 
        
        return () => { abortController.abort() }; 
    }, []) 
    
    
    const CreateToast = () => showToast('Created Successfully') 
    const ErrorToast = () => showToast('Server Error') 

    return ( 
        <Fragment> 
            <div className="container"> 
            <BreadCrumb path="Brand Category" slug="Create" link="/app/brandCategory" backButtonFunction={backButtonFunction} /> 
            <Card className="my-3 "> 
            {/* <CardHeader> Add Brand Category </CardHeader>  */} 
            <CardBody> 
                <Formik 
                    initialValues={{ name:'', status: true, parentCategory: '', description: '' }} 
                    validationSchema={ 
                        Yup.object().shape({ 
                            name: Yup 
                                .string().required("Required").trim().lowercase()
                                .notOneOf(allData ? allData.data.map(obj => obj.name.toLowerCase()) : [], "Already Exist"), 
                        }) 
                    } 
                    onSubmit={(values, { resetForm }) => { 
                        let parentId = null; 
                        for(let i = 0; i < parents.data.length; i++) { 
                            if(parents.data[i].name === values.parentCategory) { 
                                parentId = parents.data[i]._id 
                            } 
                        } 

                        let createdData = {} 
                        if(parentId) {
                            createdData = {
                                name: values.name.trim(), 
                                active: values.status, 
                                parentId,
                                description: values.description, 
                                createdAt: Date.now() 
                            } 
                        } 
                        else { 
                            createdData = { 
                                name: values.name.trim(), 
                                active: values.status, 
                                description: values.description, 
                                createdAt: Date.now() 
                            } 
                        } 
                        dispatch(createNewBrandCategory(createdData, CreateToast, ErrorToast)); 
                        resetForm(); 
                        history.push('/app/brandCategory'); 
                    }}> 
                    { 
                    props => ( 
                        <Form onSubmit={props.handleSubmit}> 
                            <FormGroup row> 
                                <Label htmlFor="brandCategory" sm={2}> 
                                    Name: <span style={{color: 'red'}}>{` *`}</span> 
                                </Label> 
                                <Col sm={4}> 
                                    <Input type="text" name="name" id="brandCategory" 
                                        style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                        value={props.values.name} onChange={props.handleChange} 
                                        onBlur={props.handleBlur} placeholder="Enter brand category name"
                                    /> 
                                    { 
                                        props.errors.name && props.touched.name && 
                                        <p style={{ 
                                            color: 'red', fontSize: '12px', 
                                            marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                        }}> 
                                            {props.errors.name} 
                                        </p> 
                                    } 
                                </Col> 

                                <Label htmlFor="status" className="mt-1">Active:</Label> 
                                <Col > 
                                    <Input 
                                        type="checkbox" 
                                        id="status" 
                                        name="status" 
                                        className="form-check-input mt-2" 
                                        checked={props.values.status} 
                                        onChange={props.handleChange} 
                                        onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            
                            <FormGroup row> 
                                <Label htmlFor="parentCategory" sm={2}> Parent Category: </Label> 
                                <Col sm={4}> 
                                    <Input 
                                        type="select" name="parentCategory" id="parentCategory" 
                                        value={props.values.parentCategory} 
                                        onChange={props.handleChange} 
                                        onBlur={props.handleBlur} > 
                                        <option value="" disabled defaultValue hidden>Select...</option> 
                                        { 
                                            parents.data.map((obj) => { 
                                                return ( <option key={obj._id}> {obj.name} </option> ) 
                                            }) 
                                        } 
                                    </Input> 
                                </Col> 
                            </FormGroup> 

                            <FormGroup row> 
                                <Label htmlFor="description" sm={2}> Description: </Label> 
                                <Col> 
                                    <Input type="textarea" name="description" id="description" 
                                        value={props.values.description} onChange={props.handleChange} 
                                        onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            
                            <Button 
                                style={{ background: '#1B998B', borderColor: '#1B998B' }} 
                                className="btnHover"
                                size="sm" type="submit" > 
                                Save 
                            </Button> 
                            
                            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 10px' }} size="sm" 
                                onClick={() => { 
                                    setCreate(!create); 
                                    history.push('/app/brandCategory'); 
                                }}> 
                                Cancel 
                            </Button> 
                        </Form> 
                    ) 
                    } 
                </Formik> 
            </CardBody> 
            </Card> 
            </div> 
        </Fragment> 
    ) 
}

export default withRouter(CreateBrandCategory) 

