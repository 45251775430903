import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'

import QuestionCard from '../../components/QuestionCard'
import {
  deleteQuestion,
  editField,
  editCheckbox,
  editTitle,
  changeType,
  getQuestionId
} from '../../redux/actions/modifyQuestion'


const QuestionCardList = ({ questionList, deleteQuestion, editField, editCheckbox, editTitle, changeType, getQuestionId, selected, hasError }) => {
  console.log("questionList:", questionList)
  const list = questionList.map((item, index) => {
    return <QuestionCard
      err={hasError}
      key={item.id}
      selected={selected}
      deleteQuestion={deleteQuestion}
      editTitle={editTitle}
      editField={editField}
      editCheckbox={editCheckbox}
      changeType={changeType}
      getQuestionId={getQuestionId}
      id={item.id}
      item={item}
      index={index}
    />
  })

  return (
    <React.Fragment>
      {list}
    </React.Fragment>
  )
}

const mapStatetoProps = state => ({
  questionList: state.question,
  selected: state.selected,
  hasError: state.error.noData
})

//You can add dispatch like this too: 
//https://dev.to/mcrowder65/you-ve-been-doing-mapdispatchtoprops-wrong-this-entire-time-582m
const mapDispatchToProps = {
  deleteQuestion: id => deleteQuestion(id),  //this id is called back from questionCard. id is passed as props from QuestionCardList to questioncard
  editField: (qId, fId, value) => editField(qId, fId, value),
  editCheckbox: (qId, fId, value) => editCheckbox(qId, fId, value),
  editTitle: (id, value) => editTitle(id, value),
  changeType: (id, value) => changeType(id, value),
  getQuestionId: id => getQuestionId(id)    //its called from Questioncard which is passes as props from QuestionCardList
}

export default connect(mapStatetoProps, mapDispatchToProps)(QuestionCardList)