import React from 'react';


const style = {
    title: {
        color: '#424242',
        fontSize: '15px',
        fontWeight: '700',
        marginTop:'20px'
    },

    value:{
        fontSize:'50px',
        fontWeight:'700',
        color:'coral',
        lineHeight:'30px',
        textAlign:'center'
    }
}

const InfoCard = props => {
    return (
        <div className="card text-center">
            <div className="card-body" style={{ height: "160px" }}>
                <div>
                    <h6 className="card-title" style={style.title}>{props.title}</h6>
                    <p className="card-text" style={style.value}>{props.response}</p>
                    {
                        props.limit ? <p className="text-center" style={{fontSize:"13px", color:"#424242", fontWeight:"700", marginTop:"10px"}}>{`Out of ${props.limit}`}</p> : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default InfoCard;
