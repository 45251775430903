import React from 'react'

function Security() {
  const style = {
    p: { 
      color: 'rgb(52, 58, 64)', 
      fontSize: '13px', 
      textAlign: 'justify', 
      fontFamily: 'Roboto', 
      fontWeight: '400'
    } 
  }
  return ( 
    <div className="my-5 ml-3 text-justify"> 
      <section id="security-statement-prolificxm-security-and-compilance">
        <h3 className="text-center">ProlificXM Security and Compliances</h3>
        <p style={{...style.p}}>
          This statement will explain how ProlificXM ensures all kinds of security to its millions of users worldwide to ease their Experience Management process. Security is one of the key components in our offerings. The following statement will also discuss the globally accepted Standards and Compliances we maintain for the Security of our Services.
        </p> 
      </section> 


      <section id="security-statement-user-security">
        <h5 className="text-center">User Security</h5>
        <p style={{...style.p}}>
          ProlificXM provides its users with a unique and secure User ID and Password after registration that needs to be entered in order to log in to our website every time. We endeavor to follow the best practices in the industry to store user data which we consider confidential. Passwords of users are encrypted in our database before they are saved with a purpose to maintain confidentiality. All kind of Information associated with your account are maintained with security and we do not disclose those information with any other User. Our Servers are located in Singapore where all the Data are stored. Upon expiration of an account, Data of that account are securely stored for certain period of time. After that time, the Data are completely erased from our Server. <br /> 
          We do not preserve any information related to credit card of our paying users. Users credit card details are handled by a third party payment management system employed by us with utmost security, which complies with Payment Card Industry Data Security Standard (PCI Dsecurity-statement) Compliances. For the details, you can see Compliances Section below.
        </p> 
      </section>



      <section id="security-statement-data-security">
        <h5 className="text-center">Data Security</h5>
        <p style={{...style.p}}>
          ProlificXM provides Software as a Service (SaaS) to its users worldwide. Which means the Survey Data, Content, Survey Questionnaire, Responses & other materials are stored in our Server. The security of your Data is uttermost important to us, and to you as well. We understand that your Data requires confidentiality, accuracy, sustainability to operate and availability. <br /> <br /> 
          Our Security measures separate each Customer's Data logically from other Customers' Data following a set of secure protocols in the framework. It assures that one Customer's Data will not become accessible to another customer. Your Data is stored on our servers when you register for our Services. Your data is in your ownership, and not in ours. If you want, you can export your Data anytime from our Server and store offline in your own custody. We do not share your Data with any third-party without your consent. 
          Also if you have any question or concern regarding our Data Security measures, you can write to us at 
          {` `} <span style={{color: '#1B998B', fontWeight: '500'}}>
            support@prolificxm.com
          </span>.
           
        </p> 
      </section>



      <section id="security-statement-system-reliability"> 
        <h5 className="text-center">System Reliability</h5>
        <p style={{...style.p}}>
          We install important updated on our system from time to time based to requirements and necessity. We review our servers at regular intervals to make sure that they are up to date. Before uploading any update on our production servers, we upload it on a local environment where a team of QA testers verify and certify that the update will have no conflicting effect on any of the applications. Our estimated downtime for regular maintenance purpose is limited to a total of 8 hours per year. We notify our users before each maintenance about approximate duration of downtime. <br /> <br /> 

          We have failover in place for all critical hardware and software components, as well as for the entire site. An individual hardware mechanism is available as a failover for every component. We also have an offline failover system for complete failover in cold state when a daily backup copy is being restored. <br /> <br /> 

          We perform backup of data at different frequencies as the Data changes constantly. The backups are performed on Daily, Weekly, and Monthly basis. Backups of transaction and incremental are performed on a regular basis. We carry out a testing plan each month to ensure that the backup operation are running smoothly.
        </p> 
      </section>


      
     
      <section id="security-statement-vulnerability-and-penetration-tests">
        <h5 className="text-center">Vulnerability Management and Penetration Test</h5>
        <p style={{...style.p}}>
          ProlificXM maintains a vulnerability management program which remain documented. It includes recurring scans, identify, and resolve security vulnerabilities on our Servers, Applications, Network Equipment, and Workstations. The production and testing environments are regularly scanned using trusted third party vendors. We apply critical patches to our servers on a priority basis. <br /> <br /> 
          ProlificXM relies on a Security Organization, who are solely dedicated to provide Server, Network and Application security to Internet based business entities. We also conduct regular internal and external penetration tests for security purpose through the Security Organization. If we find any severity in the results of the tests, we remediate if necessary.
        </p> 
      </section>



      <section id="security-statement-access-control">
        <h5 className="text-center">Access Control</h5>
        <p style={{...style.p}}>
          Access to our Technical resources are very limited and requires multi-factor authentication of the permitted authority. We review the access to ProlificXM resources on a regular basis to ensure that there are no misuse of access. We revoke access immediately with termination of an employment to protect our resources. We conduct regular workshop to ensure that the designated employee is aware of the access permitted to them and continue it accordingly.
        </p> 
      </section>



      <section id="security-statement-network-security"> 
        <h5 className="text-center">Network Security</h5>
        <p style={{...style.p}}>
          For Data Protection, we use a strong firewall. We endeavor to follow industry best practices such as making sure that the unwanted Services are getting disabled on firewall, default passwords are changed upon immediately registration, and the firmware version remains up-to-date. We permit Access to the designated administrators only.
        </p> 
      </section>

     

      <section id="security-statement-security-beach">
        <h5 className="text-center">Security Breach</h5>
        <p style={{...style.p}}>
          In order to protect your Information, we have enforced considerable and elaborate security measures. For Logging in to an account, User must enter a unique user name and accurate password specifically. We use encryption technology for conveying sensitive Information. We understand that, when it comes to transmission over the internet or electronic storage, no method can be called perfectly secure. We cannot guarantee that we are absolutely secure. We may notice a security breach in our system sometimes. If we notice such security breach, we will invest our optimum effort to notify the affected users through an email so that they can take measures necessary to prevent further breach in security. We are committed to keeping our customers fully aligned with any case of security breach that we know of.
        </p> 
      </section>



      <section id="security-statement-customer-responsibility">
        <h5 className="text-center">Customer Responsibilities</h5>
        <p style={{...style.p}}>
          ProlificXM takes different measures to ensure the safety and Security of Application, Data and Services. As a Customer, you have some responsibilities as well to ensure that your own Data and your end user Data remains intact and unharmed. ProlificXM allows Customers to create and grant access to sub-users by assigning Administrator of each account as per requirement. It is up to the account Administrator who they share account password with, permit to access specific Data, Resource, Content and Information and revoke or restrict access when needed. Customers should be liable for any confusion and inefficiency while maintaining data privacy among sub-users. ProlificXM will not be liable for explicitly granting or revoking or restricting any access to any sub-user of an account. We encourage you to understand which user will have access to which resource before granting the access. It is also your responsibility to notify us or report to us about any security breach in your account by any of your sub-users. You can inform us in such incident by emailing us at {` `} <span style={{color: '#1B998B', fontWeight: '500'}}>
            support@prolificxm.com
          </span>.
        </p> 
      </section>



      {/* <section id="security-statement-compilation-and-certification"> 
        <h5 className="text-center">Compliance and Certifications</h5>
      </section> 


      <section id="compilation-and-certification-gdpr-compilance">
        <p style={{...style.p}}>
          GDPR Compliance <br /> <br /> 
          ProlificXM is compliant with General Data Protection Regulation (GDPR) Compliance. We understand that the requirements to be updated with GDPR requirements are significant and we are working to keep ourselves with it. We are trying our best to ensure that the Terms of Uses and other contractual Terms are in place. We look forward to ensure continuous data transmission worldwide and protect those Data. We will change our policies, if needed, in order to adapt with GDPR updates and modifications.
        </p> 
      </section> */} 



      <section id="security-statement-anti-spam-protection">
        <h5 className="text-center">Anti-Spam Protection</h5>
        <p style={{...style.p}}>
          ProlificXM allows users to distribute Survey over email. Any Email with misleading content may result in ProlificXM being marked as Spammer. In such case, we maintain very strict policy and take necessary measure which may even result in Customer being suspended permanently.  For the users who use this Channel can get details of our Anti-Spam Protection policy by clicking the Anti-Spam Policy option below our website.
        </p> 
      </section>



      <section id="security-statement-conclusion">
        <h5 className="text-center">Conclusion</h5>
        <p style={{...style.p}}>
          ProlificXM will continue to upgrade its Service and Features to assist modern business entities by providing advanced experience management solution. We will enforce the security even more with Parallel to each update. To ensure utmost security, we will need your cooperation too. If you have any valuable feedback or question regarding our Security and Compliances, you can share with us via email at {` `} <span style={{color: '#1B998B', fontWeight: '500'}}>
            support@prolificxm.com
          </span>.
        </p> 
      </section>
    </div> 
  ) 
} 

export default Security; 
