import {
  CHANNEL_INFO_RECIEVE,
  EDIT_VALUE,
  ADD_CONTACT_CART,
  START_DATE,
  END_DATE
} from '../actions/const'

const answerReducer = (
  state = {
    _id: "",
    createdByCompanyID: "",
    data: {},
    contactCard: {}
  },
  action
) => {
  switch (action.type) {

    case CHANNEL_INFO_RECIEVE: {
      const questions = { ...action.channelInfo.question.questionBank.questionOrder }
      const projects = Object.keys(questions)
      projects.forEach(projectId => {
        questions[projectId] = questions[projectId].map(ques => {
          const data = { ...ques }
          delete data.isRequired
          delete data.isConditional
          delete data.jump
          switch (ques.type) {
            case "likertScale":
            case "multipleChoice":
              data.answer = {
                index: null,
                value: ""
              }
              data.responseTime = 0
              break
            case "checkbox":
              data.answer = []
              data.responseTime = 0
              break
            case "numeric":
            case "slider":
              data.answer = data.min
              data.responseTime = 0
              break
            case "date":
              data.answer = Date.now()
              data.responseTime = 0
              break
            case "daterange":
              data.answer = {}
              data.responseTime = 0
              break
            case "textBox":
              data.answer = ""
              data.responseTime = 0
              break
            default:
              data.answer = ""
              data.responseTime = 0
          }
          return data
        })
      })
      return {
        ...state,
        _id: action.channelInfo._id,
        createdByCompanyID: action.channelInfo.createdByCompanyID,
        data: { ...questions }
      }
    }

    case EDIT_VALUE: {
      const { projectId, questionId, value } = action
      const answers = state.data[projectId]
      const newAnswers = answers.map(ans => {
        if (ans.id !== questionId) {
          return ans
        } else {
          return {
            ...ans,
            answer: value
          }
        }
      })
      const newState = {
        ...state,
        data: {
          ...state.data,
          [projectId]: newAnswers
        }
      }
      return newState
    }

    case START_DATE: {
      const { projectId, questionId, value } = action
      const answers = state.data[projectId]
      const newAnswers = answers.map(ans => {
        if (ans.id !== questionId) {
          return ans
        } else {
          return {
            ...ans,
            startDate: value
          }
        }
      })
      const newState = {
        ...state,
        data: {
          ...state.data,
          [projectId]: newAnswers
        }
      }
      return newState
    }

    case END_DATE: {
      const { projectId, questionId, value } = action
      const answers = state.data[projectId]
      const newAnswers = answers.map(ans => {
        if (ans.id !== questionId) {
          return ans
        } else {
          return {
            ...ans,
            endDate: value,
            responseTime: value - ans.startDate
          }
        }
      })
      const newState = {
        ...state,
        data: {
          ...state.data,
          [projectId]: newAnswers
        }
      }
      return newState
    }


    case ADD_CONTACT_CART: {
      return {
        ...state,
        contactCard: action.payload
      }
    }

    default: {
      return state
    }
  }
}

export default answerReducer