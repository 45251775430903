import React from 'react'
import image from './assets/product.png'
import banner from './assets/product_experience.png'
import {css} from 'glamor'

function Product() { 

  const contentTitle = css({textAlign: 'center', color: '#1B998B', fontSize: '22px', marginBottom: '20px'})
  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'})
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 


  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Product experience banner" {...bannerWidth} /> 
      </div> 
      <p {...text}>
        Have you tried the new dessert item of your favorite bakery shop? How much did you like it? Or the new hair spa which was launched last month in your regular go-tosaloon? Did you enjoy that? Product experience is just that which brings out customer’s feelings, perceptions, suggestions about that particular product. Customer’s actions/reactions both can be measured through product experience. 
      </p>

      <p {...text}>
        Product is always made to consume commercially. Until and unless customers buy it, its value remains numb. Product experience is a vast area to explore. Before launching a product if it can be forecasted that how much demand it can create in the market, what people expect from that product and the hype it can make, then the chances of getting that product successful will be increased. Product experienceserves the same purpose not just before launching a product, after getting into a market in which extent the product is performing it can also derive. 
      </p>
      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="product experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        ProlificXM helps you to shape up and develop your product based on customer needs. Product launching, product performance, product development, product feedback, product usability, product loyalty, etc. are some metrics ProlificXM uses toevaluate the whole journey of a product. The quality, price, and availability of product is satisfactory or not, any modification, up-gradation is necessary or not, what type of issues the product is facing and how to overcome it, why people are liking or disliking it and how to turn people’s attention on that everything can be analyzed with real-time data through ProlificXM. It is a compact tool to deliver the experience of your product from your customer. The Artificial Intelligence of ProlificXM will help you to ensure your products are performing better and will perform consistently or even better.
      </p>


      <h1 {...header}>Product Launching</h1>
      <p {...text}>
        “The best ad is a good product”. A good product itself grab customer’s attraction for purchasing it. But indeed to let people know and to make space in the market a proper product launching is a must. If you are thinking that you are coming with a great product with fantastic features which can change the whole market scenario, which is incomparable from others and people definitely should buy this then you have to be very strategic launching it. The product should have a super attractive as well as a facile name that can widely spread among everyone. A compact marketplan should be initiated to establish the product. The same product is not for everyone. Its demands and needs vary from person to person. So the targeted customer group must be aware of the product to consume it. Media, electronic 
        media, social media, brand ambassadors, campaigns, promotional packages, etc. various contents need to be developed for risen up the product. The more innovative, creative, out of the box the idea is, it will attract more people towards it.
      </p> 


      <h1 {...header}>Product Development</h1>
      <p {...text}>
        Great companies are built on great products. Product is that thing which bonds a customer to the business industry. Product development is an endless process. Adding, changing, modifying, updating is necessary on a demand basis. A product can surely fail but its development process is always open to make it a successful one. The crispy chicken of KFC or the glazed donut of Krispy Kreme did not stand out overnight. It went through taste, quality, recipe, packaging, serving, etc. variousmodifications and up-gradation. We can see the result of the final developed product. Who doesn’t love that crispiness of that KFC chicken or that delicious and mouth-watering Krispy Kreme donuts? Indeed their products bonding us with them in terms of delights.
      </p> 


      <h1 {...header}>Product Feedback</h1>
      <p {...text}>
        Customer’s real experience comes from product feedback. Any product or service is dedicated to the customer. It is important to know whether the customers are preferring the product or not. What are they liking about the product and what are they lacking? Which features need to get changed or what can make it better? If someone is not liking it what are the reasons behind? Product feedback captured all of this information. This feedback helps to develop the product with new dimensionsto grab the wider acceptance. Product feedback is actually the customer’s voice, lending an opportunity to serve customers better.
      </p> 


      <h1 {...header}>Product Usability</h1>
      <p {...text}>
        I gifted a trimmer to my brother last week on his birthday. The trimmer I gifted him, has lots of options and tools to use as per its packaging and manual. My brother does a lot of experiments with his beard and hair, so I thought it will help him to try more styles. But with my utter surprise, instead of praising that product, my brothercomes out with problems for using it. Tools are not synchronized enough to operate, manuals are not finely described, in a word not user-friendly at all. The efficiency and effectiveness of a product depend on its usability. If you find difficulties to use any product, it is obviously not something which you will buy again to use. We intend to purchase those products again and again which we find comfortable, easy and useful to use. Product usability is based on customer satisfaction which can be brought through the quality and performance of the product. The less effort a product takes to consume and the less error is encountered, the more satisfaction comes through the product. Product usability makes more sense to erase labor and increase mobility for consumers.
      </p> 
    </div> 
  ) 
} 

export default Product; 
