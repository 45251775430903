import React from 'react'
import image from './assets/board.png'
import banner from './assets/Online_Survey.png'
import SurveyImg from './assets/Survey.png'
import {css} from 'glamor'
import { Button } from 'reactstrap'

function Survey() { 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="online survey" {...bannerWidth} /> 
      </div> 

      <p {...text}>
        A survey is a traditional way to get data directly from people. In this era of technology, it is no longer conducted by pen paper. An online Survey can serve many purposes like helping an organization to understand their strength, weakness and pinpoint their improvement points. In the following paragraphs, we will take a quick look at how to prepare an online survey effortlessly and effectively. 
      </p> 

      {/* Image */} 
      <div className="text-center"> 
        <img src={SurveyImg} alt="Random Survey" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <h1 {...header}>Things we need to Consider</h1>
      <p {...text}>
        There are a few things we need to consider before preparing the set of questions. While preparing the survey, we must maintain those things to make it effective.
      </p> 
      <ul className="ml-5" style={{fontSize: '15px', color: 'rgb(52, 58, 64)'}}>
        <li className="mb-2"> 
          Variation: While creating an online survey, we should use multiple question types such as Open textbox, Multiple Choice, Likert Scale, Slider, etc. Only a single type of questions can be very monotonous and repel the participants from responding. An online survey platform usually offers variations. We should choose the platform based on that. 
        </li>
        <li className="mb-2"> 
          Channels: An online survey should have an Omni Channel distribution option. It cannot be ensured that all users will respond through one single link. Rather some may prefer to get it through Social Media or choose to use a device instead. So the survey platform should have an Omni Channel facility for all types of participants
        </li>
        <li className="mb-2">
          Time: Time is a valuable asset. So the survey must be short and compact. Usually, question types play an important role here. If the question type is an open textbox and requires the participant to write descriptive information, they may not be interested. But if the questions require just a few taps on the screen or write only a few words, it can receive more responses.
        </li>
        <li className="mb-2">
          Relevancy: We all have purposes and so should our questions be. If the questions are not relevant to our services or the objective of the survey, then it will not be fruitful. Therefore, it is important to ask relevant questions only.
        </li>
        <li className="mb-2">
          Awareness: While preparing questions, we must be aware of the privacy and sentiment. We have to make sure that the questions are not personal or too sensitive to be asked. We should not be breaching any global privacy standard or try to make any controversial political statements through our questions. It should be specific but also convenient for the participants to respond.
        </li>
      </ul> 


      <h1 {...header}>Simple and Guided Process</h1>
      <ol className="ml-5" style={{fontSize: '15px', color: 'rgb(52, 58, 64)'}}>
        <li className="mb-2">
          The first step you have to take is to set an objective. You have a purpose for the survey. Turn the objective into an objective and setup the metrics if needed.
        </li>
        <li className="mb-2">
          Based on your objective, identify the participants. Whether your participants belong to any age range, area, location or institution, determine it. If possible, list down the channels through which you want to engage with them.
        </li>
        <li className="mb-2">
          Prepare your questions based on your objective. While preparing questionnaire, make sure that you have considered the above points. Use appropriate options in multiple choice questions, proper scale for likert scale and put an open text where necessary.
        </li>
        <li className="mb-2">
          Employing the right channels is the next step. As you have already identified the participants, you can enable the channels you want to use.
        </li>
      </ol> 

      <p {...text}>
        If you have followed the mentioned steps, your online survey is ready. Use the distribution channels properly to gather your responses. You can also check more guidelines on our Resources page.
      </p> 
    </div> 
  ) 
} 

export default Survey; 
