import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import GaugeComponent from 'react-gauge-component';
import WordCloud from 'wordcloud';
import ApexChart from 'react-apexcharts';
import PieChart from '../charts/piechart/PieChart';
import InfoBar from '../../../DataAnalysisTwo/components/InfoBar/InfoBar';
import Barchart from '../../../DataAnalysisTwo/components/charts/nivo/Barchart';
import { Scrollbars } from 'react-custom-scrollbars';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSadCry,
  faSadTear,
  faFrown,
  faMeh,
  faLaugh,
  faSmile,
  faUserAlt,
  faStar,
  faHeart,
  faThumbsUp,
  faGrinHearts,
} from '@fortawesome/free-solid-svg-icons';
import Nps from '../../../DataAnalysisTwo/components/nps/Nps';
import NpsDescriptor from '../../../DataAnalysisTwo/components/npsDescriptor/NpsDescriptor';

// const npsLineData = [
// 	{
// 		id: 'japan',
// 		color: 'hsl(289, 70%, 50%)',
// 		data: [
// 			{
// 				x: 'plane',
// 				y: 248,
// 			},
// 			{
// 				x: 'helicopter',
// 				y: 211,
// 			},
// 			{
// 				x: 'boat',
// 				y: 33,
// 			},
// 			{
// 				x: 'train',
// 				y: 125,
// 			},
// 			{
// 				x: 'subway',
// 				y: 148,
// 			},
// 			{
// 				x: 'bus',
// 				y: 127,
// 			},
// 			{
// 				x: 'car',
// 				y: 201,
// 			},
// 			{
// 				x: 'moto',
// 				y: 232,
// 			},
// 			{
// 				x: 'bicycle',
// 				y: 24,
// 			},
// 			{
// 				x: 'horse',
// 				y: 100,
// 			},
// 			{
// 				x: 'skateboard',
// 				y: 291,
// 			},
// 			{
// 				x: 'others',
// 				y: 159,
// 			},
// 		],
// 	},
// 	{
// 		id: 'france',
// 		color: 'hsl(281, 70%, 50%)',
// 		data: [
// 			{
// 				x: 'plane',
// 				y: 69,
// 			},
// 			{
// 				x: 'helicopter',
// 				y: 235,
// 			},
// 			{
// 				x: 'boat',
// 				y: 147,
// 			},
// 			{
// 				x: 'train',
// 				y: 64,
// 			},
// 			{
// 				x: 'subway',
// 				y: 150,
// 			},
// 			{
// 				x: 'bus',
// 				y: 145,
// 			},
// 			{
// 				x: 'car',
// 				y: 129,
// 			},
// 			{
// 				x: 'moto',
// 				y: 149,
// 			},
// 			{
// 				x: 'bicycle',
// 				y: 41,
// 			},
// 			{
// 				x: 'horse',
// 				y: 148,
// 			},
// 			{
// 				x: 'skateboard',
// 				y: 297,
// 			},
// 			{
// 				x: 'others',
// 				y: 284,
// 			},
// 		],
// 	},
// 	{
// 		id: 'us',
// 		color: 'hsl(171, 70%, 50%)',
// 		data: [
// 			{
// 				x: 'plane',
// 				y: 218,
// 			},
// 			{
// 				x: 'helicopter',
// 				y: 298,
// 			},
// 			{
// 				x: 'boat',
// 				y: 155,
// 			},
// 			{
// 				x: 'train',
// 				y: 97,
// 			},
// 			{
// 				x: 'subway',
// 				y: 135,
// 			},
// 			{
// 				x: 'bus',
// 				y: 296,
// 			},
// 			{
// 				x: 'car',
// 				y: 168,
// 			},
// 			{
// 				x: 'moto',
// 				y: 229,
// 			},
// 			{
// 				x: 'bicycle',
// 				y: 135,
// 			},
// 			{
// 				x: 'horse',
// 				y: 192,
// 			},
// 			{
// 				x: 'skateboard',
// 				y: 170,
// 			},
// 			{
// 				x: 'others',
// 				y: 246,
// 			},
// 		],
// 	},
// 	{
// 		id: 'germany',
// 		color: 'hsl(40, 70%, 50%)',
// 		data: [
// 			{
// 				x: 'plane',
// 				y: 176,
// 			},
// 			{
// 				x: 'helicopter',
// 				y: 183,
// 			},
// 			{
// 				x: 'boat',
// 				y: 68,
// 			},
// 			{
// 				x: 'train',
// 				y: 191,
// 			},
// 			{
// 				x: 'subway',
// 				y: 196,
// 			},
// 			{
// 				x: 'bus',
// 				y: 200,
// 			},
// 			{
// 				x: 'car',
// 				y: 177,
// 			},
// 			{
// 				x: 'moto',
// 				y: 206,
// 			},
// 			{
// 				x: 'bicycle',
// 				y: 14,
// 			},
// 			{
// 				x: 'horse',
// 				y: 40,
// 			},
// 			{
// 				x: 'skateboard',
// 				y: 202,
// 			},
// 			{
// 				x: 'others',
// 				y: 44,
// 			},
// 		],
// 	},
// 	{
// 		id: 'norway',
// 		color: 'hsl(88, 70%, 50%)',
// 		data: [
// 			{
// 				x: 'plane',
// 				y: 250,
// 			},
// 			{
// 				x: 'helicopter',
// 				y: 269,
// 			},
// 			{
// 				x: 'boat',
// 				y: 122,
// 			},
// 			{
// 				x: 'train',
// 				y: 198,
// 			},
// 			{
// 				x: 'subway',
// 				y: 143,
// 			},
// 			{
// 				x: 'bus',
// 				y: 241,
// 			},
// 			{
// 				x: 'car',
// 				y: 149,
// 			},
// 			{
// 				x: 'moto',
// 				y: 132,
// 			},
// 			{
// 				x: 'bicycle',
// 				y: 13,
// 			},
// 			{
// 				x: 'horse',
// 				y: 60,
// 			},
// 			{
// 				x: 'skateboard',
// 				y: 207,
// 			},
// 			{
// 				x: 'others',
// 				y: 93,
// 			},
// 		],
// 	},
// ];

const colors = [
  '#1B998B',
  '#99B2DD',
  '#EF8354',
  '#4F5D75',
  '#F27D7D',
  '#A5668B',
  '#7EAB7E',
  '#CDC392',
  '#BE95C4',
  '#FFBF69',
  '#99B2DD',
  '#EF8354',
  '#4F5D75',
  '#F27D7D',
  '#A5668B',
  '#7EAB7E',
  '#CDC392',
  '#BE95C4',
  '#FFBF69',
  '#1B998B',
  '#99B2DD',
];

export const getPieChart = (questionId, options, answer, chartType, icons) => {
  let chartData = [];
  options.forEach((item, index) => {
    if (!answer[index]) {
      answer[index] = 0;
    }
    chartData.push([item, answer[index]]);
  });
  const element = (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <PieChart
          id={questionId}
          chartData={chartData}
          type={chartType}
          icons={icons}
        />
      </div>
    </div>
  );
  return element;
};

export const getProgressChart = (options, answer, totalResponse) => {
  const list = options.map((item, index) => {
    const percentage = Number(
      Math.round(parseFloat((answer[index] / totalResponse) * 100 + 'e' + 2)) +
        'e-' +
        2
    );
    return (
      <div key={index}>
        <InfoBar
          value={percentage}
          title={item}
          valueFontSize={13}
          textFontSize={10}
          color={colors[index]}
          progressBarHeight={20}
        />
        <hr className='my-0' />
      </div>
    );
  });

  let listA,
    listB = null;
  if (list.length > 10) {
    listA = list.slice(0, 10);
    listB = list.slice(10);
  }

  const element = (
    <div
      className={
        list.length > 10 ? 'row h-100' : 'row h-100 align-items-center'
      }
    >
      {list.length <= 10 ? (
        <div className='col-12'>
          <hr className='my-0' />
          {list}
        </div>
      ) : (
        <>
          <div className='col-6'>
            <hr className='my-0' />
            {listA}
          </div>
          <div className='col-6'>
            <hr className='my-0' />
            {listB}
          </div>
        </>
      )}
    </div>
  );
  return element;
};

export const getBarChart = (
  questionId,
  options,
  answer,
  layout,
  ratingType
) => {
  const chartData = [];
  const colors = {
    3: ['#F27D7D', '#CDC392', '#1B998B'],
    5: ['#F27D7D', '#FFBF69', '#CDC392', '#7EAB7E', '#1B998B'],
    7: [
      '#F27D7D',
      '#EF8354',
      '#FFBF69',
      '#CDC392',
      '#7EAB7E',
      '#5DBAA2',
      '#1B998B',
    ],
  };
  const emojis = {
    3: [faSadCry, faMeh, faGrinHearts],
    5: [faSadCry, faFrown, faMeh, faSmile, faGrinHearts],
    7: [faSadCry, faSadTear, faFrown, faMeh, faSmile, faLaugh, faGrinHearts],
  };

  let icons = [faSadCry, faFrown, faMeh, faSmile, faLaugh];
  let color = ['#F27D7D', '#FFBF69', '#CDC392', '#7EAB7E', '#1B998B'];

  const scale = options.length;
  icons = emojis[scale];
  color = colors[scale];

  switch (ratingType) {
    case 'starRating':
      icons = [faStar, faStar, faStar, faStar, faStar, faStar, faStar];
      break;
    case 'loveRating':
      icons = [faHeart, faHeart, faHeart, faHeart, faHeart, faHeart, faHeart];
      break;
    case 'thumbsUpRating':
      icons = [
        faThumbsUp,
        faThumbsUp,
        faThumbsUp,
        faThumbsUp,
        faThumbsUp,
        faThumbsUp,
        faThumbsUp,
      ];
      break;
    default:
      break;
  }
  options.forEach((item, index) => {
    chartData.push({
      name: item,
      value: answer[index] ? answer[index] : 0,
      color: color[index],
      icon: icons[index],
    });
  });
  return (
    <Barchart
      barchart={chartData}
      isParcent={false}
      min={0}
      max='auto'
      layout={layout}
      colorFlag={true}
      hasIcon={true}
      ratingType={ratingType}
    />
  );
};

export const getList = (questionId, textType, answer) => {
  const element = answer.map((item, index) => {
    return (
      <div
        key={index}
        style={{
          padding: '8px 10px 0px 10px',
          marginBottom: '6px',
          border: 'none',
          backgroundColor: '#EFF3F4',
          borderRadius: '4px',
        }}
      >
        <div className='row'>
          <div
            className='col-md-4 mb-2'
            style={{ fontSize: '12px', fontWeight: 500, color: '#212121' }}
          >
            <FontAwesomeIcon
              icon={faUserAlt}
              size='1x'
              color='#212121'
              className='mr-1'
            />{' '}
            {item.name} :
          </div>
          <div
            className='col-md-8 mb-2'
            style={{ fontSize: '12px', fontWeight: 400, color: '#424242' }}
          >
            {item.text}
          </div>
        </div>
      </div>
    );
  });

  return (
    <Scrollbars
      style={{ height: 340 }}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
    >
      {element}
    </Scrollbars>
  );
};

export const getNpsChart = (options, answer, chartType = 'simpleChart') => {
  const nps = {
    detractor: 0,
    passive: 0,
    promoter: 0,
  };
  if (answer.length) {
    for (let index = 0; index <= 6; index++) {
      nps.detractor += answer[index];
    }
    for (let index = 7; index <= 8; index++) {
      nps.passive += answer[index];
    }
    for (let index = 9; index <= 10; index++) {
      nps.promoter += answer[index];
    }
  }

  let totalResponse = nps.detractor + nps.passive + nps.promoter;
  totalResponse = totalResponse !== 0 ? totalResponse : 1;

  const promoterResponse = nps.promoter;
  const passiveResponse = nps.passive;
  const detractorResponse = nps.detractor;

  nps.detractor = Number(
    Math.round(parseFloat((nps.detractor / totalResponse) * 100 + 'e' + 2)) +
      'e-' +
      2
  );
  nps.passive = Number(
    Math.round(parseFloat((nps.passive / totalResponse) * 100 + 'e' + 2)) +
      'e-' +
      2
  );
  nps.promoter = Number(
    Math.round(parseFloat((nps.promoter / totalResponse) * 100 + 'e' + 2)) +
      'e-' +
      2
  );
  const npsValue = Number(
    Math.round(parseFloat(nps.promoter - nps.detractor + 'e' + 2)) + 'e-' + 2
  );

  const simpleChart = (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <h4
          className='text-center'
          style={{ fontSize: '24px', color: '#071E3D', fontWeight: '700' }}
        >
          NPS Value:{' '}
          <span className='d-inline-block' style={{ color: '#1B998B' }}>
            {npsValue}
          </span>
        </h4>
        <Nps nps={nps} label={true} />
        <div className='row'>
          <div className='col-12 text-center'>
            <NpsDescriptor
              name='Promoters'
              nps={nps.promoter}
              response={promoterResponse}
              color='#1B998B'
            />
            <NpsDescriptor
              name='Passives'
              nps={nps.passive}
              response={passiveResponse}
              color='#FFBF69'
            />
            <NpsDescriptor
              name='Detractors'
              nps={nps.detractor}
              response={detractorResponse}
              color='#F27D7D'
            />
          </div>
        </div>
      </div>
    </div>
  );

  const gaugeChart = (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <GaugeComponent
          value={npsValue}
          type='semicircle'
          minValue={-100}
          maxValue={100}
          labels={{
            tickLabels: {
              type: 'inner',
              defaultTickValueConfig: {
                style: {
                  fontSize: '14px',
                  color: '#000',
                },
              },
              defaultTickLineConfig: {
                hide: true,
              },
              ticks: [
                { value: -100 },
                { value: -80 },
                { value: -60 },
                { value: -40 },
                { value: -20 },
                { value: 0 },
                { value: 20 },
                { value: 40 },
                { value: 60 },
                { value: 80 },
                { value: 100 },
              ],
            },
          }}
          arc={{
            colorArray: ['#ff6060', '#ffea51', '#5eff69'],
            subArcs: [{ limit: 20 }, { limit: 60 }, { limit: 100 }],
            padding: 0.02,
            width: 0.3,
          }}
          pointer={{
            elastic: true,
            animationDelay: 0,
          }}
        />
      </div>

      <div className='col-12'>
        <h4
          className='text-center'
          style={{ fontSize: '24px', color: '#071E3D', fontWeight: '700' }}
        >
          NPS Value:{' '}
          <span className='d-inline-block' style={{ color: '#1B998B' }}>
            {npsValue}
          </span>
        </h4>
      </div>
    </div>
  );

  // const lineChart = (
  // 	<div className='row h-100 align-items-center'>
  // 		<div className='col-12'>
  // 			<div className='w-100'>
  // 				<ResponsiveLine
  // 					data={[
  // 						{
  // 							id: 'japan',
  // 							data: {
  // 								x: 'plane',
  // 								y: 248,
  // 							},
  // 						},
  // 					]}
  // 					margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
  // 					xScale={{ type: 'point' }}
  // 					yScale={{
  // 						type: 'linear',
  // 						min: 'auto',
  // 						max: 'auto',
  // 						stacked: true,
  // 						reverse: false,
  // 					}}
  // 					yFormat=' >-.2f'
  // 					axisTop={null}
  // 					axisRight={null}
  // 					axisBottom={{
  // 						tickSize: 5,
  // 						tickPadding: 5,
  // 						tickRotation: 0,
  // 						legend: 'transportation',
  // 						legendOffset: 36,
  // 						legendPosition: 'middle',
  // 						truncateTickAt: 0,
  // 					}}
  // 					axisLeft={{
  // 						tickSize: 5,
  // 						tickPadding: 5,
  // 						tickRotation: 0,
  // 						legend: 'count',
  // 						legendOffset: -40,
  // 						legendPosition: 'middle',
  // 						truncateTickAt: 0,
  // 					}}
  // 					pointSize={10}
  // 					pointColor={{ theme: 'background' }}
  // 					pointBorderWidth={2}
  // 					pointBorderColor={{ from: 'serieColor' }}
  // 					pointLabel='data.yFormatted'
  // 					pointLabelYOffset={-12}
  // 					enableTouchCrosshair={true}
  // 					useMesh={true}
  // 					legends={[
  // 						{
  // 							anchor: 'bottom-right',
  // 							direction: 'column',
  // 							justify: false,
  // 							translateX: 100,
  // 							translateY: 0,
  // 							itemsSpacing: 0,
  // 							itemDirection: 'left-to-right',
  // 							itemWidth: 80,
  // 							itemHeight: 20,
  // 							itemOpacity: 0.75,
  // 							symbolSize: 12,
  // 							symbolShape: 'circle',
  // 							symbolBorderColor: 'rgba(0, 0, 0, .5)',
  // 							effects: [
  // 								{
  // 									on: 'hover',
  // 									style: {
  // 										itemBackground: 'rgba(0, 0, 0, .03)',
  // 										itemOpacity: 1,
  // 									},
  // 								},
  // 							],
  // 						},
  // 					]}
  // 				/>
  // 			</div>
  // 		</div>
  // 	</div>
  // );

  const barChart = (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <div className='w-100'>
          <ApexChart
            series={[
              {
                name: 'NPS',
                // data: [4, 2, 1, 2, 2, 4, 10, 9, 23, 19, 55], // this is dummy data
                data: answer,
              },
            ]}
            type='bar'
            height={300}
            options={{
              colors: [
                '#ff6060',
                '#ff6060',
                '#ff6060',
                '#ff6060',
                '#ff6060',
                '#ff6060',
                '#ff6060',
                '#ffea51',
                '#ffea51',
                '#5eff69',
                '#5eff69',
              ],
              xaxis: {
                categories: Array(11)
                  .fill(0)
                  .map((_, i) => i),
              },
              tooltip: {
                enabled: true,
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  distributed: true,
                },
              },
              legend: {
                show: false,
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  const donutChart = (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <ApexChart
          series={[nps.promoter, nps.passive, nps.detractor]}
          type='donut'
          height={300}
          options={{
            labels: ['Promoters', 'Passives', 'Detractors'],
            colors: ['#5EFF69', '#FFBF69', '#F27D7D'],
            legend: {
              show: true,
              position: 'bottom',
              fontSize: '14px',
              fontWeight: 'medium',
            },
            plotOptions: {
              pie: {
                donut: {
                  size: '40%',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );

  switch (chartType) {
    case 'simpleChart':
      return simpleChart;

    case 'gaugeChart':
      return gaugeChart;

    // case 'lineChart':
    // 	return lineChart;

    case 'donutChart':
      return donutChart;

    case 'barChart':
      return barChart;

    default:
      return simpleChart;
  }
};
