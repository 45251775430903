export const editTitle = (id, value, questionList) => {
  return(questionList.map(item => {
    if(id !== item.id){
      return{...item}
    }else{
      const newItem = {...item}
      
      newItem.title = value
      return {...newItem}
    }
  }))
}