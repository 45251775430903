import { createSelector } from 'reselect'
import collect from 'collect.js';
import { date } from 'yup';

const questionsSelector = state => state.form.data.question.questionBank.questionOrder
const projectsSelector = state => state.form.data.question.questionBank.projectOrder
const currentProjectIdSelector = state => state.form.currentProject
const currentQuestionIdSelector = state => state.form.currentQuestion
const loadedDataSelector = state => state.form.loadedData
// const isLoadingSelector = state=>state.form.isLoading
const answerSelector = state => state.answers.data
export const redirectUrlSelector = state => state.form.redirectUrl
//Gives the channelType and submit Time
const channelDetailsSelector = state => {
  const data = {
    channelType: state.form.channelType,
    channelID: state.form.channelID,
    responseDate: new Date(),
  }
  return data
}

//returns the question data for the current question identified by the currentQuestion value in state inside form
export const questionDataSelector = createSelector(
  [currentProjectIdSelector,
    currentQuestionIdSelector,
    questionsSelector,],
  (projectId, questionId, questionData) => {
    const question = questionData[projectId].find(question => question.id === questionId)
    return question
  }
)

//Returns the type of the question
export const questionTypeSelector = createSelector(
  questionDataSelector,
  data => data.type
)

//Returns whether the question is required or not
const requiredSelector = createSelector(
  questionDataSelector,
  data => data.isRequired
)

//Returns a boolean indicating whether data is available or not
export const dataAvailabilitySelector = createSelector(
  state => state.form.noData,
  value => !value
)

// check is there any contact card
export const contactCardAvailableSelector = createSelector(
  state => state.form.data.question.questionBank.order.list,
  value => collect(value).where('id', 'contactCard').isNotEmpty()

)

//Returns a boolean identifying whether data is loaded or not
export const dataLoadedSelector = createSelector(
  loadedDataSelector,
  isDataLoaded => isDataLoaded
)

//Returns a boolean identifying whether the submit function should run or not
export const submitFunctionSelector = createSelector(
  currentProjectIdSelector,
  projectId => projectId !== "END" ? false : true
)

//Returns a boolean identifying whether the button should be next or submit
export const submitButtonSelector = createSelector(
  [currentProjectIdSelector,
    projectsSelector,
    currentQuestionIdSelector,
    questionsSelector,],
  (projectId, projectList, questionId, questionList) => {
    if (projectList.indexOf(projectId) !== projectList.length - 1) {
      return false
    } else {
      if (
        questionList[projectId].findIndex(question => question.id === questionId) !==
        questionList[projectId].length - 1
      ) {
        return false
      } else {
        return true
      }
    }
  }
)

export const totalQuestionSelector = createSelector(
  questionsSelector,
  questions => {
    let total = 0;
    for (let key in questions) {
      total += questions[key].length
    }
    return total
  }
)

export const isQuestionConditional = createSelector(
  questionsSelector,
  questions => {
    let conditional = false;

    for (let key in questions) {
      let check = collect(questions[key]).where('isConditional', true);
      if (check.items.length > 0) {
        conditional = true
        // console.log(check)
      }

      // total += questions[key].length
    }
    // console.log(conditional)
    return conditional
  }
)
//returns the current value of the answer to the current question in view
export const answerFieldValueSelector = createSelector(
  [currentProjectIdSelector,
    currentQuestionIdSelector,
    answerSelector,],
  (projectId, questionId, answers) => {
    const value = answers[projectId].find(question => question.id === questionId).answer
    return value
  }
)

//Returns a boolean on whether the next or submit button is enabled or not
//Depends on whether the question is required or not and 
//if required, whether there is an answer or not
export const buttonEnableSelector = createSelector(
  [answerFieldValueSelector,
    questionTypeSelector,
    requiredSelector],
  (answer, type, required) => {
    if (!required) {
      return true
    }
    switch (type) {

      case "multipleChoice":
      case "likertScale":
      case "dichotomy":
      case "thumbsUpDown":
      case "ratingScale":
      case "starRating":
      case "thumbsUpRating":
      case "loveRating":
      case "dropDown":
      case "nps":
        if (!answer.value) {
          return false
        }
        return true

      case "checkbox":
        if (answer.length !== 0) {
          return true
        }
        return false
      case "date":
      case "textBox":
        if (!answer) {
          return false
        }
        return true
      case "daterange":
      case "numeric":
      case "slider":
        return true;
      default:
        return true
    }
  }
)

//Returns the data that needs to be posted through the response api
export const postDataSelector = createSelector(
  [channelDetailsSelector,
    submitFunctionSelector,
    state => state.answers],
  (channelDetails, submit, answers) => {
    if (!submit) {
      return null
    }
    const data = { ...answers.data }
    const projectIdList = Object.keys(data)
    projectIdList.forEach(projectId => {
      const newProject = data[projectId].map(answer => {
        switch (answer.type) {
          case "likertScale":
          case "multipleChoice":
          case "dichotomy":
          case "thumbsUpDown":
          case "ratingScale":
          case "starRating":
          case "thumbsUpRating":
          case "loveRating":
          case "dropDown":
          case "nps": {
            if (answer.answer.value) {
              return answer
            }
            return {
              ...answer,
              answer: {
                index: null,
                value: "Unanswered"
              }
            }
          }
          case "textBox": {
            // if(answer.answer){
            //   return answer
            // }
            // return{
            //   ...answer,
            //   answer: "Unanswered"
            // }
          }
          case "date":
          case "daterange":
          case "numeric":
          case "slider":
          case "checkbox":
          default:
            return answer
        }
      })
      data[projectId] = newProject
    })
    return {
      ...channelDetails,
      // channelID: answers._id,
      createdByCompanyID: answers.createdByCompanyID,
      answers: { ...data },
      projectID: [...projectIdList]
    }
  }
)

// export const previewStatusSelector