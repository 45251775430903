import React, { useState, useEffect, useRef } from 'react';
import CircualarProgress from '../../../DataAnalysisTwo/components/charts/circularchart/CircularProgress';
import SparkLine from '../charts/sparkline/SparkLine';
import PieChart from '../charts/piechart/PieChart';
import './CardView.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faChartPie,
  faChartBar,
  faDotCircle,
} from '@fortawesome/free-solid-svg-icons';
import { downlaodImage, downloadPNG } from '../downloadJSX/downloadJSX';

const CardView = (props) => {
  const { title, answered, skipped, type, config } = props;
  const [hideDownloadButton, setHideDownloadButton] = useState('');
  const [open, setOpen] = useState(false);
  const refDropdown = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (refDropdown.current && !refDropdown.current.contains(event.target)) {
      setOpen(false);
    }
  };

  //active button name
  const activeButton =
    config && config !== undefined
      ? config[type]
          .filter((el) => el.questionTitle === title)
          .map((el) => el.chartType)
      : null;

  useEffect(() => {
    setHideDownloadButton('');
  }, [hideDownloadButton]);

  let buttonGroup = null;

  if (
    type === 'multipleChoice' ||
    type === 'dropDown' ||
    type === 'checkbox' ||
    type === 'dichotomy' ||
    type === 'thumbsUpDown'
  ) {
    buttonGroup = (
      <div style={{ display: hideDownloadButton }}>
        <FontAwesomeIcon
          onClick={() => props.switchChart('pie', title, type)}
          icon={faChartPie}
          style={{
            margin: '2px',
            color: activeButton == 'pie' ? '#1B998B' : null,
          }}
        />
        <FontAwesomeIcon
          onClick={() => props.switchChart('bar', title, type)}
          icon={faChartBar}
          style={{
            margin: '2px',
            color: activeButton == 'bar' ? '#1B998B' : null,
          }}
        />
        <FontAwesomeIcon
          onClick={() => props.switchChart('donut', title, type)}
          icon={faDotCircle}
          style={{
            margin: '2px',
            color: activeButton == 'donut' ? '#1B998B' : null,
          }}
        />
      </div>
    );
  } else if (type === 'ratingScale') {
    buttonGroup = (
      <div style={{ display: hideDownloadButton }}>
        <FontAwesomeIcon
          onClick={() => props.switchChart('bar', title, type)}
          icon={faChartBar}
          style={{
            margin: '2px',
            color: activeButton == 'bar' ? '#1B998B' : null,
          }}
        />
        <FontAwesomeIcon
          onClick={() => props.switchChart('donut', title, type)}
          icon={faDotCircle}
          style={{
            margin: '2px',
            color: activeButton == 'donut' ? '#1B998B' : null,
          }}
        />
      </div>
    );
  }

  const handleDropdown = () => {
    setOpen(!open);
    props.getCardId(title);
  };

  const onClickDownload = (type) => {
    setHideDownloadButton('none');
    setOpen(false);
    switch (type) {
      case 'jpeg':
        downlaodImage(title + 'jpeg', title);
        break;
      case 'png':
        downloadPNG(title + 'png', title);
        break;
      default:
        break;
    }
  };

  const btnDownload = (
    <FontAwesomeIcon
      icon={faDownload}
      style={{ display: hideDownloadButton, float: 'right' }}
      onClick={handleDropdown}
    />
  );

  const dropdown = (
    <div
      className='dropdown'
      style={{ display: hideDownloadButton, position: 'absolute' }}
    >
      <ul>
        <li onClick={() => onClickDownload('jpeg')}>JPEG</li>
        <li onClick={() => onClickDownload('png')}>PNG</li>
      </ul>
    </div>
  );

  return (
    <div
      id={props.title + 'jpeg'}
      className='card CardView'
      style={{ height: '100%' }}
    >
      <div className='card-header'>
        <div className='row'>
          <div
            ref={refDropdown}
            className={props.hasSparkLine ? 'col-md-6' : 'col-12'}
          >
            {props.downloadCart ? btnDownload : null}
            {open && dropdown}
            <h6 className='card-view-title'>{title}</h6>
            <div className='row'>
              <div className='col'>
                <div class='d-flex flex-row'>
                  <div
                    className='response-counter'
                    style={{ marginRight: '5px' }}
                  >
                    Answered:{' '}
                    <span style={{ color: '#1B998B' }}>{answered}</span>
                  </div>
                  <div className='response-counter'>
                    Skipped: <span style={{ color: '#F27D7D' }}>{skipped}</span>
                  </div>
                </div>
              </div>
              <div className='col d-flex justify-content-end'>
                {props.type === 'nps' && (
                  <select
                    onChange={(e) => props.setNpsChartType(e.target.value)}
                    value={props.npsChartType}
                  >
                    <option value='simpleChart'>Summary</option>
                    <option value='donutChart'>Ratio</option>
                    <option value='gaugeChart'>Gauge</option>
                    <option value='lineChart'>Trend</option>
                    <option value='barChart'>Frequency</option>
                  </select>
                )}

                {props.type === 'textBox' && (
                  <select
                    onChange={(e) => props.setTextBoxType(e.target.value)}
                    value={props.textBoxType}
                  >
                    <option value='wordCloud'>Word Cloud</option>
                    <option value='summary'>Summary</option>
                  </select>
                )}
              </div>
            </div>
          </div>
          {props.hasSparkLine && (
            <div className='col-md -6'>
              <p className='text-right sparkline-legend'>
                {props.sparklineTitle}
              </p>
              <SparkLine chartData={props.sparkLineData} />
            </div>
          )}
        </div>
      </div>
      <div
        id={props.title + 'png'}
        className='card-body'
        style={{ padding: props.padding }}
      >
        {props.type ? buttonGroup : null}
        {props.children}
      </div>
    </div>
  );
};

export default CardView;
