import React from 'react'
import styled from 'styled-components/macro'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { saveQuestion } from '../../redux/actions/modifyQuestion'
import { saveButtonSelector } from '../../redux/selectors'
import { withRouter } from 'react-router-dom'

const style = {
  button: {
    background: 'rgb(27,153,139)',
    borderColor: 'rgb(27,153,139)',
    position: 'fixed',
    top: '75px',
    right: '40px',
    fontSize: "12px",
    fontWeight: "500",
    height: "55px",
    width: "56px",
    borderRadius: "50%",
    textAlign: "center",
    zIndex: "2",
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
  }
}
const SaveButton = (props) => {
  // console.log(questionList)
  const { saveQuestion, questionList, disabled } = props;
  const NewButton = styled.button`
    display : flex;
    box-sizing: border-box;
    position: fixed;
    justify-content: center;
    align-items: center;
    background-color: #310E31;
    border: none;
    color: white;
    font-size: 15px;
    line-height: 15px;
    // width: 80px;
    // height: 80px;
    // left: 200px;
    // bottom: 50px;
    top: 65px;
    left: 280px;
    cursor: pointer;
    box-shadow: 1px 1px 5px -1px #586469;
    z-index: 10;
    // border-radius: 50%;
    :hover{
      cursor: pointer;
      box-shadow: 1px 1px 5px 2px #586469;
    }
    @media only screen and (max-width: 768px){
      left: 150px;
      font-size: 12px;
      width: 70px;
      height: 70px;
    }
    @media screen and (max-width: 600px){
      left: 50px;
      font-size: 10px;
      width: 60px;
      height: 60px;
    }
    @media screen and (max-width: 480px){
      left: 20px;
      font-size: 10px;
      width: 50px;
      height: 50px;
    }
  `

  // console.log(disabled)
  const saveProject = () => {
    saveQuestion(questionList)
    if (props.projectName && props.featureId.id) {
      if (!props.error.title && props.error.noData.length == 0) {
          props.saveToast();
          props.history.push('/app/questionbank');
      } 
    }
  }

  return (
    <Button
      className="btnHover"
      disabled={disabled}
      onClick={saveProject}
      size="sm"
      style={style.button}
    >
      {/* <FontAwesomeIcon icon={faSave} style={{ color: "white", marginRight: "5px" }} /> */}
      Save
    </Button>
  )
}



const mapStatetoProps = state => ({
  disabled: saveButtonSelector(state),
  questionList: state.question,
  projectName: state.projectName,
  featureId: state.featureId,
  error:state.error
})

const mapDispatchToProps = {
  saveQuestion: (data) => saveQuestion(data),
}

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(SaveButton))
