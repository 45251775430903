import React, { useState } from 'react' 
import { Carousel, CarouselItem, CarouselIndicators, CarouselCaption, Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 
import ProlificXMfeatures from './assets/Banner1.png' 
import ProlificXM_data from './assets/Banner2.png' 

import Customer_Effort from './assets/customer_effort_score.png'
import Customer_Journey_Map from './assets/customer_journey_map.png'
import Customer_Satisfaction from './assets/customer_satisfaction.png'
import Net_Promoter_Score from './assets/net_promoter_score.png'

import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../../../LandingPage/components/ReqDemo/ReqDemoModal'

import { css } from 'glamor'
import './slider.css' 

const Slider = (props) => { 
  const [activeIndex, setActiveIndex] = useState(0); 
  const [animating, setAnimating] = useState(false); 
  const [showFreeModal, setShowFreeModal] = useState(false); 
  const [successMsgModal, setSuccessMsgModal] = useState(false); 
  const [reqDemo, setReqDemo] = useState(false); 

  const h1header = css({ 
    color: 'rgb(52, 58, 64) !important',
    '@media(max-width: 600px)': { fontSize: '25px !important' }, 
    '@media(max-width: 390px)': { fontSize: '23px !important' }, 
    '@media(max-width: 360px)': { fontSize: '21px !important' }, 
    '@media(max-width: 335px)': { fontSize: '20px !important' }, 
    '@media(max-width: 320px)': { fontSize: '18px !important' }, 
  }) 

  const cancelModal = () => setReqDemo(false); 
  const closeModal = (msg = null) => { 
    if(msg) { 
      setSuccessMsgModal(true) 
    } 
    setShowFreeModal(false) 
  } 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }, 
    demoButton: { background: '#fff', border: '2px solid #1B998B' } 
  } 

  let style={ 
    ourBtn: { 
      backgroundColor: '#1B998B', borderColor: '#1B998B', 
      color: 'white',  borderRadius: '0%', 
      position: 'relative', bottom: '210px', left: '580px', margin: '5px'
    } 
  } 

  const items = [ 
    { 
      src: ProlificXMfeatures, 
      altText: 'ProlificXM Feature',
      header: 'Start Now...',
      text: 'Manage your Customer Experience, product Experience, Brand Experience, Employee Experience, Survey and many more across different platforms.'
    }, 
    { 
      src: Customer_Journey_Map, 
      altText: 'Customer Journey Map',
      header: 'Map Your Customer Journey',
      text: 'Its simple by using ProlificXM'
    }, 
    { 
      src: Customer_Satisfaction, 
      altText: 'Customer Satisfaction', 
      header: 'Customer Satisfaction is Crucial', 
      text: 'Measure Customer Satisfaction to turn your dissatisfied customers into satisfied customers.'
    }, 
    { 
      src: Customer_Effort, 
      altText: 'Customer Effort',
      header: 'Customer Effort Does Matter',
      text: 'Measure the effort of your customer to make it easier'
    }, 
    { 
      src: Net_Promoter_Score, 
      altText: 'Net Promoter Score',
      header: 'Net Promoter Score',
      text: 'NPS is the core measurement for customer experience management(CEM) programs'
    }, 
    { 
      src: ProlificXM_data, 
      altText: 'ProlificXM Data', 
      header: 'Your Data is Secured', 
      text: 'Move towards customer centric & data driven business approach' 
    } 
  ]; 

  const next = () => { 
    if (animating) return; 
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1; 
    setActiveIndex(nextIndex); 
  } 

  const previous = () => { 
    if (animating) return; 
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1; 
    setActiveIndex(nextIndex); 
  } 

  const goToIndex = (newIndex) => { if (animating) return; setActiveIndex(newIndex); } 

  const slides = items.map((item) => { 
    return ( 
      <CarouselItem 
        onExiting={() => setAnimating(true)} onExited={() => setAnimating(false)} 
        key={item.altText}> 
        <img className="img-fluid" src={item.src} alt={item.altText} style={{width: '100%'}}/> 
        <CarouselCaption captionHeader={item.header} captionText={item.text} /> 
        <button className="btn btn-sm sliderBtn btnHover"  
          style={{...style.btnStyle, fontWeight: '400'}} onClick={() => setShowFreeModal(true)}> 
          Try it free 
        </button> 
        <button className="btn btn-sm sliderBtn btnHover" style={{fontWeight: '400'}}
          onClick={() => setReqDemo(true)}> 
          Request Demo 
        </button> 
      </CarouselItem> 
    ); 
  }); 


  return ( 
    <> 
      <div className="container"> 
        <h1 
          {...h1header}
          style={{ textAlign: 'center', fontSize: '30px', marginTop: '25px', color: 'rgb(52, 58, 64)' }}>
          Most Advanced Experience Management & Survey Software
        </h1> 
      </div> 

      <Carousel className="hideCarousel" activeIndex={activeIndex} next={next} previous={previous} > 
        <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} /> 
        { slides } 
      </Carousel> 
      
      
      
      <>
        {/* Signup Modals for free, basic, standard, pro, advanced */} 
        {
          showFreeModal && <SignupModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} />
        } 
      </> 

      {/* Signup success message */}
      <>
        <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          {/* { closeModal() }  */}
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!!
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 
    
      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    </> 
  ); 
} 

export default Slider; 


