import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setActive } from '../../../redux/actions'
import './style.css';

const SelectProjects = props => {

  return (
    <label className="dislabel">
      Active:
        <input
        name="active"
        type="checkbox"
        checked={props.active}
        onChange={event => {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          // setActive(value)
          props.setActive(value, props.status, props.disId)
        }} />
    </label>
  )

}

const mapStateToProps = state => {
  return {
    active: state.question.active,
    status: state.question.status,
    disId: state.question.distributionId
  }
}

export default connect(mapStateToProps, { setActive })(SelectProjects);