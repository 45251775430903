import React, { Component } from 'react';
import Gauge from '../charts/gauge/Gauge';
import VerticalBar from '../charts/verticalbar/VerticalBar';
import Nps from '../nps/Nps';
import Barchart from '../charts/nivo/Barchart';
import './BorderHandler.css';

const styles = {
    root: {
        padding: '20px 5px'
    }
}

class DeatailsAnalysis extends Component {
    render() {
        const variables = this.props.variables;
        const length = variables.length;
        let borderClass = "";
        const classes = "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2";
        return (
            <>
                <div className="card mb-4" style={styles.root}>
                    <div className="card-body">
                        <div className="row justify-content-center">
                            {

                                variables.map((item, index) => {
                                    const itemName = item.name;
                                    let elementId = itemName.split(' ');
                                    elementId = elementId[0].replace(/[^a-zA-Z0-9 ]/g, "") + '_' + index;

                                    borderClass = (index === (length - 1)) ? " " : " border-right-cl-2";

                                    return <div key={index} className={classes + borderClass} style={{ marginBottom: "50px" }}>
                                        <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242", height: "25px", cursor: "pointer" }}>
                                            {item.name}
                                        </h6>
                                        <Gauge value={item.value} max={100} round={true} />
                                        <Nps nps={item.nps} />
                                        <VerticalBar data={item.variablePercent} />
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="card" style={styles.root}>
                    <div className="card-body">
                        <h6 style={{fontSize:"15px", fontWeight:"700", color:"#424242"}}>Source of responses</h6>
                        <Barchart barchart={this.props.barchart} isParcent={false} legend="Channel" />
                    </div>
                </div>

            </>
        );
    }
}

export default DeatailsAnalysis;