import baseUrl from './index'


//--------Contact---------------
export const AddNewContact = `${baseUrl}/api/prm/contact/addnew`
export const FetchAllContactByIndividualCompany = (companyProfileId) => `${baseUrl}/api/prm/contact/organization/${companyProfileId}`
export const GetIndividualContact = (contactId) => `${baseUrl}/api/prm/contact/id/${contactId}`


//-------Employee---------------
export const AddNewEmployee = `${baseUrl}/api/prm/employee/addnew`
export const FetchAllEmployee = `${baseUrl}/api/prm/employee/all`


//----------Lead-------------------
export const AddNewLead = `${baseUrl}/api/prm/lead/addnew`
export const GetAllLeadByProfile = (companyProfileId) => `${baseUrl}/api/prm/lead/get/${companyProfileId}` 


//--------Get All Company Profile----------
export const GetAllCompanyProfile = `${baseUrl}/api/profile/all`


//---------Industry-------------
export const AddNewIndustry = `${baseUrl}/api/prm/industry/addnew`
export const FetchAllIndustry = `${baseUrl}/api/prm/industry`

export const FetchIndividualIndustry = (industryId) => {
    return `${baseUrl}/api/prm/industry/details/${industryId}`
}

export const UpdateIndividualIndustry = (industryId) => {
    return `${baseUrl}/api/prm/industry/update/${industryId}`
}

export const DeleteIndividualIndustry = (industryId) => {
    return `${baseUrl}/api/prm/industry/delete/${industryId}`
}





//--------Company------------------
export const AddNewCompany = `${baseUrl}/api/prm/company/addnew`

export const FetchAllCompany = (companyProfileId) => {
    return `${baseUrl}/api/prm/company/list/${companyProfileId}`
}






//-------Product------------------
export const AddNewProduct = `${baseUrl}/api/prm/product/addnew`

export const FetchAllProduct = (companyProfileId) =>`${baseUrl}/api/prm/product/list/${companyProfileId}`






//-------Product Category---------
export const AddNewProductCategory = `${baseUrl}/api/prm/productCategory/addnew`

export const FetchAllProductCategory = `${baseUrl}/api/prm/productCategory/list/all`




//-----------------Brand------------------
export const AddNewBrand = `${baseUrl}/api/prm/brand/addnew`
export const FetchAllBrand = (companyProfileId) => `${baseUrl}/api/prm/brand/list/${companyProfileId}`

export const addBrand               = `${baseUrl}/api/prm/brand/` 

export const brands                 = `${baseUrl}/api/prm/brand/` 

export const singleBrand            = (id) => `${baseUrl}/api/prm/brand/${id}` 

export const updateBrand            = (id) => `${baseUrl}/api/prm/brand/${id}` 

export const deleteBrand            = (id) => `${baseUrl}/api/prm/brand/${id}` 

export const getProfileWiseAllBrand = (id) => `${baseUrl}/api/prm/brand/?companyProfileId=${id}` 

// export const parentCetagories = `${baseUrl}/api/prm/brandCetagory/parent` 







//-------------Brand Category------------
export const AddNewBrandCategory = `${baseUrl}/api/prm/brandCetagory/addnew`
export const FetchAllBrandCategory = `${baseUrl}/api/prm/brandCetagory/list/all` 

export const addBrandCategory = `${baseUrl}/api/prm/brandCetagory/` 

export const brandCetagories         = `${baseUrl}/api/prm/brandCetagory/` 
export const brandCetagoriesTrueOnly = `${baseUrl}/api/prm/brandCetagory/?active=true` 

export const singleBrandCetagory = (id) =>  `${baseUrl}/api/prm/brandCetagory/${id}` 

export const updateBrandCetagory = (id) =>  `${baseUrl}/api/prm/brandCetagory/${id}` 

export const deleteBrandCetagory = (id) =>  `${baseUrl}/api/prm/brandCetagory/${id}` 

export const parentCetagories = `${baseUrl}/api/prm/brandCetagory/parent` 
export const parentCetagoriesTrueOnly = `${baseUrl}/api/prm/brandCetagory/parent/?active=true` 








//--------Campaign-----------------
export const AddNewCampaign = `${baseUrl}/api/prm/campaign/addnew`

export const FetchAllCampaign = (companyProfileId) =>`${baseUrl}/api/prm/campaign/list/${companyProfileId}`

//-------Campaign Category---------
export const AddNewCampaignCategory =`${baseUrl}/api/prm/campaignType/addnew`

export const FetchAllCampaignCategory = `${baseUrl}/api/prm/campaignType/list/all`






//---------------------- Target Audience ---------------
export const AddNewTargetAudience = `${baseUrl}/api/prm/targetAudience/addnew`

export const FetchAllTargetAudience = `${baseUrl}/api/prm/targetAudience/list/all`






//------------------ Department --------------------
export const AddNewDepartment = `${baseUrl}/api/prm/department/addnew`
export const FetchAllDepartment = `${baseUrl}/api/prm/department/list/all`


export const CreateDepartment = `${baseUrl}/api/prm/department/` 

export const GetDepartments = `${baseUrl}/api/prm/department/`

export const GetSingleDepartment = (id) => `${baseUrl}/api/prm/department/${id}` 

export const UpdateDepartment = (id) =>  `${baseUrl}/api/prm/department/${id}` 

export const DeleteDepartment = (id) =>  `${baseUrl}/api/prm/department/${id}` 






//---------------- Designation --------------------
export const AddNewDesignation = `${baseUrl}/api/prm/designation/addnew`

export const FetchAllDesignation = `${baseUrl}/api/prm/designation/list/all`


export const CreateDesignation = `${baseUrl}/api/prm/designation/` 

export const GetDesignation = `${baseUrl}/api/prm/designation/`

export const GetSingleDesignation = (id) => `${baseUrl}/api/prm/designation/${id}` 

export const UpdateDesignation = (id) =>  `${baseUrl}/api/prm/designation/${id}` 

export const DeleteDesignation = (id) =>  `${baseUrl}/api/prm/designation/${id}` 







// ------------------ Industry ------------------- 
export const CreateIndustry = `${baseUrl}/api/prm/industry/` 
export const AllIndustry = `${baseUrl}/api/prm/industry/` 
export const GetSingleIndustry = (id) => `${baseUrl}/api/prm/industry/${id}`
export const UpdateIndustry = (id) => `${baseUrl}/api/prm/industry/${id}`
export const DeleteIndustry = (id) => `${baseUrl}/api/prm/industry/${id}`




//--------------------- Branch ------------------------
export const AddNewBranch = `${baseUrl}/api/prm/branch/addnew`

export const FetchAllBranch = `${baseUrl}/api/prm/branch/list/all`

