import styled from 'styled-components'

export const Question = styled.textarea`
  box-sizing: border-box;
  border: 1px solid grey;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`


export const SingleLine = styled.input`
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius:3px;
  background-color:#CFD8DC;
  resize: none;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

export const MultiLine = styled.textarea`
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius:3px;
  background-color:#CFD8DC;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`