import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import DatePicker from "react-datepicker";
import { FetchAllContactByIndividualCompany, GetAllCompanyProfile} from '../../../url/prmUrl';
import { Button, Input, Label, Body, Error, Aside, Modal } from '../StyledComponents'
import Alert from '../ShowAlert'

let leadSourceOptions = [
    {label: 'Cold Call', value: 'Cold Call'},{label: 'Existing Customer', value: 'Existing Customer'},
    {label: 'Self Generated', value: 'Self Generated'},{label: 'Employee', value: 'Employee'},
    {label: 'Partner', value: 'Partner'},{label: 'Public Relations', value: 'Public Relations'},
    {label: 'Direct Mail', value: 'Direct Mail'},{label: 'Conference', value: 'Conference'},
    {label: 'Trade Show', value: 'Trade Show'},{label: 'Website', value: 'Website'},
    {label: 'Social Media', value: 'Social Media'},{label: 'Word of Mouth', value: 'Word of Mouth'},
    {label: 'Other', value: 'Other'},
]

let salesStageOptions = [
    {label: 'Prospecting', value: 'Prospecting'},{label: 'Qualification', value: 'Qualification'},
    {label: 'Needs Analysis', value: 'Needs Analysis'},{label: 'Value Proposition', value: 'Value Proposition'},
    {label: 'Identify Decision Makers', value: 'Identify Decision Makers'},{label: 'Perception Analysis', value: 'Perception Analysis'},
    {label: 'Proposal or Price Quote', value: 'Proposal or Price Quote'},{label: 'Negotiation or Review', value: 'Negotiation or Review'},
    {label: 'Closed Win', value: 'Closed Win'},{label: 'Closed Lost', value: 'Closed Lost'},
]

let contactOptions = []
let profileId = []

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Contact:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="contactId"
                    value={values.contactId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.contactId}
                  /> 
                </div>
              </Label>
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.mobileNumber && touched.mobileNumber ? <Error>{errors.mobileNumber}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Mobile No:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="mobileNumber"
                    type="text"
                    placeholder="Mobile Number"
                    value={values.mobileNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.email && touched.email ? <Error>{errors.email}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="email"
                    type="text"
                    placeholder="Email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.profileId && touched.profileId ? <Error>{errors.profileId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Company Profile:
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="profileId"
                    value={values.profileId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.profileId}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Lead Source:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="leadSource"
                    value={values.leadSource}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.leadSource}
                  /> 
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  expected Close Date:
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="expectedCloseDate"
                  value={values.expectedCloseDate}
                  onChange={setFieldValue}
                  
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Amount:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="amount"
                    type="number"
                    placeholder="Enter Number"
                    value={values.amount }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Sales Stage:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="salesStage"
                    value={values.salesStage}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.salesStage}
                  /> 
                </div>
              </Label>
              {errors.probability && touched.probability ? <Error>{errors.probability}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Probability:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="probability"
                    type="number"
                    min="1"
                    max="100"
                    placeholder="Enter probability"
                    value={values.probability }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}

const DateSelect = ({ name, value, onChange }) => {
    return (
      <DatePicker
        selected={(value && new Date(value)) || null}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={val => {
          onChange(name, val);
        }}
      />
    );
  };

class MySelect extends React.Component {
  handleChange = value => {
    this.props.name==="contactId" ? this.props.onChange("contactId", value) :
    this.props.name==="leadSource" ? this.props.onChange("leadSource", value) :
    this.props.name==="profileId" ? this.props.onChange("profileId", value) :
    this.props.name==="salesStage" ? this.props.onChange("salesStage", value) : this.props.onChange("salesStage", value)
  };

  handleBlur = () => {
    this.props.name==="contactId" ? this.props.onBlur("contactId", true) :
    this.props.name==="profileId" ? this.props.onBlur("profileId", true) :
    this.props.name==="leadSource" ? this.props.onBlur("leadSource", true) :  
    this.props.name==="salesStage" ? this.props.onBlur("salesStage", true) :  this.props.onBlur("salesStage", true)
  };
 
  render() {
    return (
        <Select
          id="color"
          placeholder="Select one..."
          options={
            this.props.name==="contactId" ? contactOptions :
            this.props.name==="profileId" ? profileId :
            this.props.name==="leadSource" ? leadSourceOptions :
            this.props.name==="salesStage" ? salesStageOptions : null
            }
          //isMulti
          onChange={this.handleChange}  
          onBlur={this.handleBlur}     
          value={this.props.value}      
        />

    );
  }
}

const Company = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    name:'',
    mobileNumber:'',
    email:'',
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
      const fetchContact = await axios(FetchAllContactByIndividualCompany(localStorage.getItem("jwtID")));
       contactOptions = fetchContact.data.map(element=>({label: element.firstName+" "+element.lastName, value: element._id}))

       const allCompanyProfile = await axios(GetAllCompanyProfile);
       profileId = allCompanyProfile.data.map(element=>({label: element.user_Name, value: element.user_Id}))
     }
     catch (err) {
      // console.log("err")
     }

    //   const result = await axios(FetchAllCompany(localStorage.getItem('jwtID')));
    //   setEditable(prev=>{  //gets all previous editable value
    //     return {
    //       ...prev,
    //       loaded: result.data
    //     }
    //   })

    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

//   const opportunity = editable.loaded ?
//                   editable.loaded.Error? [] :
//                   editable.loaded.map(item=>item.companyName) : [] 
                  
  const schema = Yup.object().shape({
    name: Yup.string().required("Must Define a Name")
                      .min(3, "Minimum 3 characters"),
    profileId: Yup.string().required("Please select one..."),
    salesStage: Yup.string().required("Please select one..."),
    createdDate: Yup.date().required("Date of Birth required"),
    mobileNumber: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    email: Yup.string().email("Invalid Email"),
    probability: Yup.number().min(1, "Minimum 1").max(100, "Maximum 100")
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      contactId: '', 
      name: '',
      mobileNumber: '',
      email: '',
      profileId: '',
      leadSource:'',
      leadId: localStorage.getItem("jwtID"),
      expectedCloseDate: '',
      amount: '',
      salesStage: '',
      probability:'',
      assignToId:'',
      nextContactDate: '',
      nextMeettingOn: '',
      weightedRevenue:'',
      createdDate:'',
      remarks: '',
      nextStep: ''
    }),

    handleSubmit: (values, { setSubmitting }) => {
      values.contactId = values.contactId.value
      values.profileId = values.profileId.value
    //   axios.post(AddNewCompany, values)
    //   .then((response) => {
    //     if(!response.data.Error){
    //       setView(true)
    //     }
    //     else {
    //       window.alert("Uexpected Error! Duplicate Name found!")
    //     }
    //   })
    //   .catch((err) => console.log("Error"))

    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Company)