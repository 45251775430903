import React from 'react'
import PropTypes from 'prop-types'
import {Body, ButtonBody, Message, Button} from './styles'

const Confirmation = props=>{
  const {warning, accept, decline} = props

  const defaultFn = ()=>{
    console.log("Pressed Confirmation Button")
  }

  const acceptFn = accept?
    accept.fn? accept.fn : defaultFn
    :
    defaultFn

  const declineFn = decline?
    decline.fn? decline.fn : defaultFn
    :
    defaultFn

  return(
    <Body>
      <Message>{warning? warning : "Some warning message"}</Message>
      <ButtonBody>
        <Button onClick={acceptFn}>
          {accept? accept.value : "Yes"}
        </Button>
        <Button onClick={declineFn}>
          {decline? decline.value : "No"}
        </Button>
      </ButtonBody>
    </Body>
  )

}

Confirmation.propTypes = {
  warning: PropTypes.string,
  accept: PropTypes.shape({
    value: PropTypes.string,
    fn: PropTypes.func
  }),
  decline: PropTypes.shape({
    value: PropTypes.string,
    fn: PropTypes.func
  })
}

export default Confirmation