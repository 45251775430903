import React from 'react'
import image from './assets/education.png'
import banner from './assets/education_experience.png'
import {css} from 'glamor'

function Education() { 

  const contentTitle = css({textAlign: 'center', color: '#1B998B', fontSize: '22px', marginBottom: '20px'})
  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'})
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Education experience banner" {...bannerWidth} /> 
      </div> 

      <p {...text}>
        We all had been involved with education at some point in our life and can understand that the sector can always improve. The quality of education we received, the quality we want our next generation to receive and the quality which is ideal to ensure a better future is always in our thoughts. The sector is also changing rapidly to keep up with modern business, technology, and society. The objective of the organization, institutes is to build a structure that can adapt upcoming technology and shape the future generation according to society. Thousands of experts across the world conduct research to provide direction to the industry leaders where they should be heading. However, market research in this particular sector can be very costly and country-specific. To improve the quality inside an area, an organization or any society, we should be narrowing down the research perimeter within our population. For example, the experience of parents and the trust in K-12 or elementary schools, local colleges and universities or training institutes, the experience and confidence build-up of students through their institutional education can be hardly determined through educational market research. It is to each of our own. ProlificXM expert team has designed the Education Experience feature to serve that purpose. 
      </p> 

      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="Education Experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        Any top educational institutes are concerned about the quality of education they provide, the culture they develop practice and the image the hold towards the society. As much as the students pursue to become a part of those organizations, the organizations also want nothing but the best quality of students to serve society with the best quality education. To raise the bar such high, the institute must be looking forward to examining where they lack and which part can be improved more. The institute is nothing without its resources and assets. Therefore, to ensure quality, it must ensure that the people behind the institute are responsible, visionary and motivated. By people, we mean the administration, the instructors, the researchers, the students, the staffs and the governing body and all other persons related to the institute. The objectives of those surveys could be the education and understanding between teacher-student, the self-learning environment institute offers, the extracurricular activities, and the scope to learn from professionals, the practice of social and human behavior and the advancement in technology. The groups can use the surveys to evaluate individual and collective performance and exchange group feedback with each other for motivation. 
      </p>
      
      <p {...text}>
        Apart from educational instate, there are other organizations involved with education. Starting from the business organization that provides educational supplies, the organization that works with educational market research, the training institute related to teachers training and the conference that studies the further progression of education sectors. Each of these organizations must improve their experience to ensure that they are going at the same pace as the institute itself.
      </p> 

      <p {...text}> 
        We understand that it is difficult to decide from where to start. We suggest you take a look at ProlificXM Education Experience Templates designed by our expert team.
      </p> 
    </div> 
  ) 
} 

export default Education; 
