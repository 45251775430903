import React from 'react'
import styled from 'styled-components'
// import {connect} from 'react-redux'

// import {toggleDemographyField} from '../../redux/actions/modifyQuestion'

const Input = styled.textarea`
  box-sizing: border-box;
  border: 1px solid grey;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px 10px 5px 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

const Demography = ({item})=>{
  const {fields} = item
  return (
    <>
      {fields.name && 
      <label>
        Name
        <input type="text"/>
      </label>}

      {fields.gender && 
      <label>
        Gender
        <input type="text"/>
      </label>}

      {fields.email && 
      <label>
        Email
        <input type="text"/>
      </label>}

      {fields["contact number"] &&
      <label>
        Contact Number
        <input type="text"/>
      </label>}

      {fields.age &&
      <label>
        Age
        <input type="text"/>
      </label>}

      {fields["birth date"] &&
      <label>
        Date of Birth
        <input type="text"/>
      </label>}

      {fields["postal code"] &&
      <label>
        Postal Code
        <input type="text"/>
      </label>}

      {fields.district &&
      <label>
        District
        <input type="text"/>
      </label>}

      {fields.profession &&
      <label>
        Profession
        <input type="text"/>
      </label>}

      {fields["organization/institution"] &&
      <label>
        Organization/Institution
        <input type="text"/>
      </label>}
    </>
  )
}

export default Demography