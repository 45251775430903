import React from 'react'
import image from './assets/event.png'
import banner from './assets/event_experience.png'
import {css} from 'glamor'

function Event() { 

  const contentTitle = css({textAlign: 'center', color: '#1B998B', fontSize: '22px', marginBottom: '20px'})
  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Event experience banner" {...bannerWidth} /> 
      </div> 
      <p {...text}>
        It is not easy to restrict the word event with some perimeters or boundaries. An event could be an entertainment source like Live Concert, Stand-up Comedy show or a professional platform like training or education activity like Parents and Guardians meet up with teachers to discuss the further process of the students or simply crowdfunding. We as a whole in some way are related to it, either as an organizer or as participants. In any case, if anyone randomly asks us about the best event we have attended, it becomes tough to recall any of those in particular. Especially when we are not sure how we can define the term best, which metrics to use for our judgment and why should we consider it best. Therefore, from both organizers and participants’ perspectives, it is important to get feedback, pinpoint the additions and limitations, uses of resources, allocation of time and most importantly engagement of the participants to enhance the experience in the future. We understand that it is not possible to ensure a hundred percent perfection but the more we try, the better we can get out of it. ProlificXM has exclusively designed its’ Event Experience feature with an objective to provide the Users insightful analysis based on Omni Channel responses. 
      </p>

      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="event experience" className="img-fluid" style={{maxHeight: '350px'}} /> 
      </div> 
      <br /> 


      <h1 {...header}>Types of Events</h1>
      <p {...text}>
        Before discussing the metrics of Event Experience, we would like to discuss few popular event types generally takes place around us on regular basis. ProlificXM Event Experience does not include regular training, promotional activation or internal meeting of different organizations as it is covered under the Employee Experience and Brand Experience features. Likewise, any event to gather customer insights or market research are not included as these are covered under Customer Experience and Brand Experience features. Event Experience evaluates the following kind of events organized by professional or non-professional organizations for a group or combination of audiences or participants.
      </p> 
      <ol className="ml-5" style={{fontSize: '15px', color: 'rgb(52, 58, 64)'}}>
        <li className="mb-2">Entertainment Event: Live Concert, Stage Drama, Comedy Show, Movie or Documentary Premier, Art Show, Art Discussion, Book Publishing and Launching, Book Reading and Discussion etc.</li>
        <li className="mb-2">Professional Event: Professional Training, Introduction to New Technology, Conference and Seminar, Talk Show with Professionals etc.</li>
        <li className="mb-2">Self-Development Event: Skill Development Workshop, Competition, Contest, Tournament, Fitness and Rehabilitation Training etc.</li>
        <li className="mb-2">Social Event: Community Meet up, Institute re-union, Workplace re-union, Family Program such as Wedding and Birthday Party, Spiritual Gathering, Religious Festivals etc.</li>
        <li className="mb-2">Political Event: Election, Council Meeting, Summit, Assembly etc.</li>
        <li className="mb-2">Trade and Commercial Event: Product Launch, Auction, Fair, Convention, Exposition, Exhibition etc.</li>
        <li className="mb-2">Non-profit Event: Charity, Crowdfunding, Donation etc.</li>
      </ol> 

      <p {...text}>
        These are the regular events takes place around us. Apart from these, there are some occasional events which can also develop a XM strategy to get the Feedback of participants. We need to know where to look in order to improve the experience of an event.
      </p> 




      <h1 {...header}>Metrics of Event Experience</h1>
      <p {...text}>
        An event is a combination of venue, people, engagement and activities. Depending on the type, few factors may add but these factors will be present to serve the purpose. If we can segment the people of an event, we get following groups: Organizer, Staffs or Volunteers, Participants or Audience, and Media or Observers. From each groups’ perspective, the experience will be different. Therefore, it is important to get the true experiences of the individual participants directly and combine them to get the broad analysis so that organizers can make it better.
      </p> 

      <p {...text}>
        We have to use few metrics in order to combine the experiences of different individuals. Depending on the types of event, the metrics may vary as well. One of the most common metrics to use is Net Promoter Score. It is a popular metrics in Customer Experience (CX) to identify the Promoters and differentiate the ratio of Passive customers and the detractors. The same metrics can be implemented in most types of events to get the insight of promoters of the particular events. A promoter helps business grow and in a likewise manner, promoter can help the event organizers to get more audiences. If the organizers are professional organization, then it may serve them to make their Customer Experience better as well.
      </p> 

      <p {...text}>
        Another import metrics is the Effort Score (ES). We participate in events with certain purpose but we prioritize them as well. If the event is an entertainment event, we need to occupy certain time out of our leisure and take initiative to book for participation. Then it comes to venue, space allocation and other activities like engagement and participation. If the booking process is not easy, if the event length is very long or too short, if the venue is hard to find, if the spaces in the venue is not sufficient for the participants, it may repel the participants from participating and result in detractors. Therefore, organizers need to measure the Effort Score of the participants on different touch points using Omni Channels so that they can make it easier.
      </p> 

      <p {...text}>
        Satisfaction Score is another metrics which may play important role in Event Experience. Event Experience not only depends on less effort but also the on serving the purpose. For instance, if an event takes less effort for participants to join in but doesn’t serve its ultimate purpose or even it serves purpose well but loses its value to some extent, it may cause dissatisfaction among participants. We go to entertainment event knowing that the performer will be prepared, the arrangement will be top notch and the environment will be suitable for it. If either of these doesn’t meet our expectation, we opt-out from participating in future. It not only bring bad reputation to the organizers but also to performers. Likewise, a workshop needs to be coordinated by some expert of that field, a seminar speaker needs to be knowledgeable about the topic, an election needs to offer clarity and any re-union should bring togetherness. A simple discipline break may hamper the smoothness and result in dissatisfaction.
      </p> 


      <h1 {...header}>Benefits of Event Experience Management</h1> 
      <p {...text}> 
        It is very important to manage Event Experience for the organizers because of the Arrangement, the Engagement, and the Outcome – all of these shapes its future path. A very well-organized, purposeful event that ends intending to continue for betterment always gets the attention and coverage. Needless to say, it is a platform for the organizers to promote their Goal, an exposure of their innovation, ability, and strength. Sometimes organizers fail to understand how impactful the event might be but it turns out that the event impacts more than they expected. Such a thing may happen if the organizers lack vision and plan. However, collecting and combining experiential data may add a few insights and contribute to that plan to ensure that such a thing never happens.
      </p> 
      <p {...text}> 
        To conclude, we can agree that there is no limit to improve. The sky is surely the limit. However, that does not mean we should quit trying because of a few barriers. Mankind is born with incredible ability and we should not stop being better. If you cannot decide where to start with, take a look at ProlificXM Existing Template Library.
      </p> 
    </div> 
  ) 
} 

export default Event;
