import React, {useState} from 'react'
import image from './assets/cjm.png'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import './style.css'

function CJM(props) { 
  // const [showFreeModal, setShowFreeModal] = useState(false) 
  // const [successMsgModal, setSuccessMsgModal] = useState(false) 
  const [reqDemo, setReqDemo] = useState(false); 
  const cancelModal = () => setReqDemo(false); 
  // const closeModal = (msg = null) => {  
  //   if(msg) { setSuccessMsgModal(true) } 
  //   setShowFreeModal(false) 
  // } 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }, 
    demoButton: { background: '#fff', border: '2px solid #1B998B' } 
  } 

  let style={ 
    ourBtn: { 
      backgroundColor: '#1B998B', borderColor: '#1B998B', 
      color: 'white', borderRadius: '0%' 
    } 
  } 
  
  return ( 
    <div className="container boxStyle"> 
      <div className="row align-items-center"> 
        <div className="col-sm-12 col-md-6 col-lg-6 orderImg"> 
          <div className="py-sm-5 py-md-2 py-lg-5" style={{width: '100%'}}> 
            <img className="img-fluid" src={image} alt="Customer Journey Map" /> 
          </div> 
        </div> 
        <div className="col-sm-12 col-md-6 col-lg-6 orderText"> 
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <h6 className="display-4 heading mb-3"> 
              Map your customer journey 
            </h6> 
            <p className="text"> 
              A customer journey map is a visual representation that tells the story of the customer’s experience: from initial contact, through the process of engagement and into a long-term relationship. It helps to understand motivates your customers - what their needs are, their hesitations, and concerns. Although most organizations are reasonably good at gathering data about their customers, data alone fails to communicate the frustrations and experiences the customer experienced. Customer Journey map is the best storytelling tools in this circumstance.
            </p> 
            <Button className="btn btn-sm btnHover" 
              onClick={() => setReqDemo(true)}> 
              Request Demo  
            </Button> 
          </div> 
        </div> 
      </div> 

      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 

      <>
        {/* Signup Modals for free, basic, standard, pro, advanced */} 
        {
          // showFreeModal && <SignupModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} />
        } 
      </> 

      {/* Signup success message */}
      <>
        {/* <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!!
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal>  */}
      </> 

    </div> 
  ) 
} 

export default CJM; 
