import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { MainBody, Back } from './Styles'
import Question from '../Question'
import axios from 'axios'
import baseUrl from '../../url'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons'

const IndividualProject = props => {
  let [name, setName] = useState('')
  const { id } = props.match.params

  useEffect(() => {
    axios.get(`${baseUrl}/api/project/${id}`)
      .then(res => { setName(res.data.name) })
      .catch(err => console.log('server error on project-individual index page api call'))
  }, [])

  return (
    <MainBody>
      {/* Breadcrumb */}
      {
        (name || id === 'create') && <>
        <div className="d-flex mt-3">
          <h6 style={{padding:"4px 15px"}}>
            <span style={{ color: '#1B998B', cursor: 'pointer' }}
              onClick={() => { props.history.push('/app/projects') }}>
              Projects
            </span>
            {` / ${id === 'create' ? 'Create' : name}`}
          </h6>
          {/* <Back to={`/app/projects`}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} size="1x" />
          </Back> */}
        </div>
        <hr className="mt-0" />
        </>
      }
      <Question projectId={id} />
    </MainBody>
  )
}

export default withRouter(IndividualProject);

