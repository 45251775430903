import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronCircleRight} from '@fortawesome/free-solid-svg-icons'

import {Body} from './styles'

const ToggleMaximize = props=>{

  return(
    <Body>
      <FontAwesomeIcon size="1x" icon={faChevronCircleRight}/>
    </Body>
  )
}

export default ToggleMaximize