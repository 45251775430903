import styled from 'styled-components'

export const MainBody = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: column;
    overflow: visible;
    box-sizing: border-box;
  `