import isEqual from 'lodash.isequal'
import clone from 'lodash.clonedeep'
import union from 'lodash.unionwith'

export const formatByQuestion = (state)=>{
  const {
    response,
  } = state

  if(response.length===0){
    return state
  }
  let newState = clone(state)
  const newQuestionTally = []
  response.forEach(res=>{
    const {answers, channelID, channelType, responseDate, _id} = res
    answers.forEach(question=>{
      const foundQues = newQuestionTally.find(item=>{
        const {title:iTitle, type:iType} = item
        const {title: qTitle, type: qType} = question
        return (iTitle.trim()===qTitle.trim() && iType.trim()===qType.trim())
      })
      const foundQuesIndex = newQuestionTally.findIndex(item=>{
        const {title:iTitle, type:iType} = item
        const {title: qTitle, type: qType} = question
        return (iTitle.trim()===qTitle.trim() && iType.trim()===qType.trim())
      })
      let newQues
      if(!foundQues){
        newQues = clone(question)
        delete newQues.answer
        delete newQues.id
        if(newQues.type==='textBox'){
          delete newQues.text
        }
        newQues.answers = []
        newQues.answers.push({
          responseInfo: {
            _id,
            channelID,
            channelType,
            responseDate
          },
          ans: question.answer
        })
        newQuestionTally.push(newQues)
      }else{
        const questionInTally = clone(foundQues)
        delete questionInTally.answers

        const questionInResponse = clone(question)
        delete questionInResponse.answer
        delete questionInResponse.id

        newQues = clone(foundQues)
        newQues.answers.push({
          responseInfo: {
            _id,
            channelID,
            channelType,
            responseDate
          },
          ans: question.answer
        })

        if(!isEqual(questionInTally, questionInResponse)){
          const {type} = question
          switch(type){
            case 'likertScale':
              const newRatings = union(foundQues.ratings, question.ratings, isEqual)
              newQues.ratings = newRatings
              break
            case 'multipleChoice':
              const newChoices = union(foundQues.choices, question.choices, isEqual)
              newQues.choices = newChoices
              break
            case 'checkbox':
              const newOptions = union(foundQues.options, question.options, isEqual)
              newQues.options = newOptions
              break
            default: 
          }
          const newObjectives = union(foundQues.objectives, question.objectives, isEqual)
          newQues.objectives = newObjectives
        }

        newQuestionTally[foundQuesIndex] = newQues
      }
    })
  })

  newState.isLoading = false
  newState.formattedResponse = clone(newQuestionTally)

  return newState
}