import React, { useState, useEffect } from 'react'

import { MainBody } from './styles'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList'
import EditNextButton from '../../components/EditNextButton'

import collect from 'collect.js';
import { connect } from 'react-redux'
import { editDistributionQuestion, editDistributionConfig, selectProjectAndFetch, setActiveChannel } from '../../redux/actions'
import { BrowserRouter, Route, Switch, withRouter, Link, Redirect } from 'react-router-dom'
import axios from 'axios';
import baseUrl from '../../../../url/index'

const SelectPage = props => {

  const { disid } = props.match.params

  useEffect(() => {
    const fetchDistData = (disid) => {
      axios.get(`${baseUrl}/api/distribution/details/${disid}`).then(res => {
        props.editDistributionQuestion(res.data, disid)
        props.editDistributionConfig(res.data)
        props.selectProjectAndFetch(res.data.question.questionBank.projectOrder[0])
        if ('activeChannels' in res.data) { props.setActiveChannel(res.data.activeChannels) }
      })
    }
    fetchDistData(disid)
  }, [disid])

  return (
    <MainBody>
      <EditNextButton disid={disid} />
      <Configuration />
      <QuestionList />
    </MainBody>
  )
}

export default connect(null, { editDistributionQuestion, editDistributionConfig, selectProjectAndFetch, setActiveChannel })(SelectPage)