import React from 'react'
import image from './assets/profession.png'
import banner from './assets/professional_experience.png'
import {css} from 'glamor'

function Professional() { 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'})
  const bannerWidth = css({ 
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' }, 
  }) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Professional experience banner" {...bannerWidth} /> 
      </div> 

      <p {...text}>
        Whenever we need the service of any specific profession, the first thing we look for is the best in the business. Very often we end up taking the suggestion of people in our surroundings instead of relying on the advertisements of different local business providers. Studies of Referralrock revealed that 92% of consumers trust the recommendations from the people they know and about 50% recommendations become a leading factor for any purchase decision. Modern-day business entities develop or intend to develop a well-designed Customer Experience (CX) to retain customers and bring more. However, the local business providers cannot always ensure this as their time and resources are limited. In most cases, they cannot allocate budget to develop a strategy and take out the time to work on it. To clarify the above point, a healthcare service may have its own Customer Experience (CX) for patients but the health specialists, chiropractors, neurosurgeons, dentists, psychiatrists, and all other individual professionals cannot afford to follow accordingly. Also, their patients don’t always fall under the typical Customer Experience (CX) strategy because of the difference in service they provide. The metrics are different and so should be the questionnaires. Similarly, different local service provides like a locksmith, plumber or a lawyer, it is important to get client feedback but inconvenient to align with pre-designed Customer Experience. Keeping that in mind, ProlificXM has designed the Professional Experience feature to improve the experience of all service provider professionals working in a local territory. 
      </p>

      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="Professional Experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        It depends on your service type to determine what kind of improvements you can bring. Being a service provider in a specific territory or surrounding, you definitely face competition from similar skilled people. You can never be sure that you are the best in the business. It is because, your customers or clients never look to things from one pattern. The way you look at it, it changes with change of perception. You may think you are giving the best effort but it may not be enough. You do not get feedback from end users does not mean they do not have anything to say or share any improvement point. Perhaps it is because they do not understand how and when to share it. This is why you can offer a set of questionnaire or a survey and distribute through Omni Channels to reach your customers in every possible way. Bear in mind that, all your customers or clients will not respond it the more you get, the more you can improve. 
      </p>

      <p {...text}>
        After running a survey and collecting the responses, you need to know where to look at. After a typical survey, you usually have a pile of papers or email or soft documents which you need to analyze. This requires dedicated resources, time and of course, a lot of money. Using our Professional Experience feature you can ease that pain. Our cloud based XM will provide you real time response analysis and insights with an advanced Dashboard (For Pro Users Only). The filters will guide you towards specific time period of responses. Looking at those insights, you will be able to determine where you need to improve. Then you can directly jump into decision making based on the insights.
      </p> 

      <p {...text}> 
        There are few metrics which can be used to justify the responses and provide the analysis. As our advanced analysis dashboard works on metrics, you can empower following metrics and create your questionnaires on that basis. Some common metrics across different service providers would be: Satisfaction Score, Effort Score, Net Promoter Score, Retention Rate, and Churn Rate. The details of all those metrics are mentioned on our Metrics of XM page. You can also customize the metrics as per your need and add more if needed. We understand that it is difficult to decide from where to start. We suggest you take a look at ProlificXM Professional Experience Templates designed by our expert team.
      </p> 
    </div> 
  ) 
} 

export default Professional; 
