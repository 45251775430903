import React, { useState, useEffect } from 'react'
import axios from 'axios'
import baseUrl, { pexUrl } from '../../../../url/index'
import { Link } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import collect from 'collect.js';

import { Container, PreviewBody, SaveButton, SubmitButton, DistributeButton, PreviewButton } from './styles'
import './style.css'
import BackButton from '../../components/BackButton'
import ProjectGroup from '../../container/ProjectGroup'
import Header from '../../components/Header'
import AdditionalContentBar from '../../components/AdditionalContentBar'

import ActiveChannelDeviceList from '../../components/ActiveChannel/DeviceList'
import ActiveChannelOpenLink from '../../components/ActiveChannel/OpenLink'
import ActiveChannelQrCode from '../../components/ActiveChannel/QrCode'

import { sortQuestion, sortProjects, addCard, pushConfiguration, updateConfiguration, setProfileIdInState, fetchChannels, fetchDeviceLists, setDeviceActive, setDistDevice, distributeConfiguration, setOpenLink, updateOpenLink, setOpenLinkInDistribution, setQRCode, setGenQrCode, setQrCodeInDistribution, setProgressbar } from '../../redux/actions'
import { apiDataGeneratorSelector, activeDeviceSelector, saveButtonSelector } from '../../redux/selectors'
import QRCode from 'qrcode.react';

const PushChannel = props => {
  useEffect(() => {
    props.fetchDeviceLists(localStorage.getItem('jwtProfileId'))
    // console.log('props.profileId', props.profileId)
  }, [])
  return (
    <div>
      <h4>{props.channel.channelType}</h4>
      {props.children}
    </div>
  )
}

const Device = props => {
  return (
    <label className="dislabel" style={{ margin: '10px' }}>
      {props.item.name}
      <input
        name="active"
        type="checkbox"
        checked={props.item.status === 'Active' ? true : false}
        onChange={event => {
          const target = event.target;
          const value = target.type === 'checkbox' ? target.checked : target.value;
          props.setDeviceActive(value, props.index);
          props.deviceStatusOnClick();
        }
        }
      />
    </label>
  )
}

const OpenLink = props => {

  const [active, setActive] = useState(false)

  return (
    <div>
      <h4>Open Link</h4>
      <label className="dislabel" style={{ margin: '10px' }}>
        Active open link
          <input
          name="active"
          type="checkbox"
          checked={active}
          onChange={event => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            setActive(value)
            props.setStatus(value)
          }
          }
        />
      </label>

      {props.openlink.hasOwnProperty('hashID') && <button className="generate" onClick={() => { props.updateLink(props.openlink.distributionId, [{ "propName": "link", "value": `${pexUrl}/openlink/${props.openlink.hashID}` }]); }}>Generate Open Link</button>}

      {props.openlink.hasOwnProperty('link') && <a href={props.openlink.link} target="_blank" style={{ fontSize: '.7em', wordBreak: 'break-all', color: 'rgba(0,0,0,.8)', fontWeight: '500', margin: '10px' }}>{props.openlink.link}</a>}

    </div>
  )

}

const QrCode = props => {
  const [active, setActive] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false)

  const downloadQR = value => {
    const imageFile = `${value}.png`
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = imageFile;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  // const updateQrCode = ({ distributionId, hashId }) => {

  //   const canvas = document.getElementById("qrcode");
  //   const pngUrl = canvas
  //     .toDataURL("image/png")
  //     .replace("image/png", "image/octet-stream");
  //   props.genQrCode(distributionId, { 'image': pngUrl, 'link': `${pexUrl}/qrcode/${hashId}` });
  // }

  return (
    <div>
      <h4>QR Code</h4>
      <label className="dislabel" style={{ margin: '10px' }}>
        Active QR Code
          <input
          name="active"
          type="checkbox"
          checked={active}
          onChange={event => {
            const target = event.target;
            const value = target.type === 'checkbox' ? target.checked : target.value;
            setActive(value)
            props.setQrCode(value)
          }
          }
        />
      </label>
      {
        props.qRCode.hasOwnProperty('hashId') &&
        <button className="generate" onClick={() => { setShowQrCode(true); props.genQrCode(props.disId); }}>Generate QR Code</button>
      }

      {showQrCode &&
        <div>
          <QRCode
            id="qrcode"
            value={`${pexUrl}/qrcode/${props.qRCode.hashId}`}
            size={250}
            level={"H"}
            includeMargin={true}
          />
          <button className="generate" onClick={() => downloadQR(props.disId)}> Download </button>
          {/* <a style={{ color: 'rgba(0,0,0,.8)', margin: '10px' }} onClick={() => updateQrCode(props.qRCode)}> Update </a> */}
        </div>
      }

    </div>

  )
}

const SortAndPreview = props => {

  const { sortQuestion, sortProjects, addCard, dataToPush, pushConfiguration, updateConfiguration, distributeConfiguration, enableSave, setProfileIdInState, disId, fetchChannels, statusDevice, fetchDeviceLists, activeDevice, deviceLists, setDeviceActive, setDistDevice, status, statusOpenLink, setOpenLink, updateOpenLink, setOpenLinkInDistribution, openlinks, setQRCode, statusQrCode, qRCode, setGenQrCode, setQrCodeInDistribution, activeChannels, order, progressbar } = props
  const enablePreview = !enableSave

  const [profileId, setProfileId] = useState(localStorage.getItem('jwtProfileId'));
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [linkStatus, setLinkStatus] = useState(false);
  const [qrCodeStatus, setQrCodeStatus] = useState(false)
  // console.log(props.activeChannels)
  const openlink = {
    "name": "Open link",
    "createdByCompanyID": profileId,
    "status": linkStatus,
    "distributionId": disId
  }

  const qrCode = {
    "name": "QR Code",
    "distributionId": disId,
    "createdByCompanyID": profileId,
    "status": qrCodeStatus
  }

  useEffect(() => {
    // const jwtId = localStorage.getItem('jwtID')
    // setUserId(jwtId)
    // setProfileIdInState(jwtId);
    // const jwtProfileId = localStorage.getItem('jwtProfileId')
    // setProfileId(jwtProfileId)
    setProfileIdInState(profileId);
    if (status === 'SUBMITTED') {
      fetchChannels(profileId)
    }
  },
    []
  )

  const deviceStatusOnClick = () => {
    let deviceStatus = collect(deviceLists).where('status', '=', 'Active').isNotEmpty();
    setDeviceStatus(deviceStatus)
  }

  const setOpenLinkStatus = value => {
    setLinkStatus(value)
  }

  const setQrCode = value => {
    setQrCodeStatus(value)
  }

  const handleSaveClick = () => {
    const { data } = dataToPush
    pushConfiguration(data, profileId)
  }

  const handleSubmitClick = () => {
    const { data } = dataToPush
    updateConfiguration(data, profileId, disId)
    fetchChannels(profileId)
  }

  const handleDistributeClick = () => {
    const { data } = dataToPush
    if (deviceStatus) { setDistDevice(deviceLists, disId) }
    if (statusOpenLink) { setOpenLink(openlink) }
    if (statusQrCode) { setQRCode(qrCode) }
    distributeConfiguration(data, activeDevice, profileId, disId)
  }

  const updateLink = (id, obj) => {
    updateOpenLink(id, obj)
    setOpenLinkInDistribution(id, { id: openlinks._id, hashId: openlinks.hashID })
  }

  const genQrCode = (id) => {
    // setGenQrCode(id, obj)
    setQrCodeInDistribution(id, { id: qRCode._id, hashId: qRCode.hashId })
  }

  const onDragEnd = ({ destination, source, draggableId, type, }) => {
    if (destination) {
      if (type === "projects") {
        if (source.droppableId === "additionalCardsList") {

          addCard(destination, draggableId)
          return
        }
        sortProjects(destination, source, draggableId)
        return
      }
      sortQuestion(destination, source, draggableId)
    }
  }

  return (
    <Container>

      <BackButton disid={props.match.params.disid} />

      {status === '' && <SaveButton type='button' onClick={handleSaveClick}>Save</SaveButton>}

      {status === 'DRAFT' && <SubmitButton type='button' onClick={handleSubmitClick}>Submit</SubmitButton>}

      {status === 'SUBMITTED' && <DistributeButton type='button' onClick={handleDistributeClick} disabled={!deviceStatus && !linkStatus && !qrCodeStatus}>Distribute</DistributeButton>}

      {status !== '' && !props.match.params.disid && <PreviewButton as={Link} to="/app/distribution/create/preview">Preview</PreviewButton>}
      {status !== '' && props.match.params.disid && <PreviewButton as={Link} to={`/app/distribution/edit/preview/${props.match.params.disid}`}>Preview</PreviewButton>}

      <PreviewBody>
        <Header />
        <DragDropContext onDragEnd={onDragEnd}>
          <AdditionalContentBar />
          <ProjectGroup />
        </DragDropContext>
        <div className="channel-wrapper">

          <label className="dislabel" style={{ margin: '10px' }}>
            Show question count? (Not applicable for conditional question)
              <input
              name="progressbar"
              type="checkbox"
              checked={progressbar === true ? true : false}
              onChange={event => {
                const target = event.target;
                const value = target.type === 'checkbox' ? target.checked : target.value;
                props.setProgressbar(value);
              }
              }
            />
          </label>

          {statusDevice !== undefined &&
            <PushChannel profileId={profileId} channel={statusDevice} fetchDeviceLists={fetchDeviceLists}>
              {deviceLists.length > 0 && deviceLists.map((item, index) => <Device deviceStatusOnClick={deviceStatusOnClick} index={index} item={item} key={item._id} setDeviceActive={setDeviceActive} />)}
            </PushChannel>
          }
          {
            statusOpenLink !== undefined &&
            <OpenLink setStatus={setOpenLinkStatus} disId={disId} updateLink={updateLink} openlink={openlinks} />
          }
          {
            statusQrCode !== undefined &&
            <QrCode setQrCode={setQrCode} disId={disId} qRCode={qRCode} genQrCode={genQrCode}></QrCode>
          }
        </div>
        {status === 'DISTRIBUTED' &&
          <div className="channel-wrapper">
            {'device' in activeChannels && activeChannels.device.length > 0 &&
              <ActiveChannelDeviceList deviceList={activeChannels.device} />
            }
            {'openlink' in activeChannels &&
              <ActiveChannelOpenLink openlink={activeChannels.openlink} />
            }
            {'qrcode' in activeChannels &&
              <ActiveChannelQrCode qrcode={activeChannels.qrcode} disId={disId} />
            }

          </div>
        }
      </PreviewBody>

    </Container>
  )
}

const mapStateToProps = state => ({
  dataToPush: apiDataGeneratorSelector(state),
  enableSave: saveButtonSelector(state),
  activeDevice: activeDeviceSelector(state),
  status: state.question.status,
  progressbar: state.question.progressbar,
  // profileId: state.question.profileId,
  disId: state.question.distributionId,
  order: state.question.order,
  statusDevice: state.question.pushChannelList.find(channel => channel.channelType === 'device'),
  statusOpenLink: state.question.pushChannelList.find(channel => channel.channelType === 'openLink'),
  statusQrCode: state.question.pushChannelList.find(channel => channel.channelType === 'qrCode'),
  deviceLists: state.question.deviceList,
  openlinks: state.question.openLink,
  qRCode: state.question.qRCode,
  activeChannels: state.question.activeChannels
})

const mapDispatchToProps = {
  sortQuestion,
  sortProjects,
  addCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  setOpenLink,
  updateOpenLink,
  setOpenLinkInDistribution,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar
}

export default connect(mapStateToProps, mapDispatchToProps)(SortAndPreview)