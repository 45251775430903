import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';

import {
	questionDataSelector,
	answerFieldValueSelector,
} from '../../redux/selectors';
import { updateData, startDate } from '../../redux/actions';
import './style.css';
import { Fields, QuestionCard, QuestionTitle } from '../styles';
import { Input } from './styles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

whyDidYouRender(React, {
	onlyLogs: true,
	titleColor: 'green',
	diffNameColor: 'aqua',
});

const DateRange = (props) => {
	const { data, answer, updateData, startDate } = props;
	const [startDateRange, setStartDateRange] = useState(new Date());
	const [endDateRange, setEndDateRange] = useState(
		new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
	);

	useEffect(() => {
		if (data.hasOwnProperty('id')) {
			startDate(new Date());
		}
	}, [data]);

	useEffect(() => {
		if (answer.hasOwnProperty('startDate')) {
			setStartDateRange(answer.startDate);
			setEndDateRange(answer.endDate);
		}
	}, []);

	useEffect(() => {
		const value = { startDate: startDateRange, endDate: endDateRange };
		console.log(value);
		updateData(value);
	}, [startDateRange, endDateRange]);

	return (
		<QuestionCard>
			<QuestionTitle>{data.title}</QuestionTitle>

			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					alignItems: 'center',
					gap: '10px',
				}}
			>
				<DatePicker
					selected={startDateRange}
					onChange={(date) => {
						setStartDateRange(date);
					}}
					dateFormat='dd/MM/yyyy'
					selectsStart
					startDate={startDateRange}
					endDate={endDateRange}
				/>
				<DatePicker
					selected={endDateRange}
					onChange={(date) => {
						setEndDateRange(date);
					}}
					dateFormat='dd/MM/yyyy'
					selectsEnd
					startDate={startDateRange}
					endDate={endDateRange}
					minDate={startDateRange}
				/>
			</div>
		</QuestionCard>
	);
};

DateRange.whyDidYouRender = true;

const mapStateToProps = (state) => {
	return {
		data: questionDataSelector(state),
		answer: answerFieldValueSelector(state),
	};
};

const mapDispatchToProps = {
	updateData,
	startDate,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(DateRange));
