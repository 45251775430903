import domtoimage from "dom-to-image";
import { saveAs } from 'file-saver';
// import html2canvas from 'html2canvas'



export const downlaodImage = (id, title) => {
    domtoimage.toJpeg(document.getElementById(id), { quality: 0.95 })
        .then(function (dataUrl) {
            let link = document.createElement('a');
            link.download = title + '.jpeg';
            link.href = dataUrl;
            link.click();
        });
};

export const downloadPNG = (id, title) => {
    domtoimage.toBlob(document.getElementById(id))
        .then(function (blob) {
            window.saveAs(blob, title + '.png');
        });
};



/*export const downloadPNG = ( id, imageFormat) => {
    const input = document.getElementById(id);
    html2canvas(input)
        .then((canvas) =>{
            let imgData = canvas.toDataURL('image/png').replace("image/png", "image/octet-stream");
            downloadURL(imgData, id);
        });
};

 const downloadURL = (imgData, id) =>{
    const a = document.createElement('a');
    a.href = imgData.replace("image/png", "image/octet-stream");
    a.download = id +'.png';
    a.click();
};*/
