import React, { useReducer, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';
import {
  faTabletAlt,
  faLaptopCode,
  faMailBulk,
  faGlobeAmericas,
  faCommentAlt,
  faUserCheck,
  faVideo,
  faHeadset,
  faLink,
  faUserCog,
  faPlug,
  faQrcode,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Select from 'react-select';

import {
  Body,
  StyledLink,
  StyledButton,
  Channel,
  Banner,
  ChannelList,
  ChannelTypeItem,
  Checkbox,
  CheckboxContainer,
} from '../../StyledComponents';
import { Route, Switch, withRouter } from 'react-router-dom';
import Logo from '../../../../resources/PEX_New.png';

import Device from './Device';
import Software from './Software';
import Email from './Email';
import Website from './Website';
import Facbook from './Facbook';
import Twitter from './Twitter';
import Linkedin from './Linkedin';
import Instagram from './Instagram';
import Youtube from './Youtube';
import Sms from './Sms';
import ServiceCenter from './ServiceCenter';
import CcCamera from './CcCamera';
import CallCenter from './CallCenter';
import OpenLink from './OpenLink';
import Manual from './Manual';
import QrCode from './QrCode';
import Offline from './Offline';
import InApp from './InApp';
import { DotLoader } from '../../../Loader';

import {
  getActiveChannelList,
  getProfileIdWiseChannels,
  putProfile,
} from '../../../../url';

const CustomSelect = (props) => {
  return (
    <Select
      {...props}
      theme={(base) => ({
        ...base,
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1,
        },
      })}
      styles={{
        container: (style) => {
          return {
            ...style,
            minHeight: '1px !important',
            boxSizing: 'border-box',
            padding: '0 5px 5px 5px',
          };
        },
        control: (base) => {
          return {
            ...base,
            boxShadow: '1px 1px 5px 1px #D9D9D9',
            padding: '1px 2px',
            background: 'white',
            ':hover': {
              ...base[':hover'],
              boxShadow: '1px 1px 10px 2px #D9D9D9',
              borderColor: '#394662 !important',
            },
            ':active': {
              ...base[':active'],
              borderColor: '#394662 !important',
            },
          };
        },
        singleValue: (style) => {
          return {
            ...style,
            padding: '0 5px',
          };
        },
        menu: (base) => ({
          ...base,
          boxSizing: 'border-box',
          marginTop: 0,
          width: 'calc(100% - 10px)',
        }),
        option: (base) => ({
          ...base,
          padding: 2,
        }),
      }}
      isSearchable={false}
    />
  );
};

const ChannelsConfig = (props) => {
  const [selectedChannel, setSelectedChannel] = React.useState('');

  const RECEIVE_CHANNEL_DATA = 'RECEIVE_CHANNEL_DATA';
  const SET_CHANNEL_DISTRIBUTION_TYPE = 'SET_CHANNEL_DISTRIBUTION_TYPE';
  const SET_CHANNEL_STATUS = 'SET_CHANNEL_STATUS';
  const SET_LOADING = 'SET_LOADING';

  const initialState = {
    isLoading: true,
    channelList: [],
    channelProperties: {},
    pullChannels: [],
    pushChannels: [],
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case RECEIVE_CHANNEL_DATA: {
        // console.log(`Running: ${RECEIVE_CHANNEL_DATA}`)
        const {
          payload: { properties, list, pullChannelList, pushChannelList },
        } = action;
        const newState = {
          ...state,
          channelProperties: { ...properties },
          channelList: [...list],
          pullChannels: [...pullChannelList],
          pushChannels: [...pushChannelList],
        };
        return newState;
      }

      case SET_CHANNEL_DISTRIBUTION_TYPE: {
        // console.log(`Running: ${SET_CHANNEL_DISTRIBUTION_TYPE}`)
        const {
          payload: { channel, value },
        } = action;
        const channelId = state.channelProperties[channel].id;
        const newState = { ...state };
        newState.channelProperties[channel].channelMode = value;
        switch (value) {
          case 'pull': {
            if (!newState.pullChannels.includes(channelId)) {
              newState.pullChannels = newState.pullChannels.concat([channelId]);
            }
            newState.pushChannels = newState.pushChannels.filter(
              (item) => item !== channelId
            );
            break;
          }
          case 'push': {
            if (!newState.pushChannels.includes(channelId)) {
              newState.pushChannels = newState.pushChannels.concat([channelId]);
            }
            newState.pullChannels = newState.pullChannels.filter(
              (item) => item !== channelId
            );
            break;
          }
          case 'both': {
            // console.log('running here')
            if (!newState.pullChannels.includes(channelId)) {
              newState.pullChannels = newState.pullChannels.concat([channelId]);
            }
            if (!newState.pushChannels.includes(channelId)) {
              newState.pushChannels = newState.pushChannels.concat([channelId]);
            }
            break;
          }
          default:
        }
        return newState;
      }

      case SET_CHANNEL_STATUS: {
        // console.log(`Running: ${SET_CHANNEL_STATUS}`)
        const {
          payload: { channel, status },
        } = action;
        const newState = {
          ...state,
          channelProperties: {
            ...state.channelProperties,
            [channel]: {
              ...state.channelProperties[channel],
              isActive: status,
            },
          },
        };
        if (!status) {
          const channelId = state.channelProperties[channel].id;
          newState.pullChannels = newState.pullChannels.filter(
            (item) => item !== channelId
          );
          newState.pushChannels = newState.pushChannels.filter(
            (item) => item !== channelId
          );
        } else {
          const channelId = state.channelProperties[channel].id;
          switch (state.channelProperties[channel].distributionType) {
            case 'pull': {
              newState.pullChannels = newState.pullChannels.concat([channelId]);
              newState.channelProperties[channel].channelMode = 'pull';
              break;
            }
            case 'push': {
              newState.pushChannels = newState.pushChannels.concat([channelId]);
              newState.channelProperties[channel].channelMode = 'push';
              break;
            }
            case 'both': {
              if (!newState.pullChannels.includes(channelId)) {
                newState.pullChannels = newState.pullChannels.concat([
                  channelId,
                ]);
              }
              if (!newState.pushChannels.includes(channelId)) {
                newState.pushChannels = newState.pushChannels.concat([
                  channelId,
                ]);
              }
              newState.channelProperties[channel].channelMode = 'both';
              break;
            }
            default:
          }
        }
        return newState;
      }

      case SET_LOADING: {
        // console.log(`Running: ${SET_LOADING}`)
        const { value } = action;
        const newState = {
          ...state,
          isLoading: value,
        };
        return newState;
      }

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const renderOnce = useRef(false); //Used to decide whether the 2nd useEffect fucntion should run to update according to the channel changes

  const getData = async () => {
    const profileId = localStorage.getItem('jwtProfileId');

    try {
      const [
        { data: channelData },
        {
          data: { pullChannelList, pushChannelList },
        },
      ] = await axios.all([
        axios.get(getActiveChannelList),
        axios.get(getProfileIdWiseChannels(profileId)),
      ]);
      const formattedData = {};
      const channelList = [];
      channelData.forEach((item) => {
        let channelDistributionMode;
        if (
          pullChannelList.includes(item._id) &&
          pushChannelList.includes(item._id)
        ) {
          channelDistributionMode = 'both';
        } else if (pullChannelList.includes(item._id)) {
          channelDistributionMode = 'pull';
        } else if (pushChannelList.includes(item._id)) {
          channelDistributionMode = 'push';
        } else {
          channelDistributionMode = item.distributionType;
        }
        formattedData[item.channelType] = {
          isActive:
            pullChannelList.includes(item._id) ||
            pushChannelList.includes(item._id),
          distributionType: item.distributionType,
          id: item._id,
          channelMode: channelDistributionMode,
        };
        channelList.push({ id: item._id, channelType: item.channelType });
      });
      dispatch({
        type: RECEIVE_CHANNEL_DATA,
        payload: {
          properties: { ...formattedData },
          list: [...channelList],
          pullChannelList: [...pullChannelList],
          pushChannelList: [...pushChannelList],
        },
      });
    } catch (err) {
    } finally {
      dispatch({ type: SET_LOADING, value: false });
      if (!renderOnce.current) {
        renderOnce.current = true;
      } else {
        renderOnce.current = false;
      }
    }
  };

  const updateChannels = async () => {
    if (renderOnce.current) {
      const userId = localStorage.getItem('jwtProfileId');
      const payload = {
        pullChannelList: [...state.pullChannels],
        pushChannelList: [...state.pushChannels],
      };
      try {
        await axios.put(putProfile(userId), payload);
      } catch (err) {
        console.log(err);
      }
      // console.log('channels:',state.channelProperties)
      // console.log(`pull:${state.pullChannels}`, `push:${state.pushChannels}`)
    }
  };

  //Runs only during first render
  useEffect(() => {
    dispatch({ type: SET_LOADING, value: true });
    getData();
  }, []);

  //Runs only when the pullChannels and pushChannels lists are updated in state but not at the first render
  useEffect(() => {
    if (renderOnce.current) {
      updateChannels();
      dispatch({ type: SET_LOADING, value: true });
      getData();
      // console.log('channels:',state.channelProperties)
      // console.log(`pull:${state.pullChannels}`, `push:${state.pushChannels}`)
    }
  }, [state.pullChannels, state.pushChannels]);

  const channels = state.channelProperties;

  const distributionTypeOptions = [
    { label: 'Pull', value: 'pull' },
    { label: 'Push', value: 'push' },
    { label: 'Both', value: 'both' },
  ];

  const handleCheckbox = (event) => {
    const { name } = event.target;
    const value = !state.channelProperties[name].isActive;
    dispatch({
      type: SET_CHANNEL_STATUS,
      payload: { channel: name, status: value },
    });
  };

  const createHandleSelect = (channel) => {
    return ({ value }) => {
      dispatch({
        type: SET_CHANNEL_DISTRIBUTION_TYPE,
        payload: { channel, value },
      });
    };
  };

  const find = (channel) => {
    return distributionTypeOptions.find(
      (item) => item.value === state.channelProperties[channel].channelMode
    );
  };

  const isDisabled = (channel) => {
    return (
      state.channelProperties[channel].distributionType !== 'both' ||
      !state.channelProperties[channel].isActive
    );
  };

  const renderSelectedChannel = () => {
    switch (selectedChannel) {
      case 'device':
        return (
          <Device
            activestatus={
              state.channelProperties['device'] &&
              state.channelProperties['device'].isActive
            }
          />
        );

      case 'openLink':
        return (
          <OpenLink
            activestatus={
              state.channelProperties['openLink'] &&
              state.channelProperties['openLink'].isActive
            }
          />
        );

      case 'qrCode':
        return (
          <QrCode
            activestatus={
              state.channelProperties['qrCode'] &&
              state.channelProperties['qrCode'].isActive
            }
          />
        );

      default:
        return (
          <div
            css={`
              color: grey;
            `}
          >
            Select a Channel...
          </div>
        );
    }
  };

  return state.isLoading ? (
    <DotLoader />
  ) : (
    <Body
      css={`
        padding: 0;
        margin-bottom: 20px;
      `}
    >
      <Banner>Channel List</Banner>
      <ChannelList>
        {channels['device'] && (
          <ChannelTypeItem>
            <StyledButton
              onClick={() => setSelectedChannel('device')}
              className={selectedChannel === 'device' ? 'active' : ''}
            >
              <FontAwesomeIcon
                icon={faTabletAlt}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #4aaee2;
                `}
              />
              Device
            </StyledButton>

            <CheckboxContainer>
              <Checkbox
                id='device'
                name='device'
                checked={state.channelProperties['device'].isActive}
                onChange={handleCheckbox}
              />
              <label
                style={{ color: 'rgb(52, 58, 64', margin: 0 }}
                htmlFor='device'
              >
                Active
              </label>
            </CheckboxContainer>

            <CustomSelect
              options={distributionTypeOptions}
              value={find('device')}
              isDisabled={isDisabled('device')}
              onChange={createHandleSelect('device')}
            />
          </ChannelTypeItem>
        )}

        {channels['openLink'] && (
          <ChannelTypeItem>
            <StyledButton
              onClick={() => setSelectedChannel('openLink')}
              className={selectedChannel === 'openLink' ? 'active' : ''}
            >
              <FontAwesomeIcon
                icon={faLink}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #4aaee2;
                `}
              />
              Open Link
            </StyledButton>

            <CheckboxContainer>
              <Checkbox
                id='openLink'
                name='openLink'
                checked={state.channelProperties['openLink'].isActive}
                onChange={handleCheckbox}
              />
              <label
                style={{ color: 'rgb(52, 58, 64', margin: 0 }}
                htmlFor='openLink'
              >
                Active
              </label>
            </CheckboxContainer>

            <CustomSelect
              options={distributionTypeOptions}
              value={find('openLink')}
              isDisabled={isDisabled('openLink')}
              onChange={createHandleSelect('openLink')}
            />
          </ChannelTypeItem>
        )}

        {channels['qrCode'] && (
          <ChannelTypeItem>
            <StyledButton
              onClick={() => setSelectedChannel('qrCode')}
              className={selectedChannel === 'qrCode' ? 'active' : ''}
            >
              <FontAwesomeIcon
                icon={faQrcode}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #4aaee2;
                `}
              />
              QR Code
            </StyledButton>

            <CheckboxContainer>
              <Checkbox
                id='qrCode'
                name='qrCode'
                checked={state.channelProperties['qrCode'].isActive}
                onChange={handleCheckbox}
              />
              <label
                style={{ color: 'rgb(52, 58, 64', margin: 0 }}
                htmlFor='qrCode'
              >
                Active
              </label>
            </CheckboxContainer>

            <CustomSelect
              options={distributionTypeOptions}
              value={find('qrCode')}
              isDisabled={isDisabled('qrCode')}
              onChange={createHandleSelect('qrCode')}
            />
          </ChannelTypeItem>
        )}

        {/* {
            channels['device'] &&
            <ChannelTypeItem>
              <StyledLink exact to={`${props.match.url}/device`}>
                <FontAwesomeIcon
                  icon={faTabletAlt}
                  css={`            
                    margin-right: 10px;
                    font-size: 17px;
                    color: #1B998B;
                  `} 
                /> 
                Device
              </StyledLink>
              <CheckboxContainer>
                <Checkbox id='device' name='device' checked={state.channelProperties['device'].isActive} onChange={handleCheckbox} />
                <label style={{color: 'rgb(52, 58, 64'}} htmlFor='device'>Active</label>
              </CheckboxContainer>
              <CustomSelect options={distributionTypeOptions} value={find('device')} isDisabled={isDisabled('device')} onChange={createHandleSelect('device')} />
            </ChannelTypeItem>
          }  */}

        {channels['software'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/software`}>
              <FontAwesomeIcon
                icon={faLaptopCode}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #2cc76d;
                `}
              />
              Software
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='software'
                name='software'
                checked={state.channelProperties['software'].isActive}
                onChange={handleCheckbox}
              />
              <label style={{ color: 'rgb(52, 58, 64' }} htmlFor='software'>
                Active
              </label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('software')}
              isDisabled={isDisabled('software')}
              onChange={createHandleSelect('software')}
            />
          </ChannelTypeItem>
        )}

        {channels['email'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/email`}>
              <FontAwesomeIcon
                icon={faMailBulk}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #3b6093;
                `}
              />
              Email
            </StyledLink>

            <CheckboxContainer>
              <Checkbox
                id='email'
                name='email'
                checked={state.channelProperties['email'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='email' style={{ margin: 0 }}>
                Active
              </label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('email')}
              isDisabled={isDisabled('email')}
              onChange={createHandleSelect('email')}
            />
          </ChannelTypeItem>
        )}

        {channels['website'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/website`}>
              <FontAwesomeIcon
                icon={faGlobeAmericas}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #26a198;
                `}
              />
              Website
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='website'
                name='website'
                checked={state.channelProperties['website'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='website'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('website')}
              isDisabled={isDisabled('website')}
              onChange={createHandleSelect('website')}
            />
          </ChannelTypeItem>
        )}

        {channels['facebook'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/facbook`}>
              <FontAwesomeIcon
                icon={faFacebook}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #3d5a99;
                `}
              />
              Facbook
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='facebook'
                name='facebook'
                checked={state.channelProperties['facebook'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='facebook'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('facebook')}
              isDisabled={isDisabled('facebook')}
              onChange={createHandleSelect('facebook')}
            />
          </ChannelTypeItem>
        )}

        {channels['twitter'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/twitter`}>
              <FontAwesomeIcon
                icon={faTwitter}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #32b2e8;
                `}
              />
              Twitter
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='twitter'
                name='twitter'
                checked={state.channelProperties['twitter'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='twitter'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('twitter')}
              isDisabled={isDisabled('twitter')}
              onChange={createHandleSelect('twitter')}
            />
          </ChannelTypeItem>
        )}

        {channels['linkedin'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/linkedin`}>
              <FontAwesomeIcon
                icon={faLinkedin}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #2276b5;
                `}
              />
              Linkedin
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='linkedin'
                name='linkedin'
                checked={state.channelProperties['linkedin'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='linkedin'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('linkedin')}
              isDisabled={isDisabled('linkedin')}
              onChange={createHandleSelect('linkedin')}
            />
          </ChannelTypeItem>
        )}

        {channels['instagram'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/instagram`}>
              <FontAwesomeIcon
                icon={faInstagram}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #fd1d1d;
                `}
              />
              Instagram
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='instagram'
                name='instagram'
                checked={state.channelProperties['instagram'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='instagram'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('instagram')}
              isDisabled={isDisabled('instagram')}
              onChange={createHandleSelect('instagram')}
            />
          </ChannelTypeItem>
        )}

        {channels['youtube'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/youtube`}>
              <FontAwesomeIcon
                icon={faYoutube}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #f90500;
                `}
              />
              Youtube
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='youtube'
                name='youtube'
                checked={state.channelProperties['youtube'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='youtube'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('youtube')}
              isDisabled={isDisabled('youtube')}
              onChange={createHandleSelect('youtube')}
            />
          </ChannelTypeItem>
        )}

        {channels['sms'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/sms`}>
              <FontAwesomeIcon
                icon={faCommentAlt}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #adcc33;
                `}
              />
              SMS
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='sms'
                name='sms'
                checked={state.channelProperties['sms'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='sms'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('sms')}
              isDisabled={isDisabled('sms')}
              onChange={createHandleSelect('sms')}
            />
          </ChannelTypeItem>
        )}

        {channels['serviceCenter'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/serviceCenter`}>
              <FontAwesomeIcon
                icon={faUserCheck}
                css={`
                  margin-right: 8px;
                  font-size: 16px;
                  color: #265dc7;
                `}
              />
              Service Center
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='serviceCenter'
                name='serviceCenter'
                checked={state.channelProperties['serviceCenter'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='serviceCenter'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('serviceCenter')}
              isDisabled={isDisabled('serviceCenter')}
              onChange={createHandleSelect('serviceCenter')}
            />
          </ChannelTypeItem>
        )}

        {channels['ccCamera'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/ccCamera`}>
              <FontAwesomeIcon
                icon={faVideo}
                css={`
                  margin-right: 7px;
                  font-size: 14px;
                  color: #87bd48;
                `}
              />
              CC Camera
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='ccCamera'
                name='ccCamera'
                checked={state.channelProperties['ccCamera'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='ccCamera'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('ccCamera')}
              isDisabled={isDisabled('ccCamera')}
              onChange={createHandleSelect('ccCamera')}
            />
          </ChannelTypeItem>
        )}

        {channels['callCenter'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/callCenter`}>
              <FontAwesomeIcon
                icon={faHeadset}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #f39c11;
                `}
              />
              Call Center
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='callCenter'
                name='callCenter'
                checked={state.channelProperties['callCenter'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='callCenter'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('callCenter')}
              isDisabled={isDisabled('callCenter')}
              onChange={createHandleSelect('callCenter')}
            />
          </ChannelTypeItem>
        )}

        {/* {channels['openLink'] &&
            <ChannelTypeItem>
              <StyledLink exact to={`${props.match.url}/openLink`}>
                <FontAwesomeIcon
                  icon={faLink}
                  css={`            
                margin-right: 10px;
                font-size: 17px;
                color:#4AAEE2;
              `}
                />
                Open Link
            </StyledLink>
              <CheckboxContainer>
                <Checkbox id='openLink' name='openLink' checked={state.channelProperties['openLink'].isActive} onChange={handleCheckbox} />
                <label style={{color: 'rgb(52, 58, 64'}} htmlFor='openLink'>Active</label>
              </CheckboxContainer>
              <CustomSelect options={distributionTypeOptions} value={find('openLink')} isDisabled={isDisabled('openLink')} onChange={createHandleSelect('openLink')} />
            </ChannelTypeItem>
          } */}

        {channels['manual'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/manual`}>
              <FontAwesomeIcon
                icon={faUserCog}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #e77206;
                `}
              />
              Manual
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='manual'
                name='manual'
                checked={state.channelProperties['manual'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='manual'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('manual')}
              isDisabled={isDisabled('manual')}
              onChange={createHandleSelect('manual')}
            />
          </ChannelTypeItem>
        )}

        {/* {channels['qrCode'] &&
            <ChannelTypeItem>
              <StyledLink exact to={`${props.match.url}/qrCode`}>
                <FontAwesomeIcon
                  icon={faQrcode}
                  css={`            
                margin-right: 10px;
                font-size: 17px;
              `}
                />
                QR Code
            </StyledLink>
              <CheckboxContainer>
                <Checkbox id='qrCode' name='qrCode' checked={state.channelProperties['qrCode'].isActive} onChange={handleCheckbox} />
                <label style={{color: 'rgb(52, 58, 64'}} htmlFor='qrCode'>Active</label>
              </CheckboxContainer>
              <CustomSelect options={distributionTypeOptions} value={find('qrCode')} isDisabled={isDisabled('qrCode')} onChange={createHandleSelect('qrCode')} />
            </ChannelTypeItem>
          } */}

        {channels['offline'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/offline`}>
              <FontAwesomeIcon
                icon={faPlug}
                css={`
                  margin-right: 10px;
                  font-size: 17px;
                  color: #be263d;
                `}
              />
              Offline
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='offline'
                name='offline'
                checked={state.channelProperties['offline'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='offline'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('offline')}
              isDisabled={isDisabled('offline')}
              onChange={createHandleSelect('offline')}
            />
          </ChannelTypeItem>
        )}

        {channels['inApp'] && (
          <ChannelTypeItem>
            <StyledLink exact to={`${props.match.url}/inApp`}>
              <img
                draggable='false'
                alt='PEX Logo'
                src={Logo}
                css={`
                  position: absolute;
                  -webkit-user-drag: none;
                  -khtml-user-drag: none;
                  -moz-user-drag: none;
                  -o-user-drag: none;
                  user-drag: none;
                  width: 20x;
                  height: 20px;
                `}
              />
              <span
                css={`
                  padding-left: 25px;
                `}
              >
                In App
              </span>
            </StyledLink>
            <CheckboxContainer>
              <Checkbox
                id='inApp'
                name='inApp'
                checked={state.channelProperties['inApp'].isActive}
                onChange={handleCheckbox}
              />
              <label htmlFor='inApp'>Active</label>
            </CheckboxContainer>
            <CustomSelect
              options={distributionTypeOptions}
              value={find('inApp')}
              isDisabled={isDisabled('inApp')}
              onChange={createHandleSelect('inApp')}
            />
          </ChannelTypeItem>
        )}
      </ChannelList>

      <Channel>
        {renderSelectedChannel()}

        {/* <Switch>
            <Route exact path={`${props.match.path}`} render={() => (<div css={`color:grey`}>Select a Channel...</div>)} />
            <Route path={`${props.match.path}/device`} render={() => <Device activestatus={state.channelProperties['device'] && state.channelProperties['device'].isActive} />} />
            <Route path={`${props.match.path}/software`} component={Software} />
            <Route path={`${props.match.path}/email`} component={Email} />
            <Route path={`${props.match.path}/website`} component={Website} />
            <Route path={`${props.match.path}/facbook`} component={Facbook} />
            <Route path={`${props.match.path}/twitter`} component={Twitter} />
            <Route path={`${props.match.path}/linkedin`} component={Linkedin} />
            <Route path={`${props.match.path}/instagram`} component={Instagram} />
            <Route path={`${props.match.path}/youtube`} component={Youtube} />
            <Route path={`${props.match.path}/sms`} component={Sms} />
            <Route path={`${props.match.path}/serviceCenter`} component={ServiceCenter} />
            <Route path={`${props.match.path}/ccCamera`} component={CcCamera} />
            <Route path={`${props.match.path}/callCenter`} component={CallCenter} />
            <Route path={`${props.match.path}/openLink`} render={() => <OpenLink activestatus={state.channelProperties['openLink'].isActive} />} />
            <Route path={`${props.match.path}/manual`} component={Manual} />
            <Route path={`${props.match.path}/qrCode`} render={() => <QrCode activestatus={state.channelProperties['qrCode'].isActive} />} />
            <Route path={`${props.match.path}/offline`} component={Offline} />
            <Route path={`${props.match.path}/inApp`} component={InApp} />
          </Switch> */}
      </Channel>
    </Body>
  );
};

export default withRouter(ChannelsConfig);
