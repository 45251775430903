import React from 'react';
import './TrafficLight.css';

const style = {
    title: {
        color: '#424242',
        fontSize: '15px',
        fontWeight: '700',
        marginTop: '20px'
    }
}

const TrafficLight = props => {

    let indicatorText = "";

    let traficLight = {
        red:"light light-red",
        yellow:"light light-yellow",
        green:"light light-green"
    }


    if (props.value >= props.threshold[1] ) {
        traficLight = {
            red:"light light-red",
            yellow:"light light-yellow",
            green:"light green-active"
        }
        indicatorText =  props.indicatior && props.indicatior[2] ? props.indicatior[2] : ""; 
    }else if(props.value < props.threshold[1] && props.value >= props.threshold[0] ){
        traficLight = {
            red:"light light-red",
            yellow:"light yellow-active",
            green:"light light-green"
        }
        indicatorText = props.indicatior && props.indicatior[1] ? props.indicatior[1] : ""; 
    }else if(props.value < props.threshold[0] && props.value > 0) {
        traficLight = {
            red:"light red-active",
            yellow:"light light-yellow",
            green:"light light-green"
        }
        indicatorText = props.indicatior && props.indicatior[0] ? props.indicatior[0] : ""; 
    }else {
        traficLight = {
            red:"light light-red",
            yellow:"light light-yellow",
            green:"light light-green"
        }
        indicatorText = "No data found yet";
    }

    return (
        <>
        <div>
            <h6 style={style.title}>{props.title}</h6>
            <div style={{
                backgroundColor:"#424242",
                display: "inline-block",
                padding: "6px 4px 1px 4px",
                borderRadius:"25px"
            }}>
                <div className={traficLight.red}></div>
                <div className={traficLight.yellow}></div>
                <div className={traficLight.green} ></div>
            </div> 
        </div>
        <small>{indicatorText}</small> 
        </>
    );
}

export default TrafficLight;