import styled from 'styled-components'

export const Scale = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  margin:0;
  padding: 0 20%;
  @media (max-width: 500px){
    padding: 0;
  }
`

export const Rating = styled.div`
  box-sizing: border-box;
  position: relative;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-basis: ${props=>(100/props.scale)}%;
  >img{
    width: 50%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  >label{
    width: 100%;
    font-size: 1em;
    word-wrap: break-word;
    text-align: center;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  @media (max-width: 500px){
    >img {
      width: 100%;
      padding: 0;
    }
    >label{
      font-size: 0.5em;
    }
  }
`