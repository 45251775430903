import React, { useState, useEffect } from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';

import {
  MainBody,
  Body,
  ProjectItem,
  Project,
  Edit,
  Delete,
  Banner,
} from './StyledComponents';
import Visualization from '../Visualization';
import CoreAnalytics from '../components/CoreAnalytics';
import { fetchProject } from '../../../url';
import Loader from '../../Loader';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb';

const Projects = (props) => {
  /*state-------------------------------------------------------------*/
  const [project, setProject] = useState([]);

  const [loading, setLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await axios(
          fetchProject(localStorage.getItem('jwtProfileId'))
        );

        const data = result.data.projectList.map((project) => {
          const filteredData = {
            id: project._id,
            projectName: project.name,
            // projectType: project.featureinfo.name
          };

          return filteredData;
        });

        // console.log({data});

        setProject([...project, ...data]);
      } catch (err) {
      } finally {
        setLoaded(true);
      }
    };

    fetchData();
  }, []);

  /*state-------------------------------------------------------------*/

  //generates the projects from the state information
  const items = project.map((item, index) => (
    <ProjectItem key={item.id}>
      <Link
        css={`
          box-sizing: border-box;
          margin: 0;
          border-radius: 4px;
          padding: 0;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          padding: 10px;
          width: 100%;
          height: 100%;
          z-index: 5;
        `}
        to={`${props.match.url}/${item.id}`}
      ></Link>
      <Project>
        <div
          css={`
            text-align: center;
            padding-top: 10px;
          `}
        >
          <FontAwesomeIcon
            icon={faPencilRuler}
            css={`
              font-size: 50px;
              color: #1b998b;
              padding-top: 10px;
              :hover {
                color: #1b998b;
              }
              @media only screen and (max-width: 768px) {
              }
              @media only screen and (max-width: 425px) {
                padding-top: 15px;
              }
            `}
          />
        </div>

        <div
          css={`
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
            background-color: rgb(52, 58, 64);
          `}
        >
          <div>Name: {item.projectName}</div>
          {/* <div>Type: {item.projectType}</div> */}
        </div>
      </Project>
    </ProjectItem>
  ));

  //initial view of the Projects tab showing all the created Projects
  const ProjectView = () => (
    <MainBody>
      <div className='mx-3 mt-2'>
        <Breadcrumb path='Core Analysis /' fontSize='16px' />
      </div>
      <Body>
        <Banner>All Projects</Banner>
        {items.reverse()} {/* displaying the items */}
      </Body>
    </MainBody>
  );

  //The routes are for main Project tab and individual Projects
  return (
    <>
      {loading.load === false ? (
        <Loader />
      ) : (
        <Switch>
          <Route path={`${props.match.path}/`} exact component={ProjectView} />
          <Route
            path={`${props.match.path}/:projectId`}
            exact
            component={CoreAnalytics}
          />
          <Route render={() => <div>Page Not Found</div>} />
        </Switch>
      )}
    </>
  );
};

export default withRouter(Projects);
