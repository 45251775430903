import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import axios from 'axios';
import {
	Button,
	Card,
	Col,
	FormGroup,
	Label,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import {
	getAllProfileWiseQRcode,
	updateQRcode,
	getAllBranch,
} from '../../../../../../url/index';
import showToast from '../../../../../../components/Toast/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

const QrCodeDetails = (props) => {
	const { addDevice, setView, history, data: item } = props;
	const [qrcodes, setQRcodes] = useState([]);
	const [branches, setbranches] = useState([]);

	// Toast
	const UpdateToast = () => showToast('Updated Successfully');
	const ErrorToast = () => showToast('Server Error');

	useEffect(() => {
		const fetchData = async () => {
			const res = await axios(
				getAllProfileWiseQRcode(localStorage.getItem('jwtProfileId'))
			);
			setQRcodes(res.data);

			const res2 = await axios(
				getAllBranch(localStorage.getItem('jwtProfileId'))
			);
			setbranches(res2.data.branch);
		};
		fetchData();
	}, []);

	let branchName;
	let emptyName = '';
	try {
		if (!Object.keys(branches).length) {
			branches = [];
		}
		if (Object.keys(branches).length) {
			for (let i = 0; i < branches.length; i++) {
				if (item.branchId === branches[i]._id) {
					branchName = branches[i].name;
				}
			}

			// Sorting Alphabetically
			let compare = (a, b) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				} else if (nameA < nameB) {
					comparison = -1;
				}
				return comparison;
			};
			branches.sort(compare);
		}
	} catch (error) {}

	let errorStyle = {
		color: 'red',
		fontSize: '12px',
		marginLeft: '5px',
		textAlign: 'left',
		fontWeight: 'bold',
	};

	return (
		<Card style={{ padding: '20px 25px 15px 40px' }}>
			<Formik
				initialValues={{
					name: item.name,
					status: item.active,
					isDefault: item.isDefault,
					branch: item.branchId,
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string()
						.required('Required')
						.trim()
						.lowercase()
						.notOneOf(
							qrcodes.length
								? qrcodes.map((obj) => {
										if (obj._id !== item.id) {
											if (obj.name) return obj.name.toLowerCase();
										}
								  })
								: [],
							'Already exists'
						),
				})}
				onSubmit={(values, { resetForm }) => {
					let updatedQRcode = {};
					if (values.branch) {
						updatedQRcode = {
							createdByCompanyID: item.createdByCompanyID,
							name: values.name.trim(),
							isDefault: values.isDefault,
							active: values.status,
							branchId: values.status,
						};
					} else {
						updatedQRcode = {
							createdByCompanyID: item.createdByCompanyID,
							active: values.status,
							isDefault: values.isDefault,
							name: values.name.trim(),
						};
					}

					axios
						.patch(updateQRcode(item.id), updatedQRcode)
						.then((res) => UpdateToast())
						.catch((error) => ErrorToast());
					resetForm();
					history.push('/app/profile/qrCode');
				}}
			>
				{(props) => (
					<Form onSubmit={props.handleSubmit}>
						<FormGroup className='d-flex flex-wrap'>
							<Label htmlFor='name' sm={4} style={{ paddingLeft: '0px' }}>
								{' '}
								Name: <span style={{ color: 'red' }}>{` *`}</span>{' '}
							</Label>
							<Col>
								<Input
									type='text'
									name='name'
									id='name'
									placeholder='Enter device name'
									style={{
										border:
											props.errors.name && props.touched.name
												? '1px solid red'
												: null,
									}}
									value={props.values.name}
									onChange={(e) => {
										if (e.target.value === '') {
											emptyName = 'empty';
										}
										if (e.target.value) {
											emptyName = '';
										}
										props.handleChange(e);
									}}
									onBlur={props.handleBlur}
								/>
								<span style={{ display: 'none' }}>
									{emptyName === 'empty'
										? (props.errors.name = 'Required')
										: null}
								</span>
								{props.errors.name && props.touched.name && (
									<p style={errorStyle}> {props.errors.name} </p>
								)}
							</Col>
						</FormGroup>

						<FormGroup className='d-flex flex-wrap'>
							<Label htmlFor='branch' sm={4} style={{ paddingLeft: '0px' }}>
								{' '}
								Branch:{' '}
							</Label>
							<Col>
								<Input
									type='select'
									name='branch'
									id='branch'
									value={props.values.branch}
									onChange={props.handleChange}
									onBlur={props.handleBlur}
								>
									{!item.branchId && (
										<option value='' disabled defaultValue hidden>
											Select...
										</option>
									)}
									{!branches.length && (
										<option value='' disabled defaultValue>
											Nothing to select!
										</option>
									)}
									{branches.map((obj) => {
										return (
											<option value={obj._id} key={obj._id}>
												{' '}
												{obj.name}{' '}
											</option>
										);
									})}
								</Input>
							</Col>
						</FormGroup>

						<FormGroup className='d-flex flex-wrap'>
							<Label
								htmlFor='status'
								className='mt-1'
								sm={4}
								style={{ paddingLeft: '0px' }}
							>
								Active:
							</Label>
							<Col style={{ marginTop: '7px' }}>
								<Input
									type='checkbox'
									id='status'
									name='status'
									className='form-check-input mt-2'
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									checked={props.values.status}
								/>
							</Col>
						</FormGroup>

						<FormGroup className='d-flex flex-wrap'>
							<Label
								htmlFor='isDefault'
								className='mt-1'
								sm={4}
								style={{ paddingLeft: '0px' }}
							>
								{' '}
								Default:{' '}
							</Label>
							<Col style={{ marginTop: '7px' }}>
								<Input
									type='checkbox'
									id='isDefault'
									name='isDefault'
									className='form-check-input mt-2'
									onChange={props.handleChange}
									onBlur={props.handleBlur}
									checked={props.values.isDefault}
								/>
							</Col>
						</FormGroup>

						<Button
							type='submit'
							className='btnHover'
							style={{ background: '#1B998B', borderColor: '#1B998B' }}
							size='sm'
						>
							Save
						</Button>

						<Button
							className='btnHover'
							style={{
								background: '#1B998B',
								borderColor: '#1B998B',
								margin: '10px',
							}}
							size='sm'
							onClick={() => {
								history.push('/app/profile/qrCode');
							}}
						>
							Cancel
						</Button>
					</Form>
				)}
			</Formik>
		</Card>
	);
};

export default withRouter(QrCodeDetails);
