import React, { Component } from 'react';
import './CircularProgress.css';

class CircualarProgress extends Component {

    render() {
        const strokeDasharray = `${this.props.data}, 100`;
        const strokClasses = ['very-low', 'low', 'medium', 'high'];
        let strokeClass = "";
        if (!this.props.color) {
            strokeClass = this.props.data <= 25 ? strokClasses[0] 
                : this.props.data >=26 && this.props.data <=50 ? strokClasses[1] 
                : this.props.data >=50 && this.props.data <=75 ? strokClasses[2] 
                : this.props.data >=76 ? strokClasses[3] : '#BDBDBD';
            
        }

        return (
            <div>
                <div className="CircularProgress" style={{width:'140px', height:'140px', margin:"0 auto"}}>
                    <h6 className="text-center title">{this.props.title}</h6>
                    <svg viewBox="0 0 36 36" className={`circular-chart ${strokeClass}`}>
                        <path className="circle-bg"
                            d="M18 2.0845
                            a 11.9155 11.9155 0 0 1 0 31.831
                            a 11.9155 11.9155 0 0 1 0 -31.831"
                        />
                        <path className="circle"
                            strokeDasharray={strokeDasharray}
                            stroke={this.props.color}
                            d="M18 2.0845
                            a 11.9155 11.9155 0 0 1 0 31.831
                            a 11.9155 11.9155 0 0 1 0 -31.831"
                        />
                        <text x="18" y="20.35" className="percentage">{this.props.data}%</text>
                    </svg>
                </div>
            </div>
        );
    }
}

export default CircualarProgress;
