import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { Button } from 'reactstrap'
import Can from '../../../../components/Can'

function Createbtn(props) {
  return (
    <div> 
      <BreadCrumb path="Distribution /" /> 
      <Can
        feature="distribution" 
        perform="create" 
        yes={() => ( 
          <Button 
            style={{ background: '#1B998B', borderColor: '#1B998B', 
            margin: '10px 0px 10px 0px', padding: '4px 15px', fontSize:'13px', fontWeight:'500' }}
            className="btnHover" size="sm" onClick={() => props.history.push('/app/distribution/create')}>
            Create New
          </Button> 
        )} 
      /> 
    </div> 
  ) 
} 

export default withRouter(Createbtn); 
