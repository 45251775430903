import { createSelector } from 'reselect'
import Moment from 'moment'

//Get the list of devices for the user stored in state->data->device
const deviceListSelector = state => state.data.device

//Get the list of openLink for the user stored in state->data->openLink
const openLinkListSelector = state => state.data.openLink

const qrCodeListSelector = state => state.data.qrCode

//Get the list of formattedResponses according to question stored in state->data->formattedResponse
export const formattedResponseSelector = createSelector(
  state => state.data.formattedResponse,
  data => data
)

//Get the isLoading property stred in state->data->isLoading
export const isLoadingSelector = createSelector(
  state => state.data.isLoading,
  data => data
)

//Get the isDataFetching property stored in state->data->isDataFetching
export const isFetchingSelector = createSelector(
  state => state.data.isFetching,
  data => data
)

export const isDataPresentSelector = createSelector(
  [isLoadingSelector, isFetchingSelector,]
)

//Get the formattedResponse based on the title of the question
const questionDataByTitleAndTypeSelector = (state, props) => {
  const { title, type } = props
  return state.data.formattedResponse.find(item => (item.title === title && item.type === type))
}

//Get the list of all sources of response, channels or subchannels
export const getSourceSelector = createSelector(
  [deviceListSelector, openLinkListSelector, qrCodeListSelector],
  (deviceList, openLinkList, qrCodeList) => [...openLinkList, ...deviceList, ...qrCodeList]
)

//Get the byDate tally for an specific question based on title
export const makeQuestionDataSelector = () => {
  return createSelector(
    [questionDataByTitleAndTypeSelector, getSourceSelector],
    (data, sourceList) => {
      const { answers, type } = data
      // console.log('answers', data)
      const byDate = []
      answers.forEach(item => {
        const { responseInfo, ans } = item
        switch (type) {
          case "likertScale":
          case "multipleChoice":
            if (ans.index === null) {
              return
            }
          case "checkbox":
          case "textBox":
          default:
        }
        const date = Moment(responseInfo.responseDate, Moment.ISO_8601).format('DD/MM/YYYY')
        const foundDataInByDateIndex = byDate.findIndex(element => element.date === date)
        const foundDataInByDate = byDate[foundDataInByDateIndex]
        const foundChannel = sourceList.find(channel => channel._id === responseInfo.channelID)
        // console.log('responseInfo.channelID', responseInfo.channelID)
        if (!foundDataInByDate) {
          let newItem
          if (foundChannel) {
            newItem = {
              date,
              [foundChannel.name]: 1
            }
          } else {
            newItem = {
              date
            }
          }
          byDate.push(newItem)
        } else {
          // console.log('found cn', foundChannel)
          const channelPresent = byDate[foundDataInByDateIndex].hasOwnProperty(foundChannel.name)
          if (channelPresent) {
            byDate[foundDataInByDateIndex][foundChannel.name] += 1
          } else {
            byDate[foundDataInByDateIndex][foundChannel.name] = 1
          }
        }
      })

      return byDate
    }
  )
}

//Get the answersTally for a specific question; doesn't work for textBox
export const makeAnswerTallySelector = () => {
  return createSelector(
    [questionDataByTitleAndTypeSelector,],
    (data) => {
      const { answers, type } = data
      let tally
      const tallyData = {}
      switch (type) {
        case "likertScale": {
          data.ratings.forEach(key => {
            tallyData[key] = 0
          })
          tallyData["Unanswered"] = 0
          answers.forEach(item => {
            const { ans: { value } } = item
            tallyData[value] += 1
          })
          tally = [tallyData]
          break
        }
        case "multipleChoice":
          data.choices.forEach(key => {
            tallyData[key] = 0
          })
          tallyData["Unanswered"] = 0
          answers.forEach(item => {
            const { ans: { value } } = item
            tallyData[value] += 1
          })
          tally = [tallyData]
          break
        case "checkbox":
          data.options.forEach(key => {
            tallyData[key] = 0
          })
          answers.forEach(item => {
            const { ans } = item
            ans.forEach(option => {
              tallyData[option] += 1
            })
          })
          tally = [tallyData]
          break
        case "textBox":
        default:
          tally = []
          return tally
      }
      return tally
    }
  )
}