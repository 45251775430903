import React from 'react'

class EasyModal extends React.Component { 
    constructor(props) {
        super(props);
        this.outerStyle = {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            zIndex: 99
        };

        // default style
        this.style = {
            modal: {
                position: "relative",
                top: '50%',
                width: 500,
                padding: 20,
                boxSizing: "border-box",
                backgroundColor: '#1b998b',
                color: '#FFF',
                margin: "40px auto",
                marginTop: '-150px',
                borderRadius: 3,
                borderRadius:"4px",
                zIndex: 2,
                textAlign: "left",
                boxShadow: "0 20px 30px rgba(0, 0, 0, 0.2)",
                ...this.props.style.modal
            },
            overlay: {
                position: "fixed",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0,0,0,0, 0)",
                ...this.props.style.overlay
            }
        };
    }

    // render modal
    render() {
        return (
            <div
                style={{
                    ...this.outerStyle,
                    display: this.props.isEasyModalOpen ? "block" : "none"
                }}
            >
                <div style={this.style.overlay} onClick={this.props.closeEasyModal} />
                <div onClick={this.props.closeEasyModal} />
                <div style={this.style.modal}>{this.props.children}</div>
            </div>
        );
    }
}

export default EasyModal