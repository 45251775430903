import React from 'react'

import { Container, Image, TextBox, Title } from './styles'

import ImageUpload from './ImageUpload'
import TextEditor from './TextEditor'

const Header = props => {
  return (
    <section style={{ backgroundColor: "#EFF3F4", padding: "0px 10px 10px 10px", borderRadius: '4px' }}>
      <Title>Header</Title>
      <Container>
        <Image>
          <ImageUpload />
          <h6 className="text-center" style={{ fontSize: '12px', fontWeight: '700', color: '#424242', marginTop: '2px', padding: '0 4px' }}>Upload Image</h6>
          <p className="text-center" style={{ fontSize: '9px', fontWeight: '700', color: '#757575', lineHeight: '10px', margin:'0' }}>
            This image will be appeared on your distribution's panel
          </p>
        </Image>
        <TextBox>
          <TextEditor style={{ textAlign: 'initial' }} />
        </TextBox>
      </Container>
    </section>
  )
}

export default Header