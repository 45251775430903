import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form, FormGroup, Label, Input, Col } from 'reactstrap'
import baseUrl from '../../../../url'
import { useSelector, useDispatch } from 'react-redux'
import { changeName, changeFeature, changeActive } from '../../redux/actions/modifyQuestion'
import axios from 'axios'
import collect from 'collect.js';
import { CHANGE_ACTIVE } from "../../redux/actions/actionTypes";

const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    featureId: Yup.string().required("Required")
});

export default function Register() {

    const [feature, setFeature] = useState([])
    let name = useSelector(state => state.projectName)
    let featureId = useSelector(state => state.featureId)
    let active = useSelector(state => state.active)
    let dispatch = useDispatch()
    // console.log(name, objective, active)

    useEffect(
        () => {
            axios.get(`${baseUrl}/api/features/list/all?limit=500&page=1`).then(
                response => { setFeature(response.data.features); }
            ).catch(
                error => console.log(error)
            )
        }
        , [])

    return (
        <>
            <h6 style={{ fontSize: "18px", fontWeight: "700", color: "#424242", borderBottom: "1px solid #BDBDBD", padding: "4px 0" }}>Project Information</h6>
            <Formik
                enableReinitialize
                initialValues={{
                    name: name || "",
                    featureId: featureId.id || "",
                    active: active || ""
                }}
                validationSchema={validationSchema}
                onSubmit={values => {
                    console.log(values);
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, errors }) => (
                    <Form onSubmit={handleSubmit}>

                        <FormGroup row>
                            <Label className="requiredField" style={{ fontSize:'14px', color: "#424242", fontWeight: "500" }} sm={2}>Project Name</Label>
                            <Col sm={4}>
                                <Input
                                    type="text"
                                    onChange={(event) => { dispatch(changeName(event.target.value)); handleChange(event.target.value) }}
                                    value={values.name}
                                    name="name"
                                    onBlur={handleBlur}
                                    invalid={errors.name ? true : false}
                                    autoComplete="off"
                                />
                                <small style={{ color: "#F27D7D" }}>{errors.name}</small>
                            </Col>
                            <Label className="requiredField" style={{ fontSize:'14px', color: "#424242", fontWeight: "500" }} sm={2}>Feature</Label>
                            <Col sm={4}>
                                <Input
                                    type="select"
                                    onChange={(event) => {
                                        // console.log(event);
                                        const sf = collect(feature).where('_id', event.target.value)
                                        // console.log(sf.items[0])
                                        dispatch(changeFeature(sf.items[0])); handleChange(event.target.value)
                                    }}
                                    value={values.featureId}
                                    name="featureId"
                                    invalid={errors.featureId ? true : false}
                                >
                                    <option value="" label="Select option here" disabled />
                                    {feature.map((fea, i) => <option value={fea._id} label={fea.name} key={i} />)}
                                    {errors.featureId}
                                </Input>
                                <small style={{ color: '#F27D7D' }}>{errors.featureId}</small>
                            </Col>


                        </FormGroup>
                        <FormGroup row>
                            <Label style={{ color: "#424242", fontWeight: "500", fontSize: "14px", lineHeight: "8px" }} sm={2}>Active</Label>
                            <Col sm={4}>
                                <Input type="checkbox"
                                    onChange={(event) => { dispatch(changeActive(event.target.checked)); handleChange(event.target.checked) }}
                                    checked={values.active}
                                    value={values.active}
                                    name="active" />
                                {errors.active}
                            </Col>
                        </FormGroup>
                    </Form>
                )}
            </Formik>
        </>
    );
}
