import styled from 'styled-components'

export const Input = styled.textarea`
  box-sizing: border-box;
  border: 1px solid grey;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

export const Scale = styled.div`
  border:1px solid #bdbdbd;
  border-radius:4px;
  position: relative;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 5px; 
  margin-bottom: 0px;
  background-color:#FFF;
`

export const Rating = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 10px 4px;
  margin: 0 4px;
  flex: 1 1 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius:8px;
`
export const RatingValue = styled.textarea`
  text-align: center;
  width: 100%;
  margin: 2px 5px;
  box-sizing: border-box;
  border: none;
  resize: none;
  font-size: 12px;
  font-weight:700;
  color:#757575;
  height: 15px;
  line-height: 10px;
  outline: none;
  padding: 5px;
  overflow: hidden;
  border-radius: 2px;
  background-color:transparent;
`