
import React, { useState } from 'react'
import Navbar from '../LandingPage/components/nav/nav'
import TermsNavbar from './components/TermsNavbar/TermsNavbar'
import Services from './components/Services/services'
import Privacy from './components/Privacy/privacy'
import Security from './components/Security/security'
import AntiSpam from './components/AntiSpam/AntiSpam'
import Footer from '../LandingPage/components/footer/Footer'
import { Scrollbars } from 'react-custom-scrollbars';

function Terms() { 
  const [policy, setPolicy] = useState(1) 
  const selectPolicy = (n) => { 
    setPolicy(n) 
  } 

  return ( 
    <div style={{overflowY: 'scroll'}}> 
      <Navbar /> 
      <div data-spy="scroll" data-target="#myScrollspy" data-offset="20" className="row" style={{marginRight: '0px'}}> 
        <div className="col-md-4 col-lg-3"> 
          <Scrollbars autoHide style={{height: 'calc(100vh)', borderRight: '5px solid #1B998B'}}> 
            <TermsNavbar policy={policy} selectPolicy={selectPolicy} /> 
          </Scrollbars> 
        </div> 
        <div className="col-md-8 col-lg-9"> 
          <Scrollbars autoHide style={{height: 'calc(100vh)'}}> 
            { policy === 1 && <Services /> } 
            { policy === 2 && <Privacy />  } 
            { policy === 3 && <Security /> } 
            { policy === 4 && <AntiSpam /> } 
          </Scrollbars> 
        </div> 
      </div> 
      <div style={{marginTop: '-100px'}}> 
        <Footer /> 
      </div> 
    </div> 
  ) 
} 

export default Terms; 









// import React, { useState } from 'react'
// import Navbar from '../LandingPage/components/nav/nav'
// import TermsNavbar from './components/TermsNavbar/TermsNavbar'
// import Services from './components/Services/services'
// import Privacy from './components/Privacy/privacy'
// import Security from './components/Security/security'
// import AntiSpam from './components/AntiSpam/AntiSpam'
// import Footer from '../LandingPage/components/footer/Footer'

// function Terms() { 
//   const [policy, setPolicy] = useState(1) 
//   const selectPolicy = (n) => { 
//     setPolicy(n) 
//   } 

//   return ( 
//     <div style={{overflowY: 'scroll'}}> 
//       <Navbar /> 
//       <div data-spy="scroll" data-target="#myScrollspy" data-offset="20" className="row" style={{marginRight: '0px'}}> 
//         <div className="col-md-4 col-lg-3"  style={{borderRight: '5px solid #1B998B', overflowY: 'auto', height: '100vh - 60px'}}> 
//           <TermsNavbar policy={policy} selectPolicy={selectPolicy} /> 
//         </div> 
//         <div className="col-md-8 col-lg-9"> 
//           { policy === 1 && <Services /> } 
//           { policy === 2 && <Privacy />  } 
//           { policy === 3 && <Security /> } 
//           { policy === 4 && <AntiSpam /> } 
//         </div> 
//       </div> 
//       <Footer /> 
//     </div> 
//   ) 
// } 

// export default Terms; 











// --------------------------------------------------------------------------------
// --------------------------------------------------------------------------------


