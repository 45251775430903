//Dependencies
import React from 'react';
import styled from 'styled-components/macro';
import DeleteButton from '../DeleteButton';
import QuestionCardContent from '../QuestionCardContent';
import QuestionCardObjectives from '../QuestionCardObjectives';
import SelectType from '../SelectType';
import QuestionConfig from '../QuestionConfig';

import {
  Card,
  Config,
  Container,
  QuestionEdit,
  ObjectiveSelect,
} from './styles';

const types = [
  {
    type: 'Multiple Choice',
    value: 'multipleChoice',
  },
  {
    type: 'Drop Down',
    value: 'dropDown',
  },
  {
    type: 'Dichotomy',
    value: 'dichotomy',
  },
  {
    type: 'ThumbsUp/ThumbsDown',
    value: 'thumbsUpDown',
  },
  {
    type: 'Checkbox',
    value: 'checkbox',
  },
  {
    type: 'Likert Scale',
    value: 'likertScale',
  },
  {
    type: 'rating Scale',
    value: 'ratingScale',
  },
  {
    type: 'Star Rating',
    value: 'starRating',
  },
  {
    type: 'ThumbsUp Rating',
    value: 'thumbsUpRating',
  },
  {
    type: 'Love Rating',
    value: 'loveRating',
  },
  {
    type: 'NPS',
    value: 'nps',
  },
  {
    type: 'Text Box',
    value: 'textBox',
  },
  {
    type: 'Demography',
    value: 'demography',
  },
];

//styling components(found in styles.js):
//Card, Config, QuestionEdit

//Functional Components:

const QuestionCard = ({
  id,
  item,
  editTitle,
  deleteQuestion,
  editField,
  changeType,
  getQuestionId,
  selected,
  err,
}) => {
  //
  //
  // console.log("\n")
  // console.log(err)
  // console.log({types})
  return (
    <Card
      selected={item.id === selected} //indicates which card id selected on click;
      name={id}
      className='animated fadeIn delay-0.1s'
      onClick={() => {
        getQuestionId(item.id);
      }} //call back fn dispatching in QuestionCardList
      css={`
        border: ${err.some((check) => check === item.id)
          ? `1px solid red`
          : ''};
        background-color: #eff3f4;
      `}
    >
      {item.id === selected &&
      item.type ===
        'likertScale' /*edit here for conditional view as view for likert only by using type*/ ? (
        <Config view={item.id === selected}>
          {' '}
          {/*this config is for to view the library on the right for likertScale*/}
          <QuestionConfig id_={item.id} type={item.type} />
        </Config>
      ) : null}
      <QuestionEdit>
        <SelectType
          options={types}
          changeType={changeType}
          selectValue={item.type}
          selectId={item.id}
        />
        <DeleteButton click={() => deleteQuestion(id)}>Delete</DeleteButton>{' '}
        {/*passed click as call-back fn props to DeleteButton and called there*/}
      </QuestionEdit>
      <QuestionCardContent
        item={item}
        editField={editField}
        editTitle={editTitle}
      />
      {/* <ObjectiveSelect>
      </ObjectiveSelect> */}
      <QuestionCardObjectives id={id} selected={item.objectives} />
    </Card>
  );
};

export default QuestionCard;
