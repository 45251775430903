import Axios from 'axios' 
import {
    CREATE_BRAND_CATEGORY, GET_ALL_BRAND_CATEGORY, 
    GET_SINGLE_BRAND_CATEGORY, UPDATE_BRAND_CATEGORY, 
    DELETE_BRAND_CATEGORY, ERROR_IN_BRAND_CATEGORY, 
    BACK_BUTTON_IN_DETAIL_PAGE, PARENTS_OF_BRAND_CATEGORY, 
    PARENTS_OF_BRAND_CATEGORY_TRUE_ONLY
} from './actionTypes'

import { 
    brandCetagories, parentCetagories, 
    addBrandCategory, singleBrandCetagory, 
    updateBrandCetagory, parentCetagoriesTrueOnly
} from '../../../../../url/prmUrl'


export const createNewBrandCategory = (values, CreateToast, ErrorToast) => dispatch => { 
    Axios.post(addBrandCategory, values) 
        .then(res => { 
            dispatch({ type: CREATE_BRAND_CATEGORY, payload: res.data }) 
            CreateToast() 
        }) 
        .catch(err => ErrorToast()) 
} 


export const getParentsOfBrandCategories = () => dispatch => { 
    Axios.get(parentCetagories) 
        .then(res => { dispatch({type: PARENTS_OF_BRAND_CATEGORY, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND_CATEGORY}) }) 
} 


export const getParentsOfBrandCategoriesTrueOnly = () => dispatch => { 
    Axios.get(parentCetagoriesTrueOnly) 
        .then(res => { dispatch({type: PARENTS_OF_BRAND_CATEGORY_TRUE_ONLY, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND_CATEGORY}) }) 
} 


export const getALLBrandCategories = () => dispatch => { 
    Axios.get(brandCetagories) 
        .then(res => { dispatch({type: GET_ALL_BRAND_CATEGORY, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND_CATEGORY}) }) 
} 


export const getBrandCategoryDetails = (id, ErrorToast) => dispatch => { 
    Axios.get(singleBrandCetagory(id)) 
        .then(res => { dispatch({type: GET_SINGLE_BRAND_CATEGORY, payload: res.data}) }) 
        .catch(err => { ErrorToast() }) 
} 


export const updateBrandCategoryById = (id, value, UpdateToast, ErrorToast) => dispatch => { 
    Axios.patch(updateBrandCetagory(id), value) 
        .then(res => { dispatch({type: UPDATE_BRAND_CATEGORY, payload: res.data}); UpdateToast(); }) 
        .catch( err => ErrorToast() ) 
} 


export const deleteBrandCategoryById = (state) => dispatch => { 
    dispatch({type: DELETE_BRAND_CATEGORY, payload: {state}}) 
} 


export const backButtonFunction = () => dispatch => { 
    dispatch({type: BACK_BUTTON_IN_DETAIL_PAGE}) 
}










