import React, { Component } from 'react';
import Gauge from '../charts/gauge/Gauge';
import ResponseCategory from '../responseCategory/ResponseCategory';
import Nps from '../nps/Nps';
import MediaObject from '../mediaObject/MediaObject';

const styles = {
    border: 'none',
    //boxShadow:'0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
}

const GaugeChart = (props) => {

    const variables = props.variables;
    const length = variables.length;
    const classes = "col-12 col-sm-6 col-md-6 col-lg-3 col-xl-2 mb-4";
    let borderClass = "";
    return (
        <div className="card mb-4" style={styles}>
            <div className="card-body">
                <div className="row justify-content-center">
                    {
                        variables.map((item, index) => {

                            const itemName = item.name;
                            let elementId = itemName.split(' ');
                            elementId = elementId[0] + '_' + index;

                            borderClass = index === (length - 1) ? " " : " border-right-cl-2";

                            return <div key={index} className={classes + borderClass}>
                                <Gauge title={item.name} value={item.value} max={100} round={true} />
                                <ResponseCategory responses={item.responses} />
                                <Nps key={index} nps={item.nps} />
                                <MediaObject topVariable={item.topVariable} bottomVariable={item.bottomVariable} />
                            </div>
                        })
                    }
                </div>

            </div>
        </div>
    );

}

export default GaugeChart;