import React from 'react'
import {connect} from 'react-redux'
import styled from 'styled-components'

import {toggleDemographyField} from '../../../redux/actions/modifyQuestion'
import {questionSelector} from '../../../redux/selectors'

const options = [
  {
    label: "Name",
    field_name: "name"
  },
  {
    label: "Gender",
    field_name: "gender"
  },
  {
    label: "Email",
    field_name: "email"
  },
  {
    label: "Contact Number",
    field_name: "contact number"
  },
  {
    label: "Age",
    field_name: "age"
  },
  {
    label: "Birth Date",
    field_name: "birth date"
  },
  {
    label: "District",
    field_name: "district"
  },
  {
    label: "Postal Code",
    field_name: "postal code"
  },
  {
    label: "Profession",
    field_name: "profession"
  },
  {
    label: "Organization/Institution",
    field_name: "organization/institution"
  }
]

const Demography = ({id_, question, toggle})=>{

  const changeHandler = event=>{
    toggle(id_,event.target.name)
  }

  const fieldSelect = options.map((element, index)=>
      <label key ={index} htmlFor={element.field_name}>
        <input name={element.field_name} type="checkbox" checked={question.fields[element.field_name]} onChange={changeHandler}/>
        {element.label}
      </label>
    )
  
  return(
    <>
    {fieldSelect}
    </>
  )
}

const mapStateToProps = (state, ownProps)=>{
  const {id_} = ownProps
  return {
    question : questionSelector(state),
    id_
  }
}

const mapDispatchToProps = {
  toggle: (id_, field_name)=>toggleDemographyField(id_, field_name)
}

export default connect(mapStateToProps, mapDispatchToProps)(Demography)