import styled from 'styled-components'

export const Scale = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-around;
`

export const Rating = styled.div`
  position: relative;
  max-width: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  justify-content: stretch;
  box-shadow: 0 0 5px 2px blue inset;
  @media (max-width: 767px) {
    max-width: 65px;
    box-shadow: 0 0 0 1px blue inset;
  }
  @media (max-width: 375px) {
    max-width: 60px;
  }
  @media (max-width: 320px) {
    max-width: 50px;
  }
`

export const Gif = styled.img`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 15px;
  background: white;
  @media (min-width: 768px) and (max-width: 992px) {
    padding: 5px;
  }
  @media (max-width: 767px) {
    padding: 2px;
  }
`

export const Label = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-grow: 1;
  width: 100%;
  padding: 8px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  @media (max-width: 767px) {
    font-size: 10px;
  }
`

export const RadioButton = styled.input`
  position: absolute;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  opacity: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  &:checked ~ ${Label}{
    background: inherit;
  }
  &:checked ~ ${Gif}{
    background: inherit;
  }
`