import React, { Component } from 'react';
import axios from 'axios';
import CoreAccordion from './CoreAccordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import './CoreAnalytics.css';
import baseUrl, { fetchProject } from '../../../url/index';
import moment from 'moment';
import Breadcrumb from '../../../components/Breadcrumb/breadcrumb';
import { backButtonFunction } from '../../Blog/store/actions/actionCreator';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

class CoreAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coreData: coreDataFormat,
      loader: true,
      projectName: null,
      startDate: moment().startOf('month').format('DD/MM/YYYY'),
      endDate: moment().endOf('month').format('DD/MM/YYYY'),
      channelType: 'all',
      isCustomDate: false,
      customStartDate: moment().subtract(7, 'days').toDate(),
      customEndDate: moment().toDate(),
    };
  }

  componentDidMount() {
    const projectId = this.props.match.params.projectId;
    const profileId = localStorage.getItem('jwtProfileId');
    // start date should be 14 days before the current date
    // const startDate = moment().subtract(14, 'days').format('DD/MM/YYYY');
    // const endDate = moment().format('DD/MM/YYYY');

    axios
      .post(`${baseUrl}/api/visualization/core`, {
        profileId: profileId,
        projectId: projectId,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        channelType: this.state.channelType,
      })
      .then((response) => {
        // console.log('response: ', response);
        this.setState({
          coreData: response.data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(fetchProject(localStorage.getItem('jwtProfileId')))
      .then((response) => {
        console.log('response: ', response.data);

        const projectId = this.props.match.params.projectId;

        const projectName = response.data.projectList.find(
          (project) => project._id === projectId
        ).name;

        this.setState({
          projectName: projectName,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.get(`http://localhost:3000/core.json`)
    //     .then(response => {
    //         this.setState({
    //             coreData: response.data,
    //             loader: false
    //         });
    //     }).catch(error => {
    //         console.log(error);
    //     });
  }

  handleGetFilteredData = (startDate, endDate, channelType) => {
    axios
      .post(`${baseUrl}/api/visualization/core`, {
        profileId: localStorage.getItem('jwtProfileId'),
        projectId: this.props.match.params.projectId,
        startDate: startDate,
        endDate: endDate,
        channelType: channelType,
      })
      .then((response) => {
        this.setState({
          coreData: response.data,
          loader: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isCustomDate) {
      if (
        this.state.customStartDate !== prevState.customStartDate ||
        this.state.customEndDate !== prevState.customEndDate
      ) {
        const start = moment(this.state.customStartDate).format('DD/MM/YYYY');
        const end = moment(this.state.customEndDate).format('DD/MM/YYYY');

        this.setState({
          startDate: start,
          endDate: end,
        });
        this.handleGetFilteredData(start, end, this.state.channelType);
      }
    }
  }

  handleChangeTime = (e) => {
    const value = e.target.value;

    switch (value) {
      case 'today':
        {
          const start = moment().startOf('day').format('DD/MM/YYYY');
          const end = moment().endOf('day').format('DD/MM/YYYY');

          this.setState({
            startDate: start,
            endDate: end,
            isCustomDate: false,
          });
          this.handleGetFilteredData(start, end, this.state.channelType);
        }
        break;

      case 'thisWeek':
        {
          const start = moment().startOf('week').format('DD/MM/YYYY');
          const end = moment().endOf('week').format('DD/MM/YYYY');

          this.setState({
            startDate: start,
            endDate: end,
            isCustomDate: false,
          });
          this.handleGetFilteredData(start, end, this.state.channelType);
        }
        break;

      case 'thisMonth':
        {
          const start = moment().startOf('month').format('DD/MM/YYYY');
          const end = moment().endOf('month').format('DD/MM/YYYY');

          this.setState({
            startDate: start,
            endDate: end,
            isCustomDate: false,
          });
          this.handleGetFilteredData(start, end, this.state.channelType);
        }
        break;

      case 'thisYear':
        {
          const start = moment().startOf('year').format('DD/MM/YYYY');
          const end = moment().endOf('year').format('DD/MM/YYYY');

          this.setState({
            startDate: start,
            endDate: end,
            isCustomDate: false,
          });
          this.handleGetFilteredData(start, end, this.state.channelType);
        }
        break;

      case 'custom':
        this.setState({
          isCustomDate: true,
        });
        break;

      default:
        break;
    }
  };

  render() {
    return (
      <div className='mt-2 mb-4'>
        {/* {} */}

        <div className='mx-3 mb-2 d-flex align-items-center justify-content-between'>
          <Breadcrumb
            path='Core Analysis'
            fontSize='16px'
            slug={this.state.projectName}
            link={'/app/dataanalysis'}
          />

          <div className='d-flex align-items-center' style={{ gap: '20px' }}>
            {this.state.isCustomDate ? (
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                <span>Start Date:</span>

                <ReactDatePicker
                  selected={this.state.customStartDate}
                  onChange={(date) => {
                    this.setState({ customStartDate: date });
                  }}
                  dateFormat='dd/MM/yyyy'
                  className='form-control'
                />

                <span>End Date:</span>

                <ReactDatePicker
                  selected={this.state.customEndDate}
                  onChange={(date) => {
                    this.setState({ customEndDate: date });
                  }}
                  dateFormat='dd/MM/yyyy'
                  className='form-control'
                />
              </div>
            ) : null}

            <select
              className='form-control'
              style={{ width: '150px' }}
              onChange={this.handleChangeTime}
              defaultValue='thisMonth'
            >
              <option value='today'>Today</option>
              <option value='thisWeek'>This Week</option>
              <option value='thisMonth'>This Month</option>
              <option value='thisYear'>This Year</option>
              <option value='custom'>Custom</option>
            </select>

            <select
              className='form-control'
              style={{ width: '150px' }}
              onChange={(e) => {
                this.setState({ channelType: e.target.value }, () => {
                  axios
                    .post(`${baseUrl}/api/visualization/core`, {
                      profileId: localStorage.getItem('jwtProfileId'),
                      projectId: this.props.match.params.projectId,
                      startDate: this.state.startDate,
                      endDate: this.state.endDate,
                      channelType: this.state.channelType,
                    })
                    .then((response) => {
                      // console.log('response: ', response);
                      this.setState({
                        coreData: response.data,
                        loader: false,
                      });
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              }}
            >
              <option value='all'>All</option>
              <option value='openLink'>Open Link</option>
              <option value='qrCode'>QR Code</option>
              <option value='device'>Device</option>
              <option value='email'>Email</option>
            </select>
          </div>
        </div>

        <div className='container-fluid CoreAnalytics'>
          {/* <div className="card mb-1">
                        <div className="card-body py-2 px-2">
                            <div className="d-flex flex-row bd-highlight core-links">
                                <div className="p-2 bd-highlight">Dashboard</div>
                                <div className="p-2 bd-highlight">Response Analysis</div>
                                <div className="p-2 bd-highlight">Spotlight Report</div>
                            </div>
                        </div>
                    </div> */}
          {this.state.loader ? (
            <div className='text-center my-5'>
              <FontAwesomeIcon
                icon={faCircleNotch}
                size='6x'
                style={{ color: '#1B998B' }}
                spin
              />
            </div>
          ) : this.state.coreData ? (
            <CoreAccordion
              coreData={this.state.coreData}
              apiBody={{
                profileId: localStorage.getItem('jwtProfileId'),
                projectId: this.props.match.params.projectId,
                startDate: moment().subtract(200, 'days').format('DD/MM/YYYY'),
                endDate: moment().format('DD/MM/YYYY'),
                channelType: 'all',
              }}
            />
          ) : (
            <div className='alert text-danger text-center' role='alert'>
              <strong>No data found!</strong>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CoreAnalytics;

const coreDataFormat = {
  summary: {
    respondants: 0,
    answered: 0,
    skipped: 0,
    averageResponseTime: 0,
    trend: [],
  },

  dateWiseResponse: {
    trend: [],
  },

  respondantInformation: {
    list: [],
  },

  channelWiseResponse: {
    chartData: [],
    trend: [],
  },

  genderWiseResponse: {
    chartData: [],
    trend: [],
  },
  questions: [],
};
