import React, { useState } from 'react'
import { pexUrl } from '../../../../url/index'

const OpenLink = props => {

    const [active, setActive] = useState(false)

    return (
        <div>
            <h4>Open Link</h4>
            <label className="dislabel" style={{ margin: '10px' }}>
                Active open link
            <input
                    name="active"
                    type="checkbox"
                    checked={active}
                    onChange={event => {
                        const target = event.target;
                        const value = target.type === 'checkbox' ? target.checked : target.value;
                        setActive(value)
                        props.setStatus(value)
                    }
                    }
                />
            </label>

            {props.openlink.hasOwnProperty('hashID') && <button className="generate" onClick={() => { props.updateLink(props.openlink.distributionId, [{ "propName": "link", "value": `${pexUrl}/openlink/${props.openlink.hashID}` }]); }}>Generate Open Link</button>}

            {props.openlink.hasOwnProperty('link') && <a href={props.openlink.link} target="_blank" style={{ fontSize: '.7em', wordBreak: 'break-all', color: 'rgba(0,0,0,.8)', fontWeight: '500', margin: '10px' }}>{props.openlink.link}</a>}

        </div>
    )

}

export default OpenLink