import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  padding: 0;
  margin: 0;
  
`
export const Image = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  width: 150px;
  height: 150px;
  padding: 0;
  margin: 0;
`

export const TextBox = styled.div`
  box-sizing: border-box;
  position: relative;
  flex-grow: 1;
  padding: 5px;
  border: 1px solid #1B998B;
`

export const Title = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 5px;
  font-size: 20px;
  text-align: center;
`

export const ImgPreview = styled.div`
  display:flex;
  border: 3px solid white;
  position: relative;
  width: 95%;
  height: 95%;
  background-color: #F2F3F2;
  box-shadow: 0 0 3px grey;
`

  export const UploadContainer = styled.div`
  width:30px;
  height:30px;
  bottom: 6px;
  right: 6px;
  position: absolute;
  border: 1px solid white;
  z-index:1;
  background-color:rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  `


export const Upload = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
`

export const UploadButton = styled.div`
  background-color: #310D31;
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
