import React from 'react'
import styled from 'styled-components/macro'
import { connect } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { RadioButton } from 'primereact/radiobutton'

const ThumbsUpDown = ({ item, hasError, isReadOnly }) => {
    // console.log(item)
    // console.log(editField)

    //Generate the choices
    const options = item.options.map((option, index) => {
        let translateY = option == "thumbsUp" ? 'translateY(-5px)' : 'translateY(5px)';
        // console.log(translateY)
        return (
            <div className="p-col-3" key={index}>
                <RadioButton inputId={`${item.id}`} name={`${item.id}`} value={`${option}`} onChange={(e) => false} disabled={isReadOnly} />
                <label htmlFor={`${item.id}`} className="p-radiobutton-label" style={{ transform: translateY }}>
                    <FontAwesomeIcon icon={option == "thumbsUp" ? faThumbsUp : faThumbsDown} size="2x" />
                </label>
            </div>
        )
    })

    //Return the ThumbsUpDown Component
    return (
        <React.Fragment>
            <h6 style={{ marginTop: "20px", marginBottom: "0px", fontSize: "14px", fontWeight: "700" }}>Options</h6>
            <div className="p-grid" style={{ width: "100%", marginTop: "10px" }}>
                {options}
            </div>
        </React.Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        hasError: state.error.options,
        // disStatus: state.disStatus,
        isReadOnly: state.isReadOnly
    }
}

export default connect(mapStateToProps, null)(ThumbsUpDown)