import React, {useState} from 'react'
import image from './assets/EJM_2.png'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import './style.css'

function Employee() { 
  const [reqDemo, setReqDemo] = useState(false); 
  const cancelModal = () => setReqDemo(false); 

  let style={ 
    ourBtn: { 
      backgroundColor: '#1B998B', 
      borderColor: '#1B998B', 
      color: 'white', 
      borderRadius: '0%' 
    } 
  } 
  
  return ( 
    <div className="container boxStyle"> 
      <div className="row align-items-center"> 
        <div className="col-sm-12 col-md-6 col-lg-6 orderImg"> 
          <div className="py-sm-5 py-md-2 py-lg-5" style={{width: '100%'}}> 
            <img className="img-fluid" src={image} alt="Employee Journey Map" /> 
          </div> 
        </div> 
        <div className="col-sm-12 col-md-6 col-lg-6 orderText"> 
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <h6 className="display-4 heading mb-3"> 
              Map the journey of your <br /> Employee to make it better  
            </h6> 
            <p className="text"> 
              Employee journey mapping is a process to ensure that your employees are having positive experiences with your organization from their first impression onwards. During their employment, the engagement with co-workers, the satisfaction or dissatisfaction regarding any organization decision, any kind of facilities or any policymaking and the effort they have to put to meet their target, combining all these insights, you will get the Employee Journey Map.
            </p> 
            <Button className="btn btn-sm btnHover" 
              onClick={() => setReqDemo(true)}> 
              Request Demo  
            </Button> 
          </div> 
        </div> 
      </div> 

      {/* Request Demo  */}
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    </div> 
  ) 
} 

export default Employee; 
