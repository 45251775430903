import React, { useState, useEffect } from 'react'; 
import { useSelector, useDispatch } from 'react-redux'; 
import { Card } from 'reactstrap'; 
import Table from './Table'; 
import { getAll_ } from '../../store/actions/actionCreator'; 
import showToast from '../../../../components/Toast/toast';

function Index() {
  const dispatch = useDispatch() 
  let { allData } = useSelector(state => state.blog) 
  
  try { if(!Object.keys(allData).length) { allData.postList = [] } } 
  catch (error) {} 

  const ErrorToast = () => showToast('Server Error') 

  useEffect(() => {
    dispatch(getAll_(ErrorToast)) 
  }, []) 

  const columns = React.useMemo(() => [ 
    { Header: 'Title',   accessor: 'title' }, 
    { Header: 'Active',  accessor: 'active' }, 
    { Header: 'Excerpt', accessor: 'excerpt' } 
  ], []) 

  return ( 
    <div className="animated fadeIn"> 
    { 
      allData.postList.length === 0 ? 
      <Card style={{ border: '0px', background: 'transparent' }}> 
        <Table columns={columns} data={[]} /> 
      </Card> 
      : 
      <Card style={{ border: '0px', background: 'transparent' }}> 
        <Table columns={columns} data={allData.postList.reverse()} /> 
      </Card> 
    } 
    </div> 
  ) 
} 

export default Index; 
