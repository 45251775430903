import React from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'
import './index.css'
// import { useSelector } from 'react-redux'

const select = ({ options, changeType, selectValue, selectId, isReadOnly }) => {
  // let disStatus = useSelector(state => state.disStatus)
  const newOptions = options.map(item => ({
    label: item.type,
    value: item.value
  }))
  const select = newOptions.filter(item => item.value === selectValue)
  return (
    <Select
      className='react-select-container'
      classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: style => {
          return {
            ...style,
            minHeight: "1px !important",
            height: "25px",
            width: "60%",
          }
        },
        control: (base) => {
          return {
            ...base,
            padding: "1px 2px",
            background: "white",
            ':hover': {
              ...base[':hover'],
              borderColor: "#1B998B !important"
            },
            ':active': {
              ...base[':active'],
              borderColor: "#1B998B !important"
            }
          };
        },
        singleValue: style => {
          return {
            ...style,
            padding: "5px !important"
          }
        }
      }}
      isSearchable={false}
      isDisabled={isReadOnly}
      options={newOptions}
      value={select}
      onChange={selected => {
        changeType(selectId, selected.value)
      }}
    />
    // <Select value={selectValue} onChange={event => {changeType(selectId, event.target.value)}}>
    //   {options.map((item,index) => <option key={`o_${index}`} value={item.value}>{item.type}</option> )}
    // </Select>
  )
}

export default select