import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { InputTextarea } from 'primereact/inputtextarea';
import { connect } from 'react-redux';
import _ from 'lodash';
import AddFieldButton from '../AddFieldButton';
import DeleteFieldButton from '../DeleteFieldButton';
import { Checkbox as CheckBoxInput } from 'primereact/checkbox';

// const Input = styled.textarea`
//   box-sizing: border-box;
//   border-color: #BDBDBD;
//   border-style: solid;
//   border-width: 0 0 1px 0;
//   resize: none;
//   width: 100%;
//   font-size: 15px;
//   height: 35px;
//   line-height: 1;
//   outline: none;
//   padding: 10px 10px 5px 10px;
//   overflow: hidden;
//   @media screen and (max-width: 425px){
//     font-size: 10px;
//     padding: 12.5px;
//   }
// `
// const choices = item.options.map((option, index) => {
//   return (
//     <div key={index}
//       style={{
//         position: "relative",
//         width: "100%",
//         display: "flex",
//         flexDirection: "row",
//         alignSelf: "flex-end",
//         alignItems: "center",
//         alignContent: "space-between",
//         padding: "0px",
//         marginTop: "8px"
//       }}
//     >
//       {/* <input type="checkbox" name={`${item.id}`} value={`${option}`} /> */}
//       <CheckBoxInput onChange={e => false} checked={false} name={`${item.id}`} value={`${option}`} disabled={isReadOnly}></CheckBoxInput>
//       <Input
//         css={`border-color:${option || !hasError ? '#BDBDBD' : '#F27D7D'}`}
//         value={option}
//         placeholder="option"
//         onChange={event => { editField(item.id, index, event.target.value) }}
//         disabled={isReadOnly}
//       />
//       {!isReadOnly && <DeleteFieldButton qId={item.id} fId={index} />}
//       {/* <DeleteFieldButton qId={item.id} fId={index} /> */}
//     </div>
//   )
// })

const Input = styled.input`
  box-sizing: border-box;
  border-color: #bdbdbd;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  resize: none;
  width: 100%;
  font-size: 15px;
  outline: none;
  padding: 5px;
  margin: 0 8px;
  border-radius: 4px;
  color: #424242;
  overflow: hidden;
  @media screen and (max-width: 425px) {
    font-size: 10px;
    padding: 12.5px;
  }
`;

const Option = ({ option, index, editField, isReadOnly, hasError, item }) => {
  const [optionTitle, setOptionTitle] = useState('');
  useEffect(() => {
    setOptionTitle(option);
  }, []);
  const delayedQuery = _.debounce(
    (id, index, value) => editField(id, index, value),
    10000
  );
  const debounceEditField = (id, index, value) => {
    setOptionTitle(value);
    // delayedQuery(id, index, value);
    editField(id, index, value);
  };

  return (
    <div
      key={index}
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        alignItems: 'center',
        alignContent: 'space-between',
        padding: '0px',
        marginTop: '8px',
      }}
    >
      {/* <input type="checkbox" name={`${item.id}`} value={`${option}`} /> */}
      <CheckBoxInput
        onChange={(e) => false}
        checked={false}
        name={`${item.id}`}
        value={`${optionTitle}`}
        disabled={isReadOnly}
      ></CheckBoxInput>
      <Input
        css={`
          border-color: ${optionTitle || !hasError ? '#BDBDBD' : '#F27D7D'};
        `}
        value={optionTitle}
        placeholder='option'
        onChange={(event) => {
          debounceEditField(item.id, index, event.target.value);
        }}
        disabled={isReadOnly}
      />
      {!isReadOnly && <DeleteFieldButton qId={item.id} fId={index} />}
      {/* <DeleteFieldButton qId={item.id} fId={index} /> */}
    </div>
  );
};

const Checkbox = ({
  item,
  editField,
  hasError,
  disStatus,
  hasDuplicate,
  isReadOnly,
}) => {
  //Generate the choices

  //Return the Checkbox Component
  return (
    <>
      <h6
        style={{
          marginTop: '20px',
          marginBottom: '5px',
          fontSize: '14px',
          fontWeight: '700',
        }}
      >
        Options
      </h6>
      {/* {choices} */}
      {item.options.map((option, index) => (
        <Option
          key={option.id}
          option={option.value}
          index={index}
          editField={editField}
          isReadOnly={isReadOnly}
          hasError={hasError}
          item={item}
        />
      ))}
      {hasDuplicate ? (
        <small style={{ color: '#F27D7D', marginLeft: '28px' }}>
          Options are same
        </small>
      ) : null}
      {/* {disStatus !== 'DISTRIBUTED' && <AddFieldButton id={item.id} />} */}
      {!isReadOnly && <AddFieldButton id={item.id} />}
      {/* <AddFieldButton id={item.id} /> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    hasError: state.error.options,
    hasDuplicate: state.error.dublicateField,
    disStatus: state.disStatus,
    // disStatus: state.disStatus,
    isReadOnly: state.isReadOnly,
  };
};

export default connect(mapStateToProps, null)(Checkbox);

// back up
// onInput={e => {
//   e.target.style.height = "25px"
//   if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
//     e.target.style.height = e.target.scrollHeight + "px";
//   }
// }}
