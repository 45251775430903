import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Scrollbars } from 'react-custom-scrollbars'

import { Body } from './styles'
import Question from '../../components/Question'
import Loader from '../../../Loader'

import { questionIsLoadingSelector } from '../../redux/selectors'

const QuestionList = ({ questionList, isLoading }) => {

  const [questionChecked, setQuestionChecked] = useState(false)
  const [toggleCount, setToggleCount] = useState(0)

  const handleInputChange = (event) => {
    setQuestionChecked(event.target.checked)
    let count = toggleCount + 1;
    setToggleCount(count)
  }

  // const setToggleQuestionChecked = value => {
  //   setToggleQuestionChecked(value)
  // }

  const items = questionList.map((question, index) =>
    <Question
      key={`${index}_Question`}
      question={question}
      questionChecked={questionChecked}
      toggleCount={toggleCount}
    // setToggleQuestionChecked={setToggleQuestionChecked}
    />)

  return (
    <Body>
      {/* <Scrollbars autoHeight>
      </Scrollbars> */}

      {
        !isLoading ?
          <div>
            <label style={{ marginLeft: '15px', cursor: 'pointer' }}>
              <input
                name="isGoing"
                type="checkbox"
                checked={questionChecked}
                onChange={handleInputChange}
                style={{ position: 'relative', top: '2px', right: '4px' }}
              />
              Select all

            </label>
            {items}
          </div>
          :
          <Loader />
      }
    </Body>
  )
}

const mapStateToProps = state => {
  return {
    questionList: state.config.questionList,
    isLoading: questionIsLoadingSelector(state)
  }
}

// const mapDispatchToProps = {

// }

export default connect(mapStateToProps)(QuestionList)