const editNumericRange = (item, value, err) => {
    console.log(value)
    const newItem = {...item};
    newItem.hasRange = !value;
    return newItem;
}

export const editCheckbox = (qId, value, questionList, err) => {
    return (questionList.map(item => {
      if (qId !== item.id) {
        return ({ ...item })
      } else {
        switch (item.type) {
          case "numeric":  
            return editNumericRange(item, value, err)
          default:
            return { ...item }
        }
      }
    }))
  }