import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 10px 15px;
  background-color: white;
  box-shadow: 1px 1px 8px 1px grey;
`

export const QuestionHeader = styled.div`
  position: relative;
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  line-height: 1;
  border-bottom: 1px solid grey;
`

export const Question = styled.div`
  position: relative;
  line-height: 1;
  flex-grow: 1;
  padding-right: 10px;
  font-size: 18px;
  text-align: justify;
`

export const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  padding-top: 0px;
`

export const Delete = styled.input`
  position: relative;
  display: block;
  box-sizing: border-box;
  margin: 0;
  padding: 5px 10px;
  border: none;
  outline: none;
  background: orange;
  align-self: center;
`