import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 30vw;
  padding: 10px 0;
  background-color: white;
`
export const Icon = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width:100%;
  height:50%;
  color: rgb(52, 58, 64);
  font-size: 2em;
`

export const Text = styled.div`
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  width:100%;
  color: rgb(52, 58, 64);
  font-size: 1em;
`

export const CloseButton = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;  
  color: white;
  font-size: 100%;
  align-items: center;
  justify-content: center;
  background-color: #1B998B;
  width: 50px;
  height: 25px;
  /* box-shadow: 1px 1px 5px -1px #586469; */
  :hover{
    cursor: pointer;
    /* box-shadow: 1px 1px 5px 2px #586469; */
    background-color: #0B6157;
  }
`

