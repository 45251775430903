import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, startDate } from '../../redux/actions'
import './style.css'
import { Fields, QuestionCard, QuestionTitle } from '../styles'
import { Input } from './styles'

whyDidYouRender(React, {
    onlyLogs: true,
    titleColor: "green",
    diffNameColor: "aqua"
})

const NumericSlider = props => {
    const { data, answer, updateData, startDate } = props

    useEffect(() => {
        console.log(data)
        if (data.hasOwnProperty('id')) {
            startDate(new Date())
        }
    }, [data])

    const handleChange = event => {
        const { value } = event.target
        updateData(value)
    }

    return (
        <QuestionCard>
            <QuestionTitle>{data.title}</QuestionTitle>
            <div className="row w-100">
                <div className="col text-left numeric-box-label">{data.min}</div>
                <div className="col text-right numeric-box-label">{data.max}</div>
            </div>
            {/* <Fields>
                <Input type="text" value={answer} onChange={handleChange} />
            </Fields> */}
            <label className="numeric-range">
                <input
                    id="typeinp"
                    type="range"
                    min={data.min}
                    max={data.max}
                    value={answer}
                    onChange={handleChange}
                    step="1" />
                {answer}
            </label>
        </QuestionCard>
    )
}

NumericSlider.whyDidYouRender = true

const mapStateToProps = state => {
    return {
        data: questionDataSelector(state),
        answer: answerFieldValueSelector(state)
    }
}

const mapDispatchToProps = {
    updateData,
    startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(NumericSlider))