import React from 'react'
import {Route, Redirect} from 'react-router-dom'

const PrivateRoute = ({component: Component, redirect, ...rest})=>{
  return(
    <Route
      {...rest}
      render={props=>
        redirect?
        <Redirect
          to={{
            pathname: "/app/distribution",
            state: {from: props.location} 
          }} 
        /> : 
        <Component {...props}/> 
      } 
    />
  )
}

export default PrivateRoute