import React from 'react'
import {Link} from 'react-router-dom'
import {Analytics} from 'grommet-icons'
import styled from 'styled-components'

const customLink = ({className, to, children}) => (
  <Link to={to} className={className}>
    {children}
  </Link>
)

const StyledLink = styled(customLink)`
  display: flex;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 15px;
  color: white;
  height: 35px;
  padding-left:14px;
  padding-right: 14px;
  align-items: center;
  margin-right: 5px; 
  background-color: #394662;
  border-color: #394662;
  border-radius: 5px;
  &:hover{
    background-color: #50659C;
    border-color: #50659C;
    box-shadow: 2px 3px 2px 2px #D9D9D9;
    color: white;
  }
`

const VisualizationButton = () => {

  return(
    <StyledLink to="/analysis">
      <Analytics size="15px" color="white" />
      <div style={{
        width: "12px"
      }}></div>
      Analysis
    </StyledLink>
  )
}





export default VisualizationButton
