import axios from 'axios'
import {
    GET_ALL_BROAD_OBJECTIVES,
    GET_SINGLE_BROAD_OBJECTIVE,
    CREATE_BROAD_OBJECTIVE,
    BACK_BUTTON_IN_DETAIL_PAGE,
    DELETE_BROAD_OBJECTIVE,
    UPDATE_BROAD_OBJECTIVCE
} from './actionTypes'
import baseUrl from '../../../../url'

export const getAllBroadObjectives = (ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/broadObjectives/list/all/?page=1&limit=500`)
        .then(response => {
            const res = response.data.objectives.map(r => {
                let createdAT = r.createdAt.slice(0, 10)
                return { createdAT, ...r }
            })
            dispatch({ type: GET_ALL_BROAD_OBJECTIVES, payload: res })
        })
        .catch(error => ErrorToast())

}


export const getBroadObjectiveDetail = (id, ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/broadObjectives/${id}`)
        .then(res => {
            dispatch({ type: GET_SINGLE_BROAD_OBJECTIVE, payload: res.data })
        })
        .catch(err => ErrorToast())
}

export const createNewBroadObjective = (values, CreateToast, ErrorToast) => dispatch => {

    axios.post(`${baseUrl}/api/broadObjectives`, values)
        .then(res => {
            // console.log(res)
            dispatch({ type: CREATE_BROAD_OBJECTIVE, payload: res.data })
            CreateToast()
        })
        .catch(err => ErrorToast())

}


export const deleteBroadObjectiveById = (state) => dispatch => {
    dispatch({ type: DELETE_BROAD_OBJECTIVE, payload: state })
}

export const updateBroadObjectiveById = (id, value, UpdateToast, ErrorToast) => dispatch => {

    axios.patch(`${baseUrl}/api/broadObjectives/${id}`, value)
        .then(res => {
            dispatch({ type: UPDATE_BROAD_OBJECTIVCE, payload: res.data });
            UpdateToast();
        })
        .catch(err => ErrorToast())

}

export const backButtonFunction = () => dispatch => { dispatch({ type: BACK_BUTTON_IN_DETAIL_PAGE }) }


