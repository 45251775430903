import React from 'react';
import axios from 'axios';
import { Card } from 'reactstrap';
import showToast from '../../../../components/Toast/toast';
import baseUrl from '../../../../url';
import Table from './Table';

const Index = ({ searchQuery, data, setData }) => {
  const [loading, setLoading] = React.useState(true);
  const [pageNo, setPageNo] = React.useState(1);
  const [pageLimit, setPageLimit] = React.useState(15);
  const [totalPages, setTotalPages] = React.useState(0);

  React.useEffect(() => {
    const fetchBulkEmails = async () => {
      const profileId = localStorage.getItem('jwtProfileId');

      try {
        const res = await axios.get(
          `${baseUrl}/api/bulk-email/list/${profileId}?page=${pageNo}&limit=${pageLimit}&fields=name,email,createdAt&search=${searchQuery}`
        );
        const { data } = res;

        if (data && data.status === 'success') {
          setData(data.data);
          setTotalPages(Math.ceil(data.result / pageLimit));
        } else {
          setData([]);
        }
      } catch (error) {
        showToast('Something went wrong');
      } finally {
        setLoading(false);
      }
    };

    fetchBulkEmails();
  }, [pageNo, pageLimit, searchQuery]);

  const columns = React.useMemo(() => {
    return [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Email', accessor: 'email' },
      { Header: 'Created At', accessor: 'createdAt' },
      { Header: 'Action', accessor: '' },
    ];
  }, []);

  return (
    <div className='animated fadeIn'>
      <Card style={{ border: '0px', background: 'transparent' }}>
        <Table
          columns={columns}
          data={data}
          setData={setData}
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          totalPages={totalPages}
          setTotalPages={setTotalPages}
        />
      </Card>
    </div>
  );
};

export default Index;
