import React from 'react';
import SparkLine from '../charts/sparkline/SparkLine';
import './CardSummary.css';

const style = {
    
}

const CardSummary = (props) => {

    const { respondants, answered, skipped } = props.summary;
    let responseRate = Number(Math.round(parseFloat(((answered / respondants) * 100) + 'e' + 2)) + 'e-' + 2);
    responseRate = responseRate ? responseRate : 0;

    return (
        <div className="card CardSummary" style={style}>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <h6 className="text-center summary-title">RESPONDANTS</h6>
                        <p className="text-center mb-4 summary-value">{respondants}</p>
                    </div>
                    <div className="col">
                        <h6 className="text-center summary-title">ANSWERED</h6>
                        <p className="text-center mb-4 summary-value">{answered}</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="text-center summary-title">SKIPPED</h6>
                        <p className="text-center summary-value">{skipped}</p>
                    </div>
                    <div className="col">
                        <h6 className="text-center summary-title">RESPONSE RATE</h6>
                        <p className="text-center summary-value">{responseRate}%</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="text-center mt-5 summary-title">AVERAGE RESPONSE TIME</h6>
                        <p className="text-center summary-value">{props.summary.averageResponseTime}s</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <h6 className="text-center mt-5 summary-title" style={{fontSize:'12px'}}>LAST 15 DAYS OF AVEREGE RESPONSE TIME</h6>
                        <SparkLine chartData={props.summary.trend} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardSummary;