import React from 'react';
import collect from 'collect.js';

const dataFormater = (data) => {
    let counter = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };
    let variableTopTwoBox = [];
    let result = [];
    let lineData = [];
    let barchart = [];
    let line = [];
    let lineChartTitle = "Overall";
    let topVariable = { name: "N/A" };
    let bottomVariable = { name: "N/A" };
    let responseResult = [];
    let totalResponse = 0;
    let totalLowLevelCounter = [];
    let overallT2B = 0;
    let overallB2B = 0;
    let overallNeutral = 0;
    let overallAverage = 0;
    let overallRating = 0;
    let channelT2B = [];
    let avgPercentage = [];
    let netPositiveBarChart = [];
    let channelNetPositiveBarChart = [];
    let channelNps = [];
    let overallNps = { promoter: 0, passive: 0, detractor: 0 };
    let lowLevelObjectNps = null;
    let lowLevelObjectCounter = [];
    let lowLevelChannelNps = [];


    if (Array.isArray(data)) {

        lineChartTitle = "Overall";

        let channelResponse = [];

        data.forEach(object => {
            let variables = object.answer.variable;
            let channel = collect(object.answer.channel);
            let responses = channel.map((item, index) => {
                if (Array.isArray(item)) {
                    item.forEach((element) => {
                        channelResponse.push({
                            name: element.name,
                            response: element.response ? element.response : 0,
                            count: element.count
                        });
                    });
                    return item.length ? collect(item).sum('response') : 0;
                } else {
                    channelResponse.push({
                        name: index,
                        response: item.response ? item.response : 0,
                        count: item.count
                    });
                    return item.response ? item.response : 0;
                }
            });

            if (variables.length) {

                variables.forEach(variable => {
                    counter["1"] += variable.count["1"];
                    counter["2"] += variable.count["2"];
                    counter["3"] += variable.count["3"];
                    counter["4"] += variable.count["4"];
                    counter["5"] += variable.count["5"];

                    let totalResponseOfVatiable = collect(variable.count).sum();
                    let variableTopTwoBoxValue = (variable.count["4"] + variable.count["5"]) / totalResponseOfVatiable;
                    variableTopTwoBoxValue = variableTopTwoBoxValue * 100;
                    variableTopTwoBox.push({
                        name: variable.title,
                        value: variableTopTwoBoxValue
                    });
                });

            }

            totalLowLevelCounter.push(counter);

            let totalResponse = collect(counter).sum() ? collect(counter).sum() : 1;

            // let highResponse = counter["4"] + counter["5"];
            // let percentageValue = (highResponse / totalResponse) * 100;
            // let value = Number(Math.round(parseFloat(percentageValue + 'e' + 2)) + 'e-' + 2);
            let value = getTop2Box(counter);
            let promoters = counter["5"];
            let detractors = counter["1"] + counter["2"] + counter["3"];

            let promotersPercentage = Number(Math.round(parseFloat(((promoters / totalResponse) * 100) + 'e' + 2)) + 'e-' + 2);
            let detractorsPercentage = Number(Math.round(parseFloat(((detractors / totalResponse) * 100) + 'e' + 2)) + 'e-' + 2);

            let passivepercentage = Number(Math.round(parseFloat((100 - (promotersPercentage + detractorsPercentage)) + 'e' + 2)) + 'e-' + 2);
            let nps = Number(Math.round(parseFloat((promotersPercentage - detractorsPercentage) + 'e' + 2)) + 'e-' + 2);

            
            if (variableTopTwoBox.length) {

                // Find high t2b percent of variables
                topVariable = variableTopTwoBox.reduce((a, c) => {
                    if (a.value > c.value) {
                        return a;
                    } else if (a.value === c.value) {
                        return a.name.toUpperCase() < c.name.toUpperCase() ? a : c;
                    } else {
                        return c;
                    }
                });

                // find low t2b percent of variables
                bottomVariable = variableTopTwoBox.reduce((a, c) => {
                    if (a.value < c.value) {
                        return a;
                    } else if (a.value === c.value) {
                        return a.name.toUpperCase() < c.name.toUpperCase() ? a : c;
                    } else {
                        return c;
                    }
                });

                topVariable = topVariable.value < 60 ? { name: "N/A" } : topVariable;
                bottomVariable = bottomVariable.value >= 60 ? { name: "N/A" } : bottomVariable;

                variableTopTwoBox.length = 0;

            }

            result.push({
                name: object.objectiveDetails.name,
                value: value,
                nps: {
                    value: nps,
                    promoter: promotersPercentage,
                    passive: passivepercentage,
                    detractor: detractorsPercentage,
                },
                topVariable: topVariable,
                bottomVariable: bottomVariable,
                responses: responses
            });

            topVariable = { name: "N/A" };
            bottomVariable = { name: "N/A" };

            lineData.push({
                "x": object.objectiveDetails.name,
                "y": value
            });

            barchart.push({
                name: object.objectiveDetails.name,
                value: value
            });

            counter = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };

        });

        // console.log({ channelResponse });

        channelResponse.reduce(function (a, c) {
            if (!a[c.name]) {
                a[c.name] = { name: c.name, value: 0, count: [] };
                responseResult.push(a[c.name])
            }
            a[c.name].value += c.response;
            if (c.count) {
                a[c.name].count.push(c.count);
            }
            return a;
        }, {});

        responseResult.forEach(item => {
            let counter = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };
            totalResponse += item.value;
            let top2Box = 0;
            let bottom2Box = 0;
            let netPositive = 0
            let promoter = 0;
            let detractor = 0;
            let nps = 0;

            item.count.forEach(count => {
                counter["1"] += count["1"];
                counter["2"] += count["2"];
                counter["3"] += count["3"];
                counter["4"] += count["4"];
                counter["5"] += count["5"];
            });

            top2Box = ((counter["4"] + counter["5"]) / collect(counter).sum()) * 100;
            top2Box = Number(Math.round(parseFloat(top2Box + 'e' + 2)) + 'e-' + 2);

            bottom2Box = ((counter["1"] + counter["2"]) / collect(counter).sum()) * 100;
            netPositive = Number(Math.round(parseFloat((top2Box - bottom2Box) + 'e' + 2)) + 'e-' + 2);

            promoter = (counter["5"] / collect(counter).sum()) * 100;
            detractor = ((counter["1"] + counter["2"] + counter["3"]) / collect(counter).sum()) * 100;
            nps = Number(Math.round(parseFloat((promoter - detractor) + 'e' + 2)) + 'e-' + 2);

            channelT2B.push({
                name: item.name,
                value: top2Box ? top2Box : 0
            });

            channelNps.push({
                name: item.name,
                value: nps
            });

            channelNetPositiveBarChart.push({
                name: item.name,
                value: netPositive ? netPositive : 0
            });
        });


        counter = { "1": 0, "2": 0, "3": 0, "4": 0, "5": 0 };

        totalLowLevelCounter.forEach((item) => {
            counter["1"] += item["1"];
            counter["2"] += item["2"];
            counter["3"] += item["3"];
            counter["4"] += item["4"];
            counter["5"] += item["5"];
        });

        overallT2B = ((counter["4"] + counter["5"]) / collect(counter).sum()) * 100;
        overallT2B = Number(Math.round(parseFloat(overallT2B + 'e' + 2)) + 'e-' + 2);

        overallB2B = ((counter["1"] + counter["2"]) / collect(counter).sum()) * 100;
        overallB2B = Number(Math.round(parseFloat(overallB2B + 'e' + 2)) + 'e-' + 2);

        overallNeutral = 100 - (overallT2B + overallB2B);
        overallNeutral = Number(Math.round(parseFloat(overallNeutral + 'e' + 2)) + 'e-' + 2);

        overallRating = collect(counter).map((item, index) => item * index).sum();
        overallAverage = overallRating / collect(counter).sum();
        overallAverage = Number(Math.round(parseFloat(overallAverage + 'e' + 2)) + 'e-' + 2);

        overallNps.promoter = Number(Math.round(parseFloat(((counter["5"] / collect(counter).sum()) * 100) + 'e' + 2)) + 'e-' + 2);
        overallNps.promoter = overallNps.promoter ? overallNps.promoter : 0;
        overallNps.passive = Number(Math.round(parseFloat(((counter["4"] / collect(counter).sum()) * 100) + 'e' + 2)) + 'e-' + 2);
        overallNps.passive = overallNps.passive ? overallNps.passive : 0;
        overallNps.detractor = Number(Math.round(parseFloat((((counter["1"] + counter["2"] + counter["3"]) / collect(counter).sum()) * 100) + 'e' + 2)) + 'e-' + 2);
        overallNps.detractor = overallNps.detractor ? overallNps.detractor : 0;

    } else {

        let variables = data.answer.variable;

        let avgPercentageValue = [];

        lineChartTitle = data.objectiveDetails.name;

        variables.forEach(variable => {

            let totalResponse = collect(variable.count).sum();

            // console.log({totalResponse});
            let variablePercent = [];

            variablePercent[0] = Number(Math.round(parseFloat(((variable.count["1"] / totalResponse) * 100) + 'e' + 0)) + 'e-' + 0);
            variablePercent[1] = Number(Math.round(parseFloat(((variable.count["2"] / totalResponse) * 100) + 'e' + 0)) + 'e-' + 0);
            variablePercent[2] = Number(Math.round(parseFloat(((variable.count["3"] / totalResponse) * 100) + 'e' + 0)) + 'e-' + 0);
            variablePercent[3] = Number(Math.round(parseFloat(((variable.count["4"] / totalResponse) * 100) + 'e' + 0)) + 'e-' + 0);
            variablePercent[4] = Number(Math.round(parseFloat(((variable.count["5"] / totalResponse) * 100) + 'e' + 0)) + 'e-' + 0);

            counter["1"] += variable.count["1"];
            counter["2"] += variable.count["2"];
            counter["3"] += variable.count["3"];
            counter["4"] += variable.count["4"];
            counter["5"] += variable.count["5"];

            let average = ((variable.count["1"] * 1)
                + (variable.count["2"] * 2)
                + (variable.count["3"] * 3)
                + (variable.count["4"] * 4)
                + (variable.count["5"] * 5)) / totalResponse;

            average = Number(Math.round(parseFloat(average + 'e' + 2)) + 'e-' + 2);

            let avgPercentage = Number(Math.round(parseFloat(((average / 5) * 100) + 'e' + 2)) + 'e-' + 2);

            avgPercentageValue.push({
                x: variable.title,
                y: avgPercentage
            })


            let highResponse = variable.count["4"] + variable.count["5"];
            let percentageValue = (highResponse / totalResponse) * 100;
            let value = Number(Math.round(parseFloat(percentageValue + 'e' + 2)) + 'e-' + 2);

            let highResponsePercentage = value;
            let lowResponsePercentage = ((variable.count["1"] + variable.count["2"]) / totalResponse) * 100;
            lowResponsePercentage = Number(Math.round(parseFloat(lowResponsePercentage + 'e' + 2)) + 'e-' + 2);

            let neutralResponsePercentage = (variable.count["3"] / totalResponse) * 100;
            neutralResponsePercentage = Number(Math.round(parseFloat(neutralResponsePercentage + 'e' + 2)) + 'e-' + 2);

            let promoters = variable.count["5"];
            let detractors = variable.count["1"] + variable.count["2"] + variable.count["3"];

            let promotersPercentage = Number(Math.round(parseFloat(((promoters / totalResponse) * 100) + 'e' + 2)) + 'e-' + 2);
            let detractorsPercentage = Number(Math.round(parseFloat(((detractors / totalResponse) * 100) + 'e' + 2)) + 'e-' + 2);

            let passivepercentage = Number(Math.round(parseFloat((100 - (promotersPercentage + detractorsPercentage)) + 'e' + 2)) + 'e-' + 2);
            let nps = Number(Math.round(parseFloat((promotersPercentage - detractorsPercentage) + 'e' + 2)) + 'e-' + 2);

            result.push({
                name: variable.title,
                value: value,
                average: average,
                avgPercentage: avgPercentage,
                nps: {
                    value: nps,
                    promoter: promotersPercentage,
                    passive: passivepercentage,
                    detractor: detractorsPercentage
                },
                variablePercent: variablePercent,
                threePointChart: [['1', highResponsePercentage], ['2', neutralResponsePercentage], ['3', lowResponsePercentage]]
            });

            lineData.push({
                "x": variable.title,
                "y": value
            });

            variableTopTwoBox.push({
                name: variable.title,
                value: value
            });

            netPositiveBarChart.push({
                name: variable.title,
                value: Number(Math.round(parseFloat((highResponsePercentage - lowResponsePercentage) + 'e' + 2)) + 'e-' + 2)
            })

        });

        lowLevelObjectCounter = lowLevelObjectResponseCounter(counter);
        lowLevelObjectNps = lowLevelObjectNpsFinder(counter);
        overallT2B = getTop2Box(counter);


        let channel = collect(data.answer.channel);
        let responses = [];
        let lowLevelObjectChannelResponses = [];
        channel.map((item, index) => {
            if (Array.isArray(item)) {
                item.forEach(device => {
                    responses.push({
                        name: device.name,
                        value: device.response
                    });
                    lowLevelObjectChannelResponses.push({
                        name: device.name,
                        response: device.response,
                        indexCount: device.count
                    });
                });
            } else {
                responses.push({
                    name: index,
                    value: item.response ? item.response : 0
                });
                lowLevelObjectChannelResponses.push({
                    name: index,
                    response: item.response ? item.response : 0,
                    indexCount: item.count
                });
            }
        });

        lowLevelObjectChannelResponses.forEach((response) => {
            let result = lowLevelObjectNpsFinder(response.indexCount);
            lowLevelChannelNps.push({
                name: response.name,
                promoter: result.promoter,
                passive: result.passive,
                detractor: result.detractor
            });
        });

        totalResponse = collect(responses).sum('value');
        barchart = responses;

        avgPercentage.push({
            "id": `Avereage ${lineChartTitle}`,
            "data": avgPercentageValue
        });

    }

    line.push({
        "id": lineChartTitle,
        "data": lineData
    });

    return {
        result: result,
        line: line,
        barchart: barchart,
        responseResult: responseResult,
        totalResponse: totalResponse,
        overallT2B: overallT2B ? overallT2B : 0,
        overallB2B: overallB2B ? overallB2B : 0,
        overallNeutral: overallNeutral ? overallNeutral : 0,
        overallAverage: overallAverage ? overallAverage : 0,
        overallRating: overallRating ? overallRating : 0,
        channelT2B: channelT2B,
        variableTopTwoBox: variableTopTwoBox,
        avgPercentage: avgPercentage,
        netPositiveBarChart: netPositiveBarChart,
        netPositiveValue: (overallT2B - overallB2B) ? (overallT2B - overallB2B) : 0,
        channelNetPositiveBarChart: channelNetPositiveBarChart,
        channelNps: channelNps,
        overallNps: overallNps,
        lowLevelObjectCounter: lowLevelObjectCounter,
        lowLevelObjectNps: lowLevelObjectNps ? lowLevelObjectNps : { promoter: 0, passive: 0, detractor: 0 },
        lowlevelChannelNps: lowLevelChannelNps
    }


}
export { dataFormater };


/*--------------------------------------------------------------------------------------------------------------------------
                                        ADDITIONAL HELPING FUNCTION
---------------------------------------------------------------------------------------------------------------------------*/
const lowLevelObjectResponseCounter = (counter) => {

    let responseCategory = { "1": "Strongly Disagree", "2": "Disagree", "3": "Neutral", "4": "Agree", "5": "Strongly Agree" }
    let lowLevelCounter = collect(counter).map((item, index) => {
        return {
            name: responseCategory[index],
            value: item
        }
    }).toArray();

    return lowLevelCounter;
}


const getTop2Box = (counter) => {
    const totalResponse = collect(counter).sum() ? collect(counter).sum() : 1;
    const top2Box = counter["4"] + counter["5"];
    const percentageValue = (top2Box / totalResponse) * 100;
    const value = Number(Math.round(parseFloat(percentageValue + 'e' + 2)) + 'e-' + 2);
    return value;
}


const lowLevelObjectNpsFinder = (counter) => {

    let promoter = 0;
    let passive = 0;
    let detractor = 0;
    let totalResponse = collect(counter).sum();
    totalResponse = totalResponse ? totalResponse : 1; // to prevent divided by zero problem
    let nps = 0;

    // console.log("count", totalResponse);

    if (counter) {

        promoter = (counter["5"] / totalResponse) * 100;
        promoter = Number(Math.round(parseFloat(promoter + 'e' + 2)) + 'e-' + 2);

        passive = (counter["4"] / totalResponse) * 100;
        passive = Number(Math.round(parseFloat(passive + 'e' + 2)) + 'e-' + 2);

        detractor = ((counter["1"] + counter["2"] + counter["3"]) / totalResponse) * 100;
        detractor = Number(Math.round(parseFloat(detractor + 'e' + 2)) + 'e-' + 2);

        // nps = Number(Math.round(parseFloat( (promoter - detractor) + 'e' + 2)) + 'e-' + 2);

    }

    return {
        promoter: promoter ? promoter : 0,
        passive: passive ? passive : 0,
        detractor: detractor ? detractor : 0,
    }
}
