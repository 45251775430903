import React from 'react'
import {Button} from 'primereact/button'
import {connect} from 'react-redux';
import axios from 'axios';
const md5 = require('md5');


class LinkButton extends React.Component {


  state={
    openLink:false,
    link:''
  }

  closeLinkHandler = () => {
    this.setState({
      openLink: !this.state.openLink
    })
  }

  linkHandler = () => {

    const hashLink = md5(`api/surveys/user/${this.props.questionList.userId}`);

    const data ={
      actualLink : `http://localhost:3001/api/surveys/user/${this.props.questionList.userId}`,
      hashLink : `http://localhost:3001/${hashLink}`
    }

    axios.post('http://localhost:5000/api/link/post',data)
      .then((res) => {
        // console.log(res.data)
      })
      .catch((err) => {
        // console.log(err)
      })

    this.setState({
      openLink: !this.state.openLink,
      link : `http://localhost:3001/${hashLink}`
    })

  }


  render(){
    
    return(
      <div>
        {
          this.state.openLink === true &&
          <article class="message">
          <div class="message-header">
            <p> {this.state.link} </p>
            <button class="delete" aria-label="delete" onClick={this.closeLinkHandler}></button>
          </div>
          </article>
        }
    <Button id="Generate Link" label="Generate Link" icon="pi pi-save" onClick={this.linkHandler} />
    
  </div>
    )
  }
}

const mapStatetoProps = state => ({
  questionList: state
})

export default connect(mapStatetoProps)(LinkButton)
