import React from 'react'
import './style.css';

const CardContainer = (props) => {
    return (
        <div className="CardContainer">
            <div className="card-body py-1">
                {props.children}
            </div>
        </div>
    );
}

export default CardContainer;