import React, { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReactMonthPicker.css";

const ReactMonthPicker = (props) => {
    const [startDate, setStartDate] = useState(new Date());

    const handleChange = (date) => {
        setStartDate(date);
    }

    props.getFilterParam({
        startDate: startDate,
        endDate:null,
        type:props.type
    });

    return (
        <div className="mx-2 ReactMonthPicker">
            {/* <h6 className="text-left" style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Month</h6> */}
            <DatePicker
                showMonthYearPicker
                dateFormat="MMM, yyyy"
                selected={startDate}
                onChange={handleChange}
                withPortal
            />
        </div>
    );
}

export default ReactMonthPicker;