import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  // display: flex;
  flex-wrap:wrap;
  padding: 0;
`;

export const Image = styled.div`
  box-sizing: border-box;
  position: absolute;
  // flex:0 0 20%;
  padding: 0;
  margin: 0;
  top:0;
  left:0;
  max-width:120px;
  max-height:120px;

`

export const TextBox = styled.div`
  box-sizing: border-box;
  position: relative;
  // flex:1 0 75%;
  margin: 0 0 0 125px;
`

export const Title = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  font-weight:700;
  color:#424242;
  text-align: center;
`

export const ImgPreview = styled.div`
  // display:flex;
  border: 2px solid white;
  position: relative;
  min-width:120px;
  min-height:120px;
  width: 96%;
  height: 100%;
  background-color: #F2F3F2;
  box-shadow: 0 0 3px grey;
`

  export const UploadContainer = styled.div`
  width:30px;
  height:30px;
  bottom: 6px;
  right: 6px;
  position: absolute;
  border: 1px solid white;
  z-index:1;
  background-color:rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  `


export const Upload = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
`

export const UploadButton = styled.div`
  background-color: #310D31;
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
