import styled from 'styled-components'

export const Body = styled.div`
position: absolute;
top:60px;
display: flex;
box-sizing: border-box;
width: 100%;

flex-flow: row wrap;
justify-content: flex-start;
align-items: flex-start;
align-content: flex-start;
`

export const Back = styled.button`
  align-self: flex-start;
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 10px 10px 5px 10px;
  outline: none;
  border: none;
`


export const Button = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  color: white;
  border: none;
  background-color: #1B998B;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 1;
  :hover{
    background-color: #0B6157;
    color: #fff;
    border: 1px solid #0B6157;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
    width: 90px;
    height: 25px;
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
    width: 80px;
    height: 20px;
  }
`

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  width: 50%;
  background-color: white;
  box-shadow: 1px 1px 5px -1px #586469;
  border-radius:5px;
`

export const CloseButton = styled.div`
  color: #C52590;
  font-size: 20px;
  line-height:18px;
  position: absolute;
  text-align: center;
  top: -10px;
  right: -10px;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 5px -1px #586469;
  :hover{
    cursor: pointer;
    box-shadow: 1px 1px 5px 2px #586469;
  }
`

export const Edit = styled(Button)`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  right: 15px;
  width: auto;
  height: auto;
  padding: 2px 5px;
  background-color: #fff;
  color: rgb(52, 58, 64);
  z-index: 6;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: #1B998B;
    color: white;
    border: none;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  }
`

export const Delete = styled(Button)`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  left: 15px;
  width: auto;
  height: auto;
  padding: 2px 5px;
  background-color: #fff;
  color: #ff1744;
  z-index: 6;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: #F27D7D;
    color: white;
    border: none;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  }
`

export const AddNewItem = styled.div`
display: flex;
box-sizing: border-box;
position: relative;
justify-content: center;
align-items: center;
margin:10px;
width: 80px;
height: 40px;
cursor: pointer;
background-color: white;
box-shadow: 2px 2px 5px grey;
border-radius:5px;
color:#310E31;
:hover {
  box-shadow: 5px 5px 10px grey;
}
@media only screen and (max-width: 768px){
  width:20%;
}

@media only screen and (max-width: 425px){
  width:20%;
}
`

//===
export const Error = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
`

export const Aside = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    margin-bottom:10px;
    color: #310D31 !important;
    >section {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding-left:15px;  
    }
    >table{
      border: 1px solid #ddd;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
    }
    >table >tr >th{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td >input{
      width:25%;
    }
  `


export const Input = styled.input.attrs(props => ({
  ...props
}))`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 10px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 3px 1px hsl(0,0%,80%);
  border-radius: 4px;
  height: 35px;
  :placeholder {
    color: #310D31;
    opacity: 1;
  }
`
export const TextArea = styled.textarea.attrs(props => ({
  ...props
}))`
font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 10px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 3px 1px hsl(0,0%,80%);
  border-radius: 4px;
  height: 80px;
  :placeholder {
    color: #310D31;
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: left;
  flex-direction: row;
  text-align: center;
  align-items:center;
  padding:10px 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: rgb(52, 58, 64);
  font-weight:normal;
  font-size: 15px;
  > h2 {
    font-size: 30px;
    @media only screen and (max-width: 768px){
      font-size: 18px;
      margin-top: 30px;
    }
    @media only screen and (max-width: 425px){
      font-size: 16px;
      margin-top: 45px;
    }
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`
export const Modal = styled.div`  
  box-sizing: border-box;
  display: ${props=>props.view ? "flex": "none"};
  justify-content: center;
  align-items: center;
  color: black;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0,0,0,0.35);
  left: 0;
  top: 0;
`

export const Form = styled.form.attrs(props=>({
  ...props
}))`
  width: 100%;
`