import React, {useState} from 'react'
import image from './assets/CSAT.png'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import './style.css' 

function CSAT() { 
  const [reqDemo, setReqDemo] = useState(false); 
  const cancelModal = () => setReqDemo(false); 

  let style={ 
    ourBtn: { 
      backgroundColor: '#1B998B', 
      borderColor: '#1B998B', 
      color: 'white', 
      borderRadius: '0%' 
    } 
  } 
  return ( 
    <div className="container boxStyle"> 
      <div className="row align-items-center"> 
         
        <div className="col-sm-12 col-md-6 col-lg-6 orderText">
        <div className="col-lg-6 d-block d-sm-none orderImg">  
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <img className="img-fluid employeeImg" src={image} alt="Customer Satisfaction" /> 
          </div> 
        </div>  
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <h6 className="display-4 heading mb-3" style={{fontFamily: 'Roboto', color: 'rgb(52, 58, 64)'}}> 
              Improve Customer <br /> Satisfaction 
            </h6> 
            <p className="text" style={{fontFamily: 'Roboto', color: 'rgb(52, 58, 64)'}}> 
              CSAT, commonly knows as Customer Satisfaction is a measurement of your products or services meeting or surpassing your customer’s expectation. The satisfaction quotient is not the same for all your customers. It varies from person to person. That is why you need to pinpoint the dissatisfaction reason then take necessary measures to turn your dissatisfied customers into satisfied customers. 
            </p> 
            <Button className="btn btn-sm btnHover" 
              onClick={() => setReqDemo(true)}> 
              Request Demo  
            </Button> 
          </div> 
        </div>
        <div className="col-sm-12 col-md-6 col-lg-6 d-none d-sm-block orderImg">  
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <img className="img-fluid employeeImg" src={image} alt="Customer Satisfaction" /> 
          </div> 
        </div> 
      </div> 

      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    </div> 
  ) 
} 

export default CSAT; 
