import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const CloseButton = styled(Link)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #e6e6e6;
  color: red;
  text-decoration: none;
  // border: 1px solid black;
  :hover {
    color: red;
    box-shadow: 1px 1px 5px 2px black;
    border: none;
    > span {
      text-shadow: 0 0 red;
    }
  }
`;

export const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  // height: 90%;
  // width: 90%;
  // background: white;
  z-index: 501;
`;

const FormButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #fff;
  height: 55px;
  width: 56px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  @media (max-width: 767.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
  @media (max-width: 991.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
`;

export const DistributeButton = styled(FormButton)`
  background: rgb(27, 153, 139);
  position: fixed;
  top: 85px;
  right: 50px;
  z-index: 2;
  height: auto;
  width: auto;
  padding: 5px 20px;
  border-radius: 5px;
  :disabled {
    background: #ec3b3b;
  }
  @media (max-width: 767.92px) {
    top: 80px;
    right: 45px;
  }
  @media (max-width: 991.92px) {
    top: 55px;
    right: 90px;
    width: auto;
    height: auto;
  }
`;

// export const DistributeButton = styled.button`
//   display: block;
//   position: fixed;
//   z-index: 20;
//   font-size: 10px;
//   width: 60px;
//   height: 60px;
//   top: 85px;
//   right: 100px;
//   font-size: 12px;
//   font-weight: 500;
//   border-radius: 50%;
//   border: none;
//   outline: none;
//   padding: auto;
//   margin: 0;
//   line-height: 1;
//   text-align: center;
//   text-decoration: none;
//   // background: green;
//   background: #1b9989;
//   color: white;
//   cursor: pointer;
//   box-shadow: 0 0 3px 1px grey;
//   :hover {
//     box-shadow: 2px 2px 5px 2px grey;
//   }
//   :disabled {
//     background: #ec3b3b;
//   }
// `;
