import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, startDate } from '../../redux/actions'
import './style.css'
import { Fields, QuestionCard, QuestionTitle } from '../styles'
import { Input } from './styles'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

whyDidYouRender(React, {
    onlyLogs: true,
    titleColor: "green",
    diffNameColor: "aqua"
})

const DateType = props => {
    const { data, answer, updateData, startDate } = props

    useEffect(() => {
        if (data.hasOwnProperty('id')) {
            startDate(new Date())
        }
    }, [data])

    const handleChange = date => {
        // const { value } = event.target
        updateData(date)
    }

    return (
        <QuestionCard>
            <QuestionTitle>{data.title}</QuestionTitle>
            {/* <div className="row w-100">
                <div className="col text-left numeric-box-label">{data.min}</div>
                <div className="col text-right numeric-box-label">{data.max}</div>
            </div> */}
            {/* <label className="numeric-range">
                <input
                    id="typeinp"
                    type="range"
                    min="0"
                    max="1000"
                    // value={answer}
                    onChange={handleChange}
                    step="1" />
                {answer}startDate
            </label> */}
            <DatePicker
                selected={answer}
                onChange={handleChange}
                dateFormat="dd/MM/yyyy"
            />
        </QuestionCard>
    )
}

DateType.whyDidYouRender = true

const mapStateToProps = state => {
    return {
        data: questionDataSelector(state),
        answer: answerFieldValueSelector(state)
    }
}

const mapDispatchToProps = {
    updateData,
    startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(DateType))