import React from 'react'
import Mision from './Assets/Mision.JPG'
import Vision from './Assets/Vision.JPG'

function MainContent() { 
  const content = [
    {
      id: '01', 
      image: Mision, 
      title: 'ProlificXM Mission Statement',
      text: 'To empower consumers and brands with powerful tools to enhance better growth in modern business era.'
    }, 
    { 
      id: '02', 
      image: Vision, 
      title: 'ProlificXM Vision Statement',
      text: 'ProlificXM dreams to be the largest experience management for the upcoming world.' 
    } 
  ] 

  const styles = { 
    title: { color: '#1B998B', fontSize: '22px', fontFamily: 'Roboto', textAlign: 'center', marginTop: '30px'  }, 
    text: { color: 'rgb(52, 58, 64)', fontSize: '20px', fontFamily: 'Roboto', textAlign: 'center', fontWeight: '500'  }, 
    image: { maxHeight: '230px' } 
  } 

  return ( 
    <div> 
      { 
        content.map((obj, i) => { 
          if(i % 2 === 0) { 
            return ( 
              <div key={`id: ${i}`}> 
                <h5 style={styles.title}> { obj.title } </h5> 
                <div key={`id: ${i}`} className="row align-items-center mt-3 p-3"> 
                  <div className="col-sm-12 col-md-6 col-lg-6"> 
                    <p style={styles.text}> { obj.text } </p> 
                  </div> 
                  <div className="col-sm-12 col-md-6 col-lg-6 aboutImg"> 
                    <div className="text-center" style={{width: '100%'}}> 
                      <img className="img-fluid" src={obj.image} style={styles.image} alt="image" /> 
                    </div> 
                  </div> 
                </div> 
              </div> 
            ) 
          } 
          else { 
            return ( 
              <div key={`id: ${i}`}> 
                <h5 style={styles.title}> { obj.title } </h5> 
                <div key={`id: ${i}`} className="row align-items-center mt-3 p-3"> 
                  <div className="col-sm-12 col-md-6 col-lg-6 aboutImg"> 
                    <div className="text-center" style={{width: '100%'}}> 
                      <img className="img-fluid" src={obj.image} style={styles.image} alt="image" /> 
                    </div> 
                  </div> 
                  <div className="col-sm-12 col-md-6 col-lg-6"> 
                    <p style={styles.text}> { obj.text } </p> 
                  </div> 
                </div> 
              </div> 
            ) 
          } 
        }) 
      } 
    </div> 
  ) 
} 

export default MainContent; 
