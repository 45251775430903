import React, { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import './style.css'
import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, nextConditionalQuestion, startDate } from '../../redux/actions'

import { QuestionCard, QuestionTitle } from '../styles'
import { RadioButton } from 'primereact/radiobutton'
import { Rating, Gif, Label, Fields, Scale } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'
// import one from './resources/1.gif'
// import two from './resources/2.gif'
// import three from './resources/3.gif'
// import four from './resources/4.gif'
// import five from './resources/5.gif'
// import six from './resources/6.gif'
// import seven from './resources/7.gif'

// const emojis = [one, two, four, six, seven]

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const LikertScale = props => {
  const { data, updateData, answer, nextConditionalQuestion, startDate } = props
  const { isConditional, jump, title } = data
  const [starratingSelected, setStarratingSelected] = useState(null)

  useEffect(() => {
    setStarratingSelected(null)
  }, [data.id])

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  useEffect(() => {
    if (answer.index !== null)
      setStarratingSelected(answer.index)
  }, [answer])

  useEffect(() => {
    // console.log(answer)
    if (answer.index !== null) {
      if (isConditional) {
        const nextQuestion = jump.find(item => item.jumpOptionIndex === answer.index)
        if (!nextQuestion) {
          nextConditionalQuestion("NEXT")
        } else {
          nextConditionalQuestion(nextQuestion.jumpTo)
        }
      }
    }
  }, [answer])

  const handleChange = (event) => {
    const { id, value } = event.target
    const index = parseInt(id, 10)
    setStarratingSelected(index)
    updateData({
      index,
      value
    })
    if (isConditional) {
      const nextQuestion = jump.find(item => item.jumpOptionIndex === index)
      if (!nextQuestion) {
        nextConditionalQuestion("NEXT")
      } else {
        nextConditionalQuestion(nextQuestion.jumpTo)
      }
    }
  }

  const selectCheck = value => {
    return value === answer.index
  }

  const ratings = data.ratings.map((rating, index) =>
    // <Rating key={`rating-${index}`}>
    //   <RadioButton
    //     id={index}
    //     name={data.id}
    //     type="radio"
    //     value={rating}
    //     onChange={handleChange}
    //     checked={selectCheck(index)}
    //   />
    //   <Gif src={emojis[index]} />
    //   <Label>
    //     {rating}
    //   </Label>
    // </Rating>
    <div key={`rating-${index}`} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
      <input type="radio" inputId={index} id={index} name={data.id} value={rating} onChange={handleChange} checked={selectCheck(index)} className="starratingres" />
      {/* <FontAwesomeIcon icon={faStar} size="2x" style={{ color: "#757575", marginBottom: "5px" }} /> */}
      <label htmlFor={index} style={{ cursor: 'pointer' }}><FontAwesomeIcon icon={faStar} size="2x" style={{ color: index < starratingSelected ? "#eec231" : "#757575", marginBottom: "5px" }} /></label>
    </div>
  )

  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        <Scale>
          {ratings}
        </Scale>
      </Fields>
    </QuestionCard>)
}

LikertScale.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state)
  }
}

const mapDispatchToProps = {
  updateData,
  nextConditionalQuestion,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(LikertScale))