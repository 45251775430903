import React, { useEffect, Fragment, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Input } from 'reactstrap';
import { Formik } from 'formik'
import * as Yup from 'yup'
import Select from 'react-select';
import { withRouter } from 'react-router-dom'
import axios from 'axios'

import baseUrl from '../../../../url'
import { createNewMicroObjective, backButtonFunction } from '../../store/actions/actionCreator'
import showToast from '../../../../components/Toast/toast'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import './style.css' 

class MySelect extends React.Component {

    state = { options: [] }

    componentDidMount() {
        axios.get(`${baseUrl}/api/prm/industry`).then(
            response => {
                const option = response.data.data.map(obj => {
                    return {
                        value: obj._id,
                        label: obj.user_Name || obj.name
                    }
                })
                this.setState({ options: option })
            }
        ).catch(
            error => console.log(error)
        )
    }

    handleChange = value => {
        this.props.onChange('industryIds', value);
    };

    handleBlur = () => {
        this.props.onBlur('industryIds', true);
    };

    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                {/* <label htmlFor="color">Topics (select at least 3) </label> */}
                <Select
                    id="color"
                    options={this.state.options}
                    isMulti
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                />
                {!!this.props.error &&
                    this.props.touched && (
                        <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                    )}
            </div>
        );
    }
}

class MySelectObjectiveId extends React.Component {

    state = { options: [] }

    componentDidMount() {
        axios.get(`${baseUrl}/api/objectives`).then(
            response => {
                // console.log(response)
                const option = response.data.features.map(obj => {
                    return {
                        value: obj._id,
                        label: obj.user_Name || obj.name
                    }
                })
                this.setState({ options: option })
            }
        ).catch(
            error => console.log(error)
        )
    }

    handleChange = value => {
        this.props.onChange('objectIds', value);
    };

    handleBlur = () => {
        this.props.onBlur('objectIds', true);
    };

    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                {/* <label htmlFor="color">Topics (select at least 3) </label> */}
                <Select
                    id="color"
                    options={this.state.options}
                    isMulti
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                />
                {!!this.props.error &&
                    this.props.touched && (
                        <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                    )}
            </div>
        );
    }
}

const styles = {
    error: {
        color: 'red',
        fontSize: '12px',
        marginLeft: '5px',
        textAlign: 'left',
        fontWeight: 'bold'
    }
}

function CreateBrand(props) {

    const { history } = props;
    const [industry, setIndustry] = useState([])
    const [profile, setProfile] = useState([])

    let dispatch = useDispatch()
    let { allData } = useSelector(state => state.microObjective)

    const CreateToast = () => showToast('Created Successfully')
    const ErrorToast = () => showToast('Server Error')

    useEffect(
        () => {

            axios.get(`${baseUrl}/api/prm/industry`).then(
                response => {
                    setIndustry(response.data.data)
                }
            ).catch(
                error => console.log(error)
            )

            axios.get(`${baseUrl}/api/profile/all`).then(
                response => { setProfile(response.data.data); }
            ).catch(
                error => console.log(error)
            )

        }
        , [])


    return (
        <Fragment>
            <div className="container">
                <BreadCrumb path="Micro-Level Objective" slug="Create" link="/app/microObjectives/" backButtonFunction={backButtonFunction} />

                <Card className="my-3">
                    <CardBody>
                        <Formik

                            initialValues={{
                                profileId: '',
                                name: '',
                                active: true,
                                isDefault: true,
                                industryIds: [],
                                objectIds: [],
                                // featureIds: [],
                                description: '',
                                // tag: ''
                            }}

                            validationSchema={
                                Yup.object().shape({
                                    name: Yup
                                        .string().required("Required").trim().lowercase()
                                        .notOneOf(allData ? allData.map(obj => obj.name.toLowerCase()) : [], "Already Exist"),
                                    profileId: Yup
                                        .string().when("isDefault", {
                                            is: false,
                                            then: Yup.string().required("Required")
                                        })
                                    // industryId: Yup.array()
                                    //     .min(1, 'Pick at least 1 tags')
                                    //     .of(
                                    //         Yup.object().shape({
                                    //             label: Yup.string().required(),
                                    //             value: Yup.string().required(),
                                    //         })
                                    //     ),
                                })
                            }

                            onSubmit={(values, { resetForm }) => {
                                const indId = values.industryIds.map(obj => obj.value)
                                const objId = values.objectIds.map(obj => obj.value)
                                const createdData = {
                                    "profileId": values.profileId,
                                    "name": values.name,
                                    "active": values.active,
                                    "isDefault": values.isDefault,
                                    "industryIds": indId,
                                    "objectIds": objId,
                                    "createdAt": Date.now(),
                                    "description": values.description,
                                    "trends": '',

                                }

                                dispatch(createNewMicroObjective(createdData, CreateToast, ErrorToast));
                                resetForm();
                                history.push('/app/microObjectives/');

                            }}>

                            {
                                (props) => (
                                    <Form onSubmit={props.handleSubmit}>
                                        <FormGroup row>



                                            <Label htmlFor="name" sm={2}>
                                                Name: <span style={{ color: 'red' }}>{` *`}</span>
                                            </Label>
                                            <Col sm={4}>
                                                <Input type="text" name="name" id="name"
                                                    value={props.values.name}
                                                    onChange={props.handleChange}
                                                    style={{ border: props.errors.name && props.touched.name ? '1px solid red' : null }}
                                                    onBlur={props.handleBlur} placeholder="Enter broad objective name"
                                                />
                                                {
                                                    props.errors.name && props.touched.name &&
                                                    <p style={styles.error}> {props.errors.name} </p>
                                                }
                                            </Col>

                                            <Label htmlFor="isDefault" sm={2}>
                                                Default:
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="checkbox" id="isDefault" name="isDefault"
                                                    className="form-check-input mt-2"
                                                    checked={props.values.isDefault}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur} />
                                                {
                                                    props.errors.isDefault && props.touched.isDefault &&
                                                    <p style={styles.error}> {props.errors.isDefault} </p>
                                                }
                                            </Col>

                                        </FormGroup>

                                        <FormGroup row>

                                            <Label htmlFor="industryIds" sm={2}>
                                                Industry:
                                            </Label>
                                            <Col sm={4}>
                                                <MySelect
                                                    value={props.values.industryIds}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    error={props.errors.industryIds}
                                                    touched={props.touched.industryIds}
                                                />
                                            </Col>

                                            <Label htmlFor="active" sm={2}>
                                                Active:
                                            </Label>
                                            <Col sm={4}>
                                                <Input
                                                    type="checkbox" id="active" name="active"
                                                    className="form-check-input mt-2"
                                                    checked={props.values.active}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur} />
                                                {
                                                    props.errors.active && props.touched.active &&
                                                    <p style={styles.error}> {props.errors.active} </p>
                                                }
                                            </Col>
                                        </FormGroup>

                                        <FormGroup row>



                                        </FormGroup>

                                        <FormGroup row>

                                            <Label htmlFor="objectIds" sm={2}>
                                                Objective:
                                            </Label>
                                            <Col sm={4}>
                                                <MySelectObjectiveId
                                                    value={props.values.objectIds}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    error={props.errors.objectIds}
                                                    touched={props.touched.objectIds}
                                                />
                                            </Col>

                                            {!props.values.isDefault ? <>
                                                <Label htmlFor="profileId" sm={2}>
                                                    Profile: <span style={{ color: 'red' }}>{` *`}</span>
                                                </Label>
                                                <Col sm={4}>
                                                    <Input
                                                        type="select" name="profileId" id="profileId"
                                                        style={{ border: props.errors.profileId && props.touched.profileId ? '1px solid red' : null }}
                                                        value={props.values.profileId}
                                                        onChange={props.handleChange}
                                                        onBlur={props.handleBlur} >
                                                        <option value="" disabled defaultValue hidden>Select...</option>
                                                        {profile.map((obj) => <option value={obj._id} key={obj._id}> {obj.user_Name || obj.name} </option>)}
                                                    </Input>
                                                    {
                                                        props.errors.profileId && props.touched.profileId &&
                                                        <p style={styles.error}> {props.errors.profileId} </p>
                                                    }
                                                </Col>
                                            </> : null}

                                            {/* <Label htmlFor="tag" sm={2}>
                                                Tag: <span style={{ color: 'red' }}>{` *`}</span>
                                            </Label>
                                            <Col sm={4}>
                                                <Input type="text" name="tag" id="tag"
                                                    value={props.values.tag}
                                                    onChange={props.handleChange}
                                                    style={{ border: props.errors.tag && props.touched.tag ? '1px solid red' : null }}
                                                    onBlur={props.handleBlur} placeholder="Enter broad objective tag"
                                                />
                                                {
                                                    props.errors.tag && props.touched.tag &&
                                                    <p style={styles.error}> {props.errors.tag} </p>
                                                }
                                            </Col> */}

                                        </FormGroup>

                                        <FormGroup row>
                                            <Label htmlFor="description" sm={2}>
                                                Description:
                                            </Label>
                                            <Col sm={10}>
                                                <Input
                                                    type="textarea" id="description" name="description"
                                                    checked={props.values.description}
                                                    onChange={props.handleChange}
                                                    onBlur={props.handleBlur} />
                                                {
                                                    props.errors.description && props.touched.description &&
                                                    <p style={styles.error}> {props.errors.description} </p>
                                                }
                                            </Col>
                                        </FormGroup>

                                        <Button style={{ background: '#1B998B', borderColor: '#1B998B' }} size="sm" className="btnHover" type="submit">
                                            Save
                                        </Button>

                                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 10px' }} className="btnHover" size="sm"
                                            onClick={() => { history.push('/app/microObjectives') }}>
                                            Cancel
                                        </Button> 
                                    </Form> 
                                ) 
                            } 
                        </Formik> 
                    </CardBody> 
                </Card>
            </div>
        </Fragment>
    )
}

export default withRouter(CreateBrand)