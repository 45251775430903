import styled from 'styled-components'


export const Arrow = styled.p` 
  font-size: 15px; 
  color: black; 
  padding: 3px; 
  
  position: absolute; 
  right: 8px; 
  display: inline; 
` 


export const Container = styled.div`
  position: fixed;
  box-sizing: border-box;
  top: calc(60px + 10%);
  left: ${({sidebarState}) => sidebarState ? '100px' : '-60px'};
  /* left: 90px; */
  width: 150px;
  height: 70vh;
  z-index: 3;
  padding: 8px 8px;
  background: rgb(255, 255, 255);
  box-shadow: 1px 1px 10px 2px grey;
  transition: left 0.2s ease;
  &:hover{
    /* left: 150px; */
    left: ${({sidebarState}) => sidebarState ? '218px' : '65px'};
  } 

  @media only screen and (max-width: 2560px) { 
    left: ${({sidebarState}) => sidebarState ? '300px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '410px' : '70px'} };
  } 
  
  @media only screen and (max-width: 2430px) { 
    left: ${({sidebarState}) => sidebarState ? '280px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '390px' : '70px'} };
  } 

  @media only screen and (max-width: 2230px) { 
    left: ${({sidebarState}) => sidebarState ? '280px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '355px' : '70px'} };
  } 

  @media only screen and (max-width: 2100px) { 
    left: ${({sidebarState}) => sidebarState ? '220px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '335px' : '70px'} };
  } 

  @media only screen and (max-width: 1875px) { 
    left: ${({sidebarState}) => sidebarState ? '180px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '300px' : '70px'} };
  } 

  @media only screen and (max-width: 1620px) { 
    left: ${({sidebarState}) => sidebarState ? '150px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '260px' : '70px'} };
  } 


  @media only screen and (max-width: 1440px) { 
    left: ${({sidebarState}) => sidebarState ? '110px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '220px' : '70px'} };
  } 

  @media only screen and (max-width: 1300px) { 
    left: ${({sidebarState}) => sidebarState ? '80px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '200px' : '70px'} };
  } 

  @media only screen and (max-width: 1230px) { 
    left: ${({sidebarState}) => sidebarState ? '80px' : '-40px'};
    &:hover{ left: ${({sidebarState}) => sidebarState ? '195px' : '70px'} };
  } 

  @media only screen and (max-width: 1200px) { 
    left: ${({sidebarState}) => sidebarState ? '65px' : '-50px'}; 
    &:hover{ left: ${({sidebarState}) => sidebarState ? '192px' : '80px'} }; 
  } 

  @media only screen and (max-width: 1050px) { 
    left: ${({sidebarState}) => sidebarState ? '50px' : '-70px'}; 
    &:hover{ left: ${({sidebarState}) => sidebarState ? '165px' : '60px'} }; 
  } 

  @media only screen and (max-width: 768px) { 
    top: calc(60px + 10%); 
    left: ${({sidebarState}) => sidebarState ? '-75px' : '60px'}; 
    &:hover{ left: ${({sidebarState}) => sidebarState ? '55px' : '190px'} }; 
  } 

  @media only screen and (max-width: 600px) { 
    top: calc(60px + 10%); 
    width: 120px; 
    left: -100px; 
    &:hover{ left: 0px; } 
  } 
` 









