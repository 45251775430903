import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { fetchProfile } from '../../../../../../url';
import { DotLoader } from '../../../../../Loader';
import collect from 'collect.js';
import { Button, Card, Col, FormGroup, Label, Form, Input } from 'reactstrap';
import {
	createDevice,
	getAllDevice,
	getAllBranch,
} from '../../../../../../url/index';
import showToast from '../../../../../../components/Toast/toast';

const DeviceCreate = (props) => {
	const { history } = props;
	const [devices, setdevices] = useState([]);
	const [branches, setbranches] = useState([]);
	const [editable, setEditable] = useState({ fetched: false, loaded: '' });

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(
				fetchProfile(localStorage.getItem('jwtProfileId'))
			);
			setEditable({
				...editable,
				loaded: result.data,
				fetched: !editable.fetched,
			});
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const res = await axios(
				getAllDevice(localStorage.getItem('jwtProfileId'))
			);
			setdevices(res.data);

			const res2 = await axios(
				getAllBranch(localStorage.getItem('jwtProfileId'))
			);
			setbranches(res2.data.branch);
		};
		fetchData();
	}, []);

	try {
		if (branches.length) {
			// Sorting Alphabetically
			let compare = (a, b) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				} else if (nameA < nameB) {
					comparison = -1;
				}
				return comparison;
			};
			branches.sort(compare);
		}
	} catch (error) {}

	const CreateToast = () => showToast('Created Successfully');
	const ErrorToast = () => showToast('Server Error');

	let errorStyle = {
		color: 'red',
		fontSize: '12px',
		marginLeft: '5px',
		textAlign: 'left',
		fontWeight: 'bold',
	};

	return (
		<Card style={{ padding: '20px 25px 15px 40px' }}>
			<section>
				{editable.fetched === false ? (
					<DotLoader />
				) : (
					<>
						<Formik
							// initialValues={{ name:'', email: '', status: true, branch: '', macAddress: '', password: Math.random().toString(36).substr(2, 5).toUpperCase()}}
							initialValues={{
								name: '',
								email: '',
								status: true,
								branch: '',
								macAddress: '',
								password: '',
							}}
							validationSchema={Yup.object().shape({
								name: Yup.string()
									.required('Required')
									.trim()
									.lowercase()
									.notOneOf(
										devices
											? devices.map((obj) => {
													if (obj.name) return obj.name.toLowerCase();
											  })
											: [],
										'Already Exist'
									),
								email: Yup.string()
									.email('Invalid Email')
									.test('email', 'Already exist', (value) => {
										return (
											collect(devices).where('email', value).all().length !== 1
										);
									}),
								password: Yup.string().required('Required').trim(),
							})}
							onSubmit={(values, { resetForm }) => {
								let createdDevice = {};
								if (values.branch) {
									createdDevice = {
										profileId: localStorage.getItem('jwtProfileId'),
										name: values.name.trim(),
										email: values.email.trim(),
										password: values.password,
										active: values.status,
										branchId: values.branch,
										macAddress: values.macAddress,
										createdAt: Date.now(),
									};
								} else {
									createdDevice = {
										profileId: localStorage.getItem('jwtProfileId'),
										name: values.name.trim(),
										email: values.email.trim(),
										password: values.password,
										active: values.status,
										macAddress: values.macAddress,
										createdAt: Date.now(),
									};
								}
								console.log({ createdDevice });

								axios
									.post(createDevice, createdDevice)
									.then((res) => CreateToast())
									.catch((error) => ErrorToast());
								resetForm();
								history.push('/app/profile/device');
							}}
						>
							{(props) => (
								<Form onSubmit={props.handleSubmit}>
									<FormGroup className='d-flex flex-wrap'>
										<Label htmlFor='name' sm={4} style={{ paddingLeft: '0px' }}>
											{' '}
											Name: <span style={{ color: 'red' }}>{` *`}</span>{' '}
										</Label>
										<Col>
											<Input
												type='text'
												name='name'
												id='name'
												placeholder='Enter device name'
												style={{
													border:
														props.errors.name && props.touched.name
															? '1px solid red'
															: null,
												}}
												value={props.values.name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.errors.name && props.touched.name && (
												<p style={errorStyle}> {props.errors.name} </p>
											)}
										</Col>
									</FormGroup>
									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='email'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											{' '}
											Email:{' '}
										</Label>
										<Col>
											<Input
												type='email'
												name='email'
												id='email'
												style={{
													border:
														props.errors.email && props.touched.email
															? '1px solid red'
															: null,
												}}
												value={props.values.email}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												placeholder='Enter a email'
											/>
											{props.errors.email && props.touched.email && (
												<p style={errorStyle}> {props.errors.email} </p>
											)}
										</Col>
									</FormGroup>
									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='password'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											Password: <span style={{ color: 'red' }}>{` *`}</span>
										</Label>
										<Col>
											<Input
												type='text'
												name='password'
												id='password'
												style={{
													border:
														props.errors.password && props.touched.password
															? '1px solid red'
															: null,
												}}
												value={props.values.password}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												placeholder='Enter a password'
											/>
											{props.errors.password && props.touched.password && (
												<p style={errorStyle}> {props.errors.password} </p>
											)}
										</Col>
									</FormGroup>
									{/* <FormGroup className="d-flex flex-wrap"> 
                        <Label htmlFor="branch" sm={4} style={{paddingLeft: '0px'}}> 
                            Branch: 
                        </Label> 
                        <Col> 
                          <Input 
                            type="select" name="branch" id="branch" value={props.values.branch} 
                            onChange={props.handleChange} onBlur={props.handleBlur}> 
                            <option value="" disabled defaultValue hidden>Select...</option> 
                            { 
                              branches.length === 0 && <option value="" disabled defaultValue>Nothing to select!</option> 
                            } 
                            { 
                              branches.map((obj) => { 
                                return ( <option value={obj._id} key={obj._id}> {obj.name} </option> ) 
                              }) 
                            } 
                          </Input> 
                          
                        </Col> 
                        
                      </FormGroup> 
                      <FormGroup className="d-flex flex-wrap"> 
                        <Label htmlFor="macAddress" sm={4} style={{paddingLeft: '0px'}}> Mac Address: </Label> 
                        <Col> 
                          <Input type="text" name="macAddress" id="macAddress" 
                            value={props.values.macAddress} onChange={props.handleChange} 
                            onBlur={props.handleBlur} placeholder="Enter a mac address"
                          /> 
                        </Col> 
                      </FormGroup>  */}
									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='status'
											className='mt-1'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											{' '}
											Active:{' '}
										</Label>
										<Col style={{ marginTop: '7px' }}>
											<Input
												type='checkbox'
												id='status'
												name='status'
												className='form-check-input mt-2'
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												checked={props.values.status}
											/>
										</Col>
									</FormGroup>

									<Button
										className='btnHover'
										type='submit'
										style={{ background: '#1B998B', borderColor: '#1B998B' }}
										size='sm'
									>
										Save
									</Button>
									<Button
										className='btnHover'
										style={{
											background: '#1B998B',
											borderColor: '#1B998B',
											margin: '10px',
										}}
										size='sm'
										onClick={() => {
											history.push('/app/profile/device');
										}}
									>
										Cancel
									</Button>
								</Form>
							)}
						</Formik>
					</>
				)}
			</section>
		</Card>
	);
};

export default withRouter(DeviceCreate);
