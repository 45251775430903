import styled from 'styled-components'

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-sizing: border-box;
  width: 100%;

  // flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 15px;
  align-content: flex-start;
`
export const Banner = styled.div`
  disply: flex;
  width: 100%;
  height: 50px;
  color: white;
  margin: 10px;
  text-align: center;
  background-color: #310D31;
  padding-top: 18px;
  font-weight: bold;
`


//====================================================

export const ProjectItem = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;  
  flex-basis: 25%;
  padding: 10px;
  height: 142px;
  cursor: pointer;
  transition: width 2s;

  @media only screen and (max-width: 768px){
    flex-basis: 50%;
  }

  @media only screen and (max-width: 425px){
    flex-basis: 100%;
  }
`

//====================================================



export const Project = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: width 2s;
  background-color: white;
  box-shadow: 2px 2px 5px grey;
  >div {
    width:100%;
    height: 50%;
    color: white;
    flex-direction: column;
    font-style: oblique;
  }
  ${ProjectItem}:hover &{
    box-shadow: 5px 5px 10px grey;
  }
  @media only screen and (max-width: 768px){
    font-size:15px;
  }
`

//====================================================


export const CreateProject = styled.div`
  position: absolute;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 40%;
  background-color: white;
  box-shadow: 2px 5px 5px #666666;
  z-index: 100;
  @media only screen and (max-width: 768px){
    width: 90%;
  }
  @media only screen and (max-width: 425px){
    width: 70%;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  margin: 5px 15px;
  border: none;
  padding: 5px 10px;
  background-color: white;
  outline: none;
  box-shadow: 0px 0px 2px #310D31;
  :focus{
    box-shadow: 0px 0px 5px 2px #808080;
  }
`
export const ProjectTypes = styled.div`
  box-sizing: border-box;
  margin: 10px 15px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start; 
  @media only screen and (max-width: 425px){
    flex-direction: column;
  }
`

export const Types = styled.div.attrs(props => ({ ...props }))`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-basis: 50%;
  height: 100px;
  border-radius: 25px;
  
  @media only screen and (max-width: 2560px){
    margin-top: 40px;
    height: 150px;
  }

  @media only screen and (max-width: 1440px){
    margin-top: 5px;
    height: 100px;
  }

  @media only screen and (max-width: 768px){
    height: 100px;
  }

  @media only screen and (max-width: 425px){
    margin: 5px;
    padding: ${props => props.selected ? '10px' : null}
  }

  background-color: ${props => props.selected ? '#310D31' : null}
  
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 15px;
`

export const CloseButton = styled.div`
  color: #C52590;
  font-size: 20px;
  line-height:18px;
  position: absolute;
  text-align: center;
  top: -10px;
  right: -10px;
  background-color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 5px -1px #586469;
  :hover{
    cursor: pointer;
    box-shadow: 1px 1px 5px 2px #586469;
  }
`

export const Button = styled.button`
  display: block;
  margin: 15px auto;
  box-sizing: border-box;
  text-decoration: none;
  color: #384363;
  border: none;
  background-color: #AEC6CF;
  height: 30px;
  padding: 5px 10px;
  text-align: center;
  color: #C42590;
  box-shadow: 1px 1px 5px -1px #586469;
  outline: none;
  :hover{
    cursor: pointer;
    background-color: #8DA2A8;
    box-shadow: 1px 1px 5px 2px #586469;
  }
`
export const Edit = styled(Button)`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  right: 15px;
  background-color: #310D31;
  color: white;
  z-index: 6;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: white;
    color: #310D31;
    border: 1px solid #310D31;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  }
`

export const Delete = styled(Button)`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  right: 15px;
  background-color: #310D31;
  color: white;
  z-index: 6;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: white;
    color: #310D31;
    border: 1px solid #310D31;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  }
`