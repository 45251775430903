import React from 'react'
import styled from 'styled-components/macro'
import { connect } from "react-redux"

import MultipleChoice from '../MultipleChoice'
import DropDown from '../dropDown'
import Dichotomy from '../dichotomy'
import ThumbsUpDown from '../thumbsUpDown'
import Checkbox from '../Checkbox'
import LikertScale from '../LikertScale'
import RatingScale from '../ratingScale'
import TextBox from '../TextBox'
import Demography from '../Demography'
import Nps from '../Nps/Nps'
//import { connect } from 'net';

const Content = styled.div`
  margin: 10px;
  box-sizing: border-box;
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: center;
  align-items: flex-start;
`

const Input = styled.textarea`
  box-sizing: border-box;
  border-color: #BDBDBD;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  resize: none;
  border-radius:4px;
  width: 100%;
  font-size: 15px;
  color:#424242;
  outline: none;
  height:30px;
  line-height:18px;
  padding: 4px 8px 4px 8px;
  overflow: hidden;
  @media screen and (max-width: 767.92px){
    font-size: 12px;
    padding: 4px 10px;
    line-height:18px;
  }
`

const QuestionCardContent = ({ item, editField, editTitle, hasError }) => {   //this component indicates the question state in store
  switch (item.type) {
    case "multipleChoice":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <MultipleChoice item={item} editField={editField} />
        </Content>
      )

    case "dropDown":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <DropDown item={item} editField={editField} />
        </Content>
      )
    case "dichotomy":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: Do you want to buy it again?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <Dichotomy item={item} />
        </Content>
      )
    case "thumbsUpDown":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: Do you like it?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <ThumbsUpDown item={item} />
        </Content>
      )
    case "checkbox":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: What are your favourite foods?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <Checkbox item={item} editField={editField} />
        </Content>
      )
    case "likertScale":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <LikertScale item={item} editField={editField} />
        </Content>
      )
    case "ratingScale":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <RatingScale ratingType="number" item={item} editField={editField} />
        </Content>
      )
    case "starRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <RatingScale ratingType="star" item={item} editField={editField} />
        </Content>
      )
    case "thumbsUpRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <RatingScale ratingType="thumbsUp" item={item} editField={editField} />
        </Content>
      )
    case "loveRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <RatingScale ratingType="love" item={item} editField={editField} />
        </Content>
      )
    case "nps":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <Nps item={item} editField={editField} />
        </Content>
      )
    case "textBox":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color:"#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: Your Name?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <TextBox item={item} editField={editField} />
        </Content>
      )
    case "demography":
      return (
        <Content>
          <Input
            css={`border:${item.title || !hasError ? '' : '1px solid red'}`}
            value={item.title}
            placeholder="Example: Your Name?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
          />
          <Demography item={item} />
        </Content>
      )
    default:
      return (
        <Content>
        </Content>
      )
  }

}

const mapStateToProps = (state) => {
  return {
    hasError: state.error.title
  }
}

export default connect(mapStateToProps, null)(QuestionCardContent)