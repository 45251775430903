import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";

const Speedometer = (props) => {

    return (
        <ReactSpeedometer
            value={props.value}
            width={200}
            height={150}
            segments={10}
            segmentColors={['#FF6B6B', '#2EC4B6']}
            niddle={false}
            needleColor="#30324F"
            paddingHorizontal={2}
            paddingVertical={2}
            ringWidth={40}
            minValue={-100}
            maxValue={100}
        />
    );
}

export default Speedometer;