import axios from 'axios'
import { fetchDeviceConfig, postDeviceResponse, fetchOpenLinkConfig } from '../../../../url'
import baseUrl from '../../../../url/index'
import {
  // HEADER_TEXT,
  CHANNEL_INFO_RECIEVE,
  FETCH,
  SET_CHANNEL_ID,
  SET_CHANNEL_TYPE,
  NEXT_QUESTION,
  NEXT_CONDITIONAL_QUESTION,
  NO_DATA,
  EDIT_VALUE,
  SUBMIT,
  SUBMIT_FAILURE,
  SUBMIT_SUCCESS,
  SET_PREVIEW,
  SET_LOADING,
  ADD_CONTACT_CART,
  START_DATE,
  END_DATE,
  // SET_PREVIOUS_QUESTION,
  PREVIOUS_QUESTION
} from './const'
import { async } from 'q'


export const setChannelType = channelType => {
  return {
    type: SET_CHANNEL_TYPE,
    channelType
  }
}

export const setChannelID = channelID => {
  return {
    type: SET_CHANNEL_ID,
    channelID
  }
}

const channelInfoFetch = (disId) => {

  // const api = {
  //   'device': fetchDeviceConfig(subChannelId),
  //   'openLink': fetchOpenLinkConfig(subChannelId)
  // }

  return async (dispatch) => {
    dispatch(fetch())
    let res
    try {
      // res = await axios.get(api[channelType],{timeout: 10000}) 
      // api/distribution/details/
      res = await axios.get(`${baseUrl}/api/distribution/details/${disId}`)

      if (res.data.Error) {
        throw new Error(res.data.Error)
      }
      if (res.data.hasOwnProperty('profileId')) {
        dispatch(channelInfoRecieve(res.data))
      } else {
        dispatch(noData())
      }

    } catch (err) {
      dispatch(noData())
    }

  }
}

const fetch = () => {
  return {
    type: FETCH,
  }
}

const channelInfoRecieve = channelInfo => {
  return {
    type: CHANNEL_INFO_RECIEVE,
    channelInfo
  }
}

// export const nextQuestion = () => {
//   return {
//     type: NEXT_QUESTION
//   }
// }

export const startDate = value => {
  return (dispatch, getState) => {
    const { currentProject, currentQuestion } = getState().form
    dispatch({
      type: START_DATE,
      projectId: currentProject,
      questionId: currentQuestion,
      value
    })
  }
}

export const nextQuestion = () => {
  return (dispatch, getState) => {
    const { currentProject, currentQuestion } = getState().form
    dispatch({
      type: END_DATE,
      projectId: currentProject,
      questionId: currentQuestion,
      value: new Date()
    })
    dispatch({ type: NEXT_QUESTION, payload: false })
  }
}

export const nextQuestionShouldSkip = () => {
  return (dispatch, getState) => {
    const { currentProject, currentQuestion } = getState().form
    dispatch({
      type: END_DATE,
      projectId: currentProject,
      questionId: currentQuestion,
      value: new Date()
    })
    dispatch({ type: NEXT_QUESTION, payload: true })
  }
}

export const previousQuestion = value => {
  return {
    type: PREVIOUS_QUESTION,
    payload: value
  }
}

export const nextConditionalQuestion = (questionId) => {
  return {
    type: NEXT_CONDITIONAL_QUESTION,
    questionId
  }
}

const noData = () => {
  return {
    type: NO_DATA
  }
}

export const updateData = (value) => {
  return (dispatch, getState) => {
    const { currentProject, currentQuestion } = getState().form
    dispatch(editValue(currentProject, currentQuestion, value))
  }
}

const editValue = (projectId, questionId, value) => {
  return {
    type: EDIT_VALUE,
    projectId,
    questionId,
    value
  }
}

export const submitData = (data, createdByCompanyID) => {
  return async (dispatch, getState) => {
    console.log(data)
    const contactCard = getState().answers.contactCard
    const distributionId = getState().answers._id
    delete contactCard['fields']
    dispatch(submit())
    // console.log(data)
    try {
      // console.log(data)
      const result = await axios.post(postDeviceResponse, { ...data, 'createdByCompanyID': createdByCompanyID, contactCard, distributionId })
      // console.log(result)
      dispatch(submitSuccess())
    } catch (err) {
      // console.log(err)
      dispatch(submitFailure())
    }
  }
}

const submit = () => {
  return {
    type: SUBMIT
  }
}

const submitSuccess = () => {
  return {
    type: SUBMIT_SUCCESS
  }
}

const submitFailure = () => {
  return {
    type: SUBMIT_FAILURE
  }
}

// const setPreview = isPreview=>{
//   return{
//     type: SET_PREVIEW,
//     isPreview
//   }
// }

export const init = (disId, userType) => {
  return async dispatch => {
    // dispatch(setChannelType(channelType))
    // dispatch(setPreview(isPreview))
    dispatch(channelInfoFetch(disId))
    // if (userType === 'admin' || userType === 'openlink')
    //   dispatch(channelInfoFetch(disId))
    // if (userType === 'device') {
    //   const res = await axios.get(`${baseUrl}/api/channels/device/individual/${disId}`)
    //   dispatch(channelInfoFetch(res.data[0].distributionId))
    // }
  }
}

//reset the form on the device channel for resubmit
export const resetForm = () => {

  const reset = (dispatch, getState) => {
    const { form: { data: channelInfo } } = getState()
    setTimeout(() => { dispatch(channelInfoRecieve(channelInfo)) }, 4000)
  }

  return reset
}

//reset the form on the device channel for resubmit on click button
export const resetFormOnClick = () => {

  const reset = (dispatch, getState) => {
    const { form: { data: channelInfo } } = getState()
    dispatch(channelInfoRecieve(channelInfo))
  }

  return reset
}

//Whether the question should be skipped or not based on its conditional preview if present
export const shouldSkip = () => {
  return (dispatch, getState) => {

    const {
      form: {
        data: {
          question: { questionOrder }
        },
        currentProject,
        currentQuestion
      },
      answers: { data }
    } = getState()
    const quesOrder = getState().form.data.question.questionBank.questionOrder
    const currentQuestionData = quesOrder[currentProject].find(ques => ques.id === currentQuestion)

    if (!currentQuestionData.previewCondition) {
      dispatch({ type: "Do Nothing!" })
      return
    }
    if (!currentQuestionData.previewCondition.status) {
      dispatch({ type: "Do Nothing!" })
    } else {
      const {
        questionId: conditionalQuestionId,
        optionIndex
      } = currentQuestionData.previewCondition

      let { options, ratings, choices, answer } = data[currentProject].find(ques => ques.id === conditionalQuestionId)
      options = options || ratings || choices
      let answerIndex = []
      if (Array.isArray(answer))
        answer.forEach(ans => {
          const index = options.indexOf(ans)
          if (index >= 0) {
            answerIndex.push(index)
          }
        })

      if (typeof (answer) === 'object') {
        for (let objKey in answer) {
          const index = options.indexOf(answer[objKey])
          if (index >= 0) {
            answerIndex.push(index)
          }
        }
      }
      // let answerIndex = []

      const found = answerIndex.includes(optionIndex)
      if (found) {
        dispatch({ type: "Do Nothing!" })
      } else {
        dispatch(nextQuestionShouldSkip())
      }
    }
  }
}

export const addContactCard = values => {
  return {
    type: ADD_CONTACT_CART,
    payload: values
  }
}

const setLoading = () => ({ type: SET_LOADING })