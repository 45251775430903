import {
  HEADER_TEXT,
  CHANNEL_INFO_RECIEVE,
  SET_CHANNEL_ID,
  SET_CHANNEL_TYPE,
  FETCH,
  NEXT_QUESTION,
  NEXT_CONDITIONAL_QUESTION,
  NO_DATA,
  PREVIOUS_QUESTION
} from '../actions/const'

const configReducer = (state = {
  isLoading: false,
  loadedData: false,
  noData: false,
  channelType: "",
  channelID: "",
  data: {},
  progressbar: true,
  redirectUrl: "",
  currentProject: "",
  currentQuestion: "",
  nextQuestion: "",
  previousQuestion: "",
  previousQuestionLists: {}
}, action) => {
  switch (action.type) {

    // case HEADER_TEXT:
    //   return {
    //     ...state,
    //     headerText: action.headerText,
    //   }

    case SET_CHANNEL_TYPE: {
      const { channelType } = action
      return {
        ...state,
        channelType
      }
    }

    case SET_CHANNEL_ID: {
      const { channelID } = action
      // console.log('id', channelID)
      return {
        ...state,
        channelID
      }
    }

    case CHANNEL_INFO_RECIEVE: {
      // console.log("dispatch")
      const firstProjectId = action.channelInfo.question.questionBank.projectOrder[0]
      const firstQuestionId = action.channelInfo.question.questionBank.questionOrder[firstProjectId][0].id
      return {
        ...state,
        isLoading: false,
        loadedData: true,
        data: action.channelInfo,
        progressbar: action.channelInfo.showCount,
        redirectUrl: action.channelInfo.redirectUrl,
        currentProject: firstProjectId,
        currentQuestion: firstQuestionId,
        nextQuestion: "NEXT"
      }
    }

    case NEXT_QUESTION: {
      const { currentProject, currentQuestion, nextQuestion, previousQuestion } = state
      const { projectOrder, questionOrder } = state.data.question.questionBank
      let newCurrentProject, newCurrentQuestion, newNextQuestion
      let previousQuestionLists = state.previousQuestionLists
      let prevQues;
      const projectIndex = projectOrder.indexOf(currentProject)
      const questionIndex = questionOrder[currentProject].findIndex(
        question => question.id === currentQuestion)
      if (nextQuestion === "NEXT") {
        if (questionIndex < questionOrder[currentProject].length - 1) {
          newCurrentProject = currentProject
          newCurrentQuestion = questionOrder[currentProject][questionIndex + 1].id
          newNextQuestion = "NEXT"
        } else {
          if (projectIndex !== projectOrder.length - 1) {
            newCurrentProject = projectOrder[projectIndex + 1]
            newCurrentQuestion = questionOrder[newCurrentProject][0].id
            newNextQuestion = "NEXT"
          } else {
            newCurrentProject = "END"
            newCurrentQuestion = "END"
            newNextQuestion = "END"
          }
        }
      } else if (nextQuestion === "END") {
        if (projectIndex !== projectOrder.length - 1) {
          newCurrentProject = projectOrder[projectIndex + 1]
          newCurrentQuestion = questionOrder[newCurrentProject][0].id
          newNextQuestion = "NEXT"
        } else {
          newCurrentProject = "END"
          newCurrentQuestion = "END"
          newNextQuestion = "END"
        }
      } else {
        newCurrentProject = currentProject
        newCurrentQuestion = nextQuestion
        newNextQuestion = "NEXT"
      }

      if (action.payload === false) {
        previousQuestionLists[newCurrentQuestion] = currentQuestion
        prevQues = currentQuestion
      }
      else {
        previousQuestionLists[newCurrentQuestion] = previousQuestion
        prevQues = previousQuestion
      }

      return {
        ...state,
        currentProject: newCurrentProject,
        previousQuestion: prevQues,
        currentQuestion: newCurrentQuestion,
        previousQuestionLists: previousQuestionLists,
        nextQuestion: newNextQuestion
      }
    }

    case PREVIOUS_QUESTION: {
      return {
        ...state,
        currentQuestion: action.payload,
        nextQuestion: "NEXT"
      }
    }

    case NEXT_CONDITIONAL_QUESTION: {

      const { questionId } = action

      return {
        ...state,
        nextQuestion: questionId
      }
    }

    case FETCH: {
      return {
        ...state,
        isLoading: true
      }
    }

    case NO_DATA: {
      return {
        ...state,
        isLoading: false,
        loadedData: true,
        noData: true
      }
    }

    // cas

    default:
      return state
  }
}

export default configReducer