import axios from 'axios'
import {
    GET_ALL_OBJECTIVES,
    GET_SINGLE_OBJECTIVE,
    CREATE_OBJECTIVE,
    BACK_BUTTON_IN_DETAIL_PAGE,
    DELETE_OBJECTIVE,
    UPDATE_OBJECTIVCE
} from './actionTypes'
import baseUrl from '../../../../url'

export const getAllObjectives = (ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/objectives/list/all/?page=1&&limit=500`)
        .then(response => {
            const res = response.data.objectives.map(r => {
                let createdAT = r.createdAt.slice(0, 10)
                return { createdAT, ...r }
            })
            dispatch({ type: GET_ALL_OBJECTIVES, payload: res })
        })
        .catch(error => ErrorToast())

}


export const getObjectiveDetail = (id, ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/objectives/${id}`)
        .then(res => {
            dispatch({ type: GET_SINGLE_OBJECTIVE, payload: res.data })
        })
        .catch(err => ErrorToast())
}

export const createNewObjective = (values, CreateToast, ErrorToast) => dispatch => {

    axios.post(`${baseUrl}/api/objectives`, values)
        .then(res => {
            // console.log(res)
            dispatch({ type: CREATE_OBJECTIVE, payload: res.data })
            CreateToast()
        })
        .catch(err => ErrorToast())

}


export const deleteObjectiveById = (state) => dispatch => {
    dispatch({ type: DELETE_OBJECTIVE, payload: state })
}

export const updateObjectiveById = (id, value, UpdateToast, ErrorToast) => dispatch => {

    axios.patch(`${baseUrl}/api/objectives/${id}`, value)
        .then(res => {
            dispatch({ type: UPDATE_OBJECTIVCE, payload: res.data });
            UpdateToast();
        })
        .catch(err => ErrorToast())

}

export const backButtonFunction = () => dispatch => { dispatch({ type: BACK_BUTTON_IN_DETAIL_PAGE }) }


