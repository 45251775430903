import styled from 'styled-components'
import {Link,NavLink} from 'react-router-dom'

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center; 
  align-items: center;
  margin: 20px;
  background-color: #white;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 0 5px 4px #E6E6E6;

  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
  }

`
export const Banner = styled.div`
  disply: flex;
  width: 100%;
  height: 50px;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  background-color: #310D31;
  padding-top: 18px;
  font-weight: bold;
`

export const UploadContainer = styled.div`
  overflow: hidden;
  position: relative;
  align-self: center;
  width: 100px;
  height: 25px;
  box-shadow: 1px 1px 5px #CCCCCC;
  :hover{
    box-shadow: 2px 2px 5px #BFBFBF;
  }
`

export const Upload = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 5;
`

export const UploadButton = styled.div`
  background-color: #310D31;
  position: absolute;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${Upload}:hover & {
    background-color: #C52590;
  }
`


export const Input = styled.input.attrs(props=>({
  ...props
}))`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 30px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 3px 1px #1e051e;
  
  :placeholder {
    color: #310D31;
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  flex-direction: row;
  text-align: center;
  padding:10px 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: black;
  font-size: 15px;
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`

export const Button = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  margin: 20px auto;
  color: white;
  border: none;
  background-color: #310D31;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 1;
  :hover{
    background-color: #C52590;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`