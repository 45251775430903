// import rules from './permission'

const check = (feature, action) => {

    const rules = JSON.parse(localStorage.getItem('roleDetails'));
    const permissions = localStorage.getItem('jwtType')
    // console.log('permissions', permissions)
    // console.log('rules', rules)
    if (!permissions) {
        return false;
    }

    if (rules[feature][action]) {
        return true;
    }

    return false;

};

const Can = props => {
    return check(props.feature, props.perform)
        ? props.yes()
        : props.no();
}

Can.defaultProps = {
    yes: () => null,
    no: () => null
};

export default Can;