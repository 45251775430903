import React from 'react';
import WordCloudJS from 'wordcloud';

const colors = [
  '#00D084',
  '#EB144C',
  '#FCB900',
  '#FF6900',
  '#2196F3',
  '#FA28FF',
  '#9900EF',
];

const getWordList = (answer) => {
  const allWords = answer
    .map((item) => item.text.toLowerCase())
    .join(' ')
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s+/g, ' ')
    .split(' ');

  const words = {};

  for (const word of allWords) {
    if (!words[word]) {
      words[word] = 1;
    } else {
      words[word] += 1;
    }
  }

  return Object.entries(words);
};

const WordCloud = ({ answer }) => {
  React.useEffect(() => {
    const canvas = document.getElementById('word-cloud');
    const container = document.getElementById('word-cloud-container');

    canvas.width = container.offsetWidth;
    canvas.height = container.offsetHeight;

    WordCloudJS(canvas, {
      list: getWordList(answer),
      weightFactor: 20,
      shuffle: false,
      color: (word, weight) => {
        const indexOfWord = getWordList(answer).findIndex(
          (item) => item[0] === word
        );
        if (indexOfWord >= colors.length) {
          return colors[indexOfWord % colors.length];
        } else {
          return colors[indexOfWord];
        }
      },
      backgroundColor: '#ffffff',
      rotateRatio: 0,
      drawOutOfBound: false,
      shrinkToFit: true,
    });

    return () => {
      WordCloudJS.stop();
    };
  }, [answer]);

  return (
    <div className='row h-100 align-items-center'>
      <div className='col-12'>
        <div
          style={{
            width: '100%',
            height: '300px',
          }}
          id='word-cloud-container'
        >
          <canvas id='word-cloud' />
        </div>
      </div>
    </div>
  );
};

export default WordCloud;
