import axios from 'axios';
import React from 'react';
import { toast } from 'react-toastify';
import { css } from 'glamor';
import baseUrl, { fetchDevice } from '../../../../url/index';
import {
  SET_NAME,
  SET_ACTIVE,
  SET_STATUS,
  PROFILE_ID,
  SELECT_CHANNEL,
  TOGGLE_VIEW,
  FETCH,
  // SUB_CHANNEL_LIST_RECEIVE,
  // SELECT_SUB_CHANNEL,
  SELECT_PROJECT,
  PROJECT_RECEIVE,
  QUESTION_RECIEVE,
  TOGGLE_QUESTION,
  TOGGLE_REQUIRED,
  TOGGLE_CONDITIONAL,
  SORT_QUESTION,
  SORT_PROJECTS,
  REMOVE_QUESTION,
  ADD_CARD,
  REMOVE_CARD,
  EDIT_HEADER,
  ADD_IMAGE,
  CHANGE_CONTACT_CARD_OPTIONS,
  SET_QUESTION_ID_FOR_CONDITION,
  SAVE_CONFIGURATION,
  CONFIGURATION_SAVED,
  RESET,
  TOGGLE_CONDITIONAL_PREVIEW,
  SET_QUESTION_FOR_CONDITIONAL_PREVIEW,
  SET_OPTION_FOR_CONDITIONAL_PREVIEW,
  PUSH_CHANNEL_LIST,
  DEVICE_LIST,
  SET_DEVICE_STATUS,
  SET_DIST_DEVICE,
  INIT_OPEN_LINK,
  SET_OPEN_LINK,
  UPDATE_OPEN_LINK,
  SET_OPEN_LINK_IN_DIST,
  INIT_QR_CODE,
  SET_QR_CODE,
  SET_GEN_QR_CODE,
  SET_QR_CODE_IN_DIST,
  EDIT_DISTRIBUTION_QUESTION,
  EDIT_DISTRIBUTION_CONFIG,
  SET_ACTIVE_CHANNEL,
  SET_PROGRESSBAR,
  SET_REDIRECT_URL,
  CREATE_PROJECT_FOR_SURVEY,
  SURVEY_PROJECT_STEP,
  SURVEY_CONFIG_STEP,
  SURVEY_PREVIEW_STEP,
  SURVEY_CHANNEL_STEP,
  SET_SURVEY_DISTRIBUTION,
  CHANGE_CONDITIONAL_PREVIEW,
  SHOW_TOAST,
  HIDE_TOAST,
  RESET_STORE,
  ADD_LOGO,
  RESTORE_SELECTED_QUESTION_AND_ORDER,
  SURVEY_QUESTION_SAVE_MESSAGE_TRUE,
  SURVEY_QUESTION_SAVE_MESSAGE_FALSE,
  ADD_LOGO_LOCAL,
} from './const';
import { async } from 'q';

const channelList = [
  { channel: 'device', subChannel: true },
  { channel: 'openLink', subChannel: true },
];

// Set distribution name
export const setName = (name) => {
  return {
    type: SET_NAME,
    name,
  };
};

// export const setActive = active => {
//   return {
//     type: SET_ACTIVE,
//     active
//   }
// }

export const setActive = (active, status, disId) => {
  if (status === '') {
    return {
      type: SET_ACTIVE,
      active,
    };
  }
  return (dispatch) => {
    axios
      .put(`${baseUrl}/api/distribution/update/${disId}`, { active })
      .then((response) => {
        // alert("The file is successfully uploaded");
        // console.log(response.data)
        dispatch({ type: SET_ACTIVE, active });
      })
      .catch((error) => {});
  };
};

export const setStatus = (status) => {
  return {
    type: SET_STATUS,
    status,
  };
};

//To Select a channel
const selectChannel = (channel) => {
  return {
    type: SELECT_CHANNEL,
    channel,
  };
};

//To toogle hide/show top group of dropdown
export const toggleView = () => {
  return {
    type: TOGGLE_VIEW,
  };
};

/*The following function is being used as a middleware. Redux thunk library helps us with adding
middlewares to redux. All actions are supposed to return an object but when used as middlewares
they return a function. The returned function takes the dispatch function of the store as argument, 
that dispatches actions, and then allows you to dispatch multiple actions or asynchronous actions
accordingly.*/
// export const selectAndFetch = (channel, companyId) => {
//   return dispatch => {
//     dispatch(selectChannel(channel))

//     fetchOrNot(dispatch, channel, companyId)
//   }
// }
//To fetch list of sub channel data if the channel has sub channels
// const fetchOrNot = (dispatch, channel, companyId) => {

//   const {channel: channelId, subChannel} = channelList.find(element => element.channel === channel)
//   // console.log(companyId)
//   if (subChannel) {
//     dispatch(fetch('subChannel'))

//     let url
//     switch(channelId){
//       case 'device':
//         url = `${baseUrl}/api/channels/device/list/${companyId}`
//         break
//       case 'openLink':
//         url = `${baseUrl}/api/channels/openlink/list/${companyId}`
//       default:
//     }

//     axios.get(url)
//       .then(
//         res => {
//           dispatch(subChannelReceive(res.data))
//         },
//         err => {
//           dispatch(subChannelReceive([]))
//         }
//       )
//   }
// }
//To initialize sub channel list fetch
const fetch = (what) => {
  return {
    type: FETCH,
    what,
  };
};
//To receive data fetched for sub channels
// const subChannelReceive = (subChannelList) => {
//   return {
//     type: SUB_CHANNEL_LIST_RECEIVE,
//     subChannelList
//   }
// }

//To select a subchannel
// export const selectSubChannel = subChannel => {
//   return {
//     type: SELECT_SUB_CHANNEL,
//     subChannel
//   }
// }

// fetch profile id

export const setProfileIdInState = (profileId) => {
  return {
    type: PROFILE_ID,
    profileId: profileId,
  };
};

// export const fetchProfileId = jwtId => {
//   return dispatch => {
//     axios.get(`${baseUrl}/api/profile/${jwtId}`)
//       .then(function (response) {
//         const id = response.data;
//         // console.log(response.data);
//         dispatch(profileId(id))
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   }
// }

// const profileId = id => {
//   return {
//     type: PROFILE_ID,
//     profileId: id
//   }
// }

//Shafiul Code=================================

// to fetch list of project
export const projectFetch = (companyId) => {
  return (dispatch) => {
    dispatch(fetch('project'));

    axios
      .get(`${baseUrl}/api/project/profile/${companyId}/?limit=500&page=1`)
      .then(
        (res) => {
          dispatch(projectRecieve(res.data.projectList));
        },
        (err) => {
          dispatch(projectRecieve([]));
        }
      );
  };
};
//reciving data from project fetch
const projectRecieve = (projectList) => {
  return {
    type: PROJECT_RECEIVE,
    projectList,
  };
};

//To Select a project
export const selectProject = (project) => {
  return {
    type: SELECT_PROJECT,
    project,
  };
};

//asynchronous call for project question fetching
export const selectProjectAndFetch = (project) => {
  return (dispatch) => {
    dispatch(selectProject(project));
    dispatch(fetch('question'));

    axios.get(`${baseUrl}/api/project/${project}`).then(
      (res) => {
        if (res.data.question.length === 0) {
          dispatch(questionRecieve([]));
        } else {
          // console.log(res.data[0].question)
          const questionList = res.data.question;
          dispatch(questionRecieve(questionList));
        }
      },
      (err) => {
        // console.log(err)
        dispatch(questionRecieve([]));
      }
    );
  };
};

export const restoreSelectedQuestionAndOrder = () => {
  return {
    type: RESTORE_SELECTED_QUESTION_AND_ORDER,
  };
};

//Recieve list of questions for a project
const questionRecieve = (questionList) => {
  return {
    type: QUESTION_RECIEVE,
    questionList,
  };
};

//Toggle question to add or remove to the subChannel or Channel
export const toggleQuestion = (question, projectId) => {
  return {
    type: TOGGLE_QUESTION,
    question,
    projectId,
  };
};

//Toggle if the question is required or not
export const toggleRequired = (projectId, questionId) => {
  // console.log(TOGGLE_REQUIRED)
  return {
    type: TOGGLE_REQUIRED,
    projectId,
    questionId,
  };
};

//Toogle if the question is conditional or not
export const toggleConditional = (projectId, questionId) => {
  // console.log(TOGGLE_CONDITIONAL)
  return {
    type: TOGGLE_CONDITIONAL,
    projectId,
    questionId,
  };
};

export const toggleConditionalPreview = (projectId, questionId) => {
  return {
    type: TOGGLE_CONDITIONAL_PREVIEW,
    projectId,
    questionId,
  };
};

//Set jump Question id for a conditional question
export const setQuestionIdForCondition = (
  projectId,
  questionId,
  optionIndex,
  destinationQuestionId
) => {
  return {
    type: SET_QUESTION_ID_FOR_CONDITION,
    projectId,
    questionId,
    optionIndex,
    destinationQuestionId,
  };
};

//Set QuestionId for conditional Preview
export const setQuestionForConditionalPreview = (
  projectId,
  questionId,
  conditionalId,
  optionsList
) => {
  return {
    type: SET_QUESTION_FOR_CONDITIONAL_PREVIEW,
    projectId,
    questionId,
    conditionalId,
    optionsList,
  };
};

//Set option for conditional preview
export const setOptionForConditionalPreview = (
  projectId,
  questionId,
  conditionalId
) => {
  return {
    type: SET_OPTION_FOR_CONDITIONAL_PREVIEW,
    projectId,
    questionId,
    conditionalId,
  };
};

export const setProgressbar = (value) => {
  return {
    type: SET_PROGRESSBAR,
    payload: value,
  };
};

export const setRedirectUrl = (value) => {
  return {
    type: SET_REDIRECT_URL,
    payload: value,
  };
};
//Sort Questions with drag and drop inside individual projects
export const sortQuestion = (destination, source, questionId) => {
  return {
    type: SORT_QUESTION,
    destination,
    source,
    questionId,
  };
};

//Sort projects with drag and drop inside the project list
export const sortProjects = (destination, source, projectId) => {
  return {
    type: SORT_PROJECTS,
    destination,
    source,
    projectId,
  };
};

//Remove a question from a project
export const removeQuestion = (projectId, questionId) => {
  // console.log(projectId, questionId)
  return {
    type: REMOVE_QUESTION,
    projectId,
    questionId,
  };
};

//Add a card to the project list from the side bar using drag and drop
export const addCard = (destination, draggableId) => {
  return {
    type: ADD_CARD,
    destination,
    draggableId,
  };
};

//Remove a card from the project list
export const removeCard = (id) => {
  return {
    type: REMOVE_CARD,
    id,
  };
};

//Edit header content
export const editHeader = (data) => {
  return {
    type: EDIT_HEADER,
    data,
  };
};

//Add image to header
export const addImage = (data) => {
  // console.log(file)
  return {
    type: ADD_IMAGE,
    data,
  };
};

// add logo
export const addLogo = (data) => {
  // console.log(data)
  return {
    type: ADD_LOGO,
    data,
  };
};

export const addLogoLocal = (data) => {
  return {
    type: ADD_LOGO_LOCAL,
    payload: data,
  };
};

//Options for contactCard
export const changeCardOptions = (options) => {
  return {
    type: CHANGE_CONTACT_CARD_OPTIONS,
    options,
  };
};

export const conditionalPreview = (value) => {
  return {
    type: CHANGE_CONDITIONAL_PREVIEW,
    payload: value,
  };
};

// push distribution save
export const pushConfiguration = (data, userId) => {
  return async (dispatch, getState) => {
    let url;
    const name = getState().question.name;
    const active = getState().question.active;
    const header = getState().question.header.text;
    const logo = getState().question.logo;
    const showCount = getState().question.progressbar;
    const redirectUrl = getState().question.redirectUrl;
    const projectId = getState().survey.projectId;
    const status = 'submitted';

    const newData = {
      name: name,
      profileId: userId,
      active: active,
      header: header,
      status: status,
      showCount: showCount,
      redirectUrl: redirectUrl,
      question: data,
    };

    let checkConditionalPreview = data.questionBank.questionOrder;
    let cP = false;
    for (let key in checkConditionalPreview) {
      let lists = checkConditionalPreview[key].map((item) => {
        if (item.previewCondition.status === true) {
          if (
            item.previewCondition.optionIndex === null ||
            item.previewCondition.questionId === ''
          ) {
            // console.log('true', item.previewCondition.questionId)
            cP = true;
          }
        }

        return item;
      });
    }
    if (cP) {
      dispatch({ type: CHANGE_CONDITIONAL_PREVIEW, payload: true });
      return;
    }

    dispatch(saveConfiguration());

    url = `${baseUrl}/api/distribution/addNew`;
    // axios.post(url, newData)
    //   .then(
    //     res => {
    //       const resData = res.data
    //       dispatch(configurationSaved(resData))
    //       dispatch(setSurveyPreviewStep())
    //     },
    //     err => {
    //       console.log(err.response)
    //     }
    //   ).finally(

    //   )

    const res = await axios.post(url, newData);
    dispatch(configurationSaved(res.data));
    dispatch(setSurveyPreviewStep());

    const projectResponse = await axios.patch(
      `${baseUrl}/api/project/${projectId}`,
      {
        distributionId: res.data._id,
        profileId: localStorage.getItem('jwtProfileId'),
      }
    );

    dispatch({
      type: SET_SURVEY_DISTRIBUTION,
      payload: projectResponse.data.distributionId,
    });

    if (typeof logo === 'object') {
      axios
        .patch(
          `${baseUrl}/api/distribution/upload/logo/${res.data._id}`,
          logo,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )
        .then((response) => {
          // props.addImage(response.data.logo)
          dispatch(addImage(response.data.logo));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
};

// update distribution submit
export const updateConfiguration = (data, userId, disId, history, mode) => {
  return (dispatch, getState) => {
    let url;
    const name = getState().question.name;
    const active = getState().question.active;
    const header = getState().question.header.text;
    const logo = getState().question.header.image;
    const showCount = getState().question.progressbar;
    const redirectUrl = getState().question.redirectUrl;
    const status = 'submitted';
    const newData = {
      name: name,
      profileId: userId,
      active: active,
      header: header,
      logo: logo,
      status: status,
      showCount: showCount,
      redirectUrl: redirectUrl,
      question: data,
    };

    let checkConditionalPreview = data.questionBank.questionOrder;
    let cP = false;
    for (let key in checkConditionalPreview) {
      let lists = checkConditionalPreview[key].map((item) => {
        if (item.previewCondition.status === true) {
          if (
            item.previewCondition.optionIndex === null ||
            item.previewCondition.questionId === ''
          ) {
            // console.log('true', item.previewCondition.questionId)
            cP = true;
          }
        }

        return item;
      });
    }
    if (cP) {
      dispatch({ type: CHANGE_CONDITIONAL_PREVIEW, payload: true });
      return;
    }

    dispatch(saveConfiguration());

    url = `${baseUrl}/api/distribution/update/${disId}`;
    axios
      .put(url, newData)
      .then(
        (res) => {
          const resData = res.data;
          dispatch(configurationSaved(resData));
          // dispatch(setSurveyChannelStep())
          // if (mode === 'create')
          //   history.push('/app/survey/create/channel')
          // if (mode === 'edit')
          //   history.push(`/app/survey/edit/channel/${disId}`)
        },
        (err) => {
          console.log(err.response);
        }
      )
      .finally();
  };
};

// distributed distribution distribute
// export const distributeConfiguration = (data, activeDevice, userId, disId) => {

//   return (dispatch, getState) => {
//     let url
//     const name = getState().question.name;
//     const active = getState().question.active;
//     const showCount = getState().question.progressbar;
//     const status = 'distributed';
//     const newData = {
//       "name": name,
//       "profileId": userId,
//       "active": active,
//       "status": status,
//       "showCount": showCount,
//       "question": data,
//       "activeChannels": {
//         device: activeDevice
//       }
//     }

//     dispatch(saveConfiguration())

//     url = `${baseUrl}/api/distribution/update/${disId}`
//     axios.put(url, newData)
//       .then(
//         res => {
//           const resData = res.data
//           dispatch(configurationSaved(resData))
//         },
//         err => {
//           console.log(err.response)
//         }
//       ).finally(

//       )
//   }
// }

export const distributeConfiguration = (data, userId, disId, history, mode) => {
  return (dispatch, getState) => {
    let url;
    const name = getState().question.name;
    const active = getState().question.active;
    const header = getState().question.header.text;
    const logo = getState().question.header.image;
    const showCount = getState().question.progressbar;
    const redirectUrl = getState().question.redirectUrl;
    const status = 'distributed';
    const newData = {
      name: name,
      profileId: userId,
      header: header,
      logo: logo,
      active: active,
      status: status,
      showCount: showCount,
      redirectUrl: redirectUrl,
      question: data,
    };

    // dispatch(saveConfiguration())
    let checkConditionalPreview = data.questionBank.questionOrder;
    let cP = false;
    for (let key in checkConditionalPreview) {
      let lists = checkConditionalPreview[key].map((item) => {
        if (item.previewCondition.status === true) {
          if (
            item.previewCondition.optionIndex === null ||
            item.previewCondition.questionId === ''
          ) {
            // console.log('true', item.previewCondition.questionId)
            cP = true;
          }
        }

        return item;
      });
    }
    if (cP) {
      dispatch({ type: CHANGE_CONDITIONAL_PREVIEW, payload: true });
      return;
    }

    dispatch(saveConfiguration());

    const projectId = getState().survey.projectId;

    url = `${baseUrl}/api/distribution/update/${disId}`;
    axios
      .put(url, newData)
      .then(
        (res) => {
          const resData = res.data;
          dispatch(configurationSaved(resData));
          dispatch(setSurveyChannelStep());
          if (mode === 'create')
            history.push(`/app/survey/create/channel/${projectId}`);
          if (mode === 'edit')
            history.push(`/app/survey/edit/channel/${projectId}`);
        },
        (err) => {
          console.log(err.response);
        }
      )
      .finally();
  };
};

export const changeConfigurationStatus = (disId) => {
  return (dispatch) => {
    // let url
    // const projectId = getState().survey.projectId

    let url = `${baseUrl}/api/distribution/update/${disId}`;
    axios
      .put(url, { status: 'submitted' })
      .then(
        (res) => {
          const resData = res.data;
          dispatch(configurationSaved(resData));
          dispatch(surveyQuestionSaveMessageFalse());
          // dispatch(setSurveyChannelStep())
          // if (mode === 'create')
          //   history.push(`/app/survey/create/channel/${projectId}`)
          // if (mode === 'edit')
          //   history.push(`/app/survey/edit/channel/${projectId}`)
        },
        (err) => {
          console.log(err.response);
        }
      )
      .finally();
  };
};
// surveyQuestionSaveMessage
const saveConfiguration = () => {
  return {
    type: SAVE_CONFIGURATION,
  };
};

const configurationSaved = (resData) => {
  return {
    type: CONFIGURATION_SAVED,
    resData,
  };
};

//Reset the current selections
export const reset = () => {
  return {
    type: RESET,
  };
};

export const fetchChannels = (userId) => {
  let url = `${baseUrl}/api/profile/channels/${userId}`;
  return async (dispatch) => {
    const res = await axios.get(url);
    Promise.all(
      res.data.pushChannelList.map(async (channel) => {
        const c = await axios.get(`${baseUrl}/api/channel/details/${channel}`);
        // console.log('cgrwtreth', c)

        try {
          if (c.data.length > 0) {
            dispatch(pushChannelList(c.data[0]));
          }
        } catch (error) {
          console.log(error);
        }
      })
    );
  };
};

const pushChannelList = (channel) => {
  return {
    type: PUSH_CHANNEL_LIST,
    channel,
  };
};

export const fetchDeviceLists = (userId) => {
  let url = `${baseUrl}/api/channels/device/list/${userId}`;
  return async (dispatch) => {
    const res = await axios.get(url);
    const device = res.data;
    dispatch({ type: DEVICE_LIST, device });
  };
};

export const setDeviceActive = (value, index) => {
  const status = value === true ? 'Active' : 'Inactive';
  return {
    type: SET_DEVICE_STATUS,
    payload: {
      status,
      index,
    },
  };
};

// export const setDistDevice = (deviceLists, disId) => {

//   return (dispatch, getState) => {
//     Promise.all(
//       deviceLists.map(async channel => {
//         if (channel.status === 'Active') {
//           await axios.patch(`${baseUrl}/api/channels/device/update/${channel._id}`, {
//             "name": channel.name,
//             "password": channel.password,
//             "distributionId": disId
//           })
//           dispatch({ type: SET_DIST_DEVICE })
//         }
//       })
//     )
//   }

// }

export const setDistDevice = (deviceLists) => {
  return (dispatch, getState) => {
    axios
      .patch(`${baseUrl}/api/channels/device`, deviceLists)
      .then((response) => {
        // console.log(response)
        dispatch({ type: SET_DIST_DEVICE });
        toast.success(
          <span>
            <i style={{ color: '#1B998B' }} className='fa fa-thumbs-up'></i>{' '}
            {' Distribution Set To Selected Device.'}
          </span>,
          {
            className: css({
              color: '#9a9a9a !important',
              backgroundColor: '#fff !important',
            }),
          }
        );
      });
  };
};

export const initOpenLink = (openlink) => {
  return {
    type: INIT_OPEN_LINK,
    payload: openlink,
  };
};

export const setOpenLink = (openlink) => {
  return async (dispatch) => {
    const res = await axios.post(`${baseUrl}/api/channels/openlink`, openlink);
    dispatch({ type: SET_OPEN_LINK, payload: res.data });
    toast.success(
      <span>
        <i style={{ color: '#1B998B' }} className='fa fa-thumbs-up'></i>{' '}
        {'Distribution Set To Open Link.'}
      </span>,
      {
        className: css({
          color: '#9a9a9a !important',
          backgroundColor: '#fff !important',
        }),
      }
    );
  };
};

export const updateOpenLink = (id, obj) => {
  return async (dispatch) => {
    const response = await axios.patch(
      `${baseUrl}/api/channels/openlink/${id}`,
      obj
    );
    dispatch({ type: UPDATE_OPEN_LINK, payload: response.data });
  };
};

export const setOpenLinkInDistribution = (id, obj) => {
  return async (dispatch) => {
    const response = await axios.patch(`${baseUrl}/api/distribution/${id}`, {
      activeChannels: {
        openlink: obj,
      },
    });
    dispatch({ type: SET_OPEN_LINK_IN_DIST });
    // console.log('res open link ', response.data)
  };
};

export const initQrCode = (qrCode) => {
  return {
    type: INIT_QR_CODE,
    payload: qrCode,
  };
};

export const setQRCode = (qrCode) => {
  return async (dispatch) => {
    const response = await axios.post(`${baseUrl}/api/channels/qrcode`, qrCode);
    dispatch({ type: SET_QR_CODE, payload: response.data });
    toast.success(
      <span>
        <i style={{ color: '#1B998B' }} className='fa fa-thumbs-up'></i>{' '}
        {'Distribution Set To QR Code.'}
      </span>,
      {
        className: css({
          color: '#9a9a9a !important',
          backgroundColor: '#fff !important',
        }),
      }
    );
  };
};

export const setGenQrCode = (id, obj) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  return (dispatch) => {
    axios
      .patch(`${baseUrl}/api/channels/qrcode/${id}`, obj, config)
      .then((response) => {
        // alert("The file is successfully uploaded");
        dispatch({ type: SET_GEN_QR_CODE, payload: response.data });
      })
      .catch((error) => {});
  };
};

export const setQrCodeInDistribution = (id, obj) => {
  return async (dispatch) => {
    const response = await axios.patch(`${baseUrl}/api/distribution/${id}`, {
      activeChannels: {
        qrcode: obj,
      },
    });
    dispatch({ type: SET_QR_CODE_IN_DIST });
    // console.log(response.data)
  };
};

export const editDistributionQuestion = (obj, disid) => {
  return {
    type: EDIT_DISTRIBUTION_QUESTION,
    payload: { ...obj, disid },
  };
};

export const editDistributionConfig = (obj) => {
  return {
    type: EDIT_DISTRIBUTION_CONFIG,
    payload: obj,
  };
};

export const setActiveChannel = (obj) => {
  return {
    type: SET_ACTIVE_CHANNEL,
    payload: obj,
  };
};

export const createProjectForSurvey = (obj, history) => (dispatch) => {
  axios.post(`${baseUrl}/api/project/post`, obj).then((response) => {
    if (
      response.data.hasOwnProperty('isValid') &&
      response.data.isValid === false
    ) {
      dispatch(showToast(response.data.message));
      setTimeout(() => {
        dispatch(hideToast());
      }, 2000);
    } else {
      dispatch({ type: CREATE_PROJECT_FOR_SURVEY, payload: response.data });
      dispatch({ type: SET_NAME, name: response.data.name });
      dispatch({ type: SELECT_PROJECT, project: response.data._id });
      dispatch(setSurveyProjectStep());
      // history.push('/app/survey/question')
      history.push({
        pathname: `/app/survey/question/${response.data._id}`,
        state: { reload: false },
      });
    }
  });
};

export const createProjectForSurveyOnReload = (data) => {
  // return {
  //   type: CREATE_PROJECT_FOR_SURVEY,
  //   payload: data
  // }
  return (dispatch) => {
    dispatch({ type: CREATE_PROJECT_FOR_SURVEY, payload: data });
    dispatch({ type: SET_NAME, name: data.name });
    dispatch({ type: SELECT_PROJECT, project: data._id });
  };
};

export const setSurveyProjectStep = () => {
  return {
    type: SURVEY_PROJECT_STEP,
  };
};

export const setSurveyConfigStep = () => {
  return {
    type: SURVEY_CONFIG_STEP,
  };
};

export const setSurveyPreviewStep = () => {
  return {
    type: SURVEY_PREVIEW_STEP,
  };
};

export const setSurveyChannelStep = () => {
  return {
    type: SURVEY_CHANNEL_STEP,
  };
};

export const showToast = (message) => {
  return {
    type: SHOW_TOAST,
    payload: {
      message,
    },
  };
};

export const hideToast = () => {
  return {
    type: HIDE_TOAST,
  };
};

export const resetStore = () => {
  return {
    type: RESET_STORE,
  };
};

export const deleteSurvey =
  (surveyId, history, DeleteToast, DependencyToast) => (dispatch) => {
    axios.delete(`${baseUrl}/api/project/${surveyId}`).then((response) => {
      console.log(response);
      if (response.data.isDeletable) {
        DeleteToast();
        history.push('/app/survey');
      } else {
        DependencyToast();
      }
    });
  };

export const surveyQuestionSaveMessageTrue = () => {
  return { type: SURVEY_QUESTION_SAVE_MESSAGE_TRUE, payload: true };
};

export const surveyQuestionSaveMessageFalse = () => {
  return { type: SURVEY_QUESTION_SAVE_MESSAGE_FALSE, payload: false };
};
