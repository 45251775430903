import React from 'react'
import { connect } from 'react-redux'

import Modal from '../../../../components/Modal'
import Form from '../../../Form'

import { channelTypeIdSelector } from '../../redux/selectors'

import { CloseButton, Content } from './style'

const Preview = props => {

  const { channelType, subChannelId, disId, header } = props

  return (
    <Modal>
      <Content>
        {!props.match.params.disid && <CloseButton to="/app/distribution/create/sortandpreview"><span>x</span></CloseButton>}
        {props.match.params.disid && <CloseButton to={`/app/distribution/edit/sortandpreview/${props.match.params.disid}`}><span>x</span></CloseButton>}
        <Form isPreview={true} userType='admin' disId={disId} header={header} channelType={channelType} subChannelId={subChannelId} />
      </Content>
    </Modal>
  )
}

const mapStateToProps = state => {
  const { channelType, subChannelId } = channelTypeIdSelector(state)
  return {
    channelType,
    subChannelId,
    disId: state.question.distributionId,
    header: state.question.header.text
  }
}

export default connect(mapStateToProps)(Preview)