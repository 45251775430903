import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik, yupToFormErrors } from 'formik'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; //need to import other styling wont effect
import { FetchAllTargetAudience, FetchAllCampaignCategory, FetchAllCampaign, AddNewCampaign} from '../../../url/prmUrl';
import Alert from '../ShowAlert'
import { Button, Input, Label, Body, Error, Aside,TextArea, Modal } from '../StyledComponents'

let campaignTypeOptions = []
let targetAudienceOptions = []

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.campaignName && touched.campaignName ? <Error>{errors.campaignName}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Campaign Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="campaignName"
                    type="text"
                    placeholder="Campaign Name"
                    value={values.campaignName }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.campaignTypeIds && touched.campaignTypeIds ? <Error>{errors.campaignTypeIds}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Campaign Type:
                </div>
                <div css={`width: 60%`}>
                  <TypeSelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    value={values.campaignTypeIds }
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.campaignTypeIds}
                  /> 
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Active:
                </div>
                <div css={'width:15px'}>
                  <Input
                    name="campaignStatus"
                    type="checkbox"
                    css={`width:18px;height:18px`}
                    checked={values.campaignStatus}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Target Audience:
                </div>
                <div css={`width: 60%`}>
                  <TargetAudience         //calls the TargetAudience (ismaulti true hobe) Class and passes all the props necessary with exact name.
                    value={values.targetAudienceIds }
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.targetAudienceIds}
                  /> 
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Target Size:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="targetSize"
                    type="number"
                    placeholder="Enter Number"
                    value={values.targetSize }
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Expected Start Date:
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="expectedStartDate"
                  value={values.expectedStartDate }
                  onChange={setFieldValue}
                  
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Expected End Date:
                </div>
                <div css={`width: 60%`}>
                <DateSelect         
                  name="expectedEndDate"
                  value={values.expectedEndDate}
                  onChange={setFieldValue}
                  
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Remark:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="remark"
                    placeholder="Type here..."
                    value={values.remark}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class TypeSelect extends React.Component {
  handleChange = value => {
    this.props.onChange("campaignTypeIds", value); //initially gets the null value and updates when called from onchange below
  };

  handleBlur = () => {
    this.props.onBlur("campaignTypeIds", true); //sets the onblur value to true when called from onBlur below
  };

  render() {
    return (
        <Select
          id="color"
          options={campaignTypeOptions}
          //isMulti
          onChange={this.handleChange}  //calls the handleChange in MySelect class
          onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
          value={this.props.value}      // gets the selected value, which is eventually options.value
        />

    );
  }
}

class TargetAudience extends React.Component {
    handleChange = value => {
      this.props.onChange("targetAudienceIds", value); //initially gets the null value and updates when called from onchange below
    };
  
    handleBlur = () => {
      this.props.onBlur("targetAudienceIds", true); //sets the onblur value to true when called from onBlur below
    };
  
    render() {
      return (
          <Select
            id="color"
            options={targetAudienceOptions}
            isMulti
            onChange={this.handleChange}  //calls the handleChange in MySelect class
            onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
            value={this.props.value}
          />
  
      );
    }
  }


const DateSelect = ({ name, value, onChange }) => {
    return (
      <DatePicker
        selected={(value && new Date(value))}
        dateFormat="dd/MM/yyyy"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        onChange={val => {
          onChange(name, val);
        }}
      />
    );
  };
  

const Campaign = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
      const fetchCampaignCategory = await axios(FetchAllCampaignCategory);
      campaignTypeOptions = fetchCampaignCategory.data.map(element=>({label: element.name, value: element._id}))
      
      const fetchTargetAudience = await axios(FetchAllTargetAudience);
      targetAudienceOptions = fetchTargetAudience.data.map(element=>({label: element.name, value: element._id}))
     }
     catch (err) {
      // console.log(err)
     }

      await axios(FetchAllCampaign(localStorage.getItem("jwtID")))
      .then(result=>{
        setEditable(prev=>{  
          return {
            ...prev,
            loaded: result.data
          }
        })
      })
      .catch(err=>{
        // console.log("err")
      })
      

    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  const campaign = editable.loaded ?
                  editable.loaded.Error? [] :
                  editable.loaded.map(item=>item.campaignName) : []

  const schema = Yup.object().shape({
    campaignName: Yup.string().required("Must Define a Name")
                               .min(3, "Minimum 3 characters")
                               .notOneOf(campaign, "Already exists"),
    campaignTypeIds: Yup.string().required("Please select one..."),
    expectedStartDate: Yup.string().required("Select a Start Date"),
    expectedEndDate: Yup.string().required("Select a Start Date"),
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      createdByCompanyID: localStorage.getItem("jwtID"),
      campaignName:"" , 
      campaignTypeIds: '',
      campaignStatus: true,
      targetAudienceIds: '',
      targetSize: '',
      referenceId: '',
      expectedStartDate: new Date(Date.now()),
      expectedEndDate: new Date(Date.now()),
      remark: ''
    }),

    handleSubmit: (values, { setSubmitting, resetForm }) => {
      const data={
          ...values, 
        campaignTypeIds: values.campaignTypeIds.value,
        targetAudienceIds: values.targetAudienceIds ? values.targetAudienceIds.map(t => t.value) : '' //using ternary operator to avoid error(if false map fn won't execute) 
        }                                                                                   //when submitted without any value as it is not mendatory field
      
      axios.post(AddNewCampaign, data)
      .then((response) => {
        if(!response.data.Error){
          setView(true)
        }
        else {
          window.alert("Uexpected Error! Duplicate Name found!")
        }
      })
      .catch((err) => {
        // console.log("Error")
      })
          
    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Campaign)