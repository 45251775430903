import React from 'react'
import Select from 'react-select'
import {connect} from 'react-redux'

import LikertScale from './LikertScale'
import Demography from './Demography'
// import likertScale from '../../questionLibraries/likertScale'
// import {libraryUpdate} from '../../redux/actions/modifyQuestion'

const QuestionConfig = ({id_, type})=>{
  
  switch(type){
    case "likertScale":
      return(
        <LikertScale id_ = {id_} />
      )
    case "demography":
      return(
        <Demography id_ = {id_} />
      )
    default:
      return(
        <>
        </>
      )
  }
  
  
}

const mapStateToProps = (state, ownProps) => {
  const {id_, type} = ownProps
  return {
    id_,
    type
  }
}

// const mapDispatchToProps = {
//   libraryUpdate: (id_, library)=>libraryUpdate(id_, library)
// }

export default connect(mapStateToProps)(QuestionConfig)