import React from 'react';
import { connect } from 'react-redux'
import CKEditor from '@ckeditor/ckeditor5-react';
// NOTE: Use the editor from source (not a build)!
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import { editHeader } from '../../../redux/actions'
import { headerTextSelector } from '../../../redux/selectors'
import './style.css'

const editorConfiguration = {
  plugins: [Essentials, Bold, Italic, Paragraph, Underline, alignment, Font],
  toolbar: ['bold', 'italic', 'underline', 'alignment', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'undo', 'redo'],
  alignment: {
    options: ['center', 'right', 'justify', 'left']
  }
};

const TextEditor = props => {
  const { data, editHeader, isReadOnly } = props
  return (
    <div id="SurveyDesignEditor">
      <CKEditor
        editor={ClassicEditor}
        data={data}
        config={editorConfiguration}
        // onInit={editor=>{
        //   editor.setData(data)
        // }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log(event);
          editHeader(data)
        }}
        disabled={isReadOnly}
        style={{ width: '120px' }}
      />
    </div>
  );
}

const mapStateToProps = state => ({
  data: headerTextSelector(state),
  // status: state.question.status,
  isReadOnly: state.question.isReadOnly
})

const mapDispatchToProps = {
  editHeader: data => editHeader(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(TextEditor);