import React from 'react'
import Select from 'react-select'
import { connect } from "react-redux"
import './Nps.css'
import styled from 'styled-components'
import { Scale, Rating, RatingValue, NpsTextWrapper, NpsTextItem, NpsInput } from './Styles'
import { RadioButton } from 'primereact/radiobutton'

export const NpsScaleValue = styled(RatingValue)`
    margin-bottom:4px;
`

export const NpsRating = styled(Rating)`
    padding: 4px 4px 10px 4px;
`

const npsScale = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];


const Nps = ({ item, editField, hasError }) => {
    return (
        <>
            <NpsTextWrapper>
                <NpsTextItem>
                    <NpsInput
                        hasError={hasError}
                        type="text"
                        name="startLabel"
                        id="startLabel"
                        value={item.startLabel}
                        onChange={e => { editField(item.id, "startLabel", e.target.value) }}
                        autoComplete="off" />
                </NpsTextItem>
                <NpsTextItem className="text-right">
                    <NpsInput
                        hasError={hasError}
                        type="text"
                        name="endLabel"
                        id="endLabel"
                        value={item.endLabel}
                        onChange={e => { editField(item.id, "endLabel", e.target.value) }}
                        autoComplete="off" />
                </NpsTextItem>
            </NpsTextWrapper>
            <Scale>
                {
                    npsScale.map((scale, index) =>   //for generating the images, scaleEmoji is the array that contains all the iamges, item.scale is the selected number 3,5 or 7
                        <NpsRating key={index}>
                            <NpsScaleValue
                                value={item.ratings[index]}
                                scaleValue={item.scale}
                                onChange={(e) => false}
                                sty
                            />
                            <RadioButton inputId={`${index}`} name={`${index}`} value={`${index}`} onChange={(e) => false} />
                        </NpsRating>
                    )
                }
            </Scale>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        hasError: state.error.label
    }
}

export default connect(mapStateToProps, null)(Nps)