// export const COMPANY_DATA               = 'COMPANY_DATA'
// export const CATEGORY_DATA              = 'CATEGORY_DATA'
// export const CATEGORY_DATA_TRUE_ONLY    = 'CATEGORY_DATA_TRUE_ONLY'
// export const CREATE_BRAND               = 'CREATE_BRAND'
export const GET_ALL_FEATURE = 'GET_ALL_FEATURE'
export const GET_SINGLE_FEATURE = 'GET_SINGLE_FEATURE'
export const CREATE_FEATURE = 'CREATE_FEATURE'
// export const UPDATE_BRAND               = 'UPDATE_BRAND'
export const UPDATE_FEATURE = "UPDATE_FEATURE"
export const DELETE_FEATURE = 'DELETE_FEATURE'
// export const ERROR_IN_BRAND             = 'ERROR_IN_BRAND'
export const BACK_BUTTON_IN_DETAIL_PAGE = 'BACK_BUTTON_IN_DETAIL_PAGE' 