import React from 'react';
import styled from 'styled-components';

const SingleLine = styled.input`
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius:3px;
  background-color:#CFD8DC;
  resize: none;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

const DateRange = () => {
    return (
        <>  
            <div className="row w-100 ml-0 mt-4 mb-3">
                <div className="col pl-0">
                    <SingleLine disabled placeholder="Start Date" type="text" />    
                </div>
                <div className="col pr-0">
                    <SingleLine disabled placeholder="End Date" type="text" /> 
                </div>
            </div>  
        </>
    );
}

export default DateRange;