import React, { useEffect, useState } from 'react';
// import './style.css'
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { deleteSurvey, hideToast } from '../../redux/actions';
import showToast from '../../../../components/Toast/toast';
import DeleteModal from '../modal/deleteModal';

const FormButton = styled.button`
    font-size: 12px;
    font-weight: 500;
    border: none;
    color: #FFF;
    height: 55px;
    width: 56px;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    @media(max-width: 767.92px) {
        height:32px;
        width:32px
        font-size:10px;
    }
    @media(max-width: 991.92px) {
        height:32px;
        width:32px;
        font-size:10px;
    }
`;

const DeleteButton = styled(FormButton)`
  background: #dc3545;
  position: fixed;
  top: 85px;
  right: ${(props) => props.right};
  z-index: 2;
  height: auto;
  width: auto;
  padding: 5px 20px;
  border-radius: 5px;
  @media (max-width: 767.92px) {
    top: 50px;
    right: 8px;
  }
  @media (max-width: 991.92px) {
    top: 55px;
    right: 10px;
    width: auto;
    height: auto;
  }
`;

const Step = (props) => {
  let project = useSelector((state) => state.survey.surveyProject);
  let config = useSelector((state) => state.survey.surveyConfig);
  let preview = useSelector((state) => state.survey.surveyPreview);
  let surveyid = useSelector((state) => state.survey.projectId);
  let channel = useSelector((state) => state.survey.surveyChannel);
  let disid = useSelector((state) => state.question.distributionId);
  let toast = useSelector((state) => state.survey.toast);
  const dispatch = useDispatch();

  let [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    // console.log(!modalOpen)
    setModalOpen(!modalOpen);
  };

  const DeleteToast = (msg) => showToast('Deleted Successfully');
  const DependencyToast = () =>
    showToast('Cannot Delete. There are dependency');

  // useEffect(() => {
  //     console.log(toast.status)
  //     if (toast.status) {
  //         deleteSuccess(toast.message);
  //         dispatch(hideToast());
  //     }
  // }, [toast.status])
  const surveyDelete = () => {
    setModalOpen(!modalOpen);
    dispatch(
      deleteSurvey(surveyid, props.history, DeleteToast, DependencyToast)
    );
  };

  return (
    <div className='step' style={{ marginTop: '25px' }}>
      <div className='container'>
        {modalOpen && (
          <DeleteModal
            modal={modalOpen}
            toggle={toggleModal}
            deleteSurvey={surveyDelete}
          />
        )}
        <DeleteButton
          onClick={toggleModal}
          //   right={props.pathname.includes('sortandpreview') ? '150px' : '130px'}
          right={
            props.location && props.location.pathname.includes('preview')
              ? '150px'
              : '130px'
          }
        >
          Delete
        </DeleteButton>
        <div className='row justify-content-center'>
          <div className='col'>
            {project ? (
              // <Link to="/app/survey/question">
              <Link to={`/app/survey/question/${surveyid}`}>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: project ? '#1B998B' : '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-10px' }}>
                    Create
                  </p>
                </div>
              </Link>
            ) : (
              <>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-10px' }}>
                    Create
                  </p>
                </div>
              </>
            )}
          </div>
          <div className='col align-self-center text-center'>
            {config ? (
              <Link to={`/app/survey/create/sortandpreview/${surveyid}`}>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: config ? '#1B998B' : '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-10px' }}>
                    Design
                  </p>
                </div>
              </Link>
            ) : (
              <>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-10px' }}>
                    Design
                  </p>
                </div>
              </>
            )}
          </div>
          <div className='col align-self-center text-center'>
            {preview ? (
              <Link to={`/app/survey/create/preview/${surveyid}`}>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: preview ? '#1B998B' : '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                    position: 'relative',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-18px',
                      right: '-24px',
                      width: '66px',
                      color: '#FFF',
                      fontWeight: '500',
                      backgroundColor: '#1B998B',
                      fontSize: '10px',
                      padding: '1px, 2px',
                      borderRadius: '2px',
                    }}
                  >
                    See preview
                  </div>
                </div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-14px' }}>
                    Preview
                  </p>
                </div>
              </Link>
            ) : (
              <>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <hr
                  className='line'
                  style={{
                    right: '0%',
                    left: '30px',
                    width: '100%',
                  }}
                />
                <div>
                  <p className='step-text' style={{ marginLeft: '-14px' }}>
                    Preview
                  </p>
                </div>
              </>
            )}
          </div>
          <div className='col align-left-center text-center'>
            {channel ? (
              <Link to={`/app/survey/create/channel/${surveyid}`}>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: channel ? '#1B998B' : '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <div>
                  <p className='step-text' style={{ marginLeft: '-16px' }}>
                    Distribute
                  </p>
                </div>
              </Link>
            ) : (
              <>
                <div
                  className='my-auto dot'
                  style={{
                    height: '16px',
                    width: '16px',
                    backgroundColor: '#BDBDBD',
                    border: '1px solid #BDBDBD',
                    borderRadius: '8px',
                    // margin: '0 auto',
                  }}
                ></div>
                <div>
                  <p className='step-text' style={{ marginLeft: '-16px' }}>
                    Distribute
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Step);
