import styled from 'styled-components';

const FormButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #fff;
  height: 55px;
  width: 56px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  @media (max-width: 767.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
  @media (max-width: 991.92px) {
    height: 32px;
    width: 32px;
    font-size: 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
  position: relative;
  padding: 0;
  margin: 0;
  position: relative;
  min-height: calc(100vh - 135px);
  padding: 10px 10px 0px 10px;

  @media (max-width: 991.92px) {
    grid-template-columns: 1fr;
    min-height: fit-content;
  }
`;

export const ProgressbarRedirectUrl = styled.div`
  order: 1;
  box-sizing: border-box;
  height: 100%;
  border-radius: 4px 0px 0px 0px;
  background-color: #eff3f4;

  & label {
    background: white;
    min-height: fit-content;
    cursor: pointer;
    display: block;
    padding: 10px;
    border-radius: 4px;
  }

  & input[type='text'] {
    max-width: 250px;
  }
`;

export const PreviewBody = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 0px 0px 15px 0px;
`;

export const SaveButton = styled(FormButton)`
  background: rgb(27, 153, 139);
  position: fixed;
  top: 85px;
  right: 50px;
  z-index: 2;
  height: auto;
  width: auto;
  padding: 5px 20px;
  border-radius: 5px;
  @media (max-width: 767.92px) {
    top: 80px;
    right: 45px;
  }
  @media (max-width: 991.92px) {
    top: 55px;
    right: 90px;
    width: auto;
    height: auto;
  }
`;

// export const SaveButton = styled.button`
//   display: block;
//   position: fixed;
//   z-index: 20;
//   width: 60px;
//   height: 60px;
//   top: 85px;
//   right: 100px;
//   font-size: 12px;
//   font-weight: 500;
//   border-radius: 50%;
//   border: none;
//   outline: none;
//   padding: auto;
//   margin: 0;
//   line-height: 1;
//   text-align: center;
//   text-decoration: none;
//   background: #1b9989;
//   // background: #1B998B;
//   color: white;
//   cursor: pointer;
//   box-shadow: 0 0 3px 1px grey;
//   :hover{
//     box-shadow: 2px 2px 5px 2px grey;
//   }
//   @media(max-width: 767.92px) {
//     top: 50px;
//     right: 45px;
//     width: 32px;
//     height: 32px;
//     font-size: 9px;
//     font-weight:500;
//   }
//   @media(max-width: 992.92px) {
//     top: 62px;
//     right: 45px;
//     width: 32px;
//     height: 32px;
//     font-size: 9px;
//     font-weight:500;
//   }
// `

export const SubmitButton = styled.button`
  display: block;
  position: fixed;
  z-index: 20;
  width: 60px;
  height: 60px;
  top: 85px;
  right: 100px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: auto;
  margin: 0;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: #1b998b;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 3px 1px grey;
  :hover {
    box-shadow: 2px 2px 5px 2px grey;
  }
`;

export const DistributeButton = styled(FormButton)`
  background: rgb(27, 153, 139);
  position: fixed;
  top: 85px;
  right: 50px;
  z-index: 2;
  height: auto;
  width: auto;
  padding: 5px 20px;
  border-radius: 5px;
  :disabled {
    background: #ec3b3b;
  }
  @media (max-width: 767.92px) {
    top: 80px;
    right: 45px;
  }
  @media (max-width: 991.92px) {
    top: 55px;
    right: 90px;
    width: auto;
    height: auto;
  }
`;

// export const DistributeButton = styled.button`
//   display: block;
//   position: fixed;
//   z-index: 20;
//   font-size: 10px;
//   width: 60px;
//   height: 60px;
//   top: 85px;
//   right: 100px;
//   font-size: 12px;
//   font-weight: 500;
//   border-radius: 50%;
//   border: none;
//   outline: none;
//   padding: auto;
//   margin: 0;
//   line-height: 1;
//   text-align: center;
//   text-decoration: none;
//   background: #1b998b;
//   color: white;
//   cursor: pointer;
//   box-shadow: 0 0 3px 1px grey;
//   :hover {
//     box-shadow: 2px 2px 5px 2px grey;
//   }
//   :disabled {
//     background: #ec3b3b;
//   }
//   @media (max-width: 767.92px) {
//     top: 50px;
//     right: 45px;
//     width: 32px;
//     height: 32px;
//     font-size: 9px;
//     font-weight: 500;
//   }
//   @media (max-width: 992.92px) {
//     top: 62px;
//     right: 45px;
//     width: 32px;
//     height: 32px;
//     font-size: 9px;
//     font-weight: 500;
//   }
// `;

export const PreviewButton = styled(SaveButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  text-decoration: none;
  :hover {
    color: white;
  }
`;
