import {
    GET_ALL_BROAD_OBJECTIVES,
    GET_SINGLE_BROAD_OBJECTIVE,
    CREATE_BROAD_OBJECTIVE,
    BACK_BUTTON_IN_DETAIL_PAGE,
    DELETE_BROAD_OBJECTIVE,
    UPDATE_BROAD_OBJECTIVCE
} from '../actions/actionTypes'

const init = {
    allData: [],
    dataDetails: {}
}


const brandCategoryReducer = (state = init, action) => {
    switch (action.type) {

        case GET_ALL_BROAD_OBJECTIVES: {
            return {
                ...state,
                allData: action.payload
            }
        }

        case GET_SINGLE_BROAD_OBJECTIVE: {
            return {
                ...state,
                dataDetails: action.payload
            }
        }

        case UPDATE_BROAD_OBJECTIVCE: {
            let data = state.allData.map(obj => {
                if (obj._id === action.payload._id) {
                    return action.payload
                }
                return obj
            })
            let datas = { count: data.length, data }
            return {
                ...state,
                allData: data,
                dataDetails: action.payload,
            }
        }

        case DELETE_BROAD_OBJECTIVE: {
            let data = state.allData.filter(obj => {
                if (obj._id !== action.payload._id) { return obj }
            })
            // let datas = { count: data.length, data }

            return {
                ...state,
                allData: data,
                dataDetails: {},
            }
        }

        case CREATE_BROAD_OBJECTIVE: {
            const allDatas = state.allData.concat(action.payload)
            return {
                ...state,
                allData: allDatas
            }
        }

        case BACK_BUTTON_IN_DETAIL_PAGE: {
            return {
                ...state,
                dataDetails: {}
            }
        }

        default: return state

    }
}

export default brandCategoryReducer; 
