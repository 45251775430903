import React from 'react'

import './index.css'

const Loader = () => {
  return(
    <div className="flex-container">
      
      <div className="loader">
      </div>

    </div>
  )
}



export const DotLoader = ()=>{
  return <p className="loading">Loading</p>
}


export default Loader