import React from 'react';
import collect from 'collect.js';
import './VerticalBar.css';

const VerticalBar = (props) => {

    const colors = ['#1B998B', '#7EAB7E', '#CDC392', '#FFBF69', '#F27D7D'];
    let data = [...props.data];
    data = collect(data).reverse();
    const scale = [1, 2, 3, 4, 5];

    let element = data.map((value, index) => {
        let borderWidth = value ? '1px' : '0px';
        const style = {
            div: {
                height: `${value}%`,
                background: `${colors[index]}`,
                opacity: '.7',
                borderRadius: '2px',
                borderBottom: `${borderWidth} solid #FFFFFF`,
                maxWidth: '50px',
                marginRight: '-10px!important'
            },
        }
        return <div key={index} style={style.div} />
    })

    let scaleElement = scale.map((item, index) => {
        let marginAdjust = data.get(index) >= 10 ? 0 : 8;
        const style = {
            img: {
                display: 'inline-block',
                height: '32px',
                width: '32px',
                marginBottom: '4px',
                marginRight: `${marginAdjust}px`
            },
            span: {
                fontSize: '12px',
                paddingLeft: '2px',
                fontWeight: '700',
                color: "#616161"
            }
        }
        let num = 5 - index;
        return <div key={index} className="text-right">
            <img style={style.img} src={require(`./${num}.png`)} alt={index} />
            <span style={style.span}>{` ${data.get(index)}%`}</span>
        </div>

    });

    return (
        <>
            <div className="row mt-4 VerticalBar">
                <div className="col-5 pr-0">
                    {element}
                </div>
                <div className="col-7 pl-0 align-self-center">
                    {scaleElement}
                </div>
            </div>
        </>
    );
}

export default VerticalBar;