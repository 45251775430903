import Axios from 'axios' 
import {
    CREATE_BRAND, GET_ALL_BRAND, GET_SINGLE_BRAND, UPDATE_BRAND, 
    DELETE_BRAND, ERROR_IN_BRAND, BACK_BUTTON_IN_DETAIL_PAGE, 
    COMPANY_DATA, CATEGORY_DATA, CATEGORY_DATA_TRUE_ONLY
} from './actionTypes'

import { 
    brands, addBrand, singleBrand, updateBrand, 
    getProfileWiseAllBrand, brandCetagories, brandCetagoriesTrueOnly
    
} from '../../../../../url/prmUrl'

import { profileDetailsById } from '../../../../../url/index'


export const createNewBrand = (values, CreateToast, ErrorToast) => dispatch => { 
    Axios.post(addBrand, values) 
        .then(res => { 
            dispatch({ type: CREATE_BRAND, payload: res.data }) 
            CreateToast() 
        }) 
        .catch(err => ErrorToast()) 
} 


export const getALLBrandsProfileWise = (id) => dispatch => { 
    Axios.get(getProfileWiseAllBrand(id)) 
        .then(res => { dispatch({type: GET_ALL_BRAND, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND}) }) 
} 


export const companyFieldData = (id) => dispatch => { 
    Axios.get(profileDetailsById(id)) 
        .then(res => { dispatch({type: COMPANY_DATA, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND}) }) 
} 


export const categoryFieldData = () => dispatch => { 
    Axios.get(brandCetagories) 
        .then(res => { dispatch({type: CATEGORY_DATA, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND}) }) 
} 


export const categoryFieldDataTrueOnly = () => dispatch => { 
    Axios.get(brandCetagoriesTrueOnly) 
        .then(res => { dispatch({type: CATEGORY_DATA_TRUE_ONLY, payload: res.data}) }) 
        .catch(err => { dispatch({type: ERROR_IN_BRAND}) }) 
} 



export const getBrandDetail = (id, ErrorToast) => dispatch => { 
    Axios.get(singleBrand(id)) 
        .then(res => { dispatch({type: GET_SINGLE_BRAND, payload: res.data[0]}) }) 
        .catch(err => { ErrorToast() }) 
} 


export const updateBrandById = (id, value, UpdateToast, ErrorToast) => dispatch => { 
    Axios.patch(updateBrand(id), value) 
        .then(res => { dispatch({type: UPDATE_BRAND, payload: res.data}); UpdateToast(); }) 
        .catch( err => ErrorToast() ) 
} 


export const deleteBrandById = (state) => dispatch => { dispatch({type: DELETE_BRAND, payload: {state}}) } 


export const backButtonFunction = () => dispatch => { dispatch({type: BACK_BUTTON_IN_DETAIL_PAGE}) } 










