import React, {useState} from 'react'
import ScreenModal from '../modal/modal'
import {Modal, ModalHeader, ModalBody, Button} from 'reactstrap'
import ReqDemoModal from '../../../LandingPage/components/ReqDemo/ReqDemoModal'
import {css} from 'glamor'

function Summary(props) { 
  const [showFreeModal, setShowFreeModal] = useState(false) 
  const [successMsgModal, setSuccessMsgModal] = useState(false) 
  const [reqDemo, setReqDemo] = useState(false); 

  const style = {
    btnStyle: {backgroundColor: '#1B998B', color: '#fff', borderRadius: '0%'}, 
    pStyle: {color: 'rgb(52, 58, 64)', fontSize: '15px', fontWeight: '400', lineHeight: '1.2'}
  } 

  const cancelModal = () => setReqDemo(false); 
  const closeModal = (msg = null) => {  
    if(msg) { 
      setSuccessMsgModal(true) 
    } 
    setShowFreeModal(false) 
  } 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }
  } 

  const textColor = css({ color: 'rgb(52, 58, 64)', fontWeight: '400' }) 
  const headerColor = css({ color: 'rgb(52, 58, 64)', fontWeight: '500' }) 


  return ( 
    <> 
      {/* Signup Modals for free, basic, standard, pro, advanced */} 
      <> 
        { showFreeModal && <ScreenModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} /> } 
      </> 

      {/* Signup success message */}
      <>
        <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          {/* { closeModal() }  */}
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!!
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 

      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 


      {/* Page Content */} 
      <div className="container"> 
        <div className="row"> 
          {/* Free */} 
          <div className="colz"> 
            <h6 className="text-center" {...headerColor} style={{fontSize: '24px'}}>Free</h6> 
            <h5 className="text-center" {...headerColor} style={{fontSize: '18px'}}>$0/month</h5> 
            <h6 className="text-center" style={{marginTop: '42px', fontSize: '14px'}} {...textColor}> 
              Start trial 
            </h6> 
            <button className="btn btn-sm btn-block btnHover" 
              style={style.btnStyle} onClick={() => setShowFreeModal(true)}> 
              Join Free 
            </button> 
            {/* <p className="text-center my-3" style={style.pStyle}>Free access for life time!</p>  */}
            <ul style={{marginLeft: '15px', fontSize: '12px', marginTop: '15px'}} {...textColor}> 
              <li>No credit card required</li> 
              <li>100 Response per month</li> 
              <li>Single User</li> 
              <li>Basic Question types</li> 
              <li>Customize survey colors</li> 
              <li>Create random Survey</li> 
              <li>Build your own form</li> 
              <li>Access to all templates</li> 
              <li>Distribute via Open link</li> 
              <li>Distribute via Qr Code</li> 
              <li>Distribute on Social media</li> 
              <li>Core Analysis</li> 
              <li>Minimum Support</li> 
            </ul> 
          </div> 


          {/* Basic */}
          <div className="col"> 
            <h6 className="text-center" {...headerColor} style={{fontSize: '24px'}}>Basic</h6> 
            <h5 className="text-center" {...headerColor} style={{textDecoration: 'line-throug', fontSize: '18px'}}>$15/month</h5> 
            <h6 className="text-center" style={{fontSize: '11px', textDecoration: 'line-throug'}} {...textColor}>
              $10/month <br/> billed annually
            </h6> 
            <h6 className="text-center" style={{fontSize: '14px'}} {...textColor}> 
              Suitable for personal uses 
            </h6> 
            <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
              Request Demo 
            </button> 
            {/* <p className="text-center my-3" style={style.pStyle}>
              Mimimum payment, basic feature
            </p>  */}
            <ul style={{marginLeft: '15px', fontSize: '12px', marginTop: '15px'}} {...textColor}> 
              <li>1000 Response per month</li>  
              <li>Unlimited questions in a project</li> 
              <li>Up to 10 projects per month</li> 
              <li>Multiple Question types</li> 
              <li>Customize survey colors</li> 
              <li>Create random Survey</li> 
              <li>Build your own form</li> 
              <li>Make your own branding</li> 
              <li>Distribute via open link</li> 
              <li>Generating Qr Code</li> 
              <li>Distribute via Email</li> 
              <li>Embed in any Website</li> 
              <li>Distribute on Social media</li> 
              <li>Core Analysis</li> 
              <li>Moderate Support</li> 
            </ul> 
          </div> 


          {/* Standard */}
          <div className="col"> 
            <h6 className="text-center" {...headerColor} style={{fontSize: '24px'}}>Standard</h6> 
            <h5 className="text-center" {...headerColor} style={{fontSize: '18px'}}>$39/month</h5> 
            <h6 className="text-center" style={{fontSize: '11px'}} {...textColor}>
              $35/month <br/> billed annually
            </h6> 
            <h6 className="text-center" style={{fontSize: '14px'}} {...textColor}> 
              Suitable for team 
            </h6> 
            <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
              Request Demo 
            </button>  
              {/* Upgrade
            </button>  */}
            {/* <p className="text-center my-3" style={{...style.pStyle, visibility: 'hidden'}}>Mimimum payment, basic feature</p>  */}
            <ul style={{marginLeft: '15px', fontSize: '12px', marginTop: '15px'}} {...textColor}> 
              <li>5000 Response per month</li> 
              <li>Unlimited Survey</li> 
              <li>Multiple User access</li> 
              <li>No Survey limit</li> 
              <li>Unlimited questions in a project</li> 
              <li>Unlimited projects</li> 
              <li>Multiple Question types</li> 
              <li>Unlimited logic</li> 
              <li>Create random Survey</li> 
              <li>Build your own form</li> 
              <li>Make your own branding</li> 
              <li>Offline data collection</li> 
              <li>Distribute via Email</li> 
              <li>Embed in any Website</li> 
              <li>Distribute on Social media</li> 
              <li>Core Analysis</li> 
              <li>Moderate Support</li> 
            </ul> 
          </div> 


          {/* Pro */}
          <div className="col"> 
            <h6 className="text-center" {...headerColor} style={{fontSize: '24px'}}>Pro</h6> 
            <h5 className="text-center" {...headerColor} style={{fontSize: '18px'}}>$69/month</h5> 
            <h6 className="text-center" style={{fontSize: '11px'}} {...textColor}>
              $65/month <br/> billed annually
            </h6> 
            <h6 className="text-center" style={{fontSize: '14px'}} {...textColor}> 
              Suitable for organization 
            </h6> 
            <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
              Request Demo 
            </button> 
            {/* <p className="text-center my-3" style={{...style.pStyle, visibility: 'hidden'}}>Mimimum payment, basic feature</p>  */}
            <ul style={{marginLeft: '15px', fontSize: '12px', marginTop: '15px'}} {...textColor}> 
              <li>Unlimited Response</li> 
              <li>Unlimited Survey</li> 
              <li>Multiple User access</li> 
              <li>Unlimited questions in a project</li> 
              <li>Unlimited projects</li> 
              <li>Multiple Question types</li> 
              <li>Unlimited logic</li> 
              <li>Access up-to 10 branches</li> 
              <li>Create random Survey</li> 
              <li>Build your own form</li> 
              <li>Customer Experience/Employee Experience</li> 
              <li>Make your own branding</li> 
              <li>Offline data collection</li> 
              <li>Predetermined open link</li> 
              <li>Predetermined QR code</li> 
              <li>Distribute via Email</li> 
              <li>Embed in any Website</li> 
              <li>Distribute on Social media</li> 
              <li>Core Analysis</li> 
              <li>24x7 Support</li> 
              <li>API Integration</li> 
            </ul> 
          </div> 


          {/* Advance */}
          <div className="col"> 
            <h6 className="text-center" {...headerColor} style={{fontSize: '24px'}}>Advanced</h6> 
            <h5 className="text-center" {...headerColor} style={{marginBottom: '18px', fontSize: '18px'}}>Let's talk</h5> 
            <h6 className="text-center" style={{fontSize: '14px', marginTop: '42px'}} {...textColor}> 
              Suitable for customization 
            </h6> 
            <button className="btn btn-sm btn-block btnHover" style={style.btnStyle} onClick={() => setReqDemo(!reqDemo)}> 
              Request Demo 
            </button> 
            {/* <p className="text-center my-3" style={{...style.pStyle, visibility: 'hidden'}}>Mimimum payment, basic feature</p>  */}
            <ul style={{marginLeft: '15px', fontSize: '12px', marginTop: '15px'}} {...textColor}> 
              <li>All features of Pro Package &</li> 
              <li>Dedicated success manager</li> 
              <li>Team management and collaboration</li> 
              <li>Customized Dashboard</li> 
              <li>Advanced integrations</li> 
              <li>Custom contracts</li> 
            </ul> 
          </div> 
        </div> 
      </div> 
    </> 
  ) 
} 

export default Summary
