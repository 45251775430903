import React from 'react'
import CreateButton from './components/create/createButton'; 
import TableView from './components/table_view/index'

const BrandCategory = () => { 
    return ( 
        <div className="container"> 
            <CreateButton /> 
            <TableView /> 
        </div> 
    ) 
} 

export default BrandCategory; 
