import React from 'react'
import styled from 'styled-components/macro'
import { Spring, animated, interpolate } from 'react-spring/renderprops'

const TRIANGLE = 'M20,20 L20,380 L380,380 L380,20 L20,20 Z'
const RECTANGLE = 'M20,20 L20,380 L380,380 L380,20 L20,20 Z'
const styles = {
  container: {
    height: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    willChange: 'background',
    position: 'absolute',
  },
  shape: { width: 300, height: 300, position: 'absolute', willChange: 'transform' },
}

export default class Animate extends React.Component {
  state = { toggle: true }
  toggle = () => this.setState(state => ({ toggle: !state.toggle }))

  render() {
    const toggle = this.state.toggle
    return (
      <Spring 
        native
            from={{ fill: 'white',}}
            to={{
            fill: toggle ? 'white' : 'white',
            rotate: toggle ? '0deg' : '180deg',
            scale: toggle ? 0.3 : 0.9,
            shape: toggle ? TRIANGLE : RECTANGLE,
            opacity: toggle ? 0 : 0.7,
            marginTop: toggle ? 0 : 100,
            }}
            //toggle={this.toggle}
        >
        {({ toggle, fill,opacity,marginTop, rotate, scale, shape }) => (
          <animated.div style={{ ...styles.container }}>
            <animated.svg
              style={{
                ...styles.shape,opacity,marginTop,
                fill,
                transform: interpolate(
                  [rotate, scale],
                  (r, s) => `rotate3d(0,1,0,${r}) scale(${s})`
                ),
              }}
              version="1.1"
              viewBox="0 0 400 400">
              <g
                style={{ cursor: 'pointer',  }}
                fillRule="evenodd"
                onClick={this.toggle}
              >
                <animated.path id="path-1" d={shape} />
              </g>
            </animated.svg>
          </animated.div>
        )}
      </Spring>

    )
  }
}