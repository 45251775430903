import React from 'react'; 
import { withRouter, Link } from 'react-router-dom'
import {css} from 'glamor'; 
import './style.css'; 

function Footer(props) { 
  const miniFooter = css({ background: '#1B998B' }) 

  const textCss = css({ fontSize: '11px', color: 'white' }) 

  const iconCss = css({ marginRight: '20px', position: 'relative', top: '12px', cursor: 'pointer', color: '#fff' })

  return ( 
    <div className="row px-1" {...miniFooter}> 
      <div className="col-sm-12 col-md-6 col-lg-5"> 
        <ul className="smFooter mt-3" {...textCss}> 
          <li> About ProlificXM </li> 
          <li> Terms & Privacy </li> 
          <li> GDPR </li> 
          <li> Help </li> 
          <li> Contact </li> 
        </ul> 
      </div> 
      <div className="col-sm-12 col-md-6 col-lg-3"> 
        <span style={{position: 'relative', top: '12px'}} {...textCss}> 
          &copy; 2020 ProlificXM. All Rights Reserved 
        </span> 
      </div> 
      <div className="col-sm-12 col-md-6 col-lg-4"> 
        <span {...iconCss} onClick={() => {}}>  
          <i className="fab fa-twitter"></i>     
        </span> 
        <span {...iconCss} onClick={() => {}}>  
          <i className="fab fa-facebook-f"></i>  
        </span> 
        <span {...iconCss} onClick={() => {}}>  
          <i className="fab fa-linkedin-in"></i> 
        </span> 
        <span {...iconCss} onClick={() => {}} style={{marginRight: '0'}}>  
          <i className="fab fa-youtube"></i> 
        </span> 
      </div> 
    </div> 
  ) 
} 

export default withRouter(Footer); 
