import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Spring } from 'react-spring/renderprops';
import baseUrl, { loginURL } from '../../url';
import setAuthToken from '../../util/setAuthToken';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import crossEye from './asset/eye-cross.png';
import Eye from './asset/eye.png';
import SignupModal from '../Pricing/components/modal/modal';
import ShowToast from '../../components/Toast/toast';
import { css } from 'glamor';

import './index.css';

// import {
//     MainBody,
//     SignInButton,
//     SignUpButton,
//     Card,
//     Error,
//     Input,
//     Label,
//     StyledLink
// } from '../StyledComponents'
import Logo from './asset/ProlificXM.png';
import Animate from './animate';

const CryptoJS = require('crypto-js');

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'Device',
      viewCard: true,
      showPassword: false,
      isInvalid: false,
      showFreeModal: false,
      successMsgModal: false,
      forgotPasswordForm: false,
      resetPassword: false,
      response: false,
      invalidEmail: false,
      invalidEmailMsg: '',
    };
  }

  signupQuery = window.location.search;
  urlParams = new URLSearchParams(this.signupQuery);
  isSignup = this.urlParams.get('isSignup');

  componentDidMount() {
    if (this.isSignup === 'true') {
      this.setState({ showFreeModal: true });
    }
  }

  ErrorToast = () => ShowToast('Server Error');

  toggle = () => this.setState((state) => ({ viewCard: !state.viewCard }));

  setIdentification = (
    token,
    userType,
    id,
    userName,
    companyProfileId,
    roleDetails,
    pushTo
  ) => {
    const role = JSON.stringify(roleDetails);
    const ciphertext = CryptoJS.AES.encrypt(token, 'p0tat@_Pex-app');
    localStorage.setItem('jwtToken', ciphertext.toString());
    localStorage.setItem('jwtType', userType);
    localStorage.setItem('jwtID', id);
    localStorage.setItem('userName', userName);
    localStorage.setItem('jwtProfileId', companyProfileId);
    localStorage.setItem('roleDetails', role);
    setAuthToken(token);
    pushTo();
  };

  loginHandler = (data) => {
    // this.props.history.push("/app");
    const filteredData = {
      identification: data.email,
      password: data.password,
    };

    try {
      axios.post(loginURL, filteredData).then((res) => {
        if (res.data.isInvalid === true) {
          console.log({ loginResponse: res.data });
          this.setState({ isInvalid: res.data.isInvalid });
        } else {
          const {
            token,
            userType,
            id,
            userName,
            companyProfileId,
            roleDetails,
          } = res.data;

          if (userType === 'SuperUser') {
            // Set token in localStorage
            this.setIdentification(
              token,
              userType,
              id,
              userName,
              companyProfileId,
              roleDetails,
              () => this.props.history.push('/app')
            );
            // this.props.history.push("/app");
          } else if (userType === 'Device') {
            // Set token in localStorage
            this.setIdentification(
              token,
              userType,
              id,
              userName,
              companyProfileId,
              roleDetails,
              () => this.props.history.push('/app/preview')
            );
            // this.props.history.push("/app/preview");
          } else {
            //Admin
            this.setIdentification(
              token,
              userType,
              id,
              userName,
              companyProfileId,
              roleDetails,
              () => this.props.history.push('/app')
            );
            // this.props.history.push("/app");
          }
        }
      });
    } catch (error) {
      console.log('Server Error or wrong email/password ', error);
    }
  };

  closeModal = (msg = null) => {
    if (msg) {
      this.setState({ successMsgModal: true });
    } else {
      this.setState({ showFreeModal: false });
    }
  };

  styles = {
    error: {
      fontSize: '12px',
      color: 'red',
      marginBottom: '2px',
      fontWeight: '500',
    },
    button: { background: '#1B998B', borderColor: '#1B998B' },
  };

  forgotPasswordHeaderCss = css({ color: 'rgb(52, 58, 64)' });

  render() {
    return (
      <>
        <div className='wrapper'>
          <div className='element'>
            <div className='logo'>
              <Link to='/'>
                <img height='60px' src={Logo} />
              </Link>
            </div>
            <div className='box'>
              {!this.state.response ? (
                <>
                  <>
                    {!this.state.forgotPasswordForm ? (
                      <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={Yup.object().shape({
                          email: Yup.string().required('Required').trim(),
                          password: Yup.string().required('Required').trim(),
                        })}
                        onSubmit={(values) => {
                          this.loginHandler(values);
                        }}
                      >
                        {(props) => (
                          <Form onSubmit={props.handleSubmit}>
                            <FormGroup>
                              <label className='form-label'>
                                Email address
                              </label>
                              <input
                                type='text' // type email
                                id='email'
                                placeholder='user@your-email.com'
                                className='form-control form-control-signin'
                                style={{
                                  border:
                                    props.errors.email && props.touched.email
                                      ? '1px solid red'
                                      : null,
                                }}
                                value={props.values.email}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                              />
                              {props.errors.email && props.touched.email && (
                                <p
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    marginLeft: '5px',
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {props.errors.email}
                                </p>
                              )}
                            </FormGroup>

                            <FormGroup>
                              <label className='form-label'>Password</label>
                              <InputGroup>
                                <Input
                                  // type={this.state.showPassword ? 'text' : "password"}
                                  type={
                                    this.state.showPassword
                                      ? 'text'
                                      : 'password'
                                  }
                                  id='password'
                                  placeholder='Enter your password'
                                  className='form-control form-control-signin'
                                  style={{
                                    height: '30px',
                                    border:
                                      props.errors.password &&
                                      props.touched.password
                                        ? '1px solid red'
                                        : null,
                                  }}
                                  value={props.values.password}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />

                                {props.values.password && (
                                  <InputGroupAddon addonType='append'>
                                    <InputGroupText>
                                      {this.state.showPassword && (
                                        <img
                                          width='18px'
                                          style={{
                                            background: 'rgb(232, 240, 254)',
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              showPassword: false,
                                            });
                                          }}
                                          src={Eye}
                                        />
                                      )}
                                      {!this.state.showPassword && (
                                        <img
                                          width='18px'
                                          style={{
                                            background: 'rgb(232, 240, 254)',
                                          }}
                                          onClick={() => {
                                            this.setState({
                                              showPassword: true,
                                            });
                                          }}
                                          src={crossEye}
                                        />
                                      )}
                                    </InputGroupText>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                              {props.errors.password &&
                                props.touched.password && (
                                  <p
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginLeft: '5px',
                                      textAlign: 'left',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {props.errors.password}
                                  </p>
                                )}
                            </FormGroup>

                            {this.state.isInvalid && (
                              <p
                                style={{
                                  fontSize: '12px',
                                  color: 'red',
                                  fontWeight: '400',
                                }}
                              >
                                Invalid email or password
                              </p>
                            )}

                            <FormGroup>
                              <div className='custom-control custom-checkbox mb-3'>
                                <input
                                  type='checkbox'
                                  className='custom-control-input'
                                  id='customCheck1'
                                />
                                <label
                                  className='custom-control-label form-label'
                                  htmlFor='customCheck1'
                                >
                                  Remember me
                                </label>
                              </div>
                            </FormGroup>

                            <button
                              type='submit'
                              className='btn btn-block btn-sm btn-signin'
                            >
                              Sign In
                            </button>

                            {/* No API, Future Work */}

                            {/* <div style={{display: 'flex'}}> 
                                <hr width="40%" /> 
                                <span className="or"> 
                                    Or 
                                </span> 
                                <hr width="40%" /> 
                              </div>  */}
                            <span
                              style={{
                                fontSize: '11px',
                                cursor: 'pointer',
                                color: '#47A4DB',
                                fontWeight: '500',
                              }}
                              onClick={() =>
                                this.setState({ forgotPasswordForm: true })
                              }
                            >
                              Forgot your password?
                            </span>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <>
                        <h6 {...this.forgotPasswordHeaderCss}>
                          Forgot your password?
                        </h6>
                        <Formik
                          initialValues={{ email: '' }}
                          validationSchema={Yup.object().shape({
                            email: Yup.string().required('Required').trim(),
                          })}
                          onSubmit={(values, { setErrors }) => {
                            this.setState({ resetPassword: false });
                            let data = { email: values.email };
                            console.log(data);

                            axios
                              .post(`${baseUrl}/api/login/forgotPassword`, data)
                              .then((res) => {
                                if (res.data.isValid === true) {
                                  this.setState({
                                    response: true,
                                    invalidEmail: false,
                                    invalidEmailMsg: '',
                                  });
                                } else {
                                  this.setState({
                                    response: false,
                                    invalidEmail: true,
                                  });
                                  setErrors({ email: res.data.message });
                                }
                              })
                              .catch((err) => this.ErrorToast());
                          }}
                        >
                          {(props) => (
                            <Form onSubmit={props.handleSubmit}>
                              <FormGroup>
                                <label
                                  className='form-label mt-3 mb-2'
                                  style={{ fontSize: '14px' }}
                                >
                                  Please enter your email address
                                </label>
                                <input
                                  type='text' // type email
                                  id='email'
                                  placeholder='user@your-email.com'
                                  className='form-control form-control-signin'
                                  style={{
                                    border:
                                      props.errors.email && props.touched.email
                                        ? '1px solid red'
                                        : null,
                                  }}
                                  value={props.values.email}
                                  onChange={props.handleChange}
                                  onBlur={props.handleBlur}
                                />

                                {props.errors.email && props.touched.email ? (
                                  <p
                                    style={{
                                      color: 'red',
                                      fontSize: '12px',
                                      marginLeft: '5px',
                                      textAlign: 'left',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {props.errors.email}
                                  </p>
                                ) : (
                                  this.state.invalidEmail && (
                                    <p
                                      style={{
                                        color: 'red',
                                        fontSize: '12px',
                                        marginLeft: '5px',
                                        textAlign: 'left',
                                        fontWeight: 'bold',
                                      }}
                                    >
                                      {this.state.invalidEmailMsg}
                                    </p>
                                  )
                                )}

                                <button
                                  type='submit'
                                  className='btn btn-sm btn-block mt-4 mb-2'
                                  style={{
                                    background: '#1B998B',
                                    borderColor: '#1B998B',
                                    color: 'white',
                                  }}
                                >
                                  Request Password Reset
                                </button>

                                <p
                                  style={{
                                    fontSize: '11px',
                                    cursor: 'pointer',
                                    color: '#47A4DB',
                                    fontWeight: '500',
                                  }}
                                  onClick={() =>
                                    this.setState({ forgotPasswordForm: false })
                                  }
                                >
                                  Signin with email & password
                                </p>
                              </FormGroup>
                            </Form>
                          )}
                        </Formik>
                      </>
                    )}
                  </>
                  <center className='ifNo mt-3'>
                    Don't have an account? {` `}
                    <span
                      onClick={() => {
                        this.setState((state) => ({ showFreeModal: true }));
                      }}
                    >
                      SIGN UP
                    </span>
                  </center>
                </>
              ) : (
                <>
                  <>
                    <p
                      style={{
                        fontWeight: '600',
                        fontSize: '14px',
                        color: 'rgb(52, 58, 64)',
                        marginBottom: '1.5em',
                      }}
                    >
                      Password reset link has been sent. Please check your
                      email.
                    </p>

                    <p style={{ fontSize: '13px', color: 'rgb(52, 58, 64)' }}>
                      Follow the link to get back to your account.
                    </p>

                    <p style={{ fontSize: '13px', color: 'rgb(52, 58, 64)' }}>
                      Already reset your password? {` `}
                      <span
                        style={{
                          color: '#1B998B',
                          fontWeight: '600',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          this.setState({
                            response: false,
                            forgotPasswordForm: false,
                          });
                        }}
                      >
                        SIGN IN
                      </span>
                    </p>

                    <center
                      className='ifNo mt-4'
                      style={{ color: 'rgb(52, 58, 64)' }}
                    >
                      Don't have an account? {` `}
                      <span
                        onClick={() => {
                          this.setState((state) => ({ showFreeModal: true }));
                        }}
                      >
                        SIGN UP
                      </span>
                    </center>
                  </>
                </>
              )}
            </div>
          </div>

          {/* Signup Modals for free, basic, standard, pro, advanced */}
          <>
            {this.state.showFreeModal && (
              <SignupModal
                header='Free Signup'
                showFreeModal={this.state.showFreeModal}
                closeModal={this.closeModal}
              />
            )}
          </>

          {/* Signup success message */}
          <>
            <Modal
              isOpen={this.state.successMsgModal}
              className={this.props.className}
              style={{ top: '150px' }}
            >
              {/* { closeModal() }  */}
              <ModalHeader style={{ color: '#1B998B', padding: '0.5rem 1rem' }}>
                You are Done!!!
              </ModalHeader>
              <ModalBody>
                Thank you for joining us. Please check your email to verify.
                <br />
                <Button
                  className='btnHover mt-3'
                  size='sm'
                  style={this.styles.button}
                  onClick={() => {
                    this.setState((state) => ({ successMsgModal: false }));
                  }}
                >
                  Close
                </Button>
              </ModalBody>
            </Modal>
          </>

          {/* Forgot password modal */}
          <Modal
            isOpen={this.state.resetPassword}
            className={this.props.className}
            style={{ top: '150px' }}
          >
            <ModalHeader style={{ color: '#1B998B', padding: '0.5rem 1rem' }}>
              Forgot Password
            </ModalHeader>

            <ModalBody>
              <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                validationSchema={Yup.object().shape({
                  password: Yup.string().required('Required').min(6).trim(),
                  confirmPassword: Yup.string()
                    .required('Required')
                    .trim()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                })}
                onSubmit={(values, { resetForm }) => {
                  let data = {
                    password: values.password.trim(),
                    confirmPassword: values.confirmPassword.trim(),
                  };
                  console.log(data);
                }}
              >
                {(props) => (
                  <div>
                    <Form onSubmit={props.handleSubmit}>
                      {/* User Password */}
                      <FormGroup row style={{ marginBottom: '0.5em' }}>
                        <Label
                          htmlFor='password'
                          sm={4}
                          style={{ fontSize: '14px' }}
                        >
                          Password: <span style={{ color: 'red' }}>{` *`}</span>
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <Input
                              type='password'
                              name='password'
                              id='password'
                              className='signupPassword'
                              value={props.values.password}
                              onChange={props.handleChange}
                              style={{
                                height: 'calc(1.5em + 0.5rem + 2px)',
                                border:
                                  props.errors.password &&
                                  props.touched.password
                                    ? '1px solid red'
                                    : null,
                              }}
                              onBlur={props.handleBlur}
                              placeholder='Enter a password'
                              autoComplete='off'
                            />

                            {/* <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  {
                                    displayPassword &&
                                    <img width="18px"
                                      onClick={() => { setDisplayPassword(false) }}
                                      src={Eye} />
                                  }
                                  {
                                    !displayPassword &&
                                    <img width="18px"
                                      onClick={() => { setDisplayPassword(true) }} 
                                      src={crossEye} /> 
                                  } 
                                </InputGroupText> 
                              </InputGroupAddon>  */}
                          </InputGroup>
                          {props.errors.password && props.touched.password && (
                            <p
                              style={{
                                color: 'red',
                                fontSize: '12px',
                                marginLeft: '5px',
                                textAlign: 'left',
                                fontWeight: 'bold',
                              }}
                            >
                              {props.errors.password}
                            </p>
                          )}
                        </Col>
                      </FormGroup>

                      {/* Confirm Password */}
                      <FormGroup row style={{ marginBottom: '0.5em' }}>
                        <Label
                          htmlFor='confirmPassword'
                          sm={4}
                          style={{ fontSize: '14px' }}
                        >
                          Confirm Password:{' '}
                          <span style={{ color: 'red' }}>{` *`}</span>
                        </Label>
                        <Col sm={8}>
                          <InputGroup>
                            <Input
                              type='password'
                              name='confirmPassword'
                              id='confirmPassword'
                              value={props.values.confirmPassword}
                              onChange={props.handleChange}
                              style={{
                                height: 'calc(1.5em + 0.5rem + 2px)',
                                border:
                                  props.errors.confirmPassword &&
                                  props.touched.confirmPassword
                                    ? '1px solid red'
                                    : null,
                              }}
                              onBlur={props.handleBlur}
                              placeholder='Enter password again to confirm'
                              autoComplete='off'
                            />
                          </InputGroup>
                          {props.errors.confirmPassword &&
                            props.touched.confirmPassword && (
                              <p
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginLeft: '5px',
                                  textAlign: 'left',
                                  fontWeight: 'bold',
                                }}
                              >
                                {props.errors.confirmPassword}
                              </p>
                            )}
                        </Col>
                      </FormGroup>

                      <Button
                        type='submit'
                        className='btnHover mr-2'
                        size='sm'
                        style={{
                          background: '#1B998B',
                          borderColor: '#1B998B',
                        }}
                      >
                        Change Password
                      </Button>

                      <Button
                        className='btnHover'
                        size='sm'
                        onClick={() => this.setState({ resetPassword: false })}
                        style={{
                          background: '#1B998B',
                          borderColor: '#1B998B',
                        }}
                      >
                        Cancel
                      </Button>
                    </Form>
                  </div>
                )}
              </Formik>
            </ModalBody>
          </Modal>
        </div>
      </>
    );
  }
}

export default SignIn;
