import React, {useState} from 'react'
import image from './assets/Effort_2.png'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import './style.css'

function Effort() { 
  const [reqDemo, setReqDemo] = useState(false); 
  const cancelModal = () => setReqDemo(false); 

  let style={ 
    ourBtn: { 
      backgroundColor: '#1B998B', 
      borderColor: '#1B998B', 
      color: 'white', 
      borderRadius: '0%' 
    } 
  } 

  return ( 
    <div className="container boxStyle"> 
      <div className="row align-items-center"> 
        <div className="col-sm-12 col-md-4 col-lg-4 orderImg">  
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <img className="img-fluid effortImg" width="180px" src={image} alt="Customer Effort" /> 
          </div> 
        </div> 
        <div className="col-sm-12 col-md-7 col-lg-7 orderText"> 
          <div className="p-md-2 p-lg-5" style={{width: '100%'}}> 
            <h6 className="display-4 heading mb-3"> 
              Measure the effort of your <br /> customer to make it easier 
            </h6> 
            <p className="text"> 
              Only keeping your customers satisfied can’t ensure customer retention. Despite providing the best service, you may lose your customers to competitors just because they need to put more effort to avail your service. In modern-day business, it is important to know the pain points of your customers and initiate smart solutions to reduce their effort.
            </p> 
            <Button className="btn btn-sm btnHover" 
              onClick={() => setReqDemo(true)}> 
              Request Demo  
            </Button> 
          </div> 
        </div> 
      </div> 

      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    </div> 
  ) 
} 

export default Effort; 
