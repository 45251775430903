import React from 'react';
import TableView from './components/table_view/index';
import CreateButton from './components/createButton/createButton';

const BulkEmail = () => {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [data, setData] = React.useState([]);

  return (
    <div className='container'>
      <CreateButton
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        data={data}
        setData={setData}
      />
      <TableView searchQuery={searchQuery} data={data} setData={setData} />
    </div>
  );
};

export default BulkEmail;
