import React, { memo, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, nextConditionalQuestion, startDate } from '../../redux/actions'
import './style.css'
import { QuestionCard, QuestionTitle } from '../styles'
// import { Rating, Gif, Label, RadioButton, Scale } from './styles'
import { Rating, Gif, Label, Fields, Scale } from './styles'
import { RadioButton } from 'primereact/radiobutton'
// import one from './resources/1.gif'
// import two from './resources/2.gif'
// import three from './resources/3.gif'
// import four from './resources/4.gif'
// import five from './resources/5.gif'
// import six from './resources/6.gif'
// import seven from './resources/7.gif'

// const emojis = [one, two, four, six, seven]

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const LikertScale = props => {
  const { data, updateData, answer, nextConditionalQuestion, startDate } = props
  const { isConditional, jump, title } = data
  const [dicotomySelected, setDicotomySelected] = useState(null)

  useEffect(() => {
    setDicotomySelected(null)
  }, [data.id])

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  useEffect(() => {
    if (answer.index !== null)
      setDicotomySelected(answer.index)
  }, [answer])

  useEffect(() => {
    // console.log(answer)
    if (answer.index !== null) {
      if (isConditional) {
        const nextQuestion = jump.find(item => item.jumpOptionIndex === answer.index)
        if (!nextQuestion) {
          nextConditionalQuestion("NEXT")
        } else {
          nextConditionalQuestion(nextQuestion.jumpTo)
        }
      }
    }
  }, [answer])


  const handleChange = (event) => {
    const { id, value } = event.target
    const index = parseInt(id, 10)
    updateData({
      index,
      value
    })
    setDicotomySelected(index)
    if (isConditional) {
      const nextQuestion = jump.find(item => item.jumpOptionIndex === index)
      if (!nextQuestion) {
        nextConditionalQuestion("NEXT")
      } else {
        nextConditionalQuestion(nextQuestion.jumpTo)
      }
    }
  }

  const selectCheck = value => {
    return value === answer.index
  }

  const ratings = data.options.map((rating, index) =>
    <div key={`rating-${index}`}>
      {/* <RadioButton
        id={index}
        name={data.id}
        type="radio"
        value={rating}
        onChange={handleChange}
        checked={selectCheck(index)}
      />

      <Label>
        {rating}
      </Label> */}

      {/* <RadioButton inputId={`${index}`} name={`${data.id}`} value={`${rating}`} onChange={handleChange} checked={selectCheck(index)} /> */}
      <input type="radio" inputId={index} id={index} name={data.id} value={rating} onChange={handleChange} checked={selectCheck(index)} className="dicotomyres" />

      <label htmlFor={index} className="p-radiobutton-label" style={{ color: index === dicotomySelected ? "rgb(27, 153, 139)" : "#757575" }}>
        {rating}
      </label>

    </div>
  )

  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        <Scale>
          {ratings}
        </Scale>
      </Fields>
    </QuestionCard>)
}

LikertScale.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state)
  }
}

const mapDispatchToProps = {
  updateData,
  nextConditionalQuestion,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(LikertScale))