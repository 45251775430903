import React from 'react';
import styled from 'styled-components/macro'
import axios from 'axios';
// import Avatar from '../../../../resources/Banolota-Cover-pic.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusCircle, faSave, faCamera } from '@fortawesome/free-solid-svg-icons'
import { Input, Label, Body, UploadContainer, UploadButton, Upload, Aside } from './StyledComponents'
// import { fetchUser, putUser, postImageURL, postCoverImageURL } from '../../../../url';
import SimpleReactValidator from 'simple-react-validator';
import baseUrl from '../../../url'
import EasyModal from '../../../components/EasyModal'
import '../AddNewUser/style.css'
import { Button } from 'reactstrap'
import showToast from '../../../components/Toast/toast'

const modalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0,0.5)"
  }
};

const mainStyle = {
  app: {
    // margin: "120px 0"
  },
  button: {
    backgroundColor: "#408cec",
    border: 0,
    padding: "12px 20px",
    color: "#fff",
    margin: "0 auto",
    width: 150,
    display: "block",
    borderRadius: 3
  }
};

class Individual extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      userEmail: '',
      active: true,
      Type: [],
      roleId: "",
      roleName: "",
      password: "",
      confirmPassword: "",
      channel: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      companyProfile: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      dashboard: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      dataAnalysis_1: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      dataAnalysis_2: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      distribution: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      email: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      feature: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      highLevelObjective: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      lowLevelObjective: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      microLevelObjective: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      blog: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      questionBank: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },
      // prm: {
      //   view: false,
      //   create: false,
      //   edit: false,
      //   delete: false
      // },

      project: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      user: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      survey: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      setting: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      contact: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      employee: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      designation: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      company: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      industry: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      branch: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      department: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      brandCategory: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      campaignCategory: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      productCategory: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      targetAudience: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      campaign: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      brand: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      product: {
        view: false,
        create: false,
        edit: false,
        delete: false
      },

      edit: false,
      isEasyModalOpen: false,
      isEasyInnerModalOpen: false

    };
  }

  componentWillMount() {
    this.validator = new SimpleReactValidator({
      validators: {
        password: {
          message: 'Password must be matched.',
          rule: (val, params, validator) => {
            return val === params[0]
          },
          required: true
        }
      }
    });
  }

  componentDidMount() {
    axios.get(`${baseUrl}/api/login/${this.props.match.params.userId}`)
      .then(response => {
        const { roleInfo } = response.data
        this.setState({ firstName: response.data.firstName })
        this.setState({ lastName: response.data.lastName })
        this.setState({ userEmail: response.data.email })
        this.setState({ roleId: response.data.roleInfo.roleId })
        this.setState({ roleName: response.data.roleInfo.roleName })
        this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard = roleInfo.rolePermission.dashboard; return { dashboard } });
        this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile = roleInfo.rolePermission.companyProfile; return { companyProfile } });
        this.setState(prevState => { let channel = { ...prevState.channel }; channel = roleInfo.rolePermission.channel; return { channel } });
        this.setState(prevState => { let feature = { ...prevState.feature }; feature = roleInfo.rolePermission.feature; return { feature } });
        this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective = roleInfo.rolePermission.highLevelObjective; return { highLevelObjective } });
        this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective = roleInfo.rolePermission.lowLevelObjective; return { lowLevelObjective } });
        this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective = roleInfo.rolePermission.microLevelObjective; return { microLevelObjective } });
        this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank = roleInfo.rolePermission.questionBank; return { questionBank } });
        this.setState(prevState => { let blog = { ...prevState.blog }; blog = roleInfo.rolePermission.blog; return { blog } });
        this.setState(prevState => { let project = { ...prevState.project }; project = roleInfo.rolePermission.project; return { project } });
        this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution = roleInfo.rolePermission.distribution; return { distribution } });
        this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1 = roleInfo.rolePermission.dataAnalysis_1; return { dataAnalysis_1 } });
        this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2 = roleInfo.rolePermission.dataAnalysis_2; return { dataAnalysis_2 } });
        this.setState(prevState => { let email = { ...prevState.email }; email = roleInfo.rolePermission.email; return { email } });
        this.setState(prevState => { let user = { ...prevState.user }; user = roleInfo.rolePermission.user; return { user } });
        // this.setState(prevState => { let prm = { ...prevState.prm }; prm = roleInfo.rolePermission.prm; return { prm } });
        this.setState(prevState => { let survey = { ...prevState.survey }; survey = roleInfo.rolePermission.survey; return { survey } });
        this.setState(prevState => { let setting = { ...prevState.setting }; setting = roleInfo.rolePermission.setting; return { setting } });
        this.setState(prevState => { let contact = { ...prevState.contact }; contact = roleInfo.rolePermission.contact; return { contact } });
        this.setState(prevState => { let employee = { ...prevState.employee }; employee = roleInfo.rolePermission.employee; return { employee } });
        this.setState(prevState => { let designation = { ...prevState.designation }; designation = roleInfo.rolePermission.designation; return { designation } });
        this.setState(prevState => { let company = { ...prevState.company }; company = roleInfo.rolePermission.company; return { company } });
        this.setState(prevState => { let industry = { ...prevState.industry }; industry = roleInfo.rolePermission.industry; return { industry } });
        this.setState(prevState => { let branch = { ...prevState.branch }; branch = roleInfo.rolePermission.branch; return { branch } });
        this.setState(prevState => { let department = { ...prevState.department }; department = roleInfo.rolePermission.department; return { department } });
        this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory = roleInfo.rolePermission.brandCategory; return { brandCategory } });
        this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory = roleInfo.rolePermission.campaignCategory; return { campaignCategory } });
        this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory = roleInfo.rolePermission.productCategory; return { productCategory } });
        this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience = roleInfo.rolePermission.targetAudience; return { targetAudience } });
        this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign = roleInfo.rolePermission.campaign; return { campaign } });
        this.setState(prevState => { let brand = { ...prevState.brand }; brand = roleInfo.rolePermission.brand; return { brand } });
        this.setState(prevState => { let product = { ...prevState.product }; product = roleInfo.rolePermission.product; return { product } });

      })
    axios.get(`${baseUrl}/api/access/userRole`)
      .then(response => { this.setState({ Type: response.data.data }) })
      .catch(error => console.log(error))
  }

  closeEasyModal = () => this.setState({ isEasyModalOpen: false });

  openEasyModal = () => this.setState({ isEasyModalOpen: true });

  handleRolePermission = (event) => {
    let selectedRole
    this.setState(
      { roleId: event.target.value }
      ,
      () => {
        selectedRole = this.state.Type.find(type => type._id === this.state.roleId)
        // console.log('selectedRole', selectedRole)
        this.setState({ roleName: selectedRole.roleName })
        this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard = selectedRole.dashboard; return { dashboard } });
        this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile = selectedRole.companyProfile; return { companyProfile } });
        this.setState(prevState => { let channel = { ...prevState.channel }; channel = selectedRole.channel; return { channel } });
        this.setState(prevState => { let feature = { ...prevState.feature }; feature = selectedRole.feature; return { feature } });
        this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective = selectedRole.highLevelObjective; return { highLevelObjective } });
        this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective = selectedRole.lowLevelObjective; return { lowLevelObjective } });
        this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective = selectedRole.microLevelObjective; return { microLevelObjective } });
        this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank = selectedRole.questionBank; return { questionBank } });
        this.setState(prevState => { let blog = { ...prevState.blog }; blog = selectedRole.blog; return { blog } });
        this.setState(prevState => { let project = { ...prevState.project }; project = selectedRole.project; return { project } });
        this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution = selectedRole.distribution; return { distribution } });
        this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1 = selectedRole.dataAnalysis_1; return { dataAnalysis_1 } });
        this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2 = selectedRole.dataAnalysis_2; return { dataAnalysis_2 } });
        this.setState(prevState => { let email = { ...prevState.email }; email = selectedRole.email; return { email } });
        this.setState(prevState => { let user = { ...prevState.user }; user = selectedRole.user; return { user } });
        // this.setState(prevState => { let prm = { ...prevState.prm }; prm = selectedRole.prm; return { prm } });
        this.setState(prevState => { let survey = { ...prevState.survey }; survey = selectedRole.survey; return { survey } });
        this.setState(prevState => { let setting = { ...prevState.setting }; setting = selectedRole.setting; return { setting } });
        this.setState(prevState => { let contact = { ...prevState.contact }; contact = selectedRole.contact; return { contact } });
        this.setState(prevState => { let employee = { ...prevState.employee }; employee = selectedRole.employee; return { employee } });
        this.setState(prevState => { let designation = { ...prevState.designation }; designation = selectedRole.designation; return { designation } });
        this.setState(prevState => { let company = { ...prevState.company }; company = selectedRole.company; return { company } });
        this.setState(prevState => { let industry = { ...prevState.industry }; industry = selectedRole.industry; return { industry } });
        this.setState(prevState => { let branch = { ...prevState.branch }; branch = selectedRole.branch; return { branch } });
        this.setState(prevState => { let department = { ...prevState.department }; department = selectedRole.department; return { department } });
        this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory = selectedRole.brandCategory; return { brandCategory } });
        this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory = selectedRole.campaignCategory; return { campaignCategory } });
        this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory = selectedRole.productCategory; return { productCategory } });
        this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience = selectedRole.targetAudience; return { targetAudience } });
        this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign = selectedRole.campaign; return { campaign } });
        this.setState(prevState => { let brand = { ...prevState.brand }; brand = selectedRole.brand; return { brand } });
        this.setState(prevState => { let product = { ...prevState.product }; product = selectedRole.product; return { product } });
      })
  }

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.validator.allValid()) {
      const user = {
        "firstName": this.state.firstName,
        "lastName": this.state.lastName,
        "email": this.state.userEmail,
        "active": this.state.active,
        "companyProfileId": localStorage.getItem('jwtProfileId'),
        "roleInfo": {
          "roleId": this.state.roleId,
          "roleName": this.state.roleName,
          "rolePermission": {
            channel: this.state.channel,
            companyProfile: this.state.companyProfile,
            dashboard: this.state.dashboard,
            dataAnalysis_1: this.state.dataAnalysis_1,
            dataAnalysis_2: this.state.dataAnalysis_2,
            distribution: this.state.distribution,
            email: this.state.email,
            feature: this.state.feature,
            highLevelObjective: this.state.highLevelObjective,
            lowLevelObjective: this.state.lowLevelObjective,
            microLevelObjective: this.state.microLevelObjective,
            questionBank: this.state.questionBank,
            blog: this.state.blog,
            // prm: this.state.prm,
            project: this.state.project,
            user: this.state.user,
            survey: this.state.survey,
            setting: this.state.setting,
            contact: this.state.contact,
            employee: this.state.employee,
            designation: this.state.designation,
            company: this.state.company,
            industry: this.state.industry,
            branch: this.state.branch,
            department: this.state.department,
            brandCategory: this.state.brandCategory,
            productCategory: this.state.productCategory,
            campaign: this.state.campaign,
            brand: this.state.brand,
            product: this.state.product
          }
        }
      }
      axios.put(`${baseUrl}/api/login/${this.props.match.params.userId}`, user)
        .then(response => { this.openEasyModal(); this.setState({ edit: false }); this.UpdateToast() })
        .catch(error => alert('Something went wrong!'))
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
  }

  UpdateToast = () => showToast('Updated Successfully');

  render() {
    return (
      <div>
        {/* Breadcrumb */}
        <div className="d-flex mt-3">
          <h6>
            <span style={{ color: '#1B998B', cursor: 'pointer', marginLeft: '20px' }}
              onClick={() => { this.props.history.push('/app/users') }}>
              {`Users /`}
            </span>
            {` ${this.state.firstName}`}
          </h6>
        </div>

        <div style={{ marginLeft: '15px' }}>
          <Button
            size="sm" className="btnHover"
            style={{ margin: '10px 0px 10px 0px', background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
            onClick={() => { this.props.history.push('/app/users/'); }}>
            Back
          </Button>
          {
            !this.state.edit &&
            <Button
              size="sm" className="ml-2 btnHover"
              style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff', marginLeft: '-15px' }}
              onClick={() => this.setState({ edit: true })}>
              Edit
            </Button>
          }
        </div>

        {
          !this.state.edit &&
          <>
            <Body>
              <Label css={`margin-top:10px !important;`} style={{ fontSize: '1.2rem' }}>
                <h4>User Information</h4>
              </Label>
              <Aside>
                <section>
                  <Label>
                    <div css={`width: 20%;text-align:left;`}> Fisrt Name : </div>
                    <div css={`width: 70%;text-align:left;`}> {this.state.firstName} </div>
                  </Label>
                  <Label>
                    <div css={`width: 20%;text-align:left;`}> Last Name : </div>
                    <div css={`width: 70%;text-align:left;`}> {this.state.lastName} </div>
                  </Label>
                  <Label>
                    <div css={`width: 20%;text-align:left;`}> Email : </div>
                    <div css={`width: 70%;text-align:left;`}> {this.state.userEmail} </div>
                  </Label>
                  <Label>
                    <div css={`width: 20%;text-align:left;`}>Role : </div>
                    <div css={`width: 70%;text-align:left;`}> {this.state.roleName} </div>
                  </Label>
                </section>
                <section>

                </section>
              </Aside>

              <Label css={`margin-top:10px;`} style={{ fontSize: '1.2rem' }}> <h4>User Access</h4></Label>
              <Aside>
                <table >
                  <tbody>
                    <tr style={{ color: 'rgb(52, 58, 64' }}>
                      <th>Sections</th><th>View</th><th>Create</th><th>Update</th><th>Delete</th>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Dashboard</td>
                      <td><input type="checkbox" checked={this.state.dashboard.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dashboard.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dashboard.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dashboard.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Profile</td>
                      <td><input type="checkbox" checked={this.state.companyProfile.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.companyProfile.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.companyProfile.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.companyProfile.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Channel</td>
                      <td><input type="checkbox" checked={this.state.channel.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.channel.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.channel.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.channel.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Data Analysis Basic</td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_1.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_1.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_1.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_1.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Data Analysis Advance</td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_2.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_2.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_2.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.dataAnalysis_2.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Distribution</td>
                      <td><input type="checkbox" checked={this.state.distribution.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.distribution.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.distribution.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.distribution.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Email</td>
                      <td><input type="checkbox" checked={this.state.email.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.email.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.email.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.email.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Feature</td>
                      <td><input type="checkbox" checked={this.state.feature.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.feature.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.feature.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.feature.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>High Level Objective</td>
                      <td><input type="checkbox" checked={this.state.highLevelObjective.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.highLevelObjective.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.highLevelObjective.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.highLevelObjective.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Low Level Objective</td>
                      <td><input type="checkbox" checked={this.state.lowLevelObjective.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.lowLevelObjective.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.lowLevelObjective.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.lowLevelObjective.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Micro Level Objective</td>
                      <td><input type="checkbox" checked={this.state.microLevelObjective.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.microLevelObjective.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.microLevelObjective.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.microLevelObjective.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Question bank</td>
                      <td><input type="checkbox" checked={this.state.questionBank.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.questionBank.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.questionBank.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.questionBank.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Blog</td>
                      <td><input type="checkbox" checked={this.state.blog.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.blog.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.blog.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.blog.delete} disabled /></td>
                    </tr>
                    {/* <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>PRM</td>
                      <td><input type="checkbox" checked={this.state.prm.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.prm.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.prm.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.prm.delete} disabled /></td>
                    </tr> */}
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Project</td>
                      <td><input type="checkbox" checked={this.state.project.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.project.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.project.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.project.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>User</td>
                      <td><input type="checkbox" checked={this.state.user.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.user.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.user.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.user.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Survey</td>
                      <td><input type="checkbox" checked={this.state.survey.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.survey.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.survey.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.survey.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Setting</td>
                      <td><input type="checkbox" checked={this.state.setting.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.setting.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.setting.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.setting.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Contact</td>
                      <td><input type="checkbox" checked={this.state.contact.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.contact.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.contact.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.contact.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Employee</td>
                      <td><input type="checkbox" checked={this.state.employee.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.employee.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.employee.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.employee.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Designation</td>
                      <td><input type="checkbox" checked={this.state.designation.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.designation.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.designation.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.designation.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Company</td>
                      <td><input type="checkbox" checked={this.state.company.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.company.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.company.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.company.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Industry</td>
                      <td><input type="checkbox" checked={this.state.industry.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.industry.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.industry.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.industry.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Branch</td>
                      <td><input type="checkbox" checked={this.state.branch.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.branch.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.branch.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.branch.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Department</td>
                      <td><input type="checkbox" checked={this.state.department.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.department.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.department.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.department.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Brand category</td>
                      <td><input type="checkbox" checked={this.state.brandCategory.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brandCategory.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brandCategory.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brandCategory.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Product category</td>
                      <td><input type="checkbox" checked={this.state.productCategory.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.productCategory.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.productCategory.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.productCategory.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Target audience</td>
                      <td><input type="checkbox" checked={this.state.targetAudience.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.targetAudience.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.targetAudience.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.targetAudience.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Campaign</td>
                      <td><input type="checkbox" checked={this.state.campaign.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.campaign.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.campaign.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.campaign.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Brand</td>
                      <td><input type="checkbox" checked={this.state.brand.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brand.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brand.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.brand.delete} disabled /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Product</td>
                      <td><input type="checkbox" checked={this.state.product.view} disabled /></td>
                      <td><input type="checkbox" checked={this.state.product.create} disabled /></td>
                      <td><input type="checkbox" checked={this.state.product.edit} disabled /></td>
                      <td><input type="checkbox" checked={this.state.product.delete} disabled /></td>
                    </tr>
                  </tbody>
                </table>
              </Aside>
            </Body>
          </>
        }

        {
          this.state.edit &&
          <Body>
            <form onSubmit={this.handleSubmit} css={`width:100%;`}>
              <Label css={`margin-top:10px !important;`} style={{ fontSize: '1.2rem' }}>
                <h4>User Information</h4>
              </Label>
              <Aside>
                <section>
                  <Label>
                    <div css={`width: 30%;text-align:left;`}>
                      Fisrt Name:
                        </div>
                    <div css={`width: 60%`}>
                      <Input
                        name="firstName"
                        type="text"
                        value={this.state.firstName}
                        onChange={event => this.setState({ firstName: event.target.value })}
                      />
                      {this.validator.message('firstName', this.state.firstName, 'required')}
                    </div>
                  </Label>

                  <Label>
                    <div css={`width: 30%;text-align:left;`}>
                      Last Name:
                      </div>
                    <div css={`width:60%`}>
                      <Input
                        name="lastName"
                        type="text"
                        value={this.state.lastName}
                        onChange={event => this.setState({ lastName: event.target.value })}
                      />
                      {this.validator.message('lastName', this.state.lastName, 'required')}
                    </div>
                  </Label>

                  <Label>
                    <div css={`width: 30%;text-align:left;`}>
                      Active:
                        </div>
                    <div css={`width: 60%`} style={{ textAlign: 'left' }}>
                      <input
                        type="checkbox"
                        name="active"
                        checked={this.state.active}
                        onChange={(e) => { this.setState({ active: !this.state.active }); }} />
                    </div>
                  </Label>
                </section>


                <section>

                  <Label>
                    <div css={`width: 30%;text-align:left;`}>
                      Email:
                      </div>
                    <div css={`width: 60%`}>
                      <Input
                        name="userEmail"
                        type="text"
                        value={this.state.userEmail}
                        onChange={event => this.setState({ userEmail: event.target.value })}
                      />
                      {this.validator.message('email', this.state.userEmail, 'required|email')}
                    </div>
                  </Label>

                  <Label>
                    <div css={`width: 30%;text-align:left;`}>
                      Role:
                      </div>
                    <div css={`width: 60%`}>
                      <select value={this.state.roleId} onChange={this.handleRolePermission} style={{ fontFamily: 'Roboto', boxSizing: 'border-box', width: '100%', padding: '2px 30px', border: 'none', outline: 'none', boxShadow: '1px 1px 3px 1px rgb(52, 58, 64)', background: 'white' }}>
                        {this.state.Type.map(type => <option value={type._id} key={type._id}>{type.roleName}</option>)}
                      </select>
                      {this.validator.message('roleId', this.state.roleId, 'required')}
                    </div>
                  </Label>
                </section>
              </Aside>

              <Label css={`margin-top:10px;`}> <h4 style={{ fontSize: '1.2rem' }}>User Access</h4></Label>
              <Aside>
                <table >
                  <tbody>
                    <tr style={{ color: 'rgb(52, 58, 64' }}>
                      <th>Sections</th><th>View</th><th>Create</th><th>Update</th><th>Delete</th>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Dashboard</td>
                      <td><input type="checkbox" name="dashboardview" checked={this.state.dashboard.view} onChange={(e) => { this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard.view = !dashboard.view; return { dashboard } }); }} /></td>
                      <td><input type="checkbox" name="dashboardcreate" checked={this.state.dashboard.create} onChange={(e) => { this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard.create = !dashboard.create; return { dashboard } }); }} /></td>
                      <td><input type="checkbox" name="dashboardedit" checked={this.state.dashboard.edit} onChange={(e) => { this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard.edit = !dashboard.edit; return { dashboard } }); }} /></td>
                      <td><input type="checkbox" name="dashboarddelete" checked={this.state.dashboard.delete} onChange={(e) => { this.setState(prevState => { let dashboard = { ...prevState.dashboard }; dashboard.delete = !dashboard.delete; return { dashboard } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Profile</td><td><input type="checkbox" name="companyProfileview" checked={this.state.companyProfile.view} onChange={(e) => { this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile.view = !companyProfile.view; return { companyProfile } }); }} /></td>
                      <td><input type="checkbox" name="companyProfilecreate" checked={this.state.companyProfile.create} onChange={(e) => { this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile.create = !companyProfile.create; return { companyProfile } }); }} /></td>
                      <td><input type="checkbox" name="companyProfileedit" checked={this.state.companyProfile.edit} onChange={(e) => { this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile.edit = !companyProfile.edit; return { companyProfile } }); }} /></td>
                      <td><input type="checkbox" name="companyProfiledelete" checked={this.state.companyProfile.delete} onChange={(e) => { this.setState(prevState => { let companyProfile = { ...prevState.companyProfile }; companyProfile.delete = !companyProfile.delete; return { companyProfile } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Channel</td>
                      <td><input type="checkbox" name="channelview" checked={this.state.channel.view} onChange={(e) => { this.setState(prevState => { let channel = { ...prevState.channel }; channel.view = !channel.view; return { channel } }); }} /></td>
                      <td><input type="checkbox" name="channelcreate" checked={this.state.channel.create} onChange={(e) => { this.setState(prevState => { let channel = { ...prevState.channel }; channel.create = !channel.create; return { channel } }); }} /></td>
                      <td><input type="checkbox" name="channeledit" checked={this.state.channel.edit} onChange={(e) => { this.setState(prevState => { let channel = { ...prevState.channel }; channel.edit = !channel.edit; return { channel } }); }} /></td>
                      <td><input type="checkbox" name="channeldelete" checked={this.state.channel.delete} onChange={(e) => { this.setState(prevState => { let channel = { ...prevState.channel }; channel.delete = !channel.delete; return { channel } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Feature</td>
                      <td><input type="checkbox" name="featureview" checked={this.state.feature.view} onChange={(e) => { this.setState(prevState => { let feature = { ...prevState.feature }; feature.view = !feature.view; return { feature } }); }} /></td>
                      <td><input type="checkbox" name="featurecreate" checked={this.state.feature.create} onChange={(e) => { this.setState(prevState => { let feature = { ...prevState.feature }; feature.create = !feature.create; return { feature } }); }} /></td>
                      <td><input type="checkbox" name="featureedit" checked={this.state.feature.edit} onChange={(e) => { this.setState(prevState => { let feature = { ...prevState.feature }; feature.edit = !feature.edit; return { feature } }); }} /></td>
                      <td><input type="checkbox" name="featuredelete" checked={this.state.feature.delete} onChange={(e) => { this.setState(prevState => { let feature = { ...prevState.feature }; feature.delete = !feature.delete; return { feature } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>High Level Objective</td>
                      <td><input type="checkbox" name="highLevelObjectiveview" checked={this.state.highLevelObjective.view} onChange={(e) => { this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective.view = !highLevelObjective.view; return { highLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="highLevelObjectivecreate" checked={this.state.highLevelObjective.create} onChange={(e) => { this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective.create = !highLevelObjective.create; return { highLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="highLevelObjectiveedit" checked={this.state.highLevelObjective.edit} onChange={(e) => { this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective.edit = !highLevelObjective.edit; return { highLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="highLevelObjectivedelete" checked={this.state.highLevelObjective.delete} onChange={(e) => { this.setState(prevState => { let highLevelObjective = { ...prevState.highLevelObjective }; highLevelObjective.delete = !highLevelObjective.delete; return { highLevelObjective } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Low Level Objective</td>
                      <td><input type="checkbox" name="lowLevelObjectiveview" checked={this.state.lowLevelObjective.view} onChange={(e) => { this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective.view = !lowLevelObjective.view; return { lowLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="lowLevelObjectivecreate" checked={this.state.lowLevelObjective.create} onChange={(e) => { this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective.create = !lowLevelObjective.create; return { lowLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="lowLevelObjectiveedit" checked={this.state.lowLevelObjective.edit} onChange={(e) => { this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective.edit = !lowLevelObjective.edit; return { lowLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="lowLevelObjectivedelete" checked={this.state.lowLevelObjective.delete} onChange={(e) => { this.setState(prevState => { let lowLevelObjective = { ...prevState.lowLevelObjective }; lowLevelObjective.delete = !lowLevelObjective.delete; return { lowLevelObjective } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Micro Level Objective</td>
                      <td><input type="checkbox" name="microObjectiveview" checked={this.state.microLevelObjective.view} onChange={(e) => { this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective.view = !microLevelObjective.view; return { microLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="microObjectivecreate" checked={this.state.microLevelObjective.create} onChange={(e) => { this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective.create = !microLevelObjective.create; return { microLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="microObjectiveedit" checked={this.state.microLevelObjective.edit} onChange={(e) => { this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective.edit = !microLevelObjective.edit; return { microLevelObjective } }); }} /></td>
                      <td><input type="checkbox" name="microObjectivedelete" checked={this.state.microLevelObjective.delete} onChange={(e) => { this.setState(prevState => { let microLevelObjective = { ...prevState.microLevelObjective }; microLevelObjective.delete = !microLevelObjective.delete; return { microLevelObjective } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Question bank</td>
                      <td><input type="checkbox" name="questionBankview" checked={this.state.questionBank.view} onChange={(e) => { this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank.view = !questionBank.view; return { questionBank } }); }} /></td>
                      <td><input type="checkbox" name="questionBankcreate" checked={this.state.questionBank.create} onChange={(e) => { this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank.create = !questionBank.create; return { questionBank } }); }} /></td>
                      <td><input type="checkbox" name="questionBankedit" checked={this.state.questionBank.edit} onChange={(e) => { this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank.edit = !questionBank.edit; return { questionBank } }); }} /></td>
                      <td><input type="checkbox" name="questionBankdelete" checked={this.state.questionBank.delete} onChange={(e) => { this.setState(prevState => { let questionBank = { ...prevState.questionBank }; questionBank.delete = !questionBank.delete; return { questionBank } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Blog</td>
                      <td><input type="checkbox" name="blogview" checked={this.state.blog.view} onChange={(e) => { this.setState(prevState => { let blog = { ...prevState.blog }; blog.view = !blog.view; return { blog } }); }} /></td>
                      <td><input type="checkbox" name="blogcreate" checked={this.state.blog.create} onChange={(e) => { this.setState(prevState => { let blog = { ...prevState.blog }; blog.create = !blog.create; return { blog } }); }} /></td>
                      <td><input type="checkbox" name="blogedit" checked={this.state.blog.edit} onChange={(e) => { this.setState(prevState => { let blog = { ...prevState.blog }; blog.edit = !blog.edit; return { blog } }); }} /></td>
                      <td><input type="checkbox" name="blogdelete" checked={this.state.blog.delete} onChange={(e) => { this.setState(prevState => { let blog = { ...prevState.blog }; blog.delete = !blog.delete; return { blog } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Project</td>
                      <td><input type="checkbox" name="projectview" checked={this.state.project.view} onChange={(e) => { this.setState(prevState => { let project = { ...prevState.project }; project.view = !project.view; return { project } }); }} /></td>
                      <td><input type="checkbox" name="projectcreate" checked={this.state.project.create} onChange={(e) => { this.setState(prevState => { let project = { ...prevState.project }; project.create = !project.create; return { project } }); }} /></td>
                      <td><input type="checkbox" name="projectedit" checked={this.state.project.edit} onChange={(e) => { this.setState(prevState => { let project = { ...prevState.project }; project.edit = !project.edit; return { project } }); }} /></td>
                      <td><input type="checkbox" name="projectdelete" checked={this.state.project.delete} onChange={(e) => { this.setState(prevState => { let project = { ...prevState.project }; project.delete = !project.delete; return { project } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Distribution</td>
                      <td><input type="checkbox" name="distributionview" checked={this.state.distribution.view} onChange={(e) => { this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution.view = !distribution.view; return { distribution } }); }} /></td>
                      <td><input type="checkbox" name="distributioncreate" checked={this.state.distribution.create} onChange={(e) => { this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution.create = !distribution.create; return { distribution } }); }} /></td>
                      <td><input type="checkbox" name="distributionedit" checked={this.state.distribution.edit} onChange={(e) => { this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution.edit = !distribution.edit; return { distribution } }); }} /></td>
                      <td><input type="checkbox" name="distributiondelete" checked={this.state.distribution.delete} onChange={(e) => { this.setState(prevState => { let distribution = { ...prevState.distribution }; distribution.delete = !distribution.delete; return { distribution } }); }} /></td>
                    </tr>

                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Data Analysis Basic</td>
                      <td><input type="checkbox" name="dataAnalysis_1view" checked={this.state.dataAnalysis_1.view} onChange={(e) => { this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1.view = !dataAnalysis_1.view; return { dataAnalysis_1 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_1create" checked={this.state.dataAnalysis_1.create} onChange={(e) => { this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1.create = !dataAnalysis_1.create; return { dataAnalysis_1 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_1edit" checked={this.state.dataAnalysis_1.edit} onChange={(e) => { this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1.edit = !dataAnalysis_1.edit; return { dataAnalysis_1 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_1delete" checked={this.state.dataAnalysis_1.delete} onChange={(e) => { this.setState(prevState => { let dataAnalysis_1 = { ...prevState.dataAnalysis_1 }; dataAnalysis_1.delete = !dataAnalysis_1.delete; return { dataAnalysis_1 } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Data Analysis Advance</td>
                      <td><input type="checkbox" name="dataAnalysis_2view" checked={this.state.dataAnalysis_2.view} onChange={(e) => { this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2.view = !dataAnalysis_2.view; return { dataAnalysis_2 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_2create" checked={this.state.dataAnalysis_2.create} onChange={(e) => { this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2.create = !dataAnalysis_2.create; return { dataAnalysis_2 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_2edit" checked={this.state.dataAnalysis_2.edit} onChange={(e) => { this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2.edit = !dataAnalysis_2.edit; return { dataAnalysis_2 } }); }} /></td>
                      <td><input type="checkbox" name="dataAnalysis_2delete" checked={this.state.dataAnalysis_2.delete} onChange={(e) => { this.setState(prevState => { let dataAnalysis_2 = { ...prevState.dataAnalysis_2 }; dataAnalysis_2.delete = !dataAnalysis_2.delete; return { dataAnalysis_2 } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Email</td>
                      <td><input type="checkbox" name="emailview" checked={this.state.email.view} onChange={(e) => { this.setState(prevState => { let email = { ...prevState.email }; email.view = !email.view; return { email } }); }} /></td>
                      <td><input type="checkbox" name="emailcreate" checked={this.state.email.create} onChange={(e) => { this.setState(prevState => { let email = { ...prevState.email }; email.create = !email.create; return { email } }); }} /></td>
                      <td><input type="checkbox" name="emailedit" checked={this.state.email.edit} onChange={(e) => { this.setState(prevState => { let email = { ...prevState.email }; email.edit = !email.edit; return { email } }); }} /></td>
                      <td><input type="checkbox" name="emaildelete" checked={this.state.email.delete} onChange={(e) => { this.setState(prevState => { let email = { ...prevState.email }; email.delete = !email.delete; return { email } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>User</td>
                      <td><input type="checkbox" name="userview" checked={this.state.user.view} onChange={(e) => { this.setState(prevState => { let user = { ...prevState.user }; user.view = !user.view; return { user } }); }} /></td>
                      <td><input type="checkbox" name="usercreate" checked={this.state.user.create} onChange={(e) => { this.setState(prevState => { let user = { ...prevState.user }; user.create = !user.create; return { user } }); }} /></td>
                      <td><input type="checkbox" name="useredit" checked={this.state.user.edit} onChange={(e) => { this.setState(prevState => { let user = { ...prevState.user }; user.edit = !user.edit; return { user } }); }} /></td>
                      <td><input type="checkbox" name="userdelete" checked={this.state.user.delete} onChange={(e) => { this.setState(prevState => { let user = { ...prevState.user }; user.delete = !user.delete; return { user } }); }} /></td>
                    </tr>
                    {/* <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>PRM</td>
                      <td><input type="checkbox" name="prmview" checked={this.state.prm.view} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.view = !prm.view; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmcreate" checked={this.state.prm.create} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.create = !prm.create; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmedit" checked={this.state.prm.edit} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.edit = !prm.edit; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmdelete" checked={this.state.prm.delete} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.delete = !prm.delete; return { prm } }); }} /></td>
                    </tr> */}
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Survey</td>
                      <td><input type="checkbox" name="surveyview" checked={this.state.survey.view} onChange={(e) => { this.setState(prevState => { let survey = { ...prevState.survey }; survey.view = !survey.view; return { survey } }); }} /></td>
                      <td><input type="checkbox" name="surveycreate" checked={this.state.survey.create} onChange={(e) => { this.setState(prevState => { let survey = { ...prevState.survey }; survey.create = !survey.create; return { survey } }); }} /></td>
                      <td><input type="checkbox" name="surveyedit" checked={this.state.survey.edit} onChange={(e) => { this.setState(prevState => { let survey = { ...prevState.survey }; survey.edit = !survey.edit; return { survey } }); }} /></td>
                      <td><input type="checkbox" name="surveydelete" checked={this.state.survey.delete} onChange={(e) => { this.setState(prevState => { let survey = { ...prevState.survey }; survey.delete = !survey.delete; return { survey } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Setting</td>
                      <td><input type="checkbox" name="settingview" checked={this.state.setting.view} onChange={(e) => { this.setState(prevState => { let setting = { ...prevState.setting }; setting.view = !setting.view; return { setting } }); }} /></td>
                      <td><input type="checkbox" name="settingcreate" checked={this.state.setting.create} onChange={(e) => { this.setState(prevState => { let setting = { ...prevState.setting }; setting.create = !setting.create; return { setting } }); }} /></td>
                      <td><input type="checkbox" name="settingedit" checked={this.state.setting.edit} onChange={(e) => { this.setState(prevState => { let setting = { ...prevState.setting }; setting.edit = !setting.edit; return { setting } }); }} /></td>
                      <td><input type="checkbox" name="settingdelete" checked={this.state.setting.delete} onChange={(e) => { this.setState(prevState => { let setting = { ...prevState.setting }; setting.delete = !setting.delete; return { setting } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Contact</td>
                      <td><input type="checkbox" name="contactview" checked={this.state.contact.view} onChange={(e) => { this.setState(prevState => { let contact = { ...prevState.contact }; contact.view = !contact.view; return { contact } }); }} /></td>
                      <td><input type="checkbox" name="contactcreate" checked={this.state.contact.create} onChange={(e) => { this.setState(prevState => { let contact = { ...prevState.contact }; contact.create = !contact.create; return { contact } }); }} /></td>
                      <td><input type="checkbox" name="contactedit" checked={this.state.contact.edit} onChange={(e) => { this.setState(prevState => { let contact = { ...prevState.contact }; contact.edit = !contact.edit; return { contact } }); }} /></td>
                      <td><input type="checkbox" name="contactdelete" checked={this.state.contact.delete} onChange={(e) => { this.setState(prevState => { let contact = { ...prevState.contact }; contact.delete = !contact.delete; return { contact } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Employee</td>
                      <td><input type="checkbox" name="employeeview" checked={this.state.employee.view} onChange={(e) => { this.setState(prevState => { let employee = { ...prevState.employee }; employee.view = !employee.view; return { employee } }); }} /></td>
                      <td><input type="checkbox" name="employeecreate" checked={this.state.employee.create} onChange={(e) => { this.setState(prevState => { let employee = { ...prevState.employee }; employee.create = !employee.create; return { employee } }); }} /></td>
                      <td><input type="checkbox" name="employeeedit" checked={this.state.employee.edit} onChange={(e) => { this.setState(prevState => { let employee = { ...prevState.employee }; employee.edit = !employee.edit; return { employee } }); }} /></td>
                      <td><input type="checkbox" name="employeedelete" checked={this.state.employee.delete} onChange={(e) => { this.setState(prevState => { let employee = { ...prevState.employee }; employee.delete = !employee.delete; return { employee } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Designation</td>
                      <td><input type="checkbox" name="designationview" checked={this.state.designation.view} onChange={(e) => { this.setState(prevState => { let designation = { ...prevState.designation }; designation.view = !designation.view; return { designation } }); }} /></td>
                      <td><input type="checkbox" name="designationcreate" checked={this.state.designation.create} onChange={(e) => { this.setState(prevState => { let designation = { ...prevState.designation }; designation.create = !designation.create; return { designation } }); }} /></td>
                      <td><input type="checkbox" name="designationedit" checked={this.state.designation.edit} onChange={(e) => { this.setState(prevState => { let designation = { ...prevState.designation }; designation.edit = !designation.edit; return { designation } }); }} /></td>
                      <td><input type="checkbox" name="designationdelete" checked={this.state.designation.delete} onChange={(e) => { this.setState(prevState => { let designation = { ...prevState.designation }; designation.delete = !designation.delete; return { designation } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Company</td>
                      <td><input type="checkbox" name="companyview" checked={this.state.company.view} onChange={(e) => { this.setState(prevState => { let company = { ...prevState.company }; company.view = !company.view; return { company } }); }} /></td>
                      <td><input type="checkbox" name="companycreate" checked={this.state.company.create} onChange={(e) => { this.setState(prevState => { let company = { ...prevState.company }; company.create = !company.create; return { company } }); }} /></td>
                      <td><input type="checkbox" name="companyedit" checked={this.state.company.edit} onChange={(e) => { this.setState(prevState => { let company = { ...prevState.company }; company.edit = !company.edit; return { company } }); }} /></td>
                      <td><input type="checkbox" name="companydelete" checked={this.state.company.delete} onChange={(e) => { this.setState(prevState => { let company = { ...prevState.company }; company.delete = !company.delete; return { company } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Industry</td>
                      <td><input type="checkbox" name="industryview" checked={this.state.industry.view} onChange={(e) => { this.setState(prevState => { let industry = { ...prevState.industry }; industry.view = !industry.view; return { industry } }); }} /></td>
                      <td><input type="checkbox" name="industrycreate" checked={this.state.industry.create} onChange={(e) => { this.setState(prevState => { let industry = { ...prevState.industry }; industry.create = !industry.create; return { industry } }); }} /></td>
                      <td><input type="checkbox" name="industryedit" checked={this.state.industry.edit} onChange={(e) => { this.setState(prevState => { let industry = { ...prevState.industry }; industry.edit = !industry.edit; return { industry } }); }} /></td>
                      <td><input type="checkbox" name="industrydelete" checked={this.state.industry.delete} onChange={(e) => { this.setState(prevState => { let industry = { ...prevState.industry }; industry.delete = !industry.delete; return { industry } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Branch</td>
                      <td><input type="checkbox" name="branchview" checked={this.state.branch.view} onChange={(e) => { this.setState(prevState => { let branch = { ...prevState.branch }; branch.view = !branch.view; return { branch } }); }} /></td>
                      <td><input type="checkbox" name="branchcreate" checked={this.state.branch.create} onChange={(e) => { this.setState(prevState => { let branch = { ...prevState.branch }; branch.create = !branch.create; return { branch } }); }} /></td>
                      <td><input type="checkbox" name="branchedit" checked={this.state.branch.edit} onChange={(e) => { this.setState(prevState => { let branch = { ...prevState.branch }; branch.edit = !branch.edit; return { branch } }); }} /></td>
                      <td><input type="checkbox" name="branchdelete" checked={this.state.branch.delete} onChange={(e) => { this.setState(prevState => { let branch = { ...prevState.branch }; branch.delete = !branch.delete; return { branch } }); }} /></td>
                    </tr>
                    {/* <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>PRM</td>
                      <td><input type="checkbox" name="prmview" checked={this.state.prm.view} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.view = !prm.view; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmcreate" checked={this.state.prm.create} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.create = !prm.create; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmedit" checked={this.state.prm.edit} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.edit = !prm.edit; return { prm } }); }} /></td>
                      <td><input type="checkbox" name="prmdelete" checked={this.state.prm.delete} onChange={(e) => { this.setState(prevState => { let prm = { ...prevState.prm }; prm.delete = !prm.delete; return { prm } }); }} /></td>
                    </tr> */}
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Department</td>
                      <td><input type="checkbox" name="departmentview" checked={this.state.department.view} onChange={(e) => { this.setState(prevState => { let department = { ...prevState.department }; department.view = !department.view; return { department } }); }} /></td>
                      <td><input type="checkbox" name="departmentcreate" checked={this.state.department.create} onChange={(e) => { this.setState(prevState => { let department = { ...prevState.department }; department.create = !department.create; return { department } }); }} /></td>
                      <td><input type="checkbox" name="departmentedit" checked={this.state.department.edit} onChange={(e) => { this.setState(prevState => { let department = { ...prevState.department }; department.edit = !department.edit; return { department } }); }} /></td>
                      <td><input type="checkbox" name="departmentdelete" checked={this.state.department.delete} onChange={(e) => { this.setState(prevState => { let department = { ...prevState.department }; department.delete = !department.delete; return { department } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Brand category</td>
                      <td><input type="checkbox" name="brandCatagoryview" checked={this.state.brandCategory.view} onChange={(e) => { this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory.view = !brandCategory.view; return { brandCategory } }); }} /></td>
                      <td><input type="checkbox" name="brandCatagorycreate" checked={this.state.brandCategory.create} onChange={(e) => { this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory.create = !brandCategory.create; return { brandCategory } }); }} /></td>
                      <td><input type="checkbox" name="brandCatagoryedit" checked={this.state.brandCategory.edit} onChange={(e) => { this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory.edit = !brandCategory.edit; return { brandCategory } }); }} /></td>
                      <td><input type="checkbox" name="brandCatagorydelete" checked={this.state.brandCategory.delete} onChange={(e) => { this.setState(prevState => { let brandCategory = { ...prevState.brandCategory }; brandCategory.delete = !brandCategory.delete; return { brandCategory } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Campaign category</td>
                      <td><input type="checkbox" name="campaignCategoryview" checked={this.state.campaignCategory.view} onChange={(e) => { this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory.view = !campaignCategory.view; return { campaignCategory } }); }} /></td>
                      <td><input type="checkbox" name="campaignCategorycreate" checked={this.state.campaignCategory.create} onChange={(e) => { this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory.create = !campaignCategory.create; return { campaignCategory } }); }} /></td>
                      <td><input type="checkbox" name="campaignCategoryedit" checked={this.state.campaignCategory.edit} onChange={(e) => { this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory.edit = !campaignCategory.edit; return { campaignCategory } }); }} /></td>
                      <td><input type="checkbox" name="campaignCategorydelete" checked={this.state.campaignCategory.delete} onChange={(e) => { this.setState(prevState => { let campaignCategory = { ...prevState.campaignCategory }; campaignCategory.delete = !campaignCategory.delete; return { campaignCategory } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Product category</td>
                      <td><input type="checkbox" name="productCategoryview" checked={this.state.productCategory.view} onChange={(e) => { this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory.view = !productCategory.view; return { productCategory } }); }} /></td>
                      <td><input type="checkbox" name="productCategorycreate" checked={this.state.productCategory.create} onChange={(e) => { this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory.create = !productCategory.create; return { productCategory } }); }} /></td>
                      <td><input type="checkbox" name="productCategoryedit" checked={this.state.productCategory.edit} onChange={(e) => { this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory.edit = !productCategory.edit; return { productCategory } }); }} /></td>
                      <td><input type="checkbox" name="productCategorydelete" checked={this.state.productCategory.delete} onChange={(e) => { this.setState(prevState => { let productCategory = { ...prevState.productCategory }; productCategory.delete = !productCategory.delete; return { productCategory } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Target audience</td>
                      <td><input type="checkbox" name="targetAudienceview" checked={this.state.targetAudience.view} onChange={(e) => { this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience.view = !targetAudience.view; return { targetAudience } }); }} /></td>
                      <td><input type="checkbox" name="targetAudiencecreate" checked={this.state.targetAudience.create} onChange={(e) => { this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience.create = !targetAudience.create; return { targetAudience } }); }} /></td>
                      <td><input type="checkbox" name="targetAudienceedit" checked={this.state.targetAudience.edit} onChange={(e) => { this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience.edit = !targetAudience.edit; return { targetAudience } }); }} /></td>
                      <td><input type="checkbox" name="targetAudiencedelete" checked={this.state.targetAudience.delete} onChange={(e) => { this.setState(prevState => { let targetAudience = { ...prevState.targetAudience }; targetAudience.delete = !targetAudience.delete; return { targetAudience } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Campaign</td>
                      <td><input type="checkbox" name="campaignview" checked={this.state.campaign.view} onChange={(e) => { this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign.view = !campaign.view; return { campaign } }); }} /></td>
                      <td><input type="checkbox" name="campaigncreate" checked={this.state.campaign.create} onChange={(e) => { this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign.create = !campaign.create; return { campaign } }); }} /></td>
                      <td><input type="checkbox" name="campaignedit" checked={this.state.campaign.edit} onChange={(e) => { this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign.edit = !campaign.edit; return { campaign } }); }} /></td>
                      <td><input type="checkbox" name="campaigndelete" checked={this.state.campaign.delete} onChange={(e) => { this.setState(prevState => { let campaign = { ...prevState.campaign }; campaign.delete = !campaign.delete; return { campaign } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Brand</td>
                      <td><input type="checkbox" name="brandview" checked={this.state.brand.view} onChange={(e) => { this.setState(prevState => { let brand = { ...prevState.brand }; brand.view = !brand.view; return { brand } }); }} /></td>
                      <td><input type="checkbox" name="brandcreate" checked={this.state.brand.create} onChange={(e) => { this.setState(prevState => { let brand = { ...prevState.brand }; brand.create = !brand.create; return { brand } }); }} /></td>
                      <td><input type="checkbox" name="brandedit" checked={this.state.brand.edit} onChange={(e) => { this.setState(prevState => { let brand = { ...prevState.brand }; brand.edit = !brand.edit; return { brand } }); }} /></td>
                      <td><input type="checkbox" name="branddelete" checked={this.state.brand.delete} onChange={(e) => { this.setState(prevState => { let brand = { ...prevState.brand }; brand.delete = !brand.delete; return { brand } }); }} /></td>
                    </tr>
                    <tr>
                      <td style={{ color: 'rgb(52, 58, 64' }}>Product</td>
                      <td><input type="checkbox" name="productview" checked={this.state.product.view} onChange={(e) => { this.setState(prevState => { let product = { ...prevState.product }; product.view = !product.view; return { product } }); }} /></td>
                      <td><input type="checkbox" name="productcreate" checked={this.state.product.create} onChange={(e) => { this.setState(prevState => { let product = { ...prevState.product }; product.create = !product.create; return { product } }); }} /></td>
                      <td><input type="checkbox" name="productedit" checked={this.state.product.edit} onChange={(e) => { this.setState(prevState => { let product = { ...prevState.product }; product.edit = !product.edit; return { product } }); }} /></td>
                      <td><input type="checkbox" name="productdelete" checked={this.state.product.delete} onChange={(e) => { this.setState(prevState => { let product = { ...prevState.product }; product.delete = !product.delete; return { product } }); }} /></td>
                    </tr>
                  </tbody>
                </table>
              </Aside>

              <Button
                type="submit" size="sm" className="btnHover"
                style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}>
                Save
              </Button>
            </form>
          </Body>
        }
      </div>
    )
  }
}

export default Individual
