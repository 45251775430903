import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setName } from '../../../redux/actions'
import './style.css';

const SelectProjects = props => {
  // const [name, setName] = useState('');

  return (
    <label className="dislabel">
      Distribution Name:
        <input
        className="inputname"
        type="text"
        value={props.name}
        required
        onChange={
          // e => { setName(e.target.value);
          event => props.setName(event.target.value)
        }
      />
    </label>
  )

}

const mapStateToProps = state => {
  return { name: state.question.name }
}

export default connect(mapStateToProps, { setName })(SelectProjects);