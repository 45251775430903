import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

export const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  
`
export const Banner = styled.div`
  disply: flex;
  width: 100%;
  height: 45px;
  color: white;
  margin: 10px;
  text-align: center;
  background-color: rgb(52,58,64);
  padding-top: 15px;
  font-weight: bold;
`

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  border-sizing: border-box;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
`

export const ProjectItem = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;  
  flex-basis: 25%;
  padding: 10px;
  height: 200px;
  cursor: pointer;
  @media only screen and (max-width: 768px){
    flex-basis: 50%;
  }
  
  @media only screen and (max-width: 425px){
    flex-basis: 100%;
  }
`
export const Project = styled.div`
display: flex;
border:none !important;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
background-color: white;
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
transition: all 0.3s cubic-bezier(.25,.8,.25,1);
>div {
  width:100%;
  height: 50%;
  color: white;
  flex-direction: column;
  font-style: oblique;
}
${ProjectItem}:hover &{
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
@media only screen and (max-width: 768px){
  font-size:15px;
}
`
export const Button = styled.button`
  display: block;
  margin: 15px auto;
  box-sizing: border-box;
  text-decoration: none;
  color: #384363;
  border: none;
  background-color: #AEC6CF;
  height: 30px;
  padding: 5px 10px;
  text-align: center;
  color: #C42590;
  box-shadow: 1px 1px 5px -1px #586469;
  outline: none;
  :hover{
    cursor: pointer;
    background-color: #8DA2A8;
    box-shadow: 1px 1px 5px 2px #586469;
  }
`