import React from "react";
import { css } from "@emotion/core";
import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import './style.css'
import styled from 'styled-components'
import { Animated } from "react-animated-css";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const TextCenter = styled.p`
  text-align: center;
`;

class AwesomeComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.channelType === "device")
                this.setState({ loading: true })
        }, 2000)
        setTimeout(() => {
            if (this.props.channelType === "openLink" || this.props.channelType === "qrCode")
                if (this.props.redirectUrl && this.props.redirectUrl !== "") {
                    if (this.props.redirectUrl.includes("http") || this.props.redirectUrl.includes("https")) {
                        window.location.replace(this.props.redirectUrl);
                    } else {
                        window.location.replace("//" + this.props.redirectUrl)
                    }
                }
        }, 2000)
        // console.log(this.props.channelType)
    }
    // if(props.chann)
    render() {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                boxSizing: 'border-box',
                position: 'relative',
                justifyContent: 'center',
                alignItems: 'center',
                // border: 2px solid #F2F2F2;
                margin: '0 10px',
                height: 'calc(100% - 20px)',
                borderRadius: '5px',
                padding: '20px'
            }}>

                {!this.state.loading && <React.Fragment>
                    <Animated animationIn="headShake" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000} isVisible={true}>
                        <FontAwesomeIcon
                            icon={faThumbsUp}
                            color="rgb(27, 153, 139)"
                            style={{ fontSize: '75px', }}
                        />
                    </Animated>
                    <TextCenter>Thank you!</TextCenter>

                </React.Fragment>}

                {this.state.loading && <div className="sweet-loading">
                    <p style={{ fontSize: '1.5em', textAlign: 'center', margin: '0 auto' }}>Loading next survey.</p>
                    <BeatLoader
                        css={override}
                        size={20}
                        //size={"150px"} this also works
                        color="rgb(27, 153, 139)"
                        loading={this.state.loading}
                    />
                </div>}

            </div>
        );
    }
}

export default AwesomeComponent