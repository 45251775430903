import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb';
import { Button } from 'reactstrap';
import baseUrl from '../../url';
import showToast from '../../components/Toast/toast';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const MailConfig = () => {
  const [formData, setFormData] = React.useState({
    name: '',
    smtpHost: '',
    smtpPort: '',
    smtpUser: '',
    smtpPass: '',
    smtpEncryption: '',
    active: true,
  });
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchMailConfig = async () => {
      try {
        const res = await axios.get(
          `${baseUrl}/api/mailServerConfig/${localStorage.getItem(
            'jwtProfileId'
          )}`
        );

        const { data } = res;

        if (data) {
          setFormData({
            name: data.name,
            smtpHost: data.smtpHost,
            smtpPort: data.smtpPort,
            smtpUser: data.smtpUser,
            smtpPass: data.smtpPass,
            smtpEncryption: data.smtpEncryption,
            active: data.active,
          });
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    fetchMailConfig();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const res = await axios.post(`${baseUrl}/api/mailServerConfig`, {
        ...formData,
        smtpPort: Number(formData.smtpPort),
        profileId: localStorage.getItem('jwtProfileId'),
      });

      const { data } = res;

      if (data.nModified === 1) {
        showToast('Mail configuration updated successfully');
      } else {
        showToast('Mail configuration added successfully');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='container py-4'>
      <Breadcrumb path='Mail Config /' />

      <form onSubmit={handleSubmit} className='mt-4'>
        <Grid>
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              className='form-control'
              id='name'
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
              value={formData.name}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='security'>Connection Security</label>
            <select
              className='form-control'
              id='security'
              onChange={(e) =>
                setFormData({ ...formData, smtpEncryption: e.target.value })
              }
              value={formData.smtpEncryption}
            >
              <option value=''>Select</option>
              <option value='auto'>AUTO</option>
              <option value='none'>NONE</option>
              <option value='ssl'>SSL</option>
              <option value='tls'>TLS</option>
              <option value='starttls'>STARTTLS</option>
            </select>
          </div>

          <div className='form-group'>
            <label htmlFor='server'>SMTP Server</label>
            <input
              type='text'
              className='form-control'
              id='server'
              onChange={(e) =>
                setFormData({ ...formData, smtpHost: e.target.value })
              }
              value={formData.smtpHost}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='port'>Port</label>
            <input
              type='text'
              className='form-control'
              id='port'
              onChange={(e) =>
                setFormData({ ...formData, smtpPort: e.target.value })
              }
              value={formData.smtpPort}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='email'>Email Address</label>
            <input
              type='email'
              className='form-control'
              id='email'
              onChange={(e) =>
                setFormData({ ...formData, smtpUser: e.target.value })
              }
              value={formData.smtpUser}
            />
          </div>

          <div className='form-group'>
            <label htmlFor='password'>Password</label>
            <input
              type='password'
              className='form-control'
              id='password'
              onChange={(e) =>
                setFormData({ ...formData, smtpPass: e.target.value })
              }
              value={formData.smtpPass}
            />
          </div>

          <div className='form-group'>
            <label
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                cursor: 'pointer',
              }}
            >
              <input
                type='checkbox'
                style={{ width: '16px', height: '16px' }}
                onChange={(e) =>
                  setFormData({ ...formData, active: e.target.checked })
                }
                checked={formData.active}
              />
              <span style={{ marginBottom: '-3px' }}>Active</span>
            </label>
          </div>
        </Grid>

        <Button
          className='btnHover mt-1 px-5'
          style={{ background: '#1B998B', borderColor: '#1B998B' }}
          type='submit'
          disabled={loading}
        >
          Save
        </Button>
      </form>
    </div>
  );
};

export default MailConfig;
