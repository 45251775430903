import React from 'react'
import { withRouter, NavLink } from 'react-router-dom'
import NavBar from '../LandingPage/components/nav/nav'
import customer from './asset/customer.png'
import education from './asset/education.png'
import brand from './asset/brand.png'
import employee from './asset/employee.png'
import product from './asset/product.png'
import professional from './asset/professional.png'
import community from './asset/community.png'
import event from './asset/event.png'
import form from './asset/form.png'
import survey from './asset/survey.png'
import { css } from 'glamor'
import Footer from '../LandingPage/components/footer/Footer'

function Index(props) { 
  const { history } = props
  let style={ 
    boxHeader: { fontSize: '18px', textAlign: 'center', color: 'rgb(52, 58, 64)', fontFamily: 'Roboto', ':hover': { textDecoration: 'none !important' } },
    boxIcon: { height: '50px', width: '50px', margin: 'auto' }, 
    p: { fontSize: '15px', color: 'black', fontFamily: 'Roboto', color: 'rgb(52, 58, 64)' }, 
    ourBtn: { 
      backgroundColor: '#1B998B', color: 'white', 
      borderRadius: '0%', borderColor: '#1B998B', 
    } 
  } 

  const readMoreCss = css({ 
    color: '#1B998B', fontSize: '12px', fontWeight: '500', position: 'relative', top: '-15px',
    ':hover': { color: '#0B6157' } 
  }) 


  return ( 
    <div style={{overflowY: 'scroll'}}> 
      <NavBar /> 
      <div className="container">
      <>
        <div style={{marginTop: '25px', marginBottom: '15px'}}>
          <h5 className="text-center mb-3" style={{fontFamily: 'Roboto', color: 'rgb(52, 58, 64)'}}>Our Features</h5>
        </div>

        <div className="row justify-content-center"> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}} 
              onClick={() => history.push("/prolificxm-features/Customer-Experience")}> 
               
                <div className="text-center">
                  <img src={customer} className="card-img-top mt-3" style={style.boxIcon} alt="Customer Experience"/>
                </div> 
                <div className="card-body">
                  <h5 className="card-title" style={style.boxHeader}>Customer Experience</h5> 
                  <p style={style.p} className="card-text text-justify">
                    The process of monitoring every interaction the customers experience with an organization. 
                  </p> 
                  <span {...readMoreCss}> Read more... </span> 
                </div> 
              
            </div> 
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Brand-Experience')}>
              <img src={brand} style={style.boxIcon} className="card-img-top mt-3" alt="Brand Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Brand Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Brand Experience pulls out the customers’ thoughts about the brands.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Product-Experience')}>
              <img src={product} style={style.boxIcon} className="card-img-top mt-3" alt="Product Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Product Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Product Experience determines the interaction between a user and a product.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div> 
            </div> 
          </div>  
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Employee-Experience')}>
              <img src={employee} style={style.boxIcon} className="card-img-top mt-3" alt="Employee Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Employee Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Employees’ observations and perceptions about their employment at an organization.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Professional-Experience')}>
              <img src={professional} style={style.boxIcon} className="card-img-top mt-3" alt="Professional Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Professional Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Ameliorate your career through the feedback from your clients.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Education-Experience')}>
              <img src={education} style={style.boxIcon} className="card-img-top mt-3" alt="Education Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Education Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Explore your institutions by getting the opinion from teachers, staff, students, and parents.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
            onClick={() => history.push('/prolificxm-features/Community-Experience')}>
              <img src={community} style={style.boxIcon} className="card-img-top mt-3" alt="Community Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Community Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  It enables an opportunity to enhance the living experience in a community.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}}
              onClick={() => history.push('/prolificxm-features/Event-Experience')}>
              <img src={event} style={style.boxIcon} className="card-img-top mt-3" alt="Event Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Event Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Figure out what everyone experienced at your event and what you can do to make it even better.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div> 
            </div> 
          </div> 

          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}} 
              onClick={() => history.push('/prolificxm-features/Random-Survey')}>
              <img src={survey} style={style.boxIcon} className="card-img-top mt-3" alt="Random Survey"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Create Random Survey</h5> 
                <p style={style.p} className="card-text text-justify">
                  Make an amazing and customized survey in minutes using our Template Library.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div> 
          
          {/* <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100" style={{cursor: 'pointer'}} 
              onClick={() => history.push('/prolificxm-features/Build-Form')}>
              <img src={form} style={style.boxIcon} className="card-img-top mt-3" alt="Build Form"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Build a Form</h5> 
                <p style={style.p} className="card-text text-justify">
                  Make an amazing and customized survey in minutes using our Template Library.
                </p> 
                <span {...readMoreCss}> Read more... </span> 
              </div>
            </div>
          </div>  */} 
        </div> 
      </> 
      </div> 
      <Footer /> 
    </div> 
  ) 
} 

export default withRouter(Index); 
