import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';

import {
	questionDataSelector,
	answerFieldValueSelector,
} from '../../redux/selectors';
import { updateData, startDate } from '../../redux/actions';
import './style.css';
import { Fields, QuestionCard, QuestionTitle } from '../styles';
import { Input } from './styles';

whyDidYouRender(React, {
	onlyLogs: true,
	titleColor: 'green',
	diffNameColor: 'aqua',
});

const NumericBox = (props) => {
	const { data, answer, updateData, startDate, error, setError } = props;

	useEffect(() => {
		console.log(data);
		if (data.hasOwnProperty('id')) {
			startDate(new Date());
		}
	}, [data]);

	const handleChange = (event) => {
		let { value } = event.target;

		if (data.hasRange) {
			if (value > data.max) {
				setError(`Value must be less than ${data.max}`);
				return;
			} else if (value < data.min) {
				setError(`Value must be greater than ${data.min}`);
				return;
			} else {
				setError('');
				updateData(value);
			}
		}

		// if (data.hasRange) {
		// 	if (value > data.max) {
		// 		value = data.max;
		// 	} else if (value < data.min) {
		// 		value = data.min;
		// 	}
		// }
		// updateData(value);
	};

	return (
		<QuestionCard>
			<QuestionTitle>{data.title}</QuestionTitle>
			{data.hasRange && (
				<div className='row w-100'>
					<div className='col text-left numeric-box-label'>{data.min}</div>
					<div className='col text-right numeric-box-label'>{data.max}</div>
				</div>
			)}
			{/* <Fields>
                <Input type="text" value={answer} onChange={handleChange} />
            </Fields> */}
			<label className='numeric-range'>
				{data.hasRange ? (
					<>
						<input
							id='typeinp'
							type='number'
							// min={data.min}
							// max={data.max}
							// value={answer}
							defaultValue={answer}
							onChange={handleChange}
							// step='.01'
							style={{
								border: error ? '1px solid red' : '1px solid #ced4da',
							}}
						/>

						{error && <div className='text-danger'>{error}</div>}
					</>
				) : (
					<input
						id='typeinp'
						type='number'
						// value={answer}
						defaultValue={answer}
						onChange={handleChange}
						// step='.01'
					/>
				)}
			</label>
		</QuestionCard>
	);
};

NumericBox.whyDidYouRender = true;

const mapStateToProps = (state) => {
	return {
		data: questionDataSelector(state),
		answer: answerFieldValueSelector(state),
	};
};

const mapDispatchToProps = {
	updateData,
	startDate,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(NumericBox));
