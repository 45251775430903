import styled from 'styled-components'

export const Body = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 40px;
  box-sizing: border-box;
  overflow: auto;
  // background: green;
  margin-top: -150px;
  z-index: 9999;
`