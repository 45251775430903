import axios from 'axios'
import {
    GET_ALL_FEATURE,
    GET_SINGLE_FEATURE,
    CREATE_FEATURE,
    BACK_BUTTON_IN_DETAIL_PAGE,
    DELETE_FEATURE,
    UPDATE_FEATURE
} from './actionTypes'
import baseUrl from '../../../../url'

export const getAllFeature = (ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/features/list/all/?page=1&&limit=500`)
        .then(response => {
            const res = response.data.features.map(r => {
                let createdAT = r.createdAt.slice(0, 10)
                return { createdAT, ...r }
            })
            dispatch({ type: GET_ALL_FEATURE, payload: res })
        })
        .catch(error => ErrorToast())

}


export const getFeatureDetail = (id, ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/features/${id}`)
        .then(res => {
            dispatch({ type: GET_SINGLE_FEATURE, payload: res.data })
        })
        .catch(err => ErrorToast())
}

export const createNewFeature = (values, CreateToast, ErrorToast) => dispatch => {

    axios.post(`${baseUrl}/api/features`, values)
        .then(res => {
            console.log(res)
            dispatch({ type: CREATE_FEATURE, payload: res.data })
            CreateToast()
        })
        .catch(err => ErrorToast())

}


export const deleteFeatureById = (state) => dispatch => {
    dispatch({ type: DELETE_FEATURE, payload: state })
}

export const updateFeatureById = (id, value, UpdateToast, ErrorToast) => dispatch => {

    axios.patch(`${baseUrl}/api/features/${id}`, value)
        .then(res => {
            dispatch({ type: UPDATE_FEATURE, payload: res.data });
            UpdateToast();
        })
        .catch(err => ErrorToast())

}

export const backButtonFunction = () => dispatch => { dispatch({ type: BACK_BUTTON_IN_DETAIL_PAGE }) }


