import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { Ques, Body, Checkbox } from './styles'

import { toggleQuestion } from '../../redux/actions/index'

const Question = ({ title, question, projectId, toggleQuestion, checked, questionChecked, toggleCount }) => {

  useEffect(() => {
    if (checked !== questionChecked && toggleCount !== 0)
      toggleQuestion(question, projectId)
  }, [questionChecked])

  const handleClick = () => {
    toggleQuestion(question, projectId)
    // setToggleQuestionChecked(checked)
  }

  // TODO select all change on changing single change

  const handleCheck = () => { }

  return (
    <Body onClick={handleClick}>
      <Checkbox type="checkbox" checked={checked} onChange={handleCheck} />
      <Ques>{title}</Ques>
    </Body>
  )
}

const mapStateToProps = (state, ownProps) => {
  const projectId = state.config.project
  const selectedList = state.question.selectedQuestion[projectId]
  // const {title, questionId} = ownProps
  const { question } = ownProps
  let checked
  if (selectedList) {
    checked = selectedList.some(element => element.id === question.id)
  } else {
    checked = false
  }
  return {
    projectId,
    title: question.title,
    // questionId,
    question,
    checked
  }
}

const mapDispatchToProps = {
  toggleQuestion: (question, projectId) => toggleQuestion(question, projectId)
}

export default connect(mapStateToProps, mapDispatchToProps)(Question)