//Question list modification
export const ADD_QUESTION = "ADD_QUESTION"
export const DELETE_QUESTION = "DELETE_QUESTION"
export const SAVE_QUESTION = "SAVE_QUESTION"

//Individual question modification
export const GET_QUESTION_ID = "GET_QUESTION_ID"
export const EDIT_FIELD = "EDIT_FIELD"
export const ADD_FIELD = "ADD_FIELD"
export const DELETE_FIELD = "DELETE_FIELD"
export const EDIT_TITLE = "EDIT_TITLE"
export const CHANGE_TYPE = "CHANGE_TYPE"
export const ADD_OBJECTIVE = "ADD_OBJECTIVE"

//Data fetching
export const FETCH_QUESTION = "FETCH_QUESTION"
export const FETCH_OBJECTIVE = "FETCH_OBJECTIVE"

//Notification and status modification
export const SHOW_TOAST = "SHOW_TOAST"
export const HIDE_TOAST = "HIDE_TOAST"
export const LOADING = "LOADING"

//Common Config
export const SET_REQUIRED = "SET_REQUIRED"

//LikertScale Config
export const LIBRARY_UPDATE = "LIBRARY_UPDATE"

//Demography Config
export const TOGGLE_DEMOGRAPHY_FIELD = "TOGGLE_DEMOGRAPHY_FIELD"

//error config
export const ERROR = "ERROR"

export const CHANGE_NAME = "CHANGE_NAME"
export const CHANGE_FEATURE = "CHANGE_FEATURE"
export const CHANGE_ACTIVE = "CHANGE_ACTIVE"
export const DELETE_FEATURE = "DELETE_FEATURE"
export const BACK_BUTTON_IN_DETAIL_PAGE = 'BACK_BUTTON_IN_DETAIL_PAGE'

// delete project
export const DELETE_PROJECT = 'DELETE_PROJECT'

export const RESET_STORE = 'RESET_STORE'