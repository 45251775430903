import React from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import baseUrl from '../../../../url';
import showToast from '../../../../components/Toast/toast';
import { read as xlsxRead, utils as xlsxUtils } from 'xlsx';
// import Toast from '../../../../components/Toast';
import Breadcrumb from '../../../../components/Breadcrumb/breadcrumb';

function CreateButton({ searchQuery, setSearchQuery, data, setData }) {
  const [modal, setModal] = React.useState(false);
  const [file, setFile] = React.useState(null); // file[0]
  const [entryType, setEntryType] = React.useState('manual'); // manual or file

  const fetchBulkEmails = async () => {
    const profileId = localStorage.getItem('jwtProfileId');

    try {
      const res = await axios.get(
        `${baseUrl}/api/bulk-email/list/${profileId}?page=1&limit=15&fields=name,email,createdAt`
      );
      const { data } = res;

      if (data && data.status === 'success') {
        setData(data.data);
      } else {
        setData([]);
      }
    } catch (error) {
      showToast('Something went wrong');
    }
  };

  const toggleModal = () => setModal(!modal);

  const handleFileChange = (e) => {
    // only one file can be uploaded & accepted file types are .csv, .xlsx, .xls
    const files = e.target.files;
    const file = files ? files[0] : null;

    if (file) {
      if (
        file.type === 'application/vnd.ms-excel' ||
        file.type ===
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        file.type === 'text/csv'
      ) {
        setFile(file);
      } else {
        alert('Invalid file type. Please upload .csv, .xlsx, or .xls file.');
      }
    } else {
      setFile(null);
    }
  };

  const handleFileUpload = (e) => {
    e.preventDefault();
    // upload file
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = xlsxRead(bstr, { type: rABS ? 'binary' : 'array' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = xlsxUtils.sheet_to_json(ws, { header: 1 }).slice(1);

      const transformedData = [];
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if (!emailRegex.test(element[1])) {
          continue;
        }

        transformedData.push({
          name: element[0],
          email: element[1],
        });
      }

      handleSubmit(transformedData);
    };

    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  };

  const handleSubmit = async (values = null) => {
    let bodyData = null;

    if (entryType === 'manual') {
      bodyData = {
        createdByProfileId: localStorage.getItem('jwtProfileId'),
        email_list: [
          {
            name: values.name,
            email: values.email,
          },
        ],
      };
    } else {
      bodyData = {
        createdByProfileId: localStorage.getItem('jwtProfileId'),
        email_list: [...values],
      };
    }

    try {
      const res = await axios.post(`${baseUrl}/api/bulk-email`, bodyData, {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
        },
      });

      const { data } = res;

      if (data && data.success) {
        showToast('Bulk Emails Added Successfully');
        toggleModal();
        fetchBulkEmails();
      } else {
        showToast('Failed to add email');
      }
    } catch (error) {
      showToast('Something went wrong');
    }
  };

  return (
    <div>
      <Breadcrumb path='Bulk Email /' />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Button
          style={{
            background: '#1B998B',
            borderColor: '#1B998B',
            margin: '10px 0px 10px 0px',
            padding: '4px 15px',
            fontSize: '13px',
            fontWeight: '500',
          }}
          className='btnHover'
          size='sm'
          onClick={toggleModal}
        >
          Add New
        </Button>

        <Input
          type='text'
          placeholder='Search'
          style={{ width: '250px' }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Add New Email</ModalHeader>

        <ModalBody
          style={{
            padding: '0px',
          }}
        >
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
              email: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required('Name is required'),
              email: Yup.string().required('Email is required'),
            })}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              // ;

              handleSubmit(values);
            }}
          >
            {(props) => (
              <>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    textAlign: 'center',
                    borderBottom: '1px solid #E0E0E0',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      padding: '1rem',
                      fontSize: '16px',
                      fontWeight: '500',
                      borderBottom:
                        entryType === 'manual' ? '4px solid #1B998B' : null,
                      background: entryType === 'manual' ? '#E0E0E0' : null,
                    }}
                    onClick={() => setEntryType('manual')}
                  >
                    Manual
                  </div>
                  <div
                    style={{
                      padding: '1rem',
                      fontSize: '16px',
                      fontWeight: '500',
                      borderBottom:
                        entryType === 'file' ? '4px solid #1B998B' : null,
                      background: entryType === 'file' ? '#E0E0E0' : null,
                    }}
                    onClick={() => setEntryType('file')}
                  >
                    Upload
                  </div>
                </div>

                <form
                  onSubmit={
                    entryType === 'manual'
                      ? props.handleSubmit
                      : handleFileUpload
                  }
                  style={{
                    padding: '1rem',
                  }}
                >
                  {entryType === 'manual' ? (
                    <>
                      <div className='form-group'>
                        <label>
                          Name<span style={{ color: 'red' }}>{` *`}</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='name'
                          value={props.values.name}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          style={{
                            border:
                              props.errors.name && props.touched.name
                                ? '1px solid red'
                                : null,
                          }}
                        />
                        {props.errors.name && props.touched.name && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              margin: '0px',
                              textAlign: 'left',
                            }}
                          >
                            {' '}
                            {props.errors.name}{' '}
                          </p>
                        )}
                      </div>

                      <div className='form-group'>
                        <label>
                          Email<span style={{ color: 'red' }}>{` *`}</span>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          id='email'
                          value={props.values.email}
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          style={{
                            border:
                              props.errors.email && props.touched.email
                                ? '1px solid red'
                                : null,
                          }}
                        />
                        {props.errors.email && props.touched.email && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              margin: '0px',
                              textAlign: 'left',
                            }}
                          >
                            {' '}
                            {props.errors.email}{' '}
                          </p>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className='form-group'>
                      <label style={{ marginBottom: '20px' }}>
                        Upload File
                        <span style={{ color: 'red' }}>{` *`}</span> (.csv,
                        .xlsx, .xls)
                      </label>
                      <br />
                      <Input
                        type='file'
                        name='file'
                        id='file'
                        multiple={false}
                        onChange={handleFileChange}
                      />
                    </div>
                  )}

                  <Button
                    className='btnHover mr-2'
                    style={{ background: '#1B998B', borderColor: '#1B998B' }}
                    type='submit'
                  >
                    Next
                  </Button>

                  <Button color='secondary' onClick={toggleModal}>
                    Cancel
                  </Button>
                </form>
              </>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default withRouter(CreateButton);
