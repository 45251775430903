import React, { useState } from 'react'
import { connect } from 'react-redux'
import { setStatus } from '../../../redux/actions'
import './style.css';

const SelectProjects = props => {

  return (
    <label className="dislabel" style={{ marginTop: '15px' }}>
      Status:
      <select className="selectstatus" value={props.status} readOnly>
        <option value="DRAFT" disabled>Draft</option>
        <option value="SUBMITTED" disabled>Submitted</option>
        <option value="CANCELED" disabled>Canceled</option>
        <option value="DISTRIBUTED" disabled>Distributed</option>
      </select>
    </label>
  )

}

const mapStateToProps = state => {
  return { status: state.question.status }
}

export default connect(mapStateToProps, { setStatus })(SelectProjects);