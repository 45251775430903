import React, { useState, useEffect } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Container,
  PreviewBody,
  SaveButton,
  DistributeButton,
  ProgressbarRedirectUrl,
} from './styles';
import './style.css';
import ProjectGroup from '../../container/ProjectGroup';
import Header from '../../components/Header';
import AdditionalContentBar from '../../components/AdditionalContentBar';
import baseUrl from '../../../../url/index';
import axios from 'axios';
import { toast, Slide } from 'react-toastify';

import {
  sortQuestion,
  sortProjects,
  addCard,
  removeCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  updateOpenLink,
  setOpenLinkInDistribution,
  initQrCode,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar,
  setRedirectUrl,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  restoreSelectedQuestionAndOrder,
  changeConfigurationStatus,
} from '../../redux/actions';
import {
  apiDataGeneratorSelector,
  activeDeviceSelector,
  saveButtonSelector,
  sortandpreviewSelector,
  additionalCardSelector,
  projectOrderSelector,
} from '../../redux/selectors';
import Step from '../../components/Step/index';

import CreateSelectPage from '../SelectPage/createIndex';
import { ResponsiveContainer } from 'recharts';

const SortAndPreview = (props) => {
  const {
    projectList,
    sortQuestion,
    sortProjects,
    addCard,
    removeCard,
    dataToPush,
    history,
    pushConfiguration,
    updateConfiguration,
    distributeConfiguration,
    enableSave,
    setProfileIdInState,
    disId,
    fetchChannels,
    status,
    isReadOnly,
    progressbar,
    setProgressbar,
    redirectUrl,
    setRedirectUrl,
    sortandpreviewEnabled,
    createProjectForSurveyOnReload,
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
    projectFetch,
    setSurveyProjectStep,
    setSurveyChannelStep,
    setSurveyPreviewStep,
    setSurveyConfigStep,
    restoreSelectedQuestionAndOrder,
    changeConfigurationStatus,
    cards,
  } = props;

  const [profileId, setProfileId] = useState(
    localStorage.getItem('jwtProfileId')
  );

  useEffect(() => {
    if (props.location.state !== undefined) {
      restoreSelectedQuestionAndOrder();
    }
  }, [props.location.state]);

  useEffect(() => {
    if (props.location.state !== undefined) {
      if (status === 'SUBMITTED' || status === 'DISTRIBUTED') {
        changeConfigurationStatus(disId);
      }
    }
  }, [props.location.state]);

  useEffect(() => {
    const fetchDistDataOnReload = async (surveyid) => {
      const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`);
      createProjectForSurveyOnReload(disId.data);
      setSurveyProjectStep();
      setSurveyConfigStep();

      const disid = disId.data.distributionId;
      if (disid) {
        axios
          .get(`${baseUrl}/api/distribution/details/${disid}`)
          .then((res) => {
            editDistributionQuestion(res.data, disid);
            editDistributionConfig(res.data);
            selectProjectAndFetch(
              res.data.question.questionBank.projectOrder[0]
            );
            projectFetch(localStorage.getItem('jwtProfileId'));

            if ('activeChannels' in res.data) {
              setActiveChannel(res.data.activeChannels);
            }

            if (res.data.status === 'DRAFT') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
            }
            if (res.data.status === 'SUBMITTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              // setSurveyChannelStep()
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
            if (res.data.status === 'DISTRIBUTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              setSurveyChannelStep();
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
          });
      }
    };

    if (disId === '') {
      fetchDistDataOnReload(props.match.params.surveyid);
    }
  }, [disId]);

  useEffect(() => {
    setProfileIdInState(profileId);
  }, []);

  const handleSaveClick = () => {
    const { data } = dataToPush;
    pushConfiguration(data, profileId);
    toast('Survey design saved', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Slide,
      className: 'text-center',
    });
  };

  const handleSubmitClick = () => {
    const { data } = dataToPush;
    updateConfiguration(data, profileId, disId, history, 'create');
    fetchChannels(profileId);
    toast('Survey design confirmed', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Slide,
      className: 'text-center',
    });
  };

  const handleDistributeClick = () => {
    const { data } = dataToPush;
    distributeConfiguration(data, profileId, disId, history, 'create');
    fetchChannels(profileId);
    toast('Survey is distributed', {
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      transition: Slide,
      className: 'text-center',
    });
  };

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (destination) {
      if (type === 'projects') {
        if (source.droppableId === 'additionalCardsList') {
          addCard(destination, draggableId);
          return;
        }
        sortProjects(destination, source, draggableId);
        return;
      }
      sortQuestion(destination, source, draggableId);
    }
  };

  return (
    <>
      {sortandpreviewEnabled ? (
        <div className='container'>
          <Step />
        </div>
      ) : (
        <div className='container'>
          <Step />
          <div className='container'>Please wait ... </div>
        </div>
      )}

      <div>
        <div style={{ position: 'absolute', left: '-9999px' }}>
          <CreateSelectPage />
        </div>

        {sortandpreviewEnabled && (
          <Container>
            <ProgressbarRedirectUrl>
              <p
                style={{
                  margin: 0,
                  padding: '21px',
                  fontWeight: 500,
                }}
              >
                Survey Settings
              </p>

              <hr style={{ marginTop: '0px' }} />

              <div className='row settings-row'>
                <div className='col-12 p-0'>
                  <label className='dislabel'>
                    <input
                      name='progressbar'
                      type='checkbox'
                      checked={progressbar === true ? true : false}
                      disabled={isReadOnly}
                      style={{ marginRight: '10px' }}
                      onChange={(event) => {
                        const target = event.target;
                        const value =
                          target.type === 'checkbox'
                            ? target.checked
                            : target.value;
                        setProgressbar(value);
                      }}
                    />
                    Show question count?
                    <br />
                    (Not applicable for conditional question)
                  </label>
                </div>
                <div className='col-12 p-0'>
                  <label>
                    Redirect to website after response:
                    <input
                      className='form-control'
                      type='text'
                      value={redirectUrl}
                      onChange={(event) => setRedirectUrl(event.target.value)}
                      disabled={isReadOnly}
                      placeholder='default: www.prolificxm.com'
                    />
                  </label>
                </div>
                <div className='col-12 p-0'>
                  <label className='dislabel'>
                    {}
                    <input
                      name='progressbar'
                      type='checkbox'
                      disabled={isReadOnly}
                      style={{ marginRight: '10px' }}
                      checked={
                        projectList.find(
                          (item) => item.type === 'contactCard'
                        ) !== undefined
                      }
                      onChange={(event) => {
                        // const target = event.target;
                        // const value =
                        //   target.type === 'checkbox'
                        //     ? target.checked
                        //     : target.value;
                        // newAddCard();
                        if (event.target.checked) {
                          addCard(0, 'contactCard');
                        } else {
                          removeCard('contactCard');
                        }
                      }}
                    />
                    Add Contact Card?
                    <br />
                    (Will be added at the top of the survey)
                  </label>
                </div>
              </div>
            </ProgressbarRedirectUrl>

            <div className='container-inner'>
              {status === '' && (
                <SaveButton type='button' onClick={handleSaveClick}>
                  Confirm
                </SaveButton>
              )}

              {(status === 'SUBMITTED' || status === 'DRAFT') && (
                <DistributeButton type='button' onClick={handleDistributeClick}>
                  Distribute
                </DistributeButton>
              )}

              <PreviewBody>
                <Header />
                {/* <DragDropContext onDragEnd={onDragEnd}> */}
                {/* <AdditionalContentBar /> */}
                {/* <AdditionalContentBar /> */}
                <ProjectGroup />
                {/* </DragDropContext> */}
              </PreviewBody>
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  projectList: projectOrderSelector(state),
  cards: additionalCardSelector(state),
  dataToPush: apiDataGeneratorSelector(state),
  enableSave: saveButtonSelector(state),
  activeDevice: activeDeviceSelector(state),
  status: state.question.status,
  progressbar: state.question.progressbar,
  redirectUrl: state.question.redirectUrl,
  isReadOnly: state.question.isReadOnly,
  disId: state.question.distributionId,
  order: state.question.order,
  statusDevice: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'device'
  ),
  statusOpenLink: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'openLink'
  ),
  statusQrCode: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'qrCode'
  ),
  deviceLists: state.question.deviceList,
  openlinks: state.question.openLink,
  qRCode: state.question.qRCode,
  projectId: state.survey.projectId,
  sortandpreviewEnabled: sortandpreviewSelector(state),
  questionLists: state.config.questionList,
  surveyQuestionSaveMessage: state.question.surveyQuestionSaveMessage,
});

const mapDispatchToProps = {
  sortQuestion,
  sortProjects,
  addCard,
  removeCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  initQrCode,
  updateOpenLink,
  setOpenLinkInDistribution,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar,
  setRedirectUrl,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  restoreSelectedQuestionAndOrder,
  changeConfigurationStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SortAndPreview)
);
