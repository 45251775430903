import React from 'react'
import { connect } from 'react-redux'
import Select from 'react-select'
import { Scale, Rating } from './styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'

import { setQuestionIdForCondition } from '../../../redux/actions'

const JumpQuestionSelector = props => {
  return (
    <Select
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base => {
          return {
            ...base,
            boxSizing: 'border-box',
            // minHeight: "1px !important",
            height: "25px",
            width: "100%",
            flexShrink: 0
          }
        },
        control: (base) => {
          return {
            ...base,
            boxSizing: 'border-box',
            // boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover': {
              ...base[':hover'],
              // boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active': {
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style => {
          return {
            ...style,
            padding: "0 5px"
          }
        }
      }}
      // id="channel"
      // isClearable={true}
      placeholder="Question"
      isSearchable={false}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
    />
  )
}

const LikertScale = props => {
  const { id, choices, scale, questionsAfter, isConditional, setQuestionIdForCondition, jump, projectId } = props

  const ratingList = choices.map((item, index) => {

    const jumpValue = jump.find(item => item.jumpOptionIndex === index)
    const formattedJumpValue = jumpValue ?
      questionsAfter.find(item => item.value === jumpValue.jumpTo) :
      ({ label: 'None', value: null })

    const handleChange = selected => {
      setQuestionIdForCondition(projectId, id, index, selected.value)
    }

    return (
      <Rating key={`${id}_${index}`} scale={scale}>
        <FontAwesomeIcon icon={faHeart} size="2x" style={{ color: "#757575", marginBottom: "5px" }} />
        {
          isConditional &&
          questionsAfter.length !== 2 &&
          <JumpQuestionSelector
            options={questionsAfter}
            value={formattedJumpValue}
            onChange={handleChange}
          />
        }
      </Rating>)
  })

  return (
    <Scale>
      {ratingList}
    </Scale>
  )
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
})

const mapDispatchToProps = {
  setQuestionIdForCondition
}

export default connect(mapStateToProps, mapDispatchToProps)(LikertScale)