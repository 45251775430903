import React from 'react'
import {connect} from 'react-redux'

import {contactCard as config} from '../../../config'
import {Container, Title, Header, RemoveButton} from '../styles'
import {Body} from './styles'
import Options from './Options'

import {removeCard} from '../../../redux/actions'

const ContactCard = props=>{
  const {provided, snapshot, inSidebar, id, removeCard} = props
  
  const handleClick = ()=>{
    removeCard(id)
  }

  return(
    <Container
      ref={provided.innerRef}
      {...provided.draggableProps} 
    >
      <Header 
        {...provided.dragHandleProps}
      >
        <Title>
          {config.title}
          {!inSidebar && <RemoveButton type='button' value="Remove" onClick={handleClick}/>}
        </Title>
      </Header>
      {
        !inSidebar && 
        <Body>
          <Options />
        </Body> 
      }
    </Container>
  )
}

const mapStateToProps = (state, ownProps) => { 
  return { 
    ...ownProps, 
  } 
} 

const mapDispatchToProps = {
  removeCard: id=>removeCard(id),
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactCard)