import React,{useState} from 'react'
import PropTypes from 'prop-types'

import {Body,Question, GraphBody} from './styles'
import ResponseCountByDay from '../ResponseCountByDay'
import Answers from '../Answers'
import ToggleMinimize from '../ToggleMinimize'
import ToggleMaximize from '../ToggleMaximize'

const Card = (props)=>{
  // {title, type, date, device}
  const {title, type} = props

  const [view, setView] = useState(false)

  const changeView = ()=>{
    setView(!view)
  }

  // try{
  //   Object.keys(device).forEach(item=>{
  //     console.log(Object.keys(device[item]))
  //   })
  // }
  // catch(err){
  //   console.log(err)
  // }

  return(
    <Body view={view}>
      <Question onClick={changeView}>
        {
          view ? 
            <ToggleMinimize />
            :
            <ToggleMaximize />
        }
        {title}
      </Question>
      {
        view ? 
          <GraphBody>
            <ResponseCountByDay title={title} type={type}/>
            <Answers title={title} type={type}/>
          </GraphBody>
          :
          null
      }
    </Body>
  )
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  // date: PropTypes.object.isRequired,
  // device: PropTypes.object.isRequired
}

export default Card