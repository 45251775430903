import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import truncate from 'truncate-html';
import './SparkLine.css';

const SparkLine = (props) => {
  const data = props.chartData ? props.chartData : [];
  const colorScheme = [
    '#1B998B',
    '#EF8354',
    '#99B2DD',
    '#4F5D75',
    '#F27D7D',
    '#A5668B',
    '#7EAB7E',
    '#CDC392',
    '#BE95C4',
    '#FFBF69',
    '#99B2DD',
    '#EF8354',
    '#4F5D75',
    '#F27D7D',
    '#A5668B',
    '#7EAB7E',
    '#CDC392',
    '#BE95C4',
    '#FFBF69',
  ];
  return (
    <div className='SparkLine' style={{ height: '50px', width: '100%' }}>
      <ResponsiveLine
        data={data}
        margin={{ top: 8, right: 5, bottom: 5, left: 5 }}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: false,
          reverse: false,
        }}
        curve='linear'
        axisTop={null}
        axisRight={null}
        axisBottom={null}
        axisLeft={null}
        enableGridX={true}
        enableGridY={false}
        colors={colorScheme}
        lineWidth={2}
        pointSize={2}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel={function (e) {
          return e.x + ': ' + e.y;
        }}
        pointLabelYOffset={-12}
        enableArea={true}
        crosshairType='x'
        useMesh={true}
        tooltip={({ point }) => {
          return (
            <div className='SparkLine-tooltip'>
              <h6>
                <span
                  className='d-inline-block'
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor: `${point.serieColor}`,
                    borderRadius: '2px',
                    marginRight: '2px',
                  }}
                ></span>
                {point.serieId}
              </h6>
              {point.data.x} : {point.data.y}
            </div>
          );
        }}
        legends={[]}
      />
    </div>
  );
};

export default SparkLine;
