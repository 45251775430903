import React from 'react'
import {connect} from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import {ToggleContainer} from '../styles'

import {toggleView} from '../../../redux/actions'

const ToggleButton = ({view, toggleView})=>{
  return(
    <>
      <ToggleContainer>
        {view && <FontAwesomeIcon icon={faMinusCircle} onClick = {toggleView} size="lg"/>}
        {!view && <FontAwesomeIcon icon={faPlusCircle} onClick = {toggleView} size="lg"/>}
      </ToggleContainer>
    </>
  )
}

const mapStateToProps = state=>{
  return {
    view: state.config.view
  }
}

const mapDispatchToProps = {
  toggleView: ()=>toggleView()
}

export default connect(mapStateToProps, mapDispatchToProps)(ToggleButton)