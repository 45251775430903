import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const Modal = styled.div`  
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: black;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0,0,0,0.35);
  left: 0;
  top: 0;
`

const Card = styled.div`
position: absolute;
display:flex;
flex-direction: column;
box-sizing: border-box;
justify-content: center;
align-items: center;
width: 30vw;
height:20vh;
background-color: white;
border-radius: 3px;
z-index:5;
`

const Text = styled.div`
display: flex;
box-sizing: border-box;
justify-content: center;
align-items: center;
width:100%;
height:50%;
color: #212121;
font-size: 120%;
`

const ButtonContainer = styled.div`
display: flex;
box-sizing: border-box;
flex-direction: row;
justify-content: center;
//align-items: center;
width:100%;
height:40%;
`
const ConfirmationButton = styled.div`
display: flex;
box-sizing: border-box;  
color: white;
font-size: 13px;
align-items: center;
justify-content: center;
background-color: #F27D7D;
margin-left:10px;
width: 60px;
height: 25px;
border-radius:4px;
font-weight:500;
box-shadow: 1px 1px 5px -1px #586469;
:hover{
cursor: pointer;
}
`

const Delete = styled.button`
background-color: #F27D7D;
box-sizing: border-box;
outline: none;
border: none;
border-radius:3px;
color: #FFF;
font-size: 12px;
font-weight:700;
line-height: 14px;
padding: 5px 10px;
margin: 0 0 5px 0;
box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
transition: all 0.3s cubic-bezier(.25,.8,.25,1);
:hover{
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
  }
@media screen and (max-width:425px){
  font-size: 10px;
  padding: 2px 8px;
}
`

const DeleteButton = ({ click }) => {
  const [view, setview] = useState(false)

  const ShowConfirmation = () =>
    <Card>
      <Text>
        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "38px", marginRight: "10px", color:"#F27D7D" }} />
        Are you sure you want to delete?
      </Text>
      <ButtonContainer>
        <ConfirmationButton onClick={click}> Confirm </ConfirmationButton>
        <ConfirmationButton style={{backgroundColor:"#757575"}} onClick={() => { setview(false) }}> Cancel </ConfirmationButton>
      </ButtonContainer>
    </Card>

  return (
    <>
      <Modal css={`display:${view ? 'flex' : 'none'}`}>
        <ShowConfirmation />
      </Modal>

      <Delete onClick={() => setview(true)}>
        <FontAwesomeIcon icon={faTrashAlt} style={{ color: "white", marginRight: "10px" }} />
        Delete
    </Delete>
    </>
  )
}


export default DeleteButton