import React, { useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import Logo from './asset/ProlificXM.png';
import crossEye from './asset/eye-cross.png';
import Eye from './asset/eye.png';
import SignupModal from '../Pricing/components/modal/modal';
import ShowToast from '../../components/Toast/toast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';
import './style.css';
import baseUrl from '../../url';

function ResetPassword(props) {
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayPassword2, setDisplayPassword2] = useState(false);
  const [showFreeModal, setShowFreeModal] = useState(false);
  const [successMsgModal, setSuccessMsgModal] = useState(false);

  const dynamicCode = props.location.search.split('?code=')[1];

  const ErrorToast = () => ShowToast('Server Error');
  const SuccessToast = (msg) => ShowToast(msg);

  const closeModal = (msg = null) => {
    if (msg) {
      setSuccessMsgModal(true);
    }
    setShowFreeModal(false);
  };

  const { history } = props;
  return (
    <div>
      {/* Page Data */}
      <div className='wrapper' style={{ overflowY: 'auto' }}>
        <div className='elementReset'>
          <div className='logo'>
            <Link to='/'>
              <img height='60px' src={Logo} />
            </Link>
          </div>
          <div className='fieldBox'>
            <>
              <h2 className='boxHeader'>Set New Password</h2>
              <Formik
                initialValues={{ email: '', password: '', confirmPassword: '' }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().email().required('Required').trim(),
                  password: Yup.string().required('Required').min(6).trim(),
                  confirmPassword: Yup.string()
                    .required('Required')
                    .trim()
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                })}
                onSubmit={(values, { resetForm }) => {
                  let data = {
                    email: values.email.trim(),
                    password: values.password.trim(),
                    confirmPassword: values.confirmPassword.trim(),
                    code: dynamicCode.trim(),
                  };
                  console.log(data);
                  axios
                    .patch(`${baseUrl}/api/login/resetPassword`, data)
                    .then((res) => {
                      if (res.data.isValid) {
                        SuccessToast(res.data.message);
                        resetForm();
                        history.push('/signin');
                      } else {
                        toast(res.data.message, {
                          className: css({ color: '#1B998B' }),
                        });
                      }
                    })
                    .catch((err) => ErrorToast());
                }}
              >
                {(props) => (
                  <div>
                    <Form onSubmit={props.handleSubmit}>
                      {/* Eamil */}
                      <FormGroup
                        row
                        style={{
                          marginBottom: '0.5em',
                          marginLeft: '0',
                          marginRight: '0',
                        }}
                      >
                        <Label
                          htmlFor='email'
                          style={{ fontSize: '14px', color: 'rgb(52,58,64)' }}
                        >
                          Email: <span style={{ color: 'red' }}>{` *`}</span>
                        </Label>
                        <Input
                          type='email'
                          name='email'
                          id='email'
                          className='resetPassword'
                          value={props.values.email}
                          onChange={props.handleChange}
                          style={{
                            height: 'calc(1.5em + 0.5rem + 2px)',
                            border:
                              props.errors.email && props.touched.email
                                ? '1px solid red'
                                : null,
                          }}
                          onBlur={props.handleBlur}
                          placeholder='Enter your email'
                          autoComplete='off'
                        />
                        {props.errors.email && props.touched.email && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              marginBottom: '0',
                              marginLeft: '5px',
                              textAlign: 'left',
                              fontWeight: 'bold',
                            }}
                          >
                            {props.errors.email}
                          </p>
                        )}
                      </FormGroup>

                      {/* New Password */}
                      <FormGroup
                        row
                        style={{
                          marginBottom: '0.5em',
                          marginLeft: '0',
                          marginRight: '0',
                        }}
                      >
                        <Label
                          htmlFor='password'
                          style={{ fontSize: '14px', color: 'rgb(52,58,64)' }}
                        >
                          New Password:{' '}
                          <span style={{ color: 'red' }}>{` *`}</span>
                        </Label>
                        <InputGroup>
                          <Input
                            type={displayPassword ? 'text' : 'password'}
                            name='password'
                            id='password'
                            className='resetPassword'
                            value={props.values.password}
                            onChange={props.handleChange}
                            style={{
                              height: 'calc(1.5em + 0.5rem + 2px)',
                              border:
                                props.errors.password && props.touched.password
                                  ? '1px solid red'
                                  : null,
                            }}
                            onBlur={props.handleBlur}
                            placeholder='Password'
                            autoComplete='off'
                          />

                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {displayPassword && (
                                <img
                                  width='18px'
                                  onClick={() => {
                                    setDisplayPassword(false);
                                  }}
                                  src={Eye}
                                />
                              )}
                              {!displayPassword && (
                                <img
                                  width='18px'
                                  onClick={() => {
                                    setDisplayPassword(true);
                                  }}
                                  src={crossEye}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {props.errors.password && props.touched.password && (
                          <p
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              marginBottom: '0',
                              marginLeft: '5px',
                              textAlign: 'left',
                              fontWeight: 'bold',
                            }}
                          >
                            {props.errors.password}
                          </p>
                        )}
                      </FormGroup>

                      {/* Confirm Password */}
                      <FormGroup
                        row
                        style={{
                          marginBottom: '0.5em',
                          marginLeft: '0',
                          marginRight: '0',
                        }}
                      >
                        <Label
                          htmlFor='confirmPassword'
                          style={{ fontSize: '14px', color: 'rgb(52,58,64)' }}
                        >
                          Confirm Password:{' '}
                          <span style={{ color: 'red' }}>{` *`}</span>
                        </Label>
                        <InputGroup>
                          <Input
                            type={displayPassword2 ? 'text' : 'password'}
                            name='confirmPassword'
                            id='confirmPassword'
                            value={props.values.confirmPassword}
                            onChange={props.handleChange}
                            style={{
                              height: 'calc(1.5em + 0.5rem + 2px)',
                              border:
                                props.errors.confirmPassword &&
                                props.touched.confirmPassword
                                  ? '1px solid red'
                                  : null,
                            }}
                            onBlur={props.handleBlur}
                            placeholder='Confirm Password'
                            autoComplete='off'
                            className='resetPassword'
                          />
                          <InputGroupAddon addonType='append'>
                            <InputGroupText>
                              {displayPassword2 && (
                                <img
                                  width='18px'
                                  onClick={() => {
                                    setDisplayPassword2(false);
                                  }}
                                  src={Eye}
                                />
                              )}
                              {!displayPassword2 && (
                                <img
                                  width='18px'
                                  onClick={() => {
                                    setDisplayPassword2(true);
                                  }}
                                  src={crossEye}
                                />
                              )}
                            </InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                        {props.errors.confirmPassword &&
                          props.touched.confirmPassword && (
                            <p
                              style={{
                                color: 'red',
                                fontSize: '12px',
                                marginBottom: '0',
                                marginLeft: '5px',
                                textAlign: 'left',
                                fontWeight: 'bold',
                              }}
                            >
                              {props.errors.confirmPassword}
                            </p>
                          )}
                      </FormGroup>

                      {/* Tips */}
                      {/* <FormGroup row style={{margin: '0 0 0.5em 0'}}> 
                          <section style={{background: '#ececec', width: '100%', padding: '10px 20px', borderRadius: '5px'}}>
                            <p style={{ 
                              color: 'rgb(52, 58, 64)', 
                              fontSize: '12px', 
                              marginBottom: '5px' 
                            }}> 
                              Your password must have: 
                            </p> 
                            <ul style={{marginBottom: '0px'}}> 
                              <li style={{marginLeft: '25px', color: 'rgb(52, 58, 64)', fontSize: '11px'}}> 
                                At least 6 characters 
                              </li> 
                            </ul> 
                          </section> 
                        </FormGroup>  */}

                      {/* Buttons */}
                      <FormGroup
                        row
                        style={{
                          marginBottom: '0.5em',
                          marginLeft: '0',
                          marginRight: '0',
                        }}
                      >
                        <Button
                          type='submit'
                          className='btnHover mr-2'
                          size='sm'
                          style={{
                            background: '#1B998B',
                            borderColor: '#1B998B',
                          }}
                        >
                          Reset Password
                        </Button>

                        <Button
                          className='btnHover'
                          size='sm'
                          onClick={() => history.push('/signin')}
                          style={{
                            background: '#1B998B',
                            borderColor: '#1B998B',
                          }}
                        >
                          Cancel
                        </Button>
                      </FormGroup>

                      <FormGroup
                        row
                        style={{
                          marginBottom: '0',
                          marginLeft: '0',
                          marginRight: '0',
                        }}
                      >
                        <p
                          style={{
                            fontSize: '11px',
                            cursor: 'pointer',
                            color: '#47A4DB',
                            fontWeight: '500',
                          }}
                          onClick={() => history.push('/signin')}
                        >
                          Signin with email & password
                        </p>
                      </FormGroup>
                    </Form>

                    <center className='ifNo mt-1'>
                      Don't have an account? {` `}
                      <span onClick={() => setShowFreeModal(true)}>
                        SIGN UP
                      </span>
                    </center>
                  </div>
                )}
              </Formik>
            </>
          </div>
        </div>
      </div>

      {showFreeModal && (
        <SignupModal
          header='Free Signup'
          showFreeModal={showFreeModal}
          closeModal={closeModal}
        />
      )}

      {/* Signup success message */}
      <>
        <Modal
          isOpen={successMsgModal}
          className={props.className}
          style={{ top: '150px' }}
        >
          {/* { closeModal() }  */}
          <ModalHeader style={{ color: '#1B998B', padding: '0.5rem 1rem' }}>
            You are Done!!!
          </ModalHeader>
          <ModalBody>
            Thank you for joining us. Please check your email to verify.
            <br />
            <Button
              className='btnHover mt-3'
              size='sm'
              style={{ background: '#1B998B', borderColor: '#1B998B' }}
              onClick={() => setSuccessMsgModal(false)}
            >
              Close
            </Button>
          </ModalBody>
        </Modal>
      </>
    </div>
  );
}

export default withRouter(ResetPassword);
