import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import { connect } from "react-redux"
import _ from 'lodash'
import MultipleChoice from '../MultipleChoice'
import DropDown from '../dropDown'
import Dichotomy from '../dichotomy'
import ThumbsUpDown from '../thumbsUpDown'
import Checkbox from '../Checkbox'
import LikertScale from '../LikertScale'
import RatingScale from '../ratingScale'
import TextBox from '../TextBox'
import Demography from '../Demography'
import Nps from '../Nps/Nps'
import SliderQuestion from '../Slider'
import DateComponent from '../Date'
import DateRange from '../DateRange'
import NumericBox from '../NumericBox/NumericBox'
//import { connect } from 'net';

const Content = styled.div`
  margin: 10px;
  box-sizing: border-box;
  width: 90%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: center;
  align-items: flex-start;
`

const Input = styled.textarea`
  box-sizing: border-box;
  border-color: #BDBDBD;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  resize: none;
  border-radius:4px;
  width: 100%;
  font-size: 15px;
  color:#424242;
  outline: none;
  height:30px;
  line-height:18px;
  padding: 4px 8px 4px 8px;
  overflow: hidden;
  @media screen and (max-width: 767.92px){
    font-size: 12px;
    padding: 4px 10px;
    line-height:18px;
  }
`






const QuestionCardContent = ({ item, editField, editCheckbox, editTitle, hasError, isReadOnly }) => {   //this component indicates the question state in store

  const [itemTitle, setItemTitle] = useState("");
  useEffect(() => {
    setItemTitle(item.title)
  }, [])
  const delayedQuery = _.debounce((id, value) => editTitle(id, value), 10000);
  const debounceEditTitle = (id, value) => {
    setItemTitle(value);
    // delayedQuery(id, value);
    editTitle(id, value)
  };

  switch (item.type) {
    case "multipleChoice":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <MultipleChoice item={item} editField={editField} />
        </Content>
      )

    case "dropDown":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <DropDown item={item} editField={editField} />
        </Content>
      )
    case "dichotomy":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: Do you want to buy it again?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <Dichotomy item={item} />
        </Content>
      )
    case "thumbsUpDown":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: Do you like it?"
            onInput={e => {
              //console.log(e.target.style.height)
              //console.log(e.target.scrollHeight)

              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) { //to change the height the input field according to the input without adding scroll, here initial scrollHeight is 75
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <ThumbsUpDown item={item} />
        </Content>
      )
    case "checkbox":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: What are your favourite foods?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <Checkbox item={item} editField={editField} />
        </Content>
      )
    case "likertScale":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <LikertScale item={item} editField={editField} />
        </Content>
      )
    case "ratingScale":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <RatingScale ratingType="number" item={item} editField={editField} />
        </Content>
      )
    case "starRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <RatingScale ratingType="star" item={item} editField={editField} />
        </Content>
      )
    case "thumbsUpRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <RatingScale ratingType="thumbsUp" item={item} editField={editField} />
        </Content>
      )
    case "loveRating":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <RatingScale ratingType="love" item={item} editField={editField} />
        </Content>
      )
    case "nps":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <Nps item={item} editField={editField} />
        </Content>
      )
    case "date":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <DateComponent />
        </Content>
      )
    case "daterange":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: How was your trip?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <DateRange />
        </Content>
      )
    case "textBox":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${itemTitle || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={itemTitle}
            placeholder="Example: Your Name?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <TextBox item={item} editField={editField} isReadOnly={isReadOnly} />
        </Content>
      )
    case "slider":
      return (
        <Content>
          <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
          <Input
            css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
            value={item.title}
            placeholder="Example: Your Name?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            onChange={e => { editTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <SliderQuestion item={item} editField={editField} isReadOnly={isReadOnly} />
        </Content>
      )
    case "numeric":
        return (
          <Content>
            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Question Title</h6>
            <Input
              css={`border-color:${item.title || !hasError ? '#BDBDBD' : '#F27D7D'}`}
              value={item.title}
              placeholder="Example: Your Name?"
              onInput={e => {
                e.target.style.height = "30px"
                if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                  e.target.style.height = e.target.scrollHeight + "px";
                }
              }}
              onChange={e => { editTitle(item.id, e.target.value) }}
              disabled={isReadOnly}
            />
            <NumericBox item={item} editField={editField} editCheckbox={editCheckbox} isReadOnly={isReadOnly} />
          </Content>
        )
    case "demography":
      return (
        <Content>
          <Input
            css={`border:${itemTitle || !hasError ? '' : '1px solid red'}`}
            value={itemTitle}
            placeholder="Example: Your Name?"
            onInput={e => {
              e.target.style.height = "30px"
              if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
                e.target.style.height = e.target.scrollHeight + "px";
              }
            }}
            // onChange={e => { editTitle(item.id, e.target.value) }}
            onChange={e => { debounceEditTitle(item.id, e.target.value) }}
            disabled={isReadOnly}
          />
          <Demography item={item} />
        </Content>
      )
    default:
      return (
        <Content>
        </Content>
      )
  }

}

const mapStateToProps = (state) => {
  return {
    hasError: state.error.title,
    // disStatus: state.disStatus,
    isReadOnly: state.isReadOnly
  }
}

export default connect(mapStateToProps, null)(QuestionCardContent)