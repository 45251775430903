import React, {useState} from 'react'
import NavBar from '../LandingPage/components/nav/nav'
import Summary from './components/summary/summary' 
import Compare from './components/compare/Compare'
import Footer from '../LandingPage/components/footer/Footer'

function Pricing() { 
  let [choose, setChoose] = useState(1) 
  return (
    <div style={{overflowY: 'scroll'}}> 
      <NavBar /> 
      <h2 className="text-center mt-5" style={{color: '#1B998B', fontSize: '28px'}}> 
        Choose Your Convinient Plan 
      </h2> 
      <div className="d-flex flex-wrap justify-content-center mb-2" style={{boxSizing: 'border-box'}}> 
        <h6 className="" 
          onClick={() => setChoose(1)} 
          style={{ 
            color: choose === 1 ? '#fff' : 'rgb(52, 58, 64)', 
            background: choose === 1 ? '#1B998B' : 'none', 
            border: choose === 2 ? '3px solid #1B998B' : 'none', 
            padding: '10px',
            cursor: 'pointer', 
            boxSizing: 'border-box'
          }}> 
          Plan Summary</h6> 
        <h6 style={{ 
            color: choose === 2 ? '#fff' : 'rgb(52, 58, 64)',
            background: choose === 2 ? '#1B998B' : 'none', 
            border: choose === 1 ? '3px solid #1B998B' : 'none', 
            padding: '10px',
            cursor: 'pointer', 
            boxSizing: 'border-box'
          }} 
          onClick={() => setChoose(2)}> 
          Compare Plans 
        </h6> 
      </div> 
      
      { choose === 1 ? <Summary /> : <Compare /> } 

      <Footer /> 

    </div> 
  ) 
} 

export default Pricing; 
