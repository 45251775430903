import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import baseUrl from '../../url';
import { MainBody } from './StyledComponents';
import { toast } from 'react-toastify';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb';
import { Button } from 'reactstrap';

const defaultMessage = `
<p>Hi [Recipient’s Name],</p>
<p>I hope you are doing well.</p>
<p>Please take a few minutes to complete our survey.</p>
<p>
  To participate in the survey, please click on the following link: [Survey Link]
  <br />
  Thank you for your time!
</p>
<p>
  Best,
  <br />
  ${localStorage.getItem('userName')}
</p>
`;

const TicketDetails = (props) => {
  const ticketUID = props.match.params.ticketUID;

  const [ticket, setTicket] = React.useState(null);
  const [linkedResponses, setLinkedResponses] = React.useState([]);
  const [pageLoading, setPageLoading] = React.useState(true);

  const [comment, setComment] = React.useState('');
  const [allComments, setAllComments] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const fetchComments = async (id) => {
    try {
      const res = await axios.get(`${baseUrl}/api/ticketComments/${id}`);

      if (res.data) {
        setAllComments(res.data);
      } else {
        setAllComments([]);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    // const fetchTicketDetails = async () => {
    //   try {
    //     const res = await axios.get(
    //       `${baseUrl}/api/ticket/individual/${ticketUID}`
    //     );

    //     ;

    //     if (res.data) {
    //       setTicket(res.data);
    //       fetchComments(res.data._id);
    //     } else {
    //       setTicket(null);
    //     }
    //   } catch (error) {
    //     ;
    //   } finally {
    //     setPageLoading(false);
    //   }
    // };

    // fetchTicketDetails();

    const fetchTicketDetails = async () => {
      const urls = [
        `${baseUrl}/api/ticket/individual/${ticketUID}`,
        `${baseUrl}/api/ticket/linked-response/${ticketUID}`,
      ];

      try {
        const [res1, res2] = await Promise.all(
          urls.map((url) => axios.get(url))
        );

        if (res1.data) {
          setTicket(res1.data);
          fetchComments(res1.data._id);
        } else {
          setTicket(null);
        }

        if (res2.data) {
          setLinkedResponses(res2.data);
        } else {
          setLinkedResponses([]);
        }
      } catch (error) {
      } finally {
        setPageLoading(false);
      }
    };

    fetchTicketDetails();

    return () => {
      setTicket(null);
      setPageLoading(true);
    };
  }, [ticketUID]);

  const questions = React.useMemo(() => {
    if (!ticket) {
      return [];
    }

    let questionsDetails = [];
    const projectId = ticket.project_id;

    const project = ticket.response_id.answers[projectId];

    if (!project) {
      return [];
    }

    project.forEach((question) => {
      if (question.type !== 'nps') {
        return;
      }

      const questionTitle = question.title;
      const questionAnswer = question.answer.value;

      questionsDetails.push({
        title: questionTitle,
        answer: questionAnswer,
      });
    });

    return questionsDetails;
  }, [ticket]);

  const handlePostComment = async () => {
    setLoading(true);

    try {
      const bodyData = {
        ticketId: ticket._id,
        comment,
      };

      const res = await axios.post(`${baseUrl}/api/ticketComments`, bodyData);

      if (res.data) {
        setComment('');
        fetchComments(ticket._id);
        toast.success('Comment posted successfully');
      } else {
        toast.error('Failed to post comment');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleFollowUp = async (userContact, ticketId, link) => {
    setLoading(true);

    const bodyData = {
      emails: [userContact],
      subject: 'Follow Up',
      body: defaultMessage,
      followUp: true,
      ticketId,
      link,
    };

    try {
      const res = await axios.post(`${baseUrl}/api/bulk-email/send-email`, {
        ...bodyData,
        profile_id: localStorage.getItem('jwtProfileId'),
      });

      if (res.data.success) {
        toast.success(res.data.message);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      toast.error('Failed to send follow up email');
    } finally {
      setLoading(false);
    }
  };

  if (pageLoading) {
    return <div>Loading...</div>;
  }

  return (
    <MainBody>
      <div className='mx-3 mt-2'>
        <Breadcrumb
          path={`Ticket / Recommendation / #${ticketUID}`}
          fontSize='16px'
        />
      </div>

      <div style={{ padding: '20px' }}>
        <div className='ticket__header'>
          <div className='header__topbar'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
              }}
            >
              <span>
                Created On: {moment(ticket.createdAt).format('DD-MM-YY')}
              </span>

              <span>
                Updated On: {moment(ticket.updatedAt).format('DD-MM-YY')}
              </span>
            </div>

            <Button
              style={{
                background: '#1B998B',
                borderColor: '#1B998B',
                borderRadius: '7px',
                padding: '4px 15px',
                fontSize: '13px',
                fontWeight: '500',
              }}
              className='btnHover'
              size='sm'
              onClick={() => {
                const userContact = {
                  name: ticket.response_id.contactCard.name,
                  email: ticket.response_id.contactCard.email,
                };

                handleFollowUp(
                  userContact,
                  ticket.uid,
                  ticket.response_id.surveyLink
                );
              }}
              disabled={loading}
            >
              Follow Up
            </Button>
          </div>

          <div className='mt-3'>
            <label style={{ fontWeight: 500 }}>Assignee:</label>

            <select
              style={{
                width: '160px',
                display: 'inline-block',
                marginLeft: '10px',
                padding: '5px 10px',
                border: 'none',
                outline: 'none',
                borderRadius: '6px',
              }}
            >
              <option value={ticket.asignee._id}>
                {ticket.asignee.firstName} {ticket.asignee.lastName}
              </option>
            </select>
          </div>

          <div className='mt-2'>
            <label style={{ fontWeight: 500 }}>Priority:</label>

            <select
              style={{
                width: '160px',
                display: 'inline-block',
                marginLeft: '10px',
                padding: '5px 10px',
                border: 'none',
                outline: 'none',
                borderRadius: '6px',
              }}
              defaultValue={ticket.priority}
            >
              <option value='High'>High</option>
              <option value='Medium'>Medium</option>
              <option value='Low'>Low</option>
            </select>
          </div>

          <div className='mt-2'>
            <label style={{ fontWeight: 500 }}>Status:</label>

            <select
              style={{
                width: '160px',
                display: 'inline-block',
                marginLeft: '10px',
                padding: '5px 10px',
                border: 'none',
                outline: 'none',
                borderRadius: '6px',
              }}
              defaultValue={ticket.status}
            >
              <option value='New'>New</option>
              <option value='Open'>Open</option>
              <option value='Resolved'>Resolved</option>
              <option value='Escalated'>Escalated</option>
            </select>
          </div>
        </div>

        <div className='ticket__response__body'>
          <div className='main__response'>
            <h5 style={{ padding: '20px', margin: 0 }}>Response</h5>

            <hr style={{ margin: 0 }} />

            <div className='main__responses__inner'>
              {questions.map((question, index) => (
                <div className='single__question' key={index}>
                  <p
                    style={{
                      margin: 0,
                      marginBottom: '6px',
                      fontSize: '16px',
                      fontWeight: 500,
                    }}
                  >
                    {index + 1}. {question.title}
                  </p>

                  <div className='d-flex align-items-md-center'>
                    <span className='inline-block mr-3'>Ans:</span>
                    <div className='options__container'>
                      {Array(11)
                        .fill(0)
                        .map((_, i) => (
                          <span
                            key={i}
                            className='option'
                            style={{
                              width: '26px',
                              height: '26px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              background:
                                question.answer === i.toString()
                                  ? '#1B998B'
                                  : 'transparent',
                              color:
                                question.answer === i.toString()
                                  ? '#fff'
                                  : '#000',
                              borderRadius: '50%',
                            }}
                          >
                            {i}
                          </span>
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className='linked__response'>
            <h5 style={{ padding: '20px', margin: 0 }}>Linked Response</h5>

            <hr style={{ margin: 0 }} />

            <div className='linked__response__inner'>
              {linkedResponses.length === 0 ? (
                <p>No Data Found</p>
              ) : (
                linkedResponses.map((response, index) => (
                  <div className='linked__response__item'>
                    <p>
                      <span>Created On :</span>{' '}
                      {moment(response.responseDate).format('DD-MM-YY')}
                    </p>

                    <p>
                      <span>Ticket Id :</span> {response.ticketId}
                    </p>

                    <Link to={`#`}>View Details</Link>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <div className='comments__container'>
          <div className='comments__container__body'>
            <h5>Comments</h5>

            <div className='comments__body__inner'>
              <div className='user__avatar'>
                {localStorage.getItem('userName').slice(0, 2)}
              </div>

              <div className='w-100 d-flex flex-column'>
                <textarea
                  rows={5}
                  placeholder='Write your comment here...'
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />

                <div>
                  <Button
                    style={{
                      background: '#1B998B',
                      borderColor: '#1B998B',
                      borderRadius: '7px',
                      padding: '6px 20px',
                      fontSize: '13px',
                      fontWeight: '500',
                      marginTop: '16px',
                    }}
                    className='btnHover'
                    size='sm'
                    onClick={handlePostComment}
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainBody>
  );
};

export default withRouter(TicketDetails);
