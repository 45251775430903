import React, { useState, useEffect } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilRuler } from '@fortawesome/free-solid-svg-icons'

import { Body, ProjectItem, Project, Edit, Delete, Banner } from './StyledComponents'
import { Modal } from '../StyledComponents'
import CreateProject from './components/CreateProject'
import IndividualProject from '../ProjectIndividual'
import axios from 'axios';
import { fetchProject, postProject } from '../../url';
import Loader from '../Loader';
import Can from '../../components/Can'
import TableView from './TableView'
import BreadCrumb from '../../components/Breadcrumb/breadcrumb'


const Projects = props => {
  /*state-------------------------------------------------------------*/
  const [project, setProject] = useState([]);
  const [loading, setLoaded] = useState(false);

  const [edit, setEdit] = useState({
    status: false,
    projectName: "",
    projectType: "",
    projectId: ""
  })
  const [view, setView] = useState(false)
  /*state-------------------------------------------------------------*/
  useEffect(() => {
    const fetchData = async () => {
      // console.log(fetchProject(localStorage.getItem('jwtID')));
      // console.log(fetchProject(localStorage.getItem('jwtID')))
      try {
        // console.log(fetchProject(localStorage.getItem('jwtID')))
        console.log('project')
        let res = await axios.get(fetchProject(localStorage.getItem('jwtProfileId')))
        console.log(res)
        let data = res.data.projectList.map((project) => {
          const filteredData = {
            id: project._id,
            projectName: project.name,
            // projectType: project.projectType
          }
          return filteredData;
        })

        setProject([
          ...project,
          ...data
        ])
      }
      catch (err) {
        // console.log(err)
      }
      finally {
        setLoaded(true)
      }
    };

    fetchData();
  }, []);

  //Function to add new project, passed to CreateProject component to get projectName and projectType
  const addNew = async (projectName, projectType) => {

    const data = {
      "projectName": projectName,
      "projectType": projectType,
      "createdByCompanyID": localStorage.getItem('jwtProfileId')
    }

    axios.post(postProject, data)
      .then((response) => {

        setProject([
          ...project,
          {
            id: response.data._id,
            projectName,
            projectType
          }
        ])

      })
      .catch((err) => {

        // console.log(err)

        // setProject([])

        // setLoaded({ load : true })

      })
  }

  const editProject = (projectId, projectName, projectType) => {
    // console.log(projectId, projectName, projectType);
  }
  //generates the projects from the state information
  const items = project.map((item, index) => (

    <ProjectItem key={index}>
      <Link
        css={`
          box-sizing: border-box; margin: 0; padding: 0;
          display: block; position: absolute; top: 0; left: 0;
          padding: 10px; width: 100%; height: 100%; z-index: 5;
        `}  
        to={`${props.match.url}/${item.id}`}
      >
      </Link>

      <Project >
        <div
          css={`
            text-align: center;
            padding-top:10px;
            `}
        >
          <FontAwesomeIcon
            icon={faPencilRuler}
            css={`
                font-size: 50px;
                color: #310D31;
                padding-top:10px;
                :hover { color: #310D31; }
                @media only screen and (max-width: 768px) { }
                @media only screen and (max-width: 425px) { padding-top:15px; }
            `} 
          />
        </div>

        <div
          css={`
            display: flex;
            position:relative;
            align-items:center;
            justify-content:center;
            background-color:#310D31;           
          `}
        >
          <div> Project Name: {item.projectName}</div>
          {/* <div> Project Type: {item.projectType}</div> */}
        </div>

        <Can
          feature="project"
          perform="edit"
          yes={() => (
            <Edit
              item={ProjectItem}
              onClick={() => {
                setView(!view)
                setEdit({
                  ...edit,
                  status: true,
                  projectId: item.id,
                  projectName: item.projectName,
                  projectType: item.projectType
                })
              }
              }
            >
              Edit
              </Edit>
          )}
        />

        {/* <Delete item={ProjectItem} onClick={()=>{console.log("Delete dispatch")}}>Delete</Delete> */}
      </Project>

    </ProjectItem>
  ))
  const styles = {
    button: {
      color: '#ffffff',
      background: '#1B998B',
      borderColor: '#1B998B',
      margin: '11px 0px',
      padding: '.3rem .55rem',
      fontSize: '.875rem',
      lineHeight: '1.5',
      borderRadius: '.2rem',
      textDecoration: 'none',
      "&:hover": {
        color: '#ffffff',
        background: '#0B6157 !important',
        textDecoration: 'none'
      },
    } 
  } 
  //initial view of the projects tab showing all the created projects and creating new projects
  const ProjectView = props =>
    (<div className="container">
      <div className="row">
        <Body> 
          {/* <Banner>Projects</Banner>
            <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget) {
                  setEdit({
                    ...edit,
                    status: false,
                  })
                  setView(false)
                }
              }}
            >
              {edit.status && <CreateProject edit={edit} editProject={editProject} close={{ setView, setEdit }} />}
              {!edit.status && <CreateProject addProject={addNew} close={{ setView, setEdit }} />}
            </Modal>

            
            <Can
              feature="project"
              perform="create"
              yes={() => (
                <ProjectItem>
                  <Project
                    onClick={(e) => { e.preventDefault(); setView(true) }}
                  >
                    Add New Project
                  </Project>
                </ProjectItem>
              )}
            /> */}
            {/* <BreadCrumb path="Projects /"/>  */}
            <h6 style={{color: '#1B998B'}}> Projects / </h6> 
            <Link 
              to={`${props.match.path}/create`} 
              style={{ 
                lineHeight: '1.5', borderRadius: '.2rem', textDecoration: 'none',
                background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 0px', 
                padding:'5px 15px', fontSize:'13px', fontWeight:'500', color: '#ffffff' 
              }} 
              className="btnHover"> 
              Create New 
            </Link> 
            <TableView /> 
          </Body> 
        </div> 
      </div> 
    ) 

  //The routes are for main project tab and individual projects
  return (
    <>
      {
        loading.load === false ?
          (
            <Loader />
          ) : (

            <Switch>
              <Route path={`${props.match.path}`} exact component={ProjectView} />
              <Route path={`${props.match.path}/:id`} component={IndividualProject} />
              <Route path={`${props.match.path}/create`} component={IndividualProject} />
            </Switch>

          )
      }
    </>
  )
}

export default Projects