import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
//Related to components
import { Body } from './styles'
import Card from '../Card'
//Redux Actions
// import {fetchData, initialize, reset} from '../../redux/actions'
import { fetchData } from '../../redux/actions'
//Selectors
// import {combinedTally, getFilteredData} from '../../redux/selectors/old'
import { formattedResponseSelector, isFetchingSelector, isLoadingSelector } from '../../redux/selectors'

const Main = props => {
  // const {fetchData, initialize, data, reset, projectId, loading, tally} = props
  const { loading, fetching, data, projectId, fetchData } = props

  useEffect(() => {
    // reset()
    // data()
    // initialize(projectId, localStorage.getItem("jwtID"))
    fetchData(projectId, localStorage.getItem("jwtProfileId"))
  }, [fetchData])

  // try{
  //   console.log(tally)
  // }
  // catch(err){
  //   console.log(err)
  // }

  // console.log(data)

  const item = data.map((element, index) =>
    <Card
      key={index}
      title={element.title}
      type={element.type}
    // date={element.date} 
    // device={element.device}
    />
  )

  return (
    <Body>
      {loading || fetching ? "Loading" : item}
    </Body>
  )
}

Main.propTypes = {
  //from mapStateToProps
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool.isRequired,
  fetching: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  // initialize: PropTypes.func.isRequired,
  // tally: PropTypes.arrayOf(PropTypes.object).isRequired,
  //from mapDispatchToProps
  fetchData: PropTypes.func.isRequired,
  // reset: PropTypes.func
}

const mapStateToProps = (state, ownProps) => {
  return {
    // tally: combinedTally(state),
    data: formattedResponseSelector(state),
    loading: isLoadingSelector(state),
    fetching: isFetchingSelector(state),
    projectId: ownProps.projectId
  }
}

const mapDispatchToProps = {
  fetchData: (projectId, id) => fetchData(projectId, id),
  // initialize: (projectId, id) => initialize(projectId, id),
  // reset: ()=>reset()
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(memo(Main)))