import React from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'

import one from './resources/1.gif'
import two from './resources/2.gif'
import three from './resources/3.gif'
import four from './resources/4.gif'
import five from './resources/5.gif'
import six from './resources/6.gif'
import seven from './resources/7.gif'
import {Scale, Rating} from './styles'

import {setQuestionIdForCondition} from '../../../redux/actions'

const JumpQuestionSelector = props=>{
  return(
    <Select
      // className='react-select-container'
      // classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base=>{
          return{
            ...base,
            boxSizing: 'border-box',
            // minHeight: "1px !important",
            height: "25px",
            width: "100%",
            flexShrink: 0
          }
        },
        control: (base) => {
          return {
            ...base,
            boxSizing: 'border-box',
            // boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover':{
              ...base[':hover'],
              // boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active':{
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style=>{
          return{
            ...style,
            padding: "0 5px"
          }
        }
      }}
      // id="channel"
      // isClearable={true}
      placeholder="Question"
      isSearchable={false}
      options={props.options}
      value={props.value}
      onChange={props.onChange} 
    />
  )
}

const LikertScale = props=>{
  const {id, ratings, scale, questionsAfter, isConditional, setQuestionIdForCondition, jump, projectId} = props
  
  // console.log(jump)

  let icons
  switch(scale){
    case 3:
      icons = [one, four, seven]
      break
    case 5:
      icons = [one, three, four, six, seven]
      break
    case 7:
      icons = [one, two, three, four, five, six, seven]
      break
    default: 
      icons = [one, three, four, six, seven]
  }
  const ratingList = ratings.map((item, index)=>{

    const jumpValue = jump.find(item=>item.jumpOptionIndex===index) 
    const formattedJumpValue = jumpValue? 
      questionsAfter.find(item=>item.value===jumpValue.jumpTo) :
      ({label: 'None', value: null})

    const handleChange = selected=>{
      setQuestionIdForCondition(projectId, id, index, selected.value)
    }

    return(
      <Rating key={`${id}_${index}`} scale={scale}>
        <img src={icons[index]} alt={`${item} icon`}/>
        <label>{item}</label>
        {
          isConditional && 
          questionsAfter.length!==2 && 
          <JumpQuestionSelector 
            options={questionsAfter} 
            value={formattedJumpValue} 
            onChange={handleChange}
          />
        }
      </Rating>)
    })

  return(
    <Scale>
      {ratingList}
    </Scale>
  )
}

const mapStateToProps = (state, ownProps)=>({
  ...ownProps
})

const mapDispatchToProps = {
  setQuestionIdForCondition
}

export default connect(mapStateToProps, mapDispatchToProps)(LikertScale)