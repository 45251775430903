import React from 'react'

const Device = props => {
    return (
        <label className="dislabel" style={{ margin: '10px' }}>
            {props.device.name}
            <input
                name="active"
                type="checkbox"
                checked
                disabled
            // //   onChange={event => {
            // //     const target = event.target;
            // //     const value = target.type === 'checkbox' ? target.checked : target.value;
            // //     // props.setDeviceActive(value, props.index);
            // //     // props.deviceStatusOnClick();
            // //   }
            //   }
            />
        </label>
    )
}

const DeciceList = props => {
    return (
        <div>
            <h4>Device</h4>
            {
                props.deviceList.map(device => <Device device={device} key={device._id} />)
            }
        </div>
    )
}

export default DeciceList