import React from 'react'

function AntiSpam() { 
  const style = {
    p: { color: 'rgb(52, 58, 64)', fontSize: '13px', textAlign: 'justify', fontFamily: 'Roboto', fontWeight: '400' }, 
    list: { color: 'rgb(52, 58, 64)', fontSize: '13px', textAlign: 'justify', fontFamily: 'Roboto', fontWeight: '400', marginLeft: '30px' } 
  } 
  
  return ( 
    <div className="my-5 ml-3 text-justify"> 

      <section id="anti-spam-policy-prolificxm-anti-spam-policy"> 
        <h3 className="text-center">ProlificXM Anti-Spam Policy</h3> 
        <p style={{...style.p}}> 
        The Anti-Spam Policy is applicable to Customers who use ProlificXM Services and collect Survey responses through Email or Text Messages. It is important for us, as well as for you to send emails and text messages to the person with whom you may have a pre-existing relationship and/or the recipient permits you to send electronic messages. Except for these cases, your emails or text messages may be considered as Spam (Unsought Emails sent from unknown sources). 
        <br /> <br /> 
        ProlificXM offers Customers to send emails and text messages to Survey participants with the purpose to collect responses. However, we do not encourage our Customers to use the Service to communicate with recipient who does not expect any such email or messages. If we find out that, the content you are sending violates our policy, we may warn you or suspend or terminate your account based on the severity of your actions. We may change our Anti-Spam policies any time and it is your responsibilities to keep updated with the changes. If you do not comply with the then-current policies, we may take foreseeable action against your account.
      </p> 
      </section> 



      <section id="anti-spam-policy-how-to-avoid-sending-spam">
        <h5 className="text-center">How to Avoid Sending Spam</h5>
        <p style={{...style.p}}>
          If you are unaware of the policy regarding being considered as Spam, it is your responsibility to know it. We are providing an outline which you may follow in order to avoid sending Spam to your participants.
        </p>  
        <ul style={style.list}> 
          <li>While sending email, make sure that your email contains a proper Subject and does not contain any misleading information. Due to misleading subject may, system may identify the email as Spam and all other emails you send with similar subject line afterwards.</li>
          <li>Make sure that the email has a specific name of the sender and a valid reply-to email address.</li>
          <li>Ensure that your email is not going to non-specific email addresses such as: info@domain.com, sales@domain.com, or general email addresses.</li>
          <li>If your email contains third party information and you send it without any of their consent, your email might be marked as Spam. So it is best to avoid this practice.</li>
        </ul>
        <p style={{...style.p}}>
          In accordance, you may avoid doing few more things such as:
        </p>  
        <ul style={style.list} type="disc">
        <li>Sending promotional emails or campaigns with Survey.</li>
        <li>Adding explicit content in your email.</li>
        <li>Sending email to someone who has asked not to send email previously.</li>
        <li>Sending email to leads you have rented or harvested from other sources.</li>
      </ul>
      </section>



      <section id="anti-spam-policy-our-stand-against-spam">
        <h5 className="text-center">Our Stand against Spam</h5>
        <p style={{...style.p}}>
          We have zero tolerance policy against Spamming. Therefore, if we can identify any spammer using our Services or receive any report of spamming against any of our Customers, we are bound to investigate the issue and take necessary action. The action might be extended up to termination of an account and cancellation of a paid Subscription. Therefore, we advise our Users not to engage in any Spamming activity. We maintain following steps to prevent sending Spam using our website.
        </p>  
        <ul style={style.list}>
        <li>When an email is send using our Services, we save a copy of the email.</li>
        <li>We enable default Username and email address of behalf of the sender.</li>
        <li>We include opt-out options with each email by default so that the recipient can opt-out any time from receiving future emails from us. Those who choose the option to unsubscribe, we update their email address in our database so that, in future they no longer receive any email from us.</li>
        <li>As soon as we receive a report of Spamming against any of our Users, we immediately investigate it. If the report is valid, we take action according to our Anti-Spam Policy.</li>
        <li>If we can identify any of our customers associated with Spamming using our Services, we restrict the Customer from using our Services and take further action to prevent it.</li>
      </ul> 
      </section> 



      <section id="anti-spam-policy-reporting-spam">
        <h5 className="text-center">Reporting Spam</h5>
        <p style={{...style.p}}>
          If you are a victim of spamming from our website or by any of our Customers, we advise you to report to us. We will take necessary measure to prevent such activity and defend our stand against Spamming.  You can report via email at {` `} <span style={{color: '#1B998B', fontWeight: '500'}}>
            support@prolificxm.com
          </span>.
        </p> 
      </section>
    </div> 
  ) 
} 

export default AntiSpam
