import { combineReducers } from 'redux'
import { formatByQuestion } from './caseFunctions'

import {
  FETCH_RESPONSE,
  RECIEVE_RESPONSE,
  SET_IDENTIFICATION,
  RESET,
  FORMAT_BY_QUESTION
} from '../constants/actions'

function fetchedData(
  state = {
    isDataFetching: false,
    isLoading: false,
    response: [],
    formattedResponse: [],
    // question: [],
    device: [],
    openLink: [],
    qrCode: []
  },
  action) {
  switch (action.type) {

    case FETCH_RESPONSE:
      return {
        ...state,
        isDataFetching: true,
        isLoading: true
      }

    case RECIEVE_RESPONSE:

      return {
        ...state,
        isDataFetching: false,
        response: action.response,
        // question: action.question,
        device: action.device,
        openLink: action.openLink,
        qrCode: action.qrCode || []
      }

    case RESET:
      return {
        isDataFetching: true,
        response: [],
        // question: [],
        device: []
      }

    case FORMAT_BY_QUESTION:
      return formatByQuestion(state, action)

    default:
      return state
  }
}

function setup(
  state = {
    id: "",
    projectId: ""
  },
  action) {
  switch (action.type) {
    case SET_IDENTIFICATION:
      const { id, projectId } = action
      return {
        ...state,
        id,
        projectId
      }

    case RESET:
      return {
        id: "",
        projectId: ""
      }

    default:
      return state
  }

}



export default combineReducers(
  {
    data: fetchedData,
    id: setup
  }
)