import React, {useState} from 'react' 
import { Link } from 'react-router-dom'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import { css } from 'glamor'
import './footer.css' 

function Footer() { 
  const [reqDemo, setReqDemo] = useState(false); 
  const cancelModal = () => setReqDemo(false); 

  const reqDemoCss = css({ fontSize: '13px', cursor: 'pointer' }) 
  const footerOneColWidth = css({ 
    '@media(max-width: 768px)': { width: '33% !important' }, 
    '@media(max-width: 500px)': { width: '50% !important' }, 
    '@media(max-width: 350px)': { width: '100% !important' }, 
  }) 

  return ( 
    <div> 
      <footer className="mainfooter pb-2" role="contentinfo"> 
        <div className="container footer-middle"> 
          <div className="row"> 
            <div className="col-sm-6 col-md-4 col-lg-3" {...footerOneColWidth}>
              <div className="footer-pad">
                <h6>Take a Tour</h6>
                <ul className="footer-li list-unstyled">
                  <li><Link style={{fontSize: '13px'}} to="/blog">Blog</Link></li>
                  <li><Link style={{fontSize: '13px'}} to="/pricing">Pricing and Plan</Link></li>
                  <li><Link style={{fontSize: '13px'}} to="/terms">Terms & conditions</Link></li> 
                  <li><Link style={{fontSize: '13px'}} to="/aboutus">About Us</Link></li> 
                </ul> 
              </div> 
            </div> 
            <div className="col-sm-6 col-md-4 col-lg-3" {...footerOneColWidth}>
              <div className="footer-pad">
                <h6>What we do</h6>
                <ul className="footer-li list-unstyled"> 
                  <li><Link style={{fontSize: '13px'}} to="/prolificxm-features">ProlificXM Feature</Link></li>
                  <li><Link style={{fontSize: '13px'}} to="/xm">XM</Link></li>
                  <li><Link style={{fontSize: '13px'}} to="/survey">Survey</Link></li> 
                  <li> <span onClick={() => setReqDemo(!reqDemo)} {...reqDemoCss}> Request A Demo </span> </li> 
                </ul>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3" {...footerOneColWidth}>
              <div className="footer-pad"> 
                <h6>Quick Links</h6> 
                <ul className="footer-li list-unstyled"> 
                  <li><Link style={{fontSize: '13px'}} to="/prolificxm-features/Customer-Experience">
                    Customer Experience</Link>
                  </li> 
                  <li><Link style={{fontSize: '13px'}} to="/prolificxm-features/Employee-Experience">
                    Employee Experience</Link>
                  </li> 
                  <li><Link style={{fontSize: '13px'}} to="/prolificxm-features/Brand-Experience">
                    Brand Experience</Link>
                  </li> 
                  <li><Link style={{fontSize: '13px'}} to="/prolificxm-features/Product-Experience">
                    Product Experience</Link>
                  </li> 
                </ul> 
              </div> 
            </div> 
            <div className="col-sm-6 col-md-4 col-lg-3" {...footerOneColWidth}>
              <h6>Contact Us</h6>
              <span style={{display: 'block'}} className="mb-1">
                <i className="far fa-envelope-open"></i> 
                <span style={{fontSize: '13px', position: 'relative', left: '10px'}}>
                  info@prolificxm.com
                </span>
              </span>
              <span style={{display: 'block'}} className="mb-1">
                <i className="fas fa-phone"></i>
                <span style={{fontSize: '13px', position: 'relative', left: '10px'}}>
                  +88 01302605678
                </span>
              </span>
              <span style={{display: 'block'}} className="mb-1">
                <i className="fab fa-facebook-square"></i>
                <span style={{fontSize: '13px', position: 'relative', left: '10px', color: '#fff'}}>
                  <a href="https://www.facebook.com/prolificxm" target="_blank" style={{color: '#fff'}}> 
                    ProlificXM 
                  </a> 
                </span> 
              </span> 
              <span style={{display: 'block'}} className="mb-1">
                <i className="fab fa-linkedin"></i>
                <span style={{fontSize: '13px', position: 'relative', left: '10px'}}>
                  <a href="https://www.linkedin.com/company/prolificxm" target="_blank" style={{color: '#fff'}}>
                    ProlificXM 
                  </a> 
                </span>
              </span> 
            </div>
          </div> 
        </div> 
        <center> 
          <span style={{fontSize: '12px'}}>
            &copy; 2020 Prolific Analytics Ltd.
          </span>
        </center>
      </footer> 

      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    </div> 
  ) 
} 

export default Footer; 
