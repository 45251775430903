import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const LinkButton = styled(Link)`
  display: block;
  outline: none;
  border: 1px solid grey;
  background-color: blue;
  text-decoration: none;
  color: white;
  border-radius: 2px;
  position: absolute;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 5px 10px;
  bottom: 0;
  right: 0;
  box-shadow: 2px 2px 10px 1px grey; 
  z-index: 5;
  &:hover{
    text-decoration: none;
    background-color: purple;
    color: white;
    box-shadow: 2px 2px 5px 2px grey; 
  }
`