import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro'
import axios from 'axios';
import Avatar from '../../resources/download.png'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEdit , faPlusCircle , faSave } from '@fortawesome/free-solid-svg-icons'
import {Button, Input, Label,Body, UploadContainer, UploadButton, Upload, Banner} from './StyledComponents'
import {fetchProfile , putProfile} from '../../url';
import {Formik} from 'formik';

const UserProfile = props=>{
  
  const [editable, setEditable ] = useState({
      data: false,
      picture: false,
      loaded:''
  })

  useEffect(() => {
    const fetchData = async () => {
      const result = await axios(fetchProfile);

      setEditable({
        ...editable,
        loaded:result.data[0]
      })
      
    };

    fetchData();
  }, []);

  const editHandler = () => {
    setEditable({
      ...editable,
      data : !editable.data
    })
  }

  const postFormHandler = async (values) => {

    const data = {
      "user_Id":"5cc6ae864bac67563d2c7319",
      "user_Name": values.name,
      "designation": values.designation,
      "organization": values.organization,
      "website": values.website ,
      "mobile": values.mobile ,
      "email": values.email ,
      "address": values.address ,
      "picture": "pa/pic"
    }

     axios.put(putProfile,data)
     .then(result=>{
      
      setEditable({
        ...editable,
        data : !editable.data,
        loaded:result.data
      })

     })
     .catch(err => {
      //  console.log(err)
      })
    
     
    editHandler();
  }


  const changePictureHandler = () => {

    setEditable({
      ...editable,
      picture : !editable.picture
    })

  }

  return(

    <React.Fragment>
      {
        editable.data === false ?  
        (
          <Body>
          <Banner>User Profile</Banner>

            <img 
              alt="avatar"
              src={Avatar}
              css={`
                vertical-align: middle;
                width: 120px;
                margin-bottom: 20px;
                box-shadow: 0 0 3px grey;
                border-radius: 50%;
                border: 4px solid #310D31; 
              `}
            />

            <Label css={`color:#310D31;font-weight:bold;font-size: 18px`}>KHAN AFIF NEWAZ NIRJHAR</Label> <br/>
            {editable.loaded.user_Name} 
            <Label css={`font-weight:bold`}>Jr. Business Development Specialist</Label>
            {editable.loaded.designation}
            <Label>Prolific Analytics</Label>
            {editable.loaded.organization}
            <Label>www.prolificanalyticsinc.com</Label>
            {editable.loaded.website}
            <Label>+8801844489706</Label>
            {editable.loaded.mobile}
            <Label>nirjhor.prolific@gmail.com</Label>
            {editable.loaded.email}
            <Label css={`width 30%`}>House #15, Road #07, Block #C, Niketon, Gulshan-1, Dhaka-1212, Bangladesh</Label>
            {editable.loaded.address}

            <Button onClick={editHandler}>
              <FontAwesomeIcon
                icon={faEdit}
                css={`margin: 0 5px 0 0`}
              />
              Edit
            </Button>

          </Body>
        ) : 
        (
          <Body>
              <Banner>Edit Profile</Banner>
            <img 
              alt="avatar"
              src={Avatar}
              css={`
                align-self: center;
                width: 150px;
                margin-bottom: 10px;
                box-shadow: 0 0 3px grey;
                border-radius: 50%;
              `}
            />

            <UploadContainer>
              <Upload type="file" name="company_logo"/>
              <UploadButton>
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  css={`margin: 0 5px 0 0; color: white;`}
                />
                Upload
              </UploadButton>
            </UploadContainer>

            <br/>

            <Formik
              initialValues={{
                name: editable.loaded.user_Name,
                designation:editable.loaded.designation,
                organization:editable.loaded.organization,
                website:editable.loaded.website,
                mobile:editable.loaded.mobile,
                email:editable.loaded.email,
                address:editable.loaded.address
              }}
              onSubmit={(values)=> postFormHandler(values)}
              render= {({handleSubmit, handleChange, handleBlur, values, errors, touched})=>(
                <form onSubmit={handleSubmit} css={`width:100%;`}>
                    
                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        NAME:
                    </div>
                    <div css={`width: 60%`}>
                        <Input
                        name="name"
                        type="text"
                        value={values.name}
                        onChange={handleChange}
                        />
                    </div>
                    
                    </Label><br/>


                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        DESIGNATION:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="designation"
                        type="text"
                        value={values.designation}
                        onChange={handleChange}
                        />
                    </div>
                    </Label><br/>

                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        ORGANIZATION:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="organization"
                        type="text"
                        value={values.organization}
                        onChange={handleChange}
                        />
                    </div>
                    </Label><br/>

                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        WEBSITE:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="website"
                        type="text"
                        value={values.website}
                        onChange={handleChange}
                        />
                    </div>
                    </Label><br/>

                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        MOBILE:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="mobile"
                        type="text"
                        value={values.mobile}
                        onChange={handleChange}
                        />
                    </div>
                    </Label><br/>

                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        EMAIL:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="email"
                        type="text"
                        value={values.email}
                        onChange={handleChange}
                        />
                    </div>
                    </Label><br/>

                    <Label>
                    <div css={`width: 30%;text-align:left;`}>
                        ADDRESS:
                    </div>
                    <div css={`width:60%`}>
                        <Input
                        name="address"
                        type="text"
                        value={values.address}
                        onChange={handleChange}
                        />
                    </div>
                    </Label>

                    <Button type="submit">
                    <FontAwesomeIcon
                        icon={faSave}
                        css={`margin: 0 5px 0 0`}
                    />
                    Save Info
                    </Button>
                    
                </form>
              )}
            />
      
          </Body>
        )
      }


    </React.Fragment>
  )
}

export default UserProfile