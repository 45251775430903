import React, {useState, useEffect, Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Label, Input } from 'reactstrap'; 
import { Formik } from 'formik' 
import * as Yup from 'yup' 
import showToast from '../../../../../components/Toast/toast' 
import { withRouter } from 'react-router-dom' 
import BreadCrumb from '../../../../../components/Breadcrumb/breadcrumb'
import { createDept, getAllDept, backButtonFunction } from '../../store/actions/actionCreator'
import '../buttonCss.css' 

const CreateDept = (props) => { 
    const { history } = props; 
    const dispatch = useDispatch(); 
    let { allData } = useSelector(state => state.department) 

    try { 
        if(!Object.keys(allData).length) { allData.departments = [] } 
    } 
    catch (error) { } 
    
    

    const CreateToast = () => showToast('Created Successfully') 
    const ErrorToast = () => showToast('Server Error') 

    useEffect(() => { 
        dispatch(getAllDept(ErrorToast)) 
    }, []) 


    return ( 
        <Fragment> 
            <div className="container"> 
            <BreadCrumb path="Department" slug="Create" link="/app/department/" backButtonFunction={backButtonFunction}/> 

            <Card className="my-3 "> 
                <CardBody> 
                <Formik 
                    initialValues={{ name:'', status: true, description: '' }} 
                    validationSchema={ 
                        Yup.object().shape({ 
                            name: Yup 
                                .string().required("Required").trim().lowercase()
                                .notOneOf(allData ? allData.departments.map(obj => obj.name.toLowerCase()) : [], "Already Exist"), 
                        }) 
                    } 
                    onSubmit={(values, { resetForm }) => { 
                        let createdData = { 
                            name: values.name, 
                            active: values.status, 
                            description: values.description, 
                            createdAt: Date.now() 
                        } 
                        
                        dispatch(createDept(createdData, CreateToast, ErrorToast)); 
                        resetForm(); 
                        history.push('/app/department/'); 
                    }}> 
                    { 
                    props => ( 
                        <Form onSubmit={props.handleSubmit}> 
                            <FormGroup row> 
                                <Label htmlFor="name" sm={2}> 
                                    Name: <span style={{color: 'red'}}>{` *`}</span>
                                </Label> 
                                <Col sm={4}> 
                                    <Input type="text" name="name" id="name" 
                                        style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                        value={props.values.name} onChange={props.handleChange} 
                                        onBlur={props.handleBlur} placeholder="Enter department name"
                                    /> 
                                    { 
                                        props.errors.name && props.touched.name && 
                                        <p style={{ 
                                            color: 'red', fontSize: '12px', 
                                            marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                        }}> 
                                            {props.errors.name} 
                                        </p> 
                                    } 
                                </Col> 

                                <Label htmlFor="status" className="mt-1">Active:</Label> 
                                <Col > 
                                    <Input 
                                        type="checkbox" 
                                        id="status" 
                                        name="status" 
                                        className="form-check-input mt-2" 
                                        checked={props.values.status} 
                                        onChange={props.handleChange} 
                                        onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            <FormGroup row> 
                                <Label htmlFor="description" sm={2}> Description: </Label> 
                                <Col> 
                                    <Input type="textarea" name="description" id="description" 
                                        value={props.values.description} onChange={props.handleChange} 
                                        onBlur={props.handleBlur} /> 
                                </Col> 
                            </FormGroup> 
                            
                            <Button 
                                style={{ background: '#1B998B', borderColor: '#1B998B' }} 
                                className="btnHover"
                                size="sm" type="submit" > 
                                Save 
                            </Button> 
                            
                            <Button style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 10px' }} size="sm" 
                                className="btnHover" onClick={() => { history.push('/app/department'); }}> 
                                Cancel 
                            </Button> 
                        </Form> 
                    ) 
                    } 
                </Formik> 
            </CardBody> 
            </Card> 
            </div> 
        </Fragment> 
    ) 
} 

export default withRouter(CreateDept); 