import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import './Gauge.css';

const Gauge = (props) => {

    const { title, legened, round } = props;
    let value = props.value;

    const max = props.max ? props.max : 100;
    const colors = ["#F27D7D", "#FFBF69", "#7EAB7E", "#1B998B"];
    const height = props.height ? `${props.height}px` : '140px';

    const percentage = max === 100 ? value : (value / max) * 100;

    const color = percentage <= 40 ? colors[0]
        : percentage > 40 && percentage <= 60 ? colors[1]
            : percentage > 60 && percentage <= 80 ? colors[2]
                : colors[3];

    const data = [
        {
            "id": "value",
            "label": "value",
            "value": value,
            "color": color
        },
        {
            "id": "empty",
            "label": "empty",
            "value": Number(Math.round(parseFloat((max - value) + 'e' + 2)) + 'e-' + 2),
            "color": "#EEEEEE"
        }
    ];

    return (
        <>
            {
                title &&
                <h6 data-tip={title} className="text-center gauge-title">
                    {title}
                </h6>


            }
            <div className="Gauge" style={{ width: '100%', height: height, maxHeight: "140px", maxWidth: "150px", marginLeft: "auto", marginRight: "auto" }}>
                <ResponsivePie
                    data={data}
                    margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
                    startAngle={-90}
                    endAngle={90}
                    innerRadius={0.7}
                    cornerRadius={2}
                    colors={(data) => data.color}
                    borderColor={{ from: 'color', modifiers: [['darker', '0.1']] }}
                    enableRadialLabels={false}
                    enableSlicesLabels={false}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
                <div className="value">
                    <h6>{round ? Math.round(value) : value}</h6>
                    <p>Out of {max}</p>
                </div>
            </div>
        </>
    )
}

export default Gauge;