import {combineReducers} from 'redux'

import channelConfigReducer from './channelConfigReducer'
import submitReducer from './submitReducer'
import answerReducer from './answerReducer'

const state = combineReducers({
  form: channelConfigReducer, answers: answerReducer, submit: submitReducer
})

// const state = channelConfigReducer

export default state