export const SET_NAME = 'SET_NAME';
export const SET_ACTIVE = 'SET_ACTIVE';
export const SET_STATUS = 'SET_STATUS';
export const PROFILE_ID = 'PROFILE_ID';

export const SELECT_CHANNEL = 'SELECT_CHANNEL';
export const TOGGLE_VIEW = 'TOGGLE_VIEW';
export const FETCH = 'FETCH';
// export const SUB_CHANNEL_LIST_RECEIVE = "SUB_CHANNEL_LIST_RECEIVE"
// export const SELECT_SUB_CHANNEL = "SELECT_SUB_CHANNEL"

export const PROJECT_RECEIVE = 'PROJECT_RECEIVE';
export const SELECT_PROJECT = 'SELECT_PROJECT';

export const QUESTION_RECIEVE = 'QUESTION_RECIEVE';

export const TOGGLE_QUESTION = 'TOGGLE_QUESTION';

export const SORT_QUESTION = 'SORT_QUESTION';

export const SORT_PROJECTS = 'SORT_PROJECTS';

export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const TOGGLE_REQUIRED = 'TOGGLE_REQUIRED';
export const TOGGLE_CONDITIONAL = 'TOGGLE_CONDITIONAL';
export const TOGGLE_CONDITIONAL_PREVIEW = 'TOGGLE_CONDITIONAL_PREVIEW';
export const SET_QUESTION_ID_FOR_CONDITION = 'SET_QUESTION_ID_FOR_CONDITION';
export const SET_QUESTION_FOR_CONDITIONAL_PREVIEW =
  'SET_QUESTION_FOR_CONDITIONAL_PREVIEW';
export const SET_OPTION_FOR_CONDITIONAL_PREVIEW =
  'SET_OPTION_FOR_CONDITIONAL_PREVIEW';

export const ADD_CARD = 'ADD_CARD';
export const REMOVE_CARD = 'REMOVE_CARD';

export const CHANGE_CONTACT_CARD_OPTIONS = 'CHANGE_CONTACT_CARD_OPTIONS';

export const EDIT_HEADER = 'EDIT_HEADER';
export const ADD_IMAGE = 'ADD_IMAGE';

export const SAVE_CONFIGURATION = 'SAVE_CONFIGURATION';
export const CONFIGURATION_SAVED = 'CONFIGURATION_SAVED';
export const RESET = 'RESET';
export const PUSH_CHANNEL_LIST = 'PUSH_CHANNEL_LIST';
export const DEVICE_LIST = 'DEVICE_LIST';
export const SET_DEVICE_STATUS = 'SET_DEVICE_STATUS';
export const SET_DIST_DEVICE = 'SET_DIST_DEVICE';
export const INIT_OPEN_LINK = 'INIT_OPEN_LINK';
export const SET_OPEN_LINK = 'SET_OPEN_LINK';
export const UPDATE_OPEN_LINK = 'UPDATE_OPEN_LINK';
export const SET_QR_CODE = 'SET_QR_CODE';
export const INIT_QR_CODE = 'INIT_QR_CODE';
export const SET_GEN_QR_CODE = 'SET_GEN_QR_CODE';
export const EDIT_DISTRIBUTION_CONFIG = 'EDIT_DISTRIBUTION_CONFIG';
export const EDIT_DISTRIBUTION_QUESTION = 'EDIT_DISTRIBUTION_QUESTION';
export const SET_OPEN_LINK_IN_DIST = 'SET_OPEN_LINK_IN_DIST';
export const SET_QR_CODE_IN_DIST = 'SET_QR_CODE_IN_DIST';
export const SET_ACTIVE_CHANNEL = 'SET_ACTIVE_CHANNEL';
export const SET_PROGRESSBAR = 'SET_PROGRESSBAR';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';

export const CREATE_PROJECT_FOR_SURVEY = 'CREATE_PROJECT_FOR_SURVEY';

export const SURVEY_PROJECT_STEP = 'SURVEY_PROJECT_STEP';
export const SURVEY_CONFIG_STEP = 'SURVEY_CONFIG_STEP';
export const SURVEY_PREVIEW_STEP = 'SURVEY_PREVIEW_STEP';
export const SURVEY_CHANNEL_STEP = 'SURVEY_CHANNEL_STEP';

export const SET_SURVEY_DISTRIBUTION = 'SET_SURVEY_DISTRIBUTION';
export const CHANGE_CONDITIONAL_PREVIEW = 'CHANGE_CONDITIONAL_PREVIEW';

export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';

export const RESET_STORE = 'RESET_STORE';
export const ADD_LOGO = 'ADD_LOGO';
export const ADD_LOGO_LOCAL = 'ADD_LOGO_LOCAL';
export const RESTORE_SELECTED_QUESTION_AND_ORDER =
  'RESTORE_SELECTED_QUESTION_AND_ORDER';

export const SURVEY_QUESTION_SAVE_MESSAGE_TRUE =
  'SURVEY_QUESTION_SAVE_MESSAGE_TRUE';
export const SURVEY_QUESTION_SAVE_MESSAGE_FALSE =
  'SURVEY_QUESTION_SAVE_MESSAGE_FALSE';
