import { 
    CREATE_DEPT, GET_ALL_DEPT, GET_SINGLE_DEPT, 
    UPDATE_DEPT, DELETE_DEPT, 
    BACK_BUTTON_IN_DETAIL_PAGE
} from '../actions/actionTypes'

let init = { 
    allData: [], 
    dataDetails: {}, 
} 

const departmentReducer = (state = init, action) => {
    switch (action.type) {
        case CREATE_DEPT: {
            const allDatas = state.allData.departments.concat(action.payload) 
            
            return { 
                ...state, 
                allData: { 
                    coutn: allDatas.length, 
                    departments: allDatas 
                } 
            } 
        } 
        case GET_ALL_DEPT: {
            return {
                ...state, 
                allData: action.payload
            } 
        } 
        case GET_SINGLE_DEPT: { 
            return { 
                ...state, 
                dataDetails: action.payload 
            } 
        } 
        case UPDATE_DEPT: { 
            let data = state.allData.departments.map(obj => { 
                if(obj._id === action.payload._id) { return action.payload } 
                return obj 
            }) 
            let datas = { coutn: data.length, departments: data } 
            return { ...state, allData: datas, dataDetails: {} } 
        } 
        case DELETE_DEPT: {
            let data = state.allData.departments.filter(obj => { 
                if(obj._id !== action.payload.state._id) { return obj } 
            }) 
            let datas = { coutn: data.length, departments: data } 
            
            return { 
                ...state, 
                allData: datas, 
                dataDetails: {} 
            } 
        } 
        case BACK_BUTTON_IN_DETAIL_PAGE: {
            return { 
                ...state, 
                dataDetails: {} 
            } 
        } 
        default: return state 
    } 
} 

export default departmentReducer; 

