import styled from 'styled-components'

export const Question = styled.textarea`
  box-sizing: border-box;
  border: 1px solid grey;
  resize: none;
  width: 100%;
  font-size: 15px;
  height: 35px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  border-color: ${props => props.hasError ? '#F27D7D' : '#BDBDBD'} 
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  resize: none;
  border-radius:4px;
  width: 100%;
  font-size: 13px;
  font-weight: 500;
  color:#424242;
  outline: none;
  height:30px;
  line-height:18px;
  padding: 4px 8px 4px 8px;
  overflow: hidden;
  @media screen and (max-width: 767.92px){
    font-size: 12px;
    padding: 4px 10px;
    line-height:18px;
  }
`


export const SingleLine = styled.input`
  box-sizing: border-box;
  border: 1px solid #BDBDBD;
  border-radius:3px;
  background-color:#CFD8DC;
  resize: none;
  width: 100%;
  font-size: 14px;
  height: 30px;
  line-height: 1;
  outline: none;
  padding: 10px;
  overflow: hidden;
  @media screen and (max-width: 425px){
    font-size: 10px;
    padding: 12.5px;
  }
`