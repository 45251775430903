import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import './editor.css';
import EmailModal from './EmailModal';
import { toast } from 'react-toastify';
import baseUrl, { pexUrl } from '../../../../../url';
import showToast from '../../../../../components/Toast/toast';

const defaultMessage = `
<p>Hi [Recipient’s Name],</p>
<p>I hope you are doing well.</p>
<p>Please take a few minutes to complete our survey.</p>
<p>
  To participate in the survey, please click on the following link: [Survey Link]
  <br />
  Thank you for your time!
</p>
<p>
  Best,
  <br />
  ${localStorage.getItem('userName')}
</p>
`;

const editorConfiguration = {
  plugins: [Essentials, Bold, Italic, Paragraph, Underline, Alignment, Font],
  toolbar: [
    'bold',
    'italic',
    'underline',
    'alignment:left',
    'alignment:center',
    'alignment:right',
    'alignment:justify',
    'fontFamily',
    'fontSize',
    'fontColor',
    'fontBackgroundColor',
    'link',
    'undo',
    'redo',
  ],
};

const EmailToDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e0e0e0;

  & > span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
    flex-shrink: 0;
  }

  & > div {
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    & > button {
      background: transparent;
      display: flex;
      align-items: center;
      gap: 5px;
      border: none;
      outline: none;
      color: rgba(0, 0, 0, 0.6);

      &:focus {
        outline: none;
      }

      & > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 13px;
        height: 13px;
        padding-left: 0.5px;
        padding-right: 1px;
        font-size: 16px;
        color: #00b87c;
        border: 1px solid #00b87c;
        border-radius: 50%;
        line-height: 0;
      }
    }

    .recipient-item {
      background: #f0f0f0;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 3px 8px;
      border-radius: 5px;

      & > span {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.5px;
      }

      & > button {
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        color: rgba(0, 0, 0, 0.6);

        &:focus {
          outline: none;
        }

        & > svg {
          fill: rgba(0, 0, 0, 0.6);
        }
      }
    }
  }
`;

const EmailSubjectDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0px;
  border-bottom: 1px solid #e0e0e0;

  & > span {
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
    flex-shrink: 0;
  }

  & > input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 18px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
  }
`;

const EmailMessageDiv = styled.div`
  width: 100%;
  padding-top: 8px;
  border-bottom: 1px solid transparent;

  & > span {
    display: block;
    margin-bottom: 8px;
    color: rgba(0, 0, 0, 0.6);
    font-size: 18px;
    font-weight: 500;
  }
`;

const ShortCodeButton = styled.button`
  background: rgb(27, 153, 139);
  color: white;
  border: none;
  outline: none;
  padding: 8px 15px;
  border-radius: 8px;

  &:focus {
    outline: none;
  }
`;

const Email = ({ link }) => {
  const [modal, setModal] = React.useState(false);
  const [expandRecipients, setExpandRecipients] = React.useState(false);
  const [mailData, setMailData] = React.useState({
    to: [],
    subject: '',
    message: defaultMessage,
  });
  const [loading, setLoading] = React.useState(false);

  const editorRef = React.useRef(null);

  const addTextToCursorPoint = (text) => {
    const editor = editorRef.current;

    if (editor) {
      const viewFragment = editor.data.processor.toView(text);
      const modelFragment = editor.data.toModel(viewFragment);

      editor.model.change((writer) => {
        editor.model.insertContent(
          modelFragment,
          editor.model.document.selection
        );
      });
    }
  };

  const handleClickSurveyLink = () => {
    addTextToCursorPoint('&nbsp;[Survey Link]');
  };

  const handleClickRecipientName = () => {
    addTextToCursorPoint('&nbsp;[Recipient’s Name]');
  };

  const handleSendEmail = async () => {
    if (
      mailData.to.length === 0 ||
      mailData.subject.trim() === '' ||
      mailData.message.trim() === ''
    ) {
      toast('Please fill all the fields');
      return;
    }

    setLoading(true);

    const bodyData = {
      emails: [...mailData.to],
      subject: mailData.subject,
      body: mailData.message,
      link,
    };

    try {
      const res = await axios.post(`${baseUrl}/api/bulk-email/send-email`, {
        ...bodyData,
        profile_id: localStorage.getItem('jwtProfileId'),
      });
      const { data } = res;

      if (data.success) {
        showToast(data.message);
        setMailData({ to: [], subject: '', message: defaultMessage });
      } else {
        showToast(data.message, 'error');
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <EmailModal
        modal={modal}
        setModal={setModal}
        list={mailData.to}
        setList={(list) => setMailData({ ...mailData, to: list })}
      />

      <p
        style={{
          margin: '0px',
          padding: '15px 25px',
          fontWeight: 500,
        }}
      >
        Send By Email
      </p>

      <hr style={{ margin: 0 }} />

      <div
        style={{
          padding: '15px 25px 20px 25px',
        }}
      >
        {link ? (
          <>
            <div
              style={{
                background: 'white',
                borderRadius: '10px',
                padding: '20px',
              }}
            >
              <EmailToDiv>
                <span>To : </span>

                <div>
                  <button onClick={() => setModal(true)}>
                    <span>+</span> Add Recipients
                  </button>

                  {mailData.to
                    .slice(0, !expandRecipients ? 2 : mailData.to.length)
                    .map((item) => (
                      <div key={item.email} className='recipient-item'>
                        <span>{item.email}</span>

                        <button
                          onClick={() =>
                            setMailData({
                              ...mailData,
                              to: mailData.to.filter(
                                (i) => i.email !== item.email
                              ),
                            })
                          }
                        >
                          <svg
                            fill='#000000'
                            height='10px'
                            width='10px'
                            version='1.1'
                            id='Capa_1'
                            xmlns='http://www.w3.org/2000/svg'
                            xmlnsXlink='http://www.w3.org/1999/xlink'
                            viewBox='0 0 490 490'
                            xmlSpace='preserve'
                          >
                            <polygon
                              points='456.851,0 245,212.564 33.149,0 0.708,32.337 212.669,245.004 0.708,457.678 33.149,490 245,277.443 456.851,490 
	489.292,457.678 277.331,245.004 489.292,32.337 '
                            />
                          </svg>
                        </button>
                      </div>
                    ))}

                  {mailData.to.length > 2 && (
                    <button
                      style={{
                        background: 'transparent',
                        color: 'rgba(0, 0, 0, 0.6)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                        border: 'none',
                        outline: 'none',
                      }}
                      onClick={() => setExpandRecipients(!expandRecipients)}
                    >
                      {expandRecipients
                        ? 'Show Less'
                        : `+ ${mailData.to.length - 2} More`}
                    </button>
                  )}
                </div>
              </EmailToDiv>

              <EmailSubjectDiv>
                <span>Subject : </span>
                <input
                  type='text'
                  value={mailData.subject}
                  onChange={(e) =>
                    setMailData({ ...mailData, subject: e.target.value })
                  }
                />
              </EmailSubjectDiv>

              <EmailMessageDiv>
                <span>Message</span>

                <CKEditor
                  editor={ClassicEditor}
                  config={editorConfiguration}
                  style={{ height: '500px' }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    // ;
                    setMailData({ ...mailData, message: data });
                  }}
                  onInit={(editor) => {
                    editorRef.current = editor;
                    editor.setData(mailData.message);
                  }}
                />
              </EmailMessageDiv>
            </div>

            <p
              style={{
                marginTop: '15px',
                marginBottom: '0px',
                color: 'rgba(0, 0, 0, 0.7)',
              }}
            >
              Short Codes :
            </p>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '10px',
                  marginTop: '10px',
                }}
              >
                <ShortCodeButton onClick={handleClickRecipientName}>
                  Recipient Name
                </ShortCodeButton>

                <ShortCodeButton onClick={handleClickSurveyLink}>
                  Survey Link
                </ShortCodeButton>
              </div>

              <ShortCodeButton onClick={handleSendEmail} disabled={loading}>
                Send Email &nbsp;<span>&#10148;</span>
              </ShortCodeButton>
            </div>
          </>
        ) : (
          <div>Generate Open Link to send email</div>
        )}
      </div>
    </div>
  );
};

export default Email;
