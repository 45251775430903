import React from 'react'
import {connect} from 'react-redux'
import Select from 'react-select'

import {Choice} from './styles'

import {setQuestionIdForCondition} from '../../../redux/actions'

const JumpQuestionSelector = props=>{
  return(
    <Select
      // className='react-select-container'
      // classNamePrefix={'react-select'}
      theme={{
        borderRadius: 0,
        minHeight: 0,
        spacing: {
          baseUnit: 0,
          controlHeight: 10,
          menuGutter: 1
        }
      }}
      styles={{
        container: base=>{
          return{
            ...base,
            margin: "5px 0",
            // minHeight: "1px !important",
            height: "25px",
            width: "40%",
            flexShrink: 0
          }
        },
        control: (base) => {
          return {
            ...base,
            // boxShadow: "1px 1px 5px 1px #D9D9D9",
            padding: "1px 2px",
            background: "white",
            ':hover':{
              ...base[':hover'],
              // boxShadow: "1px 1px 10px 2px #D9D9D9",
              borderColor: "#394662 !important"
            },
            ':active':{
              ...base[':active'],
              borderColor: "#394662 !important"
            }
          };
        },
        singleValue: style=>{
          return{
            ...style,
            padding: "0 5px"
          }
        }
      }}
      // id="channel"
      // isClearable={true}
      placeholder="Question"
      isSearchable={false}
      options={props.options}
      value={props.value}
      onChange={props.onChange} 
    />
  )
}

const Mcq = props=>{
  const {id, choices, questionsAfter, isConditional, jump, setQuestionIdForCondition, projectId} = props
  // console.log(questionsAfter)
  // console.log(jump)
  const choiceList = choices.map((item,index)=>{
    // console.log(jump)
    const jumpValue = jump.find(item=>item.jumpOptionIndex===index) 
    const formattedJumpValue = jumpValue? 
      questionsAfter.find(item=>item.value===jumpValue.jumpTo) :
      ({label: 'None', value: null})

    const handleChange = selected=>{
      setQuestionIdForCondition(projectId, id, index, selected.value)
    }

    return (
      <Choice key={`${id}_${index}`}>
        <input type='radio' disabled />
        <label>{item}</label>
        {
          isConditional && 
          questionsAfter.length!==2 && 
          <JumpQuestionSelector 
            options={questionsAfter} 
            value={formattedJumpValue} 
            onChange={handleChange}
          />
        }
      </Choice>
    )
  })
  return(
    <>{choiceList}</>
  )
}

const mapStateToProps = (state, ownProps)=>({
  ...ownProps
})

const mapDispatchToProps = {
  setQuestionIdForCondition
}

export default connect(mapStateToProps,mapDispatchToProps)(Mcq)