import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Nav from '../../LandingPage/components/nav/nav';
import Footer from '../../LandingPage/components/footer/Footer';
import { css } from 'glamor';
import axios from 'axios';
import showToast from '../../../components/Toast/toast';
import baseUrl from '../../../url';

function PostDetails(props) {
	const id = props.location.state.detail._id;
	const [state, setState] = useState({});

	useEffect(() => {
		axios
			.get(`${baseUrl}/api/blog/posts/${id}`)
			.then((res) => setState(res.data))
			.catch((err) => ErrorToast());
	}, []);

	const tagCss = css({
		background: 'rgb(225, 225, 225)',
		fontSize: '12px',
		padding: '5px 10px',
		marginRight: '10px',
		borderRadius: '2px',
		cursor: 'pointer',
		marginBottom: '5px',
	});
	const titleCss = css({
		color: '#1B998B',
		fontWeight: '500',
		fontSize: '25px',
		marginBottom: '0',
	});
	const guestCss = css({
		fontSize: '11px',
		color: 'rgb(52, 58, 64)',
		marginBottom: '30px',
	});
	const ErrorToast = () => showToast('Server Error');

	return (
		<div style={{ overflowY: 'auto' }}>
			<Nav />
			<div className='container mt-3'>
				<div className='col-lg-8 offset-lg-2'>
					<h1 {...titleCss}> {state.title} </h1>
					<p {...guestCss}>Created by: Guest</p>

					<div className='mb-5 text-center'>
						{state.featureImageUrl && (
							<img
								className='img-fluid'
								src={state.featureImageUrl}
								alt={state.title}
								style={{ maxHeight: '250px' }}
							/>
						)}
					</div>

					<div dangerouslySetInnerHTML={{ __html: state.body }}></div>

					<div className='mt-5'>
						{state.tags && (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									flexWrap: 'wrap',
									gap: '10px',
								}}
							>
								{state.tags.map((obj, i) => (
									<span key={`id:${i}`} {...tagCss}>
										{' '}
										{obj.name}{' '}
									</span>
								))}
							</div>
						)}
					</div>
				</div>
			</div>
			<Footer />
		</div>
	);
}

export default withRouter(PostDetails);
