import {combineReducers} from 'redux'; 
import configReducer from './configReducer'; 
import questionReducer from './questionReducer'; 
import sidebarData from './sidebarStateReducer'; 

export default combineReducers({ 
  config: configReducer, 
  question: questionReducer, 
  sidebarData 
}) 
