import React, { useEffect, useState } from 'react'
import axios from 'axios'

import Form from '../Form'
import Loader from '../Loader';

import { fetchOpenLink } from '../../url'

const FormAnonymous = props => {

  const {
    match: {
      params: {
        hashID: hashedId
      }
    }
  } = props

  const [subChannelId, setSubChannelId] = useState('')
  const [disId, setDisId] = useState('')
  const [createdByCompanyID, setCreatedByCompanyID] = useState('')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios.get(fetchOpenLink(hashedId), { timeout: 5000 })
      .then(
        res => {
          const {
            data: {
              openlinkId
            }
          } = res

          setSubChannelId(openlinkId._id)
          setDisId(res.data.openlinkId.distributionId)
          setCreatedByCompanyID(res.data.openlinkId.createdByCompanyID)
          setLoading(false)
        },
        err => {
          // console.log(err) 
          setLoading(false)
        }
      )
  }, [hashedId])

  return loading ? <Loader /> : <Form channelType="openLink" subChannelId={subChannelId} disId={disId} userType="openlink" createdByCompanyID={createdByCompanyID} />
}

export default FormAnonymous