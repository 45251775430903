import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px 20px 0 20px;
  background-color: #white;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 0 5px 4px #E6E6E6;

  >label{
    font-size: 15px;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px;
    >label{
      font-size: 12px;
    
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
    >label{
      font-size: 10px;
  }

`

export const UploadContainer = styled.div`
  overflow: hidden;
  position: relative;
  align-self: center;
  width: 100px;
  height: 25px;
  box-shadow: 1px 1px 5px #CCCCCC;
  :hover{
    box-shadow: 2px 2px 5px #BFBFBF;
  }
  >input{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 5;
  }
`

export const UploadButton = styled.div`
  background-color: #C4DFE8;;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  :hover{
    background-color: #CEEBF5;
  }
`

export const ChannelType = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 40px;
  padding: 0;
  margin: 0;
  user-select: none;
  box-shadow: 0 3px 2px 1px #E6E6E6;
  z-index: 1;
  border: 1px solid #310D31;
`
export const StyledList = styled.ul`
  display: block;
  list-style:none;
  margin: 0;
  padding: 0;
  min-height: 40px;
  >li{
    cursor: pointer;
    margin: 2px;
    display: inline-block;
    border: 1px solid #310D31;
  }
  z-index: 1;
`
export const StyledLink = styled(NavLink).attrs({ activeClassName: "active" })`
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  padding: 5px;
  color: rgb(52, 58, 64);
  border-bottom: 1px dotted grey;
  background-color: white;
  width:100%;
  &.active{
    background-color: #1B998B;
    color: white;
    border-bottom: none;
  }
  :hover{
    color: white;
    background-color: #1B998B;
    border-bottom: none;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px;
  }
  
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
  
`

export const StyledButton = styled.button`
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  padding: 5px;
  color: rgb(52, 58, 64);
  border: 0;
  border-bottom: 1px dotted grey;
  background-color: white;
  width:100%;
  outline: none;
  &:active {
    outline: none;
  }
  &.active{
    background-color: #1B998B;
    color: white;
    border-bottom: 1px solid #1B998B;
    outline: none;
  }
  :hover{
    color: white;
    background-color: #1B998B;
    border-bottom: 1px solid #1B998B;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px;
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`;

export const Channel = styled.div`
  box-sizing: border-box;
  position: relative; 
  margin: 10px; 
  background-color: white;
`

export const ChannelCard = styled.div`
  box-sizing: border-box;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  
  @media (min-width:280px){
    flex-basis: 100%;
  }
  @media (min-width:300px){
    flex-basis: 100%;
  }

  @media (min-width:370px){
    flex-basis: 50%;
  }

  @media (min-width:768px){
    flex-basis: 33%;
  }

  @media (min-width:900px){
    flex-basis: 25%;
  }

  @media (min-width:1400px) { 
    flex-basis: 20%;
  }
`
export const ChannelItem = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  box-sizing: border-box;
  height: 97%;
  width: 97%;
  cursor: pointer;
  border: 1px solid rgb(52, 58, 64);
  margin: 5px;
  > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  }

  &:hover{
  box-shadow: 5px 5px 10px grey;
  border: none;
  background-color: white;
  color: #310D31;
  } 
` 

export const DeleteText = styled.span`
  position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  left: 15px;
  width: auto;
  height: auto;
  padding: 2px 5px;
  background-color: white;
  color: rgb(52, 58, 64);
  z-index: 101;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: #F27D7D;
    color: white;
    border: none;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  }
`

export const EditText = styled.span`
position: absolute;
  opacity: 0;
  margin: 0;
  top: 15px;
  right: 15px;
  width: auto;
  height: auto;
  padding: 2px 5px;
  background-color: white;
  color: rgb(52, 58, 64);
  z-index: 100;
  transition: 0.25 0.25s display;
  @keyframes appear{
    0% {opacity:0;}
    50% {opacity:0.5;}
    100% {opacity:1;}
  }
  :hover{
    background-color: #1B998B;
    color: white;
    border: none;
  }

  ${props => props.item}:hover & {
    animation: appear 0.25s both ease-in-out;
  } 
`


export const Banner = styled.div`
  disply: flex;
  width: 100%;
  height: 40px;
  color: white;
  margin-bottom: 10px;
  text-align: center;
  background-color: #1B998B;
  padding-top: 13px;
  font-weight: bold;
`

//-------------styles for individula channels------------------------

export const Card = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  align-items: center;
  width: 500px;
  box-shadow: 1px 1px 5px -1px #586469;
  >section{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: white;
    align-items: center;
    padding: 0 15px 10px 15px;
    width: 100%;
  }
  >section >h4{
    margin: 10px 0 15px 0;
    color: white;
    background-color: #310D31;
    padding: 0 5px 0 5px;
    border-radius:20px;
  }
  >section >aside{
    display:flex; 
    flex-direction:row; 
    width:90%;
    background-color: #310D31
  }
  >section >aside >label{
    display:flex; 
    width:50%; 
    padding: 0 10px 0 3px;
    align-items: center;
    min-height:30px;
    border:3px solid white;
    color:white;
  }
  @media only screen and (max-width: 425px){
    width: 70%;
  }
`
export const Section = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  align-items: center;
  padding: 0 15px 10px 15px;
  width: 100%;
>h4{
  margin: 10px 0 15px 0;
  color: white;
  background-color: #310D31;
  padding: 0 5px 0 5px;
  border-radius:20px;
}
`
export const Label = styled.label`
  display: flex;  
  color: #310D31;
  padding: 0 10px 0 3px;
  align-items: center;
  height:30px;
  
`

export const Icon = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  background-color: #310D31;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  font-size: 50px;
  color: white;
  margin-top: 15px;
  border-radius: 50%;
`
export const Input = styled.input`
  box-sizing: border-box;
  padding: 5px 10px;
  margin: 10px 0 15px 0;
  width: 100%;
  box-shadow: 0px 0px 2px #310D31;
  border: none;
  outline: none;
  font-size: 15px;
  line-height: 15px;
  background-color: white;
  :focus{
    box-shadow: 0px 0px 5px 2px #808080;
  }
  @media only screen and (max-width: 1024px){
    height: 20px;
    margin: 5px 0 7px 0;
    font-size: 12px;
  }
  @media only screen and (max-width: 425px){
    height: 20px;
    margin: 3px 0 5px 0;
    font-size: 10px;
  }
  
`

export const Button = styled.button`
  display: block;
  margin: 5px auto 0 auto;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  background-color: #310D31;
  height: 30px;
  padding: 5px 10px;
  text-align: center;
  border: none;
  :hover{
    cursor: pointer;
    background-color: white;
    color: #310D31;
    border: 1px solid #310D31;
  }
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
`
export const CloseButton = styled.div`
color: #C52590;
font-size: 20px;
line-height:18px;
position: absolute;
text-align: center;
top: -10px;
right: -10px;
background-color: white;
border-radius: 50%;
width: 20px;
height: 20px;
z-index: 1;
box-shadow: 1px 1px 5px -1px #586469;
:hover{
  cursor: pointer;
  box-shadow: 1px 1px 5px 2px #586469;
}
`
export const Tips = styled.div`
  box-sizing: border-box;
  display: flex;
  position: relative;
  background-color: #310D31;
  color: white;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top:20px;
  height: 30px;  
`

export const Page = styled.div`
  width: 210mm;
  min-height: 297mm;
  padding: 20mm;
  margin: 10mm auto;
  border: 1px #D3D3D3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`

export const ChannelList = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-content:space-between;
  align-items: stretch;
  border: 1px solid #1B998B;
  padding: 2px;
  width: 100%;
`

export const ChannelTypeItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 2px;
  border: 1px solid rgb(52, 58, 64);
  box-sizing: border-box;
  flex-basis: 148px;
  flex-grow: 1;
`

export const Checkbox = styled.input.attrs(props=>({
  type: 'checkbox',
  ...props
}))`
  margin: 0 5px;
  cursor: pointer;
`

export const CheckboxContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 2px;
  >label{
    font-size: 0.9em;
    cursor: pointer;
    user-select: none;
  }
`