import React, { useEffect } from 'react'
import { MainBody } from './style'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList/editIndex'
import NextButton from '../../components/NextButton/editnext'
import { useSelector, useDispatch } from 'react-redux';
import {
    selectProjectAndFetch,
    setSurveyConfigStep,
} from '../../redux/actions/index'
import EditStep from '../../components/EditStep'

const SelectPage = props => {
    let projectId = useSelector(state => state.survey.projectId)
    let name = useSelector(state => state.survey.projectName)
    let dispatch = useDispatch()

    useEffect(() => {
        if (projectId) {
            dispatch({ type: "SET_NAME", name: name })
            dispatch(setSurveyConfigStep())
            dispatch(selectProjectAndFetch(projectId))
        }
    }, [projectId])

    return (
        <MainBody>

            <div className="container">
                {/* <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link> */}
                <EditStep />
                <Configuration />
                <QuestionList />
                <NextButton disid={null} />
            </div>

        </MainBody>
    )
}

export default SelectPage