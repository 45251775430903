import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import Dashboard from './components/dashboardComponent/Dashboard';


function DataAnalysisTwo() {
    return (
        <div className="Advance-analytics">
            <div className="container">
                <Dashboard />
            </div>
        </div>
    );
}

export default DataAnalysisTwo;
