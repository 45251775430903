import React,{memo, useEffect} from 'react';
import {withRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import Body from "./components/Body";
import reducer from './redux/reducers/index'
import {initialize} from './redux/actions'


// store.dispatch(fetchData())

function App(props) {
  
  const store = createStore(reducer,composeWithDevTools(
    applyMiddleware(thunk)
  ))
  
  const {projectId} = props.match.params

  // const setProjectId = ()=>{
  //   store.dispatch(initialize(projectId,localStorage.getItem("jwtID")))
  // }

  // useEffect(setProjectId,[projectId])

  return (
    <Provider store={store}>
      <Body projectId={projectId}/>
    </Provider>
  );
}

export default memo(withRouter(App));
