import React, { useState } from 'react'
import Select from 'react-select'

import { Question, SingleLine, MultiLine } from './Styles'
import './index.css'

const TextBox = ({ item, editField, }) => {

  const options = [
    {
      label: "Single Line",
      value: "singleLine"
    },
    {
      label: "Multi Line",
      value: "multiLine"
    }
  ]
  return (
    <>
      <Select
        className='react-select-container'
        classNamePrefix={'react-select'}
        theme={{
          borderRadius: 0,
          minHeight: 0,
          spacing: {
            baseUnit: 0,
            controlHeight: 10,
            menuGutter: 1
          }
        }}
        styles={{
          container: style => {
            return {
              ...style,
              minHeight: "1px !important",
              height: "25px",
              width: "25%",
              marginTop: "10px",
              marginBottom: "10px"
            }
          },
          control: (base) => {
            return {
              ...base,
              padding: "1px 2px",
              background: "white",
              ':hover': {
                ...base[':hover'],
                borderColor: "#1B998B !important"
              },
              ':active': {
                ...base[':active'],
                borderColor: "#1B998B !important"
              }
            };
          },
          singleValue: style => {
            return {
              ...style,
              padding: "0 5px"
            }
          }
        }}
        isSearchable={false}
        options={options}
        value={options.find(entry => entry.value === item.text)}
        onChange={selected => {
          editField(item.id, "text", selected.value)
        }}
      />
      {item.text === "singleLine" && <SingleLine disabled placeholder="(non-editable demo) Example: Your Name?" type="text" />}
      {item.text === "multiLine" && <MultiLine
        disabled
        placeholder="(non-editable demo) Example: Explain your reasons"
        onInput={e => {
          e.target.style.height = "25px"
          if (e.target.style.height.split("p")[0] < e.target.scrollHeight) {
            e.target.style.height = e.target.scrollHeight + "px";
          }
        }}
      />}
    </>
  )
}

export default TextBox