import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CardView from '../cardView/CardView';
import {
  getPieChart,
  getProgressChart,
  getBarChart,
  getList,
  getNpsChart,
  getWordCloud,
} from '../../components/chartFunctions/ChartFunctions';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import baseUrl from '../../../../url';
import Line from '../../../DataAnalysisTwo/components/charts/nivo/Line';
import WordCloud from '../WordCloud/WordCloud';

// import { downlaodImage, downloadPNG } from '../downloadJSX/downloadJSX'
// import diverging from "d3-scale/src/diverging";

const QuestionAnalytics = (props) => {
  const [npsChartType, setNpsChartType] = useState('simpleChart');
  const [textBoxType, setTextBoxType] = useState('wordCloud');
  const [cardId, setCardId] = useState(''); //store question title as cardId that need to download
  const [config, setConfig] = useState(initialConfig);
  const [didMount, setDidMount] = useState(true);
  const [npsLineData, setNpsLineData] = useState([]);
  let updatedConfig = initialConfig;

  useEffect(() => {
    if (didMount === true) {
      setConfig(updatedConfig);
      setDidMount(false);
    }
  }, [updatedConfig]);

  useEffect(() => {
    const fetchNpsLineData = async () => {
      const res = await axios.post(
        `${baseUrl}/api/visualization/core/nps-over-time`,
        props.apiBody
      );
      const data = res.data;

      if (data.chartDataObj) {
        setNpsLineData(data.chartDataObj);
      }
    };

    if (props.apiBody) {
      fetchNpsLineData();
    }
  }, []);

  const getCardId = (cardId) => {
    setCardId(cardId);
  };

  const switchChart = (chartType, title, type) => {
    const configCopy = { ...config };
    configCopy[type].find((el) => el.questionTitle === title).chartType =
      chartType;
    configCopy[type].find((el) => el.questionTitle === title).activeButton =
      chartType;
    setConfig(configCopy);
  };

  const initiateType = (type, title) => {
    let chartType = null;
    const getType = config[type]
      .filter((el) => el.questionTitle === title)
      .map((ob) => ob.chartType);
    getType[0] !== null
      ? (chartType = getType[0])
      : (chartType = config[type][0].chartType);

    return chartType;
  };

  return (
    <div className='QuestionsAnalytics'>
      {props.questions.length ? (
        <div className='row'>
          {props.questions.map((item, itemIndex) => {
            let element = null;
            switch (item.type) {
              case 'multipleChoice':
                const updateMultipleChoice = {
                  ...updatedConfig,
                  multipleChoice: [
                    ...updatedConfig.multipleChoice,
                    {
                      chartType: 'pie',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateMultipleChoice;
                element = getPieChart(
                  item.id,
                  item.options,
                  item.answer,
                  initiateType(item.type, item.title)
                );
                break;

              case 'dropDown':
                const updateDropdown = {
                  ...updatedConfig,
                  dropDown: [
                    ...updatedConfig.dropDown,
                    {
                      chartType: 'pie',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateDropdown;
                element = getPieChart(
                  item.id,
                  item.options,
                  item.answer,
                  initiateType(item.type, item.title)
                );
                break;

              case 'dichotomy':
                const updateDichotomy = {
                  ...updatedConfig,
                  dichotomy: [
                    ...updatedConfig.dichotomy,
                    {
                      chartType: 'donut',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateDichotomy;
                element = getPieChart(
                  item.id,
                  item.options,
                  item.answer,
                  /*chartTypeHandler(item.title, item.type) ? chartType :*/ initiateType(
                    item.type,
                    item.title
                  )
                );
                break;

              case 'thumbsUpDown':
                const updateThumbsUpDown = {
                  ...updatedConfig,
                  thumbsUpDown: [
                    ...updatedConfig.thumbsUpDown,
                    {
                      chartType: 'pie',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateThumbsUpDown;
                element = getPieChart(
                  item.id,
                  item.options,
                  item.answer,
                  /*chartTypeHandler(item.title, item.type) ? chartType :*/ initiateType(
                    item.type,
                    item.title
                  ),
                  [
                    { icon: faThumbsUp, label: 'Thumbs Up' },
                    { icon: faThumbsDown, label: 'Thumbs Down' },
                  ]
                );
                break;

              case 'checkbox':
                const updateCheckbox = {
                  ...updatedConfig,
                  checkbox: [
                    ...updatedConfig.checkbox,
                    {
                      chartType: 'bar',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateCheckbox;
                initiateType(item.type, item.title) === 'bar'
                  ? (element = getProgressChart(
                      item.options,
                      item.answer,
                      item.answered
                    ))
                  : (element = getPieChart(
                      item.id,
                      item.options,
                      item.answer,
                      initiateType(item.type, item.title)
                    ));

                break;

              case 'likertScale':
                element = getBarChart(
                  item.id,
                  item.options,
                  item.answer,
                  'vertical'
                );
                break;

              case 'ratingScale':
                const updateRatingScale = {
                  ...updatedConfig,
                  ratingScale: [
                    ...updatedConfig.ratingScale,
                    {
                      chartType: 'bar',
                      questionTitle: item.title,
                    },
                  ],
                };
                updatedConfig = updateRatingScale;
                initiateType(item.type, item.title) === 'bar'
                  ? (element = getBarChart(
                      item.id,
                      item.options,
                      item.answer,
                      'horizontal'
                    ))
                  : (element = getPieChart(
                      item.id,
                      item.options,
                      item.answer,
                      initiateType(item.type, item.title)
                    ));
                break;

              case 'starRating':
                element = getBarChart(
                  item.id,
                  item.options,
                  item.answer,
                  'horizontal',
                  'starRating'
                );
                break;

              case 'loveRating':
                element = getBarChart(
                  item.id,
                  item.options,
                  item.answer,
                  'horizontal',
                  'loveRating'
                );
                break;

              case 'thumbsUpRating':
                element = getBarChart(
                  item.id,
                  item.options,
                  item.answer,
                  'horizontal',
                  'thumbsUpRating'
                );
                break;

              case 'nps':
                if (npsChartType !== 'lineChart') {
                  element = getNpsChart(
                    item.options,
                    item.answer,
                    npsChartType
                  );
                } else {
                  element = (
                    <Line
                      title={'NPS Over Time'}
                      line={npsLineData}
                      marginBottom={null}
                      area={true}
                      isParcent={false}
                      max={100}
                    />
                  );
                }
                break;

              case 'textBox':
                if (textBoxType === 'wordCloud') {
                  element = <WordCloud answer={item.answer} />;
                } else {
                  element = getList(item.id, item.text, item.answer);
                }
                break;

              // case 'textBox':
              case 'date':
              case 'numericBox':
                element = getList(item.id, item.text, item.answer);
                break;

              default:
                break;
            }

            return (
              <>
                <div className='col-lg-4 col-md-12 mb-4' key={item.id}>
                  <CardView
                    title={item.title}
                    answered={item.answered}
                    skipped={item.skipped}
                    padding='10'
                    hasSparkLine={false}
                    sparklineTitle={null}
                    downloadCart={true}
                    getCardId={getCardId}
                    cardId={cardId}
                    type={item.type}
                    switchChart={switchChart}
                    config={config}
                    npsChartType={npsChartType}
                    setNpsChartType={setNpsChartType}
                    textBoxType={textBoxType}
                    setTextBoxType={setTextBoxType}
                  >
                    {element}
                  </CardView>
                </div>
              </>
            );
          })}
        </div>
      ) : (
        <div
          style={{
            textAlign: 'center',
            color: '#F27D7D',
            fontSize: '14px',
            fontWeight: '700',
          }}
        >
          No response found yet
        </div>
      )}
    </div>
  );
};

export default QuestionAnalytics;

const initialConfig = {
  multipleChoice: [
    {
      chartType: 'pie',
      questionTitle: '',
    },
  ],
  dropDown: [
    {
      chartType: 'pie',
      questionTitle: '',
    },
  ],
  dichotomy: [
    {
      chartType: 'donut',
      questionTitle: '',
    },
  ],
  thumbsUpDown: [
    {
      chartType: 'pie',
      questionTitle: '',
    },
  ],
  checkbox: [
    {
      chartType: 'bar',
      questionTitle: '',
    },
  ],
  ratingScale: [
    {
      chartType: 'bar',
      questionTitle: '',
    },
  ],
  likertScale: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  starRating: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  loveRating: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  thumbsUpRating: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  nps: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  textBox: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  date: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
  numericBox: [
    {
      chartType: '',
      questionTitle: '',
    },
  ],
};
