import React from 'react'
import { withRouter } from 'react-router-dom'
import NavBar from '../LandingPage/components/nav/nav'
import Customer from './components/customer'
import Brand from './components/brand'
import Footer from '../LandingPage/components/footer/Footer';
import Product from './components/product'
import Employee from './components/employee'
import Professional from './components/professional'
import Education from './components/education'
import Community from './components/community'
import Event from './components/event'
import Survey from './components/survey'

function FeaturesDetail(props) { 
  const title = props.match.params.title
  
  return ( 
    <div style={{overflowY: 'scroll'}}> 
      <NavBar /> 
      <div className="container"> 
        { title === 'Customer-Experience'     && <Customer /> } 
        { title === 'Brand-Experience'        && <Brand /> } 
        { title === 'Product-Experience'      && <Product /> } 
        { title === 'Employee-Experience'     && <Employee /> } 
        { title === 'Professional-Experience' && <Professional /> } 
        { title === 'Education-Experience'    && <Education /> } 
        { title === 'Community-Experience'    && <Community /> } 
        { title === 'Event-Experience'        && <Event /> } 
        { title === 'Random-Survey'           && <Survey /> } 
        { title === 'Build-Form'              && <Customer /> }  
      </div> 
      <Footer /> 
    </div> 
  ) 
} 

export default withRouter(FeaturesDetail); 
