import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Scrollbars } from 'react-custom-scrollbars';
import MultipleChoice from '../../components/MultipleChoice';
import Checkbox from '../../components/Checkbox';
import LikertScale from '../../components/LikertScale';
import TextBox from '../../components/TextBox';
import Dicotomy from '../../components/Dicotomy';
import ThumbsUp from '../../components/ThumbsUp';
import Ratingscale from '../../components/Ratingscale';
import Starrating from '../../components/Starrating';
import Thumbsuprating from '../../components/Thumbsuprating';
import Loverating from '../../components/Loverating';
import Nps from '../../components/Nps';
import Dropdown from '../../components/Dropdown';
import NumericSlider from '../../components/NumericSlider';
import NumericBox from '../../components/NumericBox';
import Date from '../../components/Date';
import DateRange from '../../components/DateRange';
import ContactCard from '../../components/ContactCard';
import Progressbar from '../../components/Progressbar';

import {
	totalQuestionSelector,
	isQuestionConditional,
	questionTypeSelector,
	submitButtonSelector,
	buttonEnableSelector,
	contactCardAvailableSelector,
} from '../../redux/selectors';
import {
	nextQuestion,
	shouldSkip,
	previousQuestion,
} from '../../redux/actions';

import { Question, Button, NavigationButtonContainer } from './styles';

whyDidYouRender(React, {
	onlyLogs: true,
	titleColor: 'green',
	diffNameColor: 'aqua',
});

const QuestionComponent = (props) => {
	const {
		questionType,
		submit,
		nextQuestion,
		buttonEnabled,
		shouldSkip,
		totalQuestion,
		questionConditional,
		contactCardAvailable,
		contactCardFields,
		currentQuestion,
		previousQuestionLists,
		previousQuestion,
	} = props;

	const [contactCardCheckAvailable, setContactCardCheckAvailable] =
		useState(contactCardAvailable);
	const [currentTotal, setCurrentTotal] = useState(0);
	const [error, setError] = useState('');

	const handleClick = () => {
		nextQuestion();
		setCurrentTotal(currentTotal + 1);
	};

	const handlePreviousClick = () => {
		previousQuestion(previousQuestionLists[currentQuestion]);
		setCurrentTotal(currentTotal - 1);
		// console.log(previousQuestionLists[currentQuestion])
	};

	// console.log(totalQuestion)
	useEffect(shouldSkip);

	const contactCardSubmit = () => {
		setContactCardCheckAvailable(false);
	};

	let renderContent;
	// console.log(questionType)
	switch (questionType) {
		case 'textBox':
			renderContent = <TextBox />;
			break;
		case 'likertScale':
			renderContent = <LikertScale />;
			break;
		case 'multipleChoice':
			renderContent = <MultipleChoice />;
			break;
		case 'checkbox':
			renderContent = <Checkbox />;
			break;
		case 'dropDown':
			renderContent = <Dropdown />;
			break;
		case 'dichotomy':
			renderContent = <Dicotomy />;
			break;
		case 'thumbsUpDown':
			renderContent = <ThumbsUp />;
			break;
		case 'ratingScale':
			renderContent = <Ratingscale />;
			break;
		case 'starRating':
			renderContent = <Starrating />;
			break;
		case 'thumbsUpRating':
			renderContent = <Thumbsuprating />;
			break;
		case 'loveRating':
			renderContent = <Loverating />;
			break;
		case 'nps':
			renderContent = <Nps />;
			break;
		case 'slider':
			renderContent = <NumericSlider />;
			break;
		case 'numeric':
			renderContent = <NumericBox error={error} setError={setError} />;
			break;
		case 'date':
			renderContent = <Date />;
			break;
		case 'daterange':
			renderContent = <DateRange />;
			break;
		default:
			renderContent = null;
	}

	return (
		<React.Fragment>
			{contactCardCheckAvailable && (
				<ContactCard
					fields={contactCardFields}
					contactCardSubmit={contactCardSubmit}
				/>
			)}
			{!contactCardCheckAvailable && (
				<Question>
					{renderContent}
					<NavigationButtonContainer>
						<div className='text-right'>
							{currentQuestion in previousQuestionLists && (
								<Button
									// disabled={!buttonEnabled}
									type='button'
									onClick={handlePreviousClick}
									style={{ marginRight: '10px' }}
								>
									Previous{/* {submit ? "Submit" : "Next"} */}
								</Button>
							)}
							<Button
								disabled={!buttonEnabled || error !== ''}
								type='button'
								onClick={handleClick}
							>
								{submit ? 'Submit' : 'Next'}
							</Button>
						</div>
					</NavigationButtonContainer>
				</Question>
			)}

			{props.progressbar && !questionConditional && (
				<Progressbar total={totalQuestion} current={currentTotal} />
			)}
		</React.Fragment>
	);
};

QuestionComponent.whyDidYouRender = true;

const mapStateToProps = (state) => {
	// console.log(state)
	return {
		progressbar: state.form.progressbar,
		totalQuestion: totalQuestionSelector(state),
		questionConditional: isQuestionConditional(state),
		questionType: questionTypeSelector(state),
		submit: submitButtonSelector(state),
		buttonEnabled: buttonEnableSelector(state),
		contactCardAvailable: contactCardAvailableSelector(state),
		contactCardFields: state.form.data,
		currentQuestion: state.form.currentQuestion,
		previousQuestionLists: state.form.previousQuestionLists,
	};
};

const mapDispatchToProps = {
	nextQuestion,
	shouldSkip,
	previousQuestion,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionComponent);

// #c5258f
