import React, {useState} from 'react'
import customer from './asset/customer.png'
import education from './asset/education.png'
import brand from './asset/brand.png'
import employee from './asset/employee.png'
import product from './asset/product.png'
import professional from './asset/professional.png'
import community from './asset/community.png'
import event from './asset/event.png'
import form from './asset/form.png'
import survey from './asset/survey.png'
import {css} from 'glamor'
import SignupModal from '../../../Pricing/components/modal/modal'
import ReqDemoModal from '../ReqDemo/ReqDemoModal'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap' 

import './style.css' 


function Features(props) { 
  const [showFreeModal, setShowFreeModal] = useState(false) 
  const [successMsgModal, setSuccessMsgModal] = useState(false) 
  const [reqDemo, setReqDemo] = useState(false); 

  const cancelModal = () => setReqDemo(false); 
  const closeModal = (msg = null) => {  
    if(msg) { setSuccessMsgModal(true) } 
    setShowFreeModal(false) 
  } 

  let style={ 
    boxHeader: { fontSize: '18px', textAlign: 'center', color: 'rgb(52, 58, 64)', fontFamily: 'Roboto' },
    boxIcon: { height: '50px', width: '50px', margin: 'auto' }, 
    p: { fontSize: '15px', color: 'black', fontFamily: 'Roboto', minHeight: '110px', color: 'rgb(52, 58, 64)' }, 
    ourBtn: { 
      backgroundColor: '#1B998B', 
      borderColor: '#1B998B', 
      color: 'white', 
      borderRadius: '0%' 
    } 
  } 
  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }, 
    demoButton: { background: '#fff', border: '2px solid #1B998B' } 
  } 

  const tryItFreeCss = css({
    fontSize: '12px', cursor: 'pointer', padding: '5px 10px', 
    border: '2px solid #fff', transition: '.5s all', borderRadius: '5px',
    ':hover': { border: '2px solid #1B998B', color: '1B998B' } 
  }) 

  const reqDemoCss = css({
    fontSize: '12px', cursor: 'pointer', padding: '5px 10px', 
    border: '2px solid #fff', transition: '.5s all', float: 'right', 
    marginLeft: 'auto', borderRadius: '5px',
    ':hover': { border: '2px solid #1B998B', color: '1B998B' } 
  }) 

  return (
    <div className="container">  
      <section className="arrowFlow"> 
        <div style={{marginTop: '25px', marginBottom: '15px'}}> 
          <h2 className="text-center guide-text"> 
            Simple and Guided Process 
          </h2> 
        </div> 
        <div className="row process m-auto" style={{marginRight: '0', marginLeft: '0'}}>
          <div className="tags m-auto d-flex" style={{marginRight: '0', marginLeft: '0'}}>
            <span className="mb-3"> <p>Create</p> </span> 
            <span className="mb-3"> <p>Design</p> </span> 
            <span className="mb-3"> <p>Preview</p> </span> 
            <span className="mb-3"> <p>Distribute</p> </span> 

            <div className="flowIcon"> 
              <i className="fas fa-chart-line ml-5 fa-4x" style={{color: '#1B998B'}}></i>  
            </div> 
          </div> 
        </div> 
      </section> 

      <>
        <div style={{marginTop: '25px', marginBottom: '15px'}}>
          <h4 className="text-center mb-3" 
            style={{fontFamily: 'Roboto', color: 'rgb(52, 58, 64)', fontSize: '20px'}}>
            Our Features
          </h4> 
        </div> 
        <div className="row justify-content-center"> 
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={customer} className="card-img-top mt-3" style={style.boxIcon} alt="Customer Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Customer Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                The process of monitoring every interaction the customers experience with an organization. 
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={brand} style={style.boxIcon} className="card-img-top mt-3" alt="Brand Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Brand Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Brand Experience pulls out the customers’ thoughts about the brands.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={product} style={style.boxIcon} className="card-img-top mt-3" alt="Product Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Product Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Product Experience determines the interaction between a user and a product.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div> 
            </div> 
          </div>  
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={employee} style={style.boxIcon} className="card-img-top mt-3" alt="Employee Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Employee Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Employees’ observations and perceptions about their employment at an organization.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={professional} style={style.boxIcon} className="card-img-top mt-3" alt="Professional Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Professional Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Ameliorate your career through the feedback from your clients.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={education} style={style.boxIcon} className="card-img-top mt-3" alt="Education Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Education Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  Explore your institutions by getting the opinion from teachers, staff, students, and parents.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
            <div className="card h-100">
              <img src={community} style={style.boxIcon} className="card-img-top mt-3" alt="Community Experience"/>
              <div className="card-body">
                <h5 className="card-title" style={style.boxHeader}>Community Experience</h5> 
                <p style={style.p} className="card-text text-justify">
                  It enables an opportunity to enhance the living experience in a community.
                </p> 
                <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
              </div>
            </div>
          </div> 
          
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
              <div className="card h-100">
                <img src={event} style={style.boxIcon} className="card-img-top mt-3" alt="Event Experience"/>
                <div className="card-body">
                  <h5 className="card-title" style={style.boxHeader}>Event Experience</h5> 
                  <p style={style.p} className="card-text text-justify">
                    Figure out what everyone experienced at your event and what you can do to make it even better.
                  </p> 
                  <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
                </div>
              </div>
            </div> 
        </div> 
        
        <div style={{marginTop: '15px', marginBottom: '30px'}}>
          <h4 className="text-center" style={{fontSize: '20px'}}>Even</h4>
        </div>
        <div className="evenPart"> 
          <div className="row justify-content-center"> 
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
              <div className="card h-100">
                <img src={survey} style={style.boxIcon} className="card-img-top mt-3" alt="Random survey"/>
                <div className="card-body">
                  <h5 className="card-title" style={style.boxHeader}>Create Random Survey</h5> 
                  <p style={style.p} className="card-text text-justify">
                    Make an amazing and customized survey in minutes using our Template Library.
                  </p> 
                  <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
                </div>
              </div>
            </div> 
            
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"> 
              <div className="card h-100">
                <img src={form} style={style.boxIcon} className="card-img-top mt-3" alt="Build Form"/>
                <div className="card-body">
                  <h5 className="card-title" style={style.boxHeader}>Build a Form</h5> 
                  <p style={style.p} className="card-text text-justify">
                    Make an amazing and customized survey in minutes using our Template Library.
                  </p> 
                  <div className="d-flex"> 
                  <span {...tryItFreeCss} onClick={() => setShowFreeModal(true)}> 
                    Try it free 
                  </span> 
                  <span {...reqDemoCss} onClick={() => setReqDemo(true)}> 
                    Request Demo  
                  </span> 
                </div> 
                </div>
              </div>
            </div> 
          </div> 
        </div> 
      </> 
    
    
      <>
        {/* Signup Modals for free, basic, standard, pro, advanced */} 
        {
          showFreeModal && <SignupModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} />
        } 
      </> 


      {/* Signup success message */}
      <>
        <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          {/* { closeModal() }  */}
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!!
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button}
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 

      {/* Request Demo */} 
      { reqDemo && <ReqDemoModal reqDemo={reqDemo} cancelModal={cancelModal} /> } 
    
    </div> 
  ) 
} 

export default Features
