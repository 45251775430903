import React from 'react'; 
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'; 
import { Provider } from 'react-redux'; 
import store from './store/index'; 
import Designation from './designation'
import CreateDesignation from './components/create_designation/createDesignation'; 
import Details from './components/details/details'; 


const Routes = props => { 
  let { match } = props; 
  return ( 
    <BrowserRouter> 
      <Switch> 
        <Route exact path={`${match.path}`} component={Designation} /> 
        <Route path={`${match.path}/create`} component={CreateDesignation} /> 
        <Route path={`${match.path}/:id`} component={Details} /> 
      </Switch> 
    </BrowserRouter> 
  ) 
} 

const ReduxConnectedRoutes = withRouter(Routes) 

function Index() { 
    return ( 
      <Provider store={store}> 
        <ReduxConnectedRoutes /> 
      </Provider> 
    ) 
} 

export default Index; 





// -----------------------------------------------------------------
// --------------------------- Old Code ----------------------------
// -----------------------------------------------------------------




// import React, { useState, useEffect, memo } from 'react';
// import { Link, Switch, Route, Redirect } from 'react-router-dom'
// import styled from 'styled-components/macro'
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faSave } from '@fortawesome/free-solid-svg-icons'
// import Select from 'react-select'
// import * as Yup from 'yup'
// import { withFormik } from 'formik'
// import { FetchAllDepartment,FetchAllDesignation, AddNewDesignation } from '../../../url/prmUrl';
// import Alert from '../ShowAlert'

// import { Button, Input, Label, Body, Error, Aside, Modal } from '../StyledComponents'

// let options = []

// const Form = props => {

//   const {
//     values,
//     touched,
//     errors,
//     handleChange,
//     handleBlur,
//     handleSubmit,
//     setFieldValue,    // for custom call and change
//     setFieldTouched,  //for custom call and change
//   } = props;

//   return (
//     <form onSubmit={handleSubmit} css={`width: 100%`}>
//           <Aside>
//             <section>
//             <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Title:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <Input
//                     name="title"
//                     type="text"
//                     placeholder="Auto Generated"
//                     value={values.departmentId ? values.departmentId.label+" - "+values.name : ''}
//                     disabled
//                   />
//                 </div>
//               </Label>
//             {errors.departmentId && touched.departmentId ? <Error>{errors.departmentId}</Error> : null}
//               <Label>              
//                   <div css={`width: 30%;text-align:left;`}>
//                     Department:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
//                     name="departmentId"
//                     value={values.departmentId}
//                     onChange={setFieldValue}
//                     onBlur={setFieldTouched}
//                     touched={touched.departmentId}
//                   /> 
//                 </div>
//               </Label>

//              {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Name:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <Input
//                     name="name"
//                     type="text"
//                     placeholder="name"
//                     value={values.name}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>

//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Active:
//                 </div>
//                 <div css={'width:15px'}>
//                   <Input
//                     name="status"
//                     type="checkbox"
//                     css={`width:18px;height:18px`}
//                     checked={values.status}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>
//             </section>
//         </Aside>
//         <Button type="submit">
//           <FontAwesomeIcon
//             icon={faSave}
//             css={`margin-right:10px`}
//           />
//           Create
//         </Button>
//     </form>
//   )

// }


// class MySelect extends React.Component {
//   handleChange = value => {
//     this.props.onChange("departmentId", value); //initially gets the null value and updates when called from onchange below
//   };

//   handleBlur = () => {
//     this.props.onBlur("departmentId", true); //sets the onblur value to true when called from onBlur below
//   };

//   render() {
//     return (
//         <Select
//           id="color"
//           placeholder="Select Department"
//           options={options}
//           //isMulti
//           onChange={this.handleChange}  //calls the handleChange in MySelect class
//           onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
//           value={this.props.value}      // gets the selected value, which is eventually options.value
//         />

//     );
//   }
// }

// const Designation = props => {
//   const [view, setView] = useState(false)
//   const [editable, setEditable] = useState({
//     loaded: '',
//     fetched: false,
//     redirect: false
//   })

//   useEffect(() => {

//     const fetchData = async () => {

//       const fetchDepartment = await axios(FetchAllDepartment);
//       try{
//        options = fetchDepartment.data.map(element=>({label: element.name, value: element._id}))
//      }
//      catch (err) {
//       // console.log("err")
//      }


//       const result = await axios(FetchAllDesignation);
//       try {
//           setEditable({
//           ...editable,
//           loaded: result.data,
//         })
//       }
//       catch (err) {
//           // console.log("err")
//       }

//     };

//     if(view===false){
//       fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
//     }
//   }, [view]);

//   const designation = editable.loaded ?
//                   editable.loaded.Error ? [] :
//                   editable.loaded.map(item=>item.name) : [] 

//   const schema = Yup.object().shape({
//     name: Yup.string().required("Must Define a Name")
//                       .min(3, "Minimum 3 characters")
//                       .notOneOf(designation, "Already exists"),
//     departmentId: Yup.string().required("Please select one...")
//   })

//   const FormikForm = withFormik({
//     validationSchema: schema,
//     mapPropsToValues: () => 
//     ({ 
//       title:'',
//       departmentId: '',
//       name:"" ,
//       status: true
//     }),

//     handleSubmit: (values, { setSubmitting }) => {
//       values.title=values.departmentId.label+" - "+values.name
//       values.departmentId=values.departmentId.value
      
//       axios.post(AddNewDesignation, values)
//       .then((response) => {
//         if(!response.data.Error){
//           setView(true)
//         }
//         else {
//           window.alert("Uexpected Error! Duplicate Name found!")
//         }
//       })
//       .catch((err) => {
//         // console.log("Error")
//       })

//     }
//   })(Form)


//   return (
//     <React.Fragment>
//         <Body>
//         <Modal
//               view={view}
//               onClick={event => {
//                 event.preventDefault()
//                 if (event.target === event.currentTarget)
//                 {
//                   setView(false)
//               }
//               }}
//             >
//               <Alert close={setView}/>
//             </Modal>
//           <FormikForm/>
//         </Body>
//       {/* {editable.redirect === false ? <AddNewUser /> :
//         <Redirect
//           to={{
//             pathname: "/app/users"
//           }}
//         />
//       } */}
//     </React.Fragment>
//   )
// }

// export default memo(Designation)