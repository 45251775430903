import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import Amarlab from './asset/Amarlab.png'
import Basecamp from './asset/Basecamp.png'
import beetles from './asset/beetles.png'
import Hometex from './asset/Hometex.png'
import ishraak from './asset/ishraak.png'
import Munlai from './asset/Munlai.png'
import Neocampers from './asset/Neocampers.png'
import neofarmers from './asset/neofarmers.png'
import parmeeda from './asset/parmeeda.png'
import tMiles from './asset/tMiles.jpg'
import Zanala from './asset/Zanala.png'
import './style.css'


const Example = (props) => { 
  let height = '60px' 
  return ( 
    <> 
      <div className="container"> 
        <div className="row styleClients" style={{marginLeft: '0px'}}> 

          <div> <img alt="Beetles"     src={beetles}    height={height} /> </div> 
          <div> <img alt="Neo Campers" src={Neocampers} height={height} /> </div> 
          <div> <img alt="Parmeeda"    src={parmeeda}   height={height} /> </div> 
          <div> <img alt="Munlai"      src={Munlai}     height={height} /> </div> 
          <div> <img alt="Zanala"      src={Zanala}     height={height} /> </div> 
          <div> <img alt="Basecamp"    src={Basecamp}   height={height} /> </div> 
          <div> <img alt="T Miles"     src={tMiles}     height={height} /> </div> 
          <div> <img alt="Hometex"     src={Hometex}    height={height} /> </div> 
          <div> <img alt="Neofarmers"  src={neofarmers} height={height} /> </div> 
          <div> <img alt="Amarlab"     src={Amarlab}    height={height} /> </div> 
          <div> <img alt="Ishrak"      src={ishraak}    height={height} /> </div> 
          
        </div> 
      </div> 
    </> 
  ) 
} 

export default Example; 

