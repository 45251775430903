const editMultipleChoice = (fId, value, item, err) => {
  err = false;
  let newChoices = [...item.choices];
  newChoices[fId].value = value;
  return { ...item, choices: [...newChoices] };
};

const editDropDown = (fId, value, item, err) => {
  err = false;
  let newOptions = [...item.options];
  newOptions[fId].value = value;
  return { ...item, options: [...newOptions] };
};

const editCheckbox = (fId, value, item, err) => {
  err = false;
  let newOptions = [...item.options];
  newOptions[fId].value = value;
  return { ...item, options: [...newOptions] };
};

const editLikertScale = (fId, value, item, err) => {
  //fid is scale or rating, value is number 3,5 or 7, and item is the slected likert question (only one)
  //
  err = false;
  const newData = { ...item };
  switch (fId) {
    case 'scale':
      newData.scale = value;
      newData.ratings =
        value === 3
          ? ['', '', '']
          : value === 5
          ? ['', '', '', '', '']
          : ['', '', '', '', '', '', ''];
      return { ...newData };
    case 'ratings':
      let newRatings = [...item.ratings];
      newRatings[value.index] = value.value;
      return { ...item, ratings: [...newRatings] };
  }
};

const editRatingScale = (fId, value, item, err) => {
  //fid is scale or rating, value is number 3,5 or 7, and item is the slected likert question (only one)
  //
  err = false;
  const newData = { ...item };
  switch (fId) {
    case 'scale':
      newData.scale = value;
      newData.ratings =
        value === 3
          ? ['1', '2', '3']
          : value === 5
          ? ['1', '2', '3', '4', '5']
          : ['1', '2', '3', '4', '5', '6', '7'];
      return { ...newData };
    case 'ratings':
      let newRatings = [...item.ratings];
      newRatings[value.index] = value.value;
      return { ...item, ratings: [...newRatings] };
  }
};

const editNpsLabel = (fId, value, item, err) => {
  err.label = value === '' ? true : false;
  const newItem = { ...item };
  newItem[fId] = value;
  return { ...newItem };
};

const editTextBox = (fId, value, item) => {
  const newTextBox = { ...item };
  newTextBox[fId] = value;
  return { ...newTextBox };
};

const editSliderRange = (fId, value, item, err) => {
  if (err.range == undefined) {
    if (!err.range) {
      err.range = value === '' ? true : false;
    }
  } else {
    if (!err.range) {
      err.range = value === '' ? true : false;
    }
  }
  const newTextBox = { ...item };
  const newValue = value !== '' ? parseFloat(value) : null;
  newTextBox[fId] = newValue;
  return { ...newTextBox };
};

export const editField = (qId, fId, value, questionList, err) => {
  //fId is the index of the choices or options
  return questionList.map((item) => {
    if (qId !== item.id) {
      return { ...item };
    } else {
      switch (item.type) {
        case 'multipleChoice':
          return editMultipleChoice(fId, value, item, err.choices);
        case 'dropDown':
          return editDropDown(fId, value, item, err.options);
        case 'checkbox':
          return editCheckbox(fId, value, item, err.options);
        case 'likertScale':
          return editLikertScale(fId, value, item, err.ratings);
        case 'ratingScale':
        case 'starRating':
        case 'thumbsUpRating':
        case 'loveRating':
          return editRatingScale(fId, value, item, err.ratings);
        case 'nps':
          return editNpsLabel(fId, value, item, err);
        case 'textBox':
          return editTextBox(fId, value, item);
        case 'slider':
        case 'numeric':
          return editSliderRange(fId, value, item, err);
        default:
          return { ...item };
      }
    }
  });
};
