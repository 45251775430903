import axios from 'axios'
import {
    GET_ALL_MICRO_OBJECTIVES,
    GET_SINGLE_MICRO_OBJECTIVE,
    CREATE_MICRO_OBJECTIVE,
    BACK_BUTTON_IN_DETAIL_PAGE,
    DELETE_MICRO_OBJECTIVE,
    UPDATE_MICRO_OBJECTIVCE
} from './actionTypes'
import baseUrl from '../../../../url'

export const getAllMicroObjectives = (ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/microObjectives/list/all/?page=1&&limit=500`)
        .then(response => {
            const res = response.data.objectives.map(r => {
                let createdAT = r.createdAt.slice(0, 10)
                return { createdAT, ...r }
            })
            dispatch({ type: GET_ALL_MICRO_OBJECTIVES, payload: res })
        })
        .catch(error => ErrorToast())

}


export const getMicroObjectiveDetail = (id, ErrorToast) => dispatch => {

    axios.get(`${baseUrl}/api/microObjectives/${id}`)
        .then(res => {
            dispatch({ type: GET_SINGLE_MICRO_OBJECTIVE, payload: res.data })
        })
        .catch(err => ErrorToast())
}

export const createNewMicroObjective = (values, CreateToast, ErrorToast) => dispatch => {

    axios.post(`${baseUrl}/api/microObjectives`, values)
        .then(res => {
            // console.log(res)
            dispatch({ type: CREATE_MICRO_OBJECTIVE, payload: res.data })
            CreateToast()
        })
        .catch(err => ErrorToast())

}


export const deleteMicroObjectiveById = (state) => dispatch => {
    dispatch({ type: DELETE_MICRO_OBJECTIVE, payload: state })
}

export const updateMicroObjectiveById = (id, value, UpdateToast, ErrorToast) => dispatch => {

    axios.patch(`${baseUrl}/api/microObjectives/${id}`, value)
        .then(res => {
            dispatch({ type: UPDATE_MICRO_OBJECTIVCE, payload: res.data });
            UpdateToast();
        })
        .catch(err => ErrorToast())

}

export const backButtonFunction = () => dispatch => { dispatch({ type: BACK_BUTTON_IN_DETAIL_PAGE }) }


