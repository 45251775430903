import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import axios from 'axios';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { fetchProfile } from '../../../../../../url';
import { DotLoader } from '../../../../../Loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import {
	Button,
	Card,
	Col,
	FormGroup,
	Label,
	Form,
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap';
import {
	createQRcode,
	getAllProfileWiseQRcode,
	getAllBranch,
} from '../../../../../../url/index';
import showToast from '../../../../../../components/Toast/toast';

const QrCodeCreate = (props) => {
	const { history } = props;
	const [qrCodes, setQRcodes] = useState([]);
	const [branches, setbranches] = useState([]);

	const [editable, setEditable] = useState({ fetched: false, loaded: '' });

	useEffect(() => {
		const fetchData = async () => {
			const result = await axios(
				fetchProfile(localStorage.getItem('jwtProfileId'))
			);
			setEditable({
				...editable,
				loaded: result.data,
				fetched: !editable.fetched,
			});
		};
		fetchData();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			const res = await axios(
				getAllProfileWiseQRcode(localStorage.getItem('jwtProfileId'))
			);
			setQRcodes(res.data);

			const res2 = await axios(
				getAllBranch(localStorage.getItem('jwtProfileId'))
			);
			setbranches(res2.data.branch);
		};
		fetchData();
	}, []);

	try {
		if (branches.length) {
			// Sorting Alphabetically
			let compare = (a, b) => {
				const nameA = a.name.toUpperCase();
				const nameB = b.name.toUpperCase();

				let comparison = 0;
				if (nameA > nameB) {
					comparison = 1;
				} else if (nameA < nameB) {
					comparison = -1;
				}
				return comparison;
			};
			branches.sort(compare);
		}
	} catch (error) {}

	const CreateToast = () => showToast('Created Successfully');
	const ErrorToast = () => showToast('Server Error');

	return (
		<Card style={{ padding: '20px 25px 15px 40px' }}>
			<section>
				{editable.fetched === false ? (
					<DotLoader />
				) : (
					<>
						<Formik
							initialValues={{
								name: '',
								status: true,
								branch: '',
								isDefault: true,
							}}
							validationSchema={Yup.object().shape({
								name: Yup.string()
									.required('Required')
									.trim()
									.lowercase()
									.notOneOf(
										qrCodes
											? qrCodes.map((obj) => {
													if (obj.name) return obj.name.toLowerCase();
											  })
											: [],
										'Already Exist'
									),
							})}
							onSubmit={(values, { resetForm }) => {
								let createdQRcode = {};
								if (values.branch) {
									createdQRcode = {
										createdByCompanyID: localStorage.getItem('jwtProfileId'),
										name: values.name.trim(),
										isDefault: values.isDefault,
										active: values.status,
										branchId: values.branch,
										createdAt: Date.now(),
									};
								} else {
									createdQRcode = {
										createdByCompanyID: localStorage.getItem('jwtProfileId'),
										name: values.name.trim(),
										active: values.status,
										isDefault: values.isDefault,
										createdAt: Date.now(),
									};
								}
								axios
									.post(createQRcode, createdQRcode)
									.then((res) => CreateToast())
									.catch((error) => ErrorToast());
								resetForm();
								history.push('/app/profile/qrCode');
							}}
						>
							{(props) => (
								<Form onSubmit={props.handleSubmit}>
									<FormGroup className='d-flex flex-wrap'>
										<Label htmlFor='name' sm={4} style={{ paddingLeft: '0px' }}>
											Name:<span style={{ color: 'red' }}>{` *`}</span>
										</Label>
										<Col>
											<Input
												type='text'
												name='name'
												id='name'
												placeholder='Enter device name'
												style={{
													border:
														props.errors.name && props.touched.name
															? '1px solid red'
															: null,
												}}
												value={props.values.name}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											/>
											{props.errors.name && props.touched.name && (
												<p
													style={{
														color: 'red',
														fontSize: '12px',
														marginLeft: '5px',
														textAlign: 'left',
														fontWeight: 'bold',
													}}
												>
													{' '}
													{props.errors.name}{' '}
												</p>
											)}
										</Col>
									</FormGroup>
									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='branch'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											{' '}
											Branch:{' '}
										</Label>
										<Col>
											<Input
												type='select'
												name='branch'
												id='branch'
												value={props.values.branch}
												onChange={props.handleChange}
												onBlur={props.handleBlur}
											>
												<option value='' disabled defaultValue hidden>
													Select...
												</option>
												{!branches.length && (
													<option value='' disabled defaultValue>
														Nothing to select!
													</option>
												)}
												{branches.map((obj) => {
													return (
														<option value={obj._id} key={obj._id}>
															{' '}
															{obj.name}{' '}
														</option>
													);
												})}
											</Input>
										</Col>
									</FormGroup>
									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='status'
											className='mt-1'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											{' '}
											Active:{' '}
										</Label>
										<Col style={{ marginTop: '7px' }}>
											<Input
												type='checkbox'
												id='status'
												name='status'
												className='form-check-input mt-2'
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												checked={props.values.status}
											/>
										</Col>
									</FormGroup>

									<FormGroup className='d-flex flex-wrap'>
										<Label
											htmlFor='isDefault'
											className='mt-1'
											sm={4}
											style={{ paddingLeft: '0px' }}
										>
											{' '}
											Default:{' '}
										</Label>
										<Col style={{ marginTop: '7px' }}>
											<Input
												type='checkbox'
												id='isDefault'
												name='isDefault'
												className='form-check-input mt-2'
												onChange={props.handleChange}
												onBlur={props.handleBlur}
												checked={props.values.isDefault}
											/>
										</Col>
									</FormGroup>

									<Button
										type='submit'
										className='btnHover'
										style={{ background: '#1B998B', borderColor: '#1B998B' }}
										size='sm'
									>
										{' '}
										Save{' '}
									</Button>
									<Button
										className='btnHover'
										style={{
											background: '#1B998B',
											borderColor: '#1B998B',
											margin: '10px',
										}}
										size='sm'
										onClick={() => {
											history.push('/app/profile/qrCode');
										}}
									>
										Cancel
									</Button>
								</Form>
							)}
						</Formik>
					</>
				)}
			</section>
		</Card>
	);
};

export default withRouter(QrCodeCreate);
