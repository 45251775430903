import styled from 'styled-components'

export const Option = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  >input{
    margin: 5px;
    padding: 0;
  }
  >label{
    display: block;
    padding: 5px;
    font-size:.8em;
  }
`