import jwt_decode from 'jwt-decode'; 
import setAuthToken from './util/setAuthToken'; 
import React, { Component } from 'react'; 
import { BrowserRouter, Route, Switch } from 'react-router-dom'; 
import styled from 'styled-components'; 

//new added 
import LandingPage from './pages/LandingPage/index'
import SignUp from './pages/SignUp'; 
import SignIn from './pages/SignIn'; 
import MainPage from './pages/MainPage'; 
import PrivateRoute from './privateRoute'; 
import FormAnonymous from './pages/FormAnonymous'; 
import FormQrCode from './pages/FormAnonymous/FormQrCode'; 
import PricingPage from './pages/Pricing/index'
import Terms from './pages/Terms/Terms'
import AboutUs from './pages/AboutUs/about'
import ProlificXMfeatures from './pages/ProlificXMfeatures/index'
import featureDetail from './pages/ProlificXMfeatures/featuresDetail'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
import LandingBlog from './pages/LandingBlog/landingBlog';
import PostDetails from './pages/LandingBlog/components/postdetails';
import XM from './pages/XM/xm'
import SurveyPage from './pages/SurveyPage/SurveyPage'
import ResetPassword from './pages/ResetPassword/ResetPassword';
import EmailVerification from './pages/EmailVerification/EmailVerification';

const CryptoJS = require("crypto-js");

toast.configure({
  autoClose: 3000,
  position: 'bottom-right',
  progressStyle: {
    background: '#1B998B',
    height: '3px'
  } 
});

class App extends Component {
  render() {
    const Body = styled.div`
      box-sizing: border-box;
      display: flex;
      flex-direction: column
      height: 100vh;
      width:  100vw;
      /* justify-content: center;
      align-items: center; */
      overflow: hidden;
      ::-webkit-scrollbar{
        width: 0;
      }
      scrollbar-width: none;
    `

    // Check for user token 
    if (localStorage.jwtToken) { 

      const bytes = CryptoJS.AES.decrypt(localStorage.jwtToken.toString(), 'p0tat@_Pex-app');
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      // Set auth token header
      setAuthToken(decryptedToken);

      var decoded = "";
      // ensure it's actually JWT format
      try {
        // Decode token and get the user data
        decoded = jwt_decode(decryptedToken);
      } catch (error) {
        // console.log('Invalid Token Format', error);
        localStorage.clear();
        window.location.href = '/';
      }

      // Check for expired token
      const currentTime = Date.now() / 1000;

      if (decoded.exp < currentTime) {

        // Remove token
        localStorage.clear();
        setAuthToken(false);
        // Logout user
        // Redirect to login
        window.location.href = '/';
      } 
    } 

    return (
      <Body> 
        <BrowserRouter> 
          <Switch> 
            <PrivateRoute path="/app"                       component={MainPage} /> 
            <Route exact path="/"                           component={LandingPage} /> 
            <Route exact path="/pricing"                    component={PricingPage} /> 
            <Route exact path="/blog"                       component={LandingBlog} /> 
            <Route exact path="/blog/:id"                   component={PostDetails} /> 
            <Route exact path="/terms"                      component={Terms} /> 
            <Route exact path="/xm"                         component={XM} /> 
            <Route exact path="/survey"                     component={SurveyPage} /> 
            <Route exact path="/aboutus"                    component={AboutUs} /> 
            <Route exact path="/prolificxm-features"        component={ProlificXMfeatures} /> 
            <Route exact path="/prolificxm-features/:title" component={featureDetail} /> 
            <Route path="/signin"                           component={SignIn} /> 
            <Route path="/signup"                           component={SignUp} /> 
            <Route path="/password-reset"                   component={ResetPassword} /> 
            <Route path="/verification"                     component={EmailVerification} /> 
            <Route path="/openlink/:hashID"                 component={FormAnonymous} /> 
            <Route path="/qrcode/:hashID"                   component={FormQrCode} /> 
            <Route render={() => (<div>Error 404</div>)} /> 
          </Switch> 
        </BrowserRouter> 
      </Body> 
    ); 
  } 
} 

export default App;
