import React, { useState, useEffect } from 'react'

import { MainBody } from './styles'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList'
import EditNextButton from '../../components/EditNextButton'
import { useDispatch } from 'react-redux'
import { setSurveyConfigStep, setSurveyPreviewStep } from '../../redux/actions'

// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { connect } from 'react-redux'
import { editDistributionQuestion, editDistributionConfig, selectProjectAndFetch, setActiveChannel } from '../../redux/actions'

import EditStep from '../../components/EditStep'
import { Link } from 'react-router-dom'
const SelectPage = props => {

  const { disid } = props.match.params
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(setSurveyConfigStep())
    dispatch(setSurveyPreviewStep())
  }, [])

  return (
    <MainBody>
      <div className="container">
        {/* <Link to="/app/survey"><BreadCrumb path="Survey /" /></Link> */}
        <EditStep />
        <EditNextButton disid={disid} />
        <Configuration />
        <QuestionList />
      </div>
    </MainBody>
  )
}

export default connect(null, { editDistributionQuestion, editDistributionConfig, selectProjectAndFetch, setActiveChannel })(SelectPage)