import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom'
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
// import collect from 'collect.js';

import { withRouter } from 'react-router-dom';
import {
  Container,
  PreviewBody,
  SaveButton,
  SubmitButton,
  DistributeButton,
} from './styles';
import './style.css';
import ProjectGroup from '../../container/ProjectGroup';
import Header from '../../components/Header';
import AdditionalContentBar from '../../components/AdditionalContentBar';
import baseUrl from '../../../../url/index';
import axios from 'axios';
// import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'

import {
  sortQuestion,
  sortProjects,
  addCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  updateOpenLink,
  setOpenLinkInDistribution,
  initQrCode,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar,
  setRedirectUrl,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  restoreSelectedQuestionAndOrder,
  changeConfigurationStatus,
  removeCard,
} from '../../redux/actions';
import {
  apiDataGeneratorSelector,
  activeDeviceSelector,
  saveButtonSelector,
  sortandpreviewSelector,
  projectOrderSelector,
} from '../../redux/selectors';
import Step from '../../components/Step/index';

import CreateSelectPage from '../SelectPage/createIndex';
import { ProgressbarRedirectUrl } from '../SortAndPreviewWithoutdisId/styles';

const SortAndPreview = (props) => {
  const {
    sortQuestion,
    sortProjects,
    addCard,
    dataToPush,
    history,
    pushConfiguration,
    updateConfiguration,
    distributeConfiguration,
    enableSave,
    setProfileIdInState,
    disId,
    fetchChannels,
    statusDevice,
    fetchDeviceLists,
    activeDevice,
    deviceLists,
    setDeviceActive,
    setDistDevice,
    status,
    statusOpenLink,
    initOpenLink,
    setOpenLink,
    updateOpenLink,
    setOpenLinkInDistribution,
    openlinks,
    initQrCode,
    setQRCode,
    statusQrCode,
    qRCode,
    setGenQrCode,
    setQrCodeInDistribution,
    order,
    isReadOnly,
    progressbar,
    setProgressbar,
    redirectUrl,
    setRedirectUrl,
    sortandpreviewEnabled,
    createProjectForSurveyOnReload,
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
    projectFetch,
    setSurveyProjectStep,
    setSurveyChannelStep,
    setSurveyPreviewStep,
    setSurveyConfigStep,
    restoreSelectedQuestionAndOrder,
    questionLists,
    surveyQuestionSaveMessage,
    changeConfigurationStatus,
    projectList,
    removeCard,
  } = props;
  const enablePreview = !enableSave;

  const [profileId, setProfileId] = useState(
    localStorage.getItem('jwtProfileId')
  );
  // const [deviceStatus, setDeviceStatus] = useState(false);
  // const [linkStatus, setLinkStatus] = useState(false);
  // const [qrCodeStatus, setQrCodeStatus] = useState(false)
  // console.log(history.location.state)
  // const openlink = {
  //   "name": "Open link",
  //   "createdByCompanyID": profileId,
  //   "status": linkStatus,
  //   "distributionId": disId
  // }

  // const qrCode = {
  //   "name": "QR Code",
  //   "createdByCompanyID": profileId,
  //   "status": qrCodeStatus,
  //   "distributionId": disId,
  // }

  // useEffect(() => {
  //   restoreSelectedQuestionAndOrder()
  //   // console.log('execute')
  //   // console.log(questionLists)
  // }, [questionLists])

  // useEffect(() => {
  //   console.log('surveyQuestionSaveMessage', surveyQuestionSaveMessage)
  //   if (surveyQuestionSaveMessage === true) {
  //     if (status === 'SUBMITTED' || status === 'DISTRIBUTED') {
  //       changeConfigurationStatus(disId)
  //     }
  //   }

  // }, [status, surveyQuestionSaveMessage])

  useEffect(() => {
    // const fetchDistDataOnReload = async (surveyid) => {
    //   const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`)
    //   dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data })
    //   dispatch(setSurveyProjectStep())

    //   const disid = disId.data.distributionId
    //   if (disid) {
    //     axios.get(`${baseUrl}/api/distribution/details/${disid}`).then(res => {

    //       dispatch(editDistributionQuestion(res.data, disid))
    //       dispatch(editDistributionConfig(res.data))
    //       dispatch(selectProjectAndFetch(res.data.question.questionBank.projectOrder[0]))
    //       dispatch(projectFetch(localStorage.getItem('jwtProfileId')))

    //       if ('activeChannels' in res.data) { dispatch(setActiveChannel(res.data.activeChannels)) }

    //       if (res.data.status === "DRAFT") {
    //         dispatch(setSurveyConfigStep())
    //         dispatch(setSurveyPreviewStep())
    //       }
    //       if (res.data.status === "SUBMITTED") {
    //         dispatch(setSurveyConfigStep())
    //         dispatch(setSurveyPreviewStep())
    //         dispatch(setSurveyChannelStep())
    //         dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
    //       }
    //       if (res.data.status === "DISTRIBUTED") {
    //         dispatch(setSurveyConfigStep())
    //         dispatch(setSurveyPreviewStep())
    //         dispatch(setSurveyChannelStep())
    //         dispatch(fetchChannels(localStorage.getItem("jwtProfileId")))
    //       }
    //     })
    //   }
    // }

    const fetchDistDataOnReload = async (surveyid) => {
      const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`);
      // dispatch({ type: 'CREATE_PROJECT_FOR_SURVEY', payload: disId.data }) //
      createProjectForSurveyOnReload(disId.data);
      setSurveyProjectStep();
      setSurveyConfigStep();

      const disid = disId.data.distributionId;
      if (disid) {
        axios
          .get(`${baseUrl}/api/distribution/details/${disid}`)
          .then((res) => {
            editDistributionQuestion(res.data, disid);
            editDistributionConfig(res.data);
            selectProjectAndFetch(
              res.data.question.questionBank.projectOrder[0]
            );
            projectFetch(localStorage.getItem('jwtProfileId'));

            if ('activeChannels' in res.data) {
              setActiveChannel(res.data.activeChannels);
            }

            if (res.data.status === 'DRAFT') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
            }
            if (res.data.status === 'SUBMITTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              // setSurveyChannelStep()
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
            if (res.data.status === 'DISTRIBUTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              setSurveyChannelStep();
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
          });
      }
    };

    if (disId === '') {
      fetchDistDataOnReload(props.match.params.surveyid);
    }
  }, [disId]);

  useEffect(() => {
    setProfileIdInState(profileId);
  }, []);

  // const deviceStatusOnClick = () => {
  //   let deviceStatus = collect(deviceLists).where('status', '=', 'Active').isNotEmpty();
  //   setDeviceStatus(deviceStatus)
  // }

  // const setOpenLinkStatus = value => {
  //   setLinkStatus(value)
  // }

  // const setQrCode = value => {
  //   setQrCodeStatus(value)
  // }

  const handleSaveClick = () => {
    const { data } = dataToPush;
    pushConfiguration(data, profileId);
  };

  const handleSubmitClick = () => {
    const { data } = dataToPush;
    updateConfiguration(data, profileId, disId, history, 'create');
    fetchChannels(profileId);
    // initOpenLink(openlink)
    // initQrCode(qrCode)
  };

  // const handleDistributeClick = () => {
  //   const { data } = dataToPush
  //   if (deviceStatus) { setDistDevice(deviceLists, disId) }
  //   if (linkStatus) { setOpenLink(openlink) }
  //   if (qrCodeStatus) { setQRCode(qrCode) }
  //   distributeConfiguration(data, activeDevice, profileId, disId)
  // }

  const handleDistributeClick = () => {
    const { data } = dataToPush;
    distributeConfiguration(data, profileId, disId, history, 'create');
    fetchChannels(profileId);
  };

  // const updateLink = (id, obj) => {
  //   updateOpenLink(id, obj)
  //   setOpenLinkInDistribution(id, { id: openlinks._id, hashId: openlinks.hashID })
  // }

  // const genQrCode = (id) => {
  //   setQrCodeInDistribution(id, { id: qRCode._id, hashId: qRCode.hashId })
  // }

  const onDragEnd = ({ destination, source, draggableId, type }) => {
    if (destination) {
      if (type === 'projects') {
        if (source.droppableId === 'additionalCardsList') {
          addCard(destination, draggableId);
          return;
        }
        sortProjects(destination, source, draggableId);
        return;
      }
      sortQuestion(destination, source, draggableId);
    }
  };

  return (
    <>
      {sortandpreviewEnabled ? (
        <div className='container'>
          <Step />
        </div>
      ) : (
        <div className='container'>
          <Step />
          <div className='container'>Please wait ... </div>
        </div>
      )}

      <div>
        <div style={{ position: 'absolute', left: '-9999px' }}>
          <CreateSelectPage />
        </div>

        {sortandpreviewEnabled && (
          <Container>
            <ProgressbarRedirectUrl>
              <p
                style={{
                  margin: 0,
                  padding: '21px',
                  fontWeight: 500,
                }}
              >
                Survey Settings
              </p>

              <hr style={{ marginTop: '0px' }} />

              <div className='row settings-row'>
                <div className='col-12 p-0'>
                  <label className='dislabel'>
                    <input
                      name='progressbar'
                      type='checkbox'
                      checked={progressbar === true ? true : false}
                      disabled={isReadOnly}
                      style={{ marginRight: '10px' }}
                      onChange={(event) => {
                        const target = event.target;
                        const value =
                          target.type === 'checkbox'
                            ? target.checked
                            : target.value;
                        setProgressbar(value);
                      }}
                    />
                    Show question count?
                    <br />
                    (Not applicable for conditional question)
                  </label>
                </div>
                <div className='col-12 p-0'>
                  <label>
                    Redirect to website after response:
                    <input
                      className='form-control'
                      type='text'
                      value={redirectUrl}
                      onChange={(event) => setRedirectUrl(event.target.value)}
                      disabled={isReadOnly}
                      placeholder='default: www.prolificxm.com'
                    />
                  </label>
                </div>
                <div className='col-12 p-0'>
                  <label className='dislabel'>
                    {}
                    <input
                      name='progressbar'
                      type='checkbox'
                      disabled={isReadOnly}
                      style={{ marginRight: '10px' }}
                      checked={
                        projectList.find(
                          (item) => item.type === 'contactCard'
                        ) !== undefined
                      }
                      onChange={(event) => {
                        // const target = event.target;
                        // const value =
                        //   target.type === 'checkbox'
                        //     ? target.checked
                        //     : target.value;
                        // newAddCard();
                        if (event.target.checked) {
                          addCard(0, 'contactCard');
                        } else {
                          removeCard('contactCard');
                        }
                      }}
                    />
                    Add Contact Card?
                    <br />
                    (Will be added at the top of the survey)
                  </label>
                </div>
              </div>
            </ProgressbarRedirectUrl>

            <div className='container-inner'>
              {status === '' && (
                <SaveButton type='button' onClick={handleSaveClick}>
                  Confirm
                </SaveButton>
              )}

              {(status === 'SUBMITTED' || status === 'DRAFT') && (
                <DistributeButton type='button' onClick={handleDistributeClick}>
                  Distribute
                </DistributeButton>
              )}

              <PreviewBody>
                <Header />
                {/* <DragDropContext onDragEnd={onDragEnd}> */}
                {/* <AdditionalContentBar /> */}
                {/* <AdditionalContentBar /> */}
                <ProjectGroup />
                {/* </DragDropContext> */}
              </PreviewBody>
            </div>
          </Container>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  projectList: projectOrderSelector(state),
  dataToPush: apiDataGeneratorSelector(state),
  enableSave: saveButtonSelector(state),
  activeDevice: activeDeviceSelector(state),
  status: state.question.status,
  progressbar: state.question.progressbar,
  redirectUrl: state.question.redirectUrl,
  isReadOnly: state.question.isReadOnly,
  disId: state.question.distributionId,
  order: state.question.order,
  statusDevice: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'device'
  ),
  statusOpenLink: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'openLink'
  ),
  statusQrCode: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'qrCode'
  ),
  deviceLists: state.question.deviceList,
  openlinks: state.question.openLink,
  qRCode: state.question.qRCode,
  projectId: state.survey.projectId,
  sortandpreviewEnabled: sortandpreviewSelector(state),
  questionLists: state.config.questionList,
  surveyQuestionSaveMessage: state.question.surveyQuestionSaveMessage,
});

const mapDispatchToProps = {
  sortQuestion,
  sortProjects,
  addCard,
  removeCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  initQrCode,
  updateOpenLink,
  setOpenLinkInDistribution,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  setProgressbar,
  setRedirectUrl,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
  restoreSelectedQuestionAndOrder,
  changeConfigurationStatus,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SortAndPreview)
);
