import React, { useState } from 'react';
import ReactDatePicker from './datepicker/ReactDatePicker';
import ReactMonthPicker from './monthpicker/ReactMonthPicker';
import DateRange from './daterange/DateRange';
import './Filter.css'

const Filter = (props) => {
    const [filterType, setFilterType] = useState("1");
    const [period, setPeriod] = useState("As On Date");

    const filterChange = (e) => {
        // console.log(e.target.value);
        setFilterType(e.target.value);
        if (e.target.value === "1") {
            setPeriod("As On Date");
            props.handleFilterParam(null);
        }
    }

    const getFilterParam = (filterParams) => {

        if (filterParams.type === "date") {
            setPeriod(new Intl.DateTimeFormat('en-GB', {day:'numeric' ,month:'long', year:'numeric'}).format(filterParams.startDate));
        }else if (filterParams.type === "month") {
            setPeriod(new Intl.DateTimeFormat('en-GB', {month:'long', year:'numeric'}).format(filterParams.startDate));
        }else if (filterParams.type === "daterange") {
            let dateRange = filterParams.startDate ? new Intl.DateTimeFormat('en-GB', {day:'numeric' ,month:'long', year:'numeric'}).format(filterParams.startDate) : "Start";
            dateRange +=  " to " + new Intl.DateTimeFormat('en-GB', {day:'numeric' ,month:'long', year:'numeric'}).format(filterParams.endDate);
            setPeriod(dateRange);
        }

        props.handleFilterParam(filterParams);
    } 

    let filterComponent = "";

    switch (filterType) {
        case "1":
            filterComponent = "";
            break;
        case "2":
            filterComponent = <ReactDatePicker getFilterParam={getFilterParam} type="date" />
            break;
        case "3":
            filterComponent = <ReactMonthPicker getFilterParam={getFilterParam} type="month" />
            break;
        case "4":
            filterComponent = <DateRange getFilterParam={getFilterParam} type="daterange" />
            break;
        default:
            filterComponent = ""
    }

    return (
        /* <div className="card mt-4 mb-4 Filter">
            <div className="card-body py-3">
                <div className="row">
                    <div className="col-lg-3 col-12">
                        <div className="mb-2">
                            <h5 className="title">{props.title}</h5>
                            <h6 className="period">{period}</h6>
                        </div>
                    </div>
                    <div className="col-lg-9 col-12">
                        <div className="d-flex flex-wrap justify-content-left flex-row">
                            <div>
                                <h6 className="text-left" style={{ fontSize: "12px", fontWeight: "700", color: "#424242" }}>View By</h6>
                                <select name="filterType" id="filterType" value={filterType} onChange={filterChange} className="form-control form-control-sm">
                                    <option className="filterTypeOption" value="1">As On Date</option>
                                    <option className="filterTypeOption" value="2">Date</option>
                                    <option className="filterTypeOption" value="3">Month</option>
                                    <option className="filterTypeOption" value="4">Date Range</option>
                                </select>
                            </div>
                            <div>{filterComponent}</div>
                            <div>
                                {filterType !== "1" && <button onClick={props.showFilterData} className="btn btn-sm btn-success px-3 py-1 mt-4 btnFilter">Go</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>*/

        <div className="card mt-4 mb-4 Filter">
            <div className="card-body">
                <div className="row">
                    <div className="col-lg-3 col-12">
                        <div className="mb-2">
                            <h5 className="title">{props.title}</h5>
                            <h6 className="period">{period}</h6>
                        </div>
                    </div>
                    <div className="col-lg-9 col-12 align-self-center">
                        <div className="d-flex justify-content-end flex-row">
                            <div>
                                <select name="filterType" id="filterType" value={filterType} onChange={filterChange} className="form-control form-control-sm">
                                    <option className="filterTypeOption" value="1">To Date</option>
                                    <option className="filterTypeOption" value="2">Date</option>
                                    <option className="filterTypeOption" value="3">Month</option>
                                    <option className="filterTypeOption" value="4">Daterange</option>
                                </select>
                            </div>
                            <div>{filterComponent}</div>
                            <div>
                                {filterType !== "1" && <button onClick={props.showFilterData} className="btn btn-sm btn-success px-2 btnFilter">Go</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Filter;