import React from 'react'
import image from './assets/community.png'
import image2 from './assets/community2.png'
import banner from './assets/Community_Experience.png'
import {css} from 'glamor'
import './style.css'

function Community() { 

  const contentTitle = css({textAlign: 'center', color: '#1B998B', fontSize: '22px', marginBottom: '20px'})
  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 
  const rightBorder = css({borderRight: '1px solid rgba(200, 200, 200, 0.5)'}) 
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 
  // style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}
  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Community Experience banner" {...bannerWidth} /> 
      </div> 
      <p {...text}>
        During my childhood, I used to play around my neighborhood like most other Children. There was barely any playground in our area but it could not restrict us from going out and play every day. We used to run clueless and scream from the top of our lungs without no reason; it seemed fun to do at that age. However, the senior citizens never seemed satisfied with our activities as most of the time, they used to stare deadly or yell at us to keep quiet. We would not bother listening to them as we thought what we were doing is the right thing to do. You can barely blame a child for doing so. The lesson that comes from home is what they obtain. If the parents are not aware of the situation, they are to be blamed. Now that I am an adult, I return from work with a plan to spend a peaceful and quiet evening at home. It could be assured in this era of technology only if some kids in my area would not think otherwise. Whether its Dawn or dusk, they seem to be doing the same thing I used to do at their age and it bothers me. Now I understand that I was a source of annoyance in my society and I remain quiet now. Imprisoning the children inside the house and keep them busy with an iPad is certainly not a wise idea. But we seem to be running out of options here. We wish we could sit together and make a decision collectively to sort this out!  
      </p>

      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="Community Experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        The Community Experience feature of ProlificXM aims to enable an opportunity to enhance the living experience in a community. Community Experience Management is designed to pinpoint the area of improvement, the issue to be resolved with priority and to determine what measures can be taken to ensure a better Social life. 
      </p> 

      {/* Image */} 
      <div className="text-center"> 
        <img src={image2} alt="Community Experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        The burning question is, why should we opt-out for an experience management system instead of sitting together and talking about it? Why should we not run a survey using Pen and Paper and decide where to look at and go for an Omni Channel platform instead? The answer is casually present inside the questions. Here is a comparison between a Community Survey and a well-designed Community Experience Management system.
      </p> 

      <div className="table-responsive"> 
        <table className="table table-borderless table-hover table-md" 
          style={{border: '1px solid rgba(200, 200, 200, 0.5)'}}> 
          <thead>
            <tr style={{
              background: '', color: 'rgb(52, 58, 64)', textAlign: 'center', 
              borderBottom: '2px solid rgba(200, 200, 200, 0.5)', fontSize: '15px'
            }}> 
              <th scope="col" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                Community Survey</th>
              <th scope="col">Community Experience Management</th>
            </tr> 
          </thead> 
          <tbody className="tableRowHover" style={{fontSize: '14px', color: 'rgb(52, 58, 64)'}}> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                1. Requires more time and resources to conduct.</td> 
              <td>1. Requires less time and almost Zero resource.</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                2. Requires more financial expenses.</td> 
              <td>2. Reduces financial expenses.</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                3. Limited Channel(s)</td> 
              <td>3. Omni Channel</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                4. Analysis requires additional resources and time.</td> 
              <td>4. Real-time analysis and insights helps instant decision making.</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                5. Questions designed by general people.</td> 
              <td>5. Questions designed by experts.</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                6. Manual storage and access restricted.</td> 
              <td>6. Cloud storage and 24/7 access to data.</td> 
            </tr> 
            <tr> 
              <td scope="row" style={{borderRight: '1px solid rgba(200, 200, 200, 0.5)'}}>
                7. Data Security not guaranteed.</td> 
              <td>7. Data Security guaranteed by XM provider.</td> 
            </tr> 
          </tbody> 
        </table> 
      </div> 

      <p {...text}> 
        We understand that it can be troublesome for any beginner to decide from where to start. We suggest you take a look at ProlificXM Community Experience Templates designed by our expert team.
      </p> 
    </div> 
  ) 
} 

export default Community; 
