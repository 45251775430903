import styled from 'styled-components'
import { NavLink, Link } from 'react-router-dom'

export const Type = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: flex-start;
  background-color: #310D31;
  min-height: 40px;
  padding: 0;
  margin: 0 10px;
  user-select: none;
  box-shadow: 2px 2px 5px 4px #E6E6E6;
  z-index: 1;
`
export const StyledList = styled.ul`
  display: block;
  list-style:none;
  margin: 0;
  padding: 0;
  min-height: 40px;
  >li{
    cursor: pointer;
    display: inline-block;
    transition: 0.25s background-color;
    :hover{
      background-color: white;
    }
  }
  z-index: 1;
`
export const StyledLink = styled(NavLink).attrs({ activeClassName: "active" })`
  display: block;
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  padding: 11px 10px;
  color: white;
  &.active{
    color: #310D31;
    background-color: white;
  }
  :hover{
    color: #310D31;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px;
  }
  
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
  
`

export const MainBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  width: 100%;
`

export const Back = styled(Link)`
  display: block;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  padding: .1rem .6rem;
  font-size: .875rem;
  line-height: 1.5;
  background: rgb(27, 153, 139);
  border: none;
  margin: -2px 0px 0px 20px;
  align-self: flex-start;
  box-shadow: 1px 1px 5px 2px #E6E6E6;
  border-radius: .2rem;
  :hover{
    color: white;
    background: #0B6157 !important;
    text-decoration: none;
  }
  :hover::before{
    border-right: 10px solid white;
  }
  // &:before{
  //   content: "";
  //   position: absolute;
  //   top: 10px;
  //   left: 10px;
  //   width: 0;
  //   height: 0;
  //   border-top: 12.5px solid transparent;
  //   border-right: 10px solid #310D31;
  //   border-bottom: 12.5px solid transparent;
  // }
  @media only screen and (max-width: 768px){
    font-size: 12px;
  }
  
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
  
  
`