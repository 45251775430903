
import {
    CREATE_BRAND_CATEGORY, GET_ALL_BRAND_CATEGORY, 
    GET_SINGLE_BRAND_CATEGORY, UPDATE_BRAND_CATEGORY, 
    DELETE_BRAND_CATEGORY, ERROR_IN_BRAND_CATEGORY, 
    BACK_BUTTON_IN_DETAIL_PAGE, PARENTS_OF_BRAND_CATEGORY, 
    PARENTS_OF_BRAND_CATEGORY_TRUE_ONLY
} from '../actions/actionTypes'

const init = { 
    allData: [], 
    parents: [], 
    parentsTrueOnly: [], 
    dataDetails: {}, 
    errorText: '' 
} 


const brandCategoryReducer = (state = init, action) => { 
    switch (action.type) { 
        case ERROR_IN_BRAND_CATEGORY: { 
            return { 
                ...state, 
                errorText: 'Server Error' 
            } 
        } 
        case PARENTS_OF_BRAND_CATEGORY: { 
            return { 
                ...state, 
                parents: action.payload
            } 
        } 
        case PARENTS_OF_BRAND_CATEGORY_TRUE_ONLY: { 
            return { 
                ...state, 
                parentsTrueOnly: action.payload
            } 
        } 
        case GET_ALL_BRAND_CATEGORY: { 
            return {
                ...state, 
                allData: action.payload
            } 
        } 
        case GET_SINGLE_BRAND_CATEGORY: { 
            return { 
                ...state, 
                dataDetails: action.payload 
            } 
        } 
        case BACK_BUTTON_IN_DETAIL_PAGE: { 
            return { 
                ...state, 
                dataDetails: {}, 
                parentsTrueOnly: [] 
            } 
        } 
        case UPDATE_BRAND_CATEGORY: { 
            let data = state.allData.data.map(obj => { 
                if(obj._id === action.payload._id) { 
                    return action.payload 
                } 
                return obj 
            }) 
            let datas = { 
                count: data.length, 
                data 
            } 
            return { 
                ...state, 
                allData: datas, 
                dataDetails: action.payload 
            } 
        } 
        case DELETE_BRAND_CATEGORY: { 
            let data = state.allData.data.filter(obj => { 
                if(obj._id !== action.payload.state._id) { return obj } 
            }) 
            let datas = { count: data.length, data } 

            return { 
                ...state, 
                allData: datas, 
                dataDetails: {} 
            } 
        } 
        case CREATE_BRAND_CATEGORY: { 
            const allDatas = state.allData.data.concat(action.payload) 
            
            return { 
                ...state, 
                allData: {
                    count: allDatas.length, 
                    data: allDatas 
                }
            } 
        } 
        default: return state 
    } 
} 

export default brandCategoryReducer; 
