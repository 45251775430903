import React from 'react'

function Privacy() { 
  const style = { 
    p: { color: 'rgb(52, 58, 64)', fontSize: '13px', textAlign: 'justify', fontFamily: 'Roboto', fontWeight: '400' }, 
    list: { color: 'rgb(52, 58, 64)', fontSize: '13px', textAlign: 'justify', fontFamily: 'Roboto', fontWeight: '400', marginLeft: '30px' } 
  } 

  return ( 
    <div className="my-5 ml-3 text-justify"> 
      <section id="privacy-statement-prolificxm-privacy-statement"> 
        <h3 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>ProlificXM Privacy Statement</h3> 
        <p style={{...style.p}}>
          The following privacy statement explains how ProlificXM and its delegates maintain privacy of customer data, content and other materials during the course of using our services such as registration, survey, questionnaire, and responses. Also information and data collected in the business process such as sales, marketing, and support are handled in a process which is explained below.
        </p> 
      </section>


      <section id="privacy-statement-data-collection-and-services">
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Data Collection and Services</h5> 
        <p style={{...style.p}}>
          ProlificXM provides an online experience management platform for use in collecting and analyzing user experience information for different business entities such as corporations, partnerships, limited liability companies, limited liability partnerships through online surveys and questionnaires. ProlificXM services are self-service type and it depends on customers or users how they collect their data or responses through it. As an Omni Channel experience management platform, ProlificXM uses multiple channels including Open Link (URL), QR Code, Email and offline app. ProlificXM will act as a data processor and collector as instructed by the customers. Whatever data you employ our services to gather, whether its survey responses, data collected during a form, or data inserted on a site hosted by us – it’s all of your data!  Referred to as personal information or simply information, means information about you that we collect or we take the custody of it. 
        </p> 
      </section>



      <section id="privacy-statement-information-we-collect"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Information We Collect</h5> 
        <p style={{...style.p}}>
          When you choose to use our website or app, you provide us with data which may or may not include your personal data. Here are the details about why, how, and for how long we collect those data. Your rights are also briefly explained in the following paragraphs.
        </p> 
      </section>



      <section id="information-we-collect-customer-information">
        <h6 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Customer Information</h6> 
        <p style={{...style.p}}>
          When a Customer register on our Site, we ask the customer to give us information like name and contact information such as company name, contact person name, company address, email address, phone number, username and password, and billing information which may include credit card numbers. Customers also provide us with Information regarding the Services they have ordered such as the number of surveys they will pay for or whether they want a private or public survey. We may or may not ask Customers permission to share his location through the Device the Customers are using our Services. In addition to those information customers knowingly provide, we also know when and how often they log on to the Site.
        </p>  
      </section> 



      <section id="information-we-collect-participant-information">
        <h6 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Participant Information</h6> 
        <p style={{...style.p}}>
          We receive Participant Information from both our Customers and Survey Participants. You may provide us with the contact information of the participants such as email address and name. Before providing such Information, it is obligatory that you commit to us that you have a pre-existing relationship with the Survey Participant and/or the Survey Participant permits you to send electronic messages. Survey Participants may provide us the Information asked by you, such as their gender or zip code, and the answers to the survey questions. Our Customers are solely responsible for the content of the surveys and we do not Control the essence or questions asked in any survey. Participants can permit ProlificXM to access their Social Media Handles in order to share or provide their responses at their liability. If you instruct the Website to Collect Location of the participants for your survey, the Website ask the Participants for their Permission to Fetch their Location and provide to you accordingly. Otherwise, ProlificXM do not seek this information. Under no circumstance, ProlificXM will be held responsible for any misuse of the Access Granted by the participants on any Social Media Handles or any other Channels, or the information instructed to collect by the Customers. Therefore, ProlificXM will not indemnify the Participants with any sort of compensation for such incident. In addition, we receive any Information contained in any communications a Survey Participant makes directly with ProlificXM.
        </p> 
      </section>


      <section id="information-we-collect-automated-data-collection"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Automated Data Collection</h5> 
        <p style={{...style.p}}>
          When you visit our website in order to use the Services or other purpose, our servers capture data such as your IP address, Device Identifier and other information about your device such as the operating system of the device, time zone setting, and language setting of your device which can be wont to identify you and your device. Our servers may also capture information about your visit to our website, such as the site from which you have been referred, duration and the time of your visit on each page on the website, your page to page navigation path, and what you click on. In addition, we may employee other third-party service providers to capture different information about your visit on our website such as tracking your activity and recording your scrolling. We may place a cookie on your browser with an intention to identify you as a return visitor if you visit our site several times. You can check our Cookies Policy for more Information.
        </p> 
      </section>



      <section id="privacy-statement-purpose-of-information-collection"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Purpose of Information Collection</h5> 
        <p style={{...style.p}}>
          We collect Information for few purposes associated with our Business. The primary purposes are as follows:
        </p> 
        <ul style={style.list}>
          <li>Completion of the User Registration Process.</li>
          <li>Reduce the effort of Signing Up by linking other website Profiles Such as Google, Facebook, LinkedIn.</li>
          <li>An easy access to those Profiles for future Sharing option.</li>
          <li>Activate the Account for using our Services.</li>
          <li>Ensure User access to our Products and Services.</li>
          <li>Align our Customers with Changes in our Terms or Services</li>
          <li>Send email notifications to Customers about performance of their surveys.</li>
          <li>Notify Customers with their Plans, Purchases and Billing-related information.</li>
          <li>Provide Customers with Necessary support in using our Services.</li>
          <li>Educate our Customers with new Products and Updates.</li>
          <li>To Promote our Products, Services and Activities to Potential and Regular Customers.</li>
          <li>Our Legitimate Business Interest in regular Website Visitors.</li>
        </ul> 
        <p style={{...style.p}}>
          Apart from these purposes, there are few secondary purposes. ProlificXM can confirm that all of those purposes have Legal Basis bound by business interest.
        </p> 
      </section> 




      <section id="privacy-statement-retension-of-information"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Retention of Information</h5> 
        <p style={{...style.p}}>
          ProlificXM will retain your Information as long as your account is active or as long as needed to provide you services. We will retain and use your information as necessary in order to comply with our legal obligations, for resolving disputes, and to enforce our agreements.
        </p> 
      </section>



      <section id="privacy-statement-optout-from-sharing-information"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Opt-out from Sharing/Receiving Information</h5> 
        <p style={{...style.p}}>
          ProlificXM respects your choice of Sharing information or receiving our Updates. Therefore, you can choose which type of Information you want to provide to ProlificXM for Account completion. In accordance, ProlificXM will provide you Service within the limit. <br /> <br /> 
          Similarly, if you want to Opt-out from receiving our Promotional or regular update messages, you Promotional electronic messages or messages regarding your Survey Performances. However, ProlificXM will notify you in case of any Account Security Matter, Payment and Purchases, Billing Information and Subscription Expiration, Suspension, Termination, Change in Terms, Change in Plan, Change in Services, and Breach in Terms of ProlificXM regardless of your preference.
        </p> 
      </section>


      
      <section id="privacy-statement-your-rights-to-your-data"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Your Rights to Your Data</h5> 
        <p style={{...style.p}}>
          It is important that you know and practice your rights over your Data. You can:
        </p> 
        <ul style={style.list}>
          <li>Download or backup your survey data any time. You can also share, publish and export your survey data in different formats using our export data feature. Thus, you can create your own offline backups and store it locally on your device.</li> 

          <li>Update your account information. You can update your basic and detailed account information from “My account” page any time. However, we may keep a history of your earlier account information for a brief period of time for our internal and security considerations.</li> 

          <li>Delete all your survey data. Deleting survey data will immediately remove the survey data permanently from our servers. Thus, once you choose to erase all your survey data, it can no longer be restored or reached from your account.</li> 

          <li>Delete your account. You can close your account at any time you want through your “My account” page. Deleting your account will cause all the survey data in the account to be permanently erased. We will immediately disable your access to any other services that require an account. If you are a paying user of any subscription plan that will immediately be terminated. For multi-user account, your team members’ access to the account will no longer work. Creating a backup of your data before deleting your account will be solely your responsibility.</li> 

          <li>Ask us to manually delete our conversations with you, via email or Live Chat.</li>
        </ul> 
      </section> 



      <section id="privacy-statement-links-to-other-sites"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Links to Other Sites</h5> 
        <p style={{...style.p}}>
          ProlificXM contains links to other sites that are not owned or controlled by us. We assume that, you are aware and informed of the privacy practices of such other sites. Therefore, ProlificXM will not held responsible for any activity concerning your Privacy associated with those sites. We advise you to be aligned with the privacy statements of each and every websites that collects identifiable information associated with personnel before leaving our site.
        </p> 
      </section> 



      <section id="privacy-statement-tracking-technologies"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Tracking Technologies</h5> 
        <p style={{...style.p}}>
          We may use Tracking Technologies like JWT (JSON Web Tokens) for purposes like authentication. The purpose of using it will be solely bound by our business interest and will not breach any privacy terms stated above.
        </p> 
      </section> 




      <section id="privacy-statement-information-we-share">
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Information we share</h5> 
        <p style={{...style.p}}>
          We do not share any identifiable information associated with any person with any third parties except as indicated below.
        </p> 
        <ul style={style.list}>
        <li>If you are a User of a Multi-branch account such as there any multiple users having access to the data and content you provide, then your account information and data will be shared with the other User(s). In that case, your survey data may be visible to other User(s) in your team with whom you share your surveys or with whom you collaborate. Users who have administration access will be able to view your account information, change your passwords, suspend, transfer or terminate your account or restrict your access. We will advise you to refer to your organization’s internal policies if you have concerns about this.</li>
        <li>
          We also may have to share information in the following cases.
          <ul style={style.list}>
            <li>To meet any applicable law, regulation, legal process or enforceable governmental request.</li>
            <li>To necessitate applicable policies such as investigation of potential violations.</li>
            <li>To identify, prevent potential Fraud, or address security or technical issues.</li>
            <li>To protect any kind of violation of rights, property or safety of our users, to the public or to ProlificXM and/or as required or permitted by law.</li>
            <li>To enable a sale, merger or change in control of all or any part of our company or business or in preparation for any of these events.</li>
          </ul> 
        </li> 
      </ul> 
      </section> 



      <section id="privacy-statement-security"> 
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Security</h5> 
        <p style={{...style.p}}>
          The security of your information and content is very important to us. When you provide us with sensitive information like credit card number or any such information, we encrypt the information using Secure Socket Layer Technology (SSL). We maintain globally accepted industry standards to protect the personal information provided to us. For more details about our Security Policy, you can visit our “Security Policy” page.
        </p> 
      </section>



      <section id="privacy-statement-changes-in-privacy-statement">
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Changes in Privacy Statement</h5> 
        <p style={{...style.p}}>
          We may have to change our Privacy Statement or add new information to keep up with our Service Changes and Products Upgrade. If we need to change our privacy statement for the above reasons or any other considerable reasons, we will publish those changes in this privacy statement and we will notify you here, by email, or by means of a notice on our homepage prior to the change becoming effective. The purpose it that, you are aware of what information we collect, how we use your information, and under what circumstances, we may disclose it to specific authority. We reserve all the rights to modify, update and amend this privacy statement at any time. We will encourage you to review it frequently.
        </p> 
      </section>



      <section id="privacy-statement-contact-us">
        <h5 className="text-center" style={{color: 'rgb(52, 58, 64)'}}>Contact Us</h5> 
        <p style={{...style.p}}>
        If you have any other specific question or concern regarding our Privacy Statement, you can Contact with us through following contact details:
        <br /> <br /> 
        Prolific Analytics Ltd. <br />
        House#15 Road#7 Block#C Niketan, Gulshan, Dhaka <br />
        Dhaka 1212 <br /> 
        Bangladesh
      </p> 
      </section> 


    </div> 
  ) 
} 

export default Privacy
