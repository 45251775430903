import React from 'react';
import './NpsDescriptor.css'

const NpsDescriptor = (props) => {
    const { name, nps, response, color } = props;

    const style = {
        nameColor: {
            color: color,
            width:"90px",
            display:"inline-block",
            padding:"0"
        }
    };


    return (
        <div className="NpsDescriptor">
            <div>
                <span className="name" style={style.nameColor}>{name}</span>
                <span className="value">{`${nps}%`}</span>
                <span className="response">{`${response} responses`}</span>
            </div>
        </div>
    )
}
export default NpsDescriptor;