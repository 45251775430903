import React from 'react';
import './MediaObject.css';

const MediaObject = (props) => {

    return (
        <ul className="MediaObject">
            <li><span className="successBlock indicator"></span>{props.topVariable.name}</li>
            <li><span className="dangerBlock indicator"></span>{props.bottomVariable.name}</li>
        </ul>
    );
}

export default MediaObject;