import React from 'react';
import Line from '../components/charts/nivo/Line';
import GaugeChart from '../components/dashboardComponent/GaugeChart';
import DeatailsAnalysis from '../components/dashboardComponent/DetailsAnalysis';
import Barchart from '../components/charts/nivo/Barchart';
import TrafficLight from '../components/trafficLight/TrafficLight';
import Gauge from '../components/charts/gauge/Gauge';
import InfoCard from '../components/infoCard/InfoCard';
import LayoutOne from '../components/dashboardComponent/LayoutOne';
import LiquidGauge from '../components/charts/liquidgauge/LiquidGauge';
import Speedometer from '../components/charts/reactspeedometer/Speedometer';
// import ProgressBar from '../components/progressbar/ProgressBar';
import InfoBar from '../components/InfoBar/InfoBar';
import Nps from '../components/nps/Nps';
import NpsDescriptor from '../components/npsDescriptor/NpsDescriptor';
import CircualarProgress from '../components/charts/circularchart/CircularProgress';

const LayoutSwitcher = (tag, formatedData, lowLevelId, lowLevelTitle) => {

    let element = '';
    let topSection = '';
    let bottomSection = '';

    switch (tag) {

        // Layout for CJM
        case 'CJM':
            if (lowLevelId === "-1") {
                bottomSection = <GaugeChart variables={formatedData.result} />
            } else {
                bottomSection = <DeatailsAnalysis variables={formatedData.result} barchart={formatedData.barchart} />
            }

            element = <>
                <Line line={formatedData.line} area={true} isParcent={true} />
                {bottomSection}
            </>
            break;

        // Layout for CSAT
        case 'CSAT':
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <TrafficLight title="Customer Satisfaction" value={formatedData.overallAverage} threshold={[3, 4]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center" style={{ height: "160px" }}>
                                <div className="card-body">
                                    <Gauge title="Average Satisfaction" value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center box-shadow">
                                <div className="card-body" style={{ height: "180px" }}>
                                    <CircualarProgress data={formatedData.overallT2B} title="Satisfied" color="#1B998B" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "180px" }}>
                                    <CircualarProgress data={formatedData.overallNeutral} title="Neutral" color="#CDC392" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center" style={{ height: "180px" }}>
                                <div className="card-body">
                                    <CircualarProgress data={formatedData.overallB2B} title="Dissatisfied" color="#F27D7D" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>;

                bottomSection = <>
                    <div className="card">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242", marginBottom: "-25px" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" legend="Response" />
                            {/* <Barchart barchart={testBar} isParcent={false} layout="horizontal" legend="Response" /> */}
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Customer Satisfaction By Source</h6>
                            <Barchart barchart={formatedData.channelT2B} isParcent={false} layout="vertical" legend="Channels" />
                            {/* <Barchart barchart={testBar} isParcent={false} layout="vertical" legend="Channels" /> */}
                        </div>
                    </div>
                </>;

            } else {

                topSection = <>
                    <Line line={formatedData.avgPercentage} area={true} isParcent={true} />
                </>

                bottomSection = <LayoutOne variables={formatedData.result} barchart={formatedData.barchart} lowLevelTitle={lowLevelTitle} type="SATISFY" tag={tag} />

            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;


        // Layout for CES
        case 'CES':
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-3 align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Easy</h6>
                            <LiquidGauge
                                style={{ margin: '0 auto 20px auto' }}
                                radius={60}
                                value={formatedData.overallT2B}
                                startColor="#B9F077"
                                endColor="#25A18E"
                            />
                        </div>
                        <div className="col-md-6 text-center align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>CES</h6>
                            <Speedometer
                                value={formatedData.netPositiveValue}
                            />
                        </div>
                        <div className="col-md-3 align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Difficult</h6>
                            <LiquidGauge
                                style={{ margin: '0 auto 20px auto' }}
                                radius={60}
                                value={formatedData.overallB2B}
                                startColor="#FCCA46"
                                endColor="#EE4266"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <TrafficLight
                                        title="Customer Effort"
                                        value={formatedData.overallAverage}
                                        threshold={[3, 4]}
                                        indicatior={["Customer needs more effort", "Customer needs moderated effort", "Customer need less effort"]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <Gauge title="Average CES" value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242", marginBottom: "-25px" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" />
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Customer Effort By Source</h6>
                            <Barchart barchart={formatedData.channelNetPositiveBarChart} isParcent={false} min={-100} max={100} layout="vertical" />
                        </div>
                    </div>
                </>;

            } else {

                topSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Customer Effort</h6>
                            <Barchart barchart={formatedData.netPositiveBarChart} isParcent={false} min={-100} max={100} layout="vertical" />
                        </div>
                    </div>
                </>

                bottomSection = <LayoutOne variables={formatedData.result} barchart={formatedData.barchart} lowLevelTitle={lowLevelTitle} type="EFFORT" tag={tag} />

            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;

        // Layout of customer NPS
        case 'NPS':
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-4">
                            <hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.promoter} title="Promoters" color="#1B998B" /><hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.passive} title="Passives" color="#FFBF69" /><hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.detractor} title="Detractors" color="#F27D7D" /><hr className="my-0" />
                        </div>
                        <div className="col-md-6 text-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>NPS</h6>
                            <Speedometer
                                value={formatedData.overallNps.promoter - formatedData.overallNps.detractor}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Rating" response={formatedData.overallRating} limit={formatedData.totalResponse * 5} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <Gauge title="Average Rating" value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" legend="Response" />
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Nps By Source</h6>
                            <Barchart barchart={formatedData.channelNps} isParcent={false} min={-100} max={100} layout="vertical" legend="Channels" />
                        </div>
                    </div>
                </>;
            } else {
                topSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>NPS Scrore Composition</h6>
                            <Nps nps={formatedData.lowLevelObjectNps} label={true} />
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <NpsDescriptor
                                        name="Promoters"
                                        nps={formatedData.lowLevelObjectNps.promoter}
                                        response={formatedData.lowLevelObjectCounter[4].value}
                                        color="#1B998B"
                                    />
                                    <NpsDescriptor
                                        name="Passives"
                                        nps={formatedData.lowLevelObjectNps.passive}
                                        response={formatedData.lowLevelObjectCounter[3].value}
                                        color="#FFBF69"
                                    />
                                    <NpsDescriptor
                                        name="Detractors"
                                        nps={formatedData.lowLevelObjectNps.detractor}
                                        response={formatedData.lowLevelObjectCounter[2].value
                                            + formatedData.lowLevelObjectCounter[1].value
                                            + formatedData.lowLevelObjectCounter[0].value}
                                        color="#F27D7D"

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Category Response</h6>
                            <Barchart barchart={formatedData.lowLevelObjectCounter} isParcent={false} min={0} max="auto" layout="vertical" />
                        </div>
                    </div>
                </>

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Channel wise NPS</h6>
                            <Barchart barchart={formatedData.lowlevelChannelNps}
                                keys={['promoter', 'passive', 'detractor']}
                                colors={["#1B998B", "#FFBF69", "#F27D7D"]}
                                isParcent={false}
                                min={0} max={100}
                                layout="vertical"
                                legend="Channel" />
                        </div>
                    </div>
                </>


            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;

        // Layout for employee journey map and interview experience
        case 'EMJM':
        case 'EMIE':
            if (lowLevelId === "-1") {
                topSection = <div className="card mb-4">
                    <div className="card-body">
                        <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Overall</h6>
                        <Barchart barchart={formatedData.barchart} isParcent={true} />
                    </div>
                </div>
                bottomSection = <GaugeChart variables={formatedData.result} />
            } else {
                topSection = <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 mb-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 style={{ fontSize: "14px", marginBottom: "-10px", fontWeight: "700", color: "#424242" }}>Overall Score</h6>
                                <Gauge value={formatedData.overallT2B} max={100} round={true} />
                                <div>
                                    <h6 className="card-title" style={{
                                        color: '#424242',
                                        fontSize: '15px',
                                        fontWeight: '700',
                                        marginTop: '20px'
                                    }}>Total Response</h6>
                                    <p className="card-text text-center" style={{
                                        fontSize: '50px',
                                        fontWeight: '700',
                                        color: 'coral',
                                        lineHeight: '30px',
                                        marginBottom: "46px"
                                    }}>{formatedData.totalResponse}</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-8 col-sm-12 col-12">
                        <Line line={formatedData.line} area={true} isParcent={true} height={250} />
                    </div>
                </div>

                bottomSection = <DeatailsAnalysis variables={formatedData.result} barchart={formatedData.barchart} />
            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;

        // Layout for employee satisfaction
        case 'EMSAT':
        case 'EMFAC':
        case 'EMMF':
        case 'EMEF':
        case 'EMTF':
        case 'EMCC':
        case 'EMCI':
            const EMTitle = {
                EMSAT: 'Employee Satisfaction',
                EMFAC: 'Employee Facilities',
                EMMF: 'Meeting Feedback',
                EMEF: 'Event Feedback',
                EMTF: 'Training Feedback',
                EMCC: 'Company Culture',
                EMCI: 'Company Image',
            }
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <TrafficLight title={EMTitle[tag]} value={formatedData.overallAverage} threshold={[3, 4]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center" style={{ height: "160px" }}>
                                <div className="card-body">
                                    <Gauge title={`Average ${EMTitle[tag]}`} value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center box-shadow">
                                <div className="card-body" style={{ height: "180px" }}>
                                    <CircualarProgress data={formatedData.overallT2B} title="Satisfied" color="#1B998B" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "180px" }}>
                                    <CircualarProgress data={formatedData.overallNeutral} title="Neutral" color="#CDC392" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center" style={{ height: "180px" }}>
                                <div className="card-body">
                                    <CircualarProgress data={formatedData.overallB2B} title="Dissatisfied" color="#F27D7D" />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>;

                bottomSection = <>
                    <div className="card">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242", marginBottom: "-25px" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" legend="Response" />
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>{`${EMTitle[tag]} By Source`}</h6>
                            <Barchart barchart={formatedData.channelT2B} isParcent={false} layout="vertical" legend="Channels" />
                        </div>
                    </div>
                </>;

            } else {

                topSection = <>
                    <Line line={formatedData.avgPercentage} area={true} isParcent={true} curve="linear" />
                </>

                bottomSection = <LayoutOne variables={formatedData.result} barchart={formatedData.barchart} lowLevelTitle={lowLevelTitle} type="DEFAULT" tag={tag} />

            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;

        // Layout for EMES
        case 'EMES':
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-3 align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Easy</h6>
                            <LiquidGauge
                                style={{ margin: '0 auto 20px auto' }}
                                radius={60}
                                value={formatedData.overallT2B}
                                startColor="#B9F077"
                                endColor="#25A18E"
                            />
                        </div>
                        <div className="col-md-6 text-center align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Employee Effort Score</h6>
                            <Speedometer
                                value={formatedData.netPositiveValue}
                            />
                        </div>
                        <div className="col-md-3 align-self-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Difficult</h6>
                            <LiquidGauge
                                style={{ margin: '0 auto 20px auto' }}
                                radius={60}
                                value={formatedData.overallB2B}
                                startColor="#FCCA46"
                                endColor="#EE4266"
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <TrafficLight
                                        title="Employee Effort"
                                        value={formatedData.overallAverage}
                                        threshold={[3, 4]}
                                        indicatior={["Employee needs more effort", "Employee needs moderated effort", "Employee need less effort"]} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <Gauge title="Average Employee Effort" value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242", marginBottom: "-25px" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" />
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Employee Effort By Source</h6>
                            <Barchart barchart={formatedData.channelNetPositiveBarChart} isParcent={false} min={-100} max={100} layout="vertical" />
                        </div>
                    </div>
                </>;

            } else {

                topSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "14px", fontWeight: "700", color: "#424242" }}>Employee Effort</h6>
                            <Barchart barchart={formatedData.netPositiveBarChart} isParcent={false} min={-100} max={100} layout="vertical" />
                        </div>
                    </div>
                </>

                bottomSection = <LayoutOne variables={formatedData.result} barchart={formatedData.barchart} lowLevelTitle={lowLevelTitle} tag={tag} />

            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;

        // Layout of employee NPS
        case 'EMNPS':
            if (lowLevelId == "-1") {
                topSection = <div className="mt-4">
                    <div className="row justify-content-center">
                        <div className="col-md-6 mb-4">
                            <hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.promoter} title="Promoters" color="#1B998B" /><hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.passive} title="Passives" color="#FFBF69" /><hr className="my-0" />
                            <InfoBar value={formatedData.overallNps.detractor} title="Detractors" color="#F27D7D" /><hr className="my-0" />
                        </div>
                        <div className="col-md-6 text-center">
                            <h6 className="font-weight-bold text-center" style={{ color: "#424242", fontSize: "13px" }}>Employee NPS</h6>
                            <Speedometer
                                value={formatedData.overallNps.promoter - formatedData.overallNps.detractor}
                            />
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Response" response={formatedData.totalResponse} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <InfoCard title="Total Rating" response={formatedData.overallRating} limit={formatedData.totalResponse * 5} />
                        </div>
                        <div className="col-md-4 align-self-center mb-4">
                            <div className="card text-center">
                                <div className="card-body" style={{ height: "160px" }}>
                                    <Gauge title="Average Rating" value={formatedData.overallAverage} max={5} height={110} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>;

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Source of responses</h6>
                            <Barchart barchart={formatedData.responseResult} isParcent={false} layout="horizontal" legend="Response" />
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Employee Nps By Source</h6>
                            <Barchart barchart={formatedData.channelNps} isParcent={false} min={-100} max={100} layout="vertical" legend="Channels" />
                        </div>
                    </div>
                </>;
            } else {
                topSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Employee NPS Scrore Composition</h6>
                            <Nps nps={formatedData.lowLevelObjectNps} label={true} />
                            <div className="row justify-content-center">
                                <div className="col-md-4">
                                    <NpsDescriptor
                                        name="Promoters"
                                        nps={formatedData.lowLevelObjectNps.promoter}
                                        response={formatedData.lowLevelObjectCounter[4].value}
                                        color="#1B998B"
                                    />
                                    <NpsDescriptor
                                        name="Passives"
                                        nps={formatedData.lowLevelObjectNps.passive}
                                        response={formatedData.lowLevelObjectCounter[3].value}
                                        color="#FFBF69"
                                    />
                                    <NpsDescriptor
                                        name="Detractor"
                                        nps={formatedData.lowLevelObjectNps.detractor}
                                        response={formatedData.lowLevelObjectCounter[2].value
                                            + formatedData.lowLevelObjectCounter[1].value
                                            + formatedData.lowLevelObjectCounter[0].value}
                                        color="#F27D7D"

                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Category Response</h6>
                            <Barchart barchart={formatedData.lowLevelObjectCounter} isParcent={false} min={0} max="auto" layout="vertical" />
                        </div>
                    </div>
                </>

                bottomSection = <>
                    <div className="card mt-4">
                        <div className="card-body">
                            <h6 style={{ fontSize: "15px", fontWeight: "700", color: "#424242" }}>Channel wise eNPS</h6>
                            <Barchart barchart={formatedData.lowlevelChannelNps}
                                keys={['promoter', 'passive', 'detractor']}
                                colors={["#1B998B", "#FFBF69", "#F27D7D"]}
                                isParcent={false}
                                min={0} max={100}
                                layout="vertical"
                                legend="Channel" />
                        </div>
                    </div>
                </>


            }

            element = <>
                {topSection}
                {bottomSection}
            </>
            break;



        // Default layout
        default:
            if (lowLevelId === "-1") {
                bottomSection = <GaugeChart variables={formatedData.result} />
            } else {
                bottomSection = <DeatailsAnalysis variables={formatedData.result} barchart={formatedData.barchart} />
            }

            element = <>
                <Line line={formatedData.line} area={true} isParcent={true} />
                {bottomSection}
            </>
            break;


    }

    return element;
}

export { LayoutSwitcher };