import React, { Component } from 'react';
import c3 from 'c3';
import 'c3/c3.css';
import './PieChart.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { pngDownload } from '../../downloadJSX/downloadJSX';

// const testText = <div>
//     <div><FontAwesomeIcon icon={faThumbsUp} size="lg" />Thumbs Up</div>
//     <div><FontAwesomeIcon icon={faThumbsDown} size="lg" />Thumbs Down</div>
// </div>;

// const data = [
//     ['thumbsUp', 30],
//     ['thumbsDown', 20],
//     ['data6', 30],
//     ['data7', 25],
//     ['data8', 20],
//     ['data9', 40],
//     ['data10', 40],
//     ['data11', 40]
// ];

class PieChart extends Component {
  constructor(props) {
    super(props);
    this.pattern = [
      '#1B998B',
      '#EF8354',
      '#99B2DD',
      '#4F5D75',
      '#F27D7D',
      '#A5668B',
      '#7EAB7E',
      '#CDC392',
      '#BE95C4',
      '#FFBF69',
      '#99B2DD',
      '#EF8354',
      '#4F5D75',
      '#F27D7D',
      '#A5668B',
      '#7EAB7E',
      '#CDC392',
      '#BE95C4',
      '#FFBF69',
    ];

    // this.icon = props.icons ? <div>
    //     <div><FontAwesomeIcon icon={faThumbsUp} size="lg" />Thumbs Up</div>
    //     <div><FontAwesomeIcon icon={faThumbsDown} size="lg" />Thumbs Down</div>
    // </div> : null;
  }

  renderChart = (id) => {
    const chartData = this.props.chartData ? this.props.chartData : [];
    const type = this.props.type ? this.props.type : 'pie';

    const chart = c3.generate({
      bindto: `#${id}`,
      data: {
        columns: chartData,
        type: type,
      },
      donut: {
        width: 40,
      },
      legend: {
        show: this.props.icons ? false : true,
      },
      size: {
        height: 220,
      },
      color: {
        pattern: this.pattern,
      },
    });

    return chart;
  };
  componentDidMount() {
    this.renderChart(this.props.id);
  }

  componentDidUpdate() {
    this.renderChart(this.props.id);
  }

  render() {
    return (
      <>
        <div className='C3-PieChart' id={this.props.id}></div>
        {this.props.icons != undefined ? (
          <div className='PieChartIcon'>
            <div className='d-flex flex-row justify-content-center'>
              <div className='p-2'>
                <FontAwesomeIcon
                  icon={this.props.icons[0].icon}
                  size='lg'
                  color={this.pattern[0]}
                />
              </div>
              <div style={{ margin: '0 10px 0px 0px', padding: '10px 0px' }}>
                {this.props.icons[0].label}
              </div>
              <div className='p-2'>
                <FontAwesomeIcon
                  icon={this.props.icons[1].icon}
                  size='lg'
                  color={this.pattern[1]}
                  style={{ transform: 'translateY(5px)' }}
                />
              </div>
              <div style={{ margin: '0 10px 0px 0px', padding: '10px 0px' }}>
                {this.props.icons[1].label}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default PieChart;
