import React from 'react'; 
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'; 
import { Provider } from 'react-redux'; 
import store from './store/index'; 
import Industry from './industry'
import CreatePage from './components/create/createPage'; 
import Details from './components/details/details'; 


const Routes = props => { 
  let { match } = props; 
  return ( 
    <BrowserRouter> 
      <Switch> 
        <Route exact path={`${match.path}`} component={Industry} /> 
        <Route path={`${match.path}/create`} component={CreatePage} /> 
        <Route path={`${match.path}/:id`} component={Details} /> 
      </Switch> 
    </BrowserRouter> 
  ) 
} 

const ReduxConnectedRoutes = withRouter(Routes) 

function Index() { 
    return ( 
      <Provider store={store}> 
        <ReduxConnectedRoutes /> 
      </Provider> 
    ) 
} 

export default Index; 

