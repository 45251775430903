import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { primaryDashboardData } from '../../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faThList, faBox, faTabletAlt, faQrcode, faLink, faCode, faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import './index.css';
import DashCard from './components/dashcard/DashCard';
import RunningProject from './components/activeProject/RunningProject';
import CardContainer from './components/cardContainer/CardContainer';
import ChannelPill from './components/channelPill/ChannelPill';
import moment from 'moment';


const Dashboard = props => {

    const [info, setInfo] = useState(
        {
            "totalProject": 0,
            "runningProject": 0,
            "response": 0,
            "responseLeft": 0,
            "latestProject": [
            ],
            "channel": {
                "device": 0,
                "qrcode": 0,
                "openlink": 0
            }
        }
    );

    const [loading, setLoaded] = useState({
        load: false
    });

    useEffect(() => {
        const profileId = localStorage.getItem('jwtProfileId');
        const currentDate = moment().format('DD/MM/YYYY');
        axios.post(primaryDashboardData(), {
            profileId: profileId,
            currentDate: currentDate
        }).then(response => {
            // set loader true
            setLoaded({
                load: true
            })

            // set info
            setInfo(response.data)

        }).catch(error => {
            // handle error
            console.log(error);
        })

    }, []);

    return (
        <>
            {
                loading.load === false ?
                    (
                        <div className="text-center" style={{ transform: 'translate(0, 250px)' }}>
                            <FontAwesomeIcon
                                icon={faCircleNotch}
                                size="6x"
                                style={{ color: '#1B998B' }}
                                spin
                            />
                        </div>
                    ) : (

                        <div className="container-fluid">
                            <section className="pt-4 px-2">
                                <div className="row">
                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <DashCard title="Total Project" value={info.totalProject} description="Number of project created" icon={faThList} />
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <DashCard title="Running Project" value={info.runningProject} description="Number of project running now" icon={faThList} />
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <DashCard title="Response Used" value={info.response} description="Response used within current month" icon={faCartArrowDown} />
                                    </div>
                                    <div className="col-lg-3 col-md-6 mb-4">
                                        <DashCard title="Response Left" value={info.responseLeft} description="Response left within current month" icon={faBox} />
                                    </div>
                                </div>
                            </section>
                            <section className="px-2">
                                <h6 style={{ fontSize: '16px', fontWeight: '700', color: '#083836' }}>
                                    Latest projects
                                </h6>
                                {
                                    info.latestProject.map((project) => {
                                        return <RunningProject key={project.id} project={project} />
                                    })
                                }
                            </section>
                            <section className="mt-4 px-2">
                                <h6 style={{ fontSize: '16px', fontWeight: '700', color: '#083836', marginBottom: '10px' }}>
                                    Response Channels
                                </h6>
                                <CardContainer>
                                    <div className="d-flex flex-wrap">
                                        <ChannelPill title="Device" value={info.channel.device} icon={faTabletAlt} />
                                        <ChannelPill title="Qr Code" value={info.channel.qrcode} icon={faQrcode} />
                                        <ChannelPill title="Open link" value={info.channel.openlink} icon={faLink} />
                                    </div>
                                </CardContainer>
                            </section>
                        </div>

                    )
            }
        </>

    )
}

export default Dashboard;