import React from 'react';
import { connect, useSelector } from 'react-redux';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// import whyDidYouRender from '@welldone-software/why-did-you-render'

import { Container, Arrow } from './styles';
import ContactCard from '../AdditionalCards/ContactCard';

import { additionalCardSelector } from '../../redux/selectors';

// whyDidYouRender(React, {
//   onlyLogs: true,
//   titleColor: "green",
//   diffNameColor: "aqua"
// })

const AdditionalContentBar = (props) => {
  let { sidebarState } = useSelector((state) => state.sidebarData);

  const { cards } = props;
  const cardList = cards.map((item, index) => {
    switch (item.type) {
      case 'contactCard': {
        // return (
        //   <Draggable key={item.id} draggableId={item.id} index={index}>
        //     {(provided, snapshot) => (
        //       <ContactCard
        //         provided={provided}
        //         snapshot={snapshot}
        //         inSidebar={true}
        //       />
        //     )}
        //   </Draggable>
        // )
        return (
          <ContactCard
            key={item.id}
            item={item}
            index={index}
            inSidebar={true}
          />
        );
      }

      default:
        return <></>;
    }
  });

  return cardList;
  // return (
  //   <Droppable isDropDisabled droppableId="additionalCardsList" type="projects">
  //     {provided => (
  //       <Container
  //         sidebarState={sidebarState}
  //         ref={provided.innerRef}
  //         {...provided.droppableProps}
  //       >
  //         <div style={{
  //           padding: '5px 10px',
  //           margin: '0px'
  //         }}>
  //           <h5 style={{
  //             fontSize: '14px',
  //             color: '#424242',
  //             margin: '0',
  //             fontWeight: '700'
  //           }}>
  //             Element Card
  //           </h5>
  //         </div>
  //         <h6 style={{
  //           fontSize: '10px',
  //           color: '#757575',
  //           margin: '0px 10px',
  //           fontWeight: '500'
  //         }}>
  //           Drag & drop below elements in your form
  //           </h6>

  //         {cardList}
  //         {provided.placeholder}
  //         <div
  //           style={{
  //             position: 'absolute',
  //             right: '3px',
  //             bottom: '8px',
  //             color: 'rgb(52, 58, 64)'
  //           }}
  //         >
  //           <i className="fas fa-angle-double-right"></i>
  //         </div>
  //       </Container>
  //     )}
  //   </Droppable>
  // )
};

// AdditionalContentBar.whyDidYouRender = true

const mapStateToProps = (state) => ({
  cards: additionalCardSelector(state),
  // sidebarState:
});
export default connect(mapStateToProps)(AdditionalContentBar);
