import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Select from 'react-select';
import { css } from 'glamor';

import {
	Card,
	CardBody,
	CardHeader,
	Col,
	Button,
	Input,
	UncontrolledTooltip,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Form,
	FormGroup,
	Label,
} from 'reactstrap';
import showToast from '../../../../components/Toast/toast';
import Breadcrumb from '../../../../components/Breadcrumb/breadcrumb';

import CKEditor from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

// import Image from '@ckeditor/ckeditor5-image/src/image';
// import ImageToolbar from '@ckeditor/ckeditor5-image/src/imagetoolbar';
// import ImageCaption from '@ckeditor/ckeditor5-image/src/imagecaption';
// import ImageStyle from '@ckeditor/ckeditor5-image/src/imagestyle';
// import ImageResize from '@ckeditor/ckeditor5-image/src/imageresize';

import {
	getAll_,
	getSingle_,
	backButtonFunction,
	update_,
	delete_,
} from '../../store/actions/actionCreator';
import baseUrl, { deleteBlogPost } from '../../../../url';

import '../buttonCss.css';
import './style.css';

// const editorConfiguration = {
//   plugins: [Essentials, Bold, Italic, Paragraph, Underline, Alignment, Font],
//   toolbar: ['bold', 'italic', 'underline', 'alignment:justify', 'alignment:center', 'alignment:left',
//   'alignment:right', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'undo', 'redo'],
// };

const editorConfiguration = {
	plugins: [Essentials, Bold, Italic, Paragraph, Underline, Alignment, Font],
	toolbar: [
		'bold',
		'italic',
		'underline',
		'alignment:justify',
		'alignment:center',
		'alignment:left',
		'alignment:right',
		'fontFamily',
		'fontSize',
		'fontColor',
		'fontBackgroundColor',
		'undo',
		'redo',
	],
};

const MultiSelect = (props) => {
	let [tags, setTags] = useState([]);

	useEffect(() => {
		axios
			.get(`${baseUrl}/api/blog/tags`)
			.then((response) => {
				let allData = response.data.tagList;

				// Sorting
				allData.sort((a, b) => {
					const nameA = a.name.toUpperCase();
					const nameB = b.name.toUpperCase();

					let comparison = 0;
					if (nameA > nameB) {
						comparison = 1;
					} else if (nameA < nameB) {
						comparison = -1;
					}
					return comparison;
				});
				const tagData = allData.map((obj) => {
					return {
						value: obj._id,
						label: obj.name,
					};
				});

				setTags(tagData);
			})
			.catch((error) => console.log(error));
	}, [props.count]);

	return (
		<div>
			<Select
				isMulti
				options={tags}
				onChange={(value) => {
					props.onChange('tags', value);
				}}
				onBlur={() => {
					props.onBlur('tags', true);
				}}
				value={props.value}
			></Select>
		</div>
	);
};

function Details(props) {
	let { id } = props.match.params;
	let { history } = props;

	const formData = new FormData();

	let dispatch = useDispatch();
	let { allData, dataDetails: state } = useSelector((state) => state.blog);
	const { className } = props;
	let [isEditable, setIsEditable] = useState(false);
	let [showModal, setShowModal] = useState(false);
	const [createModel, setCreateModel] = useState(false);
	const [uniqueTags, setUniqueTags] = useState([]);
	const [imageLink, setImageLink] = useState('');
	const [count, setCount] = useState(0);

	// setImageLink(state.featureImageUrl)

	let postTitles = [];
	try {
		for (let i = 0; i < allData.postList.length; i++) {
			if (state.title !== allData.postList[i].title) {
				postTitles.push(allData.postList[i].title.toLowerCase());
			}
		}
	} catch (error) {}

	const CreateToast = () => showToast('Created Successfully');
	const UpdateToast = () => showToast('Updated Successfully');
	const DeleteToast = () => showToast('Deleted Successfully');
	const ErrorToast = () => showToast('Server Error');
	const DependencyToast = () =>
		showToast('Cannot Delete. There are dependency');

	useEffect(() => {
		dispatch(getAll_(ErrorToast));
		dispatch(getSingle_(id, ErrorToast));

		// tags api
		axios
			.get(`${baseUrl}/api/blog/tags`)
			.then((response) => {
				let allTags = response.data.tagList.map((obj) => obj.name);
				setUniqueTags(allTags);
			})
			.catch((error) => console.log(error));
	}, []);

	const NowDelete = () => {
		try {
			axios
				.delete(deleteBlogPost(id))
				.then((res) => {
					if (res.data.isDeletable) {
						DeleteToast();
						dispatch(delete_(state));
						props.history.push('/app/blog/');
					} else {
						DependencyToast();
						props.history.push('/app/blog/');
					}
				})
				.catch((err) => {
					ErrorToast();
				});
		} catch (error) {}
	};

	const imageHandler = (event) => {
		event.preventDefault();
		formData.append('file', event.target.files[0]);
	};

	const uploadImageHandler = (event) => {
		event.preventDefault();
		axios
			.patch(`${baseUrl}/api/blog/posts/upload/${id}`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then((res) => {
				setImageLink(res.data.featureImageUrl);
			})
			.catch((err) => ErrorToast());
	};

	let iconCss = css({ color: '#1B998B', ':hover': { color: '#0B6157' } });
	let tooltipCss = css({ background: '#1B998B' });
	return (
		<>
			{/* create tag */}
			<Modal isOpen={createModel} className={props.className}>
				<ModalHeader>Create a tag</ModalHeader>
				<ModalBody>
					<Formik
						initialValues={{ tagName: '' }}
						validationSchema={Yup.object().shape({
							tagName: Yup.string()
								.required('Required')
								.trim()
								.lowercase()
								.notOneOf(uniqueTags, 'Already Exist'),
						})}
						onSubmit={(values, { resetForm }) => {
							axios
								.post(`${baseUrl}/api/blog/tags`, { name: values.tagName })
								.then((res) => {
									CreateToast();
									resetForm();
									setCount(count + 1);
								})
								.catch((err) => console.log(err));
						}}
					>
						{(props) => (
							<Form onSubmit={props.handleSubmit}>
								<FormGroup row>
									<Label htmlFor='tagName' sm={3}>
										Create Tag: <span style={{ color: 'red' }}>{` *`}</span>
									</Label>
									<Col sm={7}>
										<Input
											type='text'
											name='tagName'
											id='tagName'
											style={{
												border:
													props.errors.tagName && props.touched.tagName
														? '1px solid red'
														: null,
											}}
											value={props.values.tagName}
											onChange={props.handleChange}
											onBlur={props.handleBlur}
											placeholder='Enter a tagName'
										/>
										{props.errors.tagName && props.touched.tagName && (
											<p
												style={{
													color: 'red',
													fontSize: '12px',
													marginLeft: '5px',
													textAlign: 'left',
													fontWeight: 'bold',
												}}
											>
												{props.errors.tagName}
											</p>
										)}
									</Col>
								</FormGroup>

								<FormGroup>
									<div style={{ float: 'right' }}>
										<Button
											type='submit'
											className='btnHover mr-2'
											size='sm'
											style={{
												background: '#1B998B',
												borderColor: '#1B998B',
												color: '#fff',
											}}
										>
											Create
										</Button>

										<Button
											className='btnHover'
											style={{
												background: '#1B998B',
												borderColor: '#1B998B',
												color: '#fff',
											}}
											size='sm'
											onClick={() => {
												setCreateModel(false);
											}}
										>
											Close
										</Button>
									</div>
								</FormGroup>
							</Form>
						)}
					</Formik>
				</ModalBody>
			</Modal>

			{/* delete modal */}
			<Modal isOpen={showModal} className={className}>
				<ModalHeader>Delete Blog</ModalHeader>
				<ModalBody> Are you sure, you want to delete? </ModalBody>
				<ModalFooter>
					<Button
						className='btnHover'
						style={{
							background: '#1B998B',
							borderColor: '#1B998B',
							color: '#fff',
						}}
						size='sm'
						onClick={NowDelete}
					>
						Delete
					</Button>

					<Button
						className='btnHover'
						style={{
							background: '#1B998B',
							borderColor: '#1B998B',
							color: '#fff',
						}}
						size='sm'
						onClick={() => setShowModal(false)}
					>
						Cancel
					</Button>
				</ModalFooter>
			</Modal>

			<div className='container'>
				<Breadcrumb
					path='Blog'
					slug={state.title}
					link='/app/blog'
					backButtonFunction={backButtonFunction}
				/>
				<Button
					size='sm'
					className='btnHover'
					style={{
						margin: '10px 0px 10px 0px',
						background: '#1B998B',
						borderColor: '#1B998B',
						color: '#fff',
					}}
					onClick={() => {
						dispatch(backButtonFunction());
						props.history.push('/app/blog/');
					}}
				>
					Back
				</Button>
				{!isEditable && (
					<Button
						size='sm'
						className='ml-2 btnHover'
						style={{
							background: '#1B998B',
							borderColor: '#1B998B',
							color: '#fff',
						}}
						onClick={() => setIsEditable(true)}
					>
						Edit
					</Button>
				)}
				<Button
					size='sm'
					className='ml-2 btnHover'
					style={{
						background: '#1B998B',
						borderColor: '#1B998B',
						color: '#fff',
					}}
					onClick={() => setShowModal(true)}
				>
					Delete
				</Button>

				<Card>
					<CardBody>
						{state.title && (
							<Formik
								initialValues={{
									title: state.title,
									active: state.active,
									body: state.body,
									excerpt: state.excerpt,
									tags: state.tags.map((obj) => ({
										value: obj._id,
										label: obj.name,
									})),
								}}
								validationSchema={Yup.object().shape({
									title: Yup.string()
										.required('Required')
										.trim()
										.lowercase()
										.notOneOf(postTitles, 'Already Exist'),
									body: Yup.string().required('Required').trim(),
									excerpt: Yup.string().required('Required').trim(),
								})}
								onSubmit={(values, { resetForm }) => {
									const alltags = values.tags.map((obj) => {
										return { id: obj.value, name: obj.label };
									});
									let updatedData = {
										title: values.title,
										body: values.body,
										tags: alltags,
										active: values.active,
										excerpt: values.excerpt,
									};
									console.log({ updatedData });

									dispatch(update_(id, updatedData, UpdateToast, ErrorToast));
									resetForm();
									history.push('/app/blog/');
								}}
							>
								{(props) => (
									<Form onSubmit={props.handleSubmit}>
										<FormGroup row style={{ marginBottom: '0.5rem' }}>
											<Label htmlFor='title' sm={2}>
												Title: <span style={{ color: 'red' }}>{` *`}</span>
											</Label>
											<Col
												sm={5}
												style={{ marginTop: isEditable ? '0' : '5px' }}
											>
												{isEditable ? (
													<>
														<Input
															type='text'
															name='title'
															id='title'
															style={{
																border:
																	props.errors.title && props.touched.title
																		? '1px solid red'
																		: null,
															}}
															value={props.values.title}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															placeholder='Enter a title'
														/>
														{props.errors.title && props.touched.title && (
															<p
																style={{
																	color: 'red',
																	marginBottom: '0.1rem',
																	fontSize: '12px',
																	marginLeft: '5px',
																	textAlign: 'left',
																	fontWeight: 'bold',
																}}
															>
																{props.errors.title}
															</p>
														)}
													</>
												) : (
													<span className='pt-1'>{state.title}</span>
												)}
											</Col>
										</FormGroup>

										{isEditable && (
											<FormGroup row style={{ marginBottom: '0.5rem' }}>
												<Label htmlFor='image' sm={2}>
													{' '}
													Upload Image:{' '}
												</Label>
												<Col
													style={{ marginTop: isEditable ? '0' : '5px' }}
													id='ratio'
												>
													<Input
														type='file'
														id='image'
														style={{ cursor: 'pointer' }}
														// value={formData}
														onChange={imageHandler}
													/>

													<Button
														type='submit'
														size='sm'
														onClick={uploadImageHandler}
														className='btnHover'
														style={{
															background: '#1B998B',
															borderColor: '#1B998B',
														}}
													>
														Upload
													</Button>
												</Col>
												<UncontrolledTooltip placement='top' target='ratio'>
													Expected img ratio 1100px x 400px
												</UncontrolledTooltip>
											</FormGroup>
										)}

										{(imageLink || state.featureImageUrl) && (
											<FormGroup row style={{ marginBottom: '0.5rem' }}>
												<Label sm={2}> Image URL </Label>
												<Col style={{ marginTop: '5px' }}>
													<span>
														{imageLink ? imageLink : state.featureImageUrl}
													</span>
												</Col>
											</FormGroup>
										)}

										<FormGroup row style={{ marginBottom: '0.5rem' }}>
											<Label htmlFor='excerpt' sm={2}>
												Excerpt: <span style={{ color: 'red' }}>{` *`}</span>
											</Label>
											<Col style={{ marginTop: isEditable ? '0' : '5px' }}>
												{isEditable ? (
													<>
														<Input
															type='textarea'
															name='excerpt'
															id='excerpt'
															style={{
																border:
																	props.errors.excerpt && props.touched.excerpt
																		? '1px solid red'
																		: null,
																height: '100px',
															}}
															value={props.values.excerpt}
															onChange={props.handleChange}
															onBlur={props.handleBlur}
															placeholder='Enter some text'
														/>
														{props.errors.excerpt && props.touched.excerpt && (
															<p
																style={{
																	color: 'red',
																	marginBottom: '0.1rem',
																	fontSize: '12px',
																	marginLeft: '5px',
																	textAlign: 'left',
																	fontWeight: 'bold',
																}}
															>
																{props.errors.excerpt}
															</p>
														)}
													</>
												) : (
													<span>{state.excerpt}</span>
												)}
											</Col>
										</FormGroup>

										<FormGroup row style={{ marginBottom: '0.5rem' }}>
											<Label htmlFor='body' sm={2}>
												Body: <span style={{ color: 'red' }}>{` *`}</span>
											</Label>
											<Col style={{ marginTop: isEditable ? '0' : '5px' }}>
												{isEditable ? (
													<>
														<div
															style={{
																border:
																	props.errors.body && props.touched.body
																		? '1px solid red'
																		: null,
																borderRadius: '4px',
															}}
														>
															<Editor
																name='body'
																value={props.values.body}
																onChange={props.setFieldValue}
																onBlur={props.setFieldTouched}
																touched={props.touched.body}
															/>
														</div>
														{props.errors.body && props.touched.body && (
															<p
																style={{
																	color: 'red',
																	marginBottom: '0.1rem',
																	fontSize: '12px',
																	marginLeft: '5px',
																	textAlign: 'left',
																	fontWeight: 'bold',
																}}
															>
																{props.errors.body}
															</p>
														)}
													</>
												) : (
													<div
														dangerouslySetInnerHTML={{ __html: state.body }}
													></div>
												)}
											</Col>
										</FormGroup>

										<FormGroup row style={{ marginBottom: '0.5rem' }}>
											<Label htmlFor='tags' sm={2}>
												{' '}
												Tag:{' '}
											</Label>
											<Col
												sm={5}
												style={{ marginTop: isEditable ? '0' : '5px' }}
											>
												{isEditable ? (
													<>
														<InputGroup
															style={{ display: 'block', position: 'relative' }}
														>
															<InputGroupAddon addonType='append'>
																<InputGroupText
																	id='addNew'
																	style={{
																		position: 'absolute',
																		right: '-39px',
																		height: '38px',
																		borderRadius: '0 4px 4px 0',
																	}}
																	onClick={() => setCreateModel(!createModel)}
																>
																	<i className='fas fa-plus' {...iconCss}></i>
																</InputGroupText>
																<UncontrolledTooltip
																	placement='top'
																	target='addNew'
																>
																	Add new
																</UncontrolledTooltip>
															</InputGroupAddon>
															<MultiSelect
																value={props.values.tags}
																count={count}
																onChange={props.setFieldValue}
																onBlur={props.setFieldTouched}
																error={props.errors.tags}
																touched={props.touched.tags}
															/>
														</InputGroup>
													</>
												) : (
													state.tags.map((obj, i) => {
														return (
															<span
																key={`key: ${i}`}
																style={{
																	display: 'inline-block',
																	margin: '5px',
																	background: '#E0E0E0',
																	borderRadius: '2px',
																	padding: '3px 6px',
																	marginRight: '4px',
																}}
															>
																{obj.name}
															</span>
														);
													})
												)}
											</Col>
										</FormGroup>

										<FormGroup row style={{ marginBottom: '0.5rem' }}>
											<Label htmlFor='active' sm={2}>
												Active:
											</Label>
											<Col style={{ marginTop: isEditable ? '0' : '2px' }}>
												{isEditable ? (
													<Input
														type='checkbox'
														id='active'
														name='active'
														className='form-check-input mt-2'
														checked={props.values.active}
														onChange={props.handleChange}
														onBlur={props.handleBlur}
													/>
												) : (
													<Input
														type='checkbox'
														id='active'
														name='active'
														className='form-check-input mt-2'
														readOnly
														checked={props.values.active}
													/>
												)}
											</Col>
										</FormGroup>

										{isEditable && (
											<Button
												className='btnHover'
												type='submit'
												size='sm'
												style={{
													background: '#1B998B',
													borderColor: '#1B998B',
													color: '#fff',
												}}
											>
												Save
											</Button>
										)}
									</Form>
								)}
							</Formik>
						)}
					</CardBody>
				</Card>
			</div>
		</>
	);
}

// text editor
class Editor extends React.Component {
	handleChange = (value) => this.props.onChange('body', value);
	handleBlur = () => this.props.onBlur('body', true);

	render() {
		return (
			<CKEditor
				editor={ClassicEditor}
				data={this.props.value}
				config={editorConfiguration}
				onChange={(event, editor) => {
					const data = editor.getData();
					this.handleChange(data);
				}}
				onBlur={this.handleBlur}
			/>
		);
	}
}

export default withRouter(Details);
