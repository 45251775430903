import React, { Component } from 'react';
import { Provider, connect } from 'react-redux'
import { Route, withRouter } from 'react-router-dom';

import { initialize, fetchQuestion } from './redux/actions/modifyQuestion'
import FormApp from './components/FormApp';
import generateLink from './components/GenerateLink';
import store from './redux/store'
import 'primereact/resources/themes/nova-dark/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

class App extends Component {

  render() {
    return (
      <Provider store={store}>
        <FormApp
          projectId={this.props.projectId}
          message={this.props.message}
          mode={this.props.mode}
          disStatus={this.props.disStatus}
          surveyQuestionSave={this.props.surveyQuestionSave}
          isReadOnlyProps={this.props.isReadOnly}
        />
      </Provider>
    )
  }

}

export default App;
