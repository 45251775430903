import styled from 'styled-components'

export const Question = styled.div`
  box-sizing: border-box;
  position: relative;
  margin: 0 10px;
  border-radius:5px
  padding: 20px;
  @media (max-width: 767px) {
    padding: 0;
  }  
`;

export const NavigationButtonContainer = styled.div`
  max-width:800px;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export const Button = styled.button`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  border: none;
  border-radius: 2px;
  outline: none;
  font-weight: 700;
  // box-shadow: 0 0 2px 1px grey;
  background-color: rgb(27, 153, 139);
  color: white;
  padding: 2px 10px;
  :hover{
    box-shadow: 1px 1px 5px 1px grey;
  }
  :disabled{
    background-color: grey;
  }
`