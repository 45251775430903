import React, { useEffect, useState } from 'react'
import axios from 'axios'

import Form from '../Form'
import Loader from '../Loader';

import { fetchQrCodeLink } from '../../url'

const FormQrCode = props => {

    const {
        match: {
            params: {
                hashID: hashedId
            }
        }
    } = props

    const [subChannelId, setSubChannelId] = useState('')
    const [disId, setDisId] = useState('')
    const [createdByCompanyID, setCreatedByCompanyID] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(fetchQrCodeLink(hashedId), { timeout: 5000 })
            .then(
                res => {

                    setSubChannelId(res.data._id)
                    setDisId(res.data.distributionId)
                    setCreatedByCompanyID(res.data.createdByCompanyID)
                    setLoading(false)
                },
                err => {
                    // console.log(err) 
                    setLoading(false)
                }
            )
    }, [hashedId])
    // return null;
    return loading ? <Loader /> : <Form channelType="qrCode" subChannelId={subChannelId} disId={disId} userType="openlink" createdByCompanyID={createdByCompanyID} />
}

export default FormQrCode