import React from 'react'
import './style.css'
import { Link, Switch, Route, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import CardContainer from '../cardContainer/CardContainer';

const RunningProject = (props) => {
    return (
        <CardContainer>
            <div className="RunningProject">
                <div className="row justify-content-center pt-2">
                    <div className="col-8 col-lg-2">
                        <h6 className="running-project-title">{props.project.name}</h6>
                        <p className="running-project-data">{props.project.lastModified}</p>
                    </div>
                    <div className="col-4 col-lg-2">
                        <h6 className="running-project-title">Creation Date</h6>
                        <p className="running-project-data">{props.project.createDate}</p>
                    </div>
                    <div className="col-3 col-lg-2">
                        <h6 className="running-project-title">{ props.project.status ? 'Active' : 'Inactive' }</h6>
                        <p className="running-project-data">Status</p>
                    </div>
                    <div className="col-3 col-lg-2">
                        <h6 className="running-project-title">{props.project.question}</h6>
                        <p className="running-project-data">Questions</p>
                    </div>
                    <div className="col-3 col-lg-2">
                        <h6 className="running-project-title">{props.project.response}</h6>
                        <p className="running-project-data">Response</p>
                    </div>
                    <div className="col-3 col-lg-2">
                        <h6 className="running-project-title">Action</h6>
                        <p className="running-project-data">
                            <Link to={`app/dataanalysis/${props.project.id}`} >
                                <FontAwesomeIcon icon={faChartPie} size="lg" />
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        </CardContainer>

    );
}

export default RunningProject;