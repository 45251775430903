//Dependencies
import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

//Custom Components and resources
import MainNav from '../MainNav'
import QuestionCardList from '../../containers/QuestionCardList';
import CreateProject from '../CreateProject'
import SaveButton from '../SaveButton'
import PreviewButton from '../PreviewButton'
import Analysis from '../Visualization';
import LinkButton from '../LinkButton'
import { Button } from 'reactstrap'
import AddButton from '../AddButton';
import { connect } from 'react-redux'
import { fetchQuestion, fetchObjectives, updateQuestion, backButtonFunction, deleteQuestionBank, resetStore } from '../../redux/actions/modifyQuestion'
import Toast from '../Toast'
import Loader from '../../../Loader'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { TextAlignCenter } from 'grommet-icons';
// import { ToastContainer, toast } from 'react-toastify';
import './style.css';
import showToast from '../../../../components/Toast/toast';

const MainBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: 0;
  margin: 0;
`

const FormButton = styled.button`
  font-size: 12px;
  font-weight: 500;
  border: none;
  color: #FFF;
  height: 55px;
  width: 56px;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  @media(max-width: 767.92px) {
    height:41px;
    width:42px
    font-size:10px;
  }
`;

const UpdateButton = styled(FormButton)`
  background: rgb(27, 153, 139);
  position: fixed;
  top: 85px;
  right: 100px;
  z-index: 2;
  @media(max-width: 767.92px) {
    top: 60px;
    right: 60px;
  }
`;

const DeleteButton = styled(FormButton)`
  background: #F27D7D;
  position: fixed;
  top: 85px
  right: 30px;
  z-index: 2;
  @media(max-width: 767.92px) {
    top: 60px;
    right: 10px;
  }
`;

class FormApp extends React.Component {

  componentDidMount() {
    this.props.fetchObjectives()
    if (this.props.projectId !== 'create') {
      this.props.fetchQuestion(this.props.projectId)
    }
    else{
      this.props.resetStore();
    }
  }

  updateToast = () => showToast("Update successfully");
  saveToast = () => showToast("Save successfully");
  deleteSuccess = () => showToast("Delete successfully");
  deleteFail = () => showToast("This project is not deletable");

  updateProject() {
    this.props.updateQuestion(this.props.question, this.props.projectId);
    if (this.props.projectName && this.props.featureId.id) {
      this.updateToast();
    }
  }

  deleteProject() {
    this.props.deleteQuestion(this.props.projectId);
    setTimeout(() => {
      if (this.props.error.isDeletable) {
        this.deleteSuccess();
        this.props.history.push('/app/projects')
      } else {
        this.deleteFail();
      }
    }, 1000);
  }

  render() {
    return (
      <MainBody id="testId">
        {this.props.loading ?
          <Loader />
          :
          <>
            {/* <Toast /> */}

            {this.props.projectId === 'create' && <SaveButton saveToast={this.saveToast} className="btnHover" css={`background: '#1B998B`}>Save</SaveButton>}
            {this.props.projectId !== 'create' && <UpdateButton className="btnHover text-center" size="sm" onClick={() => this.updateProject()}>Update</UpdateButton>}
            {this.props.projectId !== 'create' && <DeleteButton className="btnDeleteHover text-center" size="sm" onClick={() => this.deleteProject()}>Delete</DeleteButton>}

            <Body>
              <div className="project-question-container">
                <CreateProject />
              </div>
              <QuestionCardList />
              <AddButton />
            </Body>
          </>
        }
      </MainBody>
    )
  }
}

const mapDispatchToProps = {
  fetchQuestion: fetchQuestion,
  fetchObjectives: fetchObjectives,
  updateQuestion: updateQuestion,
  deleteQuestion: deleteQuestionBank,
  resetStore: resetStore
}

const mapStateToProps = (state, ownProps) => {   // state -> retrieve value from store, ownprops-> values form this component
  const { objective, projectId } = ownProps
  const { loading, question, projectName, featureId, error } = state
  return {
    objective,
    projectId,
    loading,
    question,
    projectName,
    featureId,
    error
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormApp))