import styled from 'styled-components'

export const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 10px;
  border-radius: 4px;
`

export const Title = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 95%;
  text-align: center;
  padding: 2px 10px;
  margin: 0 10px;
  color: white;
  font-size: 12px
  font-weight: 500;
`

export const Header = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 0;
  background-color: #1B998B;
  border-radius: 2px;
`

export const RemoveButton = styled.input`
  display: block;
  position: absolute;
  padding: 2px 4px;
  margin: 0 10px;
  top: 50%;
  transform: translate(0, -50%);
  right: -17px;
  outline: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: rgb(52, 58, 64);
  color: #fff;
  &:hover { background: #ff1744 }
`