import styled from 'styled-components'

export const Body = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  box-shadow: ${props=>props.view? "1px 1px 5px 1px grey":"none"};
  // border: 1px solid grey;
  :hover{
    box-shadow: 1px 1px 5px 1px grey;
  }
`

export const Question = styled.h2`
  position: relative;
  background: green;
  color: white;
  text-align: left;
  padding: 10px 40px;
  margin: 0;
  cursor: pointer;
`

export const GraphBody = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 5% 20px 5%;
`