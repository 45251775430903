import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import randomColor from 'randomcolor'
import PropTypes from 'prop-types'

import {
  BarChart,
  Bar,
  Brush,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import { makeQuestionDataSelector, getSourceSelector } from '../../redux/selectors'
// import {deviceDetails} from '../../redux/selectors/old'

const ResponseCountByDay = props => {

  const { dataByDay, sourceList } = props
  // const {data, device, deviceInfo} = props
  // const formattedData = []
  // const deviceName = id=>deviceInfo.find(item=>item.id===id).name

  // Object.keys(data).forEach((element)=>{
  //   const bar = {
  //     day: element
  //   }

  //   Object.keys(data[element]).forEach(device=>{
  //     bar[deviceName(device)] = data[element][device]
  //   })

  //   formattedData.push(bar)
  // })

  const color = randomColor({ count: sourceList.length })
  const bar = sourceList.map((element, index) => (
    <Bar
      key={index}
      stackId="a"
      dataKey={element.name}
      fill={color[index]}
      legendType="square"
    />)
  )

  return (
    <>
      <h3>Response Count Per Day Basis:</h3>
      <ResponsiveContainer height={300} width="100%">
        <BarChart data={dataByDay} margin={{ top: 15, right: 5, bottom: 10, left: 0 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis allowDecimals={false} />
          <Tooltip />
          <Legend verticalAlign="bottom" wrapperStyle={{ lineHeight: '35px' }} />
          <ReferenceLine y={0} stroke="#555" />
          <Brush dataKey="date" height={30} stroke="#8884d8" />
          {bar}
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

// ResponseCountByDay.propTypes = {
//   data: PropTypes.object.isRequired,
//   device: PropTypes.arrayOf(PropTypes.string).isRequired,
//   deviceInfo: PropTypes.arrayOf(PropTypes.object).isRequired
// }

// const mapStateToProps = (state, ownProps)=>({
//   deviceInfo: deviceDetails(state),
//   device: ownProps.device,
//   deviceInfo: ownProps.deviceInfo
// })

const makeMapStateToProps = () => {
  const questionDataSelector = makeQuestionDataSelector()
  const mapStateToProps = (state, ownProps) => ({
    title: ownProps.title,
    dataByDay: questionDataSelector(state, ownProps),
    sourceList: getSourceSelector(state)
  })
  return mapStateToProps
}

export default withRouter(connect(makeMapStateToProps)(ResponseCountByDay))