import styled from 'styled-components'
import { Link, } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'

export const MainBody = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: #310D31;
`
export const Card = styled.div`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  box-sizing: border-box;
  display: ${props => props.view ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  width: 700px;
  padding: 0px 30px 0 30px;
  background-color: #310D31;
  font-size: 25px;
  
  @media only screen and (max-width: 1440px){
    width: 500px;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
  }
  
  @media only screen and (max-width: 768px){
    width: 400px;
    padding: 0px 25px 0 25px;
    font-size: 15px;
  }
  
  @media only screen and (max-width: 425px){
    width: 300px;
    padding: 0px 10px 0 10px;
    font-size: 10px;
  }
  `
export const CardSignUp = styled.div`
  font-family: 'Roboto', sans-serif;
  position: absolute;
  box-sizing: border-box;
  display: ${props => props.view ? 'flex' : 'none'};
  flex-direction: column;
  align-items: center;
  width: 700px;
  padding: 0px 30px 0 30px;
  background-color: #310D31;
  font-size: 25px;
  
  @media only screen and (max-width: 1440px){
    width: 500px;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
    top: 60px;
  }

  @media only screen and (max-width: 1280px){ 
    top: 110px
  }
  
  @media only screen and (max-width: 768px){
    width: 400px;
    padding: 0px 25px 0 25px;
    font-size: 15px;
  }
  
  @media only screen and (max-width: 425px){
    width: 300px;
    padding: 0px 10px 0 10px;
    font-size: 10px;
  }
  `

export const UploadButtonWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: stretch;
  position: absolute;
`
export const UploadButton = styled.div`
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
`

export const UploadInput = styled.input`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items:center;
  left:10px;
`

export const Input = styled.input.attrs(props => ({
  ...props
}))`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 2px 30px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 5px 2px #1e051e;
  :focus{
    box-shadow: 1px 1px 10px 8px #1c011c;
  }
  ::placeholder {
    color: #310D31;
    opacity: 1;
  }
`

export const InputSignUp = styled.input`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 1px solid #a7a4a4;
  border-radius: 2px;
  padding: 2px 8px;
`

export const Label = styled.div`
  height: 30px;
  box-sizing: border-box;
  margin-top: 25px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: black;
  font-size: 15px;
`

export const LabelFormik = styled.div`
  height: 30px;
  box-sizing: border-box;
  margin-top: 25px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: white;
  font-size: 15px;
`
export const LabelFormikSignUp = styled.div`
  height: 30px;
  box-sizing: border-box;
  margin-top: 25px;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: #333333;
  font-size: 15px;
`

export const IconButton = styled.button`
background-color: #c4dfe8;
display: inline-block;
border: none;
color: #73919C;
padding: 5px 10px;
font-size: 15px;
cursor: pointer;
box-shadow: 1px 2px 5px grey;
`

export const Button = styled.button`
  display: block;
  margin: 20px auto;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border: none;
  background-color: #1B998B;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  :hover{
    background-color: 0B6157;
    color: white;
    border: 1px solid #0B6157;
  }
`

export const SignInButton = styled.button`
  margin: 20px 5px;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border: none;
  background-color: #C5268D;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  :hover{
    background-color: white;
    color: #310D31;
    border: 1px solid #310D31;
  }
`

export const Error = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
`

export const StyledLink = styled(Link).attrs(props => ({
  to: props => props.to,
  children: props => props.children
}))`
  position: relative;
  top: -20px;
  font-size: 10px;
  line-height: 10px;
  text-decoration: none;
  color: black;
  :hover{
    color: #c52590;
    text-shadow: 0px 0px 5px #BFBFBF;
  }
`

export const SignUpButton = styled(Link).attrs(props => ({
  to: props => props.to,
  children: props => props.children
}))`
  padding: 6px 17px;
  margin: 20px 5px;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border: none;
  background-color: #C5268D;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  :hover{
    background-color: white;
    color: #310D31;
    border: 1px solid #310D31;
  }
`

export const Modal = styled.div`  
  box-sizing: border-box;
  display: ${props => props.view ? "flex" : "none"};
  justify-content: center;
  align-items: center;
  color: black;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0,0,0,0.35);
  left: 0;
  top: 0;
`
