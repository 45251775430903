import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { FetchAllCompany,FetchAllBranch, AddNewBranch } from '../../../url/prmUrl';
import { Button, Input, Label, Body, Error, Aside, TextArea, Modal } from '../StyledComponents'
import Alert from '../ShowAlert'


let companyOptions = []

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Brand name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.companyId && touched.companyId ? <Error>{errors.companyId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Company:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="companyId"
                    value={values.companyId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.companyId}
                  /> 
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Address:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="address"
                    placeholder="Type here..."
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.phoneNumber && touched.phoneNumber ? <Error>{errors.phoneNumber}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Phone Number:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="phoneNumber"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.phoneNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Fax:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="fax"
                    type="text"
                    placeholder="Fax"
                    value={values.fax}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Active:
                </div>
                <div css={'width:15px'}>
                  <Input
                    name="active"
                    type="checkbox"
                    css={`width:18px;height:18px`}
                    checked={values.active}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class MySelect extends React.Component {
  handleChange = value => {
    this.props.onChange("companyId", value);
  };

  handleBlur = () => {
   this.props.onBlur("companyId", true)
  };

  render() {
    return (
        <Select
          id="color"
          placeholder="Select Company"
          options={companyOptions}
          //isMulti
          onChange={this.handleChange}  //calls the handleChange in MySelect class
          onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
          value={this.props.value}      // gets the selected value, which is eventually options.value
        />

    );
  }
}

const Branch = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
        const fetchCompany = await axios(FetchAllCompany(localStorage.getItem('jwtID')));
        companyOptions = fetchCompany.data.map(element=>({label: element.companyName, value: element._id}))
     }
     catch (err) {
      // console.log(err)
     }

      const result = await axios(FetchAllBranch);
      try {
        setEditable({
        ...editable,
        loaded: result.data,
      })
    }
      catch (err) {
          // console.log(err)
      }

    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  const branch = editable.loaded ?
                  editable.loaded.Error ? [] :
                  editable.loaded.map(item=>item.name) : []


  const schema = Yup.object().shape({
    name: Yup.string().required("Must Define a Name")
                      .min(3, "Minimum 3 characters")
                      .notOneOf(branch, "Already exists"),
    companyId: Yup.string().required("Please select Company..."),
    phoneNumber: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid') //format 999-99999-9999
  })                                      //d means integer max 4 char then dash(-) then s measn string max 5 char then dash (-) then s max 4 char

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      name:"" , 
      companyId: '',
      phoneNumber: '',
      fax: '',
      active: true
    }),

    handleSubmit: (values, { setSubmitting }) => {
      values.companyId = values.companyId.value
      axios.post(AddNewBranch, {
        ...values,
        profileId: localStorage.getItem('jwtProfileId'),
      })
      .then((response) => {
        if(!response.data.Error){
          setView(true)
        }
        else {
          window.alert("Uexpected Error! Duplicate Name found!")
        }
      })
      .catch((err) => {
        // console.log("Error")
      })

    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Branch)