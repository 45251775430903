import React from 'react'
import TableView from './components/table_view/index'
import CreateButton from './components/create/createButton'

const Blog = () => { 
  return ( 
    <div className="container"> 
      <CreateButton /> 
      <TableView />  
    </div> 
  ) 
} 

export default Blog; 
