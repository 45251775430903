// export const HEADER_TEXT = "HEADER_TEXT"
export const FETCH = "FETCH"
export const CHANNEL_INFO_RECIEVE = "CHANNEL_INFO_RECIEVE"
export const NO_DATA = "NO_DATA"
export const SET_CHANNEL_ID = "SET_CHANNEL_ID"
export const SET_CHANNEL_TYPE = "SET_CHANNEL_TYPE"
export const SET_PREVIEW = "SET_PREVIEW"

export const NEXT_QUESTION = "NEXT_QUESTION"
export const NEXT_CONDITIONAL_QUESTION = "NEXT_CONDITIONAL_QUESTION"

export const EDIT_VALUE = "EDIT_VALUE"

export const SUBMIT_SUCCESS = "SUBMIT_SUCCESS"
export const SUBMIT_FAILURE = "SUBMIT_FAILURE"
export const SUBMIT = "SUBMIT"

export const ADD_CONTACT_CART = "ADD_CONTACT_CART"

export const SET_LOADING = "SET_LOADING"
export const START_DATE = "START_DATE"
export const END_DATE = "END_DATE"

export const PREVIOUS_QUESTION = "PREVIOUS_QUESTION"