//Filter which type of question to add
export const addField = (id, questionList, err) => {
  return (questionList.map(item => {
    if (id !== item.id) {    //means if not the selected question
      return {
        ...item,
      }
    } else {
      switch (item.type) {
        case "multipleChoice":
          return err.choices ? { ...item } : addMultipleChoice(item)
        case "dropDown":
          return err.options ? { ...item } : addDropDown(item)
        case "checkbox":
          return err.options ? { ...item } : addCheckbox(item)
        default:
          return {
            ...item,
          }
      }
    }

  }))
}

const addMultipleChoice = (item) => {
  const newChoices = [...item.choices]
  newChoices.push("")
  return {
    ...item,
    choices: [...newChoices],
  }
}

const addDropDown = (item) => {
  const newOptions = [...item.options]
  newOptions.push("")
  return {
    ...item,
    options: [...newOptions],
  }
}

const addCheckbox = (item) => {
  const newOptions = [...item.options]
  newOptions.push("")
  return {
    ...item,
    options: [...newOptions],
  }
}