import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { Button, Input, Modal, ModalHeader, ModalBody } from 'reactstrap';
import baseUrl from '../../../../url';
import axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Toast from '../../components/Toast';
import { createProjectForSurvey } from '../../redux/actions';
import collect from 'collect.js';

const styles = {
  error: {
    color: 'red',
    fontSize: '12px',
    marginLeft: '5px',
    textAlign: 'left',
    fontWeight: 'bold',
  },
};

const ModalExample = (props) => {
  const { buttonLabel, className, history } = props;

  const [modal, setModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [names, setNames] = useState([]);
  const [feature, setFeature] = useState();
  let dispatch = useDispatch();
  // console.log(props)
  useEffect(() => {
    axios
      .get(`${baseUrl}/api/features/list/all?limit=500&page=1`)
      .then((response) => {
        let optionResponse = collect(response.data.features).sortBy('name');
        const option = optionResponse.items.map((obj) => {
          return {
            value: obj._id,
            label: obj.user_Name || obj.name,
          };
        });
        setFeature(option);
      });
    axios
      .get(
        `${baseUrl}/api/project/survey/profile/${localStorage.getItem(
          'jwtProfileId'
        )}/?page=1&limit=500`
      )
      .then((res) => {
        // console.log(res)
        if (res && Object.keys(res.data).length > 0) {
          const nameOption = res.data.projectList.map((obj) => {
            return obj.user_Name || obj.name;
          });
          setNames(nameOption);
        }
      });
  }, []);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Toast />
      <Button
        color='success'
        size='sm'
        onClick={toggle}
        style={{ background: 'rgb(27, 153, 139)', margin: '10px 0' }}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>Create New Survey</ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize
            initialValues={{
              name: '',
              featureId: '',
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string()
                .required('Name is required')
                .trim()
                .lowercase()
                .notOneOf(names, 'Already Exist'),
              // featureId: Yup
              //     .string().required("Feature is required")
              featureId: Yup.string(),
            })}
            onSubmit={(values, { resetForm }) => {
              const fId = feature.find((obj) => obj.value === values.featureId);
              const obj = {
                name: values.name,
                // featureinfo: {
                //     id: fId.value,
                //     name: fId.label,
                // },
                profileId: localStorage.getItem('jwtProfileId'),
                createdBy: {
                  id: localStorage.getItem('jwtID'),
                  name: localStorage.getItem('userName'),
                },
                isSurvey: true,
              };
              dispatch(createProjectForSurvey(obj, history));
              // history.push('/app/survey/question')
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <div className='form-group'>
                  <label>
                    Name<span style={{ color: 'red' }}>{` *`}</span>
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='name'
                    value={props.values.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBur}
                    style={{
                      border:
                        props.errors.name && props.touched.name
                          ? '1px solid red'
                          : null,
                    }}
                  />
                  {props.errors.name && props.touched.name && (
                    <p style={styles.error}> {props.errors.name} </p>
                  )}
                </div>

                <div className='form-group'>
                  {/* <label htmlFor="name">Feature<span style={{ color: 'red' }}>{` *`}</span></label> */}
                  <label htmlFor='name'>
                    Feature{' '}
                    <span
                      style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.7)' }}
                    >
                      (Optional)
                    </span>{' '}
                  </label>
                  <Input
                    type='select'
                    name='featureId'
                    id='featureId'
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.featureId}
                    style={{
                      border:
                        props.errors.featureId && props.touched.featureId
                          ? '1px solid red'
                          : null,
                    }}
                  >
                    <option value='' disabled defaultValue hidden>
                      Select...
                    </option>
                    {feature &&
                      feature.length > 0 &&
                      feature.map((obj) => (
                        <option value={obj.value} key={obj.value}>
                          {' '}
                          {obj.label}{' '}
                        </option>
                      ))}
                  </Input>
                  {props.errors.featureId && props.touched.featureId && (
                    <p style={styles.error}> {props.errors.featureId} </p>
                  )}
                </div>

                <Button
                  className='btnHover mr-2'
                  style={{ background: '#1B998B', borderColor: '#1B998B' }}
                  type='submit'
                >
                  Next
                </Button>

                <Button color='secondary' onClick={toggle}>
                  Cancel
                </Button>
              </form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default withRouter(ModalExample);
