import { combineReducers } from 'redux';
import configReducer from './configReducer';
import questionReducer from './questionReducer';
import sidebarData from './sidebarStateReducer';
import surveyReducer from './surveyReducer'

export default combineReducers({
  config: configReducer,
  question: questionReducer,
  survey: surveyReducer,
  sidebarData
}) 
