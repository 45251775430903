import {
  mcq,
  checkbox,
  likertScale,
  ratingScale,
  textBox,
  slider,
  numeric,
  dropdown,
  dichotomy,
  thumbsUpDown,
  starRating,
  thumbsUpRating,
  loveRating,
  nps,
  date,
  daterange
} from '../../questionStructure'

export const changeType = (id, value, questionList) => {
  return (questionList.map(item => {
    if (id !== item.id) {
      return { ...item }
    } else {
      let newItem
      switch (value) {
        case "multipleChoice":
          newItem = { ...mcq }
          break
        case "dropDown":
          newItem = { ...dropdown }
          break
        case "dichotomy":
          newItem = { ...dichotomy }
          break
        case "thumbsUpDown":
          newItem = { ...thumbsUpDown }
          break
        case "checkbox":
          newItem = { ...checkbox }
          break
        case "likertScale":
          newItem = { ...likertScale }
          break
        case "ratingScale":
          newItem = { ...ratingScale }
          break
        case "starRating":
          newItem = { ...starRating }
          break
        case "thumbsUpRating":
          newItem = { ...thumbsUpRating }
          break
        case "loveRating":
          newItem = { ...loveRating }
          break
        case "nps":
          newItem = { ...nps }
          break
        case "textBox":
          newItem = { ...textBox }
          break
        case "slider":
          newItem = { ...slider }
          break
        case "numeric":
          newItem = { ...numeric }
          break
        case "date":
          newItem = { ...date }
          break
        case "daterange":
          newItem = { ...daterange }
          break
        default:
          newItem = { ...item }
      }
      newItem.id = id
      return { ...newItem }
    }
  }))
}