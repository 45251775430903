import React from 'react'
import { connect } from 'react-redux'

import { Body } from './styles'
import Question from '../../components/Question/editIndex'
import Loader from '../../../Loader'

import { questionIsLoadingSelector } from '../../redux/selectors'

const QuestionList = ({ questionList, isLoading }) => {

    const items = questionList.map((question, index) =>
        <Question
            key={`${index}_Question`}
            question={question}
        />)

    return (
        <Body>


            {
                !isLoading ?
                    <div>{items}</div>
                    :
                    <Loader />
            }
        </Body>
    )
}

const mapStateToProps = state => {
    return {
        questionList: state.config.questionList,
        isLoading: questionIsLoadingSelector(state)
    }
}

// const mapDispatchToProps = {

// }

export default connect(mapStateToProps)(QuestionList)