import React, { useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css"; 
import device from './asset/device.png'
import email from './asset/email.png'
import embaded from './asset/embaded.png'
import openlink from './asset/openlink.png'
import qrcode from './asset/qrcode.png'
import fb from './asset/fb.png'
import gp from './asset/gp.png'
import linkedin from './asset/in.png'
import twitter from './asset/twitter.png'
import youtube from './asset/youtube.png'
import {css} from 'glamor'
import './style.css'


const Example = (props) => { 
  const responsive = {
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 6, slidesToSlide: 1 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 6, slidesToSlide: 1 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 3, slidesToSlide: 1 },
  }; 

  const imgHeightCss = css({ 
    height: '60px !important', 
    '@media(max-width: 600px)': { height: '50px !important' }, 
    '@media(max-width: 425px)': { height: '30px !important' }, 
    '@media(max-width: 350px)': { height: '25px !important' }, 
  }) 

  const imgPfont = css({ 
    fontSize: '14px', color: 'rgb(52, 58, 64)', 
    fontWeight: '400', marginTop: '10px', 
    '@media(max-width: 600px)': { fontSize: '12px !important' }, 
    '@media(max-width: 425px)': { fontSize: '11px !important' }, 
    '@media(max-width: 350px)': { fontSize: '10px !important' }, 
  }) 

  const imgDivWidth = css({ 
    width: '100px !important', 
    '@media(max-width: 600px)': { width: '80px !important' }, 
    '@media(max-width: 425px)': { width: '60px !important' }, 
    '@media(max-width: 350px)': { width: '50px !important' }, 
  }) 

  return ( 
    <> 
      <Carousel className="mx-lg-5"
        swipeable={true} 
        draggable={true} 
        responsive={responsive}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={1500} 
        keyBoardControl={true} 
        customTransition="all .5"
        transitionDuration={500} 
        containerClass="carousel-container-my-class"
        removeArrowOnDeviceType={["desktop", "tab", "mobile"]} 
        deviceType={props.deviceType} > 
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 

        {/* 1 Repeat */}
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 

        {/* 2 Repeat */}
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 

        {/* 3 Repeat */}
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 

        {/* 4 Repeat */}
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 

        {/* 5 Repeat */}
        <div {...imgDivWidth}>
          <img src={device} alt="Device" {...imgHeightCss} /> 
          <p {...imgPfont}> Device </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={email} alt="Email" {...imgHeightCss} /> 
          <p {...imgPfont}> Email </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={qrcode} alt="qrcode" {...imgHeightCss} /> 
          <p {...imgPfont}> QR Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={openlink} alt="openlink" {...imgHeightCss} /> 
          <p {...imgPfont}> Open link </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={embaded} alt="embaded code" {...imgHeightCss} /> 
          <p {...imgPfont}> Embaded Code </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={fb} alt="facebook" {...imgHeightCss} /> 
          <p {...imgPfont}> Facebook </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={twitter} alt="twitter" {...imgHeightCss} /> 
          <p {...imgPfont}> Twitter </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={youtube} alt="youtube" {...imgHeightCss} /> 
          <p {...imgPfont}> Youtube </p>
        </div> 
        <div {...imgDivWidth}>
          <img src={linkedin} alt="linkedin" {...imgHeightCss} /> 
          <p {...imgPfont}> Linkedin </p>
        </div> 
        
      </Carousel> 
    </> 
  ) 
} 

export default Example; 

