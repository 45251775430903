import Axios from 'axios' 
import { 
    CREATE, GET_ALL, GET_SINGLE, UPDATE, 
    DELETE, BACK_BUTTON_IN_DETAIL_PAGE 
} from './actionTypes' 

import { 
    CreateDesignation, GetDesignation, 
    GetSingleDesignation, UpdateDesignation 
} from '../../../../../url/prmUrl' 


export const create_ = (values, CreateToast, ErrorToast) => dispatch => { 
    Axios.post(CreateDesignation, values) 
        .then(res => { dispatch({ type: CREATE, payload: res.data }); CreateToast() }) 
        .catch(err => ErrorToast()) 
} 


export const getAll_ = (ErrorToast) => dispatch => { 
    Axios.get(GetDesignation) 
        .then(res => { dispatch({type: GET_ALL, payload: res.data}) }) 
        .catch(err => ErrorToast()) 
} 


export const getSingle_ = (id, ErrorToast) => dispatch => { 
    Axios.get(GetSingleDesignation(id)) 
        .then(res => { dispatch({type: GET_SINGLE, payload: res.data}) }) 
        .catch(err => { ErrorToast() }) 
} 


export const update_ = (id, values, UpdateToast, ErrorToast) => dispatch => { 
    Axios.patch(UpdateDesignation(id), values) 
        .then(res => { dispatch({type: UPDATE, payload: res.data}); UpdateToast(); }) 
        .catch( err => ErrorToast() ) 
} 


export const delete_ = (state) => dispatch => { dispatch({type: DELETE, payload: {state}}) } 


export const backButtonFunction = () => dispatch => { dispatch({type: BACK_BUTTON_IN_DETAIL_PAGE}) } 


