import React from 'react'
import image from './assets/customer.png'
import CJM from './assets/customer_journey_map.png'
import effort from './assets/customer_effort_score.png'
import NPS from './assets/NET_PROMOTER_SCORE.png'
import banner from './assets/Customer_Experience_CX.png'
import {css} from 'glamor'

function Customer() { 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'})
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 
  const imageHideOnMobile = css({'@media(max-width: 767px)': { display: 'none !important' }}) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Customer experience banner" {...bannerWidth} /> 
      </div> 

      <p {...text}>
        Whenever we are buying any products or purchasing any service, we are becoming a customer of that particular product or service providers business. Customer’s perception about that businessor or brand is the customer experience or CX. 
      </p>

      <p {...text}>
        Customer Experience holds a lot of importance. Every business is dedicated to customers. Any product or service is meaningless if it is not handed over to its potential customer. Customer interaction with the business reveals customer insights. Customers, satisfied with the product or service, are definitely beneficial to the business. The trust of the customer makes a solid customer base for the business. On the other hand dissatisfied customers create a negative impact on the business. Not just he or she but also some other potential customers can be lost due to their negative advocacy, which is really harmful to any business. So business heads should be aware of customer’s feelings to make better and successful decisions and to grow business. 
      </p>
      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="customer experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        ProlificXm is an incredible tool to gather customer insights. ProlificXM technology uses advanced computation and algorithm for statistical inferences and can process information at an incredible speed and map customer engagement in different touch points. ProlificXM gives the benefit of saving employees and customers’ precious time. ProlificXM helps companies to improve a customer journey by analyzing customer feedback. This gives customers a sense of belonging and satisfaction at every interaction. 
      </p>

      <p {...text}> 
        ProlificXM uses some super-sophisticated metrics to measure customer experience. There is Customer Journey Map (CJM) to track a customer’s overall journey from acknowledging the product or service to consume. CSAT is to score a customer’s satisfaction level. Customer Effort Score (CES) is to indicate how effortlessly customers can consume the offerings of the business. Net Promoter Score (NPS) is there to know how spontaneously customers can promote the business to others like friends, family, and colleagues. These metrics with powerful statistical analysis fetch out the best result in terms of customer experience. 
      </p> 



      {/* CJM */}
      <> 
        <h1 {...header}>Customer Journey Map</h1> 
        <div className="text-center"> 
          <img src={CJM} alt="Customer Journey Map" 
            className="img-fluid" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row mb-3"> 
          <div className="col-sm-12"> 
            <p {...text}> 
              How frequently do you purchase a product or service without having any idea of being unaware of that? Obviously it’s not very often. A customer’s journey with a product, service, brand or business starts with at that moment when he/she got to know about that. Then come various factors which accelerate to make a buying decision. Features, functionality, outlook, availability, consistency, reliability fetch customers’ attraction towards the product or service. When the customer gets the same or more what he expected from the product or service, his or her satisfaction level towards it automatically increases. This satisfaction brings the customer to avail of the product again and again. The more reliability exists on the customer mind, retention score is exalted in parallel. A satisfied customer does not revolve all alone, he/she also advocates about the service or product to others from his or her upright experience. This creates a broader and stronger customer base for the business.
            </p> 
          </div> 
          {/* <div className="text-center col-md-5 col-lg-5" {...imageHideOnMobile}> 
            <img src={CJM} alt="Customer Journey Map" 
              className="img-fluid" style={{maxHeight: '300px'}} /> 
          </div>  */}
        </div> 
      </> 






      <h1 {...header}>Customer Satisfaction</h1>
      <p {...text}>
        Is it possible to satisfy any customer 100% offering the best product or services you have got? You directly cannot answer this question. The satisfaction quotient is not the same for all. It varies from person to person. A dissatisfied customer is not a failure at all. The founder of Microsoft, Bill Gates said that- “Your most unhappy customers are your greatest source of learning”. Serving the same to everyone doesn’t make sure all will be happy. So it is very important to know who is preferring what and not. These customer insights help to cover all possible loopholes for fixing the satisfaction levels. Indeed, a satisfied customer is the best business strategy of all which enlightens the image of the business brighter. 
      </p> 

      {/* Effort Score */}
      <> 
        <h1 {...header}>Customer Effort Score</h1> 
        <div className="text-center"> 
          <img src={effort} alt="Customer Effort Score" 
            className="img-fluid d-sm-inline d-md-none d-lg-none mb-3" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row mb-3" style={{alignItems: 'center'}}> 
          <div className="col-sm-12 col-md-6 col-lg-6"> 
            <p {...text}> 
              How much effort your customer has to give to interact with you? If it is very less, then “Congratulations!” you are one step ahead satisfying your customers. During or after purchasing a product or service, if a customer faces any issues and it is not solved within a certain period then this negativity will surely affect the customer effort score. Customers tend to go there, where they face fewer difficulties. If your competitors have better and easy communication with their customers, they are in a more suitable phase in business than you. The less hardship in interaction, the more positive impact you can attain. Find those holes where you are lagging behind to serve your customers so that those can be filled up to overcome customer effort.
            </p> 
          </div> 
          <div className="text-center col-md-6 col-lg-6" {...imageHideOnMobile}> 
            <img src={effort} alt="Customer Effort Score" 
              className="img-fluid" style={{maxHeight: '300px'}} /> 
          </div> 
        </div> 
      </> 



      {/* NPS */} 
      <> 
        <h1 {...header}>Net Promoter Score (NPS)</h1> 
        <div className="text-center"> 
          <img src={NPS} alt="Net Promoter Score" 
            className="img-fluid d-sm-inline d-md-none d-lg-none mb-3" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row mb-3" style={{alignItems: 'center'}}> 
          <div className="text-center col-md-6 col-lg-6" {...imageHideOnMobile}> 
            <img src={NPS} alt="Net Promoter Score" 
              className="img-fluid" style={{maxHeight: '270px'}} /> 
          </div> 
          <div className="col-sm-12 col-md-6 col-lg-6"> 
            <p {...text}> 
              Can you ever imagine running a business without attaching your customer? If you don’t have any customers, who will buy your product or service and how your business will grow? Net promoter score or NPS interacts with customer experience. A customer’s experience really matters for business growth. A satisfied customer will purchase again and again and recommend others about the product or service from his or her satisfaction. In spite of being satisfied, some customers do not advocate due to the lack of enthusiasm. On the other hand, there is some customer who is not happy at all with the product or service and renders negative marks to the business. The last group of people is a great threat to the business. It can drag down the business growth rate. NPS calculates such types of customer experiences to predict the enhancement of the business. NPS indicates where the business is facing risks or where it is improving on its way of business outgrowth. 
            </p> 
          </div> 
        </div> 
      </> 




      {/* <h1 {...header}>Net Promoter Score (NPS)</h1>
      <p {...text}>
        Can you ever imagine running a business without attaching your customer? If you don’t have any customers, who will buy your product or service and how your business will grow? Net promoter score or NPS interacts with customer experience. A customer’s experience really matters for business growth. A satisfied customer will purchase again and again and recommend others about the product or service from his or her satisfaction. In spite of being satisfied, some customers do not advocate due to the lack of enthusiasm. On the other hand, there is some customer who is not happy at all with the product or service and renders negative marks to the business. The last group of people is a great threat to the business. It can drag down the business growth rate. NPS calculates such types of customer experiences to predict the enhancement of the business. NPS indicates where the business is facing risks or where it is improving on its way of business outgrowth.
      </p>  */} 
    </div> 
  ) 
} 

export default Customer; 
