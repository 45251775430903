import React, {useEffect, Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Input } from 'reactstrap'; 
import { getALLBrandsProfileWise, companyFieldData, categoryFieldDataTrueOnly, createNewBrand, backButtonFunction } from '../../store/actions/actionCreator'
import { Formik } from 'formik' 
import * as Yup from 'yup' 
import showToast from '../../../../../components/Toast/toast' 
import { withRouter } from 'react-router-dom' 
import BreadCrumb from '../../../../../components/Breadcrumb/breadcrumb' 
import '../buttonCss.css'


function CreateBrand(props) { 
    const { history } = props; 
    const companyProfileId = localStorage.getItem("jwtProfileId") 

    let dispatch = useDispatch() 
    let { allData, companys, categoryTrueOnly: category } = useSelector(state => state.brand) 
    
    
    const CreateToast = () => showToast('Created Successfully') 
    const ErrorToast = () => showToast('Server Error') 
    
    
    try { 
        if(!Object.keys(allData).length) { allData.data = [] } 
        if(Object.keys(allData).length) { 
            allData.data.sort(function(a, b) { 
                return new Date(b.createdAt) - new Date(a.createdAt) 
            }); 
        } 
        if(!Object.keys(companys).length) { companys = [] } 
        if(!Object.keys(category).length) { category.data = [] } 
        if(Object.keys(category).length) { 
            // Sorting Alphabetically 
            let compare = (a, b) => { 
                const nameA = a.name.toUpperCase(); 
                const nameB = b.name.toUpperCase(); 

                let comparison = 0; 
                if (nameA > nameB) { comparison = 1; } 
                else if (nameA < nameB) { comparison = -1; } 
                return comparison; 
            } 
            category.data.sort(compare); 
        } 
    } catch (error) { } 




    useEffect(() => { 
        dispatch(getALLBrandsProfileWise(companyProfileId)) 
        dispatch(categoryFieldDataTrueOnly()) 
        dispatch(companyFieldData(companyProfileId)) 
    }, []) 


    return ( 
        <Fragment> 
            <div className="container"> 
                <BreadCrumb path="Brand" slug="Create" link="/app/brand/" backButtonFunction={backButtonFunction} /> 
                
                <Card className="my-3"> 
                    <CardBody> 
                        <Formik 
                            initialValues={{ name:'', status: true, company: '', category: '' }} 
                            validationSchema={ 
                                Yup.object().shape({ 
                                    name: Yup 
                                        .string().required("Required").trim().lowercase()
                                        .notOneOf(allData ? allData.data.map(obj => obj.name.toLowerCase()) : [], "Already Exist"), 
                                    // company: Yup.string().required("Required"), 
                                    category: Yup.string().required("Required"),
                                }) 
                            } 
                            onSubmit={(values, { resetForm }) => { 
                                const createdData = { 
                                    companyProfileId: companyProfileId,
                                    name: values.name.trim(), 
                                    active: values.status, 
                                    categoryId: values.category, 
                                    createdAt: Date.now() 
                                } 
                                console.log({brand: createdData});
                                
                                dispatch(createNewBrand(createdData, CreateToast, ErrorToast)); 
                                resetForm(); 
                                history.push('/app/brand/'); 
                            }}> 
                            { 
                            (props) => ( 
                                <Form onSubmit={props.handleSubmit}> 
                                    <FormGroup row> 
                                        <Label htmlFor="brand" sm={2}> 
                                            Name: <span style={{color: 'red'}}>{` *`}</span> 
                                        </Label> 
                                        <Col sm={4}> 
                                            <Input type="text" name="name" id="brand" 
                                                value={props.values.name} onChange={props.handleChange} 
                                                style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                                onBlur={props.handleBlur} placeholder="Enter brand name"
                                            /> 
                                            { 
                                                props.errors.name && props.touched.name && 
                                                <p style={{color: 'red', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold'}}> 
                                                    {props.errors.name} 
                                                </p> 
                                            } 
                                        </Col> 

                                        <Label htmlFor="company" sm={2}> 
                                            Company: <span style={{color: 'red'}}>{` *`}</span>
                                        </Label> 
                                        <Col sm={4}> 
                                            <Input 
                                                type="select" name="company" id="company" 
                                                value={props.values.company} 
                                                style={{border: props.errors.company && props.touched.company ? '1px solid red' : null}}
                                                onChange={props.handleChange} 
                                                onBlur={props.handleBlur} > 
                                                <option value={companys._id} disabled defaultValue hidden> 
                                                    {companys.name ? companys.name : companys.user_Name} 
                                                </option> 
                                                <option value={companys._id}> 
                                                    {companys.name ? companys.name : companys.user_Name} 
                                                </option> 
                                            </Input> 
                                            {/* { 
                                                props.errors.company && props.touched.company && 
                                                <p style={{ 
                                                    color: 'red', fontSize: '12px', 
                                                    marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                                }}> 
                                                    {props.errors.company} 
                                                </p> 
                                            }  */}
                                        </Col> 
                                    </FormGroup> 
                                    
                                    <FormGroup row> 
                                        <Label htmlFor="category" sm={2}> 
                                            Category: <span style={{color: 'red'}}>{` *`}</span>
                                        </Label> 
                                        <Col sm={4}> 
                                            <Input 
                                                type="select" name="category" id="category" 
                                                style={{border: props.errors.category && props.touched.category ? '1px solid red' : null}}
                                                value={props.values.category} 
                                                onChange={props.handleChange} 
                                                onBlur={props.handleBlur} > 
                                                <option value="" disabled defaultValue hidden>Select...</option> 
                                                { 
                                                    category.data.map((obj) => { 
                                                        return ( <option value={obj._id} key={obj._id}> {obj.name} </option> ) 
                                                    }) 
                                                } 
                                            </Input> 
                                            { 
                                                props.errors.category && props.touched.category && 
                                                <p style={{ 
                                                    color: 'red', fontSize: '12px', 
                                                    marginLeft: '5px', textAlign: 'left', fontWeight: 'bold' 
                                                }}> 
                                                    {props.errors.category} 
                                                </p> 
                                            } 
                                        </Col> 
                                        
                                        <Label htmlFor="status" sm={2}>Active:</Label> 
                                        <Col> 
                                            <Input 
                                                type="checkbox" id="status" name="status" 
                                                className="form-check-input mt-2" 
                                                checked={props.values.status} 
                                                onChange={props.handleChange} 
                                                onBlur={props.handleBlur} /> 
                                        </Col> 
                                    </FormGroup> 
                                    
                                    <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B' }} size="sm" type="submit"> 
                                        Save 
                                    </Button> 
                                    
                                    <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 10px' }} size="sm" 
                                        onClick={() => { history.push('/app/brand') }}> 
                                        Cancel 
                                    </Button> 
                                </Form> 
                            ) 
                            } 
                        </Formik> 
                    </CardBody> 
                </Card> 
            </div> 
        </Fragment> 
    ) 
} 

export default withRouter(CreateBrand) 
