import styled from 'styled-components'
import {Link} from 'react-router-dom'

export const CloseButton = styled(Link)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #E6E6E6;
  color: red;
  text-decoration: none;
  // border: 1px solid black;
  :hover{
    color: red;
    box-shadow: 1px 1px 5px 2px black;
    border: none;
    >span{
      text-shadow: 0 0 red;
    }
  }
`

export const Content = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 90%;
  width: 90%;
  background: white;
  z-index: 501;
`