import React, { useState, useEffect } from 'react' 
import styled from 'styled-components/macro' 
import axios from 'axios'; 

import { ChannelCard, ChannelItem, Banner, DeleteText, EditText } from '../../../StyledComponents' 
// import { Modal } from '../../../../StyledComponents'
import OpenLinkCreate from './OpenLinkCreate'
import OpenLinkDetails from './OpenLinkDetails'
import { DotLoader } from '../../../../Loader'
import { getAllProfileWiseOL, deleteOL } from '../../../../../url/index'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import showToast from '../../../../../components/Toast/toast'


const OpenLink = props => { 
  /*States-----------------------------------------------------*/
  const [view, setView] = useState([false, false]) 
  const [openLink, setOpenLink] = useState([]) 
  const [select, setSelect] = useState('') 
  const [loading, setLoading] = useState(false) 
  let [deleteModal, setDeleteModal] = useState(false) 
  let [editModal, setEditModal] = useState(false) 
  let [deletableOLId, setDeletableOLId] = useState(false) 
  let [editableOLId, setEditableOLId] = useState('') 

  const toggle = () => setView([false, false]); 
  /*-----------------------------------------------------------*/ 

  // Toast 
  const DeleteToast = () => showToast('Deleted Successfully') 
  const DependencyToast = () => showToast('Cannot delete, there are dependency') 
  const ErrorToast = () => showToast('Server Error') 

  useEffect(() => { 
    setLoading(false) // make this true when connected to DB
  }, []) 

  useEffect(() => { 
    const fetchData = async () => { 
      let result, openLinkData; 
      try { 
        result = await axios(getAllProfileWiseOL(localStorage.getItem('jwtProfileId'))); 
        openLinkData = result.data.map((d) => { 
          const filterData = { 
            id : d._id , 
            name : d.name , 
            active: d.active, 
            createdByCompanyID: d.createdByCompanyID, 
            branchId: d.branchId ? d.branchId : '', 
            isDefault: d.isDefault
          } 
          return filterData 
        }) 
      } 
      catch (error) { openLinkData = [] } 
      finally { setOpenLink([ ...openLinkData ]); setLoading(false) } 
    };

    fetchData();
  }, []); 

  const deleteOpenLink = () => { 
    axios.delete(deleteOL(deletableOLId)) 
      .then(res => { 
        if(res.data.isDeletable) { 
          const newOL = openLink.filter(item => (item.id !== deletableOLId)) 
          setOpenLink(newOL) 
          DeleteToast() 
        } 
        else { DependencyToast() } 
        setDeleteModal(false); 
      }) 
      .catch(err => ErrorToast()) 
    setView([false, false]) 
    setSelect('') 
  } 


  const openLinkList = openLink.map((item, id) => ( 
    <ChannelCard key={id}> 
      <ChannelItem> 
        <DeleteText item={ChannelCard} onClick={() => { setDeleteModal(true); setDeletableOLId(item.id);}}> Delete </DeleteText>  
        <EditText item={ChannelCard} onClick={() => {setEditModal(true); setEditableOLId(item.id)}}> Edit </EditText> 

        <div css={`height: 70%; font-size: 50px; color: #1B998B;`}> 
          <FontAwesomeIcon icon={faLink} css={`padding: .1px; @media only screen and (max-width: 425px){}`}/> 
        </div> 
        <div css={`height: 30%; color:white; background-color: rgb(52, 58, 64); word-break: break-all; padding: 0 5px;`}> {item.name} </div> 
      </ChannelItem> 
    </ChannelCard> 
  )) 

  return ( 
    <> 
      { 
        deleteModal && 
        <Modal isOpen={deleteModal} className={props.className}> 
          <ModalHeader> Delete open link </ModalHeader> 
          <ModalBody> Are you sure, you want to delete? </ModalBody> 
          <ModalFooter> 
            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={deleteOpenLink}> 
              Delete 
            </Button> 
            
            <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm" onClick={() => setDeleteModal(false)}> 
              Cancel 
            </Button> 
          </ModalFooter> 
        </Modal> 
      } 

      { 
        loading ? <DotLoader /> 
        :
        <div css={` display: flex; position: relative; justify-content: flex-start; align-items: flex-start; align-content: flex-start; flex-wrap: wrap;`}>
          <Banner>OpenLink Channel List</Banner> 

          {/* Create OpenLink Modal */} 
          <Modal isOpen={view[0]} toggle={toggle} className={props.className}> 
            <ModalHeader toggle={toggle}> 
              <span style={{margin:'0 0 0 25px',color:'rgb(52, 58, 64)',fontSize:'18px'}}> Create Open Link </span> 
            </ModalHeader> 
            <OpenLinkCreate /> 
          </Modal> 

          {/* Edit Device Modal */} 
          <Modal isOpen={editModal} toggle={() => setEditModal(false)} className={props.className}> 
            <ModalHeader toggle={() => setEditModal(false)}> 
              <span style={{margin: '0 0 0 25px', color:'rgb(52, 58, 64)', fontSize:'18px'}}> Edit Open Link </span> 
            </ModalHeader> 
            <OpenLinkDetails data={openLink.find((item) => item.id === editableOLId)} /> 
          </Modal> 

          { openLinkList } 

          { 
            props.activestatus && 
            <ChannelCard> 
              <ChannelItem onClick={() => { setView([true, false]) }}> 
                <div css={`height: 55%; font-size: 30px; color: #1B998B;`}> + </div> 
                <div css={`height: 45%; text-align: center; color:white; background-color: rgb(52, 58, 64);`}>Add Predefined Open Link</div> 
              </ChannelItem> 
            </ChannelCard> 
          } 
        </div> 
      } 
    </> 
  ) 
} 

export default OpenLink; 