import React, { Component } from 'react';
import Gauge from '../charts/gauge/Gauge';
import VerticalBar from '../charts/verticalbar/VerticalBar';
import Nps from '../nps/Nps';
import Barchart from '../charts/nivo/Barchart';
import Donut from '../charts/donut/Donut';
import './BorderHandler.css';

const styles = {
    root: {
        // padding: '10px 5px 0 5px'
    },
    title: {
        color: "#424242",
        fontSize: "14px",
        fontWeight: "700",
        height: "25px",
        marginTop: "15px",
        marginBottom: "30px"
    }
}

class LayoutOne extends Component {
    render() {
        const variables = this.props.variables;
        const length = variables.length;
        let borderClass = "";
        const classes = "col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3";
        return (
            <>
                <div className="card mt-4" style={styles.root}>
                    <div className="card-body">
                        <div className="row justify-content-center">
                            {

                                variables.map((item, index) => {

                                    const itemName = item.name;
                                    let elementId = itemName.split(' ');
                                    elementId = elementId[0].replace(/[^a-zA-Z0-9 ]/g, "") + '_' + index;

                                    borderClass = (index === (length - 1)) ? " " : " border-right-cl-3";

                                    // Three pointer calculation based on tag
                                    let threePointerCenterValue = 0;
                                    let threePointerNames = ['Agree', 'Neutral', 'Disagree'];

                                    if (this.props.tag === "CSAT" || this.props.tag === "EMSAT") {

                                        threePointerCenterValue = item.avgPercentage;
                                        threePointerNames = ['Satisfied', 'Neutral', 'Dissatisfied'];

                                    } else if(this.props.tag === "CES" || this.props.tag === "EMES") {
                                        
                                        threePointerCenterValue = item.threePointChart[0][1] - item.threePointChart[2][1];
                                        threePointerCenterValue = Number(Math.round(parseFloat(threePointerCenterValue + 'e' + 2)) + 'e-' + 2);
                                        threePointerNames = ['Easy', 'Medium', 'Difficult'];

                                    } else {

                                        threePointerCenterValue = item.avgPercentage;

                                    }

                                    return <div key={index} className={classes + borderClass}>
                                        <h6 className="text-center" style={styles.title}>{item.name}</h6>
                                        <Donut id={elementId + index} data={item.threePointChart} centerValue={threePointerCenterValue} centerTitle="SCORE" names={threePointerNames} />
                                        <Gauge
                                            value={item.average}
                                            max={5}
                                        />
                                        <Nps nps={item.nps} />
                                        <div className="mb-5">
                                            <VerticalBar data={item.variablePercent} />
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LayoutOne;