import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

import { FullView, Group, ButtonGroup, Button } from './styles'
import './style.css';

import SelectName from './SelectName'
import SelectActive from './SelectActive'
import SelectStatus from './SelectStatus'

// import SelectChannel from './SelectChannel'
// import SelectSubChannel from './SelectSubChannel'
import SelectProjects from './SelectProjects'
import ToggleButton from './ToggleButton'

import { projectFetch, reset } from '../../redux/actions'
import { viewSelector, projectPropertySelector, nextButtonSelector } from '../../redux/selectors'
// import SelectStatus from './SelectStatus';

const Configuration = props => {

  const { view, projectFetch, configButtonEnabled, reset } = props;
  // const [name, setName] = useState('');
  // const [status, setStatus] = useState('draft');
  // const [active, setActive] = useState(true);

  useEffect(() => {     // using useEffect to stop re-fething
    const id = localStorage.getItem('jwtProfileId')
    projectFetch(id)
  },
    []
  )

  return (
    <FullView view={view}>
      <ToggleButton />
      {view &&
        <>

          <Group>
            {/* <label htmlFor="channel">
              Channel
            </label>
            <SelectChannel />

            <label htmlFor="subChannel">
              Sub Channel
            </label>
            <SelectSubChannel /> */}

            {/* <label className="dislabel">
              Name: 
              <input
                className="inputname"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </label> */}
            <SelectName />

            <label htmlFor="project" className="dislabel">
              Select Question from Project:
            </label>
            <SelectProjects />

          </Group>

          <Group>


            <SelectActive />
            <SelectStatus />
            {/* <label className="dislabel">
              Active: 
              <input
                name="active"
                type="checkbox"
                checked={active}
                onChange={event => {
                  const target = event.target;
                  const value = target.type === 'checkbox' ? target.checked : target.value;
                  setActive(value)
                }} />
            </label>

            <label className="dislabel" style={{marginTop: '15px'}}>
              Status: 
              <select className="selectstatus" value={status} onChange={event => setStatus(event.target.value)}>
                <option value="draft" disabled>Draft</option>
                <option value="submitted" disabled>Submitted</option>
                <option value="canceled" disabled>Canceled</option>
                <option value="distributed" disabled>Distributed</option>
              </select>
            </label>          */}

            <ButtonGroup>
              {/* {configButtonEnabled && <Button as={Link} to='/app/distribution/oldpreview'>Current Config</Button>} */}
              <Button type='button' onClick={reset}>Reset</Button>
            </ButtonGroup>

          </Group>

        </>
      }

    </FullView>
  )
}

const mapStateToProps = state => {  //loads all the states from the store 
  return {
    // view is for the props above in this page to hide and show
    view: viewSelector(state),
    // configButtonEnabled: projectPropertySelector(state) && !nextButtonSelector(state) 
  }
}

const mapDispatchToProps = {
  // dispathing  via connect to load projects as soon as the page loads. the first projectFetch is for the props and the 
  // second project fetch is the function calling from redux/action
  projectFetch,
  reset
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)   //connect deals with the dispatch function 