//Dependencies
import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

//Custom Components and resources
import MainNav from '../MainNav'
import QuestionCardList from '../../containers/QuestionCardList';
import CreateProject from '../CreateProject'
import SaveButton from '../SaveButton'
import PreviewButton from '../PreviewButton'
import Analysis from '../Visualization';
import LinkButton from '../LinkButton'
import { Button } from 'reactstrap'
import AddButton from '../AddButton';
import { connect } from 'react-redux'
import { fetchQuestion, fetchObjectives, updateQuestion, backButtonFunction, deleteQuestionBank, resetStore } from '../../redux/actions/modifyQuestion'
import Toast from '../Toast'
import Loader from '../../../Loader'
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'
import { TextAlignCenter } from 'grommet-icons';
import showToast from '../../../../components/Toast/toast';
import './style.css';

const MainBody = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: center;
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  padding: 0;
  margin: 0;
`
const style = {
  button: {
    fontSize: "12px",
    fontWeight: "500",
    border: 'none',
    height: "55px",
    width: "56px",
    borderRadius: "50%",
    textAlign: "center",
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
  },
  update: {
    background: 'rgb(27, 153, 139)',
    position: 'fixed',
    top: '75px',
    right: '100px',
    zIndex: "2"
  },
  delete: {
    background: '#F27D7D',
    position: 'fixed',
    top: '75px',
    right: '30px',
    zIndex: "2"
  }
}

class FormApp extends React.Component {

  // componentDidMount(){
  //   this.props.fetchQuestion();
  // }

  componentDidMount() {
    // this.props.initialize(this.props.objective) 
    this.props.fetchObjectives()
    if (this.props.projectId !== 'create')
      this.props.fetchQuestion(this.props.projectId)
    if (this.props.projectId === 'create')
      this.props.resetStore()
    // console.log(this.props.projectId)
  }

  // notify = () => toast("Update successfully");
  updateToast = () => showToast("Update successfully");
  saveToast = () => showToast("Save successfully");
  deleteSuccess = () => showToast("Delete successfully");
  deleteFail = () => showToast("This project is not deletable");


  updateProject() {
    this.props.updateQuestion(this.props.question, this.props.projectId)
    if (this.props.projectName && this.props.featureId.id) {
      this.updateToast();
    }
  }

  deleteProject() {
    this.props.deleteQuestion(this.props.projectId);
    setTimeout(() => {
      if (this.props.error.isDeletable) {
        this.deleteSuccess();
        this.props.history.push('/app/questionbank')
      } else {
        this.deleteFail();
      }
    }, 1000);
  }

  render() {
    return (
      <MainBody >
        {this.props.loading ?
          <Loader />
          :
          <>
            {/* <Toast /> */}

            {this.props.projectId === 'create' && <SaveButton saveToast={this.saveToast} className="btnHover" css={`background: '#1B998B`}>Save</SaveButton>}
            {this.props.projectId !== 'create' && <Button className="btnHover text-center" style={{ ...style.button, ...style.update }} size="sm" onClick={() => this.updateProject()}>Update</Button>}
            {this.props.projectId !== 'create' && <Button className="btnDeleteHover text-center" style={{ ...style.button, ...style.delete }} size="sm" onClick={() => this.deleteProject()}>Delete</Button>}

            <Body>
              <div className="container" style={{ maxWidth: '850px', width: '50%', padding: '10px 25px 5px 25px', borderRadius: '4px', backgroundColor: "#EFF3F4", marginBottom: '10px' }}>
                <CreateProject />
              </div>
              <QuestionCardList />
              <AddButton />
            </Body>
          </>
        }
      </MainBody>
    )
  }
}

const mapDispatchToProps = {
  fetchQuestion: fetchQuestion,
  fetchObjectives: fetchObjectives,
  updateQuestion: updateQuestion,
  deleteQuestion: deleteQuestionBank,
  resetStore
  // initialize: initialize,
}

const mapStateToProps = (state, ownProps) => {   // state -> retrieve value from store, ownprops-> values form this component
  const { objective, projectId } = ownProps
  const { loading, question, projectName, featureId, error } = state
  return {
    objective,
    projectId,
    loading,
    question,
    projectName,
    featureId,
    error
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FormApp))