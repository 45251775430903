import React, { Component } from 'react'
import c3 from 'c3';
import './Donut.css';

class Donut extends Component {

    renderChart() {

        let data = this.props.data;
        data.map((item, index) => {
            return item[0] = this.props.names[index] ? this.props.names[index] : item[0];
        });

        let colors = ['#1B998B', '#FFBF69', '#F27D7D'];
        c3.generate({
            bindto: `#${this.props.id}`,
            data: {
                columns: data,
                type: 'donut',
            },
            size: {
                height: 160
            },
            color: {
                pattern: colors
            },
            donut: {
                title: `${this.props.centerTitle}:${this.props.centerValue}%`,
                width: 18,
                label: {
                    show: false
                }
            }
        });

    }

    componentDidMount() {
        this.renderChart();
    }

    componentDidUpdate() {
        this.renderChart();
    }

    render() {
        return (
            <div className="C3-Donut" id={this.props.id}></div>
        )
    }
}

export default Donut;
