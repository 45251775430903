import React, {useState} from 'react' 
import { withRouter } from 'react-router-dom'
import image from './assets/xm.png'
import {css} from 'glamor'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import NavBar from '../LandingPage/components/nav/nav'
import Footer from '../LandingPage/components/footer/Footer'
import SignupModal from '../Pricing/components/modal/modal'
// import ScreenModal from '../modal/modal'

function SurveyPage(props) { 
  const [showFreeModal, setShowFreeModal] = useState(false) 
  const [successMsgModal, setSuccessMsgModal] = useState(false) 

  const closeModal = (msg = null) => {  
    if(msg) { setSuccessMsgModal(true) } 
    setShowFreeModal(false) 
  } 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'}) 
  const listHeader = css({color: 'rgb(52, 58, 64)', fontSize: '15px', fontWeight: '500'}) 

  const styles = { 
    error: { fontSize: '12px', color: 'red', marginBottom: '2px', fontWeight: '500' }, 
    button: { background: '#1B998B', borderColor: '#1B998B' }
  } 

  return ( 
    <div style={{overflowY: 'scroll'}}> 

      <> 
        { 
          showFreeModal && 
          <SignupModal header="Free Signup" showFreeModal={showFreeModal} closeModal={closeModal} /> 
        } 
      </> 

      {/* Signup success message */} 
      <> 
        <Modal isOpen={successMsgModal} className={props.className} style={{top: '150px'}}> 
          {/* { closeModal() }  */} 
          <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
            You are Done!!! 
          </ModalHeader> 
          <ModalBody> 
            Thank you for joining us. Please check your email to verify. 
            <br /> 
            <Button className="btnHover mt-3" size="sm" style={styles.button} 
              onClick={() => setSuccessMsgModal(false)}> 
              Close 
            </Button> 
          </ModalBody> 
        </Modal> 
      </> 


      <NavBar /> 
      <div className="container mt-4"> 
        <h1 {...header}>What Is Survey?</h1> 
        <p {...text}> 
          A survey is a method of gathering information from a group of people. Here a list of questions that extracting data from a particular group of people. It may be conducted by phone, mail, via the internet, and sometimes face-to-face on busy street corners or in malls. It has a variety of purposes and can be carried out in many ways depending on the methodology chosen and the objectives to be achieved. Here data are collected through a structured procedure by which respondent can provide accurate answer easily. Traditionally survey conducted on paper asking respondent for information through questionnaire. But now a days in the era of modern technology it is common to distribute those using digital media such as Social media, Emails, QR code or URLs. 
        </p> 

        <center> 
          <Button 
            style={{ background: '#1B998B', borderColor: '#1B998B', margin: 'auto', padding:'4px 15px', fontSize:'18px', fontWeight:'500' }}
            className="btnHover mt-2" size="sm" onClick={() => setShowFreeModal(true)}> 
            Get start with your first survey 
          </Button> 
        </center> 
        
        <p {...text} className="mt-2 text-center"> 
          It is very easy to create customized survey, design it, distribute them and collect insights with ProlificXM. 
        </p> 

        <div style={{width: '100%'}}>
          <div className="col-sm-12 col-md-4 col-md-3" style={{margin: 'auto', padding: '30px 10px', border: '2px solid #1B998B', borderRadius: '25px'}}> 
            <p className="text-center" style={{marginBottom: '5px', fontWeight: '500'}}>CREATE YOUR FIRST SURVEY</p>
            <p className="text-center" style={{marginBottom: '5px', fontWeight: '500'}}>SEND IT</p>
            <p className="text-center" style={{marginBottom: '5px', fontWeight: '500'}}>And</p>
            <p className="text-center" style={{marginBottom: '5px', fontWeight: '500'}}>GET REPORT</p>

            <center> 
              <Button 
                style={{ background: '#1B998B', borderColor: '#1B998B', margin: 'auto', padding:'4px 15px', fontSize:'13px', fontWeight:'400' }} 
                className="btnHover mt-2" size="sm" onClick={() => setShowFreeModal(true)}> 
                Free Signup 
              </Button> 
            </center> 
          </div> 
        </div> <br /> <br /> 

        <>
          <h1 {...header}>Benefits of using online survey.</h1> 
          <p {...text}> 
            It’s easy to create an online survey. Here a set of structured questions and respondent completes over internet. Online survey /Survey tools can save your company time and money because they have lower setup and administrative costs. It is convenient for you because you just need to send the survey link via email and you’ll have the data in your survey management software as soon as responses come in. Now a day’s people are forwarding to online survey because it is- 
          </p> 


          <ul style={{marginLeft: '40px'}} {...text}>
            <li className="mb-2">
              <span {...listHeader}>More accurate:</span> {` `} 
              The margin of error is greatly reduced with online surveys because participants enter their responses directly into the system. In traditional method there needs human interference which increase the margin of error.
            </li>
            <li className="mb-2">
              <span {...listHeader}>CHEAPER & Faster:</span> {` `} 
              Since all are in digital platform, so Using online questionnaires reduce your research cost. And provide you real time analysis.
            </li>
            <li className="mb-2">
              <span {...listHeader}>Easy for participants:</span> {` `} 
              Now a day’s most people have access to the internet and they prefer to answer survey online instead of traditional questionnaire. With an online survey, participants can pick a moment that suits those best and the time needed to complete the survey is much shorter.
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Create a Strong Brand Impression:</span> {` `} 
              An online survey is an opportunity to imprint your brand in the user’s mind and remind them about your services. You have this opportunity to design your questionnaire according your business website with customized backgrounds, images, logo, and fonts.
            </li>
            <li className="mb-2">
              <span {...listHeader}>More honest:</span> {` `} 
              Researcher found that respondents prefer to complete online survey rather than take part in written question. By designing and sending relevant and targeted surveys, people are more likely to respond with honest answers and can skip the questions or respondents to a more neutral option, increasing their flexibility to respond. 
            </li> 
          </ul> 
        </> <br /> 


        <>
          <h1 {...header}>Design Your Survey</h1> 
          <p {...text}> 
            To keep your survey on the right track you need to design it properly. By taking the time to plan out your research question and distribute them suitable channels. It helps you to get quality data. 
          </p> 


          <ul style={{marginLeft: '40px', listStyleType: 'none'}} {...text}>
            <li className="mb-2">
              <span {...listHeader}>Define your objective and Questions: </span> {` `} 
              Firstly you need to define your objective. Your objective like understanding the customers think of your latest product. Or your objective should be something like: “I want to understand the key factors that are leading our customers to leave—whether these reasons are caused by internal or external forces”. Then you need to set your questions according to your objectives. It is important to note, not to ask redundant questions or questions where the answers are already known.  Without research question and certain metrics a survey will only give you data and not the insights.
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Target Audience:</span> {` `} 
              Defining a target audience is a business best practice. Proper choice of target audience can provide quality data which helps you take accurate decision. Your target audience may be your customers, employees, or consumers who are not using your products or services. You must identify this population and understand the best way to reach them through Omni Channels (social media, email, Qr Code, your website, etc). 
            </li>
            <li className="mb-2">
              <span {...listHeader}>Design and preview surveys:</span> {` `} 
              Designing the questionnaire carefully and then preview it before distributing it to your target audience for getting valid and reliable data.
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Distribute your survey:</span> {` `} 
              After distributing your survey it’s important that you put substantial effort into getting responses from everyone in the sample, as this will determine the response rate of the survey.
            </li>
            <li className="mb-2">
              <span {...listHeader}>Data analysis:</span> {` `} 
              Its necessary to code, clean and adjust the data before analyzing. Our powerful analytics tools can easily analyze the data in real time and gives you actionable insights from your data. 
            </li> 
          </ul> 
        </> <br /> 


        <h1 {...header}>Improve Your Survey</h1>
        <p {...text}>
          Your way of asking questions has an impact on response. SO you must ask simple and relevant questions. There are several other best practices that can get you maximum response and help you take accurate decision. 
        </p> <br /> 


        <>
          <h1 {...header}>Right question types:</h1> 
          <p {...text}> 
            There are several types of questions can be used in a survey and the question type has a direct impact on the survey results. <br /> 
            A list of the most common question types:
          </p> 


          <ul style={{marginLeft: '40px'}} {...text}>
            <li className="mb-2">
              <span {...listHeader}>The Dichotomous Question: </span> {` `} 
              The dichotomous question is generally a "yes/no" close-ended question. It is used in case of the need of basic validation. It is also used to distinguish from in-between people that have had some or any form of interaction with your brand.
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Multiple Choice Question: </span> {` `} 
              In this question type a respondent has to select one (single select multiple choice question) a traditional list of choices. 
            </li>
            <li className="mb-2">
              <span {...listHeader}>Check box Questions: </span> {` `} 
              It is used when you want participants to select more than one answer from a list.
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Comment Box Open Ended Question: </span> {` `} 
              It is used to gather open-ended feedback from respondents. These responses can be lengthy essays, standard form information such as name and email address, or anything in between.
            </li>
            <li className="mb-2">
              <span {...listHeader}>Contact Information Question: </span> {` `} 
              It is an open-ended question that has multiple rows of text that is indicated with a title. This type of question can collect basic respondent information like full name, address, email address, phone number, age and sex. 
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Likert Scale Question: </span> {` `} 
              Rating questions are asked to indicate their personal levels on things such as agreement, satisfaction, or frequency. 
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Rating Question: </span> {` `} 
              Rating scale is used to represent respondent feedback in a comparative form for specific particular features/products/services. 
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Visual Analog Question: </span> {` `} 
              The visual analog question is a close-ended question can be administered in two ways - a Thumbs Up/Thumbs Down question type and odd-point smiley question. 
            </li> 
            <li className="mb-2">
              <span {...listHeader}>Net Promoter Score (NPS) Question: </span> {` `} 
              This question is imperative towards understanding the level of customer satisfaction. Response to this question is measured on a scale of 0-10 and provides a simplified, yet highly effective vision of an organization's brand popularity and identifying potential brand promoters or detractors among existing customers. 
            </li> 
          </ul> 
        </> <br /> 


        <h1 {...header}>Avoid asking Personal info When you already have.</h1>
        <p {...text}>
          Respondent feel hesitate to give their personal information. If you already have this information, don’t ask it again. If you are collecting sensitive data make sure your servers that house the data are secure and you do periodic penetration checks to patch vulnerabilities.
        </p> 

        <h1 {...header}>Distribute survey through the right channels</h1>
        <p {...text}>
          You can distribute your survey through different Omni Channels like (web, email, social media, etc.) You need to determine the best Channel to reach maximum amount of target audience.
        </p> 


        <h1 {...header}>Survey Results</h1>
        <p {...text}>
          When you conduct a survey, you must have access to its analytics dashboard. We provide two types of dashboard Core analysis & Advance Analysis Dashboard. There are many things you can do from our analytics section. You can view overall and also help you answer research questions in more depth. You can get real time analysis when respondent starts giving response.
        </p> 
      </div> 
      <Footer /> 
    </div> 
  ) 
} 

export default withRouter(SurveyPage); 
