import React from 'react'
import ReactDOM from 'react-dom'

import {Body} from './styles'

export const Modal = props=>{
  
  const backupClose = ()=>{
    console.log("Provide a function to close the modal; close=fn()")
  }
  
  const handleClose = event=>{
    if(event.target===event.currentTarget){
      close()
    }
  }
  
  const {children, close=backupClose} = props

  return ReactDOM.createPortal(
    <Body onClick={handleClose}>
      {children}
    </Body>,
    document.body)
}

export default Modal