import React from 'react'
import TableView from './components/table_view' 
import CreateButton from './components/create_brand/createButton'

const BrandCategory = () => { 
    return ( 
        <div className="container"> 
            <CreateButton /> 
            <TableView /> 
        </div> 
    ) 
} 

export default BrandCategory;

