import React from 'react';

const Device = (props) => {
  return (
    <label
      className='dislabel'
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        margin: 0,
      }}
    >
      <input
        name='active'
        type='checkbox'
        // checked={props.item.status === 'Active' ? true : false}
        defaultChecked={props.item.status === 'Active' ? true : false}
        onChange={(event) => {
          const target = event.target;
          const value =
            target.type === 'checkbox' ? target.checked : target.value;
          props.setDeviceActive(value, props.index);
          props.deviceStatusOnClick();
        }}
      />
      <span
        style={{
          fontWeight: 500,
        }}
      >
        {props.item.name}
      </span>
    </label>
  );
};

export default Device;
