import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { LinkButton } from './styles'
import { nextButtonSelector } from '../../redux/selectors'

const EditNextButton = props => {

  const { enabled, match, disid } = props
  // console.log(props)
  return enabled && props.name !== '' ?
    <LinkButton
      to={`/app/distribution/edit/sortandpreview/${disid}`}
    >
      Next
    </LinkButton>
    :
    <></>
}

const mapStateToProps = state => ({
  enabled: nextButtonSelector(state),
  name: state.question.name
})

export default withRouter(connect(mapStateToProps)(EditNextButton))