export const cardsList = [
  { id: 'contactCard', type: 'contactCard', multiple: false },
];

//ToDo: Recieve card config schemas from database and then work according to that

export const contactCard = {
  id: 'contactCard',
  title: 'Contact Card',
  fieldList: [
    { label: 'Name', value: 'name' },
    { label: 'Gender', value: 'gender' },
    { label: 'Contact Number', value: 'contact number' },
    { label: 'Email', value: 'email' },
    { label: 'Age', value: 'age' },
    { label: 'Birth Date', value: 'birth date' },
    { label: 'District', value: 'district' },
    { label: 'Postal Code', value: 'postal code' },
    { label: 'Profession', value: 'profession' },
    { label: 'Organization/Institution', value: 'organization/institution' },
  ],
  fields: {
    name: true,
    gender: false,
    'contact number': true,
    email: true,
    age: false,
    'birth date': false,
    district: false,
    'postal code': false,
    profession: false,
    'organization/institution': false,
  },
};
