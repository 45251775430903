import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Modal = styled.div`  
  box-sizing: border-box;
  display: ${props=>props.view ? "flex": "none"};
  justify-content: center;
  align-items: center;
  color: black;
  user-select: none;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 80;
  background-color: rgba(0,0,0,0.35);
  left: 0;
  top: 0;
`

export const Body = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin: 20px;
  background-color: #white;
  padding: 15px;
  font-size: 15px;
  box-shadow: 0 0 5px 4px #E6E6E6;
  justify-content: center;
  align-items: center;
  
  > span {color: #585151}
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
  }

`
export const Error = styled.span`
  color: red;
  font-size: 12px;
  font-weight: 500;
`

export const Aside = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: row;
    margin-bottom:10px;
    box-shadow: 0 0 5px 4px #E6E6E6;
    color: #310D31 !important;
    >section {
      display: flex;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding-left:15px;  
    }
    >table{
      border: 1px solid #ddd;
      text-align: left;
      border-collapse: collapse;
      width: 100%;
    }
    >table >tr >th{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td{
      border: 1px solid #ddd;
      text-align: left;
      padding: 15px;
    }
    >table >tr >td >input{
      width:25%;
    }
  `


export const Input = styled.input.attrs(props => ({
  ...props
}))`
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 10px;
  border:none;
  outline: none;
  box-shadow: 1px 1px 3px 1px hsl(0,0%,80%);
  border-radius: 4px;
  height: 35px;
  :placeholder {
    color: #310D31;
    opacity: 1;
  }
`
export const TextArea = styled.textarea.attrs(props => ({
  ...props
}))`
font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 10px;
  border:none;
  outline: none;
  resize: vertical;
  box-shadow: 1px 1px 3px 1px hsl(0,0%,80%);
  border-radius: 4px;
  height: 80px;
  :placeholder {
    color: #310D31;
    opacity: 1;
  }
`

export const Label = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: left;
  flex-direction: row;
  text-align: center;
  align-items:center;
  padding:10px 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  width: 100%;
  position: relative;
  color: rgb(52, 58, 64);
  font-weight:normal;
  font-size: 15px;
  > h2 {
    font-size: 30px;
    @media only screen and (max-width: 768px){
      font-size: 18px;
      margin-top: 30px;
    }
    @media only screen and (max-width: 425px){
      font-size: 16px;
      margin-top: 45px;
    }
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
  }
  @media only screen and (max-width: 425px){
    font-size: 10px;
  }
`

export const Button = styled.button`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  color: white;
  border: none;
  background-color: #1B998B;
  height: 30px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  z-index: 1;
  :hover{
    background-color: #0B6157;
    color: #fff;
    border: 1px solid #0B6157;
  }
  @media only screen and (max-width: 768px){
    font-size: 12px; 
    width: 90px;
    height: 25px;
  }

  @media only screen and (max-width: 425px){
    font-size: 10px;
    width: 80px;
    height: 20px;
  }
`