import React from 'react'
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { css } from 'glamor';
import ToastComponent from './toastComponent'

const showToast = (msg) => { 
    return toast(<ToastComponent msg={msg} />, { 
        className: css({ color: '#1B998B' }) 
    }) 
} 

export default showToast 
