import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UploadContainer, Upload, UploadButton } from '../styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import ImageCrop from './ImageCrop'
import { connect } from 'react-redux';
import { addImage } from '../../../redux/actions'
import axios from 'axios'
import baseUrl from '../../../../../../src/url'

const ModalExample = (props) => {
    const {
        buttonLabel,
        className
    } = props;

    const [modal, setModal] = useState(false);
    const [kropImage, setKropImage] = useState(null)

    const toggle = () => setModal(!modal);

    // function blobToFile(theBlob, fileName) {
    //     //A Blob() is almost a File() - it's just missing the two properties below which we will add
    //     theBlob.lastModifiedDate = new Date();
    //     theBlob.name = fileName;
    //     return theBlob;
    // }

    function dataURLtoFile(dataurl, filename) {
        console.log(dataurl, 'dataurl')
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        console.log(mime)

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

    //Usage example:


    function handleImageChange(src) {

        // console.log(typeof (src), src)
        // event.preventDefault();
        // const file = new File([src], "file.png", { lastModified: new Date(0), type: "image/png" });
        // console.log(typeof (file), file)
        // var myBlob = new Blob();
        // var file = blobToFile(myBlob, src);
        // console.log('file', file)
        // var file = dataURLtoFile(src, 'image.png');
        // console.log(file);
        var file = new File([src], 'filename', { type: 'image/png' });
        // var blob = new File([src], "image.png", { type: "image/png" })
        const formData = new FormData();
        formData.append('file', file);
        formData.append('method', 'PATCH')

        axios.patch(`${baseUrl}/api/distribution/upload/logo/${props.disId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(response => {
            // console.log(response)
            props.addImage(response.data.logo)
        }).catch(error => {
            console.log(error);
        });
        // console.log(formData)

    }

    return (
        <div>
            <Button style={{ backgroundColor: 'transparent', border: '0 solid', padding: '3px 4px' }} onClick={toggle}>{buttonLabel}</Button>
            <Modal isOpen={modal} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Modal title</ModalHeader>
                <ModalBody>
                    <ImageCrop kropImg={setKropImage} />
                </ModalBody>
                <ModalFooter>
                    {kropImage && <Button color="primary" onClick={() => { toggle(); handleImageChange(kropImage) }}>Add Image</Button>}{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

const mapDispatchToProps = {
    addImage: (data) => addImage(data)
}

export default connect(null, mapDispatchToProps)(ModalExample);