import React, { useState, useEffect, Fragment } from 'react'; 
import { withRouter } from 'react-router-dom'; 
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios'; 
import Select from 'react-select'; 
import { Formik } from 'formik'; 
import * as Yup from 'yup'; 
import { css } from 'glamor'
import { create_, getAll_, backButtonFunction } from '../../store/actions/actionCreator'; 
import BreadCrumb from '../../../../components/Breadcrumb/breadcrumb'; 
import showToast from '../../../../components/Toast/toast'; 
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, 
  Label, UncontrolledTooltip, Input, InputGroup, InputGroupAddon, 
  InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'; 
import baseUrl from '../../../../url';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline';
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment';
import Font from '@ckeditor/ckeditor5-font/src/font';
import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';

import '../buttonCss.css'; 
import './multi.css'; 
import './editor.css'; 

const editorConfiguration = {
  plugins: [Essentials, Bold, Italic, Paragraph, Underline, Alignment, Font],
  toolbar: ['bold', 'italic', 'underline', 'alignment:justify', 'alignment:center', 'alignment:left', 'alignment:right', 'fontFamily', 'fontSize', 'fontColor', 'fontBackgroundColor', 'undo', 'redo',],
};

const MultiSelect = (props) => { 
  let [tags, setTags] = useState([]); 
  console.log(props.count)
  useEffect(() => { 
    axios.get(`${baseUrl}/api/blog/tags`) 
      .then( response => { 
        let allData = response.data.tagList; 

        // Sorting 
        allData.sort((a, b) => { 
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase(); 

          let comparison = 0; 
          if (nameA > nameB) { comparison = 1; } 
          else if (nameA < nameB) { comparison = -1; } 
          return comparison; 
        }); 
        const tagData = allData.map(obj => { 
          return { 
            value: obj._id, 
            label: obj.name 
          } 
        }) 

        setTags(tagData); 
      }) 
      .catch(error => console.log(error) 
  )}, [props.count]) 


  return ( 
    <div>  
      <Select 
        isMulti options={tags} 
        onChange={(value) => { props.onChange('tags', value) }} 
        onBlur={() => { props.onBlur('tags', true) }} 
        value={ props.value }
        onCreateOption={value => console.log(value)}>
        {/* <button>Add new</button>  */}
      </Select> 
    </div> 
  ); 
} 





const Create = (props) => {

  const formData = new FormData();
  const { history } = props; 
  const dispatch = useDispatch(); 
  let { allData } = useSelector(state => state.blog) 
  const [bodyValue, setBodyValue] = useState('') 
  const [createModel, setCreateModel] = useState(false); 
  const [uniqueTags, setUniqueTags] = useState([]);
  const [imageLink, setImageLink] = useState(''); 
  const [count, setCount] = useState(0) 
  
  let postTitles = [] 
  try {
    postTitles = allData.postList.map(obj => obj.title.toLowerCase())
  } 
  catch (error) { } 

  const CreateToast = () => showToast('Created Successfully') 
  const ErrorToast = () => showToast('Server Error') 

  useEffect(() => { 
    dispatch(getAll_(ErrorToast)) 

    // call tags 
    axios.get(`${baseUrl}/api/blog/tags`) 
      .then( response => { 
        let allTags = response.data.tagList.map(obj => obj.name) 
        setUniqueTags(allTags); 
      }) 
      .catch(error => console.log(error)) 
  }, []) 

  const imageHandler = (event) => { 
    event.preventDefault(); 
    formData.append('file', event.target.files[0]); 
  } 

  const uploadImageHandler = (event) => {
    event.preventDefault(); 
    axios.patch(`${baseUrl}/api/blog/posts/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' }})
      .then(res => {
        setImageLink(res.data.featureImageUrl) 
      }) 
      .catch(err => ErrorToast())
  } 

  let iconCss = css({ color: '#1B998B', ':hover': { color: '#0B6157' } }) 


  return ( 
    <Fragment> 
      <Modal isOpen={createModel} className={props.className}>
        <ModalHeader>Create a tag</ModalHeader> 
        <ModalBody>
          <Formik
            initialValues={{ tagName: '' }}
            validationSchema={ 
              Yup.object().shape({ 
                tagName: Yup 
                  .string().required("Required").trim().lowercase().notOneOf(uniqueTags, 'Already Exist')
              }) 
            } 
            onSubmit={(values, { resetForm }) => { 
              axios.post(`${baseUrl}/api/blog/tags`, {name: values.tagName}) 
                .then(res => { CreateToast(); resetForm(); setCount(count+1);}) 
                .catch(err => console.log(err))
            }}> 
            { 
              props => ( 
                <Form onSubmit={props.handleSubmit}> 
                  <FormGroup row> 
                    <Label htmlFor="tagName" sm={3}> 
                      Create Tag: <span style={{ color: 'red' }}>{` *`}</span> 
                    </Label> 
                    <Col sm={7}> 
                      <Input type="text" name="tagName" id="tagName"
                        style={{ border: props.errors.tagName && props.touched.tagName ? '1px solid red' : null }}
                        value={props.values.tagName} onChange={props.handleChange} 
                        onBlur={props.handleBlur} placeholder="Enter a tagName"
                      /> 
                      { 
                        props.errors.tagName && props.touched.tagName &&
                        <p style={{color: 'red', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold'}}>
                          {props.errors.tagName} 
                        </p> 
                      } 
                    </Col> 
                  </FormGroup> 

                  <FormGroup> 
                    <div style={{float: 'right'}}> 
                      <Button 
                        type="submit" className="btnHover mr-2" size="sm" 
                        style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}> 
                        Create 
                      </Button> 
                      
                      <Button className="btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                        onClick={() => {setCreateModel(false)}}> 
                        Close 
                      </Button> 
                    </div> 
                  </FormGroup> 
                </Form> 
              ) 
            } 
          </Formik> 
        </ModalBody> 
      </Modal> 
      
      
      <div className="container"> 
        <BreadCrumb path="Blog" slug="Create" link="/app/blog/" backButtonFunction={backButtonFunction} /> 
        
        <Card className="my-3"> 
          <CardBody> 
            <Formik 
              initialValues={{ title: '', active: true, body: bodyValue, tags: [], excerpt: '' }} 
              validationSchema={ 
                Yup.object().shape({ 
                  title: Yup 
                    .string().required("Required").trim()
                    .lowercase().notOneOf(postTitles, 'Already Exist'), 
                  body: Yup.string().required("Required").trim(),
                  excerpt: Yup.string().required("Required").trim()
                }) 
              } 
              onSubmit={(values, { resetForm }) => { 
                const alltags = values.tags.map(obj => { 
                  return { 
                    id: obj.value, 
                    name: obj.label 
                  } 
                }) 
                // let createdData = { 
                //   title: values.title,
                //   excerpt: values.excerpt,
                //   body: values.body,
                //   tags: alltags, 
                //   active: values.active,
                // }

                formData.append('title', values.title);
                formData.append('excerpt', values.excerpt);
                formData.append('body', values.body);
                formData.append('tags', JSON.stringify(alltags));
                formData.append('active', values.active);
                
                dispatch(create_(formData, CreateToast, ErrorToast)); 
                resetForm();
                history.push('/app/blog/');
              }}> 
              { 
                props => ( 
                  <Form onSubmit={props.handleSubmit}> 
                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="title" sm={2}> 
                        Title: <span style={{ color: 'red' }}>{` *`}</span> 
                      </Label> 
                      <Col sm={5}> 
                        <Input type="text" name="title" id="title"
                          style={{ border: props.errors.title && props.touched.title ? '1px solid red' : null }}
                          value={props.values.title} onChange={props.handleChange} 
                          onBlur={props.handleBlur} placeholder="Enter a title"
                        /> 
                        { 
                          props.errors.title && props.touched.title &&
                          <p style={{color: 'red', marginBottom: '0.1rem', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold'}}>
                            {props.errors.title} 
                          </p> 
                        } 
                      </Col> 
                    </FormGroup>

                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="image" sm={2}> Upload Image: </Label> 
                      <Col style={{marginTop: '5px'}} id="ratio"> 
                        <Input 
                          type="file" id="image" 
                          style={{cursor: 'pointer'}} 
                          // value={formData} 
                          onChange={imageHandler} 
                        /> 
{/* 
                        <Button type="submit" size="sm" onClick={uploadImageHandler} className="btnHover" style={{background: '#1B998B', borderColor: '#1B998B'}} > 
                          Upload 
                        </Button>  */}
                      </Col> 
                      <UncontrolledTooltip placement="top" target="ratio"> 
                        Expected img ratio 1100px x 400px 
                      </UncontrolledTooltip> 
                    </FormGroup>
                    
                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="excerpt" sm={2}> 
                        Excerpt: <span style={{ color: 'red' }}>{` *`}</span> 
                      </Label> 
                      <Col> 
                        <Input type="textarea" name="excerpt" id="excerpt" 
                          style={{ border: props.errors.excerpt && props.touched.excerpt ? '1px solid red' : null, height: '100px' }} 
                          value={props.values.excerpt} onChange={props.handleChange} 
                          onBlur={props.handleBlur} placeholder="Enter some text" /> 
                        { 
                          props.errors.excerpt && props.touched.excerpt &&
                          <p style={{color: 'red', marginBottom: '0.1rem', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold'}}>
                            {props.errors.excerpt} 
                          </p> 
                        } 
                      </Col> 
                    </FormGroup> 

                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="body" sm={2}> 
                        Body: <span style={{ color: 'red' }}>{` *`}</span>
                      </Label> 
                      <Col>
                        <div style={{ border: props.errors.body && props.touched.body ? '1px solid red' : null, borderRadius: '4px' }}> 
                          <Editor 
                            name="body" 
                            value={props.values.body} 
                            onChange={props. setFieldValue} 
                            onBlur={props.setFieldTouched} 
                            touched={props.touched.body} 
                          /> 
                          
                          
                        </div> 
                        { 
                          props.errors.body && props.touched.body &&
                          <p style={{color: 'red', marginBottom: '0.1rem', fontSize: '12px', marginLeft: '5px', textAlign: 'left', fontWeight: 'bold'}}>
                            {props.errors.body} 
                          </p> 
                        } 
                      </Col>
                    </FormGroup> 

                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="tags" sm={2} className=""> Tag: </Label> 
                      <Col sm={5}> 
                        <InputGroup style={{display: 'block', position: 'relative'}}>
                          <InputGroupAddon addonType="append">
                            <InputGroupText id="addNew" 
                              style={{
                                position: 'absolute', right: '-39px', 
                                height: '38px', borderRadius: '0 4px 4px 0'
                              }}
                              onClick={() => setCreateModel(!createModel)}>
                              <i className="fas fa-plus" {...iconCss}></i> 
                            </InputGroupText> 
                            <UncontrolledTooltip placement="top" target="addNew"> 
                              Add new 
                            </UncontrolledTooltip> 
                          </InputGroupAddon> 
                          <MultiSelect 
                            value={props.values.tags} 
                            onChange={props.setFieldValue}
                            count={count}
                            // onClick={() => } 
                            onBlur={props.setFieldTouched} 
                            error={props.errors.tags} 
                            touched={props.touched.tags} 
                          /> 
                        </InputGroup> 
                      </Col> 
                    </FormGroup> 
                    
                    <FormGroup row style={{marginBottom: '0.5rem'}}> 
                      <Label htmlFor="active" sm={2}>Active:</Label>
                      <Col> 
                        <Input 
                          type="checkbox" id="active" name="active" 
                          className="form-check-input mt-2" 
                          checked={props.values.active} onChange={props.handleChange} 
                          onBlur={props.handleBlur} /> 
                      </Col> 
                    </FormGroup> 
                    
                    <Button 
                      style={{ background: '#1B998B', borderColor: '#1B998B' }} 
                      className="btnHover" size="sm" type="submit"> 
                      Save
                    </Button> 

                    <Button style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 10px' }} size="sm"
                      className="btnHover" onClick={() => { history.push('/app/blog'); }}>
                      Cancel
                    </Button> 
                  </Form> 
                ) 
              } 
            </Formik> 
          </CardBody> 
        </Card> 
      </div> 
    </Fragment> 
  ) 
} 


class Editor extends React.Component {
  handleChange = value => this.props.onChange("body", value);
  handleBlur = () => this.props.onBlur("body", true);

  render() { 
    return ( 
      <CKEditor 
        editor={ClassicEditor} 
        config={editorConfiguration} 
        onChange={(event, editor) => { 
          const data = editor.getData(); 
          this.handleChange(data) 
        }} 
        onBlur={this.handleBlur} 
        style={{height: '300px'}} 
      /> 
    ); 
  } 
} 

export default withRouter(Create); 

