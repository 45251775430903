import styled from 'styled-components'

export const FullView = styled.div`
  position: relative;
  box-sizing: border-box;
  min-height: ${props => props.view ? 'auto' : '30px'}
  width: 100%;
  padding-top: 10px;
  display: flex;
  box-shadow: 0 1px 5px 2px grey;
  z-index: 2;
`

export const Group = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 10px 20px;
  flex-basis: 50%;
`
export const ToggleContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  right: 0;
  padding: 5px;
  margin: 0;
  z-index: 20;
`
export const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`
export const Button = styled.button`
  position: relative;
  box-sizing: border-box;
  margin-left: 0;
  padding: 3px 8px;
  background: green;
  box-shadow: 2px 1px 3px 1px grey;
  color: white;
  border: none;
  outline: none;
  text-decoration: none;
  :active{
    box-shadow: 0 0 5px 1px #0B4032 inset;
  }
  :hover{
    color: white;
  }
`

export const Input = styled.input.attrs({
  type: "text",
  placeholder: "Name For the distribution(Mandatory)"
})`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 2px 5px;
  margin: 5px 0;
`