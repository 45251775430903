import React, { Component } from 'react';
import collect from 'collect.js';
import TabBar from '../tabBar/TabBar';
import './Dashboard.css';
import axios from 'axios';
import Analytic from '../analytic/Analytic';
import Filter from '../filter/Filter';
import baseUrl from '../../../../url/index';

const styles = {
    border: 'none',
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    margin: '10px 0'
}

class Tab extends Component {

    _isMount = false;

    constructor(props) {
        super(props)
        this.state = {
            feature: [],

            activeId: {},

            links: {
                feature: [],
                highLevelObject: [],
                lowLevelObject: []
            },

            line: [],

            data: [],

            dataArchive: [],

            barchart: [],

            variables: [],

            lowLevelObject: [],
        };

        this.filterParams = null;

        this.tag = '';
        this.handleClick = this.handleClick.bind(this);
        this.handleFilterParam = this.handleFilterParam.bind(this);
        this.showFilterData = this.showFilterData.bind(this);
        this.setAsOnDateAnalyticData = this.setAsOnDateAnalyticData.bind(this);
    }

    handleClick(e) {
        let id = e.target.dataset.id;
        let sectionName = e.target.parentElement.dataset.sectionName;
        const activeId = { ...this.state.activeId };
        const analyticsData = [...this.state.data];

        let lowLevelObject = null;
        if (sectionName === 'low-level-object') {
            // console.log('Id:>>', id, 'Section:>>', sectionName);
            this.linkProcess(id, activeId.highLevelObject, activeId.feature);

            if (id == -1) {
                lowLevelObject = analyticsData.filter((obj) => {
                    return obj.highLevelObjectiveId.includes(activeId.highLevelObject);
                });
            } else {
                lowLevelObject = analyticsData.find((object) => {
                    return object.objectiveDetails.id === id;
                });
            }

            if (this._isMount) {
                this.setState({
                    lowLevelObject: lowLevelObject
                });
            }

        }

        if (sectionName === 'high-level-object') {
            // console.log('Id:>>', id, 'Section:>>', sectionName);
            // console.log(e.target.dataset);

            this.linkProcess("-1", id, activeId.feature);

            lowLevelObject = analyticsData.filter((obj) => {
                return obj.highLevelObjectiveId.includes(id);
            });

            if (this._isMount) {
                this.setState({
                    lowLevelObject: lowLevelObject
                });
            }
        }

        if (sectionName === 'feature') {
            // console.log('Id:>>', id, 'Section:>>', sectionName);
            const feature = [...this.state.feature];
            const selectedFeature = collect(feature).where('id', '=', id).first();
            const highLevelObject = selectedFeature.highLevelObject.push[0];
            const highLevelObjectId = highLevelObject ? highLevelObject.id : null;

            this.linkProcess("-1", highLevelObjectId, id);

            lowLevelObject = analyticsData.filter((obj) => {
                return obj.highLevelObjectiveId.includes(highLevelObjectId);
            });

            if (this._isMount) {
                this.setState({
                    lowLevelObject: lowLevelObject
                });
            }
        }

    }

    linkProcess(lowLevelObjectiveId, highLevelObjectiveId, featureId) {

        let feature = [...this.state.feature];
        let selectedFeature = collect(feature).where('id', '=', featureId).isNotEmpty()
            ? collect(feature).where('id', '=', featureId).first()
            : null;

        feature.map(item => item.active = false);
        selectedFeature && (selectedFeature.active = true);

        let highLevelObject = selectedFeature
            ? selectedFeature.highLevelObject[this.state.activeId.channelType]
            : [];

        highLevelObject.map(item => item.active = false);

        let selectedHighLevelObject = collect(highLevelObject).isNotEmpty()
            ? collect(highLevelObject).where('id', '=', highLevelObjectiveId).first()
            : null;

        selectedHighLevelObject && (selectedHighLevelObject.active = true);

        let lowLevelObject = selectedHighLevelObject
            ? selectedHighLevelObject.lowLevelObject
            : [];

        lowLevelObject.map(item => item.active = false);

        let selectedLowLevelObject = collect(lowLevelObject).isNotEmpty
            ? collect(lowLevelObject).where('id', '=', lowLevelObjectiveId).first()
            : null;

        selectedLowLevelObject && (selectedLowLevelObject.active = true);

        this.tag = selectedHighLevelObject ? selectedHighLevelObject.tag : 'default';

        if (this._isMount) {
            this.setState({
                links: {
                    feature: feature,
                    highLevelObject: highLevelObject,
                    lowLevelObject: lowLevelObject
                },
    
                activeId: {
                    feature: featureId,
                    highLevelObject: highLevelObjectiveId,
                    lowLevelObject: lowLevelObjectiveId,
                    channelType: "push"
                }
            });
        }
    }

    handleFilterParam(filterParams) {

        if (filterParams && filterParams.type === "date") {

            this.filterParams = {
                startDate: new Intl.DateTimeFormat('en-GB').format(filterParams.startDate),
                endDate: new Intl.DateTimeFormat('en-GB').format(filterParams.startDate),
                type: "date"
            }

        } else if (filterParams && filterParams.type === "month") {

            this.filterParams = {
                startDate: new Intl.DateTimeFormat('en-GB').format(filterParams.startDate),
                endDate: new Intl.DateTimeFormat('en-GB').format(filterParams.startDate),
                type: "month"
            }
        } else if (filterParams && filterParams.type === "daterange") {

            this.filterParams = {
                startDate: filterParams.startDate ? new Intl.DateTimeFormat('en-GB').format(filterParams.startDate) : null,
                endDate: new Intl.DateTimeFormat('en-GB').format(filterParams.endDate),
                type: "daterange"
            }

        } else {

            this.setAsOnDateAnalyticData();

        }
    }

    showFilterData() {

        if (this.filterParams) {

            if (this.filterParams.type === "daterange" && this.filterParams.startDate === null) {
                const today = new Intl.DateTimeFormat('en-GB').format(new Date());
                if (today === this.filterParams.endDate) {
                    this.setAsOnDateAnalyticData();
                } else {

                    this.setFilteredAnalyticData();

                }

            }
            else {

                this.setFilteredAnalyticData();
            }

        }
    }

    setAsOnDateAnalyticData() {
        let lowLevelObject = null;
        if (this.state.activeId.lowLevelObject === "-1") {
            lowLevelObject = this.state.dataArchive.filter((obj) => {
                return obj.highLevelObjectiveId.includes(this.state.activeId.highLevelObject);
            });

        } else {
            lowLevelObject = this.state.dataArchive.find((object) => {
                return object.objectiveDetails.id === this.state.activeId.lowLevelObject;
            });
        }

        if (this._isMount) {
            this.setState({
                data: this.state.dataArchive,
                lowLevelObject: lowLevelObject
            });
        }
    }

    setFilteredAnalyticData() {
        this.getFilterData().then((response) => {

            const analyticsData = response.analyticsData;

            let lowLevelObject = null;
            if (this.state.activeId.lowLevelObject === "-1") {
                lowLevelObject = analyticsData.filter((obj) => {
                    return obj.highLevelObjectiveId.includes(this.state.activeId.highLevelObject);
                });

            } else {
                lowLevelObject = analyticsData.find((object) => {
                    return object.objectiveDetails.id === this.state.activeId.lowLevelObject;
                });
            }

            if (this._isMount) {
                this.setState({
                    data: analyticsData,
                    lowLevelObject: lowLevelObject
                });
            }

        }).catch(error => {
            console.log(error);
        })
    }

    async getData() {
        try {
            const endDate = new Intl.DateTimeFormat('en-GB').format(new Date());
            // console.log(endDate);
            const filterParams = {
                startDate: null,
                endDate: endDate,
                type: "asondate"
            }

            const profileId = localStorage.getItem('jwtProfileId');
            const linksResponse = await axios.get(`${baseUrl}/api/visualization/featuresDetails/${profileId}`);
            const analyticsDataResponse = await axios.post(`${baseUrl}/api/visualization/overallResponse/profile`, {
                profileId: profileId,
                startDate: filterParams.startDate,
                endDate:filterParams.endDate,
                type:filterParams.type
            });

            const links = linksResponse.data;
            const analyticsData = analyticsDataResponse.data;
            // console.log(analyticsData)
            return { links: links, analyticsData: analyticsData };

        } catch (error) {
            console.error(error);
        }
    }

    async getFilterData() {
        try {
            // console.log(this.filterParams);  
            const profileId = localStorage.getItem('jwtProfileId');
            const analyticsDataResponse = await axios.post(`${baseUrl}/api/visualization/overallResponse/profile`, {
                profileId: profileId,
                startDate: this.filterParams.startDate,
                endDate:this.filterParams.endDate,
                type:this.filterParams.type
            });
            const analyticsData = analyticsDataResponse.data;
            return { analyticsData: analyticsData };

        } catch (error) {
            console.error(error);
        }
    }

    componentDidMount() {

        this._isMount = true;

        this.getData().then((response) => {

            const links = response.links;
            const analyticsData = response.analyticsData;

            // console.log({links});

            // nav links initialization
            if (links.feature.length) {
                if (this._isMount) {
                    this.setState({
                        feature: links.feature
                    });
                }
            }

            if (Object.keys(links.activeId).length !== 0 && links.activeId.constructor === Object) {
                if (this._isMount) {
                    this.setState({
                        activeId: links.activeId
                    });
                }
                let activeId = { ...this.state.activeId };
                this.linkProcess(activeId.lowLevelObject, activeId.highLevelObject, activeId.feature);
            } // end nav link initilization


            // data initialization
            if (this._isMount) {
                this.setState({
                    data: analyticsData,
                    dataArchive: analyticsData
                });
            }

            // console.log({ tag: this.tag });

            const lowLevelObjects = analyticsData.filter((obj) => {
                return obj.highLevelObjectiveId.includes(links.activeId.highLevelObject);
            });

            if (this._isMount) {
                this.setState({
                    lowLevelObject: lowLevelObjects
                });
            }


        }).catch((error) => {
            console.log(error);
        })
    }

    componentWillUnmount() {
        this._isMount = false;
    }

    render() {

        const highLevelObject = this.state.links.highLevelObject.find(({ id }) => id === this.state.activeId.highLevelObject);
        let highLevelObjectName = highLevelObject ? highLevelObject.name : '';
        let lowLevelObjectId = this.state.activeId.lowLevelObject ? this.state.activeId.lowLevelObject : "-1";

        return (
            <div className="card" style={styles}>
                <div className="card-body">
                    <div className="Analysis">
                        <TabBar links={this.state.links.feature} sectionName="feature" handleClick={this.handleClick} />
                        <TabBar links={this.state.links.highLevelObject} sectionName="high-level-object" handleClick={this.handleClick} />
                        <TabBar links={this.state.links.lowLevelObject} sectionName="low-level-object" handleClick={this.handleClick} />
                        <Filter title={highLevelObjectName} handleFilterParam={this.handleFilterParam} showFilterData={this.showFilterData} />
                        <Analytic tag={this.tag} data={this.state.lowLevelObject} lowLevelId={lowLevelObjectId} />
                    </div>
                </div>
            </div>
        );
    }
}

export default Tab;