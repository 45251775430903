import React from 'react'

const Device = props => {
    return (
        <label className="dislabel" style={{ margin: '10px' }}>
            {props.item.name}
            <input
                name="active"
                type="checkbox"
                checked={props.item.status === 'Active' ? true : false}
                // checked
                onChange={event => {
                    const target = event.target;
                    const value = target.type === 'checkbox' ? target.checked : target.value;
                    props.setDeviceActive(value, props.index);
                    props.deviceStatusOnClick();
                }
                }
            />
        </label>
    )
}

export default Device