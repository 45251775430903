import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import truncate from 'truncate-html';
import './Line.css';

const CustomSymbol = ({ size, color, borderWidth, borderColor }) => (
  <g>
    <circle
      fill='#fff'
      r={size / 3}
      strokeWidth={borderWidth}
      stroke={borderColor}
    />
    <circle
      r={size / 6}
      strokeWidth={borderWidth}
      stroke={borderColor}
      fill={color}
      fillOpacity={0.35}
    />
  </g>
);

const style = {
  tooltip: {},
};

const theme = {
  background: 'transparent',
  fontFamily: 'sans-serif',
  fontSize: 11,
  textColor: '#333333',
  axis: {
    domain: {
      line: {
        stroke: 'transparent',
        strokeWidth: 1,
      },
    },
    ticks: {
      line: {
        stroke: '#777777',
        strokeWidth: 1,
      },
      text: {
        stroke: '#777777',
        strokeWidth: 0.5,
      },
    },
    legend: {
      text: {
        fontSize: 14,
        fontWeight: 700,
        fill: '#616161',
      },
    },
  },
  grid: {
    line: {
      stroke: '#dddddd',
      strokeWidth: 1,
    },
  },
  legends: {
    text: {
      fill: '#333333',
    },
  },
  labels: {
    text: {
      stroke: '#212121',
      strokeWidth: 0.4,
    },
  },
  markers: {
    lineColor: '#000000',
    lineStrokeWidth: 1,
    text: {},
  },
  dots: {
    text: {},
  },
  tooltip: {
    container: {
      background: 'white',
      color: 'inherit',
      fontSize: 'inherit',
      borderRadius: '2px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
      padding: '5px 5px',
    },
    basic: {
      whiteSpace: 'pre',
      display: 'flex',
      alignItems: 'center',
    },
    table: {},
    tableCell: {
      padding: '3px 5px',
    },
  },
  crosshair: {
    line: {
      stroke: '#000000',
      strokeWidth: 1,
      strokeOpacity: 0.75,
      strokeDasharray: '6 6',
    },
  },
  annotations: {
    text: {
      fontSize: 13,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    link: {
      stroke: '#000000',
      strokeWidth: 1,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    outline: {
      fill: 'none',
      stroke: '#000000',
      strokeWidth: 2,
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
    symbol: {
      fill: '#000000',
      outlineWidth: 2,
      outlineColor: '#ffffff',
    },
  },
};

const styles = {
  border: 'none',
};

const Line = (props) => {
  let title = props.title ? props.title : 'Overall';
  if (props.line[0] !== undefined) {
    title = props.line[0].id;
  }

  const marginBottom = props.marginBottom === null ? '' : ' mb-4';

  const curve = props.curve ? props.curve : 'monotoneX';

  const height = props.height ? props.height : 320;

  const format = props.isParcent ? '.0%' : '0';

  const colorScheme = [
    '#1B998B',
    '#99B2DD',
    '#EF8354',
    '#4F5D75',
    '#F27D7D',
    '#A5668B',
    '#7EAB7E',
  ];

  return (
    <div className={`Line card ${marginBottom}`} style={styles}>
      <div className='card-body'>
        <h6 className='title'>{title}</h6>
        <div style={{ height: `${height}px`, width: '100%' }}>
          <ResponsiveLine
            data={props.line}
            margin={{ top: 20, right: 15, bottom: 70, left: 30 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              stacked: true,
              min: 0,
              max: props.max ? props.max : 'auto',
            }}
            axisTop={null}
            axisRight={null}
            theme={theme}
            curve={curve}
            lineWidth={2}
            axisLeft={{
              orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: -90,
              legend: '',
              legendPosition: 'middle',
              legendOffset: -40,
              renderTick: ({
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y,
              }) => {
                return (
                  <g transform={`translate(${x},${y + 5})`}>
                    <text
                      alignmentBaseline={textBaseline}
                      textAnchor={textAnchor}
                      transform={`translate(${textX},${textY})`}
                      transform={`rotate(-90)`}
                      fontSize={`10px`}
                      stroke='#616161'
                      strokeWidth='.2'
                    >
                      <tspan>{truncate(value, 8)}</tspan>
                    </text>
                  </g>
                );
              },
            }}
            colors={colorScheme}
            enablePointLabel={false}
            pointSymbol={CustomSymbol}
            pointSize={15}
            pointBorderWidth={1}
            pointBorderColor={{
              from: 'color',
              modifiers: [['darker', 0.3]],
            }}
            enableArea={props.area}
            areaOpacity={0.2}
            pointLabel='y'
            pointLabelYOffset={-12}
            useMesh={true}
            tooltip={({ point }) => {
              return (
                <div className='Line-tooltip'>
                  <h6>{point.serieId}</h6>
                  {point.data.x} : {point.data.y}
                </div>
              );
            }}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                justify: false,
                translateX: 0,
                translateY: 73,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemBackground: 'rgba(0, 0, 0, .03)',
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Line;
