import Axios from 'axios' 
import {
    CREATE_DEPT, GET_ALL_DEPT, GET_SINGLE_DEPT, 
    UPDATE_DEPT, DELETE_DEPT, BACK_BUTTON_IN_DETAIL_PAGE
} from './actionTypes'

import {
    CreateDepartment, GetDepartments, 
    GetSingleDepartment, UpdateDepartment 
} from '../../../../../url/prmUrl'


export const createDept = (values, CreateToast, ErrorToast) => dispatch => { 
    Axios.post(CreateDepartment, values) 
        .then(res => { dispatch({ type: CREATE_DEPT, payload: res.data }); CreateToast() }) 
        .catch(err => ErrorToast()) 
} 


export const getAllDept = (ErrorToast) => dispatch => { 
    Axios.get(GetDepartments) 
        .then(res => { 
            dispatch({type: GET_ALL_DEPT, payload: res.data}) 
        }) 
        .catch(err => ErrorToast()) 
} 


export const getSingleDept = (id, ErrorToast) => dispatch => { 
    Axios.get(GetSingleDepartment(id)) 
        .then(res => { dispatch({type: GET_SINGLE_DEPT, payload: res.data}) }) 
        .catch(err => { ErrorToast() }) 
} 


export const updateDept = (id, values, UpdateToast, ErrorToast) => dispatch => { 
    Axios.patch(UpdateDepartment(id), values) 
        .then(res => { dispatch({type: UPDATE_DEPT, payload: res.data}); UpdateToast(); }) 
        .catch( err => ErrorToast() ) 
} 


export const deleteDept = (state) => dispatch => { 
    dispatch({type: DELETE_DEPT, payload: {state}}) 
} 


export const backButtonFunction = () => dispatch => { 
    dispatch({type: BACK_BUTTON_IN_DETAIL_PAGE}) 
} 


