import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: auto;
`

export const PreviewBody = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin: 30px;
  box-shadow: 2px 2px 10px 2px grey;
`

export const SaveButton = styled.button`
  display: block;
  position: fixed;
  z-index: 20;
  width: 60px;
  height: 60px;
  top: 85px;
  right: 100px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: auto;
  margin: 0;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: green;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 3px 1px grey;
  :hover{
    box-shadow: 2px 2px 5px 2px grey;
  }
`

export const SubmitButton = styled.button`
  display: block;
  position: fixed;
  z-index: 20;
  width: 60px;
  height: 60px;
  top: 85px;
  right: 100px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: auto;
  margin: 0;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: green;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 3px 1px grey;
  :hover{
    box-shadow: 2px 2px 5px 2px grey;
  }
`

export const DistributeButton = styled.button`
  display: block;
  position: fixed;
  z-index: 20;
  font-size: 10px;
  width: 60px;
  height: 60px;
  top: 85px;
  right: 100px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 50%;
  border: none;
  outline: none;
  padding: auto;
  margin: 0;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  background: #1b9989;
  color: white;
  cursor: pointer;
  box-shadow: 0 0 3px 1px grey;
  :hover{
    box-shadow: 2px 2px 5px 2px grey;
  }
  :disabled {
    background: #ec3b3b;
  }
`

export const PreviewButton = styled(SaveButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: orange;
  text-decoration: none;
  :hover{
    color: white;
  }
`