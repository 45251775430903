import React from 'react';
import { connect } from 'react-redux'
import styled from 'styled-components/macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap';

import { UploadContainer, ImgPreview, Upload, UploadButton } from '../styles'

import { addImage } from '../../../redux/actions'
import { imageDataSelector } from '../../../redux/selectors'
import baseUrl from '../../../../../../src/url'
import axios from 'axios'
import Modal from './Modal'

const ImageUpload = props => {

  const { imageData, addImage } = props

  // function handleImageChange(event) {
  //   event.preventDefault();
  //   const formData = new FormData();
  //   formData.append('file', event.target.files[0]);
  //   formData.append('method', 'PATCH')
  //   axios.patch(`${baseUrl}/api/distribution/upload/logo/${props.disId}`, formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data'
  //     }
  //   }).then(response => {
  //     addImage(response.data.logo)
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }
  console.log(typeof (imageData), imageData)
  let $imagePreview = null;
  if (imageData) {
    $imagePreview = (
      <img
        src={imageData}
        css={`
          width: 100%;
          height: 100%;
        `}
      />
    );
  }

  function handleDeleteChange() {

    axios.delete(`${baseUrl}/api/distribution/logo/${props.disId}`).then(response => {
      // console.log(response)
      // props.addImage(response.data.logo)
      props.addImage("")
    }).catch(error => {
      console.log(error);
    });
    // console.log(formData)

  }

  return (
    <>
      {/* <form onSubmit={(e)=>this.handleSubmit(e)}> */}
      {imageData && <UploadContainer style={{ right: '40px' }}>
        <Button style={{ backgroundColor: 'transparent', border: '0 solid', padding: '3px 4px' }} onClick={handleDeleteChange}>
          <FontAwesomeIcon
            icon={faTrashAlt}
            css={`font-size:20px; color: #310D31;`}
          />
        </Button>
      </UploadContainer>}
      <UploadContainer>

        <Modal buttonLabel={<FontAwesomeIcon
          icon={faCamera}
          css={`font-size:20px; color: #310D31;`}
        />} disId={props.disId} />
      </UploadContainer>

      {/* <UploadContainer>
        <Upload type="file" name="user_image" onChange={handleImageChange} />
        <UploadButton css={`background-color: rgba(0, 0, 0, 0);`} >
          <FontAwesomeIcon
            icon={faCamera}
            css={`font-size:20px; color: #310D31;`}
          />
         
        </UploadButton>


      </UploadContainer> */}

      {/* <SubmitButton>
        <button
          type="submit" 
          onClick={(e)=>this.handleSubmit(e)}>
          Upload Image
        </button>
      </SubmitButton> */}

      {/* </form> */}

      <ImgPreview>
        {$imagePreview}
      </ImgPreview>
    </>

  )
}

const mapStateToProps = state => ({
  imageData: imageDataSelector(state),
  disId: state.question.distributionId
})

const mapDispatchToProps = {
  addImage: (data) => addImage(data)
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
