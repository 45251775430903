import {
    CREATE_PROJECT_FOR_SURVEY,
    SURVEY_PROJECT_STEP,
    SURVEY_CONFIG_STEP,
    SURVEY_PREVIEW_STEP,
    SURVEY_CHANNEL_STEP,
    SET_SURVEY_DISTRIBUTION,
    SHOW_TOAST,
    HIDE_TOAST,
    RESET_STORE
} from '../actions/const'

const surveyReducer = (
    state = {
        userId: "",
        projectId: "",
        projectName: "",
        featureId: {},
        question: [],
        selected: '',
        active: true,
        objectives: [],
        toast: {
            status: false,
            message: ""
        },
        loading: false,
        error: {
            title: false,    //[{'mul':false},{'check':false},{'likert':false},{'text':false}],
            choices: false,
            options: false,
            ratings: false,
            noData: []
        },
        surveyProject: false,
        surveyConfig: false,
        surveyPreview: false,
        surveyChannel: false,
        distributionId: ''
    },
    action
) => {
    switch (action.type) {
        case 'CREATE_PROJECT_FOR_SURVEY':
            return {
                ...state,
                projectId: action.payload._id,
                projectName: action.payload.name,
                active: action.payload.active,
                featureId: action.payload.featureinfo,

            }
        case SURVEY_PROJECT_STEP: {
            return {
                ...state,
                surveyProject: true
            }
        }
        case SURVEY_CONFIG_STEP: {
            return {
                ...state,
                surveyConfig: true
            }
        }
        case SURVEY_PREVIEW_STEP: {
            return {
                ...state,
                surveyPreview: true
            }
        }
        case SURVEY_CHANNEL_STEP: {
            return {
                ...state,
                surveyChannel: true
            }
        }
        case SET_SURVEY_DISTRIBUTION: {
            return {
                ...state,
                distributionId: action.payload
            }
        }
        case SHOW_TOAST:
            const { message } = action.payload
            return {
                ...state,
                toast: {
                    status: true,
                    message: `${message}`
                }
            }

        case HIDE_TOAST:
            return {
                ...state,
                toast: {
                    status: false,
                    message: ""
                },
                saveDisabled: false
            }

        case RESET_STORE: {
            return {
                userId: "",
                projectId: "",
                projectName: "",
                featureId: {},
                question: [],
                selected: '',
                active: true,
                objectives: [],
                toast: {
                    status: false,
                    message: ""
                },
                loading: false,
                error: {
                    title: false,    //[{'mul':false},{'check':false},{'likert':false},{'text':false}],
                    choices: false,
                    options: false,
                    ratings: false,
                    noData: []
                },
                surveyProject: false,
                surveyConfig: false,
                surveyPreview: false,
                surveyChannel: false,
                distributionId: ''
            }
        }

        default:
            return { ...state }
    }
}

export default surveyReducer