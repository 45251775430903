import React from 'react'; 
import { BrowserRouter, Route, Switch, withRouter } from 'react-router-dom'; 
import Brand from './Brand'; 
import Details from './components/details/details'; 
import { Provider } from 'react-redux'; 
import store from './store/index'; 
import CreateBrand from './components/create/createBrand'

const Routes = props => { 
  let { match } = props; 

  return ( 
    <BrowserRouter> 
      <Switch> 
        <Route exact path={`${match.path}`} component={Brand} /> 
        <Route path={`${match.path}/create`} component={CreateBrand} /> 
        <Route path={`${match.path}/:id`} component={Details} />  
      </Switch> 
    </BrowserRouter> 
  ) 
} 

const ReduxConnectedRoutes = withRouter(Routes) 



function Index() { 
  return ( 
    <Provider store={store}> 
      <ReduxConnectedRoutes /> 
    </Provider> 
  ) 
} 

export default Index; 


















// --------------------------------------------------------------------------
// --------------------------------------------------------------------------
// --------------------------------------------------------------------------


// import React, { useState, useEffect, memo } from 'react';
// import { Link, Switch, Route, Redirect } from 'react-router-dom'
// import styled from 'styled-components/macro'
// import axios from 'axios';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {faSave } from '@fortawesome/free-solid-svg-icons'
// import Select from 'react-select'
// import * as Yup from 'yup'
// import { withFormik } from 'formik'
// import {FetchAllBrandCategory, FetchAllCompany, FetchAllBrand, AddNewBrand} from '../../../url/prmUrl';
// import Alert from '../ShowAlert'

// import { Button, Input, Label, Body, Error, Aside, Modal } from '../StyledComponents'

// let brandCategoryOptions = []
// let companyOptions = []

// const Form = props => {

//   const {
//     values,
//     touched,
//     errors,
//     handleChange,
//     handleBlur,
//     handleSubmit,
//     setFieldValue,    // for custom call and change
//     setFieldTouched,  //for custom call and change
//   } = props;

//   return (
//     <form onSubmit={handleSubmit} css={`width: 100%`}>
//           <Aside>
//             <section>
//             {errors.brandName && touched.brandName ? <Error>{errors.brandName}</Error> : null}
//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Name:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <Input
//                     name="brandName"
//                     type="text"
//                     placeholder="Brand Name"
//                     value={values.brandName}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>

//               {errors.brandCategoryId && touched.brandCategoryId ? <Error>{errors.brandCategoryId}</Error> : null}
//               <Label>              
//                   <div css={`width: 30%;text-align:left;`}>
//                     Category:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
//                     name="brandCategoryId"
//                     value={values.brandCategoryId}
//                     onChange={setFieldValue}
//                     onBlur={setFieldTouched}
//                     touched={touched.brandCategoryId}
//                   /> 
//                 </div>
//               </Label>
//               {errors.companyId && touched.companyId ? <Error>{errors.companyId}</Error> : null}
//               <Label>              
//                   <div css={`width: 30%;text-align:left;`}>
//                     Company:
//                 </div>
//                 <div css={`width: 60%`}>
//                   <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
//                     name="companyId"
//                     value={values.companyId}
//                     onChange={setFieldValue}
//                     onBlur={setFieldTouched}
//                     touched={touched.companyId}
//                   /> 
//                 </div>
//               </Label>

//               <Label>
//                 <div css={`width: 30%;text-align:left;`}>
//                   Active:
//                 </div>
//                 <div css={'width:15px'}>
//                   <Input
//                     name="status"
//                     type="checkbox"
//                     css={`width:18px;height:18px`}
//                     checked={values.status}
//                     onChange={handleChange}
//                     onBlur={handleBlur}
//                   />
//                 </div>
//               </Label>
//             </section>
//         </Aside>
//         <Button type="submit">
//           <FontAwesomeIcon
//             icon={faSave}
//             css={`margin-right:10px`}
//           />
//           Create
//         </Button>
//     </form>
//   )

// }

// // -------------------------------------------------------------------
// // -------------------------------------------------------------------



// class MySelect extends React.Component {
//   handleChange = value => {
//     this.props.name==="brandCategoryId" ? this.props.onChange("brandCategoryId", value) : this.props.onChange("companyId", value);
//   };

//   handleBlur = () => {
//    this.props.name==="brandCategoryId" ? this.props.onBlur("brandCategoryId", true) : this.props.onBlur("companyId", true)
//   };

//   render() {
//     return (
//         <Select
//           id="color"
//           placeholder={this.props.name==="brandCategoryId" ? "Select Category" : "Select Company"}
//           options={this.props.name==="brandCategoryId" ? brandCategoryOptions : companyOptions}
//           //isMulti
//           onChange={this.handleChange}  //calls the handleChange in MySelect class
//           onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
//           value={this.props.value}      // gets the selected value, which is eventually options.value
//         />

//     );
//   }
// }


// // -------------------------------------------------------------------
// // -------------------------------------------------------------------





// const Brand = props => {
//   const [view, setView] = useState(false)
//   const [editable, setEditable] = useState({
//     loaded: '',
//     fetched: false,
//     redirect: false
//   })

//   useEffect(() => {

//     const fetchData = async () => {

//       try{
//       const fetchBrandCategory = await axios(FetchAllBrandCategory);
//       brandCategoryOptions = fetchBrandCategory.data.map(element=>({label: element.name, value: element._id}))
      
//       const fetchCompany= await axios(FetchAllCompany(localStorage.getItem("jwtID")));
//       companyOptions = fetchCompany.data.map(element=>({label: element.companyName, value: element._id}))
//      }
//      catch (err) {
//       // console.log(err)
//      }

//       await axios(FetchAllBrand(localStorage.getItem("jwtID")))
//       .then(result=>{
//         setEditable(prev=>{  
//           return {
//             ...prev,
//             loaded: result.data
//           }
//         })
//       })
//       .catch(err=>{
//         // console.log("err")
//       })
      

//     };

//     if(view===false){
//       fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
//     }
//   }, [view]);

//   const brand = editable.loaded ?
//                   editable.loaded.Error? [] :
//                   editable.loaded.map(item=>item.brandName) : []

//   const schema = Yup.object().shape({
//     brandName: Yup.string().required("Must Define a Name")
//                            .min(3, "Minimum 3 characters")
//                            .notOneOf(brand, "Already exists"),
//     brandCategoryId: Yup.string().required("Please select Category..."),
//     companyId: Yup.string().required("Please select Company...")
//   })

//   const FormikForm = withFormik({
//     validationSchema: schema,
//     mapPropsToValues: () => 
//     ({ 
//       createdByCompanyID: localStorage.getItem("jwtID"),
//       brandName:"" , 
//       brandCategoryId: '',
//       companyId: '',
//       status: true
//     }),

//     handleSubmit: (values, { setSubmitting }) => {
//       values.brandCategoryId=values.brandCategoryId.value
//       values.companyId=values.companyId.value
//       // console.log(localStorage.getItem("jwtID"))
//       axios.post(AddNewBrand, values)
//       .then((response) => {
//         if(!response.data.Error){
//           setView(true)
//         }
//         else {
//           window.alert("Uexpected Error! Duplicate Name found!")
//         }
//       })
//       .catch((err) => {
//         // console.log("Error")
//       })

//     }
//   })(Form)


//   return (
//     <React.Fragment>
//         <Body>
//         <Modal
//               view={view}
//               onClick={event => {
//                 event.preventDefault()
//                 if (event.target === event.currentTarget)
//                 {
//                   setView(false)
//               }
//               }}
//             >
//               <Alert close={setView}/>
//             </Modal>
//           <FormikForm/>
//         </Body>
//       {/* {editable.redirect === false ? <AddNewUser /> :
//         <Redirect
//           to={{
//             pathname: "/app/users"
//           }}
//         />
//       } */}
//     </React.Fragment>
//   )
// }

// export default memo(Brand); 