import {SUBMIT, SUBMIT_FAILURE, SUBMIT_SUCCESS, SET_PREVIEW} from '../actions/const'

const submitReducer = (
  state={
    isSubmitting: false,
    isSuccess: false,
    // isPreview: false
  }, 
  action
)=>{
  switch(action.type){
    
    case SUBMIT:{
      return{
        ...state,
        isSubmitting: true
      }
    }
    
    case SUBMIT_SUCCESS:{
      return {
        ...state,
        isSubmitting: false,
        isSuccess: true
      }
    }

    case SUBMIT_FAILURE:{
      return {
        ...state,
        isSubmitting: false,
        isSuccess: false
      }
    }

    // case SET_PREVIEW:{
    //   const {isPreview} = action
    //   return{
    //     ...state,
    //     isPreview
    //   }
    // }

    default: 
      return state
  }
}

export default submitReducer