import React, { useState, useEffect } from 'react';
import { Tabs, Tab, TabPanel, TabList } from 'react-web-tabs';
import axios from 'axios';
import baseUrl, { pexUrl } from '../../../../url/index';
import { connect } from 'react-redux';
import collect from 'collect.js';

import './style.css';
import DeviceWrapper from '../../components/Distribution/DeviceWrapper';
import Device from '../../components/Distribution/Device';
import QrCode from '../../components/Distribution/QrCode';
import ActiveChannelOpenLink from '../../components/ActiveChannel/OpenLink';
import ActiveChannelQrCode from '../../components/ActiveChannel/QrCode';
import {
  sortQuestion,
  sortProjects,
  addCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  updateOpenLink,
  setOpenLinkInDistribution,
  initQrCode,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  // setProgressbar,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
} from '../../redux/actions';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import {
  apiDataGeneratorSelector,
  activeDeviceSelector,
  saveButtonSelector,
  deviceListsSelector,
} from '../../redux/selectors';
import '../../../../../node_modules/react-web-tabs/dist/react-web-tabs.css';
import EditStep from '../../components/EditStep';

import styled from 'styled-components';
import showToast from '../../../../components/Toast/toast';
import QRCode from 'qrcode.react';
import Email from '../../components/ActiveChannel/Email/Email';

const DistributionOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 100px;

  button {
    background: transparent;
    border: none;
    outline: none;
    padding: 15px 0px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:focus {
      outline: none;
    }

    &:hover,
    &.active {
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
  }
`;

const DistributeItemContainer = styled.div`
  background: #eff3f4;
  border-radius: 10px;
  margin: 20px 50px;
`;

const OpenLinkButton = styled.button`
  background: transparent;
  border: none;
  outline: none;
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
  }
`;

const OpenLinkLink = styled.a`
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  &:focus {
    outline: none;
  }
`;

const QRDownloadButton = styled.button`
  background: transparent;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 20px;
  border-radius: 5px;
  margin: 10px 0px;

  &:focus {
    outline: none;
  }
`;

const distOptions = [
  {
    name: 'Device',
    value: 'device',
  },
  {
    name: 'Open Link',
    value: 'openlink',
  },
  {
    name: 'QR Code',
    value: 'qrcode',
  },
  {
    name: 'Email',
    value: 'email',
  },
];

const SortAndPreview = (props) => {
  const {
    sortQuestion,
    sortProjects,
    addCard,
    dataToPush,
    activeChannels,
    pushConfiguration,
    updateConfiguration,
    distributeConfiguration,
    enableSave,
    setProfileIdInState,
    disId,
    fetchChannels,
    statusDevice,
    fetchDeviceLists,
    activeDevice,
    deviceLists,
    setDeviceActive,
    setDistDevice,
    status,
    statusOpenLink,
    initOpenLink,
    setOpenLink,
    updateOpenLink,
    setOpenLinkInDistribution,
    openlinks,
    initQrCode,
    setQRCode,
    statusQrCode,
    qRCode,
    setGenQrCode,
    setQrCodeInDistribution,
    order,
    progressbar,
    createProjectForSurveyOnReload,
    editDistributionQuestion,
    editDistributionConfig,
    selectProjectAndFetch,
    setActiveChannel,
    projectFetch,
    setSurveyProjectStep,
    setSurveyChannelStep,
    setSurveyPreviewStep,
    setSurveyConfigStep,
  } = props;
  const enablePreview = !enableSave;

  const [profileId, setProfileId] = useState(
    localStorage.getItem('jwtProfileId')
  );
  const [deviceStatus, setDeviceStatus] = useState(false);
  const [linkStatus, setLinkStatus] = useState(false);
  const [qrCodeStatus, setQrCodeStatus] = useState(false);

  const [selectedDist, setSelectedDist] = useState('device');

  const openlink = {
    name: 'Open link',
    createdByCompanyID: profileId,
    status: linkStatus,
    distributionId: disId,
  };

  const qrCode = {
    name: 'QR Code',
    createdByCompanyID: profileId,
    status: qrCodeStatus,
    distributionId: disId,
  };

  useEffect(() => {
    const fetchDistDataOnReload = async (surveyid) => {
      const disId = await axios.get(`${baseUrl}/api/project/${surveyid}`);
      createProjectForSurveyOnReload(disId.data);
      setSurveyProjectStep();

      const disid = disId.data.distributionId;
      if (disid) {
        axios
          .get(`${baseUrl}/api/distribution/details/${disid}`)
          .then((res) => {
            editDistributionQuestion(res.data, disid);
            editDistributionConfig(res.data);
            selectProjectAndFetch(
              res.data.question.questionBank.projectOrder[0]
            );
            projectFetch(localStorage.getItem('jwtProfileId'));

            if ('activeChannels' in res.data) {
              setActiveChannel(res.data.activeChannels);
            }

            if (res.data.status === 'DRAFT') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
            }
            if (res.data.status === 'SUBMITTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              setSurveyChannelStep();
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
            if (res.data.status === 'DISTRIBUTED') {
              setSurveyConfigStep();
              setSurveyPreviewStep();
              setSurveyChannelStep();
              fetchChannels(localStorage.getItem('jwtProfileId'));
            }
          });
      }
    };

    if (disId === '') {
      fetchDistDataOnReload(props.match.params.surveyid);
    }
  }, [disId]);

  useEffect(() => {
    setProfileIdInState(profileId);
    // setSurveyChannelStep()
  }, []);

  const deviceStatusOnClick = () => {
    let deviceStatus = collect(deviceLists)
      .where('status', '=', 'Active')
      .isNotEmpty();
    setDeviceStatus(deviceStatus);
  };

  const setOpenLinkStatus = (value) => {
    setLinkStatus(value);
  };

  const setQrCode = (value) => {
    setQrCodeStatus(value);
  };

  const genQrCode = (id) => {
    setQrCodeInDistribution(id, { id: qRCode._id, hashId: qRCode.hashId });
  };

  const downloadQR = (value) => {
    const imageFile = `${value}.png`;
    const canvas = document.getElementById('qrcode');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = imageFile;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div className='container'>
      <EditStep />

      <div className='distribution_tabs'>
        <DistributionOptions>
          {distOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => setSelectedDist(option.value)}
              className={selectedDist === option.value ? 'active' : ''}
            >
              {option.name}
            </button>
          ))}
        </DistributionOptions>

        <DistributeItemContainer>
          {selectedDist === 'device' && (
            <div>
              <DeviceWrapper
                profileId={profileId}
                channel={statusDevice}
                fetchDeviceLists={fetchDeviceLists}
              >
                <p
                  style={{
                    margin: '0px',
                    padding: '15px 25px',
                    fontWeight: 500,
                  }}
                >
                  Choose Device
                </p>

                <hr style={{ margin: 0 }} />

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    padding: '15px 25px 0px 25px',
                  }}
                >
                  {deviceLists.length > 0 &&
                    deviceLists.map((item, index) => (
                      <Device
                        deviceStatusOnClick={deviceStatusOnClick}
                        index={index}
                        item={item}
                        key={item._id}
                        setDeviceActive={setDeviceActive}
                      />
                    ))}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    padding: '0px 25px 15px 25px',
                  }}
                >
                  <button
                    style={{
                      background: 'rgb(27, 153, 139)',
                      color: 'white',
                      border: 'none',
                      outline: 'none',
                      padding: '7px 20px',
                      borderRadius: '5px',
                      fontSize: '14px',
                    }}
                    onClick={() =>
                      setDistDevice({
                        profileId: localStorage.getItem('jwtProfileId'),
                        distributionId: disId,
                        deviceList: activeDevice,
                      })
                    }
                  >
                    Save
                  </button>
                </div>
              </DeviceWrapper>
            </div>
          )}

          {selectedDist === 'openlink' && (
            <div
              style={{
                padding: '15px 25px',
              }}
            >
              {statusOpenLink !== undefined &&
                !('openlink' in activeChannels) && (
                  <button
                    style={{
                      background: 'rgb(27, 153, 139)',
                      color: 'white',
                      border: 'none',
                      outline: 'none',
                      padding: '7px 20px',
                      borderRadius: '5px',
                      fontSize: '14px',
                    }}
                    onClick={() =>
                      setOpenLink({
                        createdByCompanyID:
                          localStorage.getItem('jwtProfileId'),
                        active: true,
                        distributionId: disId,
                        link: `${pexUrl}/openlink`,
                      })
                    }
                    disabled={openlinks.hasOwnProperty('link') ? true : false}
                  >
                    Generate Open Link
                  </button>
                )}

              {(openlinks.hasOwnProperty('link') ||
                (status === 'DISTRIBUTED' && 'openlink' in activeChannels)) && (
                <>
                  <div
                    style={{
                      background: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '10px 20px',
                      borderRadius: '5px',
                      marginTop: '10px',
                    }}
                  >
                    <span
                      style={{
                        marginRight: '10px',
                      }}
                    >
                      {/* {`${pexUrl}/openlink/${activeChannels.openlink.hashId}`} */}
                      {activeChannels.openlink && activeChannels.openlink.hashId
                        ? `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                        : openlinks.link}
                    </span>

                    <div
                      style={{
                        borderLeft: '1px solid #ccc',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '10px',
                        paddingLeft: '20px',
                      }}
                    >
                      <OpenLinkButton
                        onClick={() => {
                          navigator.clipboard.writeText(
                            // `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                            activeChannels.openlink &&
                              activeChannels.openlink.hashId
                              ? `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                              : openlinks.link
                          );
                          showToast('Link copied to clipboard');
                        }}
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          style={{ width: '28px', height: '28px' }}
                          fill='none'
                        >
                          <path
                            d='M10 8V7C10 6.05719 10 5.58579 10.2929 5.29289C10.5858 5 11.0572 5 12 5H17C17.9428 5 18.4142 5 18.7071 5.29289C19 5.58579 19 6.05719 19 7V12C19 12.9428 19 13.4142 18.7071 13.7071C18.4142 14 17.9428 14 17 14H16M7 19H12C12.9428 19 13.4142 19 13.7071 18.7071C14 18.4142 14 17.9428 14 17V12C14 11.0572 14 10.5858 13.7071 10.2929C13.4142 10 12.9428 10 12 10H7C6.05719 10 5.58579 10 5.29289 10.2929C5 10.5858 5 11.0572 5 12V17C5 17.9428 5 18.4142 5.29289 18.7071C5.58579 19 6.05719 19 7 19Z'
                            stroke='#000'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          />
                        </svg>
                      </OpenLinkButton>

                      <OpenLinkLink
                        href={
                          // `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                          activeChannels.openlink &&
                          activeChannels.openlink.hashId
                            ? `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                            : openlinks.link
                        }
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 30 30'
                          style={{ width: '25px', height: '25px' }}
                        >
                          <path d='M 25.980469 2.9902344 A 1.0001 1.0001 0 0 0 25.869141 3 L 20 3 A 1.0001 1.0001 0 1 0 20 5 L 23.585938 5 L 13.292969 15.292969 A 1.0001 1.0001 0 1 0 14.707031 16.707031 L 25 6.4140625 L 25 10 A 1.0001 1.0001 0 1 0 27 10 L 27 4.1269531 A 1.0001 1.0001 0 0 0 25.980469 2.9902344 z M 6 7 C 4.9069372 7 4 7.9069372 4 9 L 4 24 C 4 25.093063 4.9069372 26 6 26 L 21 26 C 22.093063 26 23 25.093063 23 24 L 23 14 L 23 11.421875 L 21 13.421875 L 21 16 L 21 24 L 6 24 L 6 9 L 14 9 L 16 9 L 16.578125 9 L 18.578125 7 L 16 7 L 14 7 L 6 7 z' />
                        </svg>
                      </OpenLinkLink>
                    </div>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <FacebookShareButton
                      url={activeChannels.openlink}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <FacebookIcon size={32} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={activeChannels.openlink}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <TwitterIcon size={32} />
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={activeChannels.openlink}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <LinkedinIcon size={32} />
                    </LinkedinShareButton>
                  </div>
                </>
              )}
            </div>
          )}

          {selectedDist === 'qrcode' && (
            <div
              style={{
                padding: '15px 25px',
              }}
            >
              {statusQrCode !== undefined && !('qrcode' in activeChannels) && (
                <button
                  style={{
                    background: 'rgb(27, 153, 139)',
                    color: 'white',
                    border: 'none',
                    outline: 'none',
                    padding: '7px 20px',
                    borderRadius: '5px',
                    fontSize: '14px',
                  }}
                  onClick={() =>
                    setQRCode({
                      createdByCompanyID: localStorage.getItem('jwtProfileId'),
                      active: true,
                      distributionId: disId,
                      link: `${pexUrl}/qrcode`,
                    })
                  }
                  disabled={qRCode.hasOwnProperty('link') ? true : false}
                >
                  Generate QR Code
                </button>
              )}

              {(qRCode.hasOwnProperty('hashId') ||
                (status === 'DISTRIBUTED' && 'qrcode' in activeChannels)) && (
                <div
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <QRCode
                    id='qrcode'
                    // value={`${pexUrl}/qrcode/${activeChannels.qrcode.hashId}`}
                    value={
                      activeChannels.qrcode && activeChannels.qrcode.hashId
                        ? `${pexUrl}/qrcode/${activeChannels.qrcode.hashId}`
                        : qRCode.link
                    }
                    size={200}
                    level={'H'}
                    includeMargin={true}
                  />

                  <QRDownloadButton onClick={() => downloadQR(disId)}>
                    Download
                  </QRDownloadButton>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '10px',
                      marginTop: '10px',
                    }}
                  >
                    <FacebookShareButton
                      url={qRCode.link}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <FacebookIcon size={32} />
                    </FacebookShareButton>

                    <TwitterShareButton
                      url={qRCode.link}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <TwitterIcon size={32} />
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={qRCode.link}
                      style={{
                        borderRadius: '5px',
                        overflow: 'hidden',
                      }}
                    >
                      <LinkedinIcon size={32} />
                    </LinkedinShareButton>
                  </div>
                </div>
              )}
            </div>
          )}

          {selectedDist === 'email' && (
            <Email
              link={
                activeChannels.openlink && activeChannels.openlink.hashId
                  ? `${pexUrl}/openlink/${activeChannels.openlink.hashId}`
                  : openlinks.link
                  ? openlinks.link
                  : ''
              }
            />
          )}
        </DistributeItemContainer>
      </div>
    </div>
  );

  // return (
  //   <div className='container'>
  //     <EditStep />

  //     <Tabs defaultTab='vertical-tab-one' vertical>
  //       <TabList>
  //         <Tab tabFor='vertical-tab-one'>
  //           <h4>Device</h4>
  //         </Tab>
  //         <Tab tabFor='vertical-tab-two'>
  //           <h4>Open Link</h4>
  //         </Tab>
  //         <Tab tabFor='vertical-tab-three'>
  //           <h4>QR Code</h4>
  //         </Tab>
  //       </TabList>
  //       <TabPanel tabId='vertical-tab-one'>
  //         {statusDevice !== undefined && (
  //           <DeviceWrapper
  //             profileId={profileId}
  //             channel={statusDevice}
  //             fetchDeviceLists={fetchDeviceLists}
  //           >
  //             {deviceLists.length > 0 &&
  //               deviceLists.map((item, index) => (
  //                 <Device
  //                   deviceStatusOnClick={deviceStatusOnClick}
  //                   index={index}
  //                   item={item}
  //                   key={item._id}
  //                   setDeviceActive={setDeviceActive}
  //                 />
  //               ))}
  //           </DeviceWrapper>
  //         )}
  //         {statusDevice !== undefined && (
  //           <button
  //             className='btn btn-success btn-sm'
  //             onClick={() =>
  //               setDistDevice({
  //                 profileId: localStorage.getItem('jwtProfileId'),
  //                 // "active": true,
  //                 distributionId: disId,
  //                 // "link": `${pexUrl}/openlink`,
  //                 deviceList: activeDevice,
  //               })
  //             }
  //             // disabled={openlinks.hasOwnProperty('link') ? true : false}
  //             style={{ background: 'rgb(27, 153, 139)' }}
  //           >
  //             Distribute to Selected Device
  //           </button>
  //         )}
  //       </TabPanel>
  //       <TabPanel tabId='vertical-tab-two'>
  //         {statusOpenLink !== undefined && !('openlink' in activeChannels) && (
  //           <button
  //             className='btn btn-success btn-sm'
  //             onClick={() =>
  //               setOpenLink({
  //                 createdByCompanyID: localStorage.getItem('jwtProfileId'),
  //                 active: true,
  //                 distributionId: disId,
  //                 link: `${pexUrl}/openlink`,
  //               })
  //             }
  //             disabled={openlinks.hasOwnProperty('link') ? true : false}
  //             style={{ background: 'rgb(27, 153, 139)' }}
  //           >
  //             Generate Open Link
  //           </button>
  //         )}

  //         {openlinks.hasOwnProperty('link') && (
  //           <>
  //             <a
  //               href={openlinks.link}
  //               target='_blank'
  //               style={{
  //                 fontSize: '.7em',
  //                 wordBreak: 'break-all',
  //                 color: 'rgba(0,0,0,.8)',
  //                 fontWeight: '500',
  //                 margin: '10px 0',
  //                 display: 'block',
  //               }}
  //             >
  //               {openlinks.link}
  //             </a>
  //             <FacebookShareButton url={openlinks.link}>
  //               <FacebookIcon size={32} />
  //             </FacebookShareButton>
  //             <TwitterShareButton url={openlinks.link}>
  //               <TwitterIcon size={32} />
  //             </TwitterShareButton>
  //             <LinkedinShareButton url={openlinks.link}>
  //               <LinkedinIcon size={32} />
  //             </LinkedinShareButton>
  //           </>
  //         )}

  //         {status === 'DISTRIBUTED' && 'openlink' in activeChannels && (
  //           <div className='channel-wrap'>
  //             <ActiveChannelOpenLink openlink={activeChannels.openlink} />
  //             <FacebookShareButton url={activeChannels.openlink}>
  //               <FacebookIcon size={32} />
  //             </FacebookShareButton>
  //             <TwitterShareButton url={activeChannels.openlink}>
  //               <TwitterIcon size={32} />
  //             </TwitterShareButton>
  //             <LinkedinShareButton url={activeChannels.openlink}>
  //               <LinkedinIcon size={32} />
  //             </LinkedinShareButton>
  //           </div>
  //         )}
  //       </TabPanel>

  //       <TabPanel tabId='vertical-tab-three'>
  //         {statusQrCode !== undefined && !('qrcode' in activeChannels) && (
  //           <button
  //             className='btn btn-success btn-sm'
  //             onClick={() =>
  //               setQRCode({
  //                 createdByCompanyID: localStorage.getItem('jwtProfileId'),
  //                 active: true,
  //                 distributionId: disId,
  //                 link: `${pexUrl}/qrcode`,
  //               })
  //             }
  //             disabled={qRCode.hasOwnProperty('link') ? true : false}
  //             style={{ background: 'rgb(27, 153, 139)' }}
  //           >
  //             Generate QR Code
  //           </button>
  //         )}

  //         {qRCode.hasOwnProperty('hashId') && (
  //           <>
  //             <QrCode
  //               setQrCode={setQrCode}
  //               disId={disId}
  //               qRCode={qRCode}
  //               genQrCode={genQrCode}
  //             ></QrCode>
  //             <FacebookShareButton url={qRCode.link}>
  //               <FacebookIcon size={32} />
  //             </FacebookShareButton>
  //             <TwitterShareButton url={qRCode.link}>
  //               <TwitterIcon size={32} />
  //             </TwitterShareButton>
  //             <LinkedinShareButton url={qRCode.link}>
  //               <LinkedinIcon size={32} />
  //             </LinkedinShareButton>
  //           </>
  //         )}

  //         {status === 'DISTRIBUTED' && 'qrcode' in activeChannels && (
  //           <div className='channel-wrap'>
  //             <ActiveChannelQrCode
  //               qrcode={activeChannels.qrcode}
  //               disId={disId}
  //             />
  //             <FacebookShareButton url={activeChannels.qrcode}>
  //               <FacebookIcon size={32} />
  //             </FacebookShareButton>
  //             <TwitterShareButton url={activeChannels.qrcode}>
  //               <TwitterIcon size={32} />
  //             </TwitterShareButton>
  //             <LinkedinShareButton url={activeChannels.qrcode}>
  //               <LinkedinIcon size={32} />
  //             </LinkedinShareButton>
  //           </div>
  //         )}
  //       </TabPanel>
  //     </Tabs>
  //   </div>
  // );
};

const mapStateToProps = (state) => ({
  dataToPush: apiDataGeneratorSelector(state),
  enableSave: saveButtonSelector(state),
  activeDevice: activeDeviceSelector(state),
  status: state.question.status,
  progressbar: state.question.progressbar,
  disId: state.question.distributionId,
  order: state.question.order,
  statusDevice: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'device'
  ),
  statusOpenLink: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'openLink'
  ),
  statusQrCode: state.question.pushChannelList.find(
    (channel) => channel.channelType === 'qrCode'
  ),
  deviceLists: deviceListsSelector(state),
  openlinks: state.question.openLink,
  qRCode: state.question.qRCode,
  activeChannels: state.question.activeChannels,
});

const mapDispatchToProps = {
  sortQuestion,
  sortProjects,
  addCard,
  pushConfiguration,
  updateConfiguration,
  setProfileIdInState,
  fetchChannels,
  fetchDeviceLists,
  setDeviceActive,
  setDistDevice,
  distributeConfiguration,
  initOpenLink,
  setOpenLink,
  initQrCode,
  updateOpenLink,
  setOpenLinkInDistribution,
  setQRCode,
  setGenQrCode,
  setQrCodeInDistribution,
  createProjectForSurveyOnReload,
  editDistributionQuestion,
  editDistributionConfig,
  selectProjectAndFetch,
  setActiveChannel,
  projectFetch,
  setSurveyProjectStep,
  setSurveyChannelStep,
  setSurveyPreviewStep,
  setSurveyConfigStep,
};

export default connect(mapStateToProps, mapDispatchToProps)(SortAndPreview);
