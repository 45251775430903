import styled from 'styled-components'

export const Body = styled.main`
	position: relative;	
	box-sizing: border-box;
	width: 100%;
	padding: 10px 10px;
	margin: 10px auto;
	border-radius: 4px;
	margin:auto;
	// @media (max-device-width: 1366px) and (orientation: landscape) { 
	// 	height: auto;
	// }

`;

export const Container = styled.div`
	width: 100%;
	height: 96vh;
	margin: auto auto;
	overflow-y:auto;
`;
