import React from 'react' 
import { Button } from 'reactstrap' 
import { withRouter } from 'react-router-dom'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb'
import '../buttonCss.css'

function CreateButton(props) { 
    return ( 
        <> 
            <Breadcrumb path="Brand /" /> 
            <Button style={{ background: '#1B998B', borderColor: '#1B998B', margin: '10px 0px 10px 0px', padding:'4px 15px', fontSize:'13px', fontWeight:'500' }}
                className="btnHover" size="sm" onClick={() => props.history.push('/app/brand/create')}> 
                Create New
            </Button> 
        </> 
    ) 
} 

export default withRouter(CreateButton); 