import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'

import {GetAllCompanyProfile, FetchAllContactByIndividualCompany, FetchAllDepartment, 
  FetchAllDesignation, AddNewEmployee, FetchAllEmployee} from '../../../url/prmUrl'
import { Button, Input, Label, Body, Error, Aside, TextArea, Modal } from '../StyledComponents'
import Alert from '../ShowAlert'

let companyId = []
let contactId = []
let departmentId = []
let designationId = []


const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.name && touched.name ? <Error>{errors.name}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="name"
                    type="text"
                    placeholder="Company name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
              {errors.employeeId && touched.employeeId ? <Error>{errors.employeeId}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                Employee ID:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="employeeId"
                    type="text"
                    placeholder="Employee ID"
                    value={values.employeeId}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.companyId && touched.companyId ? <Error>{errors.companyId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Company:
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="companyId"
                    value={values.companyId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.companyId}
                  /> 
                </div>
              </Label>

              {errors.contactId && touched.contactId ? <Error>{errors.contactId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Contact:
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="contactId"
                    value={values.contactId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.contactId}
                  /> 
                </div>
              </Label>

              {errors.departmentId && touched.departmentId ? <Error>{errors.departmentId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Department:
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="departmentId"
                    value={values.departmentId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.departmentId}
                  /> 
                </div>
              </Label>

              {errors.designationId && touched.designationId ? <Error>{errors.designationId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                  Designation:
                </div>
                <div css={`width: 60%`}>
                  <MySelect
                    name="designationId"
                    value={values.designationId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.designationId}
                  /> 
                </div>
              </Label>

              {errors.personalMobile && touched.personalMobile ? <Error>{errors.personalMobile}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                 Personal Mobile:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="personalMobile"
                    type="text"
                    placeholder="Enter Phone number"
                    value={values.personalMobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.officialMobile && touched.officialMobile ? <Error>{errors.officialMobile}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                 Official Mobile:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="officialMobile"
                    type="text"
                    placeholder="Enter Official number"
                    value={values.officialMobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.personalEmail && touched.personalEmail ? <Error>{errors.personalEmail}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                    Personal Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="personalEmail"
                    type="text"
                    placeholder="Enter Personal Email"
                    value={values.personalEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.officialEmail && touched.officialEmail ? <Error>{errors.officialEmail}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                    Official Email:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="officialEmail"
                    type="text"
                    placeholder="Enter Official Email"
                    value={values.officialEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                   Address:
                </div>
                <div css={`width: 60%`}>
                  <TextArea
                    name="address"
                    placeholder="Type here..."
                    value={values.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class MySelect extends React.Component {
  handleChange = value => {
    this.props.name==="companyId" ? this.props.onChange("companyId", value) :
    this.props.name==="contactId" ? this.props.onChange("contactId", value) :
    this.props.name==="departmentId" ? this.props.onChange("departmentId", value) :
    this.props.onChange("designationId", value)
  };

  handleBlur = () => {
    this.props.name==="companyId" ? this.props.onBlur("companyId", true) :
    this.props.name==="contactId" ? this.props.onBlur("contactId", true) :
    this.props.name==="departmentId" ? this.props.onBlur("departmentId", true) :
    this.props.onBlur("designationId", true)
  };

  render() {
    return (
        <Select
          id="color"
          placeholder={
            this.props.name==="companyId" ? "Select Company" :
            this.props.name==="contactId" ? "Select Contact" :
            this.props.name==="departmentId" ? "Select Department" :
            "Select Designation"
          }
          options={
              this.props.name==="companyId" ? companyId :
              this.props.name==="contactId" ? contactId :
              this.props.name==="departmentId" ? departmentId :
              designationId
            }
          //isMulti
          onChange={this.handleChange} 
          onBlur={this.handleBlur}     
          value={this.props.value}  
        />

    );
  }
}

const Employee = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
      const allCompanyProfile = await axios(GetAllCompanyProfile);
       companyId = allCompanyProfile.data.map(element=>({label: element.user_Name, value: element.user_Id}))
      
       const allContact = await axios(FetchAllContactByIndividualCompany(localStorage.getItem("jwtID")))
       contactId = allContact.data.map(element=>({label: element.firstName, value: element._id}))

       const allDepartment = await axios(FetchAllDepartment)
       departmentId = allDepartment.data.map(element=>({label:element.name, value:element._id}))

       const allDesignation = await axios(FetchAllDesignation)
       designationId = allDesignation.data.map(element=>({label:element.title, value: element._id}))

       const result = await axios(FetchAllEmployee)
       setEditable(prev=>{  //gets all previous editable value
         return {
           ...prev,
           loaded: result.data
         }
       })
     }
     catch (err) {
      // console.log("err")
     }



    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  let duplicateName=[]
  let duplicateEmployeeID=[]
  let duplicatepersonalMobile=[]

  if(editable.loaded)
   if(!editable.loaded.Error)
    {
       duplicateName=editable.loaded.map(item=>item.name)
       duplicateEmployeeID=editable.loaded.map(item=>item.employeeId)
       duplicatepersonalMobile=editable.loaded.map(item=>item.personalMobile)
    }
   
  const schema = Yup.object().shape({
    name: Yup.string().required("Must Define a Name")
                      .min(3, "Minimum 3 characters")
                      .notOneOf(duplicateName, "Already Exists"),
    employeeId: Yup.string().required("Employee Id Required")
                            .notOneOf(duplicateEmployeeID, "Already Exists"),
    companyId: Yup.string().required("Please Select Company..."),
    contactId: Yup.string().required("Please Select Contact..."),
    departmentId: Yup.string().required("Please Select Contact..."),
    departmentId: Yup.string().required("Please Select Department..."),
    designationId: Yup.string().required("Please Select Designation..."),
    personalMobile: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid')
                       .required("Personal Mobile Required")
                       .notOneOf(duplicatepersonalMobile, "Already Exists"),
    officialMobile: Yup.string().matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{5}\)?)\s?-?\s?(\(?\d{4}\)?)\s?$/, 'Phone number is not valid'),
    personalEmail : Yup.string().email("Invalid mail")
                       .required("Personal Emial Required"),
    officialEmail : Yup.string().email("Invalid mail"),

  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      name:"" , 
      employeeId: '',
      companyId: '',
      contactId: '',
      departmentId: '',
      designationId:'',
      personalMobile:'',
      officialMobile:'',
      personalEmail:'',
      officialEmail:'',
      address:'',

    }),

    handleSubmit: (values, { setSubmitting }) => {
      const data ={
        ...values,
        companyId: values.companyId ? values.companyId.value : '',
        contactId: values.contactId ? values.contactId.value : '',
        departmentId: values.departmentId ? values.departmentId.value : '',
        designationId: values.designationId ? values.designationId.value : '',
      }

      axios.post(AddNewEmployee, data)
      .then((response) => {
        if(!response.data.Error){
          setView(true)
          // console.log("okay ",response.data)
        }
        else {
          window.alert("Uexpected Error! Duplicate Name or Contact or employee found!")
        }
      })
      .catch((err) => {
        // console.log(err)
      })
      

    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
            </Modal>
          <FormikForm/>
          </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Employee)