import React, { useState, useEffect, memo } from 'react';
import { Link, Switch, Route, Redirect } from 'react-router-dom'
import styled from 'styled-components/macro'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { FetchAllProductCategory, FetchAllBrand, FetchAllProduct, AddNewProduct} from '../../../url/prmUrl';
import Alert from '../ShowAlert'
import { Button, Input, Label, Body, Error, Aside, Modal } from '../StyledComponents'

let categoryOptions = []
let brandOptions = []
let subProductOptions = []

const Form = props => {

  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
  } = props;

  return (
    <form onSubmit={handleSubmit} css={`width: 100%`}>
          <Aside>
            <section>
            {errors.productName && touched.productName ? <Error>{errors.productName}</Error> : null}
              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Name:
                </div>
                <div css={`width: 60%`}>
                  <Input
                    name="productName"
                    type="text"
                    placeholder="Product name"
                    value={values.productName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>

              {errors.productCategoryId && touched.productCategoryId ? <Error>{errors.productCategoryId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Category:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="productCategoryId"
                    value={values.productCategoryId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.productCategoryId}
                  /> 
                </div>
              </Label>
              {errors.brandId && touched.brandId ? <Error>{errors.brandId}</Error> : null}
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Brand:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="brandId"
                    value={values.brandId}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.brandId}
                  /> 
                </div>
              </Label>
              <Label>              
                  <div css={`width: 30%;text-align:left;`}>
                    Sub Product:
                </div>
                <div css={`width: 60%`}>
                  <MySelect         //calls the MySlect Class and passes all the props necessary with exact name.
                    name="subProducts"
                    value={values.subProducts}
                    onChange={setFieldValue}
                    onBlur={setFieldTouched}
                    touched={touched.subProducts}
                  /> 
                </div>
              </Label>

              <Label>
                <div css={`width: 30%;text-align:left;`}>
                  Active:
                </div>
                <div css={'width:15px'}>
                  <Input
                    name="status"
                    type="checkbox"
                    css={`width:18px;height:18px`}
                    checked={values.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
              </Label>
            </section>
        </Aside>
        <Button type="submit">
          <FontAwesomeIcon
            icon={faSave}
            css={`margin-right:10px`}
          />
          Create
        </Button>
    </form>
  )

}


class MySelect extends React.Component {
  handleChange = value => {
    this.props.name==="productCategoryId" ? 
    this.props.onChange("productCategoryId", value) : 
        this.props.name==="brandId" ? 
        this.props.onChange("brandId", value) :
        this.props.onChange("subProducts", value);
  };

  handleBlur = () => {
   this.props.name==="productCategoryId" ? 
   this.props.onBlur("productCategoryId", true) : 
        this.props.name==="brandId" ? 
        this.props.onBlur("brandId", true) :
        this.props.onBlur("subProducts", true)
  };

  render() {
    return (
        <Select
          id="color"
          placeholder={this.props.name==="productCategoryId" ? "Select Category" : this.props.name==="brandId" ? "Select Brand" : "Select Sub Product"}
          options={this.props.name==="productCategoryId" ? categoryOptions : this.props.name==="brandId" ? brandOptions : subProductOptions}
          isMulti={this.props.name==="subProducts" ? true : false}
          onChange={this.handleChange}  //calls the handleChange in MySelect class
          onBlur={this.handleBlur}      //calls the handleBlur in MySelect class
          value={this.props.value}      // gets the selected value, which is eventually options.value
        />

    );
  }
}

const Product = props => {
  const [view, setView] = useState(false)
  const [editable, setEditable] = useState({
    loaded: '',
    fetched: false,
    redirect: false
  })

  useEffect(() => {

    const fetchData = async () => {

      try{
      const fetchProductCategory= await axios(FetchAllProductCategory);
      categoryOptions = fetchProductCategory.data.map(element=>({label: element.name, value: element._id}))
      
      const fetchBrand = await axios(FetchAllBrand(localStorage.getItem("jwtID")));
      brandOptions = fetchBrand.data.map(element=>({label: element.brandName, value: element._id}))
     }
     catch (err) {
      // console.log(err)
     }

      await axios(FetchAllProduct(localStorage.getItem("jwtID")))
      .then(result=>{
        subProductOptions = result.data.Error ? [] : result.data.map(element=>({label: element.productName, value: element._id}))
        setEditable(prev=>{  
          return {
            ...prev,
            loaded: result.data
          }
        })
      })
      .catch(err=>{
        // console.log(err)
      })
      

    };

    if(view===false){
      fetchData();  //fetch data will only be called when view false, but useEffect will be called everytime view changes
    }
  }, [view]);

  const product = editable.loaded ?
                  editable.loaded.Error ? [] :
                  editable.loaded.map(item=>item.productName) : [] 
  const schema = Yup.object().shape({
    productName: Yup.string().required("Must Define a Name")
                             .min(3, "Minimum 3 characters")
                             .notOneOf(product, "Already exists"),
    productCategoryId: Yup.string().required("Please select Category..."),
    brandId: Yup.string().required("Please select Company...")
  })

  const FormikForm = withFormik({
    validationSchema: schema,
    mapPropsToValues: () => 
    ({ 
      createdByCompanyID: localStorage.getItem("jwtID"),
      productName:"" , 
      productCategoryId: '',
      brandId: '',
      subProducts: '',
      status: true
    }),

    handleSubmit: (values, { setSubmitting }) => {
        let data = {
            ...values,
            productCategoryId:values.productCategoryId.value,
            brandId:values.brandId.value,
            subProducts:values.subProducts ? values.subProducts.map(sp=>sp.value) : '' //using ternary operator to avoid error(if false map fn won't execute)
        }                                                                          //when submitted without any value as it is not mendatory field
      
        axios.post(AddNewProduct, data)
        .then((response) => {
          if(!response.data.Error){
            setView(true)
          }
          else {
            window.alert("Uexpected Error! Duplicate Name found!")
          }
        })
        .catch((err) => {
          // console.log("Error")
        })

    }
  })(Form)


  return (
    <React.Fragment>
        <Body>
        <Modal
              view={view}
              onClick={event => {
                event.preventDefault()
                if (event.target === event.currentTarget)
                {
                  setView(false)
              }
              }}
            >
              <Alert close={setView}/>
          </Modal>
          <FormikForm/>
        </Body>
      {/* {editable.redirect === false ? <AddNewUser /> :
        <Redirect
          to={{
            pathname: "/app/users"
          }}
        />
      } */}
    </React.Fragment>
  )
}

export default memo(Product)