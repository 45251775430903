import React from 'react'

import { LinkButton } from './styles'

const BackButton = props => {

  let content;

  if (props.disid) {
    content = <LinkButton to={`/app/distribution/edit/${props.disid}`}>Go Back</LinkButton>
  } else {
    content = <LinkButton to="/app/distribution/create">Go Back</LinkButton>
  }
  return (
    <React.Fragment>
      {content}
    </React.Fragment>
  )
}

export default BackButton