import React from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'
import * as Yup from 'yup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave } from '@fortawesome/free-solid-svg-icons'
import {Formik} from 'formik'

import { Card, Input, Button, CloseButton, Aside, Error, Label,TextArea,Modal, Form as Body } from '../../Styles'

const channelTypeOptions = [
  {label: 'Device', value: 'device'},
  {label: 'OpenLink', value: 'openLink'},
  {label: 'Email', value: 'email'},
  {label: 'Software', value: 'software'},
  {label: 'Website', value: 'website'},
  {label: 'Facebook', value: 'facebook'},
  {label: 'Twitter', value: 'twitter'},
  {label: 'LinkedIn', value: 'linkedin'},
  {label: 'Instagram', value: 'instagram'},
  {label: 'YouTube', value: 'youtube'},
  {label: 'SMS', value: 'sms'},
  {label: 'Service Center', value: 'serviceCenter'},
  {label: 'CC Camera', value: 'ccCamera'},
  {label: 'Call Center', value: 'callCenter'},
  {label: 'Manual', value: 'manual'},
  {label: 'QR Code', value: 'qrCode'},
  {label: 'Offline', value: 'offline'},
  {label: 'In App', value: 'inApp'},
]

const channelModeOptions = [
  {label: 'PULL', value: 'pull'},
  {label: 'PUSH', value: 'push'},
  {label: 'BOTH', value: 'both'},
]

const CustomSelect = props=>{
  const {
    changeFn,
    blurFn,
    ...rest
  } = props

  const handleChange = selected=>{
    const {value} = selected
    changeFn(rest.name, value)
  }

  const handleBlur = ()=>{
    blurFn(rest.name, true)
  }

  return(
    <Select
      {...rest}
      onChange={handleChange}
      onBlur={handleBlur} 
    /> 
  ) 
} 

const FormBody = props=>{
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,    // for custom call and change
    setFieldTouched,  //for custom call and change
    isDisabled //Comes as an additional prop provided by connect. Used to disable a field
  } = props

  // console.log(values)

  const find = (from, value)=>{
    return from.find(item=>item.value===value)
  }

  return( 
    <> 
      <Body onSubmit={handleSubmit}> 
        <Aside> 
          <section> 
            {errors.channelType && touched.channelType ? <Error>{errors.channelType}</Error> : null}
            <Label>
              <div css={`width: 30%; text-align:left;`}>
                Channel Type:
              </div> 
              <div css={`width: 60%`}> 
                <CustomSelect 
                  isDisabled={isDisabled} 
                  name='channelType' 
                  options={channelTypeOptions} 
                  value={find(channelTypeOptions,values.channelType)} 
                  changeFn={setFieldValue} 
                  blurFn={setFieldTouched} 
                  isSearchable={false}  
                /> 
              </div> 
            </Label> 
            {errors.channelMode && touched.channelMode ? <Error>{errors.channelMode}</Error> : null}
            <Label>
              <div css={`width: 30%;text-align:left;`}>
                Channel Mode:
              </div>
              <div css={`width: 60%`}>
                <CustomSelect
                  name='channelMode'
                  options={channelModeOptions}
                  value={find(channelModeOptions,values.channelMode)}
                  changeFn={setFieldValue}
                  blurFn={setFieldTouched} 
                  isSearchable={false}
                />  
              </div>
            </Label>
            {errors.isActive && touched.isActive ? <Error>{errors.isActive}</Error> : null}
            <Label>
              <div css={`width: 30%;text-align:left;`}>
                Active:
              </div>
              <div css={`width: 18px; height: 18px`}>
                <Input
                  css={`width: 18px; height: 18px`}
                  name="isActive"
                  type="checkbox"
                  checked={values.isActive}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </Label>
          </section>
        </Aside>
        <Button type='submit' >
          <FontAwesomeIcon icon={faSave} css={`margin-right: 10px`} /> 
          Update 
        </Button> 
      </Body> 
    </> 
  ) 
} 


const Form = props => {

  let { initialValues, allChannels, submitFn, close, header } = props
  
  if(!header){
    header = "Some Header"
  }

  if(!close){
    close = ()=>{console.log("Close button pressed")}
  }

  if(!submitFn){
    submitFn = ()=>{console.log("Submit button pressed")}
  }
  
  const isDisabled = Boolean(initialValues)

  if(!initialValues){
    initialValues = {
      channelType: null,
      channelMode: null,
      isActive: true
    }
  }

  // const duplicacy = allFeature ? allFeature.map(item=>item.name.toLowerCase()) : []  .notOneOf(allChannels).nullable().required("Required")  .nullable().required('Required')

  // console.log(allChannels)

  const schema = Yup.object({ 
    channelType: Yup.string().required("Required").nullable().notOneOf(allChannels,"Already Exists"),
    channelMode: Yup.string().required("Required").nullable(),
    isActive: Yup.boolean(), 
  }) 

  const FormikForm = ()=>(
    <Formik 
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        submitFn(values)
        setSubmitting(false)
        close()
      }}
      render={props=><FormBody {...props} isDisabled={isDisabled}/>}
    />
  )

  return (
    <Card>
      <CloseButton onClick={close}> x </CloseButton> 
      <h4 css={`margin: 12px; color: rgb(52, 58, 64);`}> 
        {header} 
      </h4> 
      <FormikForm/> 
    </Card> 
  ) 
} 

export default Form; 