import React from 'react';
import { connect } from 'react-redux';
import { SplitButton } from 'primereact/splitbutton';

import { addQuestion } from '../../redux/actions/modifyQuestion';
import './index.css';

const AddButton = ({ addQuestion }) => {
  const model = [
    {
      label: 'Multiple Choice',
      command: () => {
        addQuestion('multipleChoice');
      },
    },
    {
      label: 'Drop Down',
      command: () => {
        addQuestion('dropDown');
      },
    },
    {
      label: 'Dichotomy',
      command: () => {
        addQuestion('dichotomy');
      },
    },
    {
      label: 'ThumbsUp/ThumbsDown',
      command: () => {
        addQuestion('thumbsUpDown');
      },
    },
    {
      label: 'Checkbox',
      command: () => {
        addQuestion('checkbox');
      },
    },
    {
      label: 'Likert Scale',
      command: () => {
        addQuestion('likertScale');
      },
    },
    {
      label: 'Rating Scale',
      command: () => {
        addQuestion('ratingScale');
      },
    },
    {
      label: 'Star Rating',
      command: () => {
        addQuestion('starRating');
      },
    },
    {
      label: 'Thumbs Rating',
      command: () => {
        addQuestion('thumbsUpRating');
      },
    },
    {
      label: 'Love Rating',
      command: () => {
        addQuestion('loveRating');
      },
    },
    {
      label: 'NPS',
      command: () => {
        addQuestion('nps');
      },
    },
    {
      label: 'Text Box',
      command: () => {
        addQuestion('textBox');
      },
    },
    {
      label: 'Demography',
      command: () => {
        addQuestion('demography');
      },
    },
  ];

  return (
    <SplitButton
      id='add'
      label='Add Question'
      onClick={() => addQuestion()}
      model={model}
      style={{ margin: '0 auto 20px auto' }}
    />
  );
};

const mapDispatchToProps = {
  addQuestion: (questionType) => addQuestion(questionType),
};

export default connect(null, mapDispatchToProps)(AddButton);
