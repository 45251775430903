
let init = { fullsidebar: true } 

const rootReducer = (state = init, action) => { 
    switch (action.type) { 
        case 'UPDATE_SIDEBAR_STATE': { return { fullsidebar: action.payload } } 
        default: return state; 
    } 
} 

export default rootReducer
