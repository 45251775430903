import { createSelector } from 'reselect'
import objectClone from 'lodash.clonedeep'

import { contactCard } from '../../config'

//Gives the channel value of config in State
export const channelSelector = createSelector(
  state => state.config.channel,
  data => data
)
// console.log(channelSelector); return fn man
// console.log(state=>state.config.channel) return fn man

const subChannelList = state => state.config.subChannelList
// console.log(subChannelList) return fn
const subChannelSelector = state => state.config.subChannel
const projectListSelector = state => state.config.projectList
const projectSelector = state => state.config.project
const selectedQuestionSelector = state => state.question.selectedQuestion
// console.log(selectedQuestionSelector) return fn
const orderSelector = state => state.question.order
const cardListSelector = state => state.question.order.cardsList
const isSavedSelector = state => state.question.isSaved

const deviceListSelector = state => state.question.deviceList
// const openLinkSelector = state => state.question.openLink
// const qRCodeSelector = state => state.question.qRCode

//Gives the list of questions from a selected project after a certain index
const projectQuestionListSelector = (state, projectId, index) => {
  return {
    questionList: state.question.order.projects[projectId].questionList.slice(index + 1),
    index
  }
}

//Gives the list of questions from a selected project before a certain index
const projectPreviousQuestionListSelector = (state, projectId, index) => {
  return {
    questionList: state.question.order.projects[projectId].questionList.slice(0, index),
    projectId
  }
}

//Gives the channelType and channelId for currently being configured
export const channelTypeIdSelector = createSelector(
  [channelSelector,
    subChannelSelector],
  (channelType, subChannelId) => {
    return {
      channelType,
      subChannelId
    }
  }
)

//Gives the view property in config of State
export const viewSelector = createSelector(
  state => state.config.view,
  data => data
)

//Gives the image data for the local preview
export const imageDataSelector = state => state.question.header.image

//Gives the state->question->header->text field from state
export const headerTextSelector = state => state.question.header.text

//Gives the state->question->order->cards field from the state
export const cardsSelector = state => state.question.order.cards

// Gives isLoading field of config of state
const isLoadingSelector = state => state.config.isLoading

//Gives an boolean whether project is loading 
export const projectIsLoadingSelector = createSelector(
  isLoadingSelector,
  isLoading => isLoading.project
)
//Gives an boolean whether subChannel is loading
export const subChannelIsLoadingSelector = createSelector(
  isLoadingSelector,
  isLoading => isLoading.subChannel
)

//Gives an boolean whether question is loading 
export const questionIsLoadingSelector = createSelector(
  isLoadingSelector,
  isLoading => isLoading.question
)

//Gives the selected channel value in the format react select needs
export const channelObjectSelector = createSelector(
  channelSelector,
  channel => {  // takes the value of channelSelector as argument 
    const options = [
      { label: "Device", value: "device" },
      { label: "Open Link", value: "openLink" }
    ]
    // console.log(channel? options.find(element=>element.value===channel):null)
    return channel ? options.find(element => element.value === channel) : null
  }

)

//Gives enabled or disabled property of the channel dropdown
export const channelPropertySelector = createSelector(
  selectedQuestionSelector,
  selectedQuestion => Object.keys(selectedQuestion).length === 0
)

//Gives the enabled or disabled property of the subChannel dropdown
export const subChannelPropertySelector = createSelector(
  channelSelector,
  selectedQuestionSelector,
  (channel, selectedQuestion) => {
    const properties = [
      { value: "device", subChannel: true },
      { value: "openLink", subChannel: true }
    ]
    if (channel) {
      return properties.find(element => element.value === channel).subChannel && Object.keys(selectedQuestion).length === 0
    } else {
      return false
    }
  }
)

//Gives the list of options for subChannel dropdown in the format react select needs
export const subChannelListSelector = createSelector(
  subChannelList,
  subChannelList => subChannelList.map(element => ({ label: element.name, value: element._id }))
)

//Gives the subChannel selected in the format react select needs for the subChannel dropdown
export const subChannelObjectSelector = createSelector(
  subChannelListSelector,
  subChannelSelector,
  (list, selected) => {
    const value = list.find(element => element.value === selected)
    if (value) {
      return value
    } else {
      return { label: "Please Select...", value: "" }
    }
  }
)

//Gives a boolean indicating whether project dropdown is active or not
export const projectPropertySelector = createSelector(
  subChannelPropertySelector,
  channelSelector,
  subChannelSelector,
  (subChEnabled, channel, subChannel) => {
    if (subChEnabled) {
      if (channel && subChannel) {
        return true
      }
      return false
    } else {
      if (channel) {
        return true
      }
      return false
    }
  }
)

//Gives the list of options for projects dropdown in the format react select needs
export const projectListFormatSelector = createSelector(
  projectListSelector,
  projectList => projectList.map(element => ({ label: element.name, value: element._id }))
)

//Gives the list of projects available for the account in the project dropdown for the config tab
export const projectObjectSelector = createSelector(
  projectListFormatSelector,
  projectSelector,
  (list, selected) => {
    const value = list.find(element => element.value === selected)
    if (value) {
      return value
    } else {
      return { label: "Please Select...", value: "" }
    }
  }
)

//Gives a boolean indicating whether the link to the sort page is visible or not based on questions selected
export const nextButtonSelector = createSelector(
  selectedQuestionSelector,
  data => {
    const projectList = Object.keys(data)
    if (projectList.length === 0) {
      return false
    }
    return true
  }
)

//Gives a list of the project items with their selected questions to be rendered on the sorting page
export const projectOrderSelector = createSelector(
  orderSelector, // state.question.order
  projectListSelector, // state.config.projectList
  selectedQuestionSelector, // state.question.selectedQuestion
  (order, projectList, question) => {
    return order.list.map(element => {
      switch (element.type) {
        case "project": {
          const projectItem = projectList.find(item => item._id === element.id)
          console.log(projectList)
          console.log(element)
          const questionList = order.projects[element.id].questionList.map(id => {
            return question[element.id].find(questionData => questionData.id === id)
          })
          return {
            id: projectItem._id,
            type: element.type,
            projectName: projectItem.projectName,
            questionList
          }
        }

        default: {
          const item = order.cards[element.id]
          return { id: element.id, ...item }
        }
      }
    })
  }
)

//Gives a list of available additional cards to be dragged and dropped into the projects list
export const additionalCardSelector = createSelector(
  cardListSelector,
  cardsSelector,
  (sideBar, main) => {
    // console.log(sideBar)
    // console.log(main)
    return sideBar.filter(item => {
      const keys = Object.keys(main)
      return !keys.some(element => element === item.id)
    })
  }
)

//Gives an array of active fields in contactCard
export const activeContactFieldsSelector = createSelector(
  cardsSelector,
  cards => {
    if (!cards.contactCard) {
      return []
    }
    const { fields } = cards.contactCard
    return contactCard.fieldList.filter(item => fields[item.value])
  }
)

//Gives an array of the questions available after a specific question in a project
export const makeQuestionsToJumpToSelector = () => createSelector(
  projectQuestionListSelector,
  ({ questionList, index }) => {
    const labeledList = [{ label: 'None', value: null }].concat(
      questionList.map((element, i) => ({ label: `Question ${index + i + 2}`, value: element })))
    labeledList.push({ label: 'End', value: "END" })

    return labeledList
  }
)

//Gives an array of the list of checkbox questions available to be set as conditions
export const makeQuestionsToSetConditionsSelector = () => createSelector(
  [projectPreviousQuestionListSelector,
    selectedQuestionSelector],
  ({ questionList, projectId }, questionDataList) => {

    const labeledList = questionList.map((element, i) => ({ label: `Question ${i + 1}`, value: element }))

    const onlyCheckboxList = labeledList.filter(item => {
      const questionData = questionDataList[projectId].find(data => data.id === item.value)
      return questionData.type === 'checkbox'
    })
      .map(item => {
        const questionData = questionDataList[projectId].find(data => data.id === item.value)
        return {
          ...item,
          data: questionData.options
        }
      })

    return [{ label: 'None', value: '' }, ...onlyCheckboxList]

  }
)

//Formats all data ready for push to api
export const apiDataGeneratorSelector = createSelector(
  channelSelector, subChannelSelector, selectedQuestionSelector, orderSelector,
  (channel, subChannel, selectedQuestion, order) => {

    const { list, projects } = order
    let modifiedList = list.filter(item => item.type === 'project')
    modifiedList = modifiedList.map(item => item.id)

    const questionOrder = {}
    Object.keys(projects).forEach(projectId => {
      questionOrder[projectId] = projects[projectId].questionList
        .map(questionId => {
          const questionDetails = selectedQuestion[projectId].find(question => question.id === questionId)
          const newQuestionDetails = { ...questionDetails }
          // delete newQuestionDetails.dependant
          return newQuestionDetails
        })
    })

    const data = {
      questionBank: {
        projectOrder: [...modifiedList],
        questionOrder: { ...questionOrder },
        order: order
      }
    }

    return {
      channel,
      subChannel,
      data
    }
  }
)

// const deviceListSelector = state => state.question.deviceList
// const openLinkSelector = state => state.question.openLink
// const qRCodeSelector = state => state.question.qRCode
// {
// 	"activeChannels": {
//         "device": [{
//             "id": "5dba9e9a1904cc7568897278",
//             "name": "Demo01_d01"
//         }],
//         "openlink": {
//             "id": "5dbab9226c40ce209bfd0410",
//             "hashId": "8b1895c904eb054489f99c786b245ba7e7aa95ed070819604bdf5c61a5c0b4ee"
//         },
//         "qrcode": {
//             "id": "5dbab9226c40ce209bfd040f",
//             "hashId": "8b1895c904eb054489f99c786b245ba7e7aa95ed070819604bdf5c61a5c0b4ee"
//         }
//     }
// }

// {
// 	"activeChannels": {
//         "device": [],
//     }
// }

export const activeDeviceSelector = createSelector(
  deviceListSelector,
  (deviceList) => {

    // const activeChannels = {
    //   device: [],
    //   openlink: {},
    //   qrcode: {}
    // }

    const newDeviceList = deviceList.filter(device => device.status === 'Active')
    const formattedDevice = newDeviceList.map(device => {
      return {
        _id: device["_id"],
        name: device.name
      }
    })

    // if (formattedDevice.length > 0) { activeChannels['device'] = formattedDevice }

    // return {
    //   activeChannels
    // }
    return formattedDevice
  }
)

// export const activeChannelsSelector = createSelector(
//   () => {
//     return {
//       null
//     }
//   }
// )

//Gives a boolean indicating whether the saved button should be visible or not
export const saveButtonSelector = createSelector(
  isSavedSelector,
  isSaved => !isSaved
)

// //Gives a boolean indicating whether the preview button should be visible or not
// export const previewButtonSelector = createSelector(
//   isSavedSelector,
//   isSaved=>isSaved
// )