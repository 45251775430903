import React, { useState, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import _ from 'lodash';
import AddFieldButton from '../AddFieldButton';
import DeleteFieldButton from '../DeleteFieldButton';
import { RadioButton } from 'primereact/radiobutton';
// const Input = styled.textarea`
//   box-sizing: border-box;
//   border-color: #BDBDBD;
//   border-style: solid;
//   border-width: 0 0 1px 0;
//   resize: none;
//   width: 100%;
//   font-size: 15px;
//   height: 35px;
//   line-height: 1;
//   outline: none;
//   padding: 10px 10px 5px 10px;
//   overflow: hidden;
//   @media screen and (max-width: 425px){
//     font-size: 10px;
//     padding: 12.5px;
//   }
// `

const Input = styled.input`
  box-sizing: border-box;
  border-color: #bdbdbd;
  border-style: solid;
  border-width: 1px 1px 1px 1px;
  resize: none;
  width: 100%;
  font-size: 15px;
  outline: none;
  padding: 5px;
  margin: 0 8px;
  border-radius: 4px;
  color: #424242;
  overflow: hidden;
  @media screen and (max-width: 425px) {
    font-size: 10px;
    padding: 12.5px;
  }
`;

// const choices = item.choices.map((choice, index) => {
//   return (
//     <div key={index}
//       style={{
//         position: "relative",
//         width: "100%",
//         display: "flex",
//         flexDirection: "row",
//         alignSelf: "flex-end",
//         alignItems: "center",
//         alignContent: "space-between",
//         padding: "0px",
//         marginTop: "8px"
//       }}
//     >

//       {/* <input type="radio" name={`${item.id}`} value={`${choice}`} /> */}
//       <RadioButton inputId={`${item.id}`} name={`${item.id}`} value={`${choice}`} onChange={(e) => false} disabled={isReadOnly} />
//       <Input
//         css={`border-color:${choice || !hasError ? '#BDBDBD' : '#F27D7D'}`}
//         value={choice}
//         placeholder="option"
//         onChange={event => { editField(item.id, index, event.target.value) }}
//         // onChange={event => { debounceEditField(item.id, index, event.target.value) }}
//         disabled={isReadOnly}
//       />
//       {!isReadOnly && <DeleteFieldButton qId={item.id} fId={index} />}
//       {/* <DeleteFieldButton qId={item.id} fId={index} /> */}
//     </div>
//   )
// })

const Choice = ({ choice, index, editField, isReadOnly, hasError, item }) => {
  const [choiceTitle, setChoiceTitle] = useState('');
  useEffect(() => {
    setChoiceTitle(choice);
  }, []);
  const delayedQuery = _.debounce(
    (id, index, value) => editField(id, index, value),
    5000
  );
  const debounceEditField = (id, index, value) => {
    setChoiceTitle(value);
    // delayedQuery(id, index, value);
    editField(id, index, value);
  };

  return (
    <div
      key={index}
      style={{
        position: 'relative',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignSelf: 'flex-end',
        alignItems: 'center',
        alignContent: 'space-between',
        padding: '0px',
        marginTop: '8px',
      }}
    >
      {/* <input type="radio" name={`${item.id}`} value={`${choice}`} /> */}
      <RadioButton
        inputId={`${item.id}`}
        name={`${item.id}`}
        value={`${choiceTitle}`}
        onChange={(e) => false}
        disabled={isReadOnly}
      />
      <Input
        css={`
          border-color: ${choiceTitle || !hasError ? '#BDBDBD' : '#F27D7D'};
        `}
        value={choiceTitle}
        placeholder='option'
        onChange={(event) => {
          debounceEditField(item.id, index, event.target.value);
        }}
        // onChange={event => { debounceEditField(item.id, index, event.target.value) }}
        disabled={isReadOnly}
      />
      {!isReadOnly && <DeleteFieldButton qId={item.id} fId={index} />}
      {/* <DeleteFieldButton qId={item.id} fId={index} /> */}
    </div>
  );
};

const MultipleChoice = ({
  item,
  editField,
  hasError,
  disStatus,
  hasDuplicate,
  isReadOnly,
}) => {
  //Return the MultipleChoice Component
  return (
    <React.Fragment>
      <h6
        style={{
          marginTop: '20px',
          marginBottom: '5px',
          fontSize: '14px',
          fontWeight: '700',
        }}
      >
        Choices
      </h6>
      {/* {choices} */}
      {/* item.choices.map((choice, index) => { */}
      {}
      {item.choices.map((choice, index) => (
        <Choice
          key={choice.id}
          choice={choice.value}
          index={index}
          editField={editField}
          isReadOnly={isReadOnly}
          hasError={hasError}
          item={item}
        />
      ))}
      {hasDuplicate ? (
        <small style={{ color: '#F27D7D', marginLeft: '28px' }}>
          Choices are same
        </small>
      ) : null}
      {/* <AddFieldButton id={item.id} /> */}
      {!isReadOnly && <AddFieldButton id={item.id} />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    hasError: state.error.choices,
    hasDuplicate: state.error.dublicateField,
    disStatus: state.disStatus,
    // disStatus: state.disStatus,
    isReadOnly: state.isReadOnly,
  };
};

export default connect(mapStateToProps, null)(MultipleChoice);
