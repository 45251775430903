import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import { questionDataSelector, answerFieldValueSelector } from '../../redux/selectors'
import { updateData, startDate } from '../../redux/actions'

import { Fields, QuestionCard, QuestionTitle } from '../styles'
import { Input } from './styles'

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const TextBox = props => {
  const { data, answer, updateData, startDate } = props

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  const handleChange = event => {
    const { value } = event.target
    updateData(value)
  }

  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        <Input type="text" value={answer} onChange={handleChange} />
      </Fields>
    </QuestionCard>
  )
}

TextBox.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state)
  }
}

const mapDispatchToProps = {
  updateData,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(TextBox))