import React,{useState, memo} from 'react'
import styled from 'styled-components/macro'
import * as Yup from 'yup'

import {CreateProject as Body, Input, ProjectTypes, Types, Error, CloseButton} from '../../StyledComponents'
// import {Button} from '../../../StyledComponents'
import CE from '../../../../resources/CE.png'
import PE from '../../../../resources/PE.png'
import BE from '../../../../resources/BE.png'
import EE from '../../../../resources/EE.png'

export const Button = styled.button`
  display: block;
  padding: 5px 10px;
  margin: 20px auto;
  box-sizing: border-box;
  text-decoration: none;
  color: white;
  border: none;
  background-color: #1B998B;
  text-align: center;
  cursor: pointer;
  font-size: 15px;
  :hover{
    background-color: #0B6157;
    color: #310D31;
    border: 1px solid #0B6157;
  }
`

const CreateProject = props=>{

  let {edit} = props

  if(!edit){
    edit={
      status: false,
      projectName: "",
      projectId: "",
      projectType: ""
    }
  }

  /*State---------------------------------------------------*/
  const [projectName, setProjectName] = useState(edit.projectName)   //array destructuring
  
  const [type, setType] = useState(edit.projectType)

  const [errors, setErrors] = useState({})
  /*State---------------------------------------------------*/
  
  const selectOptions = data=>{
    setErrors({})
    if( type === "")
      setType(data)
    else if( type === data)
      setType("")
    else
      setType(data)
  }
  
  const schema = Yup.object().shape({
    projectName: Yup.string().required("Give a name to the project").min(5,"The name should be at least 5 characters long"),
    type: Yup.string().required("Select a type for the project")
  })

  const handleSubmit = ()=>{
    schema.validate({
      projectName,
      type
    },{abortEarly: false})
      .then(()=>{
        if(!props.edit){
          props.addProject(projectName, type)
        }else{
          props.editProject(edit.projectId, projectName, type)
        }
        props.close.setEdit(false);
        props.close.setView(false);
      })
      .catch((err)=>{
        const errors = {
          projectName: "",
          type: ""
        }
        Object.keys(errors).forEach(key=>{
          const item = err.inner.find(error=>error.path===key)
          errors[key] = item ? item.message : ""
        })
        setErrors(errors)
      })
  }
  
  const addProject = 
    <Body>
      <CloseButton onClick={()=>{ props.close.setEdit(false); props.close.setView(false)} }>
        x
      </CloseButton> 
      <h4 css={`margin: 10px auto; color: #310D31; font-size: 20px;`}> 
        {!props.edit ? "Add Project" : "Edit Project"} 
      </h4> 
      
      {errors.projectName && <Error>{errors.projectName}</Error>} 
      <Input 
        value={projectName} 
        onChange={e=>{
          setErrors({})
          setProjectName(e.target.value)}
        } 
        placeholder="Project Name"
      />
      {errors.type && <Error>{errors.type}</Error>} 

      <ProjectTypes>
        {!props.edit && 
        <>
          <Types  
            selected={type==="Customer Experience"} 
            onClick={() => selectOptions("Customer Experience")} 
          > 
            <img alt="Customer Experience" src={CE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Brand Experience"} 
            onClick={() => selectOptions("Brand Experience")}
          > 
            <img alt="Brand Experience" src={BE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Product Experience"} 
            onClick={() => selectOptions("Product Experience")}
          > 
            <img alt="Product Experience" src={PE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Employee Experience"} 
            onClick={() => selectOptions("Employee Experience")}
          > 
            <img alt="Employee Experience" src={EE} css={`width:90%;`}/>
          </Types>
        </>
      }
      {props.edit && 
        <>
          <Types  
            selected={type==="Customer Experience"} 
          > 
            <img alt="Customer Experience" src={CE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Brand Experience"} 
          > 
            <img alt="Brand Experience" src={BE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Product Experience"} 
          > 
            <img alt="Product Experience" src={PE} css={`width:90%;`}/></Types>
          <Types  
            selected={type==="Employee Experience"} 
          > 
            <img alt="Employee Experience" src={EE} css={`width:90%;`}/> 
          </Types> 
        </> 
      } 
      </ProjectTypes> 


      <Button 
        css={` 
          margin:0px auto 10px auto; background-color: #310D31; 
          color: white; width: auto !important; 
          height: auto !important; box-shadow: 1px 1px 5px -1px #586469; 
          outline: none; :hover { cursor: pointer; } 
        `} 
        onClick={()=>handleSubmit()}> 
        {!props.edit ? "Add Project" : "Save Changes"} 
      </Button> 
    </Body> 

  return ( <> {addProject} </> ) 
} 

export default memo(CreateProject)