import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'

import { Container, Body, QuestionHeader, Delete, Question } from './styles'
import QuestionBody from './QuestionBody'

import { removeQuestion } from '../../redux/actions'
import { makeQuestionsToJumpToSelector, makeQuestionsToSetConditionsSelector } from '../../redux/selectors'

const ProjectQuestions = ({ question, index, status, removeQuestion, projectId, questionsAfter, checkboxBefore }) => {

  const handleClick = () => {
    removeQuestion(projectId, question.id)
  }

  // console.log(checkboxBefore)

  return (
    <Draggable draggableId={question.id} index={index}>
      {(provided) => (
        <Container
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <QuestionHeader
            {...provided.dragHandleProps}
          >
            <Question>{index + 1}. {question.title}</Question>
            {status !== 'SUBMITTED' || status !== 'DISTRIBUTED' &&
              <Delete type="button" value="Remove" onClick={handleClick} />
            }
          </QuestionHeader>
          <Body>
            <QuestionBody
              question={question}
              projectId={projectId}
              questionsAfter={questionsAfter}
              checkboxQuestionsBefore={checkboxBefore}
              jump={question.jump}
            />
          </Body>
        </Container>
      )}
    </Draggable>
  )
}

const makeMapStateToProps = () => {
  const questionToJumpToSelector = makeQuestionsToJumpToSelector()
  const questionsToSetConditionSelector = makeQuestionsToSetConditionsSelector()
  const mapStateToProps = (state, ownProps) => {
    const { question, index, projectId } = ownProps

    return {
      question,
      index,
      projectId,
      status: state.question.status,
      questionsAfter: questionToJumpToSelector(state, projectId, index),
      checkboxBefore: questionsToSetConditionSelector(state, projectId, index)
    }
  }
  return mapStateToProps
}


const mapDispatchToProps = {
  removeQuestion: (projectId, questionId) => removeQuestion(projectId, questionId)
}

export default connect(makeMapStateToProps, mapDispatchToProps)(ProjectQuestions)