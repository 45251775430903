import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom';

import { Formik } from 'formik';
import * as Yup from 'yup';
import {
    Card, CardBody, Col, Button, Input,
    Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label
} from 'reactstrap'; 
import { DeleteDepartment } from '../../../../../url/prmUrl'; 
import Axios from 'axios'
import showToast from '../../../../../components/Toast/toast'
import Breadcrumb from '../../../../../components/Breadcrumb/breadcrumb';
import { getAllDept, getSingleDept, backButtonFunction, updateDept, deleteDept } from '../../store/actions/actionCreator'
import '../buttonCss.css' 

function Details(props) { 
    let {history, className} = props; 
    let dispatch = useDispatch() 
    let [isEditable, setIsEditable] = useState(false)
    let [showModal, setShowModal] = useState(false) 

    let { dataDetails: state, allData } = useSelector(state => state.department) 

    try { 
        if(!Object.keys(allData).length) { allData.departments = [] } 
    } catch (error) { } 

    const DeleteToast = () => showToast('Deleted Successfully') 
    const UpdateToast = () => showToast('Updated Successfully') 
    const ErrorToast = () => showToast('Server Error') 
    const DependencyToast = () => showToast('Cannot Delete. There are dependency') 

    let { id } = props.match.params; 
    useEffect(() => { 
        dispatch(getAllDept(ErrorToast)) 
        dispatch(getSingleDept(id, ErrorToast)) 
    }, []) 

    const NowDelete = async () => { 
        try { 
            Axios.delete(DeleteDepartment(id)) 
                .then(res => { 
                    if(res.data.isDeletable) { 
                        DeleteToast(); 
                        dispatch(deleteDept(state)); 
                        props.history.push('/app/department/'); 
                    } 
                    else { 
                        DependencyToast(); 
                        props.history.push('/app/department/'); 
                    } 
                }) 
                .catch(err => { ErrorToast(); }) 
        } 
        catch (error) {} 
    } 


    let emptyName = ''; 
    return ( 
        <> 
            {/* Delete Modals */} 
            {
                showModal &&
                <Modal isOpen={showModal} className={className}>
                    <ModalHeader>Delete Department</ModalHeader>
                    <ModalBody>
                        Are you sure, you want to delete?
                    </ModalBody> 
                    <ModalFooter> 
                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                            className="btnHover" onClick={NowDelete}> 
                            Delete 
                        </Button> 
                           
                        <Button style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }} size="sm"
                            className="btnHover" onClick={() => setShowModal(false)}>
                            Cancel
                        </Button> 
                    </ModalFooter> 
                </Modal> 
            } 


            {/* Details view */}
            <div className="container"> 
                <Breadcrumb path="Department" slug={state.name} link="/app/department/" backButtonFunction={backButtonFunction} /> 
                
                <Button size="sm" style={{ margin: '10px 0px 10px 0px', background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    className="btnHover" onClick={() => { dispatch(backButtonFunction()); history.push('/app/department/'); }}> 
                    Back
                </Button>
                {
                    !isEditable && 
                    <Button size="sm" className="ml-2 btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                        onClick={() => setIsEditable(true)}>
                        Edit 
                    </Button> 
                } 
                <Button size="sm" className="ml-2 btnHover" style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}
                    onClick={ () => setShowModal(true) }>
                    Delete
                </Button>


                {/* Data on card view */}
                <Card>
                    <CardBody> 
                    { 
                        state.name && 
                        <Formik 
                            initialValues={{ name: state.name, status: state.active, description: state.description}}
                            validationSchema={ 
                                Yup.object().shape({ 
                                    name: Yup 
                                        .string().required("Required").trim().lowercase()
                                        .notOneOf(Object.keys(allData).length ? allData.departments.map(item => {
                                            if(item.name.toLowerCase() !== state.name.toLowerCase()) {
                                                return item.name.toLowerCase() 
                                            } 
                                        }) : [], "Already exists"), 
                                }) 
                            } 
                            onSubmit={(values, { resetForm }) => { 
                                let edittedData = { 
                                    name: values.name.trim(), 
                                    active: values.status, 
                                    description: values.description 
                                }  
                                dispatch(updateDept(id, edittedData, UpdateToast, ErrorToast));
                                setIsEditable(false);
                                resetForm(); 
                                history.push('/app/department/') 
                            }}> 
                            { 
                            props => ( 
                                <Form onSubmit={props.handleSubmit}>
                                    <FormGroup row>
                                        <Label htmlFor="name" sm={2} style={{ marginTop: '-7px' }}> 
                                            Name: <span style={{color: 'red'}}>{isEditable && ` *`}</span>
                                        </Label>
                                        <Col sm={4}> 
                                        { 
                                            isEditable 
                                            ? <> 
                                                <Input 
                                                    type="text" name="name" id="name" 
                                                    style={{border: props.errors.name && props.touched.name ? '1px solid red' : null}}
                                                    value={props.values.name} 
                                                    onChange={(e) => { 
                                                        if(e.target.value === '') { emptyName = 'empty' } 
                                                        if(e.target.value) { emptyName = '' } 
                                                        props.handleChange(e) 
                                                    }} 
                                                    onBlur={props.handleBlur} 
                                                /> 
                                                <span style={{display: 'none'}}> 
                                                    {emptyName === 'empty' ? props.errors.name = 'Required' : null}
                                                </span> 
                                                
                                                <p style={{ 
                                                    color: 'red', fontSize: '12px', marginLeft: '5px', 
                                                    textAlign: 'left', fontWeight: 'bold' 
                                                }}> {props.errors.name} </p> 
                                            </> 
                                            : <span> { state.name } </span> 
                                        } 
                                        </Col> 
                                        
                                        { isEditable ? <Label htmlFor="status"> Status </Label> : <Label> Status </Label> } 
                                        <Col sm={4}> 
                                        { 
                                            isEditable ?
                                            <Input
                                                type="checkbox" name="status" id="status" className="form-check-input"
                                                checked={props.values.status} onChange={props.handleChange} onBlur={props.handleBlur} 
                                            /> 
                                            : 
                                            <Input type="checkbox" name="status" id="status" className="form-check-input" readOnly={true} checked={state.active}/> 
                                        }
                                        </Col> 
                                    </FormGroup> 

                                    <FormGroup row> 
                                        <Label htmlFor="description" sm={2} style={{ marginTop: '-7px' }}> Description </Label> 
                                        <Col sm={10}> 
                                        { 
                                            isEditable ? 
                                                <Input 
                                                    rows="3" type="textarea" name="description" id="description" 
                                                    value={props.values.description} onChange={props.handleChange} onBlur={props.handleBlur} 
                                                /> 
                                            : <span> {state.description} </span> 
                                        } 
                                        </Col> 
                                    </FormGroup> 
                                    { 
                                        isEditable && 
                                        <Button
                                            type="submit" size="sm" className="btnHover"
                                            style={{ background: '#1B998B', borderColor: '#1B998B', color: '#fff' }}>
                                            Save
                                        </Button>
                                    } 
                                </Form> 
                                ) 
                            } 
                        </Formik> 
                    } 
                    </CardBody> 
                </Card> 
            </div> 
        </> 
    ) 
} 

export default withRouter(Details) 
