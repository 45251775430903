import React from "react";
// import { render } from "react-dom";
import { Formik } from "formik";
import { connect } from 'react-redux'
import { addContactCard } from '../../redux/actions'
// import * as Yup from "yup";
import './style.css'
import { QuestionTitle } from '../styles'

const ContactCard = props => (
        <div className="contact-card" >
            {/* {console.log(props)} */}
            {/* <h1>We value your opinion!</h1> */}
            {/* <QuestionTitle>We value your opinion!</QuestionTitle> */}
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    gender: "",
                    age: "",
                    contact_number: "",
                    birth_date: "",
                    district: "",
                    postal_code: "",
                    profession: "",
                    organization: "",
                    fields: props.fields.question.questionBank.order.cards.contactCard
                }}
                onSubmit={(values, { setSubmitting }) => {
                    // setTimeout(() => {
                    //     alert(JSON.stringify(values, null, 2));
                    //     setSubmitting(false);
                    // }, 500);
                    props.addContactCard(values)
                    setSubmitting(false);
                    props.contactCardSubmit()
                }}
            // validationSchema={Yup.object().shape({
            //     name: Yup.string().required("Required"),
            //     email: Yup.string()
            //         .email()
            //         .required("Required"),
            //     gender: Yup.string().required("Required"),
            //     age: Yup.string().required("Required"),
            //     contact_number: Yup.string().required("Required"),
            //     birth_date: Yup.string().required("Required"),
            //     district: Yup.string().required("Required"),
            //     postal_code: Yup.string().required("Required"),
            //     profession: Yup.string().required("Required"),
            //     organization: Yup.string().required("Required")
            // })}
            >
                {props => {
                    const {
                        values,
                        touched,
                        errors,
                        // dirty,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit
                        // handleReset,
                    } = props;
                    return (
                        <form onSubmit={handleSubmit}>
                            {/* {console.log('fields: ')} */}
                            {/* age: true
                        birth date: true
                        contact number: false
                        district: true
                        email: false
                        gender: true
                        name: true
                        organization/institution: false
                        postal code: false
                        profession: false */}
                            {values.fields.fields['name'] && <div>
                                <label htmlFor="name" style={{ display: "block" }}>
                                    Name
                            </label>
                                <input
                                    id="name"
                                    placeholder="Enter your name"
                                    type="text"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.name && touched.name
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.name && touched.name && (
                                <div className="input-feedback">{errors.name}</div>
                            )} */}
                            </div>
                            }

                            {values.fields.fields['email'] && <div>
                                <label htmlFor="email" style={{ display: "block" }}>
                                    Email
                            </label>
                                <input
                                    id="email"
                                    placeholder="Enter your email"
                                    type="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.email && touched.email
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.email && touched.email && (
                                <div className="input-feedback">{errors.email}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['gender'] && <div>
                                <label htmlFor="gender" style={{ display: 'block' }}>
                                    Gender
                            </label>
                                <select
                                    name="gender"
                                    value={values.gender}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    style={{ display: 'block' }}
                                // className={
                                //     errors.gender && touched.gender
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                >
                                    <option value="" label="Select a gender" />
                                    <option value="male" label="Male" />
                                    <option value="female" label="Female" />
                                    <option value="other" label="Other" />
                                </select>
                                {/* {errors.gender &&
                                touched.gender &&
                                <div className="input-feedback">
                                    {errors.gender}
                                </div>} */}
                            </div>}

                            {values.fields.fields['age'] && <div>
                                <label htmlFor="age" style={{ display: "block" }}>
                                    Age
                                </label>
                                <input
                                    id="age"
                                    placeholder="Enter your age"
                                    type="number"
                                    value={values.age}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    min="10"
                                    max="120"
                                // className={
                                //     errors.age && touched.age ? "text-input error" : "text-input"
                                // }
                                />
                                {/* {errors.age && touched.age && (
                                <div className="input-feedback">{errors.age}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['contact number'] && <div>
                                <label htmlFor="contact_number" style={{ display: "block" }}>
                                    Contact Number
                                </label>
                                <input
                                    id="contact_number"
                                    placeholder="Enter your contact number"
                                    type="number"
                                    value={values.contact_number}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.contact_number && touched.contact_number
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.contact_number && touched.contact_number && (
                                <div className="input-feedback">{errors.contact_number}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['birth date'] && <div>
                                <label htmlFor="birth_date" style={{ display: "block" }}>
                                    Birth Date
                                </label>
                                <input
                                    id="birth_date"
                                    placeholder="Enter your birth date"
                                    type="date"
                                    value={values.birth_date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.birth_date && touched.birth_date
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.birth_date && touched.birth_date && (
                                <div className="input-feedback">{errors.birth_date}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['district'] && <div>
                                <label htmlFor="district" style={{ display: "block" }}>
                                    District
                                </label>
                                <input
                                    id="district"
                                    placeholder="Enter your district"
                                    type="text"
                                    value={values.district}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.district && touched.district
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.district && touched.district && (
                                <div className="input-feedback">{errors.district}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['postal code'] && <div>
                                <label htmlFor="postal_code" style={{ display: "block" }}>
                                    Postal Code
                                </label>
                                <input
                                    id="postal_code"
                                    placeholder="Enter your postal code"
                                    type="text"
                                    value={values.postal_code}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.postal_code && touched.postal_code
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.postal_code && touched.postal_code && (
                                <div className="input-feedback">{errors.postal_code}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['profession'] && <div>
                                <label htmlFor="profession" style={{ display: "block" }}>
                                    Profession
                                </label>
                                <input
                                    id="profession"
                                    placeholder="Enter your profession"
                                    type="text"
                                    value={values.profession}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.profession && touched.profession
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.profession && touched.profession && (
                                <div className="input-feedback">{errors.profession}</div>
                            )} */}
                            </div>}

                            {values.fields.fields['organization/institution'] && <div>
                                <label htmlFor="organization" style={{ display: "block" }}>
                                    Organization
                                </label>
                                <input
                                    id="organization"
                                    placeholder="Enter your organization"
                                    type="text"
                                    value={values.organization}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                // className={
                                //     errors.organization && touched.organization
                                //         ? "text-input error"
                                //         : "text-input"
                                // }
                                />
                                {/* {errors.organization && touched.organization && (
                                <div className="input-feedback">{errors.organization}</div>
                            )} */}
                            </div>}

                            {/* <button
                            type="button"
                            className="outline"
                            onClick={handleReset}
                            disabled={!dirty || isSubmitting}
                            >
                                Reset
                            </button> */}
                            <div className="text-right">
                                <button type="submit" disabled={isSubmitting}>
                                    Next
                            </button>
                            </div>
                            {/* <DisplayFormikState {...props} /> */}
                        </form>
                    );
                }}
            </Formik>

            {/* <MoreResources /> */}
        </div >
);

export default connect(null, { addContactCard })(ContactCard)
