import React from 'react';
import './ProgressBar.css';

const ProgressBar = (props) => {

    const {value, height} = props; 

    const style = {
        backgroundColor: props.bg,
        width: `${value}%`,
        fontSize:"12px",
        fontWeight:"700",
        borderRadius: "2px"
    }


    return (
        <div className="progress mt-2 mb-2" style={{ height: height, borderRadius:"2px" }}>
            <div className="progress-bar"
                style={style} 
                role="progressbar"  
                aria-valuenow={`${value}`}
                aria-valuemin="0" 
                aria-valuemax="100">
                {false ? `${value}%` : ""}
            </div>
        </div>
    );
    
}
export default ProgressBar;