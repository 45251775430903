import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import whyDidYouRender from '@welldone-software/why-did-you-render'
import './style.css'
import { questionDataSelector, answerFieldValueSelector, } from '../../redux/selectors'
import { updateData, nextConditionalQuestion, startDate } from '../../redux/actions'
import { QuestionCard, Fields, FieldItem, QuestionTitle } from '../styles'

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: "green",
  diffNameColor: "aqua"
})

const MultipleChoice = props => {
  const { data, updateData, answer, nextConditionalQuestion, startDate } = props
  const isConditional = data.isConditional
  const jump = data.jump
  // console.log(data, updateData, answer)

  useEffect(() => {
    if (data.hasOwnProperty('id')) {
      startDate(new Date())
    }
  }, [data])

  useEffect(() => {
    if (answer.index !== null) {
      if (isConditional) {
        const nextQuestion = jump.find(item => item.jumpOptionIndex === answer.index)
        if (!nextQuestion) {
          nextConditionalQuestion("NEXT")
        } else {
          nextConditionalQuestion(nextQuestion.jumpTo)
        }
      }
    }
  }, [answer])

  const handleChange = event => {
    const { id, value } = event.target
    const index = parseInt(id, 10)
    updateData({
      index,
      value
    })
    if (isConditional) {
      const nextQuestion = jump.find(item => item.jumpOptionIndex === index)
      if (!nextQuestion) {
        nextConditionalQuestion("NEXT")
      } else {
        nextConditionalQuestion(nextQuestion.jumpTo)
      }
    }
  }

  const selectCheck = value => {
    return value === answer.index
  }

  const choiceFields = data.choices.map((choice, index) => {
    return (
      <FieldItem key={`choice-${index}`}>
        <input
          type='radio'
          name={data.id}
          id={index}
          value={choice}
          onChange={handleChange}
          checked={selectCheck(index)}
          className="mcqres"
        />
        <label htmlFor={index}>
          {choice}
        </label>
      </FieldItem>
    )
  })

  return (
    <QuestionCard>
      <QuestionTitle>{data.title}</QuestionTitle>
      <Fields>
        {choiceFields}
      </Fields>
    </QuestionCard>
  )
}

MultipleChoice.whyDidYouRender = true

const mapStateToProps = state => {
  return {
    data: questionDataSelector(state),
    answer: answerFieldValueSelector(state),
  }
}

const mapDispatchToProps = {
  updateData,
  nextConditionalQuestion,
  startDate
}

export default memo(connect(mapStateToProps, mapDispatchToProps)(MultipleChoice))