import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from "react-redux"

import { FullView, Group, ButtonGroup, Button } from './styles'
import './style.css';

import SelectName from './SelectName'
import SelectActive from './SelectActive'
import SelectStatus from './SelectStatus'

// import SelectChannel from './SelectChannel'
// import SelectSubChannel from './SelectSubChannel'
import SelectProjects from './SelectProjects'
import ToggleButton from './ToggleButton'

import { projectFetch, reset } from '../../redux/actions'
import { viewSelector, projectPropertySelector, nextButtonSelector } from '../../redux/selectors'
// import SelectStatus from './SelectStatus';

const Configuration = props => {

  const { view, projectFetch, configButtonEnabled, reset } = props;

  useEffect(() => {
    const id = localStorage.getItem('jwtProfileId')
    projectFetch(id)
  },
    []
  )

  return (
    <FullView view={view}>
      <ToggleButton />
      {view &&
        <>

          <Group>
            <SelectName />
            <label htmlFor="project" className="dislabel">
              Select Question from Project:
            </label>
            <SelectProjects />

          </Group>

          <Group>


            <SelectActive />
            <SelectStatus />

            <ButtonGroup>
              <Button type='button' onClick={reset}>Reset</Button>
            </ButtonGroup>

          </Group>

        </>
      }

    </FullView>
  )
}

const mapStateToProps = state => {  //loads all the states from the store 
  return {
    view: viewSelector(state)
  }
}

const mapDispatchToProps = {
  projectFetch,
  reset
}

export default connect(mapStateToProps, mapDispatchToProps)(Configuration)  