import React from 'react'

import {Text} from './styles'

const TextBox = ()=>{
  return(
    <Text type='text' disabled />
  )
}

export default TextBox