import React from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChannelPill = (props) => {
    return (
        <div className="ChannelPill">
            <h6 className="channel-pill-title">
                <FontAwesomeIcon icon={props.icon} style={{ marginRight: '4px' }} />{props.title}
            </h6>
            <p className="channel-pill-info">{props.value}</p>
        </div>
    );
}

export default ChannelPill;