import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { LinkButton } from './styles'
import { nextButtonSelector } from '../../redux/selectors'

const NextButton = props => {

    const { enabled, match } = props
    // console.log(match.path)
    return enabled && props.name !== '' ?
        <LinkButton
            to={`${match.path}/sortandpreview`}
        >
            Next
        </LinkButton>
        :
        <></>
}

const mapStateToProps = state => ({
    enabled: nextButtonSelector(state),
    name: state.question.name
})

export default withRouter(connect(mapStateToProps)(NextButton))