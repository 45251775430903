import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// sidebar icon
export const StyledFontAwesomeIcon = styled(FontAwesomeIcon).attrs(props=>({
  icon: props.icon,
  onClick: props.onClick
}))`
  font-size: 15px; 
  /* margin: 2px 10px;  */

  :hover { color: white; } 
  @media only screen and (max-width: 1920px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '2px 10px 2px 41px' } 
      else if(fullsidebar == 'false') { return '2px 10px' } 
    }}; 
  } 
  @media only screen and (max-width: 1600px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '2px 10px 2px 33px' } 
      else if(fullsidebar == 'false') { return '2px 10px' } 
    }}; 
  } 
  @media only screen and (max-width: 1440px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '2px 10px 2px 29px' } 
      else if(fullsidebar == 'false') { return '2px 10px' } 
    }}; 
  } 
  @media only screen and (max-width: 1366px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '2px 10px 2px 27px' } 
      else if(fullsidebar == 'false') { return '2px 10px' } 
    }}; 
  } 
  @media only screen and (max-width: 1024px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '2px 10px 2px 23px' } 
      else if(fullsidebar == 'false') { return '2px 10px' } 
    }}; 
  } 
  @media only screen and (max-width: 768px) { 
    margin: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return '3px 10px' } 
      else if(fullsidebar == 'false') { return '3px 10px 3px 21px' } 
    }}; 
  } 
  @media only screen and (max-width: 750px) { font-size: 12px; margin: 3px 10px 3px 20px; } 
  @media only screen and (max-width: 425px) { font-size: 12px; margin: 3.5px 10px 3.5px 20px; } 
`


export const Anchor = styled(NavLink).attrs({activeClassName: "active"})` 
  width: 100%; 
  color: ${props => props.color}; 
  padding: 7px 0px; 

  justify-content: ${({fullsidebar}) => { 
    if(fullsidebar == 'true') { return 'flex-start' } 
    else if(fullsidebar == 'false') { return 'center' } 
  }}; 

  &:hover { color: #fff; background: #1B998B; } 
  &:hover ${StyledFontAwesomeIcon} { color: #fff; } 

  @media only screen and (max-width: 768px) { 
    justify-content: ${({fullsidebar}) => { 
      if(fullsidebar == 'true') { return 'center' } 
      else if(fullsidebar == 'false') { return 'flex-start' } 
    }}; 
    width: ${props => props.fullsidebar == 'true' ? '100%' : '96%'}; 
  } 
` 



// export const StyledNavLink = styled(NavLink).attrs({activeClassName: "active"})`
export const StyledNavLink = styled.span`
  box-sizing: border-box; 
  text-decoration: none; 
  text-align: left; 
  display: block; 
  width: 100%; 
  font-size: 13px;
  display: ${props => props.fullSidebar == 'true' ? 'auto' : 'none'}; 

  @media only screen and (max-width: 768px) { 
    display: ${props => props.fullSidebar == 'true' ? 'none' : 'block'}; 
    font-size: 13px; 
  } 
` 



export const Body = styled.div`
  width: 100vw;
  height: 100vh;
` 



export const DeviceBody = styled.div`
  position: absolute;
  border-sizing: border-box;
  width: 100%;
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  top: 60px;
  font-size: 15px;
  margin:0;
  background-color: #ffffff;

  overflow-y: auto;

  @media only screen and (max-width: 768px){
    top: 35px;
    height: calc(100% - 35px);
    width: 100%; 
    font-size: 10px; 
  } 
`




// Sidebar
export const Sidebar = styled.nav`
  position: absolute;
  display: flex;
  width: ${props => props.fullSidebar == 'true' ? `16%` : `5%`}
  height: calc(100% - 40px);
  padding: 0;
  top: 60px; 
  left:0;
  background-color: #fff; 
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: 40;
  font-size: 14px;


  @media only screen and (max-width: 1300px) { 
    width: ${props => props.fullSidebar == 'true' ? `16%` : `7%`} 
  } 

  @media only screen and (max-width: 1024px) { 
    width: ${props => props.fullSidebar == 'true' ? `16%` : `6%`} 
  } 

  @media only screen and (max-width: 900px) { 
    width: ${props => props.fullSidebar == 'true' ? `20%` : `6%`} 
  } 

  @media only screen and (max-width: 768px) { 
    width: ${props => props.fullSidebar == 'true' ? `7.5%` : `25%`} 
  } 

  @media only screen and (max-width: 750px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `25%`}; 
    top: ${props => props.fullSidebar == 'true' ? 'none' : '45px'}; 
  } 


  @media only screen and (max-width: 650px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `40%`} 
  } 
  
  @media only screen and (max-width: 430px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `44%`} 
    z-index: 15;
  } 

  @media only screen and (max-width: 390px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `47%`} 
  } 

  @media only screen and (max-width: 370px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `49%`} 
  } 

  @media only screen and (max-width: 360px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `50%`} 
  } 

  @media only screen and (max-width: 350px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `50%`} 
  } 

  @media only screen and (max-width: 340px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `52%`} 
  } 

  @media only screen and (max-width: 325px) { 
    width: ${props => props.fullSidebar == 'true' ? `0%` : `54%`} 
  } 
` 






// Topbar
export const TopBar = styled.div`
  position: absolute;
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  top:0;
  z-index: 50;

  @media only screen and (max-width: 750px){
    height: 45px;
  }
`




// Click Bar
export const Span = styled.span`
  cursor: pointer; 
  padding-left: 25px; 

  /* @media only screen and (max-width: 900px){
    padding-left: 50px; 
  }
  @media only screen and (max-width: 768px){
    padding-left: 35px; 
  }
  @media only screen and (max-width: 420){
    padding-left: 20px; 
  } */
`





// Apps
export const Apps = styled.main`
  position: absolute;
  border-sizing: border-box;
  width: ${props => props.fullSidebar == 'true' ? `84%` : `95%`}; 
  height: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  top: 60px;
  left: ${props => props.fullSidebar == 'true' ? `16%` : `5%`}; 
  font-size: 15px; 
  margin: 0;
  background-color: #FFFFFF; 
  overflow-y: auto;

  @media only screen and (max-width: 1300px) { 
    width: ${props => props.fullSidebar == 'true' ? `84%` : `93%`}; 
    left: ${props => props.fullSidebar == 'true' ? `16%` : `7%`}; 
  } 

  @media only screen and (max-width: 1024px){ 
    width: ${props => props.fullSidebar == 'true' ? `84%` : `94%`}; 
    left: ${props => props.fullSidebar == 'true' ? `16%` : `6%`}; 
  } 

  @media only screen and (max-width: 900px) { 
    width: ${props => props.fullSidebar == 'true' ? `80%` : `94%`}; 
    left: ${props => props.fullSidebar == 'true' ? `20%` : `6%`}; 
  } 

  @media only screen and (max-width: 768px){ 
    width: ${props => props.fullSidebar == 'true' ? `92.5%` : `75%`}; 
    left: ${props => props.fullSidebar == 'true' ? `7.5%` : `25%`}; 
  } 

  @media only screen and (max-width: 750px) { 
    left: 0px; 
    width: 100%; 
  } 
` 





export const StyledList = styled.ul`
  list-style-type: none;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  display: block;
  overflow-y: scroll;
  ::-webkit-scrollbar{
    width: 0;
  }
  scrollbar-width: none;
`





// export const Expandable = styled(NavLink).attrs({activeClassName: "deactive"})`
export const Expandable = styled.li` 
  box-sizing: border-box; 
  text-decoration: none; 
  text-align: left; 
  display: block; 
  width: 100%; 
  color: #310D31; 
  cursor: pointer; 
  position: relative;
  
  @media only screen and (max-width: 768px) { 
    padding-top: 8px; 
    padding-bottom: 8px; 
  } 

  @media only screen and (max-width: 425px) { 
    padding-top: 3px; 
    padding-bottom: 3px; 
  } 
` 





// Icon 
export const CrmIcon = styled.i` 
  position: absolute; 
  margin: ${props => props.fullsidebar == 'true' ? '3px 5px 0px -20px' : '1px 0'}; 

  @media only screen and (max-width: 1024px) { 
    margin: ${props => props.fullsidebar == 'true' ? '2px 5px 0px -20px' : '1px -2px'}; 
  } 
  @media only screen and (max-width: 960px) { 
    margin: ${props => props.fullsidebar == 'true' ? '2px 5px 0px -20px' : '1px -5px'}; 
  } 
  @media only screen and (max-width: 768px) { 
    margin: ${props => props.fullsidebar == 'true' ? '1px -5px' : '2px 5px 0px -20px'}; 
  } 
` 




export const User = styled.div`
    box-sizing: border-box;
    /* border: 3px solid #1B988B; */
    min-width: 80px;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: 10px;
    background-color: transparent;
    font-size: 12px;
    color: black;
    cursor: pointer;
    float: right;
    >img {
      vertical-align: middle;
      width: 40px;
      box-shadow: 0 0 3px grey;
      border-radius: 50%;
      border: 1px solid #310D31; 
    }
    :hover{
      color: #310D31;
      font-weight: bold;
    }

    @media only screen and (max-width: 768px){
      font-size: 10px;
      min-width: 50px;
      margin-right: 0px;
      >img {width: 30px;}
    }
  `

//=========User profile Modal Starts============

export const ModalContainer = styled.div`  
  position: fixed;
  display: ${props => props.view ? "flex" : "none"};
  box-sizing: border-box;
  flex-direction: column;
  font-size: 12px;
  width: 250px;
  height: 160px;
  z-index: 80;
  right: 10px;
  @media only screen and (max-width: 425px){
    font-size: 10px;
    width: 200px;
    height: 110px;
  }
`


export const ModalArrow = styled.div`
  box-sizing: border-box;
  width: 0; 
  height: 0;
  margin-left: 165px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(255,255,255);
`


export const Modal = styled.div`  
  display: flex;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  color: black;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.8);
  box-shadow:  0 1px 5px grey;
`


export const ModalDiv_Name = styled.div` 
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  width: 100%;
  height: 50%;
  color: rgb(52, 58, 64);
  flex-direction: row;
  border-bottom: 2px solid rgb(52, 58, 64); 
  background-color: rgba(255,255,255,.8);
  >img {
    vertical-align: middle;
    width: 25%;
    margin: 7px;
    box-shadow: 0 0 3px grey;
    border-radius: 50%;
    border: 2px solid #310D31; 
  }
  >div {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    flex-direction: column;
    width: 70%;
    padding-top: 20px;
  }
  @media only screen and (max-width: 425px){
    >img {width: 20%}
    >div {padding-top: 10px}
  }
`


export const ModalDiv_ViewProfile = styled(NavLink)`  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  padding-left: 10px;
  position: relative;
  width: 100%;
  height: 25%;
  color: rgb(52, 58, 64);
  cursor: pointer;
  /* font-weight: 500; */
  background-color: rgba(255,255,255,.8);
  :hover{
    background-color: #1B998B;
    color: white;
    transition: .3s;
    bottom: 1px;
  }
`


export const ModalDiv_Logout = styled.div`  
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  padding-left: 10px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 25%;
  color: rgb(52, 58, 64);
  cursor: pointer;
  /* font-weight: 500; */
  /* font-family: 'Roboto'; */
  background-color: rgba(255,255,255,.8);
  :hover{
    background-color: #1B998B;
    color: white;
    transition: .3s; 
  }
  `
export const Line = styled.div`
  width: 90%;
  height: 1px;
  border-bottom: 1px solid #310D31;
`
//=========User profile Modal Ends============
