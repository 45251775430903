import React, {useState, useEffect} from 'react'
import {Formik} from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Button, Input, Form, FormGroup, Modal, ModalHeader, ModalBody } from 'reactstrap' 
import baseUrl from '../../../../url'
import showToast from '../../../../components/Toast/toast' 
import { parsePhoneNumber } from 'react-phone-number-input' 
import { getName } from 'country-list'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {css} from 'glamor'
import './input.css'

function RequestDemo(props) { 
  let {cancelModal} = props; 

  const [jobTitle, setJobTitle] = useState([ 
    { label: 'Associate'  }, 
    { label: 'Consultant' }, 
    { label: 'Director'   }, 
    { label: 'Executive'  }, 
    { label: 'Manager'    }, 
    { label: 'Others'     }  
  ]) 

  const [allIndustries, setAllIndustries]     = useState([]) 
  const [reqFor, setReqFor]                   = useState([]) 
  const [showStar, setShowStar]               = useState(false) 
  const [makeRequired, setMakeRequired]       = useState(false) 
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false) 
  const [showMsg, setShowMsg]                 = useState(false) 
  const [submitMsg, setSubmitMsg]             = useState('') 

  // API - Industry
  useEffect(() => { 
    axios.get(`${baseUrl}/api/prm/industry`) 
      .then(res => { 
        res.data.data.sort((a, b) => { 
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase(); 

          let comparison = 0; 
          if (nameA > nameB) { comparison = 1; } 
          else if (nameA < nameB) { comparison = -1; } 
          return comparison; 
        })
        setAllIndustries(res.data.data) 
      }) 
      .catch(err => console.log('Server Error')) 
  }, []) 


  // API - Req demo for
  useEffect(() => { 
    axios.get(`${baseUrl}/api/features/list/allName`) 
      .then(res => { 
        res.data.sort((a, b) => { 
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase(); 

          let comparison = 0; 
          if (nameA > nameB) { comparison = 1; } 
          else if (nameA < nameB) { comparison = -1; } 
          return comparison; 
        })
        setReqFor(res.data) 
      }) 
      .catch(err => console.log('Server Error')) 
  }, []) 

  const styles = { 
    error: { fontSize: '11.5px', color: '#F27D7D', margin: '-10px 0 2px 15px', fontWeight: '500' }, 
    button: {background: '#1B998B', borderColor: '#1B998B', fontSize: '12px !important'} 
  } 

  const inputCss = css({ height: 'calc((1.5em + 0.3rem) + 2px) !important' }) 

  const ErrorToast   = () => showToast('Server Error') 
  
  return ( 
    <>
      <Formik 
        initialValues={{ 
          firstName: '', lastName: '', email: '', company: '', accountType: '', 
          phone: '', job: '', industry: '', demoFor: '', description: '' 
        }} 
        validationSchema={ 
          Yup.object().shape({ 
            firstName:    Yup.string().required("Required").trim(), 
            email:        Yup.string().email().required("Required").trim(), 
            company:      Yup.string().trim(), 
            // phone:        Yup.string().required("Required").min(10, 'Invalid Number').trim(), 
            phone:        Yup.string().min(10, 'Invalid Number').trim(), 
            accountType:  Yup.string().required("Required").trim() 
          }) 
        } 
        onSubmit={(values, { resetForm }) => { 
          let data = { 
            firstName:      values.firstName.trim(), 
            lastName:       values.lastName.trim(), 
            email:          values.email.trim(), 
            company:        values.company.trim(), 
            phoneNo:        values.phone.trim(), 
            jobTitle:       values.job,
            industry:       values.industry, 
            country:        values.phone.trim() ? getName(parsePhoneNumber(values.phone.trim()).country) : '', 
            requestDemoFor: values.demoFor,  
            accountType:    values.accountType, 
            otherQuery:     values.description.trim() 
          } 
          console.log(data); 
          
          axios.post(`${baseUrl}/api/requestDemo`, data) 
            .then(res => {
              if(res.data.isValid) { 
                setSubmitMsg(res.data.message); 
                setShowMsg(true); 
              } 
              else 
                setInvalidEmailMsg(true) 
            }) 
            .catch(err => ErrorToast()) 
          resetForm() 
        }}> 
        { 
          props => ( 
            <Form onSubmit={(e) => { 
              e.preventDefault() 
              if(!makeRequired) { props.handleSubmit() } 
            }}> 
              {/* First name */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="firstName" id="firstName" 
                  value={props.values.firstName} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.firstName && props.touched.firstName ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur} placeholder="First Name" 
                  autoComplete="off" 
                /> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { 
                props.errors.firstName && props.touched.firstName && 
                <p style={styles.error}> {props.errors.firstName} </p> 
              } 
              
              {/* Last name */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="lastName" id="lastName"
                  value={props.values.lastName}
                  onChange={(data) => {props.handleChange(data)}} 
                  {...inputCss}
                  onBlur={props.handleBlur} placeholder="Last Name"
                  className="inputField ml-3"
                  autoComplete="off"
                /> 
              </FormGroup> 

              {/* Country */} 
              <>
                {/* <FormGroup row style={{marginBottom: '0.5em'}}> 
                  <Label htmlFor="country" sm={4} style={{fontSize: '14px'}}> 
                    Country: <span style={{color: 'red'}}>{` *`}</span> 
                  </Label> 
                  <Col sm={8}> 
                    <Input type="select" name="country" id="country" 
                      value={props.values.country} 
                      onChange={props.handleChange} 
                      style={{
                        height: 'calc(1.5em + 0.5rem + 2px)', 
                        border: props.errors.country && props.touched.country ? '1px solid red' : null
                      }} 
                      onBlur={props.handleBlur}> 
                      <option value="" disabled defaultValue hidden
                        style={{fontSize: '15px', color: '#768192', fontWeight: '400'}}>
                        Select...
                      </option> 
                      { !allCountryList.length && <option value="" disabled defaultValue>No data</option> } 
                      { 
                        allCountryList.length && 
                        allCountryList.map(obj => <option value={obj.label} key={obj.label}> {obj.label} </option> ) 
                      } 
                    </Input> 
                    { 
                      props.errors.country && props.touched.country && 
                      <p style={styles.error}> {props.errors.country} </p> 
                    } 
                  </Col> 
                </FormGroup>  */}
              </> 
              
              {/* Phone */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <PhoneInput 
                  placeholder="Phone Number" name="phone" 
                  className="inputField ml-3" 
                  countryCodeEditable={false} 
                  value={props.values.phone} 
                  style={{ 
                    border: props.errors.phone && props.touched.phone ? '1px solid #F27D7D' : null, 
                    width: '277px', borderRadius: '20px'
                  }} 
                  onBlur={() => props.setFieldTouched('phone', true)}
                  onChange={(data) => props.setFieldValue('phone', data, true)} /> 
                {/* <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span>  */}
              </FormGroup> 
              { 
                props.errors.phone && props.touched.phone && 
                <p style={styles.error}> {props.errors.phone} </p> 
              } 


              
              {/* A. type */}
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="accountType" id="accountType" 
                  value={props.values.accountType} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.accountType && props.touched.accountType ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden> 
                    Select a account type...
                  </option> 
                  <option value="Individual"> Individual </option> 
                  <option value="Company"> Organization / Company </option> 
                </Input> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
                { 
                  props.values.accountType === 'Company'
                  ? setShowStar(true) 
                  : setShowStar(false) 
                } 
                { 
                  props.values.accountType === 'Company'
                  ? setMakeRequired(true) 
                  : setMakeRequired(false) 
                } 
              </FormGroup> 
              { 
                props.errors.accountType && props.touched.accountType && 
                <p style={styles.error}> {props.errors.accountType} </p> 
              } 
              
              {/* Company */}
              <FormGroup row style={{marginBottom: '0.5em', width: showStar ? '300px' : '292px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="company" id="company"
                  value={props.values.company}
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: makeRequired || (props.errors.company && props.touched.company) ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur} placeholder="Company Name"
                  autoComplete="off"
                /> 
                {
                  props.values.company 
                  ? setMakeRequired(false) 
                  : props.values.accountType === 'Company' && setMakeRequired(true) 
                } 
                { showStar && <span style={{color: '#F27D7D'}}>{` *`}</span> }
              </FormGroup> 
              { makeRequired && <p style={styles.error}> Required </p> } 
              
              {/* Job */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="job" id="job" 
                  value={props.values.job} 
                  onChange={props.handleChange} 
                  style={{height: 'calc(1.5em + 0.5rem + 2px)'}} 
                  className="inputField ml-3" {...inputCss} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden>Select a job role</option> 
                  { !jobTitle.length && <option value="" disabled defaultValue>No data</option> } 
                  { jobTitle.length && jobTitle.map(obj => <option value={obj.label} key={obj.label}> {obj.label} </option> ) } 
                </Input> 
              </FormGroup> 

              {/* Email */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '300px', flexWrap: 'nowrap'}}> 
                <Input type="text" name="email" id="email"
                  value={props.values.email}
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  style={{ 
                    border: props.errors.email && props.touched.email ? '1px solid #F27D7D' : null 
                  }} 
                  onBlur={props.handleBlur} placeholder="Enter your email"
                  autoComplete="off"
                /> 
                <span style={{color: '#F27D7D', marginLeft: '2px'}}>{`*`}</span> 
              </FormGroup> 
              { props.errors.email && props.touched.email && <p style={styles.error}> {props.errors.email} </p> } 

              {/* Industry */}  
              <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="industry" id="industry" 
                  value={props.values.industry} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden>Select an industry...</option> 
                  { !allIndustries.length && <option value="" disabled defaultValue>No data</option> } 
                  { 
                    allIndustries.length && 
                    allIndustries.map(obj => <option value={obj.name} key={obj._id}> {obj.name} </option> ) 
                  } 
                </Input> 
              </FormGroup> 

              {/* Req demo */} 
              {/* <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="select" name="demoFor" id="demoFor" 
                  value={props.values.demoFor} 
                  onChange={props.handleChange} 
                  className="inputField ml-3" {...inputCss} 
                  onBlur={props.handleBlur}> 
                  <option value="" disabled defaultValue hidden>Select require demo...</option> 
                  { !reqFor.length && <option value="" disabled defaultValue>No data</option> } 
                  { reqFor.length && reqFor.map(obj => <option value={obj.name} key={`id:${obj._id}`}> {obj.name} </option> ) } 
                </Input> 
              </FormGroup>  */}

              {/* Description */} 
              <FormGroup row style={{marginBottom: '0.5em', width: '292px', flexWrap: 'nowrap'}}> 
                <Input type="textarea" name="description" id="description" 
                  value={props.values.description} onChange={props.handleChange} 
                  className="textAreaField ml-3" {...inputCss} style={{height: '75px', resize: 'none'}} 
                  placeholder="Any Query" 
                  onBlur={props.handleBlur} /> 
              </FormGroup> 
              
              <Button type="submit" className="btnHover mr-2" size="sm" style={{...styles.button, fontSize: '13px'}}> 
                Submit 
              </Button> 
              
              <Button className="btnHover" size="sm" onClick={() => cancelModal()} style={{...styles.button, fontSize: '13px'}}> 
                Cancel 
              </Button> 

              <p className="mt-3 text-justify" style={{fontSize: '10px', color: 'rgb(52, 58, 64)', textAlign: 'justify'}}>
                By submitting this form, you agree to receive demonstration communication from ProlificXM as set out in our {` `}
                <a href="/terms" target="_blank">Terms of service</a> {` `}
                and {` `}
                <a href="/terms#PS-pps" target="_blank">Privacy Policy</a>. {` `}
                You also agree to receive information and offers relevant to our services via email. You can unsubscribe the emails anytime.
              </p> 
            </Form> 
          ) 
        } 
      </Formik> 

      
      {/* Invalid email */}
      <Modal isOpen={invalidEmailMsg} className={props.className} style={{top: '150px', left: '-8px'}}> 
        <ModalHeader style={{color: 'rgb(244, 60, 60)', padding: '0.5rem 1rem'}}> 
          Error 
        </ModalHeader> 
        <ModalBody> 
          Please enter a valid bussiness email address 
          <br /> 
          <Button className="btnHover mt-3" size="sm" style={styles.button}
            onClick={() => setInvalidEmailMsg(false)}> 
            Close 
          </Button> 
        </ModalBody> 
      </Modal> 

      {/* Success */}
      <Modal isOpen={showMsg} className={props.className} style={{top: '150px', left: '-8px'}}> 
        <ModalHeader style={{color: '#1B998B', padding: '0.5rem 1rem'}}> 
          Requested successfully  
        </ModalHeader> 
        <ModalBody> 
          {submitMsg} 
          <br /> 
          <Button className="btnHover mt-3" size="sm" style={styles.button}
            onClick={() => setShowMsg(false)}> 
            Close 
          </Button> 
        </ModalBody> 
      </Modal> 
    </> 
  ) 
} 

export default RequestDemo; 
