import styled from 'styled-components'
import { Link, NavLink } from 'react-router-dom'

export const Body = styled.div` 
  position: relative; 
  /* display: flex;  */
  border-sizing: border-box; 
  width: 100%; 
  flex-flow: row wrap; 
  justify-content: flex-start; 
  align-items: flex-start; 
  align-content: flex-start; 
  margin: 15px; 
` 


export const Banner = styled.div`
  disply: flex;
  width: 100%;
  height: 50px;
  color: white;
  margin: 10px;
  text-align: center;
  background-color: #1B998B;
  padding-top: 18px;
  font-weight: bold;
`

export const UserItem = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;  
  flex-basis: 10%;
  padding: 10px;
  height: 150px;
  cursor: pointer;
  transition: width 2s;
  @media only screen and (max-width: 1024px){
    flex-basis: 25%;
  }
  @media only screen and (max-width: 425px){
    flex-basis: 50%;
  }
`

export const User = styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
width: 100%;
transition: width 2s;
background-color: white;
box-shadow: 2px 2px 5px grey;
>div {
  width:100%;
  color: white;
  flex-direction: column;
}
${UserItem}:hover &{
  box-shadow: 5px 5px 10px grey;
}
@media only screen and (max-width: 768px){
  font-size:15px;
}
`

export const Button = styled.button`
  display: block;
  margin: 15px auto;
  box-sizing: border-box;
  text-decoration: none;
  color: #384363;
  border: none;
  background-color: #AEC6CF;
  height: 30px;
  padding: 5px 10px;
  text-align: center;
  color: #C42590;
  box-shadow: 1px 1px 5px -1px #586469;
  outline: none;
  :hover{
    cursor: pointer;
    background-color: #8DA2A8;
    box-shadow: 1px 1px 5px 2px #586469;
  }
`