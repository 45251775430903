import React from 'react'
import image from './assets/employee.png'
import E_SAT from './assets/employee_satisfaction.png'
import E_engagement from './assets/employee_engagement.png'
import banner from './assets/employee_experience.png'
import {css} from 'glamor'

function Employee() { 

  const header = css({textAlign: 'left', color: 'rgb(52, 58, 64)', fontSize: '22px'})
  const text = css({textAlign: 'justify', color: 'rgb(52, 58, 64)', fontSize: '15px'})
  const bannerWidth = css({
    width: '85% !important', 
    '@media(max-width: 600px)': { width: '100% !important' },
  }) 
  const imageHideOnMobile = css({'@media(max-width: 767px)': { display: 'none !important' }}) 

  return ( 
    <div className="mt-4"> 
      <div className="text-center mb-4"> 
        <img src={banner} alt="Employee experience banner" {...bannerWidth} /> 
      </div> 

      <p {...text}> 
        Have you ever thought about why you are losing one of your efficient employees every six months? Why some of your employees can not meet the task deadline ever? What are the facilities your employees lacking to be devoted to their duty? Employee experience can bring out all the answers. Employee experience is a modern way to know your employees. It gathers employee insights about their tasks, workplace, business and company culture for the purpose of analysis and provides better employee solutions. 
      </p>

      <p {...text}>
        Employee experience helps a lot to measure how dynamic a company’s human resource is. It is people who actually keep running the pace of the workforce. So it isvery much important to know what they are thinking about their work, what type of issues they are facing, and what type of suggestions they have to improve themselves. Employee experience collects all of the data from the employees and then after analyzing gives results on the basis of different metrics like employee journey map, employee satisfaction, employee effort score, company culture, company image, interview experience, etc. 
      </p> 
      
      {/* Image */} 
      <div className="text-center"> 
        <img src={image} alt="employee experience" className="img-fluid" style={{maxHeight: '300px'}} /> 
      </div> 
      <br /> 

      <p {...text}>
        ProlificXM helps you to turn your employees as valuable resources, it helps you to turn them as more productive, more reliable and more sustainable. ProlificXM revolves employee whole journey from being freshly recruited to being a loyal one, evaluates employee performances, measures employee effort score, collects training/meeting feedback, gathers employee’s thoughts on company culture and company image and so on. The insights ProlificXM brings out from employees that can be put into reformation for turning employee-friendly work environments. The Artificial Intelligence of ProlificXM will guide to enrich the workforce who will turn your organization to the next level.
      </p>


      <h1 {...header}>Employee Journey Map</h1>
      <p {...text}>
        A person selects any company for his career path, applies there against any vacant position andgets considered for the post, it is then when his/her journey as an employee starts with thatcompany. The recruitment process is a long journey. Application, written exam, viva-voce,training, internship and business-wise others recruiting levels are there before onboarding of anemployee. EX pulls out that information on how much hardens an employee faced during his/herrecruitment period, how satisfied he/she was with the recruitment process. These insights help tore-decorate the recruitment process for the future to bring out the most potential employees. EXcan evaluate employee performance in terms of efficiency and effectiveness and also can 
        evaluate employee loyalty. This analysis helps businesses to understand where thecommunication-gap is creating a loose-bond between organization and employee.
      </p> 


      {/* Engagement */}
      <> 
        <h1 {...header}>Employee Engagement</h1> 
        <div className="text-center"> 
          <img src={E_engagement} alt="Employee Engagement" 
            className="img-fluid d-sm-inline d-md-none d-lg-none mb-3" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row mb-3" style={{alignItems: 'center'}}> 
          <div className="col-sm-12 col-md-6 col-lg-6"> 
            <p {...text}> 
              As long as employees are engaging themselves whole-heartedly with the organization theiroutput will not come out at the utmost level. Employees are the wheels of any organization.Their enthusiastic participation speeds up the progress. Only assigned tasks cannot join theemployees with the workforce. A super comfortable company culture, brand image, friendlywork-environment, proper facilitation, and motivation make an employee work with fullconcentration. Even after making any mistake, if an employee gets formative feedback andincentive motivation then he/she will definitely try to rectify mistakes properly and not repeatthat again. His/her devotion to work will make holistic changes.
            </p> 
          </div> 
          <div className="text-center col-md-6 col-lg-6" {...imageHideOnMobile}> 
            <img src={E_engagement} alt="Employee Engagement" 
              className="img-fluid" style={{maxHeight: '270px'}} /> 
          </div> 
        </div> 
      </> 


      

      {/* Satisfaction */}
      <> 
        <h1 {...header}>Employee Satisfaction</h1> 
        <div className="text-center"> 
          <img src={E_SAT} alt="Employee Satisfaction" 
            className="img-fluid d-sm-inline d-md-none d-lg-none mb-3" style={{maxHeight: '300px'}} /> 
        </div> 
        <div className="row mb-3" style={{alignItems: 'center'}}> 
          <div className="text-center col-md-6 col-lg-5" {...imageHideOnMobile}> 
            <img src={E_SAT} alt="Employee Satisfaction" 
              className="img-fluid" style={{maxHeight: '270px'}} /> 
          </div> 
          <div className="col-sm-12 col-md-6 col-lg-7"> 
            <p {...text}> 
              Tasks, workplace environment, recruitment process, company image, company culture, facilitiesare some attributes that can measure employee satisfaction. The more content an employee is, themore effort he/she will give to make his/her work be properly done. Employee satisfaction is notjust limited to the workspace. When employees are getting opportunities to lead their social lifealong with their colleagues too, their bond becomes stronger which helps to create a friendlyworking environment. Such an environment increases employee satisfaction scores and leads tomassive productivity.
            </p> 
          </div> 
        </div> 
      </> 





      <h1 {...header}>Employee Net Promoter Score</h1>
      <p {...text}>
        My present work is one of the most supportive ones I have ever worked on before. Colleaguesare here no less than a friend. I feel less pressure to complete my task and more encouragementto serve the best through my work. My personal and professional life gets so balanced that I canmove in equal rhythm between them.
      </p> 
      <p {...text}>
        Employee net promoter score is how employees are recognizing and reviewing the organizationto others. How much loyal an employee towards the company is, employee engagement isfruitful or not, how much satisfaction an employee is feeling all of these insights can bemeasured through employee net promoter score. Satisfied employees like me are the promotersin eNPS who rank their organization high. They refer the organizations to friends, family, andothers. There will be some others who are neither happy nor unhappy basically neutral abouttheir organization. These passives are also needed to handle carefully as with a bit of little moreprecedence they can also be the promoters. On the contrary, there might be a group of peoplewho are not only dissatisfied but also negatively promote the organization. They are thedetractors who need extra and special attention to reduce the complexity they are facing and toprovide them proper guidance to back in track. Employees are the running power of anyorganization. Employee net promoter score helps to determine how strongly this power isgenerated in the organization.
      </p> 
    </div> 
  ) 
} 

export default Employee; 
