import { 
  CREATE, GET_ALL, GET_SINGLE, UPDATE, 
  DELETE, BACK_BUTTON_IN_DETAIL_PAGE
} from '../actions/actionTypes'

let init = {  
  allData: { 
    count: 0, 
    postList: [] 
  }, 
  dataDetails: {} 
} 

const blogReducer = (state = init, action) => { 
  switch (action.type) { 
    case CREATE: { 
      const allDatas = state.allData.postList.concat(action.payload) 
      return { 
        ...state, 
        allData: { 
          count: allDatas.length, 
          postList: allDatas 
        } 
      } 
    } 
    case GET_ALL: { 
      return { 
        ...state, 
        allData: action.payload 
      } 
    } 
    case GET_SINGLE: { 
      return { 
        ...state, 
        dataDetails: action.payload 
      } 
    } 
    case UPDATE: { 
      let data = state.allData.postList.map(obj => { 
        if(obj._id === action.payload._id) { return action.payload } 
        return obj 
      }) 
      let datas = { coutn: data.length, postList: data } 
      return { 
        ...state, 
        allData: datas, 
        dataDetails: {} 
      } 
    } 
    case DELETE: { 
      let data = state.allData.postList.filter(obj => { 
        if(obj._id !== action.payload.state._id) { return obj } 
      }) 
      let datas = { count: data.length, postList: data } 
      
      return { 
        ...state, 
        allData: datas, 
        dataDetails: {} 
      } 
    } 
    case BACK_BUTTON_IN_DETAIL_PAGE: {
      return { 
        ...state, 
        dataDetails: {} 
      } 
    } 
    default: return state 
  } 
} 

export default blogReducer; 

