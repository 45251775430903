import React from 'react'
import CreateButton from './Create/CreateButton'
import TableView from './TableView'

const BroadObjective = () => {
    return (
        <div className="container">
            <CreateButton />
            <TableView />
        </div>
    )
}

export default BroadObjective
