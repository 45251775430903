import { 
    CREATE, GET_ALL, GET_SINGLE, UPDATE, 
    DELETE, BACK_BUTTON_IN_DETAIL_PAGE
} from '../actions/actionTypes'

let init = { 
    allData: [], 
    dataDetails: {}, 
} 

const designationReducer = (state = init, action) => {
    switch (action.type) {
        case CREATE: {
            const allDatas = state.allData.designation.concat(action.payload) 
            
            return { 
                ...state, 
                allData: { 
                    coutn: allDatas.length, 
                    designation: allDatas 
                } 
            } 
        } 
        case GET_ALL: {
            return {
                ...state, 
                allData: action.payload
            } 
        } 
        case GET_SINGLE: { 
            return { 
                ...state, 
                dataDetails: action.payload 
            } 
        } 
        case UPDATE: { 
            let data = state.allData.designation.map(obj => { 
                if(obj._id === action.payload._id) { return action.payload } 
                return obj 
            }) 
            let datas = { coutn: data.length, designation: data } 
            return { ...state, allData: datas, dataDetails: {} } 
        } 
        case DELETE: { 
            let data = state.allData.designation.filter(obj => { 
                if(obj._id !== action.payload.state._id) { return obj } 
            }) 
            let datas = { coutn: data.length, designation: data } 
            
            return { 
                ...state, 
                allData: datas, 
                dataDetails: {} 
            } 
        } 
        case BACK_BUTTON_IN_DETAIL_PAGE: {
            return { 
                ...state, 
                dataDetails: {} 
            } 
        } 
        default: return state 
    } 
} 

export default designationReducer; 

