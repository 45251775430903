import React from 'react';
import useWindowSize from '../hooks/useWindowSize.js';
import { ResponsiveBar } from '@nivo/bar';
import truncate from "truncate-html";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// const data = [
//     { name: "world", value: 200 },
//     { name: "Bangladesh", value: 100 },
//     { name: "India", value: 70 },
//     { name: "America", value: 220 },
//     { name: "Pakistan", value: 120 },
//     { name: "Canada", value: 220 },
//     { name: "South Africa", value: 320 },
//     { name: "Afganistan", value: 110 }
// ];

const Barchart = (props) => {

    const size = useWindowSize();

    const format = props.isParcent ? '.0%' : '0';
    const keys = props.keys ? props.keys : ['value'];
    const colorScheme = props.colors ? props.colors : { scheme: 'set3' };

    const keyIndex = props.colorFlag ? 10 : 0;

    const theme = {
        background: 'transparent',
        fontFamily: 'sans-serif',
        fontSize: 11,
        textColor: '#333333',
        axis: {
            domain: {
                line: {
                    stroke: 'transparent',
                    strokeWidth: 1
                }
            },
            ticks: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1
                },
                text: {
                    stroke: '#777777',
                    strokeWidth: .5,
                }
            },
            legend: {
                text: {
                    fontSize: 14,
                    fontWeight: 700,
                    fill: '#616161'
                }
            }
        },
        grid: {
            line: {
                stroke: '#dddddd',
                strokeWidth: 1
            }
        },
        legends: {
            text: {
                fill: '#333333'
            }
        },
        labels: {
            text: {
                stroke: '#212121',
                strokeWidth: .4,
            }
        },
        markers: {
            lineColor: '#000000',
            lineStrokeWidth: 1,
            text: {}
        },
        dots: {
            text: {}
        },
        tooltip: {
            container: {
                background: 'white',
                color: 'inherit',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px'
            },
            basic: {
                whiteSpace: 'pre',
                display: 'flex',
                alignItems: 'center'
            },
            table: {},
            tableCell: {
                padding: '3px 5px'
            }
        },
        crosshair: {
            line: {
                stroke: '#000000',
                strokeWidth: 1,
                strokeOpacity: 0.75,
                strokeDasharray: '6 6'
            }
        },
        annotations: {
            text: {
                fontSize: 13,
                outlineWidth: 2,
                outlineColor: '#ffffff'
            },
            link: {
                stroke: '#000000',
                strokeWidth: 1,
                outlineWidth: 2,
                outlineColor: '#ffffff'
            },
            outline: {
                fill: 'none',
                stroke: '#000000',
                strokeWidth: 2,
                outlineWidth: 2,
                outlineColor: '#ffffff'
            },
            symbol: {
                fill: '#000000',
                outlineWidth: 2,
                outlineColor: '#ffffff'
            }
        }
    };

    let height = 300;
    const numberOfBar = props.barchart.length;
    if (props.layout === "horizontal") {
        if (numberOfBar > 4) {
            // console.log("I am in");
            let multiplier = numberOfBar - 4;
            height += 30 * multiplier;
        } else {
            if (numberOfBar <= 0) {
                height = 0;
            }
        }
    }

    // console.log(height);

    let rotationAngel = 0;
    let marginBottom = 70;
    let legendOffset = 60;

    switch (true) {
        case (size.width >= 1024):
            rotationAngel = 0;
            break;
        case (size.width < 1024 && size.width >= 900):
            rotationAngel = 0;
            break;
        case (size.width < 900 && size.width >= 768):
            rotationAngel = -90;
            // marginBottom = 75;
            // legendOffset = 60;
            break;
        default:
            rotationAngel = -90;
            // marginBottom = 85;
            // legendOffset = 70;
            break;
    }

    const axis = getAxis(props.layout, rotationAngel);

    return (
        <>
            {
                numberOfBar > 0
                    ?
                    <div style={{ height: `${height}px`, width: '100%', marginBottom: '40px' }}>
                        <ResponsiveBar
                            data={props.barchart}
                            keys={keys}
                            indexBy="name"
                            margin={{ top: 40, right: 20, bottom: marginBottom, left: 44 }}
                            padding={0.4}
                            minValue={props.min ? props.min : "auto"}
                            maxValue={props.max ? props.max : "auto"}
                            layout={props.layout}
                            theme={theme}
                            colors={props.colorFlag ? getColor : colorScheme}
                            defs={[
                                {
                                    id: 'lines',
                                    type: 'patternLines',
                                    background: 'inherit',
                                    color: '#38bcb2',
                                    rotation: -45,
                                    lineWidth: 4,
                                    spacing: 5
                                }
                            ]}
                            fill={[
                                {
                                    match: {
                                        id: keys[keyIndex]
                                    },
                                    id: 'lines'
                                },
                            ]}
                            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            axisTop={null}
                            axisRight={null}
                            axisBottom={axis.axisBottom}
                            axisLeft={axis.axisLeft}
                            labelSkipWidth={12}
                            labelSkipHeight={12}
                            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                            animate={true}
                            motionStiffness={90}
                            motionDamping={15}
                            tooltip={({ color, id, index, indexValue, data }) => {
                                let icon = null;
                                if (props.hasIcon) {
                                    if (props.ratingType == 'starRating' || props.ratingType == 'loveRating' || props.ratingType == 'thumbsUpRating') {
                                        icon = [];
                                        for (let i = 0; i <= index; i++) {
                                            icon[i] = <FontAwesomeIcon
                                                icon={data.icon}
                                                size="lg"
                                                style={{
                                                    color: props.ratingType == 'starRating'
                                                        ? "#FFBF69"
                                                        : props.ratingType == 'loveRating' ? "#F27D7D" :
                                                            props.ratingType == 'thumbsUpRating' ? "#1B998B" : color,
                                                    margin: '0px 1px'
                                                }}
                                            />
                                        }
                                    } else {
                                        icon = <FontAwesomeIcon
                                            icon={data.icon}
                                            size="2x"
                                            style={{ color: color, margin: '0px 1px' }}
                                        />
                                    }
                                }
                                return (
                                    <div style={{ fontSize: "11px", fontWeight: "700", minWidth: "90px", maxWidth: "180px", color: "#424242" }}>
                                        <div className="text-center">
                                            {props.hasIcon
                                                ? <>
                                                    {icon}
                                                </> : <div className="float-left" style={{
                                                    width: "15px", height: "15px",
                                                    backgroundColor: color,
                                                    marginRight: "5px", borderRadius: "2px",
                                                    border: "2px solid #424242",
                                                    display: "inline-block"
                                                }}></div>}
                                            
                                            <div className={props.hasIcon ? "text-center" : "text-left"} style={{ lineHeight: "15px" }}>{indexValue}</div>
                                        </div>
                                        <hr style={{ margin: "4px 0 0 0", backgroundColor: "#BDBDBD" }} />
                                        <div className="text-left">
                                            <span style={{ fontSize: "11px", color: "#212121" }}>{id}</span> : <span style={{ color: "#212121", fontSize: "11px" }}>{data[id]}</span>
                                        </div>
                                    </div>
                                )
                            }}
                        />
                    </div>
                    :
                    <div className="alert alert-danger mt-5" role="alert">
                        <strong>Sorry!</strong> No data found
                    </div>
            }
        </>
    );
}

const getAxis = (layout, rotationAngel) => {

    let axisBottom = {
        tickSize: 10,
        tickPadding: 5,
        tickRotation: rotationAngel,
        legend: "",
        legendPosition: 'middle',
        legendOffset: 40
    }

    let axisLeft = {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "",
        legendPosition: 'middle',
        legendOffset: 40
    }

    if (layout === 'horizontal') {
        axisLeft = {
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: ({
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y
            }) => {
                return (
                    <g transform={`translate(${x + 2},${y})`}>
                        <text
                            alignmentBaseline={textBaseline}
                            textAnchor={textAnchor}
                            transform={`translate(${textX},${textY})`}
                            fontSize={`10px`}
                            stroke="#616161"
                            strokeWidth=".4"
                        >
                            <tspan>{truncate(value, 5)}</tspan>
                        </text>
                    </g>
                );
            }
        }
    } else {
        axisBottom = {
            tickSize: 0,
            tickPadding: 5,
            tickRotation: -90,
            legend: "",
            legendPosition: "middle",
            legendOffset: -40,
            renderTick: ({
                textAnchor,
                textBaseline,
                textX,
                textY,
                value,
                x,
                y
            }) => {
                return (
                    <g transform={`translate(${x},${y + 5})`}>
                        <text
                            alignmentBaseline={textBaseline}
                            textAnchor={textAnchor}
                            transform={`translate(${textX},${textY})`}
                            transform={`rotate(-90)`}
                            fontSize={`10px`}
                            stroke="#616161"
                            strokeWidth=".4"
                        >
                            <tspan>{truncate(value, 10)}</tspan>
                        </text>
                    </g>
                );
            }
        }
    }

    return { axisLeft: axisLeft, axisBottom: axisBottom }

}

const getColor = (bar) => {
    return bar.data.color;
}

export default Barchart;
