import React from 'react'

function Services() { 
  const style = { 
    p: { 
      color: 'rgb(52, 58, 64)', 
      fontSize: '13px', 
      textAlign: 'justify', 
      fontFamily: 'Roboto', 
      fontWeight: '400'
    }, 
    list: { 
      color: 'rgb(52, 58, 64)', 
      fontSize: '13px', 
      textAlign: 'justify', 
      fontFamily: 'Roboto', 
      fontWeight: '400', 
      marginLeft: '30px'
    } 
  } 

  return (
    <div className="my-5 mx-4 text-justify"> 
      <section id=""> 
        <h3 className="text-center">Terms of Services</h3>
        <p style={{...style.p}}>
        Welcome to Prolific Experience Management System (ProlificXM). Thank you for choosing our products and services. The following Terms of Service (referred to as “Terms” or “TOS”) will exercise control over your use of our products and services. So please read the Terms of Service carefully.
      </p> 
      </section>


      <section id="terms-of-services-introduction"> 
        <h5 className="text-center mt-4"> 
          Introduction 
        </h5> 
        <p style={{...style.p}}>
        ProlificXM maintains and operates an Internet Website at the URL 

        {` `} <a style={{color: '#1B998B', fontWeight: '500'}} target="_blank" 
        href="https://www.ProlificXM.com">https://www.ProlificXM.com</a> {` `} 

        (referred to as the “Website”) with related tools and processes (referred to as “Tools”) to create individualized surveys, questionnaires and contents (referred to as “Surveys”) for use by you. Together, the website, the tools, and Surveys, including all questions, templates and related materials, shall be called the “Services”. This agreement sets out the legally binding terms of your use of the service provided by ProlificXM to you. By accessing, using, or registering for the Service provided by ProlificXM, you agree that you have read and understood these Terms of Service. You agree to this agreement being legally binding, and agree that using the Services is the same as signing a written contract (referred to as “Agreement”) containing these terms. All End Users will be subject to the conditions of this Agreement. This Agreement may be modified by us from time to time and such modifications will be effective immediately upon publication of the modifications on our Website. You shall be responsible to review the Agreement from time to time so as to be aware of any such modifications to these Terms. We may, for our convenience only and without waiving your obligation to periodically review the Agreement, email you updates to the Agreement. You understand and agree that your continued access, registration or use of the Service shall be regarded as your acceptance of all modifications to the Agreement.

      </p> 
      </section>


      <section id="terms-of-services-our-service">
        <h5 className="text-center mt-4"> 
          Our services
        </h5> 
        <p style={{...style.p}}>
        ProlificXM provides advanced experience management solution through conducting online surveys and managing the user experience on websites and web-based businesses. Our Services may change from time to time, or we may stop (permanently or temporarily) providing our Services (or any features therein) to you or to users generally. We reserve the right to create limits on access and use of the Services in our sole discretion. <br /> <br /> 
        We may make available certain software to install on your Channels in order to access and use our Services. As long as you comply with the terms of this Agreement, you have the right to install and use our software to access and use the Services, for your own website (and not for use with other websites). This license is for the sole purpose of enabling you to use the Services, in the manner permitted by the terms of this Agreement. You may not copy, modify, distribute, sell, or lease our products or any part of our Services or data, nor may you reverse engineer or attempt to extract the source code of our software, unless laws prohibit those restrictions or you have our written permission. 
      </p> 
      </section>


      <section id="terms-of-services-your-content"> 
        <h5 className="text-center mt-4"> 
          Your Content
        </h5> 
        <p style={{...style.p}}>
        You are the owner of the intellectual property rights of the content you submit to us or the Services (referred to as “Your Content”), except for the limited rights that enable us to perform the Services. In brief, your content is yours, but we need certain permissions from you so that our storage, maintenance, processing, back-up, distribution and related handling of your content does not infringe any copyright laws and any other laws. Therefore, in order to	 perform the Services, you grant us a non-exclusive, worldwide, royalty-free, conveyable, and irreversible license to use, propagate and exhibit your content as reasonably required to provide you with the Services. You are responsible for maintaining, safeguarding, and creating backups of Your Content. ProlificXM will not be liable for the loss or defilement of Your Content.
      </p> 
      </section> 


    
      <section id="terms-of-services-our-content"> 
      <h5 className="text-center mt-4"> 
        Our Content
      </h5> 
      <p style={{...style.p}}>
        Your use of our Services does not grant you ownership of any intellectual property rights in the Services, apart from Your Content. You agree that all ProlificXM content and materials (referred to as “Our Content”)  supplied via the Services or provided by ProlificXM are protected by copyrights, brand names, trademarks, patents, trade secrets, or other proprietary rights and laws. You agree not to sell, license, rent, modify, distribute, imitate, plagiarize, propagate, transmit, proclaim, alter, edit, or create modified works of Our Content, unless we provide you any specifically written authorization to do so. However, you may print or download a reasonable number of copies of Our Content for your own informational purposes; provided that you retain all copyright and other proprietary notices within the copies. Replication, imitation, or sharing any of Our Content or ProlificXM design materials for any other purpose is strictly prohibited without our prior specific written permission. Use of Our Content for any purpose not specifically permitted in these Terms is prohibited. ProlificXM reserves any rights not specifically granted in these Terms.

      </p> 
      </section> 


    
      <section id="terms-of-services-use-of-the-services">
        <h5 className="text-center mt-4"> 
          Use of the services 
        </h5> 
        <p style={{...style.p}}>
        You constitute and ensure that your use of the Services will comply with all laws and regulations; Your Content will not infringe or violate any third-party intellectual property rights or any laws or regulations (including corruption, obscenity, defamation, and privacy laws); if you use the Services on behalf of any third party, you have all necessary authorizations; and your use of the Services will not conflict with any obligations you have to any third party. We reserve the right, in our sole discretion, to remove, modify, prevent access to, or refuse to display Your Content that we believe violates these Terms, our policies, or the law.
      </p> 
      </section>
    


      <section id="terms-of-services-fees-and-payments"> 
        <h5 className="text-center mt-5"> 
          Fees and Payments 
        </h5> 
        <p style={{...style.p}}>
        To try out our Services, ProlificXM offers a free of cost registration option. At any time, you may upgrade to any of 
        
        {` `} <a style={{color: '#1B998B', fontWeight: '500'}} target="_blank" 
        href="/pricing">Prolific's</a> {` `} 

        paid subscriptions. If you are a paying user of the Services, you shall pay ProlificXM Service fees in accordance with our pricing plan. You will be billed for your first subscription term immediately upon upgrading to a paid subscription. 
      </p> 
      </section>
    

      <section id="fees-and-payments-subscription-fees"> 
        <h5 className="text-center mt-5"> 
          Subscription Fees
        </h5> 
        <p style={{...style.p}}>
        Our Services are billed on a subscription basis. For the details of each Subscription Plan, see our “Plans and Pricing” page. You will be billed in advance on a recurring, cyclic basis. Each period is called a “Billing Cycle”. Billing Cycles are typically monthly or annual, depending on what subscription plan you select when purchasing a Subscription. Your Subscription will automatically renew at the end of each billing cycle unless you cancel auto-renewal through your online account management page, or by contacting our customer support team. You may cancel auto-renewal on your Subscription at any time, in which case your Subscription will continue until the end of that billing cycle before cancellation. You may cancel auto-renewal on your Subscription immediately after the Subscription starts if you do not want to continue. 
      </p> 
      </section> 
    


      <section id="fees-and-payments-taxes"> 
        <h5 className="text-center mt-5"> 
          Taxes
        </h5> 
        <p style={{...style.p}}>
        Our pricing plan does not include any taxes, levies, duties or similar governmental charges of any nature such as value-added, sales, use or withholding taxes, accessible by any jurisdiction (collectively referred to as “Taxes”) unless otherwise indicated. You are responsible for the payment of  Taxes associated with your purchase and keep your billing information up to date.
      </p> 
      </section> 


    
      <section id="fees-and-payments-price-changes">
        <h5 className="text-center mt-5"> 
          Price Changes
        </h5> 
        <p style={{...style.p}}>
        ProlificXM may change the Subscription and Plan fees charged to you for the Services at any time, provided that, the change will become effective only at the end of the running billing cycle of your Subscription. ProlificXM will notify you with advance notice of any change in fees.
      </p> 
      </section> 



    
      <section id="fees-and-payments-overage-fees"> 
        <h5 className="text-center mt-5"> 
          Overage Fees 
        </h5> 
        <p style={{...style.p}}>
        Any overage fees incurred by you, will be billed in arrears unless otherwise stated. Overage fees which remain unpaid for 30 days after being billed are considered overdue. Failure to pay overage fees when due may result in the applicable Service being limited, suspended, or terminated (subject to applicable legal requirements), which may result in a loss of your data associated with that Service.
      </p> 
      </section> 


      
    
      <section id="terms-of-services-suspension-and-termination"> 
        <h4 className="text-center mt-5"> 
          Suspension and Termination
        </h4> 
      </section> 



      <section id="suspension-and-termination-by-you">
        <h5 className="text-center mt-3"> 
          Termination by You
        </h5> 
        <p style={{...style.p}}>
          Through our account management page, you can terminate your subscription or delete your account at any time. Termination of a Subscription will result in deactivation or disablement of your account and access to it. Deletion of your account will result in disablement of your account and access to it and deletion of the Data you collected through the use of the Services. Once you Terminate a Subscription, you will be confirmed immediately. You will not be charged again for that Subscription unless you purchase a new Subscription. If you terminate a Subscription in the middle of a billing cycle, you will not receive a refund.= unless you are terminating due to any of the following reasons:
        </p> 
        {/* <ul style={{marginLeft: '20px'}}> */}
        <ul style={style.list}> 
          <li>We have materially breached the Terms and failed to cure that breach within 30 days after you have notified us in writing;</li>
          <li>A refund is required by law;</li>
          <li>We, in our sole discretion, determine a refund is appropriate.</li>
        </ul>
        <p style={{...style.p}}>
        Thus, a refund will not be granted where you have used our Services, collected responses, and/or downloaded your responses unless the termination is due to our material, uncured breach or a refund is required by law.
      </p>
      </section> 



      <section id="suspension-and-termination-by-us">
        <h5 className="text-center mt-5"> 
          Suspension and Termination by Us
        </h5> 
        <p style={{...style.p}}>
          We may terminate your Subscription at the end of a billing cycle by providing at least 30 days’ prior written notice to you. We may terminate your Subscription for any reason by providing at least 90 days’ written notice to you and will provide a pro rata refund for any period of time you did not use in that billing cycle. We may suspend performance or terminate your Subscription for any of the following reasons:
        </p> 
        <ol style={style.list} type="A">
        <li>You have materially breached the Terms and failed to cure that breach within 30 days after ProlificXM has notified you in writing</li>
        <li>You cease your business operations or become subject to insolvency proceedings and the proceedings are not dismissed within 90 days; or</li>
        <li>You fail to pay fees for 30 days past the due date.</li>
      </ol>
        <p style={{...style.p}}>
          Additionally, ProlificXM may restrict, suspend, or terminate the Services to you on the following ground: 
        </p> 
        <ul style={style.list}> 
          <li>If you fail to comply with the Terms</li> 
          <li>If you use the Services in a way that changes-and-updatesses legal liability to us or disrupts others’ use of the Services</li> 
          <li>If we are investigating suspected misconduct by you.</li> 
        </ul>
        <p style={{...style.p}}>
          If we restrict, suspend, or terminate the Services you receive, we may give you advance notice and a chance to obtain a copy of your Content from the Service depending upon the reason. However, there may be time sensitive situations where ProlificXM may decide that we need to take immediate action without notice. ProlificXM will use commercially reasonable efforts to narrow the scope and duration of any limitation or suspension under this Section as is needed to resolve the issue that prompted such action. ProlificXM has no obligation to retain your Content upon termination of the applicable Service.
        </p>
      </section> 

     
      <section id="terms-of-services-changes-and-updates">
        <h4 className="text-center mt-5"> 
          Changes and Updates
        </h4> 
      </section>

      <section id="changes-and-updates-to-terms"> 
        <h5 className="text-center mt-3"> 
          Changes to Terms
        </h5> 
        <p style={{...style.p}}>
        ProlificXM may change the Terms at any time for different reasons such as to reflect changes in applicable law or updates to Services, and to account for new Services or features. The most current version will always be posted on the ProlificXM website. If an amendment is material, as determined in ProlificXM's sole discretion, ProlificXM will notify you by email. Notice of amendments may also be posted to ProlificXM's blog or upon your login to your account. Changes will be effective from the day they are publicly posted. In order for certain changes to become effective, applicable law may require ProlificXM to obtain your consent to such changes, or to provide you with sufficient advance notice of them. If you do not want to agree to any changes made to the terms for a Service, you should stop using that Service, bechanges-and-updatesse by continuing to use the Services you indicate your agreement to be bound by the updated terms.
      </p> 
      </section> 


      <section id="changes-and-updates-to-services"> 
        <h5 className="text-center mt-5"> 
          Changes to Services
        </h5> 
        <p style={{...style.p}}>
        ProlificXM constantly changes and improves the Services. ProlificXM may add, alter, or remove functionality from a Service it provides to you at any time without prior notice. ProlificXM may also restrict, suspend, or discontinue a Service provided to you at its discretion. If ProlificXM discontinues a Service, we will give you reasonable advance notice to provide you with an opportunity to obtain a copy of your Content from that Service. ProlificXM may remove content from the Services it provides you at any time at our sole discretion, although we will endeavor to notify you before we do that if it materially impacts you and if practicable under the circumstances.
      </p> 
      </section>


      <section id="changes-and-updates-downgrades"> 
        <h5 className="text-center mt-5"> 
          Downgrades 
        </h5> 
        <p style={{...style.p}}>
        ProlificXM provides its users with a free version for simple surveys to try out the Services. However, this only contains basic functions that can be used to collect a limited number of responses.
        <br /> <br/> 
        In the event of a downgrade of a commercial license to a ProlificXM Free user account or a lower Plan previously used functionalities only available to the higher Plan will be removed and limited to the basic functions of the lower Plan. Full access to the survey data may, in the case of earlier use of functions from the higher license, will not be available. In order to get access again, it is possible to upgrade the license at any time.
      </p> 
      </section>


      <section id="terms-of-services-confidential-information"> 
        <h5 className="text-center mt-5"> 
          Confidential Information
        </h5> 
        <p style={{...style.p}}>
          In the course of working together, we understand that each of us may have received the Confidential Information (as defined below) of the other. Unless required by law, we both agree that we shall not make each other’s Confidential Information available in any form to any third party or use each other’s Confidential Information for any purpose other than to implement the Services that you subscribe to. Nevertheless, ProlificXM may share your Confidential Information with agents acting on our behalf in the fulfillment of our obligations under this Agreement, so long as such agent has executed a confidentiality agreement protecting your Confidential Information with at least the protections outlined in this Agreement. If any party is required by law to disclose the Confidential Information of the other, the party making such a disclosure will provide the disclosing party with reasonable notice of its intent to comply and provide all reasonable cooperation in assisting the owner of the Confidential Information to minimize the disclosure. “Confidential Information” means any information disclosed by one party to the other marked “confidential” or disclosed under circumstances that would lead a reasonable person to conclude that the information is confidential. The following types of information shall be considered Confidential Information: the terms and prices negotiated by you for the Services, any software or documentation related to the Services that is non-public; ProlificXM sample questions and templates, trade secrets, technical know-how, inventions, educational materials, product development plans, pricing, marketing plans, client lists, your email distribution lists and all survey responses from your surveys. Confidential Information does not include information that: 
        </p> 
        <ol style={style.list}> 
        <li>Is or becomes publicly available through no act or omission of the party receiving the Confidential Information.</li>
        <li>Was in the receiving party’s lawful possession prior to the disclosure.</li>
        <li>Is lawfully disclosed to the other party by a third party without restrictions on non-disclosure obligations.</li>
      </ol>
      </section>


      <section id="terms-of-services-indemnification"> 
        <h5 className="text-center mt-5"> 
          Indemnification
        </h5> 
        <p style={{...style.p}}>
          You are responsible for all of your activity in connection with the Services and shall defend, indemnify, and hold harmless ProlificXM and each of its employees, contractors, directors, suppliers and representatives from all liabilities, damages, losses, settlements, claims, actions, demands, costs and expenses, including attorneys' fees, that arise from or in connection with your
        </p> 
        <ol style={style.list}> 
        <li>Use or misuse of the Services.</li>
        <li>Access to any part of ProlificXM and the Services.</li>
        <li>Your Content.</li>
        <li>Violation of these Terms</li>
      </ol>
      </section>


      <section id="terms-of-services-electronic-communication-and-notices">
        <h5 className="text-center mt-5"> 
          Electronic Communications and Notices
        </h5> 
        <p style={{...style.p}}>
        By creating an account, you hereby consent to receive electronic communications from us, including marketing emails, product updates, and other business messages. You agree that all agreements, notices, disclosures, and other communications that we provide to you electronically suspension-and-terminationisfy any legal requirement that such communications be in writing. Electronic communications may be in the form of emails sent by us to the email address associated with your account or communications posted by us on the ProlificXM website, your “My Account” page, or the Services you utilize. We save all communication (chats, emails, and calls) between ProlificXM representatives and you for record-keeping, training and quality-assurance purposes. <br /> <br />
        All notices to ProlificXM must be addressed in writing to: Prolific Analytics Ltd., House#15 Road#7 Block#C Niketan, Gulshan, Dhaka 1212, Bangladesh. ProlificXM may provide notices to you via the email address associated with your account or through your Services account.
      </p> 
      </section>


      <section id="terms-of-services-miscellaneous">
        <h5 className="text-center mt-5"> 
          Miscellaneous
        </h5> 
        <p style={{...style.p}}>
        The failure of either party to exercise or enforce any right contained in these Terms, is not a waiver of either parties’ right to do so later. ProlificXM shall not be liable for any failure to perform its obligations in these Terms where such failure results from any changes-and-updatesse beyond ProlificXM’s reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation. If any provision of these Terms is found to be inapplicable or illogical, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in effect and executable. These Terms are not assignable, transferable, or not comparable by you, except with our prior written consent. We may transfer, allocate, or assign the Terms and their rights and obligations without consent. Both parties agree that these Terms are the complete and exclusive statement of the mutual understanding of the parties and 
        <br /> 
        Overrides and cancels all previous written and oral agreements, communications, and other understandings relating to the subject matter of these Terms, and that all modifications must be in writing signed by both parties, except as otherwise provided herein. No agency, partnership, joint venture, or employment is created as a result of these Terms and you do not have any authority of any kind to bind ProlificXM in any respect whatsoever.
      </p> 
      </section>
    
    </div> 
  ) 
} 

export default Services; 
