import React, { useState, useEffect } from 'react'

import { MainBody } from './styles'
import Configuration from '../../components/Configuration'
import QuestionList from '../../container/QuestionList'
import NextButton from '../../components/NextButton'

const SelectPage = props => {

  return (
    <MainBody>
      <NextButton disid={null} />
      <Configuration />
      <QuestionList />
    </MainBody>
  )
}

export default SelectPage